import * as React from 'react';
import { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';
import { TableWrap } from '../multipleLocationWidget.styled';
import { getValFormat } from '../../../utils/helpers';
import DetailPanelTrigger from './detailPanelTrigger';
import { Location } from '../../../generated/voyager/graphql';

interface FinancialHealthTabProps {
  locationsDetails: Location[];
}

function FinancialHealthTab({ locationsDetails }: FinancialHealthTabProps): ReactElement {
  return (
    <>
      <TableWrap>
        <Table basic="very" className="varColumns4 locations-tab-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Breakeven Price</Table.HeaderCell>
              <Table.HeaderCell>AVG Discount</Table.HeaderCell>
              <Table.HeaderCell>LG/SM List Price</Table.HeaderCell>
              <Table.HeaderCell>LG/SM Net ARPM</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {locationsDetails?.map(location => {
              let smListPrice = null;
              if (location.metrics?.smListPrice) {
                smListPrice = getValFormat(location.currencyIsoCode, location.metrics.smListPrice);
              }
              let lgListPrice = null;
              if (location.metrics?.lgListPrice) {
                lgListPrice = getValFormat(location.currencyIsoCode, location.metrics.lgListPrice);
              }
              let avgNetArpmLargeSku = null;
              if (location.metrics?.avgNetArpmLargeSku) {
                avgNetArpmLargeSku = getValFormat(
                  location.currencyIsoCode,
                  location.metrics.avgNetArpmLargeSku,
                );
              }
              let avgNetArpmSmallSku = null;
              if (location.metrics?.avgNetArpmSmallSku) {
                avgNetArpmSmallSku = getValFormat(
                  location.currencyIsoCode,
                  location.metrics.avgNetArpmSmallSku,
                );
              }
              return (
                <Table.Row key={`location-widget-table-${location.id}`}>
                  <Table.Cell>
                    {location.minPrice
                      ? getValFormat(location.currencyIsoCode, location.minPrice)
                      : '-'}
                  </Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>{`${lgListPrice ?? '-'} / ${smListPrice ?? '-'}`}</Table.Cell>
                  <Table.Cell>
                    {`${avgNetArpmLargeSku ?? '-'} / ${avgNetArpmSmallSku ?? '-'}`}
                  </Table.Cell>
                  <Table.Cell>
                    <DetailPanelTrigger
                      currentDisplayableItem={location.id}
                      currentDisplayableItemType={'location'}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </TableWrap>
    </>
  );
}

export default FinancialHealthTab;
