import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import AppConfig from '../../../../../config';
import { getAuthToken } from '../../../../../index';
import {
  LIVE_BUDGET_PUSH_JOB,
  LIVE_BUDGET_PUSH_JOB_ERROR,
  LIVE_BUDGET_PUSH_JOB_SUCCESS,
  LOCATION_METRICS_PUSH_JOB,
  LOCATION_METRICS_PUSH_JOB_ERROR,
  LOCATION_METRICS_PUSH_JOB_SUCCESS,
  LOCATION_SKU_OCCUPANCY_CALCULATION,
  LOCATION_SKU_OCCUPANCY_CALCULATION_ERROR,
  LOCATION_SKU_OCCUPANCY_CALCULATION_SUCCESS,
  MARKET_SKU_OCCUPANCY_CALCULATION,
  MARKET_SKU_OCCUPANCY_CALCULATION_ERROR,
  MARKET_SKU_OCCUPANCY_CALCULATION_SUCCESS,
  PRICE_RECOMMENDATION_PUSH_JOB,
  PRICE_RECOMMENDATION_PUSH_JOB_ERROR,
  PRICE_RECOMMENDATION_PUSH_JOB_SUCCESS,
  RESERVABLE_METRICS_PUSH_JOB,
  RESERVABLE_METRICS_PUSH_JOB_ERROR,
  RESERVABLE_METRICS_PUSH_JOB_SUCCESS,
} from './snowflakeSyncInitialization.ducks';
import { CHECK_JOB_STATUS, SET_JOB_STATUS } from '../jobStatus/jobStatus.ducks';

export const priceRecommendationPushJobRequest = (): Promise<AxiosResponse<any>[]> => {
  const snowflakeSyncInitializationUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/init/price-recommendations`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(snowflakeSyncInitializationUrl, null, { headers });
};

export function* priceRecommendationPushJobWorker() {
  const response: AxiosResponse | undefined = yield call(priceRecommendationPushJobRequest);
  if (response?.status === 200) {
    yield put({ type: PRICE_RECOMMENDATION_PUSH_JOB_SUCCESS });
    toast.success(
      'Price recommendation push job starts! An email will be sent to you once the job is done!',
    );
    yield put({ type: SET_JOB_STATUS, data: { job: 'SNOWFLAKE_PRICE_RECOMMENDATION' } });
  } else {
    toast.error('Price recommendation push job failed!');
    yield put({ type: PRICE_RECOMMENDATION_PUSH_JOB_ERROR, payload: response?.data?.message });
  }
  yield put({ type: CHECK_JOB_STATUS });
}

export const liveBudgetPushJobRequest = (): Promise<AxiosResponse<any>[]> => {
  const liveBudgetPushJobUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/init/live-budget`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(liveBudgetPushJobUrl, null, { headers });
};

export function* liveBudgetPushJobWorker() {
  const response: AxiosResponse | undefined = yield call(liveBudgetPushJobRequest);
  if (response?.status === 200) {
    yield put({ type: LIVE_BUDGET_PUSH_JOB_SUCCESS });
    toast.success(
      'Live budget push job starts! An email will be sent to you once the job is done!',
    );
    yield put({ type: SET_JOB_STATUS, data: { job: 'SNOWFLAKE_LIVE_BUDGET' } });
  } else {
    toast.error('Live budget push job failed!');
    yield put({ type: LIVE_BUDGET_PUSH_JOB_ERROR, payload: response?.data?.message });
  }
}

export const locationMetricsPushJobRequest = (): Promise<AxiosResponse<any>[]> => {
  const locationMetricsPushJobUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/init/metrics/location`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(locationMetricsPushJobUrl, null, { headers });
};

export function* locationMetricsPushJobWorker() {
  const response: AxiosResponse | undefined = yield call(locationMetricsPushJobRequest);
  if (response?.status === 200) {
    yield put({ type: LOCATION_METRICS_PUSH_JOB_SUCCESS });
    toast.success(
      'Location metrics push job starts! An email will be sent to you once the job is done!',
    );
    yield put({ type: SET_JOB_STATUS, data: { job: 'SNOWFLAKE_LOCATION_METRICS' } });
  } else {
    toast.error('Location metrics push job failed!');
    yield put({ type: LOCATION_METRICS_PUSH_JOB_ERROR, payload: response?.data?.message });
  }
}

export const reservableMetricsPushJobRequest = (): Promise<AxiosResponse<any>[]> => {
  const reservableMetricsPushJobUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/init/metrics/reservable`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(reservableMetricsPushJobUrl, null, { headers });
};

export function* reservableMetricsPushJobWorker() {
  const response: AxiosResponse | undefined = yield call(reservableMetricsPushJobRequest);
  if (response?.status === 200) {
    yield put({ type: RESERVABLE_METRICS_PUSH_JOB_SUCCESS });
    toast.success(
      'Reservable metrics push job starts! An email will be sent to you once the job is done!',
    );
    yield put({ type: SET_JOB_STATUS, data: { job: 'SNOWFLAKE_REVERSABLE_METRICS' } });
  } else {
    toast.error('Reservable metrics push job failed!');
    yield put({ type: RESERVABLE_METRICS_PUSH_JOB_ERROR, payload: response?.data?.message });
  }
}

export const locationSKUOccupancyCalculationRequest = (): Promise<AxiosResponse<any>[]> => {
  const domain = '/admin/init/metrics/calculate-location-sku-occupancy';
  const locationSKUOccupancyCalculationUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL + domain}`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(locationSKUOccupancyCalculationUrl, null, { headers });
};

export function* locationSKUOccupancyCalculationWorker() {
  const response: AxiosResponse | undefined = yield call(locationSKUOccupancyCalculationRequest);
  if (response?.status === 200) {
    yield put({ type: LOCATION_SKU_OCCUPANCY_CALCULATION_SUCCESS });
    toast.success(
      'Location SKU occupancy calculation starts! An email will be sent to you once the job is done!',
    );
  } else {
    toast.error('Location SKU occupancy calculation failed!');
    yield put({ type: LOCATION_SKU_OCCUPANCY_CALCULATION_ERROR, payload: response?.data?.message });
  }
}

export const marketSKUOccupancyCalculationRequest = (): Promise<AxiosResponse<any>[]> => {
  const domain = '/admin/init/metrics/calculate-market-sku-occupancy';
  const marketSKUOccupancyCalculationUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL + domain}`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(marketSKUOccupancyCalculationUrl, null, { headers });
};

export function* marketSKUOccupancyCalculationWorker() {
  const response: AxiosResponse | undefined = yield call(marketSKUOccupancyCalculationRequest);
  if (response?.status === 200) {
    yield put({ type: MARKET_SKU_OCCUPANCY_CALCULATION_SUCCESS });
    toast.success(
      'Market SKU occupancy calculation starts! An email will be sent to you once the job is done!',
    );
  } else {
    toast.error('Market SKU occupancy calculation failed!');
    yield put({ type: MARKET_SKU_OCCUPANCY_CALCULATION_ERROR, payload: response?.data?.message });
  }
}

export default function* snowflakeSyncInitializationSaga(): any {
  yield all([takeLatest(PRICE_RECOMMENDATION_PUSH_JOB, priceRecommendationPushJobWorker)]);
  yield all([takeLatest(LIVE_BUDGET_PUSH_JOB, liveBudgetPushJobWorker)]);
  yield all([takeLatest(LOCATION_METRICS_PUSH_JOB, locationMetricsPushJobWorker)]);
  yield all([takeLatest(RESERVABLE_METRICS_PUSH_JOB, reservableMetricsPushJobWorker)]);
  yield all([
    takeLatest(LOCATION_SKU_OCCUPANCY_CALCULATION, locationSKUOccupancyCalculationWorker),
  ]);
  yield all([takeLatest(MARKET_SKU_OCCUPANCY_CALCULATION, marketSKUOccupancyCalculationWorker)]);
}
