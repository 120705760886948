import { isEmpty } from 'lodash';
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import { User } from '@auth0/auth0-spa-js';
import {
  ADD_REFERRER,
  AUTHENTICATE,
  LOGIN_REQUEST,
  LOGIN_REQUEST_SUCCESS,
  LOGOUT_REQUEST,
  REDIRECT_REQUEST,
  UN_AUTHENTICATED,
} from './auth.ducks';
import store, { auth0, graphQLClient } from '../../../index';
import { referrerSelector } from './auth.selector';
import { errorHandlerActive } from '../../../utils/errorHandling/helpers';

function logoutReferrerHelper() {
  const referrer = window.localStorage.getItem('from_logout_referrer');
  return referrer ? JSON.parse(referrer) : '';
}

// Saga - login using auth0 with redirect to callback.
function* onLoginRequestWorker() {
  try {
    yield call([auth0, auth0.loginWithRedirect]);
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

// Saga - to handle the redirect request if the redirect is callback.
function* onHandleRedirectRequestWorker() {
  try {
    // 1) Reading and setting the referrer first from local storage.
    let payload = logoutReferrerHelper();
    if (!isEmpty(payload)) {
      yield put({ type: ADD_REFERRER, payload });
      window.localStorage.removeItem('from_logout_referrer');
    }
    // 2) Handling the redirect callback.
    yield call([auth0, auth0.handleRedirectCallback]);
    // 3) Getting and storing the auth and id token.
    const token: string = yield call([auth0, auth0.getTokenSilently]);
    const user: User = yield call([auth0, auth0.getUser]);
    payload = { token, user };
    yield put({ type: LOGIN_REQUEST_SUCCESS, payload });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

// Saga - to authenticate the current user.
function* onFetchAuthStateWorker() {
  try {
    // 1) Check if already authenticated.
    const isAuthenticated: boolean = yield call([auth0, auth0.isAuthenticated]);
    // If authentication get the token and details.
    if (isAuthenticated) {
      const token: string = yield call([auth0, auth0.getTokenSilently]);
      const user: User = yield call([auth0, auth0.getUser]);
      const payload = { token, user };
      yield put({ type: LOGIN_REQUEST_SUCCESS, payload });
    } else {
      yield put({ type: UN_AUTHENTICATED });
    }
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

// Saga - to handle the user logout.
function* onLogoutRequestWorker() {
  try {
    const currReferrer = referrerSelector(store.getState());
    // Set localStorage so that the app knows that it came from the logout
    // action when the we.id backend redirects back and re-mounts the app
    window.localStorage.setItem('from_logout', 'true');
    window.localStorage.setItem('from_logout_referrer', JSON.stringify(currReferrer) ?? '');
    // Asking Auth0 to logout.
    yield call([auth0, auth0.logout]);
    // Resetting the store and clearing all the data.
    yield call(graphQLClient.resetStore);
  } catch (e: any) {
    // NOOP: do nothing since the auth sdk will redirect to /callback
  }
}

export default function* authSaga(): any {
  yield all([takeLatest(LOGIN_REQUEST, onLoginRequestWorker)]);
  yield all([takeLeading(REDIRECT_REQUEST, onHandleRedirectRequestWorker)]);
  yield all([takeLatest(AUTHENTICATE, onFetchAuthStateWorker)]);
  yield all([takeLatest(LOGOUT_REQUEST, onLogoutRequestWorker)]);
}
