import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { differenceBy, includes, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps } from 'semantic-ui-react';
import { AgGridReact } from 'ag-grid-react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { SelectionChangedEvent } from 'ag-grid-community/dist/lib/events';
import { MODIFY_SELECTED_LOCATIONS } from '../../sharedStore/modules/locations/locations.ducks';
import { getAllSelectedLocations } from '../../sharedStore/modules/locations/locations.selector';
import { columnDefs, gridOptions } from './multipleLocationSearchAGGrid.settings';
import MultipleLocationSearchGridWrapper from './multipleLocationSearchAGGrid.styled';
import { usePrevious } from '../../app/pricing/standardPricing/components/helpers';
import { GridCustomWrapper } from '../../styles/app.styled';
import { Location } from '../../generated/voyager/graphql';

interface MultipleLocationSearchTableComponentProps {
  allowDifferentCurrencies?: boolean;
  tableViewItems: Location[];
}

function MultipleLocationSearchTableComponent({
  allowDifferentCurrencies,
  tableViewItems,
}: MultipleLocationSearchTableComponentProps): ReactElement {
  // LOCAL STATE VARIABLES
  const allSelectedLocations = useSelector(getAllSelectedLocations);
  const previousAllSelectedLocations = usePrevious(allSelectedLocations);

  const [gridApi, setGridApi] = useState<GridApi>();
  const [selectedCurrency, setSelectedCurrency] = useState<string | null>(null);
  const [currentSelectedLocations, setCurrentSelectedLocations] = useState<Location[]>([]);

  // DISPATCH PROPS
  const dispatch = useDispatch();
  const modifySelectedLocations = useCallback(
    (payload: DropdownItemProps[]) => dispatch({ type: MODIFY_SELECTED_LOCATIONS, payload }),
    [dispatch],
  );

  // Initializing grid data with table row values.
  const initGridData = () => {
    if (!isEmpty(tableViewItems) && gridApi) {
      if (!allowDifferentCurrencies) {
        setSelectedCurrency(allSelectedLocations[0]?.currencyIsoCode);
      }
      gridApi.setRowData(tableViewItems);
      const idArr = map(allSelectedLocations, 'id');
      gridApi.forEachNode(rowNode => {
        if (includes(idArr, rowNode.id)) {
          rowNode.setSelected(true, false);
        }
      });
      gridApi.sizeColumnsToFit();
      gridApi.resetRowHeights();
      setCurrentSelectedLocations(gridApi.getSelectedNodes()?.map(node => node.data) ?? []);
    }
  };
  useEffect(initGridData, [tableViewItems, gridApi]);

  // Clearing all selection when clear all is clicked.
  const clearAll = () => {
    if (gridApi && isEmpty(allSelectedLocations) && !isEmpty(previousAllSelectedLocations)) {
      gridApi.deselectAll();
      setSelectedCurrency(null);
    }
  };
  useEffect(clearAll, [gridApi, allSelectedLocations, previousAllSelectedLocations]);

  /**
   * On Change in selection there are three cases:
   * 1) We might need to select only certain locations & unselect rest of the locations.
   * 2) We might need to select all locations.
   * 3) We might need to unselect all locations.
   * @param event
   */
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    // DATA on selection change.
    const allNodes = event.api.getSelectedNodes();
    let tempSelectedNodeData = allNodes.map(eachNode => eachNode.data);

    // Modify selected nodes to exclude locations with different currencies.
    if (!allowDifferentCurrencies) {
      // If no currency is selected, auto-select the currency of the first location.
      const currency = selectedCurrency ?? tempSelectedNodeData[0]?.currencyIsoCode;
      setSelectedCurrency(currency);

      // Filter out locations with different currencies.
      tempSelectedNodeData = tempSelectedNodeData.filter(data => data.currencyIsoCode === currency);

      // Case when a user deselects all locations, but there are non-selectable location(s) with different currencies.
      // Ag grid considers this action as "select all" because some nodes are not selected.
      // We need to override this behavior by manually deselecting all nodes.
      if (tempSelectedNodeData.length === currentSelectedLocations.length) {
        tempSelectedNodeData = [];
      }

      // Visually deselect nodes that were filtered out above.
      const tempSelectedNodeDataIds = tempSelectedNodeData.map(data => data.id);
      allNodes
        .filter(node => !tempSelectedNodeDataIds.includes(node.data.id))
        .forEach(node => node.setSelected(false, false));
    }

    const selected = differenceBy(tempSelectedNodeData, currentSelectedLocations, 'id');
    const deselected = differenceBy(currentSelectedLocations, tempSelectedNodeData, 'id');

    // Update the current grid selection and the list of all selected locations.
    setCurrentSelectedLocations(tempSelectedNodeData);
    modifySelectedLocations(differenceBy([...allSelectedLocations, ...selected], deselected, 'id'));
  };

  /**
   * OnGridReady is an AgGrid event that receives a param with access to the api.
   * @param params Parameter passed from AG grid.
   */
  const onGridReady = (params: GridReadyEvent): void => {
    if (params.columnApi) {
      params.columnApi.autoSizeAllColumns();
    }
    setGridApi(params?.api);
  };

  // On change in size of the grid resizing all the columns.
  const onGridSizeChanged = () => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
      gridApi.resetRowHeights();
    }
  };

  // When there is change in selectCurrency we are refreshing the cells.
  const onComponentStateChanged = useCallback(() => {
    if (gridApi) {
      gridApi.refreshHeader();
      gridApi.refreshCells({
        suppressFlash: true,
        force: true,
        columns: ['selectionCheckbox'],
      });
    }
  }, [gridApi]);

  return !isEmpty(tableViewItems) ? (
    <GridCustomWrapper>
      <MultipleLocationSearchGridWrapper className="ag-theme-alpine multiple-location-search-grid">
        <AgGridReact
          gridOptions={gridOptions}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onGridSizeChanged={onGridSizeChanged}
          context={{ selectedCurrency }}
          onComponentStateChanged={onComponentStateChanged}
        />
      </MultipleLocationSearchGridWrapper>
    </GridCustomWrapper>
  ) : (
    <></>
  );
}

export default MultipleLocationSearchTableComponent;
