import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pluralize } from 'apollo/lib/utils';
import { Checkbox, Confirm, Dropdown, Radio } from 'semantic-ui-react';
import { ConfirmProps } from '../../../../../../sharedStore/entities/confirmProps';
import {
  RESET_SELECTED_NODES_ON_DEMAND,
  UPDATE_GEO_HIERARCHY_ATTRIBUTES,
} from '../../../store/modules/onDemand/onDemandPricing.ducks';
import {
  onDemandPricingProductsSelector,
  onDemandSelectedNodesSelector,
  updateGeoHierarchyAttributesLoadingSelector,
} from '../../../store/modules/onDemand/onDemandPricing.selector';
import { DataWithCallback } from '../../../../../../utils/sharedTypes';
import { trackAnalytics } from '../../../../../../utils/analytics/helpers';
import {
  AttributeProps,
  supportedGeoHierarchyAttributes,
} from '../../../store/entities/onDemandPricing';
import { ConfirmationSectionTitle, RadioWrapper } from '../../onDemandPricing.styled';
import InfoTooltip from '../../../../../../sharedComponents/tooltip/infoTooltip';
import { UpdateGeoHierarchyAttributesMutationVariables } from '../../../../../../generated/voyager/graphql';

function OnDemandUpdateAttributesModal(props: ConfirmProps): ReactElement {
  const [productIdsToUpdate, setProductIdsToUpdate] = useState<string[]>([]);
  const [selectedAttribute, setSelectedAttribute] = useState<AttributeProps>(
    supportedGeoHierarchyAttributes[0],
  );
  const [attributeValue, setAttributeValue] = useState<string | null>(null);
  const [bulkAttributePopupOpen, setBulkAttributePopupOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const updateAttributes = useCallback(
    (payload: DataWithCallback<UpdateGeoHierarchyAttributesMutationVariables>) =>
      dispatch({
        type: UPDATE_GEO_HIERARCHY_ATTRIBUTES,
        payload,
      }),
    [dispatch],
  );
  const resetSelectedNodes = useCallback(
    () =>
      dispatch({
        type: RESET_SELECTED_NODES_ON_DEMAND,
      }),
    [dispatch],
  );

  const selectedNodes = useSelector(onDemandSelectedNodesSelector);
  const updateAttributesLoading = useSelector(updateGeoHierarchyAttributesLoadingSelector);
  const products = useSelector(onDemandPricingProductsSelector);

  const closeUpdateAttributesModal = () => {
    props.closeModal();
    resetSelectedNodes();
    setProductIdsToUpdate([]);
    setAttributeValue(null);
  };

  const updateAttributesForGeoGroupings = (geoGroupingIds: string[]) =>
    updateAttributes({
      data: {
        geoGroupingIds,
        productIds: productIdsToUpdate,
        label: selectedAttribute.label,
        newValue: attributeValue,
      },
      successCallback: closeBulkAttributePopupAndModal,
      failCallback: () => setBulkAttributePopupOpen(true),
    });

  const confirmUpdateAttributes = () => {
    trackAnalytics('On Demand - Update Attributes Button Click', {
      workflow: 'On Demand - Update Attributes',
      object_type: 'button',
      object_name: 'Update Attributes',
      object_value: selectedAttribute,
    });
    updateAttributesForGeoGroupings(selectedNodes.map(node => node.data.id));
  };

  const closeBulkAttributePopupAndModal = () => {
    setBulkAttributePopupOpen(false);
    closeUpdateAttributesModal();
  };

  let attributeValueInput;
  switch (selectedAttribute.type) {
    case 'boolean':
      attributeValueInput = (
        <RadioWrapper>
          <Radio
            label={'Yes'}
            name="attributeValue"
            checked={attributeValue === 'true'}
            onClick={() => setAttributeValue('true')}
          />
          <Radio
            label={'No'}
            name="attributeValue"
            checked={attributeValue === 'false'}
            onClick={() => setAttributeValue('false')}
          />
          <Radio
            label={'Empty (inherit from parent if any)'}
            name="attributeValue"
            checked={attributeValue === null}
            onClick={() => setAttributeValue(null)}
          />
        </RadioWrapper>
      );
      break;
    default:
      break;
  }

  return (
    <>
      <Confirm
        open={props.isOpen}
        onCancel={closeUpdateAttributesModal}
        onConfirm={confirmUpdateAttributes}
        cancelButton={{
          content: 'Cancel',
          className: 'cancel-confirmation',
          disabled: updateAttributesLoading,
        }}
        closeIcon
        confirmButton={{
          content: `Update attributes for ${pluralize(selectedNodes.length, 'item')}`,
          disabled: !selectedNodes.length || !productIdsToUpdate.length,
          loading: updateAttributesLoading,
        }}
        header={'Update attributes'}
        content={
          <div>
            <div>
              <ConfirmationSectionTitle>
                Select attribute:
                <InfoTooltip
                  popupContent={
                    <>
                      <p>{selectedAttribute.attributeInformation.summary}</p>
                      <ol>
                        {selectedAttribute.attributeInformation.details.map(detail => (
                          <li key={detail}>{detail}</li>
                        ))}
                      </ol>
                    </>
                  }
                />
              </ConfirmationSectionTitle>
              <Dropdown
                placeholder="No attributes available"
                selection
                search
                onChange={(_, data) => {
                  const newSelectedAttribute = supportedGeoHierarchyAttributes.find(
                    attr => attr.label === data.value,
                  );
                  if (newSelectedAttribute) {
                    setSelectedAttribute(newSelectedAttribute);
                  }
                }}
                selectOnBlur={false}
                options={supportedGeoHierarchyAttributes.map(attr => ({
                  key: attr.label,
                  text: attr.displayName,
                  value: attr.label,
                }))}
                value={selectedAttribute.label}
              />
            </div>
            <div>
              <ConfirmationSectionTitle>Select attribute value:</ConfirmationSectionTitle>
              {attributeValueInput}
            </div>
            <div>
              <ConfirmationSectionTitle>Select products:</ConfirmationSectionTitle>
              {products.map(product => (
                <div key={product.id}>
                  <Checkbox
                    className={'checkbox-top'}
                    label={product.name}
                    checked={productIdsToUpdate.includes(product.id)}
                    onChange={(_, data) =>
                      setProductIdsToUpdate(
                        data.checked
                          ? [...productIdsToUpdate, product.id]
                          : productIdsToUpdate.filter(id => id !== product.id),
                      )
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        }
      />
      <Confirm
        open={bulkAttributePopupOpen}
        onCancel={closeBulkAttributePopupAndModal}
        onConfirm={() => {
          const buildingIds = selectedNodes
            .flatMap(node => node.allLeafChildren)
            .filter(childNode => childNode.data.type === 'Building')
            .map(childNode => childNode.data.id);
          updateAttributesForGeoGroupings(buildingIds);
        }}
        cancelButton={{
          content: 'Cancel',
          className: 'cancel-confirmation',
          disabled: updateAttributesLoading,
        }}
        closeIcon
        confirmButton={{
          content: 'Yes',
          loading: updateAttributesLoading,
        }}
        header={'Set building attributes?'}
        content={
          <div>
            <p>
              {`Attribute cannot be set for the selected geo grouping(s) 
                    because you are not allowed to access some buildings in these grouping(s).`}
            </p>
            <br />
            <p>Do you want to set the attribute for each accessible building individually?</p>
          </div>
        }
      />
    </>
  );
}

export default OnDemandUpdateAttributesModal;
