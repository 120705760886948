import { Divider, Header } from 'semantic-ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import UuidTextArea from 'sharedComponents/uuidTextArea/uuidTextArea';
import {
  locationReconciliationLocationIdsSelector,
  locationReconciliationIsLoadingSelector,
  locationReconciliationLocationsSelector,
} from '../../store/modules/locationReconciliation/locationReconciliation.selector';
import {
  LOCATION_RECONCILIATION_SET_LOCATION_IDS,
  LOCATION_RECONCILIATION_SET_IS_LOCATION_IDS_VALID,
  LOCATION_RECONCILIATION_SET_LOCATION,
} from '../../store/modules/locationReconciliation/locationReconciliation.ducks';
import { LabelsWrapper, LocationSearch } from './locationReconciliation.styled';
import RemovableLabel from '../../../../sharedComponents/removeLabel/removeLabel';
import {
  currentLocationsItemLoadingState,
  locationsSelectorWithOpenDate,
} from '../../../../sharedStore/modules/locations/locations.selector';
import {
  CLEAR_LOCATIONS_STORE,
  FETCH_LOCATIONS,
} from '../../../../sharedStore/modules/locations/locations.ducks';
import { useDebounce } from '../../../pricing/standardPricing/components/helpers';

const LocationReconciliation = () => {
  const [locationSearch, setLocationSearch] = useState<string>('');
  const [locationSearchResult, setLocationSearchResult] = useState<any[]>([]);

  const isReconciliationLoading = useSelector(locationReconciliationIsLoadingSelector);
  const locationIds = useSelector(locationReconciliationLocationIdsSelector);
  const selectedLocations = useSelector(locationReconciliationLocationsSelector);
  const isLoading = useSelector(currentLocationsItemLoadingState);
  const allLocations = useSelector(locationsSelectorWithOpenDate);

  const dispatch = useDispatch();
  const handleLocationIdsValidation = useCallback(
    (isValid: boolean) =>
      dispatch({ type: LOCATION_RECONCILIATION_SET_IS_LOCATION_IDS_VALID, payload: isValid }),
    [dispatch],
  );

  const handleChangeLocationIdsTextArea = useCallback(
    (ids: string[]) => {
      dispatch({
        type: LOCATION_RECONCILIATION_SET_LOCATION_IDS,
        payload: ids,
      });
    },
    [dispatch],
  );

  const findLocationsByName = useCallback(
    (payload: string) => dispatch({ type: FETCH_LOCATIONS, payload }),
    [dispatch],
  );

  const addLocation = useCallback(
    (locationId: string) => {
      const selectedLocation = allLocations.find(location => location.id === locationId);
      const isNewLocation =
        selectedLocations.find(location => location.id === locationId) === undefined;
      if (selectedLocation && isNewLocation) {
        const newSelectedLocations = [...selectedLocations, selectedLocation];
        dispatch({
          type: LOCATION_RECONCILIATION_SET_LOCATION,
          payload: newSelectedLocations,
        });
        dispatch({ type: CLEAR_LOCATIONS_STORE });
        setLocationSearch('');
      }
    },
    [dispatch, allLocations, selectedLocations],
  );

  const removeLocation = useCallback(
    (locationId: string) => () => {
      const newSelectedLocations = selectedLocations.filter(location => location.id !== locationId);
      dispatch({
        type: LOCATION_RECONCILIATION_SET_LOCATION,
        payload: newSelectedLocations,
      });
    },
    [dispatch, selectedLocations],
  );

  const debouncedLocationSearch = useDebounce((value: string) => findLocationsByName(value), 500);
  const performLocationSearch = useCallback(
    (value: string) => {
      setLocationSearch(value);
      if (value) {
        debouncedLocationSearch(value);
      }
    },
    [setLocationSearch, debouncedLocationSearch],
  );

  useEffect(() => {
    setLocationSearchResult(
      allLocations.map(location => ({ title: location.name, id: location.id })),
    );
  }, [allLocations]);

  return (
    <>
      <LocationSearch
        fluid
        placeholder="Location search..."
        loading={isLoading}
        onResultSelect={(_e: any, data: any) => addLocation(data.result.id)}
        onSearchChange={(event: any) => performLocationSearch(event?.target?.value)}
        results={locationSearchResult}
        value={locationSearch}
        disabled={isReconciliationLoading}
      />
      <LabelsWrapper>
        {selectedLocations.map(location => (
          <RemovableLabel
            key={location.id}
            disabled={isReconciliationLoading}
            labelText={location?.name ?? ''}
            onDelete={removeLocation(location.id)}
          />
        ))}
      </LabelsWrapper>
      <Divider />
      <Header as="h5">Location IDs</Header>
      <UuidTextArea
        disabled={isReconciliationLoading}
        clear={isEmpty(locationIds)}
        handleValidation={useDebounce((value: boolean) => handleLocationIdsValidation(value), 500)}
        handleChange={useDebounce((value: string[]) => handleChangeLocationIdsTextArea(value), 500)}
      />
    </>
  );
};

export default LocationReconciliation;
