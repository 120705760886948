import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@wework/ray2';
import { Dock } from 'react-dock';
import {
  CLEAR_STANDARD_PRICING_FILTER_PARAMS,
  MODIFY_STANDARD_PRICING_FILTER_PARAMS,
} from '../../../store/modules/standardPricingFilters/standardPricingFilters.ducks';
import {
  ClosePanelButton,
  PanelBody,
  PanelFilterList,
  PanelHeader,
  PanelTitle,
  SidePanelWrapper,
  PanelFooter,
} from '../../../../../../styles/app.styled';
import { standardPricingFiltersSelector } from '../../../store/modules/standardPricingFilters/standardPricingFilters.selector';
import SkuFilterShared from '../../../../../../sharedComponents/filterComponents/skuFilterShared';
import YesNoFilterShared from '../../../../../../sharedComponents/filterComponents/yesNoFilterShared';
import MinMaxFilterShared from '../../../../../../sharedComponents/filterComponents/minMaxFilterShared';
import UserActionFiltersPricing from '../../../../../../sharedComponents/filterComponents/userActionFiltersPricing';

interface StandardPricingFilterSidePanelProps {
  isVisible: boolean;
  visibilityHandler: Function;
}
function StandardPricingFilterSidePanel({
  isVisible,
  visibilityHandler,
}: StandardPricingFilterSidePanelProps): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  const clearFilterParams = useCallback(
    () => dispatch({ type: CLEAR_STANDARD_PRICING_FILTER_PARAMS }),
    [dispatch],
  );

  // STATE
  const [localSidePanel, setLocalSidePanel] = useState<boolean>(isVisible);
  const [saveFilters, setSaveFilters] = useState<boolean>(false);
  const [clearFilters, setClearFilters] = useState<boolean>(false);

  useEffect(() => setLocalSidePanel(isVisible), [isVisible]);

  /**
   * Just close the side panel
   */
  const closeSidePanel = () => visibilityHandler();

  /**
   * Clear all filters and close the side panel
   */
  const clearAndCloseSidePanel = () => {
    clearFilterParams();
    setClearFilters(true);
    closeSidePanel();
  };

  /**
   * apply all filters and close side panel
   */
  const applyFilters = () => {
    setSaveFilters(true);
    closeSidePanel();
  };

  return (
    <SidePanelWrapper>
      <Dock
        position="right"
        isVisible={localSidePanel}
        dimMode="none"
        fluid={false}
        size={320}
        zIndex={100}
      >
        {/* you can pass a function as a child here */}
        <ClosePanelButton type="button" className="close" onClick={closeSidePanel} />
        <PanelHeader>
          <PanelTitle>Filters</PanelTitle>
        </PanelHeader>
        <PanelBody>
          <PanelFilterList>
            <UserActionFiltersPricing
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              resetSaveFilters={() => setSaveFilters(false)}
              resetClearFilters={() => setClearFilters(false)}
              modifyFiltersAction={MODIFY_STANDARD_PRICING_FILTER_PARAMS}
              filtersSelector={standardPricingFiltersSelector}
            />
            <SkuFilterShared
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              resetSaveFilters={() => setSaveFilters(false)}
              resetClearFilters={() => setClearFilters(false)}
              modifyFiltersAction={MODIFY_STANDARD_PRICING_FILTER_PARAMS}
              filtersSelector={standardPricingFiltersSelector}
            />
            <YesNoFilterShared
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              resetSaveFilters={() => setSaveFilters(false)}
              resetClearFilters={() => setClearFilters(false)}
              modifyFiltersAction={MODIFY_STANDARD_PRICING_FILTER_PARAMS}
              filtersSelector={standardPricingFiltersSelector}
            />
            <MinMaxFilterShared
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              resetSaveFilters={() => setSaveFilters(false)}
              resetClearFilters={() => setClearFilters(false)}
              modifyFiltersAction={MODIFY_STANDARD_PRICING_FILTER_PARAMS}
              filtersSelector={standardPricingFiltersSelector}
            />
          </PanelFilterList>
        </PanelBody>
        <PanelFooter>
          <ul>
            <li>
              <Button
                theme={'outline'}
                size={'medium'}
                className="clear-all-button"
                onClick={() => clearAndCloseSidePanel()}
              >
                Clear all
              </Button>
            </li>
            <li>
              <Button
                theme={'fill'}
                size={'medium'}
                className="apply-all-button"
                onClick={() => applyFilters()}
              >
                Apply filters
              </Button>
            </li>
          </ul>
        </PanelFooter>
      </Dock>
    </SidePanelWrapper>
  );
}

export default StandardPricingFilterSidePanel;
