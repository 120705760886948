import styled from 'styled-components';
import { FlexHorizontalHelper, ListReset } from '../../../../styles/app.styled';
import {
  blue,
  darkBlue,
  darkGray,
  lighterGray,
  lightestGray,
  pureBlack,
} from '../../../../styles/colors';

export const ModalSubTitle = styled.h4`
  display: block;
  padding: 0;
  margin: 0 0 14px 0;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
  color: ${darkBlue};
  &.no-indent {
    margin: 0;
  }
  &.inline-subtitle {
    display: inline;
    float: left;
  }
  &.edit-subtitle {
    vertical-align: top;
    margin-right: 55px;
  }
  &.edit-subtitle-name {
    vertical-align: top;
    margin-right: 125px;
  }
  &.float-left {
    float: left;
  }
`;
export const ModalTitle = styled.h1`
  display: block;
  padding: 0;
  margin: 0 0 14px 0;
  line-height: 32px;
  font-size: 32px;
  font-weight: 500;
  color: ${darkBlue};
  &.no-indent {
    margin: 0;
  }
`;
export const ModalSubTitleDatePicker = styled.h4`
  display: inline-block;
  float: left;
  padding: 0;
  margin: 10px 0 !important;
  line-height: 17px;
  font-size: 14px;
  font-weight: 500;
  color: ${darkBlue};
`;
export const ModalContentWrapper = styled.div`
  padding: 40px;
  &.curve-management-modal {
    padding: 0;
  }
`;
export const BaseOverrideRow = styled.ol`
  width: 100%;
  height: 40px;
  ${ListReset};
  ${FlexHorizontalHelper};
  padding: 12px 8px 12px 20px;
  background: ${lightestGray};
  &.white-background {
    background: white;
  }
  &.extra-height {
    height: 70px;
    padding: 0 !important;
  }
  margin: 10px 0 0;
  > li {
    flex: 0 0 55px;
    &.larger-gap {
      flex: 0 0 82px;
    }
    &.smaller-gap {
      flex: 0 0 20px;
    }
    &.smaller-margin {
      margin: 0 8px 0 0;
    }
    &.larger-margin {
      margin: 0 25px 0 0;
    }
    .ui.checkbox input.hidden {
      z-index: 1;
    }
    .ui.checkbox input[type='checkbox'] {
      top: 4px;
      opacity: unset !important;
    }
    height: 16px;
    line-height: 16px;
    margin: 0 14px 0 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: ${pureBlack};
    &:first-of-type {
      flex: 1 1 auto;
      text-align: left;
      margin: 0;
    }
    &:last-of-type {
      margin: 0;
    }
  }
  &:first-of-type > li {
    color: ${darkGray};
  }
  &:last-of-type {
    height: 46px;
    padding: 8px 8px 8px 20px;
    > li {
      height: 30px;
      line-height: 30px;
    }
  }
`;
export const BaseOverrideRowModified = styled.ol`
  width: 100%;
  height: 70px;
  ${ListReset};
  ${FlexHorizontalHelper};
  flex-direction: column;
  padding: 0 !important;
  background: ${lightestGray};
  &.white-background {
    background: white;
  }
  max-width: 850px;
  &.short {
    height: 20px !important;
  }
  margin: 10px 0 0;
  > li {
    flex: 0 0 55px;
    height: 16px;
    line-height: 16px;
    margin: 0 14px 0 0;
    font-size: 14px;
    font-weight: 500;
    color: ${pureBlack};
    &:first-of-type {
      flex: 1 1 auto;
      text-align: left;
      margin: 0;
    }
    &:last-of-type {
      margin: 14px 0 0 0;
    }
    > .relative > textarea {
      padding: 0.5rem 0.75rem;
      resize: none;
      width: 300px;
      border: 1px solid #b0b0b0;
      margin: 0;
      outline: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      text-align: left;
      font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
      background: #fff;
      color: #001e33;
      border-radius: 0.25rem;
      transition: box-shadow 0.1s ease, border-color 0.1s ease;
      box-shadow: none;
    }
    > .relative > input {
      padding: 0.5rem 0.75rem;
      resize: none;
      border: 1px solid #b0b0b0;
      margin: 0;
      outline: none;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      text-align: left;
      font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
      background: #fff;
      color: #001e33;
      border-radius: 0.25rem;
      transition: box-shadow 0.1s ease, border-color 0.1s ease;
      box-shadow: none;
    }
    > div.ui.checkbox {
      &.checkbox-margin {
        margin: 8px 0 0 25px;
        > input {
          top: 4px;
        }
      }
    }
    > div.ui.search.dropdown {
      margin-left: 20px;
      width: 300px;
      .ui.corner.label {
        height: auto;
        width: auto;
      }
    }
  }
  &:first-of-type > li {
    color: ${darkGray};
  }
  &:last-of-type {
    height: 80px;
    padding: 8px 8px 8px 20px;
    &.height-flex {
      height: 100px;
    }
    > li {
      &.top-align {
        justify-content: left;
        text-align: left;
        margin-top: -75px;
        margin-left: 400px;
      }
      &.top-align-small {
        justify-content: left;
        text-align: left;
        margin-top: 5px;
        flex: 0 0 auto;
      }
      height: 75px;
      line-height: 30px;
    }
  }
`;
export const OverrideBox = styled.div`
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 1px 0 0;
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(102, 102, 102, 0.25);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-weight: 400;
  color: ${darkGray};
  &.bottom-margin {
    margin-bottom: 8px;
  }
  &.disabled-box {
    background: lightgray;
  }
  > input {
    display: inline-block;
    width: 32px;
    min-width: 32px;
    height: 28px;
    line-height: 28px;
    padding: 0 1px 0 0;
    background: white;
    border-radius: 4px;
    border: 0;
    margin: 0;
    float: left;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    color: ${blue};
    &.init-color {
      color: black;
    }
    &.curve-input {
      width: 36px;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      background: lightgray;
    }
  }
  > span {
    display: inline-block;
    float: left;
    width: 14px;
    line-height: 16px;
    padding: 7px 0 0 0;
  }
  > .filled + span {
    color: ${blue};
  }
  &::after {
    display: block;
    content: '';
    clear: both;
  }
`;
export const OverrideDisplayBox = styled.div`
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 0 1px 0 0;
  background: white;
  font-size: 14px;
  font-weight: 400;
  color: ${darkGray};
  &.bottom-margin {
    margin-bottom: 8px;
  }
  > span {
    display: inline-block;
    float: left;
    width: 14px;
    line-height: 16px;
    padding: 7px 0 0 0;
  }
  > .filled + span {
    color: ${blue};
  }
  &::after {
    display: block;
    content: '';
    clear: both;
  }
`;
export const ModalContentWrapperManageCurve = styled.div`
  ${FlexHorizontalHelper};
  &.curve-management-modal-details {
    height: 325px;
  }
`;
export const ContentColumnManageCurve = styled.div`
  flex: 0 0 40%;
  padding: 40px;
  &.small-bottom-interval {
    padding-bottom: 10px;
  }
  &.small-top-interval {
    padding-top: 10px;
  }
  &:first-of-type {
    flex: 0 0 60%;
    border-right: 1px solid ${lighterGray};
  }
  &.scroll {
    height: 100%;
    max-height: 413px;
    padding-right: 24px;
    overflow-y: auto;
  }
  > table {
    overflow-x: scroll;
    .no-wrap {
      white-space: nowrap;
    }
  }
  &.discount-curve {
    flex: 0 0 100%;
  }
  &.discount-curve-title {
    flex: 0 0 25%;
    padding: 0;
  }
`;

/* BASE OVERRIDE COMPONENT */
export const BaseOverrideWrapper = styled.div`
  margin: 0 0 26px;
`;
export const SpanWidth = styled.span`
  margin-left: 100px;
  max-width: 250px;
  overflow-wrap: break-word;
  display: inline-block;
  &.small {
    margin-left: 56px;
    word-wrap: break-word;
  }
`;
