import { Header } from 'semantic-ui-react';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import UuidTextArea from 'sharedComponents/uuidTextArea/uuidTextArea';
import {
  inventoryReconciliationInventoryIdsSelector,
  inventoryReconciliationIsLoadingSelector,
} from '../../store/modules/inventoryReconciliation/inventoryReconciliation.selector';
import {
  INVENTORY_RECONCILIATION_SET_INVENTORY_IDS,
  INVENTORY_RECONCILIATION_SET_IS_INVENTORY_IDS_VALID,
} from '../../store/modules/inventoryReconciliation/inventoryReconciliation.ducks';
import { useDebounce } from '../../../pricing/standardPricing/components/helpers';

const InventoryReconciliation = () => {
  const isReconciliationLoading = useSelector(inventoryReconciliationIsLoadingSelector);
  const inventoryIds = useSelector(inventoryReconciliationInventoryIdsSelector);

  const dispatch = useDispatch();
  const handleInventoryIdsValidation = useCallback(
    (isValid: boolean) =>
      dispatch({ type: INVENTORY_RECONCILIATION_SET_IS_INVENTORY_IDS_VALID, payload: isValid }),
    [dispatch],
  );

  const handleChangeInventoryIdsTextArea = useCallback(
    (ids: string[]) => {
      dispatch({
        type: INVENTORY_RECONCILIATION_SET_INVENTORY_IDS,
        payload: ids,
      });
    },
    [dispatch],
  );

  return (
    <>
      <Header as="h5">Inventory IDs</Header>
      <UuidTextArea
        disabled={isReconciliationLoading}
        clear={isEmpty(inventoryIds)}
        handleValidation={useDebounce((value: boolean) => handleInventoryIdsValidation(value), 500)}
        handleChange={useDebounce(
          (value: string[]) => handleChangeInventoryIdsTextArea(value),
          500,
        )}
      />
    </>
  );
};

export default InventoryReconciliation;
