import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button } from '@wework/ray2';
import { Modal, Input } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray, isEmpty } from 'lodash';
import cn from 'classnames';
import { ContentColumn, ModalSubTitle, ModalVerticalContentWrapper } from './discountCurves.styled';
import {
  curvesSelector,
  discountItemsSubsetSelectorStore,
  mutateCurvesLoadingSelector,
} from '../../store/modules/discountItems/discountItems.selector';
import {
  APPLY_CURVES,
  CLEAR_CURVES,
  FIND_CURVES,
  REMOVE_CURVES,
} from '../../store/modules/discountItems/discountItems.ducks';
import { useDebounce } from '../../../pricing/standardPricing/components/helpers';
import CurvesSearchTable from './curvesSearchTable';
import { TextLink } from '../../../breakeven/components/breakevenTable/breakevenTableAGGrid.styled';
import DiscountCurvesConfirmationModal from './discountCurvesConfirmationModal';
import { DataWithCallback } from '../../../../utils/sharedTypes';
import { ModalTitle } from '../discountsOverride/discountsOverride.styled';
import { SummaryNote } from '../../../pricing/standardPricing/components/pricingPublish/pricingPublish.styled';
import {
  Curve,
  DiscountCurveInput,
  MutationRemoveCurvesArgs,
  QueryFindCurvesArgs,
} from '../../../../generated/voyager/graphql';

export interface CurveWarnings {
  curveName: string;
  curveId: any;
  curveWarnings: string[];
}

interface DiscountCurvesModalProps {
  closeModal: () => void;
  priceableItemIds: string[];
  filterCurveIds?: string[];
  hideRemove?: boolean;
}

function DiscountCurvesModal({
  closeModal,
  priceableItemIds,
  filterCurveIds,
  hideRemove,
}: DiscountCurvesModalProps): ReactElement {
  const allDiscountItems = useSelector(discountItemsSubsetSelectorStore) ?? [];
  const mutateCurvesLoading = useSelector(mutateCurvesLoadingSelector);

  const [selectedCurves, setSelectedCurves] = useState<Curve[]>([]);
  const [applyCurvesWarnings, setApplyCurvesWarnings] = useState<CurveWarnings[]>([]);
  const [removeCurvesWarnings, setRemoveCurvesWarnings] = useState<CurveWarnings[]>([]);
  const [openIds, setOpenIds] = useState<string[]>([]);

  const allCurves = useSelector(curvesSelector);
  const curves = filterCurveIds
    ? allCurves.filter(curve => !filterCurveIds.includes(curve.id))
    : allCurves;

  const modifyExpandedArr = (curveId: string) => {
    const termIdx = openIds.findIndex(id => id === curveId) ?? -1;
    if (termIdx > -1) {
      const modExpandedArr = [...openIds];
      modExpandedArr.splice(termIdx, 1);
      setOpenIds(modExpandedArr);
    } else {
      setOpenIds([...openIds, curveId]);
    }
  };

  const dispatch = useDispatch();
  const findCurves = useCallback(
    (payload: QueryFindCurvesArgs) => dispatch({ type: FIND_CURVES, payload }),
    [dispatch],
  );
  const clearCurves = useCallback(() => dispatch({ type: CLEAR_CURVES }), [dispatch]);
  const applyCurvesAction = useCallback(
    (payload: DataWithCallback<{ input: DiscountCurveInput }>) =>
      dispatch({ type: APPLY_CURVES, payload }),
    [dispatch],
  );
  const removeCurvesAction = useCallback(
    (payload: DataWithCallback<MutationRemoveCurvesArgs>) =>
      dispatch({ type: REMOVE_CURVES, payload }),
    [dispatch],
  );

  const debouncedCurvesSearch = useDebounce((text: string) => findCurves({ text }), 500);

  const changeCurveSelection = (curve: Curve | Curve[], selected?: boolean) => {
    if (selected) {
      (isArray(curve) ? curve : [curve]).forEach(curveToAdd => {
        if (!selectedCurves.find(selectedCurve => selectedCurve.id === curveToAdd.id)) {
          setSelectedCurves(selectedCurves.concat(curve));
        }
      });
    } else {
      setSelectedCurves(
        selectedCurves.filter(selectedCurve =>
          isArray(curve)
            ? !curve.find(c => c.id === selectedCurve.id)
            : curve.id !== selectedCurve.id,
        ),
      );
    }
  };

  const applyCurves = () => {
    applyCurvesAction({
      data: {
        input: {
          priceableItemIds,
          curveIds: selectedCurves.map(curve => curve.id),
        },
      },
      successCallback: closeModal,
    });

    if (applyCurvesWarnings.length > 0) {
      setApplyCurvesWarnings([]);
    }
  };

  const validateAndApplyCurves = () => {
    const validationMessages: CurveWarnings[] = [];

    priceableItemIds.forEach(priceableItemId => {
      const item = allDiscountItems.find(i => i.id === priceableItemId);
      if (!item) {
        return;
      }
      selectedCurves.forEach(curve => {
        const wrongOperator = !item.location.operators.find(o => o.id === curve.operator.id);
        const alreadyApplied = item.appliedCurves.find(c => c.curve.id === curve.id);

        if (wrongOperator || alreadyApplied) {
          // Trying to find if the curveWarning object already exists else creating new curveWarning.
          let curveWarningObj = validationMessages.find(
            eachCurve => eachCurve.curveId === curve.id,
          );
          if (!curveWarningObj) {
            curveWarningObj = {
              curveId: curve.id,
              curveName: curve.name,
              curveWarnings: [],
            };
            validationMessages.push(curveWarningObj);
          }

          if (wrongOperator) {
            curveWarningObj.curveWarnings.push(
              `Curve with operator ${
                curve.operator.name
              } cannot be applied to location with operator(s) [${item.location.operators
                .map(o => o.name)
                .join(', ')}]`,
            );
          }
          if (alreadyApplied) {
            curveWarningObj.curveWarnings.push(`Curve already applied to reservable ${item.name}`);
          }
        }
      });
    });

    if (validationMessages.length > 0) {
      setApplyCurvesWarnings(validationMessages);
    } else {
      applyCurves();
    }
  };

  const removeCurves = () => {
    removeCurvesAction({
      data: {
        input: {
          priceableItemIds,
          curveIds: selectedCurves.map(curve => curve.id),
        },
      },
      successCallback: closeModal,
    });

    if (removeCurvesWarnings.length > 0) {
      setRemoveCurvesWarnings([]);
    }
  };

  const validateAndRemoveCurves = () => {
    const validationMessages: CurveWarnings[] = [];

    priceableItemIds.forEach(priceableItemId => {
      const item = allDiscountItems.find(i => i.id === priceableItemId);
      if (!item) {
        return;
      }
      selectedCurves.forEach(curve => {
        if (!item.appliedCurves.find(c => c.curve.id === curve.id)) {
          // Trying to find if the curveWarning object already exists else creating new curveWarning.
          let curveWarningObj = validationMessages.find(
            eachCurve => eachCurve.curveId === curve.id,
          );
          if (!curveWarningObj) {
            curveWarningObj = {
              curveId: curve.id,
              curveName: curve.name,
              curveWarnings: [],
            };
            validationMessages.push(curveWarningObj);
          }
          curveWarningObj.curveWarnings.push(`Curve not applied to reservable ${item.name}`);
        }
      });
    });

    if (validationMessages.length > 0) {
      setRemoveCurvesWarnings(validationMessages);
    } else {
      removeCurves();
    }
  };

  useEffect(
    () => () => {
      clearCurves();
    },
    [clearCurves],
  );

  return (
    <Modal open className="discount-curves-modal" onClose={closeModal} closeOnEscape={false}>
      <Modal.Content>
        <ModalVerticalContentWrapper>
          <ContentColumn className="scroll small-bottom-interval">
            <div>
              <SummaryNote className="info no-indent">Manage Discount Curves</SummaryNote>
              <ModalTitle>{priceableItemIds.length} Reservables</ModalTitle>
              <ModalSubTitle className="no-indent">Select curves</ModalSubTitle>
              <Input
                className="fixed-width"
                icon="search"
                placeholder="Search..."
                onChange={(event: any) =>
                  event?.target?.value ? debouncedCurvesSearch(event?.target?.value) : clearCurves()
                }
              />
              <TextLink
                className={cn({ hide: !curves.length }, 'margin-left')}
                onClick={() => setOpenIds([...(curves.map(eCurve => eCurve.id) ?? [])])}
              >
                Expand all rows
              </TextLink>
              <TextLink
                className={cn({ hide: !curves.length }, 'margin-left')}
                onClick={() => setOpenIds([])}
              >
                Collapse all rows
              </TextLink>
            </div>
            <CurvesSearchTable
              changeCurveSelection={changeCurveSelection}
              selectedCurveIds={selectedCurves.map(curve => curve.id)}
              filterCurveIds={filterCurveIds}
              openIds={openIds}
              modifyExpandedArr={modifyExpandedArr}
            />
          </ContentColumn>
        </ModalVerticalContentWrapper>
      </Modal.Content>
      <Modal.Actions>
        {!hideRemove && (
          <Button
            theme={'outline'}
            size={'medium'}
            className="align-left"
            onClick={validateAndRemoveCurves}
            loading={mutateCurvesLoading}
            disabled={isEmpty(selectedCurves)}
          >
            Remove {selectedCurves.length ? `${selectedCurves.length} Curves` : ''}
          </Button>
        )}
        <Button
          size={'medium'}
          theme={'danger'}
          className={'ml-sm'}
          onClick={() => closeModal()}
          disabled={mutateCurvesLoading}
        >
          Cancel
        </Button>
        <Button
          theme={'fill'}
          size={'medium'}
          className={'ml-sm'}
          onClick={validateAndApplyCurves}
          loading={mutateCurvesLoading}
          disabled={isEmpty(selectedCurves)}
        >
          Apply {selectedCurves.length ? `${selectedCurves.length} Curves` : ''}
        </Button>
      </Modal.Actions>
      <DiscountCurvesConfirmationModal
        header={'Some curves will not be applied:'}
        warnings={applyCurvesWarnings}
        loading={mutateCurvesLoading}
        onCancel={() => setApplyCurvesWarnings([])}
        onConfirm={applyCurves}
      />
      <DiscountCurvesConfirmationModal
        header={'Some curves will not be removed:'}
        warnings={removeCurvesWarnings}
        loading={mutateCurvesLoading}
        onCancel={() => setRemoveCurvesWarnings([])}
        onConfirm={removeCurves}
      />
    </Modal>
  );
}

export default DiscountCurvesModal;
