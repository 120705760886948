import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import {
  CLEAR_BREAKEVEN_ITEMS,
  CLEAR_MARKET_SG_AND_A,
} from 'app/breakeven/store/modules/breakevenItems/breakevenItems.ducks';
import { AppLayout, MainRegionStyled, OverflowHack } from '../styles/app.styled';
import HeaderComponent from '../sharedComponents/header/header';
import MultipleLocationSearchComponent from '../sharedComponents/multipleLocationSearch/multipleLocationSearch';
import BottomPanelComponentSearch from '../sharedComponents/multipleLocationSearch/BottomPanelComponentSearch';
import { MultipleLocationSearchProps } from '../utils/sharedTypes';
import { STOP_FETCH_DISCOUNT_ITEMS } from '../app/discounts/store/modules/discountItems/discountItems.ducks';
import {
  CLEAR_STANDARD_PRICING_STORE,
  STOP_FETCH_STANDARD_PRICING_ITEMS,
} from '../app/pricing/standardPricing/store/modules/standardPricingItems/standardPricingItems.ducks';
import { AuthzProps } from '../utils/constants';
import ROUTES from '../utils/routes/routes';
import currentPathNameSelector from '../sharedStore/modules/router/router.selector';
import { CLEAR_LOCATIONS_STORE } from '../sharedStore/modules/locations/locations.ducks';
import EmployeePermissions from '../utils/store/permissions';

function MultipleLocationSearchHome(props: MultipleLocationSearchProps & AuthzProps): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  const cancelBackgroundFetch = useCallback(() => {
    dispatch({ type: STOP_FETCH_DISCOUNT_ITEMS });
    dispatch({ type: STOP_FETCH_STANDARD_PRICING_ITEMS });
  }, [dispatch]);

  const resetBreakevenStates = useCallback(() => {
    dispatch({ type: CLEAR_BREAKEVEN_ITEMS });
    dispatch({ type: CLEAR_MARKET_SG_AND_A });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );
  const location = useSelector(currentPathNameSelector);

  const permissionChecker = () => {
    const requestedPermissions = props.requestedPermissions;
    if (
      location.pathname === ROUTES.PRICING &&
      !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_view, false)
    ) {
      redirectToAccessDenied();
    } else if (
      location.pathname === ROUTES.BREAKEVEN &&
      !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_breakeven_view, false)
    ) {
      redirectToAccessDenied();
    } else if (
      location.pathname === ROUTES.DISCOUNT &&
      !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_discount_view, false)
    ) {
      redirectToAccessDenied();
    }
  };

  useEffect(permissionChecker);

  const resetReferrerHelper = () => {
    resetBreakevenStates();
    cancelBackgroundFetch();

    // BC: BUG FIX for fast switching screen and no data loaded on grid.
    // Will look better when we remove the store completely
    return () => {
      dispatch({ type: CLEAR_STANDARD_PRICING_STORE });
      dispatch({ type: CLEAR_LOCATIONS_STORE });
    };
  };

  useEffect(resetReferrerHelper, []);

  return (
    <AppLayout>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent title={props.title} />
          <MultipleLocationSearchComponent
            allowDifferentCurrencies={props.allowDifferentCurrencies}
          />
        </OverflowHack>
      </MainRegionStyled>
      <BottomPanelComponentSearch {...props} />
    </AppLayout>
  );
}

export default withRequestedAuthz<MultipleLocationSearchProps & AuthzProps>({
  permissions: [
    EmployeePermissions.voyager_dedicated_space_price_view,
    EmployeePermissions.voyager_dedicated_space_breakeven_view,
    EmployeePermissions.voyager_dedicated_space_discount_view,
  ],
})(MultipleLocationSearchHome);
