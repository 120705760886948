import * as React from 'react';
import ReactDOM from 'react-dom';
import '@wework/dieter-ui/dist/index.css';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { ApolloProvider } from '@apollo/client';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { LicenseManager } from 'ag-grid-enterprise';
import Rollbar from 'rollbar';
import App from './commonPages/app';
import { createClientFactory } from './utils/graphql/createClient';
import {
  graphqlBatchClientOptions,
  graphqlClientOptions,
} from './utils/graphql/constants/graphql.constants';
import configureStore, { routerHistory, sagaMiddleware } from './utils/store/configureStore';
import rootSaga from './utils/store/rootSaga';
import { LOGOUT_REQUEST } from './sharedStore/modules/auth/auth.ducks';
import { tokenSelector } from './sharedStore/modules/auth/auth.selector';
import AppConfig from './config';
import './styles/app.css';
import { Unassigned } from './utils/sharedTypes';
import ErrorBoundary from './utils/errorHandling/errorBoundary';

// Configuring rollbar
const ROLLBAR_ENABLED_ENVS = new Set(['production', 'staging', 'uat']);

const rollbarConfig = {
  accessToken: AppConfig.ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: ROLLBAR_ENABLED_ENVS.has(AppConfig.APP_ENV || ''),
  payload: {
    environment: AppConfig.APP_ENV,
    version: AppConfig.APP_REVISION,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: AppConfig.APP_REVISION,
        // Optionally have Rollbar guess which frames the error was thrown from
        // when the browser does not provide line and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
};

window.Rollbar = new Rollbar(rollbarConfig);
// END of rollbar configuration

LicenseManager.setLicenseKey(AppConfig.AG_GRID_LICENSE_KEY ?? '');

export const graphQLClient = createClientFactory(graphqlClientOptions, graphqlBatchClientOptions);

const store = configureStore();

sagaMiddleware.run(rootSaga);

export default store;

// Creating a new auth0 Client.
export const auth0 = new Auth0Client({
  domain: AppConfig.AUTH0_DOMAIN ?? '',
  clientId: AppConfig.AUTH0_CLIENT_ID ?? '',
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: `${window.location.origin}/callback`,
    audience: 'wework',
  },
});
// Adding logout functionality globally.
export const logoutHelper = (): { type: string } => store.dispatch({ type: LOGOUT_REQUEST });
// Adding get current auth and id token globally.
export const getAuthToken = (): string | Unassigned => tokenSelector(store.getState());

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={routerHistory}>
        <ApolloProvider client={graphQLClient}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ApolloProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') || document.createElement('div'),
);
