// GraphQL Queries
import { gql } from '@apollo/client';

export const FETCH_STANDARD_PRICING_ITEMS_QUERY = gql`
  query StandardPricingItems(
    $page: PageInfo!
    $filter: PriceableItemFilter
    $priceFilter: PriceFilter
  ) {
    priceableItems(page: $page, filter: $filter) {
      id
      name
      attributes {
        label
        value
      }
      currentPrice {
        price
        publishedAt
        validFrom
        currencyIsoCode
      }
      workingPrice {
        id
        price
        lastChangeReason
      }
      prices(filter: $priceFilter) {
        id
        price
        publishedAt
        validFrom
        createdBy
      }
      recommendedPrice {
        price
      }
      metrics {
        reservationPriceLocal
        isOccupied
        monthsVacant
      }
      location {
        id
      }
    }
  }
`;

export const GET_TOTAL_PRICEABLE_ITEMS_QUERY = gql`
  query TotalPriceableItems($filter: PriceableItemFilter) {
    totalPriceableItems(filter: $filter)
  }
`;
