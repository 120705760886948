import {
  CellClassParams,
  GridReadyEvent,
  IAggFuncParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { isEmpty, isNil } from 'lodash';
import { pluralize } from 'apollo/lib/utils';
import { GetRowIdFunc } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { GetRowIdParams } from 'ag-grid-community/dist/lib/interfaces/iCallbackParams';
import {
  isPercentageNull,
  roundToAtMost2Digit,
  toTitleCase,
} from '../../../pricing/standardPricing/components/helpers';
import { TableViewDiscountColumn } from '../../store/entities/discountItems';
import { formatDate, intlCurrencyFormatter } from '../../../../utils/helpers';

/**
 * Fit columns when grid size changed
 */
export const onGridSizeChanged = (params: GridReadyEvent): void => {
  if (params.columnApi) {
    params.columnApi.autoSizeAllColumns();
  }
};

export const getRowId: GetRowIdFunc = (data: GetRowIdParams): string => data.data?.id;

export const avgAggregateFunc = (params: IAggFuncParams): number | null => {
  const values: number[] = params.values;
  if (isEmpty(values) || values?.every(value => isNil(value))) {
    return null;
  }

  let sum: number = 0;
  let count: number = 0;
  values.forEach(eachValue => {
    if (eachValue) {
      sum += eachValue;
      count += 1;
    }
  });
  return roundToAtMost2Digit(sum / count);
};

export const getlowestNetPricevalue = (params: ValueFormatterParams): number | string => {
  const currencyIsoCode = params.data
    ? params.data.currencyIsoCode
    : params.node?.allLeafChildren[0]?.data.currencyIsoCode;

  return params.value && params.value.value
    ? intlCurrencyFormatter(currencyIsoCode, params.value.value)
    : '-';
};

export const getCurrencyFormattedPrice = (params: ValueFormatterParams): string => {
  const currencyIsoCode = params.data
    ? params.data.currencyIsoCode
    : params.node?.allLeafChildren[0]?.data.currencyIsoCode;

  return params.value ? intlCurrencyFormatter(currencyIsoCode, Number(params.value)) : '-';
};

export const getStringOfTermNames = (params: ValueFormatterParams): string =>
  params.value
    ? `${params.value.map((e: { term: { label: any } }) => e.term.label).join(', ')}`
    : '-';

export const isOccupiedVF = (params: ValueFormatterParams): string =>
  params.value ? 'Occupied' : '';

export const percentageVF = (params: ValueFormatterParams): string =>
  isPercentageNull(params.value);

export const numberVF = (params: ValueFormatterParams): number | string =>
  params.value ? roundToAtMost2Digit(params.value) : '-';

export const numberVFCurrency = (params: ValueFormatterParams): number | string => {
  const currencyIsoCode = params.data
    ? params.data.currencyIsoCode
    : params.node?.allLeafChildren[0]?.data.currencyIsoCode;

  return params.value ? intlCurrencyFormatter(currencyIsoCode, params.value) : '-';
};

export const dateToStringVF = (params: ValueFormatterParams): string => {
  if (!params.value) {
    return '-';
  }

  return formatDate(params.value);
};

export const toTitleCaseVF = (params: ValueFormatterParams): string => {
  if (!params.value) {
    return '';
  }

  return toTitleCase(params.value);
};

/**
 * Helper util to get the discount for cell. In expanded and collapsed discount views.
 *
 * @param params Parameter passed by the grid with which we can access data.
 * @param valueType Specifies the type of value needed.
 */
const discountValueGetterHelper = (params: ValueGetterParams, valueType: string): number | null => {
  const field = params.colDef.field;

  if (field) {
    const columnData: TableViewDiscountColumn = params.data[field];

    if (columnData) {
      const baseDiscount: number | null = columnData.baseOrOverride;
      const marketPrice: number = params.data.marketPrice;

      // Jira Task with formulas - https://jira.weworkers.io/browse/RO-1842
      switch (valueType) {
        case 'percent':
          return baseDiscount;
        case 'value':
          return baseDiscount ? baseDiscount * marketPrice : null;
        case 'steady':
          return baseDiscount ? (1 - baseDiscount) * marketPrice : null;
        default:
          return null;
      }
    }

    return null;
  }

  return null;
};

export const discountPercentageVG = (params: ValueGetterParams): number | null =>
  discountValueGetterHelper(params, 'percent');
export const discountValueVG = (params: ValueGetterParams): number | null =>
  discountValueGetterHelper(params, 'value');
export const discountSteadyVG = (params: ValueGetterParams): number | null =>
  discountValueGetterHelper(params, 'steady');

export const discountCurveVG = (params: ValueGetterParams): string | null => {
  if (params.data?.curvesApplied > 0) {
    return `${pluralize(params.data.curvesApplied, 'curve')}`;
  }
  return null;
};

const overrideDiscountCellClassHelper = (params: CellClassParams): boolean => {
  let isOverridden: boolean = false;

  const field = params.colDef?.field;
  if (field) {
    const columnData: TableViewDiscountColumn = params.data ? params.data[field] : null;
    if (columnData) {
      isOverridden = columnData.isBaseDiscountOverridden;
    }
  }

  return isOverridden;
};

export const overrideDiscountCellClass = {
  override: (params: CellClassParams): boolean => overrideDiscountCellClassHelper(params),
};

export const truncateCellValue =
  (fontSize: number, rowHeight: number) =>
  (params: ValueFormatterParams): string => {
    const colId = params.colDef.field;
    const column = params.columnApi.getColumn(colId);
    const rowNumber = Math.floor(rowHeight / fontSize);
    const visibleCharactersN = Math.floor(
      ((column?.getActualWidth() ?? 0) / fontSize) * rowNumber ?? 0,
    );
    const stringTail =
      params.value === null || params.value.length <= visibleCharactersN ? '' : '...';
    return params.value ? `${params.value.slice(0, visibleCharactersN)}${stringTail}` : '';
  };
