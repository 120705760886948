import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Button } from '@wework/ray2';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { IRowNode } from 'ag-grid-community';
import {
  BottomPanelLocationsCounter,
  BottomPanelWrapper,
} from '../../styles/sharedBottomPanel.styled';
import { trackAnalytics } from '../../utils/analytics/helpers';
import { routerHistory } from '../../utils/store/configureStore';
import { getAllSelectedLocations } from '../../sharedStore/modules/locations/locations.selector';
import { CLEAR_SELECTED_LOCATIONS } from '../../sharedStore/modules/locations/locations.ducks';
import { MultipleLocationSearchProps } from '../../utils/sharedTypes';
import { SET_SELECTED_NODES_STANDARD_PRICING } from '../../app/pricing/standardPricing/store/modules/standardPricingItems/standardPricingItems.ducks';

function BottomPanelComponentSearch({ buttons }: MultipleLocationSearchProps): ReactElement {
  const allSelectedLocations = useSelector(getAllSelectedLocations);
  const selectedLocationsNum = allSelectedLocations.length;

  // DISPATCH
  const dispatch = useDispatch();
  const clearSelectedLocations = useCallback(
    () => dispatch({ type: CLEAR_SELECTED_LOCATIONS }),
    [dispatch],
  );
  // Setting selected nodes to old one based on previous route.
  const clearSelectedNodesStandardPricingView = useCallback(
    (payload: IRowNode[] = []) => dispatch({ type: SET_SELECTED_NODES_STANDARD_PRICING, payload }),
    [dispatch],
  );

  // This track method can filter by both platform_name and # of selected locations.
  function trackLocationSearch(title: string) {
    trackAnalytics('Number of Selected Locations', {
      workflow: 'Select Locations',
      object_type: 'Multi-select',
      object_name: 'Number of Selected Locations',
      object_value: {
        title,
        selectedLocations: selectedLocationsNum,
      },
    });
  }

  /**
   * This method is used for fetching data from backend.
   * It also resets editMode, SortOptions and priceableItems before fetching data.
   *
   */
  const redirectToTableView = (title: string, pathname: string) => {
    trackLocationSearch(title);

    // Clearing selection of nodes in pricing view.
    clearSelectedNodesStandardPricingView();
    routerHistory.push({
      pathname,
    });
  };

  return (
    <BottomPanelWrapper>
      <BottomPanelLocationsCounter>
        {!isEmpty(allSelectedLocations) && (
          <>
            {allSelectedLocations.length} locations selected
            <button className="clear-all" onClick={() => clearSelectedLocations()}>
              Clear All
            </button>
          </>
        )}
      </BottomPanelLocationsCounter>

      {buttons.map(btn => (
        <Button
          key={`search-button-${btn.title}`}
          theme={'fill'}
          size={'medium'}
          disabled={isEmpty(allSelectedLocations)}
          onClick={() => redirectToTableView(btn.title, btn.route)}
        >
          {btn.title}
        </Button>
      ))}
    </BottomPanelWrapper>
  );
}

export default BottomPanelComponentSearch;
