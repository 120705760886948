import styled from 'styled-components';
import { devEnvOrange, pureBlack } from '../../styles/colors';
import { FlexVerticalHelper, ListReset } from '../../styles/app.styled';

export const SidebarRegionStyled = styled.div`
  grid-column: 1/2;
  grid-row: 1/5;
  * {
    box-sizing: border-box;
  }
`;

export const SidebarStyled = styled.div`
  position: relative;
  display: block;
  min-width: 80px;
  height: 100%;
  &.development {
    background: ${devEnvOrange};
  }
`;

export const AppSidebarNav = styled.nav`
  padding-top: 36px;
`;
export const AppList = styled.ol`
  ${ListReset};
  ${FlexVerticalHelper};
  align-items: flex-start;
  &:last-of-type {
    position: absolute;
    bottom: 51px;
    width: 100%;
  }
`;
export const AppItem = styled.li`
  width: 100%;
  height: 100%;
  &:hover {
    background: #ccccff;
  }
`;
export const AppItemWrap = styled.div`
  > .app-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    cursor: pointer;
    &:hover {
      color: ${pureBlack};
    }
  }
`;
