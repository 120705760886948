import * as React from 'react';
import { ReactElement } from 'react';
import styled from 'styled-components';
import Logo from '../assets/logo.svg';
import { MainRegionStyled } from '../styles/app.styled';
import HomeDashboardComponent from '../sharedComponents/homeDashboard/homeDashboard';

const VoyagerLogo = styled.img`
  padding: 56px 0 48px;
  margin: 0 40px;
`;

function VoyagerHome(): ReactElement {
  return (
    <MainRegionStyled className="home">
      <VoyagerLogo src={Logo} />
      <HomeDashboardComponent />
    </MainRegionStyled>
  );
}

export default VoyagerHome;
