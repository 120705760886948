import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import { DocumentNode } from 'graphql';
import { Action } from 'redux-actions';
import { toast } from 'react-toastify';
import { FetchResult } from '@apollo/client/link/core';
import store, { graphQLClient } from '../../../../../../index';
import { errorHandlerActive } from '../../../../../../utils/errorHandling/helpers';
import {
  FETCH_ON_DEMAND_CHANGE_LOG,
  FETCH_ON_DEMAND_PRICE_HISTORY_QUERY,
  FETCH_ON_DEMAND_PRICING_QUERY,
  FETCH_ON_DEMAND_PRODUCTS_QUERY,
} from './onDemandPricing.query';
import {
  CREATE_ON_DEMAND_WORKING_PRICE,
  CREATE_ON_DEMAND_WORKING_PRICE_END,
  END_FETCH_ON_DEMAND_PRODUCTS,
  END_FETCH_PRICE_HISTORY,
  END_ON_DEMAND_PRICING_FETCH,
  FETCH_CURRENT_ON_DEMAND_CHANGE_LOG,
  FETCH_CURRENT_ON_DEMAND_CHANGE_LOG_SUCCESS,
  FETCH_ON_DEMAND_PRICING_ITEMS,
  FETCH_ON_DEMAND_PRICING_ITEMS_SUCCESS,
  FETCH_ON_DEMAND_PRODUCTS,
  ON_DEMAND_FUTURE_PRICE_CANCEL,
  ON_DEMAND_FUTURE_PRICE_CANCEL_FAIL,
  ON_DEMAND_FUTURE_PRICE_CANCEL_SUCCESS,
  ON_DEMAND_WORKING_PRICE_PUBLISH,
  ON_DEMAND_WORKING_PRICE_PUBLISH_SUCCESS,
  ON_DEMAND_WORKING_PRICE_REVERT,
  ON_DEMAND_WORKING_PRICE_REVERT_FAIL,
  ON_DEMAND_WORKING_PRICE_REVERT_SUCCESS,
  OPEN_PRICE_HISTORY_SIDE_PANEL,
  START_ON_DEMAND_PRICING_FETCH,
  STOP_FETCH_ON_DEMAND_PRICING_ITEMS,
  UPDATE_GEO_HIERARCHY_ATTRIBUTES,
  UPDATE_GEO_HIERARCHY_ATTRIBUTES_END,
} from './onDemandPricing.ducks';
import {
  CANCEL_ON_DEMAND_FUTURE_PRICE_MUTATION,
  CREATE_ON_DEMAND_WORKING_PRICE_MUTATION,
  PUBLISH_ON_DEMAND_WORKING_PRICE_MUTATION,
  REVERT_ON_DEMAND_WORKING_PRICE_MUTATION,
  UPDATE_GEO_HIERARCHY_ATTRIBUTES_MUTATION,
} from './onDemandPricingItems.mutations';
import { DataWithCallback } from '../../../../../../utils/sharedTypes';
import {
  onDemandPricingProductsSelector,
  priceHistoryGeoSelector,
} from './onDemandPricing.selector';
import {
  GeoHierarchiesChangeLogQueryVariables,
  GeoHierarchy,
  MutationCancelFuturePriceHierarchyArgs,
  MutationPublishWorkingPriceHierarchyArgs,
  MutationRevertWorkingPriceHierarchyArgs,
  PriceHierarchyInput,
  UpdateGeoHierarchyAttributesMutation,
  UpdateGeoHierarchyAttributesMutationVariables,
} from '../../../../../../generated/voyager/graphql';

const executeGraphQLQuery = (query: DocumentNode, variable?: Object): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query,
    variables: variable,
  });

function* fetchPriceData(productIds: string[]): any {
  try {
    // Fetch data from backend.
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_ON_DEMAND_PRICING_QUERY, {
      productIds,
    });
    // If any errors stop the load and redirect to error page.
    if (!isEmpty(errors)) {
      yield put({ type: END_ON_DEMAND_PRICING_FETCH });
      errorHandlerActive(new Error(errors[0]));
      return;
    }
    if (data?.geoHierarchies && data?.geoHierarchies.length > 0) {
      // 1) Store the data in the store.
      yield put({ type: FETCH_ON_DEMAND_PRICING_ITEMS_SUCCESS, data });
    }
    // If no data from backend end the recursion.
    return;
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_ON_DEMAND_PRICING_FETCH });
  }
}

function* fetchOnDemandPricingItemsWorker(action: any) {
  try {
    // Starting a batch fetch.
    yield put({ type: START_ON_DEMAND_PRICING_FETCH });
    // Calling the recursion for fetching the priceable items.
    const productIds: string[] = action.payload ?? [];
    yield call(fetchPriceData, productIds);
    // End batch fetch.
    yield put({ type: END_ON_DEMAND_PRICING_FETCH });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_ON_DEMAND_PRICING_FETCH });
  }
}

function* fetchOnDemandProductsWorker() {
  try {
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_ON_DEMAND_PRODUCTS_QUERY);

    if (!isEmpty(errors)) {
      yield put({ type: END_FETCH_ON_DEMAND_PRODUCTS });
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    yield put({ type: END_FETCH_ON_DEMAND_PRODUCTS, data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_FETCH_ON_DEMAND_PRODUCTS });
  }
}

const fetchHistoryQuery = (filter: any): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_ON_DEMAND_PRICE_HISTORY_QUERY,
    variables: { filter },
  });

function* fetchOnDemandPriceHistory() {
  try {
    const geo: GeoHierarchy = yield select(priceHistoryGeoSelector);
    const geoIdArr = geo == null ? [] : [geo.id];
    const filterPriceHistory = {
      ids: geoIdArr,
      parentIds: null,
      topLevelOnly: null,
    };
    const { errors, data } = yield call(fetchHistoryQuery, filterPriceHistory);

    if (!isEmpty(errors) || isEmpty(data?.geoHierarchies)) {
      const error = errors[0]?.message ?? 'Not found';
      toast.error(`Fetch price history error - ${error}`);
      yield put({ type: END_FETCH_PRICE_HISTORY });
      return;
    }

    yield put({ type: END_FETCH_PRICE_HISTORY, data });
  } catch (e: any) {
    toast.error(`Fetch price history error - ${get(e, 'message', 'Unknown Error')}`);
    yield put({ type: END_FETCH_PRICE_HISTORY });
  }
}

// Batching the update request.
const updateOnDemandItems = (workingPriceInputs: PriceHierarchyInput[]): Promise<any> =>
  graphQLClient.mutate({
    mutation: CREATE_ON_DEMAND_WORKING_PRICE_MUTATION,
    variables: { priceHierarchy: workingPriceInputs },
  });

// Saga - save current working price.
function* createOnDemandWorkingPrice(action: Action<DataWithCallback<PriceHierarchyInput[]>>) {
  try {
    const onDemandWorkingPriceInputs = action.payload.data;
    const { data, errors } = yield call(updateOnDemandItems, onDemandWorkingPriceInputs);

    if (!isEmpty(errors) || !data?.saveWorkingPriceHierarchy?.success) {
      if (data?.saveWorkingPriceHierarchy?.code === 404 && action.payload.failCallback) {
        action.payload.failCallback();
      } else {
        const error = data?.saveWorkingPriceHierarchy?.errors ?? errors[0]?.message;
        toast.error(`Price(s) save error - ${error}`, { autoClose: false });
      }
      yield put({ type: CREATE_ON_DEMAND_WORKING_PRICE_END, payload: [] });
    } else {
      action.payload.successCallback();
      toast.success('Successfully updated the working price(s).');
      yield put({
        type: CREATE_ON_DEMAND_WORKING_PRICE_END,
        payload: onDemandWorkingPriceInputs,
      });
    }
  } catch (e: any) {
    toast.error(`Price(s) save error - ${get(e, 'message', 'Unknown Error')}`);
  }
}

const publishOnDemandWorkingPrice = (
  action: MutationPublishWorkingPriceHierarchyArgs,
): Promise<any> =>
  graphQLClient.mutate({
    mutation: PUBLISH_ON_DEMAND_WORKING_PRICE_MUTATION,
    variables: action,
  });

function* onDemandPublishWorkingPriceWorker(
  action: Action<MutationPublishWorkingPriceHierarchyArgs>,
) {
  try {
    const { data, errors } = yield call(publishOnDemandWorkingPrice, action?.payload);
    if (!isEmpty(errors) || !data?.publishWorkingPriceHierarchy?.success) {
      const error = data?.publishWorkingPriceHierarchy?.errors ?? errors[0]?.message;
      toast.error(`Publish working price error - ${error}`);
      errorHandlerActive(new Error(error));
      return;
    }

    yield put({ type: ON_DEMAND_WORKING_PRICE_PUBLISH_SUCCESS });
    toast.success(`Published Working Prices Successfully`);
  } catch (e: any) {
    toast.error(`Publish Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

const cancelOnDemandFuturePrice = (action: MutationCancelFuturePriceHierarchyArgs): Promise<any> =>
  graphQLClient.mutate({
    mutation: CANCEL_ON_DEMAND_FUTURE_PRICE_MUTATION,
    variables: action,
  });

function* onDemandCancelFuturePriceWorker(
  action: Action<DataWithCallback<MutationCancelFuturePriceHierarchyArgs>>,
) {
  try {
    const { data, errors } = yield call(cancelOnDemandFuturePrice, action.payload.data);

    if (!isEmpty(errors) || !data?.cancelFuturePriceHierarchy?.success) {
      yield put({ type: ON_DEMAND_FUTURE_PRICE_CANCEL_FAIL });
      const error =
        (data?.cancelFuturePriceHierarchy?.code === 404
          ? 'Price cannot be canceled at this level because the grouping contains inaccessible locations.'
          : data?.cancelFuturePriceHierarchy?.errors) ?? errors[0]?.message;
      toast.error(`Cancel future price error - ${error}`, { autoClose: false });
    } else {
      yield put({ type: ON_DEMAND_FUTURE_PRICE_CANCEL_SUCCESS, payload: action.payload.data });
      action.payload.successCallback();
      toast.success(`Canceled Future Prices Successfully`);
    }
  } catch (e: any) {
    toast.error(`Cancel future price error - ${get(e, 'message', 'Unknown Error')}`);
  }
}

const revertOnDemandWorkingPrice = (
  action: MutationRevertWorkingPriceHierarchyArgs,
): Promise<any> =>
  graphQLClient.mutate({
    mutation: REVERT_ON_DEMAND_WORKING_PRICE_MUTATION,
    variables: action,
  });

function* onDemandRevertWorkingPriceWorker(
  action: Action<DataWithCallback<MutationRevertWorkingPriceHierarchyArgs>>,
) {
  try {
    const { data, errors } = yield call(revertOnDemandWorkingPrice, action.payload.data);

    if (!isEmpty(errors) || !data?.revertWorkingPriceHierarchy?.success) {
      yield put({ type: ON_DEMAND_WORKING_PRICE_REVERT_FAIL });
      const error =
        (data?.revertWorkingPriceHierarchy?.code === 404
          ? 'Price cannot be reverted at this level because the grouping contains inaccessible locations.'
          : data?.revertWorkingPriceHierarchy?.errors) ?? errors[0]?.message;
      toast.error(`Revert working price error - ${error}`, { autoClose: false });
    } else {
      yield put({ type: ON_DEMAND_WORKING_PRICE_REVERT_SUCCESS, payload: action.payload.data });
      action.payload.successCallback();
      toast.success(`Revert Working Prices Successfully`);
    }
  } catch (e: any) {
    toast.error(`Cancel future price error - ${get(e, 'message', 'Unknown Error')}`);
  }
}

const fetchChangeLog = (action: GeoHierarchiesChangeLogQueryVariables): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_ON_DEMAND_CHANGE_LOG,
    variables: action,
  });

function* onDemandFetchChangeLogWorker(action: Action<GeoHierarchiesChangeLogQueryVariables>) {
  try {
    const { errors, data } = yield call(fetchChangeLog, action?.payload);
    if (!isEmpty(errors)) {
      toast.error(`Fetch changelog error - ${errors[0].message}`);
      return;
    }

    yield put({ type: FETCH_CURRENT_ON_DEMAND_CHANGE_LOG_SUCCESS, data });
  } catch (e: any) {
    toast.error(`Fetch changelog error - ${get(e, 'message', 'Unknown Error')}`);
  }
}

const updateGeoHierarchyAttributes = (
  variables: UpdateGeoHierarchyAttributesMutationVariables,
): Promise<FetchResult<UpdateGeoHierarchyAttributesMutation>> =>
  graphQLClient.mutate({
    mutation: UPDATE_GEO_HIERARCHY_ATTRIBUTES_MUTATION,
    variables,
  });

function* updateGeoHierarchyAttributesWorker(
  action: Action<DataWithCallback<UpdateGeoHierarchyAttributesMutationVariables>>,
) {
  try {
    const { data, errors } = yield call(updateGeoHierarchyAttributes, action.payload.data);

    if (!isEmpty(errors) || !data?.updateGeoHierarchyAttributes?.success) {
      if (data?.updateGeoHierarchyAttributes?.code === 404 && action.payload.failCallback) {
        action.payload.failCallback();
      } else {
        const error = data?.updateGeoHierarchyAttributes?.errors ?? errors[0]?.message;
        toast.error(`Update attribute(s) error - ${error}`, { autoClose: false });
      }
    } else {
      const productIds = onDemandPricingProductsSelector(store.getState()).map(
        eachProd => eachProd.id,
      );
      yield put({ type: FETCH_ON_DEMAND_PRICING_ITEMS, payload: productIds });
      action.payload.successCallback();
      toast.success('Updated attribute(s) successfully');
    }
    yield put({ type: UPDATE_GEO_HIERARCHY_ATTRIBUTES_END });
  } catch (e: any) {
    toast.error(`Update attribute(s) error - ${get(e, 'message', 'Unknown Error')}`);
  }
}

export default function* onDemandPricingItemSaga(): any {
  yield takeLatest([FETCH_ON_DEMAND_PRICING_ITEMS], function* (...args) {
    yield race({
      task: call(fetchOnDemandPricingItemsWorker, ...args),
      cancel: take(STOP_FETCH_ON_DEMAND_PRICING_ITEMS),
    });
  });
  yield all([takeLatest(FETCH_ON_DEMAND_PRODUCTS, fetchOnDemandProductsWorker)]);
  yield all([takeLatest(CREATE_ON_DEMAND_WORKING_PRICE, createOnDemandWorkingPrice)]);
  yield all([takeLatest(ON_DEMAND_WORKING_PRICE_PUBLISH, onDemandPublishWorkingPriceWorker)]);
  yield all([takeLatest(ON_DEMAND_FUTURE_PRICE_CANCEL, onDemandCancelFuturePriceWorker)]);
  yield all([takeLatest(ON_DEMAND_WORKING_PRICE_REVERT, onDemandRevertWorkingPriceWorker)]);
  yield all([takeLatest(FETCH_CURRENT_ON_DEMAND_CHANGE_LOG, onDemandFetchChangeLogWorker)]);
  yield all([takeLatest(OPEN_PRICE_HISTORY_SIDE_PANEL, fetchOnDemandPriceHistory)]);
  yield all([takeLatest(UPDATE_GEO_HIERARCHY_ATTRIBUTES, updateGeoHierarchyAttributesWorker)]);
}
