const AppConfig = {
  BACKEND_URL: `${process.env.REACT_APP_API_URL}/graphql`,
  BACKEND_REST_URL: process.env.REACT_APP_API_URL,
  BACKEND_EXTRACTOR_REST_URL: process.env.REACT_APP_EXTRACTOR_API_URL,
  BACKEND_IPP_REST_URL: process.env.REACT_APP_IPP_API_URL,
  APP_ENV: process.env.REACT_APP_ENV,
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  AG_GRID_LICENSE_KEY: process.env.REACT_APP_AG_GRID_LICENSE_KEY,
  ROLLBAR_TOKEN: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
  APP_REVISION: process.env.REACT_APP_GIT_SHA,
  AUTH0_APP_ENV: process.env.REACT_APP_AUTH0_APP_ENV,
};

export default AppConfig;
