import * as React from 'react';
import { ReactElement } from 'react';
import { Button } from '@wework/ray2';
import { TableFilter, TableFilterRow } from '../../../../styles/sharedTableFilterBar.styled';

function TableViewDataSelectorsDiscounts(): ReactElement {
  return (
    <TableFilterRow>
      <TableFilter className="info-button-groups info-button-groups-disabled">
        <Button theme={'outline'} size={'medium'} className={'ml-md'}>
          Min
        </Button>
        <Button theme={'fill'} size={'medium'} className={'middle-btn'}>
          Avg
        </Button>
        <Button theme={'outline'} size={'medium'}>
          Max
        </Button>
      </TableFilter>
    </TableFilterRow>
  );
}

export default TableViewDataSelectorsDiscounts;
