import { IRowNode } from 'ag-grid-community';
import { StoreInterface } from '../../../../../../utils/store/store.interface';
import { OnDemandPricingItemsSubset } from '../../entities/onDemandPricing';
import { FetchState } from '../../../../../../utils/sharedTypes';
import { GeoHierarchy, PriceHierarchy, Product } from '../../../../../../generated/voyager/graphql';

export const onDemandPricingItemsSelector = (state: StoreInterface): OnDemandPricingItemsSubset =>
  state?.onDemandPricingItems;

export const onDemandPricingAllItemsSelector = (state: StoreInterface): GeoHierarchy[] =>
  onDemandPricingItemsSelector(state).allItems;

export const onDemandPricingItemsToPublishSelector = (state: StoreInterface): GeoHierarchy[] =>
  onDemandPricingItemsSelector(state).itemsToPublish;

export const onDemandPricingProductsToPublishSelector = (state: StoreInterface): Product[] =>
  onDemandPricingItemsSelector(state).productsToPublish;

export const onDemandPricingAllItemsSubsetSelector = (
  state: OnDemandPricingItemsSubset,
): GeoHierarchy[] => state.allItems;

export const onDemandPricingLoadingSelector = (state: StoreInterface): boolean =>
  onDemandPricingItemsSelector(state).isLoadingFetchState;

export const onDemandPricingProductsLoadingSelector = (state: StoreInterface): boolean =>
  onDemandPricingItemsSelector(state).productsLoadingFetchState;

export const onDemandPricingProductsSelector = (state: StoreInterface): Product[] =>
  onDemandPricingItemsSelector(state).onDemandProducts;

export const onDemandPricingEditMode = (state: StoreInterface): boolean =>
  onDemandPricingItemsSelector(state).editMode;

export const onDemandPublishPricesSelector = (state: StoreInterface): FetchState =>
  onDemandPricingItemsSelector(state).publishPrices;

export const onDemandSetPricesLoadingSelector = (state: StoreInterface): boolean =>
  onDemandPricingItemsSelector(state).setPricesLoading;

export const onDemandCancelPricesLoadingSelector = (state: StoreInterface): boolean =>
  onDemandPricingItemsSelector(state).cancelPricesLoading;

export const onDemandRevertPricesLoadingSelector = (state: StoreInterface): boolean =>
  onDemandPricingItemsSelector(state).revertPricesLoading;

export const currentOnDemandChangeLog = (state: StoreInterface): GeoHierarchy[] =>
  onDemandPricingItemsSelector(state).currentChangeLog;

export const currentOnDemandChangeLogFetchState = (state: StoreInterface): FetchState =>
  onDemandPricingItemsSelector(state).currentChangeLogFetchState;

export const onDemandSelectedNodesSelector = (state: StoreInterface): IRowNode[] =>
  onDemandPricingItemsSelector(state).selectedNodes;

export const priceHistoryGeoSelector = (state: StoreInterface): GeoHierarchy | null =>
  onDemandPricingItemsSelector(state).priceHistoryGeo;

export const onDemandPriceHistorySelector = (state: StoreInterface): PriceHierarchy[] =>
  onDemandPricingItemsSelector(state).priceHistory;

export const priceHistoryLoadingSelector = (state: StoreInterface): boolean =>
  onDemandPricingItemsSelector(state).priceHistoryLoadingFetchState;

export const updateGeoHierarchyAttributesLoadingSelector = (state: StoreInterface): boolean =>
  onDemandPricingItemsSelector(state).updateGeoHierarchyAttributesLoading;
