import { ProcessCellForExportParams } from 'ag-grid-community';
import { find } from 'lodash';
import { isPercentageNull, roundToAtMost2Digit } from '../../helpers';
import {
  formatDate,
  getPhysicalAttributesDetails,
  processHeaderCallback,
} from '../../../../../../utils/helpers';
import { Location } from '../../../../../../generated/voyager/graphql';

const allStrToNumberColumns = [
  'workingPriceLP',
  'suggestedPriceARPM',
  'suggestedPriceLP',
  'currentPriceARPM',
  'currentPriceLP',
  'monthsVacant',
  'squareFootage',
];

const standardPricingTableDownloadConfig = (locationDetails: Location[]) => ({
  skipRowGroups: true,
  columnKeys: [
    'selectionCheckbox',
    'locationName',
    'sku',
    'name',
    'physicalAttributes',
    'physicalAttributes2',
    'squareFootage',
    'currentPriceARPM',
    'currentPriceLP',
    'locationSkuOccupancy',
    'marketSkuOccupancy',
    'monthsVacant',
    'lastPublished',
    'suggestedPriceARPM',
    'suggestedPriceLP',
    'suggCurrPriceDiff',
    'workingPriceLP',
    'adjBy',
    'lastChangeReason',
    'peakDiscount',
    'lowestNetPrice',
    'lowestNetBreakeven',
  ],
  processCellCallback: (params: ProcessCellForExportParams) => {
    // Location name
    const colId = params.column.getColId();
    if (colId === 'selectionCheckbox') {
      return params.node?.data.id;
    } else if (colId === 'lastPublished') {
      if (!params.value) {
        return '-';
      }
      return formatDate(params.value);
    } else if (colId === 'locationName') {
      const locationName = find(locationDetails, ['id', params.value]) ?? ({} as Location);
      return locationName?.name;
      // Physical attributes
    } else if (colId === 'physicalAttributes') {
      return getPhysicalAttributesDetails(params, 'window');
    } else if (colId === 'physicalAttributes2') {
      return getPhysicalAttributesDetails(params, 'internalRoomPresent');
    } else if (allStrToNumberColumns.includes(colId)) {
      if (!params.value) {
        return '';
      }
      const val = Number(params.value);
      return val ? roundToAtMost2Digit(val).toString() : '-';
    } else if (colId === 'lastChangeReason') {
      if (!params.value) {
        return '';
      }

      return params.value ? params.value : '-';
    } else if (colId === 'toPercentageVF') {
      if (!params.value) {
        return '';
      }

      const val: number = params.value;
      return val ? isPercentageNull(val) : '-';
    } else if (colId === 'lowestNetBreakeven' || colId === 'lowestNetPrice') {
      if (!params.value) {
        return '';
      }
      const val = Number(params.value);
      return val ? roundToAtMost2Digit(val).toString() : '-';
    }
    return params.value;
  },
  processHeaderCallback,
});

export default standardPricingTableDownloadConfig;
