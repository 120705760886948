import * as React from 'react';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Icon, IconName, IconSize, Button, IconType } from '@wework/ray2';
import { Checkbox, Table } from 'semantic-ui-react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import {
  curvesLoadingSelector,
  curvesSelector,
  discountItemsTermTypes,
} from '../../store/modules/discountItems/discountItems.selector';
import { isPercentageNull } from '../../../pricing/standardPricing/components/helpers';
import { CellContainer, CurvesSearchTableWrapper } from './discountCurves.styled';
import { Curve, TermType } from '../../../../generated/voyager/graphql';

interface CurvesSearchTableProps {
  changeCurveSelection: Function;
  modifyExpandedArr: Function;
  selectedCurveIds: string[];
  filterCurveIds?: string[];
  openIds?: string[];
}

function CurvesSearchTable({
  changeCurveSelection,
  selectedCurveIds,
  filterCurveIds,
  modifyExpandedArr,
  openIds,
}: CurvesSearchTableProps): ReactElement {
  const curvesLoading = useSelector(curvesLoadingSelector);
  const termTypes = useSelector(discountItemsTermTypes);
  const allCurves = useSelector(curvesSelector);
  const curves = filterCurveIds
    ? allCurves.filter(curve => !filterCurveIds.includes(curve.id))
    : allCurves;

  const getOpenCloseRow = (curveId: string): boolean => !!openIds?.find(id => id === curveId);

  const getDataPointsString = (curve: Curve, termType: TermType) => (
    <CellContainer
      className={cn({ open: getOpenCloseRow(curve.id), closed: !getOpenCloseRow(curve.id) })}
    >
      {curve.curveTerms
        .find(ct => ct.term.label === termType.label)
        ?.dataPoints.filter(dp => dp.value)
        .map(dp => <div key={dp.month}>{`M${dp.month}:${isPercentageNull(dp.value)}`}</div>) ?? (
        <span />
      )}
    </CellContainer>
  );

  return (
    <CurvesSearchTableWrapper>
      <Table className="curve-manage-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell key={'SELECT'}>
              {!isEmpty(curves) ? (
                <Checkbox
                  onChange={(_, data) => changeCurveSelection(curves, data.checked)}
                  checked={curves.every(curve => selectedCurveIds.includes(curve.id))}
                />
              ) : (
                ''
              )}
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell key={'NAME'}>NAME</Table.HeaderCell>
            <Table.HeaderCell key={'DESCRIPTION'}>DESCRIPTION</Table.HeaderCell>
            {termTypes.map(termType => (
              <Table.HeaderCell key={termType.label}>{termType.label}</Table.HeaderCell>
            ))}
            <Table.HeaderCell key={'OPERATOR'}>OPERATOR</Table.HeaderCell>
            <Table.HeaderCell key={'RESERVABLES'}># APPLIED</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {!isEmpty(curves) ? (
            curves.map(curve => (
              <Table.Row key={curve.id}>
                <td colSpan={1}>
                  <Checkbox
                    value={curve.id}
                    checked={selectedCurveIds.includes(curve.id)}
                    onChange={(_, data) => changeCurveSelection(curve, data.checked)}
                  />
                </td>
                <td className="icon-size">
                  <Icon
                    size={IconSize.LARGE}
                    icon={getOpenCloseRow(curve.id) ? IconName.CARET_DOWN : IconName.CARET_UP}
                    onClick={() => modifyExpandedArr(curve.id)}
                  />
                </td>
                <td className="fixed-width">
                  <CellContainer
                    className={cn({
                      open: getOpenCloseRow(curve.id),
                      closed: !getOpenCloseRow(curve.id),
                    })}
                  >
                    {curve.name}
                  </CellContainer>
                </td>
                <td className="fixed-width">
                  {' '}
                  <CellContainer
                    className={cn({
                      open: getOpenCloseRow(curve.id),
                      closed: !getOpenCloseRow(curve.id),
                    })}
                  >
                    {curve.description}
                  </CellContainer>
                </td>
                {termTypes.map(termType => (
                  <td key={termType.id}>{getDataPointsString(curve, termType)}</td>
                ))}
                <td>{curve.operator?.name ?? '-'}</td>
                <td>{curve.appliedCount}</td>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <td colSpan={11} className="align-middle">
                {curvesLoading ? (
                  <Button
                    size="large"
                    theme="text"
                    icon={IconName.AIR}
                    iconType={IconType.OUTLINE}
                    loading
                  />
                ) : (
                  'No Results'
                )}
              </td>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </CurvesSearchTableWrapper>
  );
}

export default CurvesSearchTable;
