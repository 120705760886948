import styled from 'styled-components';
import Calendar from '../../../../assets/calendar.svg';
import { FlexVerticalHelper, ListReset } from '../../../../styles/app.styled';
import {
  blue,
  darkBlue,
  darkGray,
  darkGreen,
  disabledGray,
  green,
  lighterGray,
  red,
} from '../../../../styles/colors';
import DetailsOff from '../../../../assets/icon_details_inactive.svg';
import History from '../../../../assets/history.svg';
import InfoOff from '../../../../assets/info_inactive.svg';

export const ChangelogTitle = styled.h4`
  margin: 0 0 4px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
  color: ${darkBlue};
`;
export const ChangelogList = styled.ol`
  ${ListReset};
  padding-top: 5px;
  overflow-y: auto;
  max-height: 144px;
  ${FlexVerticalHelper};
  justify-content: flex-start;
  align-content: flex-start;
  border-top: 1px solid ${lighterGray};
  &.full-height {
    max-height: 100%;
    height: 100%;
    border-top: none;
  }
`;
export const ChangelogListItem = styled.li`
  flex: 1 1 auto;
  padding: 0 5px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 400;
  color: ${darkBlue};
`;
export const CustomTableBodyCellCalendar = styled.div`
  display: flex;
  justify-content: flex-start;
  &.multi-line {
    flex-flow: column nowrap;
    align-content: flex-start;
  }
  &.single-line {
    flex-flow: row nowrap;
    align-content: center;
  }
  &.positive {
    color: ${green};
  }
  &.negative {
    color: ${red};
  }
  &.neutral {
    color: ${darkBlue};
  }

  button.scheduled-discount-trigger {
    display: block;
    padding: 0;
    border: 0;
    box-shadow: none;
    outline: none;
    order: 1;
    flex: 0 0 auto;
    align-self: center;
    justify-self: flex-end;
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-left: 15px;
    margin-top: -2px;
    &::before {
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      -webkit-mask: url(${Calendar});
      mask: url(${Calendar});
      -webkit-mask-size: contain;
      mask-size: contain;
      background-color: ${darkGreen};
    }
    &:hover::before,
    &.active::before {
      background-color: ${green};
    }
  }

  button.scheduled-reservable-trigger {
    display: block;
    padding: 0;
    border: 0;
    box-shadow: none;
    outline: none;
    order: 1;
    flex: 0 0 auto;
    align-self: center;
    justify-self: flex-end;
    cursor: pointer;
    width: 14px;
    height: 14px;
    margin-top: 20px;
    background-color: white;
    &::before {
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      -webkit-mask: url(${Calendar});
      mask: url(${Calendar});
      -webkit-mask-size: contain;
      mask-size: contain;
      background-color: ${darkGreen};
    }
    &:hover::before,
    &.active::before {
      background-color: ${green};
    }
  }
  button.changelog-trigger {
    margin-left: 15px;
  }
`;
export const CustomTableBodyCell = styled.div`
  display: flex;
  justify-content: flex-start;
  &.multi-line {
    flex-flow: column nowrap;
    align-content: flex-start;
  }
  &.single-line {
    flex-flow: row nowrap;
    align-content: center;
  }
  &.positive {
    color: ${green};
  }
  &.negative {
    color: ${red};
  }
  &.neutral {
    color: ${darkBlue};
  }
  &.row-selector-wrap {
    height: 100%;
    align-items: center;
    > .ui.checkbox {
      margin: -9px 0 0 7px;
    }
  }
  > .pa-icon {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }
  &.physical-att {
    padding-top: 18px;
    > .internal-rooms-icon {
      margin-right: 4px;
    }
  }
  button.changelog-trigger,
  button.feature-chart-popup-trigger,
  button.details-drawer-trigger,
  button.discounts-drawer-trigger {
    display: block;
    padding: 0;
    border: 0;
    box-shadow: none;
    margin: 0 0 0 4px;
    outline: none;
    width: 14px;
    height: 14px;
    background-color: white;
    order: 1;
    flex: 0 0 auto;
    align-self: center;
    justify-self: flex-end;
    cursor: pointer;
    &::before {
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      -webkit-mask: url(${History});
      mask: url(${History});
      -webkit-mask-position: center;
      -webkit-mask-size: contain;
      background-color: ${darkBlue};
    }
    &:hover::before,
    &.active::before {
      background-color: ${blue};
    }
  }
  button.details-drawer-trigger {
    width: 17px;
    height: 12px;
    &::before {
      -webkit-mask: url(${DetailsOff});
      mask: url(${DetailsOff});
    }
    &[disabled] {
      cursor: default;
      &::before {
        background-color: ${disabledGray};
      }
    }
  }
  button.feature-chart-popup-trigger,
  button.details-drawer-trigger,
  button.discounts-drawer-trigger {
    width: 14px;
    height: 14px;
    margin: 0 auto;
    &::before {
      -webkit-mask: url(${InfoOff});
      mask: url(${InfoOff});
      mask-size: contain;
      background-color: ${darkGray};
    }
  }
  button.details-drawer-trigger {
    width: 17px;
    height: 12px;
    &::before {
      -webkit-mask: url(${DetailsOff});
      mask: url(${DetailsOff});
    }
  }
  > .icon.feature-chart-popup-trigger {
    display: inline-block;
    padding: 0;
    width: 10px;
    height: 10px;
    margin: 4px 0 0 4px;
    cursor: pointer;
  }
  /* GRID OVERRIDES MODAL TRIGGER */
  button.overrides-modal-trigger {
    display: block;
    padding: 0;
    background: none;
    border: none;
    margin: 17px 0 0 0;
    line-height: 20px;
    font-size: 12px;
    font-weight: 400;
    text-decoration: underline;
    color: ${blue};
    cursor: pointer;
    outline: none;
  }
  /* DISCOUNTS DETAILS DRAWER TRIGGER */
  button.discounts-drawer-trigger {
    width: 14px;
    height: 14px;
    background: transparent;
    display: flex;
    flex-flow: column wrap;
    align-content: center;
  }
`;
export const ItemDataValueStyled = styled.p`
  order: 0;
  flex: 1 1 auto;
  justify-self: flex-start;
  align-self: center;
  overflow: auto;
  width: 100%;
  &.no-margin {
    margin: 0;
  }
`;
