import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { get, isEmpty, omit } from 'lodash';
import cn from 'classnames';
import { withRequestedAuthz } from '@wework/we-auth-react';
import {
  LiveBudgetLastUpdated,
  LocationTitleStyledBreakeven,
  TextLink,
} from '../breakevenTableAGGrid.styled';
import { CREATE_BREAKEVEN_WORKING_DATA } from '../../../store/modules/breakevenItems/breakevenItems.ducks';
import {
  breakevenItemsSelectorStore,
  editModeSelectorBreakeven,
} from '../../../store/modules/breakevenItems/breakevenItems.selector';
import { checkIfOverrideExist } from '../breakevenTable.helper';
import { rowTypeMap } from '../../constants';
import { formatDate } from '../../../../../utils/helpers';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import { AuthzProps } from '../../../../../utils/constants';
import EmployeePermissions from '../../../../../utils/store/permissions';
import { WorkingLocationBreakEvenInput } from '../../../../../generated/voyager/graphql';

interface BreakevenRowDetailsRendererProps extends ICellRendererParams, AuthzProps {}

function BreakevenRowDetailsRenderer({
  requestedPermissions,
  ...props
}: BreakevenRowDetailsRendererProps): ReactElement {
  const dispatch = useDispatch();
  const createBreakevenWorkingPrice = useCallback(
    (payload: any) => dispatch({ type: CREATE_BREAKEVEN_WORKING_DATA, payload }),
    [dispatch],
  );

  const allItems = useSelector(breakevenItemsSelectorStore) ?? [];
  const editMode = useSelector(editModeSelectorBreakeven);

  function trackApplyLiveBudgetAndClearOverridesButton() {
    trackAnalytics('Breakeven - Apply Live Budget & Clear Overrides Button Click', {
      workflow: 'Breakeven Apply Live Budget & Clear Overrides',
      object_type: 'button',
      object_name: 'Apply Live Budget & Clear Overrides',
    });
  }

  function trackApplyLiveBudgetAndPersistOverridesButton() {
    trackAnalytics('Breakeven - Apply Live Budget & Persist Overrides Button Click', {
      workflow: 'Breakeven Apply live Budget & Persist Overrides',
      object_type: 'button',
      object_name: 'Apply live Budget & Persist Overrides',
    });
  }

  const applyLiveBudget = (clearOverride: boolean = false) => {
    if (clearOverride) {
      trackApplyLiveBudgetAndClearOverridesButton();
    } else {
      trackApplyLiveBudgetAndPersistOverridesButton();
    }

    const item = allItems?.find(
      previousItem => previousItem.location.id === props.data?.location?.id,
    );
    if (item && !isEmpty(item?.liveBudget)) {
      // remove unnecessary fields from LocationBreakEvens_locationBreakEvens_liveBudget
      const liveBudget = omit(item.liveBudget ?? {}, [
        '__typename',
        'breakEvenPrice',
        'sgAndA',
        'updatedAt',
      ]);

      const payload = {
        geoLocationUUID: props.data?.location?.id,
        workingLiveBudgetAppliedInput: { ...liveBudget },
      } as WorkingLocationBreakEvenInput;

      if (clearOverride) {
        payload.workingBreakEvenOverrideInput = null;
      }

      createBreakevenWorkingPrice(payload);
    } else {
      toast.info('Location does not have any live budget data to be applied.', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  function trackClearAllButton() {
    trackAnalytics('Breakeven - Clear All Button Click', {
      workflow: 'Breakeven Clear All',
      object_type: 'button',
      object_name: 'Clear All',
    });
  }

  const clearAllBreakeven = () => {
    trackClearAllButton();
    createBreakevenWorkingPrice({
      geoLocationUUID: props.data?.location?.id,
      workingLiveBudgetAppliedInput: null,
      workingBreakEvenOverrideInput: null,
    });
  };

  return props.value === 'toBePublished' ? (
    <LocationTitleStyledBreakeven>
      <div
        className={cn(
          { override: checkIfOverrideExist(allItems, props.data?.location?.id) },
          'double',
        )}
      >
        {get(rowTypeMap, props.value, '')}
        {editMode && (
          <div className="second">
            {get(
              requestedPermissions,
              EmployeePermissions.voyager_dedicated_space_breakeven_edit,
              false,
            ) && (
              <>
                <TextLink onClick={() => applyLiveBudget()}>
                  Apply live Budget & Persist Overrides
                </TextLink>
                <TextLink onClick={() => applyLiveBudget(true)}>
                  Apply Live Budget & Clear Overrides
                </TextLink>
                <TextLink onClick={() => clearAllBreakeven()}>Clear All</TextLink>
              </>
            )}
          </div>
        )}
      </div>
    </LocationTitleStyledBreakeven>
  ) : (
    <LocationTitleStyledBreakeven>
      <span className="name">{get(rowTypeMap, props.value, '')}</span>
      <LiveBudgetLastUpdated>
        {props.value === 'liveBudget' && props.data?.updatedAt
          ? `Last Updated ${formatDate(props.data?.updatedAt)}`
          : ''}
      </LiveBudgetLastUpdated>
    </LocationTitleStyledBreakeven>
  );
}

export default withRequestedAuthz<BreakevenRowDetailsRendererProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_breakeven_edit],
})(BreakevenRowDetailsRenderer);
