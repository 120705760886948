import React, { ReactElement, useState } from 'react';
import { Button, Icon, IconName, IconSize } from '@wework/ray2';
import { Chip, DatePicker } from '@wework/dieter-ui';
import { Popup } from 'semantic-ui-react';
import { addDays, format, isAfter, isBefore, startOfTomorrow, subDays } from 'date-fns';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { trackAnalytics } from 'utils/analytics/helpers';
import {
  ActionsBox,
  ActionSubTitle,
  BottomPanelWrapper,
  CSVDownloadContainer,
} from 'styles/sharedBottomPanel.styled';
import {
  publishFileExpirationDateSelector,
  publishFileLoadingSelector,
  publishFilePublishDateSelector,
} from 'sharedStore/modules/uploadFile/uploadFile.selector';
import ROUTES from '../../utils/routes/routes';
import currentPathNameSelector from '../../sharedStore/modules/router/router.selector';

interface BasicBottomPanelComponentProps {
  buttonTitle: string;
  sampleFileLink: string;
  title: string;
  isLoading: boolean;
  isEnabled: boolean;
  publishFile: Function;
  handleSetPublishDate: Function;
  handleSetExpirationDate: Function;
}

const BottomPanelUploadFile = ({
  buttonTitle,
  publishFile,
  sampleFileLink,
  title,
  isLoading,
  isEnabled,
  handleSetPublishDate,
  handleSetExpirationDate,
}: BasicBottomPanelComponentProps): ReactElement => {
  const publishDate = useSelector(publishFilePublishDateSelector);
  const expirationDate = useSelector(publishFileExpirationDateSelector);
  const isWaitingValidation = useSelector(publishFileLoadingSelector);
  const { pathname } = useSelector(currentPathNameSelector);
  const [publishDatePickerOpen, setPublishDatePickerOpen] = useState<boolean>(false);
  const [expirationDatePickerOpen, setExpirationDatePickerOpen] = useState<boolean>(false);

  const publishDateDisabledBefore = startOfTomorrow();
  const publishDateDisabledAfter = expirationDate ? subDays(expirationDate, 1) : undefined;
  const isPublishDateInRange = (date: Date) =>
    !isBefore(date, publishDateDisabledBefore) &&
    !(publishDateDisabledAfter && isAfter(date, publishDateDisabledAfter));

  const analyticTitle = `Upload ${title}`;
  const expirationDateDisabledBefore = addDays(publishDate || publishDateDisabledBefore, 1);
  const isExpirationDateInRange = (date: Date) => !isBefore(date, expirationDateDisabledBefore);

  const getPublishDatePickerTrigger = (): ReactElement => (
    <Chip
      className={cn({ active: !!publishDate }, 'date-picker-trigger')}
      active={!!publishDate}
      onClick={() => setPublishDatePickerOpen(prevState => !prevState)}
    >
      {publishDate ? format(publishDate, 'MMM d, yyyy') : 'Select Date'}
      <i />
    </Chip>
  );

  const getExpirationDatePickerTrigger = (): ReactElement => (
    <Chip
      className={cn({ active: !!expirationDate }, 'date-picker-trigger')}
      active={!!expirationDate}
      onClick={() => setExpirationDatePickerOpen(prevState => !prevState)}
    >
      {expirationDate ? format(expirationDate, 'MMM d, yyyy') : 'Select Date'}
      <i />
    </Chip>
  );

  const redirectToSample = () => {
    trackAnalytics(`Batch ${analyticTitle} - Sample File Button Click`, {
      workflow: `Batch ${analyticTitle} Sample File`,
      object_type: 'button',
      object_name: 'Download Sample File',
    });
    window.open(sampleFileLink, '_blank');
  };

  // Track batch upload prices.
  const trackBatchUploadPublishButtonClick = () =>
    trackAnalytics(`Batch ${analyticTitle} - Button Click`, {
      workflow: `Batch ${analyticTitle} Publish`,
      object_type: 'button',
      object_name: 'Publish',
      object_value: `${publishDate?.toDateString() ?? '?'} - ${
        expirationDate?.toDateString() ?? '?'
      }`,
    });

  return (
    <BottomPanelWrapper className="publish-panel">
      <CSVDownloadContainer onClick={() => redirectToSample()}>
        <Icon
          icon={IconName.DOWNLOAD}
          size={IconSize.SMALL}
          className="download-button-icon"
          color={'black'}
        />
        <button className="sample" onClick={() => redirectToSample()}>
          Download Sample File
        </button>
      </CSVDownloadContainer>
      <ActionsBox>
        {pathname === ROUTES.UPLOAD_DISCOUNTS_FROM_FILE && !isWaitingValidation && (
          <>
            <ActionSubTitle>Set an expiration date:</ActionSubTitle>
            <Popup
              basic
              position="top left"
              trigger={getExpirationDatePickerTrigger()}
              on="click"
              className="publish-data-popup"
              open={expirationDatePickerOpen}
              onClose={() => setExpirationDatePickerOpen(prevState => !prevState)}
              closeOnDocumentClick
              closeOnEscape
            >
              <Popup.Content>
                <DatePicker
                  selectedDays={expirationDate ?? undefined}
                  onDayClick={(day, { selected }) => {
                    if (isExpirationDateInRange(day)) {
                      handleSetExpirationDate(selected ? undefined : day);
                      setExpirationDatePickerOpen(false);
                    }
                  }}
                  disabledDays={{ before: expirationDateDisabledBefore }}
                />
              </Popup.Content>
            </Popup>
          </>
        )}
        <>
          {!isWaitingValidation ? (
            <>
              <ActionSubTitle className="publish-picker">Set a published date:</ActionSubTitle>
              <Popup
                basic
                position="top left"
                trigger={getPublishDatePickerTrigger()}
                on="click"
                className="publish-data-popup"
                open={publishDatePickerOpen}
                onClose={() => setPublishDatePickerOpen(prevState => !prevState)}
                closeOnDocumentClick
                closeOnEscape
              >
                <Popup.Content>
                  <DatePicker
                    selectedDays={publishDate ?? undefined}
                    onDayClick={(day, { selected }) => {
                      if (isPublishDateInRange(day)) {
                        handleSetPublishDate(selected ? undefined : day);
                        setPublishDatePickerOpen(false);
                      }
                    }}
                    disabledDays={{
                      before: publishDateDisabledBefore,
                      after: publishDateDisabledAfter,
                    }}
                  />
                </Popup.Content>
              </Popup>
            </>
          ) : (
            <></>
          )}
          <ActionSubTitle className="info-message">
            {publishDate
              ? `${title} will publish on ${format(publishDate, 'MMM d, yyyy')}.`
              : `${title} will publish immediately.`}
          </ActionSubTitle>
          <Button
            loading={isLoading}
            theme={'fill'}
            size={'medium'}
            className={'ml-2xs'}
            disabled={!isEnabled}
            onClick={() => {
              trackBatchUploadPublishButtonClick();
              publishFile();
            }}
          >
            {buttonTitle}
          </Button>
        </>
      </ActionsBox>
    </BottomPanelWrapper>
  );
};

export default BottomPanelUploadFile;
