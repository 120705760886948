import styled from 'styled-components';
import { darkBlue, lighterGray } from '../../../../styles/colors';
import { FlexVerticalHelper } from '../../../../styles/app.styled';

export const ModalVerticalContentWrapper = styled.div`
  ${FlexVerticalHelper};
`;
export const CurvesSearchTableWrapper = styled.div`
  margin-top: 18px;
  overflow: auto;
  height: 285px;
  > .curve-manage-table.ui.table {
    .ui.checkbox input.hidden {
      z-index: 1;
    }
    .ui.checkbox input[type='checkbox'] {
      top: 4px;
      opacity: unset !important;
    }
    > tbody {
      tr {
        > td {
          vertical-align: top;
          &.align-middle {
            text-align: center;
          }
          &.icon-size > img {
            width: 10px;
          }
          &.fixed-width {
            max-width: 190px;
            overflow-wrap: break-word;
            max-height: 60px;
            overflow-y: auto;
          }
        }
      }
    }
  }
`;
export const ContentColumn = styled.div`
  flex: 0 0 40%;
  padding: 40px;
  &.small-bottom-interval {
    padding-bottom: 10px;
  }
  > div .ui.input.fixed-width {
    width: 400px;
  }
  &.small-top-interval {
    padding-top: 10px;
  }
  &:first-of-type {
    flex: 0 0 60%;
    border-right: 1px solid ${lighterGray};
  }
  &.scroll {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 500px;
    padding-right: 24px;
    overflow-y: auto;
  }
  > table {
    overflow-x: scroll;
  }
  > span {
    display: block;
    margin: 15px 0;
  }
`;
export const ModalSubTitle = styled.h4`
  display: block;
  padding: 0;
  margin: 0 0 24px 0;
  line-height: 32px;
  font-size: 16px;
  font-weight: 500;
  color: ${darkBlue};
  &.no-indent {
    margin: 0;
  }
`;
export const CellContainer = styled.div`
  &.open {
    height: auto;
  }
  &.closed {
    height: 22px;
    overflow: hidden;
  }
`;
export const WarningCellContainer = styled.div`
  margin: 10px;
`;
