import styled from 'styled-components';
import Sprite from '@wework/dieter-ui/dist/themes/dieter/assets/images/sprite.svg';
import { blue, darkGray } from '../../styles/colors';

export const ButtonLink = styled.span`
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  color: ${blue};
`;

export const BreadcrumbInnerRegionStyled = styled.header`
  display: block;
  line-height: 22px;
  padding: 20px 0;
  margin: 0 40px;
`;

export const LeftArrowBreadCrumb = styled.span`
  display: block;
  width: 16px;
  height: 12px;
  -webkit-mask: url(${Sprite}#arrow-left);
  mask: url(${Sprite}#arrow-left);
  float: left;
  background: ${darkGray};
  margin: 5px 4px 0 0;
`;
