import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { Button, IconName, IconType } from '@wework/ray2';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { Popup } from 'semantic-ui-react';
import { BottomPanelWrapper } from '../../../../../styles/sharedBottomPanel.styled';
import StandardBatchEditingComponent from '../standardPricingView/standardPricingTableFilterBar/standardPricingBatchEdit';
import { AuthzProps } from '../../../../../utils/constants';
import CancelFuturePricesConfirm from './confirmationPopup/cancelFuturePricesConfirm';
import ResetWorkingPricesConfirm from './confirmationPopup/resetWorkingPricesConfirm';
import EmployeePermissions from '../../../../../utils/store/permissions';
import {
  WorkingPriceScope,
  WorkingPricesQueryVariables,
} from '../../../../../generated/voyager/graphql';
import { routerHistory } from '../../../../../utils/store/configureStore';
import ROUTES from '../../../../../utils/routes/routes';
import { FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE } from '../../store/modules/publishPrice/publishPrice.ducks';
import { pageInfoSmall } from '../../../../../utils/store/store.constants';
import { getAllSelectedLocations } from '../../../../../sharedStore/modules/locations/locations.selector';
import {
  batchEditLoadingSelectorStore,
  standardPricingCancelPricesLoadingSelector,
  standardPricingRevertPricesLoadingSelector,
  standardPricingSelectedNodesSelector,
} from '../../store/modules/standardPricingItems/standardPricingItems.selector';

interface PublishChangesBottomPanelComponentProps extends AuthzProps {
  showBatchButtons?: boolean;
}

function PublishChangesBottomPanelComponent({
  showBatchButtons,
  requestedPermissions,
}: PublishChangesBottomPanelComponentProps): ReactElement {
  const dispatch = useDispatch();

  // STATE PROPS
  const allSelectedLocations = useSelector(getAllSelectedLocations);
  const selectedNodes = useSelector(standardPricingSelectedNodesSelector);

  const [batchPopupOpen, setBatchPopupOpen] = useState(false);
  const [cancelConfirmationModalOpen, setCancelConfirmationModalOpen] = useState(false);
  const [resetConfirmationModalOpen, setResetConfirmationModalOpen] = useState(false);
  const batchLoading = useSelector(batchEditLoadingSelectorStore);
  const cancelLoading = useSelector(standardPricingCancelPricesLoadingSelector);
  const resetLoading = useSelector(standardPricingRevertPricesLoadingSelector);
  const pricingRequestIsRunning = cancelLoading || resetLoading || batchLoading;
  const permissionForUser = get(
    requestedPermissions,
    EmployeePermissions.voyager_dedicated_space_price_publish,
    false,
  );
  const disableFlagFuturePrices =
    !cancelLoading &&
    (isEmpty(selectedNodes.filter(node => node.data.prices.length > 0)) ||
      !permissionForUser ||
      pricingRequestIsRunning);
  const disableFlagRevertPrices =
    !resetLoading &&
    (isEmpty(selectedNodes.filter(node => !!node.data.workingPrice)) || pricingRequestIsRunning);

  const disableFlagBatchEdit = !batchLoading && (isEmpty(selectedNodes) || pricingRequestIsRunning);

  const getWorkingPrices = useCallback(
    (payload: WorkingPricesQueryVariables) =>
      dispatch({ type: FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE, payload }),
    [dispatch],
  );

  const getPreviewsAndRedirectToPublish = () => {
    if (!isEmpty(selectedNodes)) {
      getWorkingPrices({
        page: pageInfoSmall,
        workingPricePreviewInput: {
          ids: selectedNodes.map(node => node.data.id),
          scope: WorkingPriceScope.PRICEABLE_ITEM,
        },
      });
    } else if (!isEmpty(allSelectedLocations)) {
      getWorkingPrices({
        page: pageInfoSmall,
        workingPricePreviewInput: {
          ids: allSelectedLocations.map(location => location.id),
          scope: WorkingPriceScope.LOCATION,
        },
      });
    }

    routerHistory.push({
      pathname: ROUTES.PUBLISH_SUMMARY,
    });
  };

  return (
    <BottomPanelWrapper>
      {showBatchButtons && (
        <Button
          size="medium"
          theme="text"
          onClick={() => {
            if (!pricingRequestIsRunning) setCancelConfirmationModalOpen(true);
          }}
          className={`${disableFlagFuturePrices ? 'text-red-80' : ''} ray-btn-styling`}
          disabled={disableFlagFuturePrices}
          loading={cancelLoading}
        >
          Cancel Future Prices
        </Button>
      )}
      {showBatchButtons && (
        <Button
          size="medium"
          theme="text"
          onClick={() => {
            if (!pricingRequestIsRunning) setResetConfirmationModalOpen(true);
          }}
          disabled={disableFlagRevertPrices}
          loading={resetLoading}
          className={`${disableFlagRevertPrices ? 'text-red-80' : ''} ray-btn-styling`}
        >
          Revert Prices to Last Published
        </Button>
      )}
      {showBatchButtons && (
        <Popup
          trigger={
            <Button
              className="btm-panel-btn-space"
              loading={batchLoading}
              disabled={disableFlagBatchEdit}
              size="medium"
              theme="outline"
              icon={batchPopupOpen ? IconName.CARET_DOWN : IconName.CARET_UP}
              iconType={IconType.OUTLINE}
            >
              Batch Edit
            </Button>
          }
          onClose={() => setBatchPopupOpen(false)}
          onOpen={() => {
            if (!pricingRequestIsRunning) setBatchPopupOpen(true);
          }}
          open={batchPopupOpen}
          on="click"
          content={<StandardBatchEditingComponent closePopup={() => setBatchPopupOpen(false)} />}
          basic
        />
      )}
      <Button
        size="medium"
        className="btm-panel-btn-space"
        theme="fill"
        onClick={getPreviewsAndRedirectToPublish}
        disabled={isEmpty(allSelectedLocations) || batchPopupOpen || pricingRequestIsRunning}
      >
        Preview
      </Button>
      <CancelFuturePricesConfirm
        closeModal={() => setCancelConfirmationModalOpen(false)}
        isOpen={cancelConfirmationModalOpen}
      />
      <ResetWorkingPricesConfirm
        closeModal={() => setResetConfirmationModalOpen(false)}
        isOpen={resetConfirmationModalOpen}
      />
    </BottomPanelWrapper>
  );
}

export default withRequestedAuthz<PublishChangesBottomPanelComponentProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_price_publish],
})(PublishChangesBottomPanelComponent);
