import styled from 'styled-components';
import { blue, darkGray, lightGray, pureBlack } from '../../../../../styles/colors';
import { FlexHorizontalHelper, FlexVerticalHelper } from '../../../../../styles/app.styled';

export const TableBlockWrapper = styled.div`
  > .discounts-terms-details-table.ui.table {
    margin: 0 0 10px 0;
    > thead {
      th {
        padding: 0 0 18px 0;
        background: white;
        overflow: visible;
        text-transform: unset;
        font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: ${darkGray};
      }
    }
    > tbody {
      td {
        height: 17px;
        padding: 0 0 12px;
        border: 0;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        color: ${pureBlack};
        &.override {
          color: ${blue};
        }
      }
      tr {
        &:last-of-type td {
          padding-bottom: 0;
        }
        &.no-data td {
          text-align: center;
          font-style: italic;
          font-size: 12px;
          font-weight: 400;
          color: ${lightGray};
        }
      }
    }
  }
`;

export const CurveInfoRow = styled.div`
  ${FlexHorizontalHelper};
  padding-left: 20px;
  justify-content: space-between;
  margin-top: 5px;
  &.center-align {
    align-items: center;
    margin-top: 10px;
  }
  > img {
    cursor: pointer;
  }
  div.inactive {
    opacity: 0.4;
  }
`;

export const CurveInfoBlock = styled.div`
  ${FlexVerticalHelper};
  max-width: 33%;
  > .title {
    font-size: 11px;
    color: ${lightGray};
  }
  > .content {
    font-size: 12px;
    overflow-wrap: break-word;
  }
`;
