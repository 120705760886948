import { Action, handleActions } from 'redux-actions';
import initialState, { initialUploadFileState } from 'utils/store/initialState';
import { createRDXConstant } from 'utils/store/store.constants';
import { FileErrorDescription, UploadFileSubset } from '../../entities/uploadFile';

// Action Constants
export const UPLOAD_FILE = createRDXConstant('UPLOAD_FILE');
export const UPLOAD_FILE_SUCCESS = createRDXConstant('UPLOAD_FILE_SUCCESS');
export const UPLOAD_FILE_ERROR = createRDXConstant('UPLOAD_FILE_ERROR');
export const UPLOAD_FILE_FORMAT_ERROR = createRDXConstant('UPLOAD_FILE_FORMAT_ERROR');
export const UPLOAD_FILE_SET_REASON = createRDXConstant('UPLOAD_FILE_SET_REASON');
export const UPLOAD_FILE_IS_ARPM_PRICE_UPLOADED = createRDXConstant(
  'UPLOAD_FILE_IS_ARPM_PRICE_UPLOADED',
);
export const UPLOAD_FILE_SET_PUBLISH_DATE = createRDXConstant('UPLOAD_FILE_SET_PUBLISH_DATE');
export const UPLOAD_FILE_SET_EXPIRATION_DATE = createRDXConstant('UPLOAD_FILE_SET_EXPIRATION_DATE');
export const UPLOAD_FILE_SET_FILE = createRDXConstant('UPLOAD_FILE_SET_FILE');

export const RESET_UPLOAD_FILE_STATE = createRDXConstant('RESET_UPLOAD_FILE_STATE');

// Reducer
export const uploadFileReducer = handleActions<UploadFileSubset, any>(
  {
    [UPLOAD_FILE]: (state: UploadFileSubset) => ({
      ...state,
    }),
    [UPLOAD_FILE_SET_REASON]: (state: UploadFileSubset, action: Action<any>) => {
      const reason = action.payload;
      return {
        ...state,
        reason,
      };
    },
    [UPLOAD_FILE_IS_ARPM_PRICE_UPLOADED]: (state: UploadFileSubset, action: Action<any>) => {
      const isARPMPrice = action.payload;
      return {
        ...state,
        isARPMPrice,
      };
    },
    [UPLOAD_FILE_SET_PUBLISH_DATE]: (state: UploadFileSubset, action: Action<any>) => {
      const publishDate = action.payload;
      return {
        ...state,
        publishDate,
      };
    },
    [UPLOAD_FILE_SET_EXPIRATION_DATE]: (state: UploadFileSubset, action: Action<any>) => {
      const expirationDate = action.payload;
      return {
        ...state,
        expirationDate,
      };
    },
    [UPLOAD_FILE_SET_FILE]: (state: UploadFileSubset, action: Action<any>) => {
      const loadedFile = action.payload;
      return {
        ...state,
        loadedFile,
      };
    },
    // Reducer to handle upload file request.
    [UPLOAD_FILE]: (state: UploadFileSubset) => ({
      ...state,
      isLoading: true,
      fileErrors: [],
      fileFormatError: '',
    }),
    // Reducer to handle successfully uploaded file.
    [UPLOAD_FILE_SUCCESS]: (state: UploadFileSubset) => ({
      ...state,
      isLoading: false,
      loadedFile: null,
      publishDate: null,
      expirationDate: null,
      reason: '',
      isARPMPrice: true,
    }),
    // Reducer to handle file errors.
    [UPLOAD_FILE_ERROR]: (state: UploadFileSubset, action: Action<FileErrorDescription[]>) => {
      const fileErrors = action.payload;
      return {
        ...state,
        isLoading: false,
        fileErrors,
      };
    },
    // Reducer to handle file format errors.
    [UPLOAD_FILE_FORMAT_ERROR]: (state: UploadFileSubset, action: Action<string>) => ({
      ...state,
      isLoading: false,
      fileFormatError: action.payload,
    }),

    [RESET_UPLOAD_FILE_STATE]: (state: UploadFileSubset) => ({
      ...state,
      ...initialUploadFileState,
    }),
  },
  initialState.uploadFile,
);
