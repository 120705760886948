import { find } from 'lodash';
import { StoreInterface } from '../../../../../utils/store/store.interface';
import { FetchState, Unassigned } from '../../../../../utils/sharedTypes';
import {
  DiscountCurveStatus,
  DiscountItemsSubset,
  GridDiscountPagedItem,
  Override,
} from '../../entities/discountItems';
import {
  Curve,
  DiscountHistory,
  Operator,
  PriceableItem,
  TermType,
} from '../../../../../generated/voyager/graphql';

const discountItemsSelector = (state: StoreInterface) => state?.discountItems;

export const batchFetchStateSelectorDiscountStore = (state: StoreInterface): boolean | Unassigned =>
  discountItemsSelector(state)?.batchFetchInProgressDiscount;

export const discountItemsSubsetSelector = (
  state: DiscountItemsSubset,
): GridDiscountPagedItem[] | Unassigned => state?.allDiscountItems;

export const discountItemsSubsetSelectorStore = (
  state: StoreInterface,
): GridDiscountPagedItem[] | Unassigned => discountItemsSelector(state)?.allDiscountItems;

export const prevDiscountItemsSubsetSelectorStore = (
  state: StoreInterface,
): GridDiscountPagedItem[] | Unassigned => discountItemsSelector(state)?.allPrevDiscountItems;

export const sidePanelParamSelector = (state: StoreInterface): boolean =>
  discountItemsSelector(state)?.sidePanelParam;

export const currentSelectedPriceableItemSelector = (state: StoreInterface): string | null =>
  discountItemsSelector(state)?.currentSelectedPriceableItemId;

export const currentDiscountDetailsByIdSelector =
  (id: string) =>
  (state: StoreInterface): PriceableItem | Unassigned =>
    find(state?.discountItems?.allDiscountItems, { id });

export const discountItemsTermTypes = (state: StoreInterface): TermType[] =>
  discountItemsSelector(state)?.termTypes;

export const discountItemsOverridesSubset = (state: DiscountItemsSubset): Override[] =>
  state?.overrides;

export const discountItemsOverrides = (state: StoreInterface): Override[] =>
  discountItemsSelector(state)?.overrides;

export const publishDiscountsPublishSaveSelectorStore = (state: StoreInterface): boolean =>
  discountItemsSelector(state)?.publish;

export const curvesLoadingSelector = (state: StoreInterface): boolean =>
  discountItemsSelector(state)?.curvesLoading;

export const curvesSelector = (state: StoreInterface): Curve[] =>
  discountItemsSelector(state)?.curves;

export const mutateCurvesLoadingSelector = (state: StoreInterface): boolean =>
  discountItemsSelector(state)?.mutateCurvesLoading;

export const discountCurvesItemsSubsetSelector = (
  state: DiscountItemsSubset,
): Curve[] | Unassigned => state?.allDiscountCurves;

export const batchFetchStateSubsetSelectorDiscountCurves = (
  state: DiscountItemsSubset,
): boolean | Unassigned => state?.batchFetchInProgressAllDiscountCurves;

export const discountCurvesItemsSelector = (state: StoreInterface): Curve[] | Unassigned =>
  discountItemsSelector(state)?.allDiscountCurves;

export const batchFetchStateSelectorDiscountCurves = (
  state: StoreInterface,
): boolean | Unassigned => discountItemsSelector(state)?.batchFetchInProgressAllDiscountCurves;

export const discountCurvesFilterSelector = (state: StoreInterface): Set<DiscountCurveStatus> =>
  discountItemsSelector(state)?.curveFilters;

export const userOperatorsLoadingSelector = (state: StoreInterface): boolean =>
  discountItemsSelector(state)?.userOperatorsLoading;

export const userOperatorsSelector = (state: StoreInterface): Operator[] =>
  discountItemsSelector(state)?.userOperators;

export const getDiscountHistoryState = (state: StoreInterface): FetchState =>
  discountItemsSelector(state)?.discountHistoryFetchState;

export const getDiscountHistoryElements = (state: StoreInterface): DiscountHistory[] | null =>
  discountItemsSelector(state)?.discountHistory;

export const cancelFutureDiscountIdsSelector = (state: StoreInterface): string[] =>
  discountItemsSelector(state)?.cancelFutureDiscountIds;

export const cancelFutureDiscountLoadingSelector = (state: StoreInterface): boolean =>
  discountItemsSelector(state)?.cancelFutureDiscountLoading;
