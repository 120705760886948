import { DocumentNode } from 'graphql';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { Action } from 'redux-actions';
import { graphQLClient } from '../../../../index';
import {
  GET_LOCATION_ACTION_ITEMS,
  GET_LOCATION_ACTION_ITEMS_ERROR,
  GET_LOCATION_ACTION_ITEMS_SUCCESS,
} from './actionItems.ducks';
import { ActionItemGroup, PageInfo } from '../../../../generated/voyager/graphql';
import FETCH_LOCATIONS_ACTION_ITEMS from './actionItems.query';

const executeGraphQLQuery = (query: DocumentNode, variable?: Object): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query,
    variables: variable,
  });

function* fetchLocationActionItemsQuery(
  action: Action<{ group: ActionItemGroup; page: PageInfo }>,
) {
  try {
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_LOCATIONS_ACTION_ITEMS, {
      group: action.payload.group,
      page: action.payload.page,
    });

    if (!isEmpty(errors)) {
      toast.error(`Fetch action items error - ${errors[0]}`);
      yield put({ type: GET_LOCATION_ACTION_ITEMS_ERROR, payload: action.payload.group });
    }

    yield put({
      type: GET_LOCATION_ACTION_ITEMS_SUCCESS,
      payload: { group: action.payload.group, locations: data.locationActionItems },
    });
  } catch (e: any) {
    toast.error(`Fetch action items error - ${get(e, 'message', 'Unknown Error')}`);
    yield put({ type: GET_LOCATION_ACTION_ITEMS_ERROR, payload: action.payload.group });
  }
}

export default function* actionItemSaga(): any {
  yield all([takeEvery(GET_LOCATION_ACTION_ITEMS, fetchLocationActionItemsQuery)]);
}
