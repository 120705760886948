import { all, takeLatest } from 'redux-saga/effects';
import {
  MODIFY_STANDARD_PRICING_FILTER_PARAMS,
  CLEAR_STANDARD_PRICING_FILTER_PARAMS,
} from './standardPricingFilters.ducks';
import { clearGeneratedUrlWorker } from '../../../../../../utils/helpers';

export default function* standardPricingFiltersSaga(): any {
  yield all([takeLatest(MODIFY_STANDARD_PRICING_FILTER_PARAMS, clearGeneratedUrlWorker)]);
  yield all([takeLatest(CLEAR_STANDARD_PRICING_FILTER_PARAMS, clearGeneratedUrlWorker)]);
}
