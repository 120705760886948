import { IRowNode } from 'ag-grid-community';
import { IconName } from '@wework/ray2';
import { FetchState } from '../../../../../utils/sharedTypes';
import {
  GeoHierarchy,
  PriceHierarchy,
  GeoHierarchyAttributeLabel,
  Product,
} from '../../../../../generated/voyager/graphql';

export interface OnDemandPricingItemsSubset {
  allItems: GeoHierarchy[];
  itemsToPublish: GeoHierarchy[];
  productsToPublish: Product[];
  isLoadingFetchState: boolean;
  onDemandProducts: Product[];
  productsLoadingFetchState: boolean;
  editMode: boolean;
  publishPrices: FetchState;
  setPricesLoading: boolean;
  cancelPricesLoading: boolean;
  revertPricesLoading: boolean;
  currentChangeLog: GeoHierarchy[];
  currentChangeLogFetchState: FetchState;
  selectedNodes: IRowNode[];
  priceHistory: PriceHierarchy[];
  priceHistoryLoadingFetchState: boolean;
  priceHistoryGeo: GeoHierarchy | null;
  updateGeoHierarchyAttributesLoading: boolean;
}

export interface PublishSummaryOnDemand {
  name: string;
  currencyIsoCode: string;
}

export interface AttributeProps {
  label: GeoHierarchyAttributeLabel;
  displayName: string;
  type: 'boolean';
  icon: IconName;
  attributeInformation: AttributeInfo;
}

export interface AttributeInfo {
  summary: string;
  details: string[];
}

export const supportedGeoHierarchyAttributes: AttributeProps[] = [
  {
    label: GeoHierarchyAttributeLabel.NON_LINEAR_PRICING_ENABLED,
    displayName: 'Non-linear pricing enabled',
    type: 'boolean',
    icon: IconName.CHART,
    attributeInformation: {
      summary: 'Apply discounts based on inventory capacity:',
      details: [
        '5-9 = 5% discount',
        '10-15 = 10% discount',
        '16-19 = 15% discount',
        '20+ = 20% discount',
      ],
    },
  },
];
