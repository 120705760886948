import { ActionItemGroup } from '../../generated/voyager/graphql';

const getGroupName = (group: ActionItemGroup): string => {
  switch (group) {
    case ActionItemGroup.LOCATION_NO_BREAKEVEN:
      return 'Locations with no breakeven';
    case ActionItemGroup.LOCATION_NO_LIVE_BUDGET:
      return 'Locations with no live budget';
    case ActionItemGroup.PRICEABLE_ITEM_NO_DISCOUNT:
      return 'Priceable items with no discount';
    case ActionItemGroup.PRICEABLE_ITEM_NO_CURRENT_PRICE:
      return 'Priceable items with no current price set';
    case ActionItemGroup.PRICEABLE_ITEM_EXPIRING_OVERRIDE:
      return 'Priceable items with expiring override within the next 14 days';
    default:
      return '';
  }
};

export default getGroupName;
