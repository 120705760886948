import { IHeaderParams } from 'ag-grid-community';
import * as React from 'react';
import { ReactElement } from 'react';
import InfoTooltip from '../../../../sharedComponents/tooltip/infoTooltip';
import InfoHeaderContainer from './infoHeaderComponent.styled';

interface InfoHeader extends IHeaderParams {
  info: string;
}

function InfoHeaderComponent({ info, displayName }: InfoHeader): ReactElement {
  return (
    <InfoHeaderContainer>
      {displayName} <InfoTooltip popupContent={<div>{info}</div>} />
    </InfoHeaderContainer>
  );
}

export default InfoHeaderComponent;
