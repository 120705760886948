import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import cn from 'classnames';
import { isEmpty, orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import {
  isPercentageNull,
  toTitleCase,
} from '../../../../pricing/standardPricing/components/helpers';
import {
  PanelSectionItem,
  SectionBody,
  SectionHeader,
} from '../../../../../sharedComponents/sidePanelComponent/detailSidePanel.styled';
import { ChangelogListItem } from '../../../../pricing/standardPricing/components/pricingTable/pricingTable.styled';
import { formatDate } from '../../../../../utils/helpers';
import { getDiscountHistoryState } from '../../../store/modules/discountItems/discountItems.selector';
import { ChangelogList } from '../../discountsTable/discountAGGrid.styled';
import { DiscountHistory } from '../../../../../generated/voyager/graphql';

interface DiscountItemsDiscountHistoryProps {
  label: string;
  data: DiscountHistory[];
  timeZone?: string;
}

function DiscountsItemDiscountHistory({
  label,
  data,
  timeZone,
}: DiscountItemsDiscountHistoryProps): ReactElement {
  const fetchDiscountHistoryState = useSelector(getDiscountHistoryState);

  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);
  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  if (isEmpty(data)) {
    return <></>;
  }

  return (
    <PanelSectionItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <SectionHeader className="section-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{label} Discount History</span>
          <i className="toggle-accordion-icon" />
        </button>
      </SectionHeader>
      <SectionBody className="section-body">
        <ChangelogList className="full-height">
          {fetchDiscountHistoryState?.loaded && data?.length ? (
            orderBy(data, 'publishDate', 'desc').map(discountHistory => (
              <ChangelogListItem key={uuidV4()} className="side-panel">
                {isPercentageNull(discountHistory.base)} on{' '}
                {formatDate(discountHistory.publishDate, timeZone)} by{' '}
                {toTitleCase(discountHistory.createdBy)}
                {discountHistory.expirationDate
                  ? `, expired on ${formatDate(discountHistory.expirationDate, timeZone)}`
                  : ''}
              </ChangelogListItem>
            ))
          ) : (
            <div>No Change Log</div>
          )}
        </ChangelogList>
      </SectionBody>
    </PanelSectionItem>
  );
}

export default DiscountsItemDiscountHistory;
