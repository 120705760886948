// eslint-disable-next-line import/prefer-default-export
import styled from 'styled-components';
import { Label } from 'semantic-ui-react';
import { lighterGray } from '../../styles/colors';

const LabelWrapper = styled(Label)`
  margin: 5px !important;
  background-color: #ff0000;
  > svg {
    display: inline-block !important;
    height: 12px !important;
    width: 12px !important;
    &:hover {
      color: ${lighterGray};
    }
  }
`;

export default LabelWrapper;
