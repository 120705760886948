import { map } from 'lodash';
import { DropdownItemProps } from 'semantic-ui-react';
import { StoreInterface } from '../../../utils/store/store.interface';
import transformOption from '../../utils/store.transformers';

const marketsSelector = (state: StoreInterface): DropdownItemProps[] =>
  map(state?.markets?.allMarkets, market =>
    market ? transformOption(market) : ({} as DropdownItemProps),
  );

export default marketsSelector;
