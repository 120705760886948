import { FileErrorDescription, UploadFileSubset } from 'sharedStore/entities/uploadFile';
import { StoreInterface } from 'utils/store/store.interface';

export const publishFileSubsetSelector = (state: StoreInterface): UploadFileSubset =>
  state?.uploadFile;

export const publishFileLoadingSelector = (state: StoreInterface): boolean =>
  publishFileSubsetSelector(state)?.isLoading;

export const publishFileLoadedFileSelector = (state: StoreInterface): File | null =>
  publishFileSubsetSelector(state)?.loadedFile;

export const publishFileReasonSelector = (state: StoreInterface): string =>
  publishFileSubsetSelector(state)?.reason;

export const publishFilePublishDateSelector = (state: StoreInterface): Date | null =>
  publishFileSubsetSelector(state)?.publishDate;

export const publishFileExpirationDateSelector = (state: StoreInterface): Date | null =>
  publishFileSubsetSelector(state)?.expirationDate;

export const publishFileIsARPMPriceSelector = (state: StoreInterface): boolean =>
  publishFileSubsetSelector(state)?.isARPMPrice;

export const publishFileErrorsSelector = (state: StoreInterface): FileErrorDescription[] =>
  publishFileSubsetSelector(state)?.fileErrors;

export const publishFileFormatErrorSelector = (state: StoreInterface): string =>
  publishFileSubsetSelector(state)?.fileFormatError;
