import { StoreInterface } from '../../../../../utils/store/store.interface';
import { InventoryReconciliationByLocationSubset } from '../../entities/inventoryReconciliationByLocation';
import { Location } from '../../../../../generated/voyager/graphql';

export const inventoryReconciliationByLocationSelector = (
  state: StoreInterface,
): InventoryReconciliationByLocationSubset => state?.inventoryReconciliationByLocation;

export const inventoryReconciliationByLocationIsLoadingSelector = (
  state: StoreInterface,
): boolean => inventoryReconciliationByLocationSelector(state)?.isLoading;

export const inventoryReconciliationByLocationLocationsSelector = (
  state: StoreInterface,
): Location[] => inventoryReconciliationByLocationSelector(state)?.locations;

export const inventoryReconciliationByLocationLocationIdsSelector = (
  state: StoreInterface,
): string[] => inventoryReconciliationByLocationSelector(state)?.locationIds;

export const inventoryReconciliationByLocationIsLocationIdsValidSelector = (
  state: StoreInterface,
): boolean => inventoryReconciliationByLocationSelector(state)?.isLocationIdsValid;
