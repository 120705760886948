import React from 'react';
import { isEmpty } from 'lodash';
import { get } from 'get-wild';
import ActionItemBlock from './actionItemBlock';
import { ActionBlocksList } from './actionItems.styled';
import { ActionItemGroup } from '../../../generated/voyager/graphql';
import EmployeePermissions from '../../../utils/store/permissions';
import { AuthzProps } from '../../../utils/constants';

const ActionItemsList = ({ requestedPermissions }: AuthzProps) => {
  const blocks = [];
  if (get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_view, false)) {
    blocks.push(
      <ActionItemBlock
        key={ActionItemGroup.PRICEABLE_ITEM_NO_CURRENT_PRICE}
        group={ActionItemGroup.PRICEABLE_ITEM_NO_CURRENT_PRICE}
      />,
    );
  }
  if (get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_discount_view, false)) {
    blocks.push(
      <ActionItemBlock
        key={ActionItemGroup.PRICEABLE_ITEM_EXPIRING_OVERRIDE}
        group={ActionItemGroup.PRICEABLE_ITEM_EXPIRING_OVERRIDE}
      />,
      <ActionItemBlock
        key={ActionItemGroup.PRICEABLE_ITEM_NO_DISCOUNT}
        group={ActionItemGroup.PRICEABLE_ITEM_NO_DISCOUNT}
      />,
    );
  }
  if (
    get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_breakeven_view, false)
  ) {
    blocks.push(
      <ActionItemBlock
        key={ActionItemGroup.LOCATION_NO_LIVE_BUDGET}
        group={ActionItemGroup.LOCATION_NO_LIVE_BUDGET}
      />,
      <ActionItemBlock
        key={ActionItemGroup.LOCATION_NO_BREAKEVEN}
        group={ActionItemGroup.LOCATION_NO_BREAKEVEN}
      />,
    );
  }
  return (
    <ActionBlocksList as="ul">
      {!isEmpty(blocks) ? blocks : <div>No Action items available</div>}
    </ActionBlocksList>
  );
};
export default ActionItemsList;
