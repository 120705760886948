import styled from 'styled-components';
import { darkBlue } from '../../styles/colors';

export const ErrorTitle = styled.p`
  font-size: 80px;
  font-weight: 600;
  line-height: normal;
  color: ${darkBlue};
  margin: 0;
  text-align: center;
`;
export const ErrorSubTitle = styled.p`
  font-size: 23px;
  font-weight: 400;
  line-height: normal;
  color: ${darkBlue};
  margin-bottom: 30px;
  text-align: center;
`;
export const ErrorCatImage = styled.img`
  width: 426px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
export const ErrorPageButtonFlex = styled.div`
  margin-top: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  text-align: center;
`;
export const ErrorPageContainer = styled.div`
  grid-column: 2/10;
  grid-row: 8/2;
  margin: auto;
  padding: 32px 24px 0;
  animation: slide-up2 0.8s ease;
  @keyframes slide-up2 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
