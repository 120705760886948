import fetch from 'cross-fetch';
import AppConfig from '../../../config';

export const graphqlClientOptions = {
  uri: AppConfig.BACKEND_URL,
  credentials: 'same-origin',
  fetch,
};

// This client options enables batching.
// By default batching has following option: [Can be modified]
// batchMax: a max number of items to batch, defaults at 10
// batchInterval: the interval at which to batch (in ms), defaults to 10
/* batchKey: a function that accepts an operation and returns a string key,
   which uniquely names the batch the operation belongs to, defaults to returning the same string */
export const graphqlBatchClientOptions = {
  uri: AppConfig.BACKEND_URL,
  credentials: 'same-origin',
  fetch,
  headers: { batch: 'true ' },
};
