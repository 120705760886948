import { Action, handleActions } from 'redux-actions';
import initialState from '../../../../../utils/store/initialState';
import { GeoHierarchyReconciliationSubset } from '../../entities/geoHierarchyReconciliation';
import { createRDXConstant } from '../../../../../utils/store/store.constants';

export const GEO_HIERARCHY_RECONCILIATION = createRDXConstant('GEO_HIERARCHY_RECONCILIATION');
export const GEO_HIERARCHY_RECONCILIATION_SUCCESS = createRDXConstant(
  'GEO_HIERARCHY_RECONCILIATION_SUCCESS',
);
export const GEO_HIERARCHY_RECONCILIATION_ERROR = createRDXConstant(
  'GEO_HIERARCHY_RECONCILIATION_ERROR',
);

export const geoHierarchyReconciliationReducer = handleActions<
  GeoHierarchyReconciliationSubset,
  any
>(
  {
    [GEO_HIERARCHY_RECONCILIATION]: (state: GeoHierarchyReconciliationSubset) => ({
      ...state,
      fetchState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [GEO_HIERARCHY_RECONCILIATION_SUCCESS]: (state: GeoHierarchyReconciliationSubset) => ({
      ...state,
      fetchState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [GEO_HIERARCHY_RECONCILIATION_ERROR]: (
      state: GeoHierarchyReconciliationSubset,
      action: Action<string>,
    ) => ({
      ...state,
      fetchState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
  },
  initialState.geoHierarchyReconciliation,
);
