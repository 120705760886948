import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { Button, Icon, IconName, IconSize } from '@wework/ray2';
import { Chip, DatePicker } from '@wework/dieter-ui';
import { format, isBefore } from 'date-fns';
import cn from 'classnames';
import { utcToZonedTime } from 'date-fns-tz';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { CSVLink } from 'react-csv';
import {
  onDemandPricingItemsToPublishSelector,
  onDemandPricingProductsToPublishSelector,
  onDemandPublishPricesSelector,
} from '../../store/modules/onDemand/onDemandPricing.selector';
import { generateWorkingPriceOnDemandArr } from '../onDemandPricing.helpers';
import {
  ActionsBox,
  ActionSubTitle,
  BottomPanelWrapper,
  CSVDownloadContainer,
} from '../../../../../styles/sharedBottomPanel.styled';
import EmployeePermissions from '../../../../../utils/store/permissions';
import { AuthzProps } from '../../../../../utils/constants';
import OnDemandPublishWorkingPricesConfirm from '../onDemandPricingPublish/confirmationPopup/onDemandPublishWorkingPricesConfirm';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import { PublishSummaryOnDemand } from '../../store/entities/onDemandPricing';

interface OnDemandBottomPanelProps {
  transformedWorkingPrice: PublishSummaryOnDemand[];
}

function BottomPanelOnDemandPricingPublish(
  props: AuthzProps & OnDemandBottomPanelProps,
): ReactElement {
  // LOCAL VARIABLES
  const [open, setOpen] = useState<boolean>(false);
  const [publishDate, setPublishDate] = useState<{ selectedDay: Date | undefined }>({
    selectedDay: undefined,
  });
  const [publishConfirmationModalOpen, setPublishConfirmationModalOpen] = useState(false);
  // STATE PROPS
  const publish = useSelector(onDemandPublishPricesSelector);
  const itemsToPublish = useSelector(onDemandPricingItemsToPublishSelector);
  const productsToPublish = useSelector(onDemandPricingProductsToPublishSelector);
  const utcDate = Date.now();
  const workingPricesToBePublished = generateWorkingPriceOnDemandArr(
    itemsToPublish,
    productsToPublish.map(product => product.id),
  );
  const timeZones = workingPricesToBePublished?.map(o => o.timeZone ?? 'UTC');
  const publishDateDisabledBefore = [...new Set(timeZones)]
    .map(timeZone => utcToZonedTime(utcDate, timeZone as string))
    .reduce((time1, time2) => (time1 > time2 ? time1 : time2), new Date());

  publishDateDisabledBefore.setHours(0, 0, 0, 0);

  /**
   * Date picker trigger.
   */
  function datePickerTrigger(): ReactElement {
    return (
      <Chip
        className={cn({ active: !!publishDate.selectedDay }, 'date-picker-trigger')}
        active={!!publishDate.selectedDay}
        onClick={() => setOpen(prevState => !prevState)}
      >
        {publishDate.selectedDay ? format(publishDate.selectedDay, 'MMM d, yyyy') : 'Select Date'}
        <i />
      </Chip>
    );
  }

  const onDemandCSVPublishDownload = () => {
    trackAnalytics('On Demand - Publish Data CSV Download Button Click', {
      workflow: 'On Demand - Publish Data CSV Download',
      object_type: 'button',
      object_name: 'Publish Data CSV Download',
    });
  };

  const dynamicColumns: { label: string; key: string }[] = [];
  productsToPublish.forEach(eachProd => {
    dynamicColumns.push({
      label: `${eachProd.name} | OLD PRICE`,
      key: `${eachProd.name}| old_Price`,
    });
    dynamicColumns.push({
      label: `${eachProd.name} | NEW PRICE`,
      key: `${eachProd.name}| new_Price`,
    });
  });
  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Currency Iso Code', key: 'currencyIsoCode' },
    ...dynamicColumns,
  ];

  return (
    <BottomPanelWrapper className="publish-panel">
      {!publish.loading && !isEmpty(itemsToPublish) ? (
        <CSVDownloadContainer>
          <CSVLink
            data={props.transformedWorkingPrice}
            headers={headers}
            filename={'my-file.csv'}
            onClick={onDemandCSVPublishDownload}
            className="btn btn-primary download-button"
            target="_blank"
          >
            <Icon
              icon={IconName.DOWNLOAD}
              size={IconSize.SMALL}
              className="download-button-icon"
              color={'black'}
            />
            Download CSV File
          </CSVLink>
        </CSVDownloadContainer>
      ) : (
        <div />
      )}
      <ActionsBox>
        <ActionSubTitle>Set a published date:</ActionSubTitle>
        <Popup
          basic
          position="top left"
          trigger={datePickerTrigger()}
          on="click"
          className="publish-data-popup"
          open={open}
          onClose={() => setOpen(prevState => !prevState)}
          closeOnDocumentClick
          closeOnEscape
        >
          <Popup.Content>
            <DatePicker
              selectedDays={publishDate.selectedDay}
              onDayClick={(day, { selected }) => {
                if (!isBefore(day, publishDateDisabledBefore)) {
                  setPublishDate({ selectedDay: selected ? undefined : day });
                  setOpen(false);
                }
              }}
              disabledDays={{ before: publishDateDisabledBefore }}
            />
          </Popup.Content>
        </Popup>
        <ActionSubTitle className="info-message">
          {publishDate.selectedDay
            ? `Prices will publish on ${format(publishDate.selectedDay, 'MMM d, yyyy')}.`
            : 'Prices will publish immediately.'}
        </ActionSubTitle>
        <Button
          loading={publish.loading}
          theme={'fill'}
          size={'medium'}
          className={'ml-sm'}
          onClick={() => setPublishConfirmationModalOpen(true)}
          disabled={
            isEmpty(itemsToPublish) ||
            !get(
              props.requestedPermissions,
              EmployeePermissions.voyager_on_demand_price_publish,
              false,
            )
          }
        >
          Publish
        </Button>
        <OnDemandPublishWorkingPricesConfirm
          closeModal={() => setPublishConfirmationModalOpen(false)}
          isOpen={publishConfirmationModalOpen}
          publishDate={publishDate.selectedDay}
        />
      </ActionsBox>
    </BottomPanelWrapper>
  );
}

export default withRequestedAuthz<AuthzProps & OnDemandBottomPanelProps>({
  permissions: [EmployeePermissions.voyager_on_demand_price_publish],
})(BottomPanelOnDemandPricingPublish);
