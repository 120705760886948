import * as React from 'react';
import { ReactElement } from 'react';
import { SummaryNote } from '../../pricing/standardPricing/components/pricingPublish/pricingPublish.styled';
import InfoTooltip from '../../../sharedComponents/tooltip/infoTooltip';
import { TooltipPosition } from '../../../utils/sharedTypes';

function CustomTermsInfo(props: {
  textOverride?: string;
  tooltipPosition: TooltipPosition;
  className?: string;
}): ReactElement {
  return (
    <SummaryNote className={`info no-indent ${props.className}` ?? ''}>
      {props.textOverride ?? 'What about terms between 6M, 12M, etc?'}
      <InfoTooltip
        tooltipPosition={props.tooltipPosition}
        popupClassName={'publish'}
        popupContent={
          <>
            <p>Discounts for intermediate terms will be applied as follows:</p>
            <ol>
              <li>M2M discounts will be applied to 1M</li>
              <li>6M discounts will be applied to 2-6M</li>
              <li>12M discounts will be applied to 7–12M</li>
              <li>24M discounts will be applied to 13–24M</li>
              <li>36M discounts will be applied to 25–36M+</li>
            </ol>
          </>
        }
      />
    </SummaryNote>
  );
}

export default CustomTermsInfo;
