// This file uses Redux Beacon to track analytics for segment based on store Actions.
// This is a very powerful tool, please go through the link below and utilize them wherever you find the need.
// https://github.com/rangle/redux-beacon
import { trackEvent } from '@redux-beacon/segment';
import { ENABLE_EDIT_MODE_BREAKEVEN } from '../../app/breakeven/store/modules/breakevenItems/breakevenItems.ducks';
import {
  ENABLE_EDIT_MODE_ON_DEMAND,
  FETCH_CURRENT_ON_DEMAND_CHANGE_LOG,
  OPEN_PRICE_HISTORY_SIDE_PANEL,
} from '../../app/pricing/onDemandPricing/store/modules/onDemand/onDemandPricing.ducks';

const breakevenEditModeOn =
  window?.analytics &&
  trackEvent((): any => ({
    name: 'Breakeven - Edit Button Enable',
    properties: {
      workflow: 'Breakeven Edit',
      object_type: 'button',
      object_name: 'Edit Enable',
    },
  }));

const onDemandEditModeOn =
  window?.analytics &&
  trackEvent((): any => ({
    name: 'On Demand - Edit Button Enable',
    properties: {
      workflow: 'On Demand Edit',
      object_type: 'button',
      object_name: 'Edit Enable',
    },
  }));

const onDemandSidePanel =
  window?.analytics &&
  trackEvent((payload): any => ({
    name: 'On Demand - Side Panel Open',
    properties: {
      workflow: 'On Demand Side Panel',
      object_type: 'button',
      object_name: 'Side Panel Open',
      object_value: payload?.id,
    },
  }));

const onDemandChangeLog =
  window?.analytics &&
  trackEvent((payload): any => ({
    name: 'On Demand - Change Log Open',
    properties: {
      workflow: 'On Demand Change Log',
      object_type: 'button',
      object_name: 'Change Log Open',
      object_value: payload?.id,
    },
  }));

const eventsMapper = (action: any): any => {
  switch (action.type) {
    case ENABLE_EDIT_MODE_BREAKEVEN:
      return breakevenEditModeOn;
    case ENABLE_EDIT_MODE_ON_DEMAND:
      return onDemandEditModeOn;
    case OPEN_PRICE_HISTORY_SIDE_PANEL:
      return onDemandSidePanel;
    case FETCH_CURRENT_ON_DEMAND_CHANGE_LOG:
      return onDemandChangeLog;
    default:
      return [];
  }
};

export default eventsMapper;
