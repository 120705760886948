import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { trackAnalytics } from '../../utils/analytics/helpers';
import { routerHistory } from '../../utils/store/configureStore';
import {
  CLEAR_DISCOUNT_ITEMS,
  RESET_DISCOUNT_STORE,
} from '../../app/discounts/store/modules/discountItems/discountItems.ducks';
import { CLEAR_FILTER_PARAMS } from '../../app/discounts/store/modules/filters/filters.ducks';
import ROUTES from '../../utils/routes/routes';
import { CLEAR_STANDARD_PRICING_STORE } from '../../app/pricing/standardPricing/store/modules/standardPricingItems/standardPricingItems.ducks';
import { CLEAR_STANDARD_PRICING_FILTER_PARAMS } from '../../app/pricing/standardPricing/store/modules/standardPricingFilters/standardPricingFilters.ducks';
import {
  CLEAR_BREAKEVEN_ITEMS,
  CLEAR_MARKET_SG_AND_A,
} from '../../app/breakeven/store/modules/breakevenItems/breakevenItems.ducks';
import {
  BreadcrumbInnerRegionStyled,
  ButtonLink,
  LeftArrowBreadCrumb,
} from './breadcrumbComponent.styled';
import { CLEAR_LOCATIONS_BY_IDS } from '../../sharedStore/modules/locations/locations.ducks';

function BreadcrumbComponent(props: { breadcrumbs: any }): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  // Resetting discount store and clearing discount filter params.
  const resetDiscountStore = useCallback(() => {
    dispatch({ type: RESET_DISCOUNT_STORE });
    dispatch({ type: CLEAR_FILTER_PARAMS });
  }, [dispatch]);
  const resetStoreBackToGrid = useCallback(() => {
    dispatch({ type: CLEAR_DISCOUNT_ITEMS });
    dispatch({ type: CLEAR_FILTER_PARAMS });
    dispatch({ type: CLEAR_STANDARD_PRICING_STORE });
    dispatch({ type: CLEAR_STANDARD_PRICING_FILTER_PARAMS });
    dispatch({ type: CLEAR_LOCATIONS_BY_IDS }); // Fix for filters not loaded on generate url + redirect
  }, [dispatch]);
  const resetBreakevenStore = useCallback(() => {
    dispatch({ type: CLEAR_BREAKEVEN_ITEMS });
    dispatch({ type: CLEAR_MARKET_SG_AND_A });
  }, [dispatch]);

  // Static breadcrumbs.
  const app = props?.breadcrumbs[1]?.key;
  const page = props?.breadcrumbs[2]?.key;

  const pageName = new Map();
  pageName
    .set(ROUTES.STANDARD_PRICING, 'Pricing Management')
    .set(ROUTES.PUBLISH_SUMMARY, 'Review Changes')
    .set(ROUTES.PUBLISH_DISCOUNT_SUMMARY, 'Review Changes')
    .set(ROUTES.DISCOUNT_TABLEVIEW, 'Discount Management')
    .set(ROUTES.BREAKEVEN_TABLEVIEW, 'Breakeven Price')
    .set(ROUTES.BREAKEVEN_SUMMARY, 'Review Changes')
    .set(ROUTES.DISCOUNT_CURVE_MANAGEMENT, 'Manage Discount Curves')
    .set(ROUTES.UPLOAD_PRICING_FROM_FILE, 'Upload prices')
    .set(ROUTES.UPLOAD_DISCOUNTS_FROM_FILE, 'Upload Discounts')
    .set(ROUTES.PRICING, 'Pricing Management')
    .set(ROUTES.DISCOUNT, 'Discount Management')
    .set(ROUTES.BREAKEVEN, 'Breakeven Price')
    .set(ROUTES.ACTION_ITEMS, 'Action Items')
    .set(ROUTES.ADMIN_PANEL, 'Admin Panel')
    .set(ROUTES.INVENTORY_RECONCILIATION, 'Inventory Reconciliation')
    .set(ROUTES.INVENTORY_RECONCILIATION_BY_LOCATION, 'Inventory Reconciliation by Location')
    .set(ROUTES.LOCATION_RECONCILIATION, 'Location Reconciliation')
    .set(ROUTES.GEO_HIERARCHY_RECONCILIATION, 'Geo Hierarchy Reconciliation')
    .set(ROUTES.RESET_BREAKEVEN, 'Reset BreakEven')
    .set(ROUTES.SNOWFLAKE_SYNC_INITIALIZATION, 'Snowflake Sync Initialization')
    .set(ROUTES.SPACEMAN_PRICES_CONSOLIDATION, 'Spaceman Prices Consolidation')
    .set(ROUTES.ON_DEMAND_PRICING, 'On Demand Pricing Management')
    .set(ROUTES.PUBLISH_ON_DEMAND_SUMMARY, 'Review Changes')
    .set(ROUTES.PRICE_SYNC_ATTEMPTS, 'Price sync attempts')
    .set(ROUTES.DISCOUNT_SYNC_ATTEMPTS, 'Discount sync attempts');

  const tracking = (object_name: string, object_value: string) => {
    trackAnalytics('Breadcrumb Clicked', {
      workflow: 'Breadcrumb Navigation',
      object_type: 'breadcrumb',
      object_name,
      object_value,
    });
  };

  // resetting discount store overrides when navigating to the search page.
  const trackAndReset = (pageStr: string) => {
    tracking('Back to Search', app);
    if (pageStr === ROUTES.DISCOUNT_TABLEVIEW) {
      resetDiscountStore();
    } else if (pageStr === ROUTES.BREAKEVEN_TABLEVIEW) {
      resetBreakevenStore();
    }
  };

  const trackAndResetGrid = () => {
    tracking('Back to Grid', app);
    resetStoreBackToGrid();
    routerHistory.goBack();
  };

  const activePage = (name: string) => <span key={'breadcrumb-active-page'}>{name}</span>;

  // SEARCH BREADCRUMB
  const home = (
    <NavLink
      to={{ pathname: ROUTES.HOME }}
      key={ROUTES.HOME}
      onClick={() => tracking('Back to Home', ROUTES.HOME)}
    >
      Back to Home
    </NavLink>
  );

  const adminPanel = (
    <NavLink
      to={{ pathname: ROUTES.ADMIN_PANEL }}
      key={ROUTES.ADMIN_PANEL}
      onClick={() => tracking('Back to Admin Panel', ROUTES.ADMIN_PANEL)}
    >
      Back to Admin Panel
    </NavLink>
  );

  // PAGE BREADCRUMB
  if (
    page &&
    page !== ROUTES.UPLOAD_PRICING_FROM_FILE &&
    page !== ROUTES.UPLOAD_DISCOUNTS_FROM_FILE &&
    page !== ROUTES.ON_DEMAND_PRICING
  ) {
    let backToSearch;
    if (
      page === ROUTES.PUBLISH_SUMMARY ||
      page === ROUTES.PUBLISH_DISCOUNT_SUMMARY ||
      page === ROUTES.BREAKEVEN_SUMMARY ||
      page === ROUTES.PUBLISH_ON_DEMAND_SUMMARY
    ) {
      backToSearch = (
        <ButtonLink key={app} onClick={() => trackAndResetGrid()}>
          Back to Grid
        </ButtonLink>
      );
    } else if (page === ROUTES.DISCOUNT_CURVE_MANAGEMENT) {
      backToSearch = home;
    } else if (
      page === ROUTES.INVENTORY_RECONCILIATION ||
      page === ROUTES.INVENTORY_RECONCILIATION_BY_LOCATION ||
      page === ROUTES.LOCATION_RECONCILIATION ||
      page === ROUTES.GEO_HIERARCHY_RECONCILIATION ||
      page === ROUTES.RESET_BREAKEVEN ||
      page === ROUTES.SNOWFLAKE_SYNC_INITIALIZATION ||
      page === ROUTES.SPACEMAN_PRICES_CONSOLIDATION ||
      page === ROUTES.PRICE_SYNC_ATTEMPTS
    ) {
      backToSearch = adminPanel;
    } else {
      backToSearch = (
        <NavLink to={{ pathname: app }} key={app} onClick={() => trackAndReset(page)}>
          Back to Search
        </NavLink>
      );
    }

    const section = [backToSearch, activePage(pageName.get(page))];

    return (
      <BreadcrumbInnerRegionStyled>
        <LeftArrowBreadCrumb />
        <Breadcrumb size={'small'} sections={section} icon={<span>/</span>} />
      </BreadcrumbInnerRegionStyled>
    );
  }

  // SEARCH BREADCRUMB

  const section = [home, activePage(pageName.get(page) ?? pageName.get(app))];

  return (
    <BreadcrumbInnerRegionStyled>
      <LeftArrowBreadCrumb />
      <Breadcrumb size={'small'} sections={section} icon={<span>/</span>} />
    </BreadcrumbInnerRegionStyled>
  );
}

export default withBreadcrumbs()(BreadcrumbComponent);
