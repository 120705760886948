import React, { useCallback, useEffect } from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { AuthzProps } from 'utils/constants';
import ROUTES from 'utils/routes/routes';
import { MainRegionStyled, OverflowHack } from 'styles/app.styled';
import HeaderComponent from 'sharedComponents/header/header';
import { AdminPanelContentWrapper } from './adminPanelView.styled';
import InventoryReconciliation from '../components/inventoryReconciliation/inventoryReconciliation';
import BottomPanelInventoryReconciliation from '../components/bottomPanel/bottomPanelInventoryReconciliation';
import EmployeePermissions from '../../../utils/store/permissions';

const InventoryReconciliationView = ({ requestedPermissions }: AuthzProps) => {
  const dispatch = useDispatch();

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_system_admin, false)) {
      redirectToAccessDenied();
    }
  }, [requestedPermissions, redirectToAccessDenied]);

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent title="Inventory Reconciliation" />
          <AdminPanelContentWrapper>
            <InventoryReconciliation />
          </AdminPanelContentWrapper>
        </OverflowHack>
      </MainRegionStyled>
      <BottomPanelInventoryReconciliation />
    </>
  );
};

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_system_admin],
})(InventoryReconciliationView);
