import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import 'ag-grid-enterprise';
import {
  ColumnApi,
  GridApi,
  GridReadyEvent,
  IRowNode,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get, includes, isEmpty, isEqual, map, partition } from 'lodash';
import { GridCustomWrapper } from 'styles/app.styled';
import {
  DISABLE_EDIT_MODE_ON_DEMAND,
  ENABLE_EDIT_MODE_ON_DEMAND,
  FETCH_ON_DEMAND_PRICING_ITEMS,
  FETCH_ON_DEMAND_PRODUCTS,
  RESET_SELECTED_NODES_ON_DEMAND,
  SET_SELECTED_NODES_ON_DEMAND,
} from 'app/pricing/onDemandPricing/store/modules/onDemand/onDemandPricing.ducks';
import {
  onDemandPricingAllItemsSelector,
  onDemandPricingEditMode,
  onDemandPricingLoadingSelector,
  onDemandPricingProductsLoadingSelector,
  onDemandPricingProductsSelector,
  onDemandSelectedNodesSelector,
  priceHistoryGeoSelector,
} from 'app/pricing/onDemandPricing/store/modules/onDemand/onDemandPricing.selector';
import { columnDefs, gridOptions } from './onDemandPricing.settings';
import { OnDemandGridWrapper } from './onDemandPricing.styled';
import { generateTreeRowData } from './onDemandPricing.helpers';
import OnDemandTableFilterBar from './onDemandPricingFilterBar/onDemandTableFilterBar';
import { AuthzProps } from '../../../../utils/constants';
import { usePrevious } from '../../standardPricing/components/helpers';
import OnDemandDetailsSidePanel from './sidePanelComponent/onDemandDetailsSidePanel';
import EmployeePermissions from '../../../../utils/store/permissions';
import { GeoHierarchy } from '../../../../generated/voyager/graphql';

function OnDemandPricing({ requestedPermissions }: AuthzProps): ReactElement {
  // ag grid apis
  const [gridApi, setGridApi] = useState<GridApi>();
  const [columnApi, setColumnApi] = useState<ColumnApi>();
  const [rowData, setRowData] = useState<any[]>();

  const dispatch = useDispatch();
  const fetchOnDemandPricing = useCallback(
    (payload: string[]) => dispatch({ type: FETCH_ON_DEMAND_PRICING_ITEMS, payload }),
    [dispatch],
  );
  const fetchOnDemandProducts = useCallback(
    () => dispatch({ type: FETCH_ON_DEMAND_PRODUCTS }),
    [dispatch],
  );
  const setSelectedNodes = useCallback(
    (payload: IRowNode[]) => dispatch({ type: SET_SELECTED_NODES_ON_DEMAND, payload }),
    [dispatch],
  );
  const enableEditModeOnDemandView = useCallback(
    () => dispatch({ type: ENABLE_EDIT_MODE_ON_DEMAND }),
    [dispatch],
  );
  const disableEditModeOnDemandView = useCallback(() => {
    dispatch({ type: DISABLE_EDIT_MODE_ON_DEMAND });
    dispatch({ type: RESET_SELECTED_NODES_ON_DEMAND });
  }, [dispatch]);

  const batchFetch = useSelector(onDemandPricingLoadingSelector);
  const batchFetchProductsLoading = useSelector(onDemandPricingProductsLoadingSelector);
  const allPricingItems = useSelector(onDemandPricingAllItemsSelector);
  const onDemandProducts = useSelector(onDemandPricingProductsSelector);
  const prevAllPricingItems = usePrevious(allPricingItems);
  const editMode = useSelector(onDemandPricingEditMode);
  const selectedNodes = useSelector(onDemandSelectedNodesSelector);
  const prevSelectedNodes = usePrevious(selectedNodes);
  const priceHistoryGeo = useSelector(priceHistoryGeoSelector);

  /**
   * OnGridReady is an AgGrid event that receives a param with access to the api.
   * @param params Parameter passed from AG grid.
   */
  const onGridReady = (params: GridReadyEvent): void => {
    setGridApi(params?.api);
    setColumnApi(params?.columnApi);
  };

  // On change in size of the grid resizing all the columns.
  const onGridSizeChanged = () => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  };

  useEffect(() => {
    fetchOnDemandProducts();
  }, [fetchOnDemandProducts]);

  useEffect(() => {
    if (!isEmpty(onDemandProducts)) {
      fetchOnDemandPricing(onDemandProducts.map(eachProd => eachProd.id));
    }
  }, [onDemandProducts, fetchOnDemandPricing]);

  useEffect(() => {
    if (isEmpty(selectedNodes) && !isEmpty(prevSelectedNodes) && gridApi) {
      gridApi.deselectAll();
    } else if (gridApi && selectedNodes.length && !batchFetch) {
      const idArr = map(selectedNodes, 'id');
      gridApi.forEachNode(rowNode => {
        if (includes(idArr, rowNode.id)) {
          rowNode.setSelected(true);
        }
      });
    }
  }, [selectedNodes, prevSelectedNodes, gridApi, batchFetch]);

  useEffect(() => {
    // Adding data to grid transactional if we get new elements from BE.
    if (!gridApi || !columnApi) {
      return;
    }

    const changedItems = allPricingItems.filter(item => !prevAllPricingItems?.includes(item));
    // If for initial load.
    if (isEmpty(changedItems) || isEmpty(prevAllPricingItems)) {
      setRowData(generateTreeRowData(allPricingItems));
    } else {
      // Secondary data load or change in data.
      const transformedData = generateTreeRowData(allPricingItems);
      const changedTransformedItems = transformedData.filter(item1 =>
        changedItems?.some(item2 => item1.id === item2.id),
      );

      // Partition depending on item with the same id being present in the grid
      const updatedAndNewData = partition(changedTransformedItems, item =>
        prevAllPricingItems?.find((previousItem: GeoHierarchy) => previousItem.id === item.id),
      );

      // updatedAndNewData[0] contains items that were already in the grid, but were changed;
      // updatedAndNewData[1] contains completely new items.
      const filteredUpdatedData = updatedAndNewData[0];
      const filteredNewData = updatedAndNewData[1];

      setTimeout(
        () =>
          gridApi.applyTransactionAsync(
            { add: filteredNewData, update: filteredUpdatedData },
            event =>
              // We have cellRenderer in the current and working price cells and we need to manually refresh
              // those cells to hide the future overrides and changelog.
              gridApi.refreshCells({
                suppressFlash: true,
                force: true,
                rowNodes: event.update,
              }),
          ),
        0,
      );
    }

    setTimeout(() => {
      if (!batchFetch && !batchFetchProductsLoading) {
        gridApi.hideOverlay();
      } else if (batchFetch) {
        gridApi.showLoadingOverlay();
      }
    });

    gridApi.sizeColumnsToFit();
  }, [
    allPricingItems,
    gridApi,
    columnApi,
    prevAllPricingItems,
    batchFetch,
    batchFetchProductsLoading,
  ]);

  const getDataPath = useCallback(data => data.orgHierarchy, []);

  // BC: Change the column definition only when onDemandProducts definition changes.
  const columnDefinitions = useMemo(
    () =>
      columnDefs(
        onDemandProducts,
        get(requestedPermissions, EmployeePermissions.voyager_on_demand_price_edit, false),
      ),
    [onDemandProducts, requestedPermissions],
  );

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const tempSelectedNodes = event.api.getSelectedNodes();
    if (!isEqual(selectedNodes, tempSelectedNodes)) {
      setSelectedNodes(tempSelectedNodes);
    }

    if (tempSelectedNodes && tempSelectedNodes.length) {
      if (!editMode) {
        // Only enabling if edit mode is off and there are selected Nodes.
        enableEditModeOnDemandView();
      }
    } else if (editMode) {
      // Only disabling if edit mode is on.
      disableEditModeOnDemandView();
    }
  };

  const onFilterChanged = () => {
    if (gridApi) {
      if (gridApi.getDisplayedRowCount() === 0) {
        gridApi.showNoRowsOverlay();
      } else {
        gridApi.hideOverlay();
      }
    }
  };

  return (
    <>
      <OnDemandTableFilterBar agGridApi={gridApi} onDemandProducts={onDemandProducts} />
      <GridCustomWrapper>
        <OnDemandGridWrapper className="ag-theme-alpine">
          <AgGridReact
            gridOptions={gridOptions}
            columnDefs={columnDefinitions}
            onGridReady={onGridReady}
            rowData={rowData}
            treeData
            getDataPath={getDataPath}
            onSelectionChanged={onSelectionChanged}
            onFilterChanged={onFilterChanged}
            onGridSizeChanged={onGridSizeChanged}
          />
        </OnDemandGridWrapper>
      </GridCustomWrapper>
      <OnDemandDetailsSidePanel isVisible={priceHistoryGeo != null} />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [
    EmployeePermissions.voyager_on_demand_price_view,
    EmployeePermissions.voyager_on_demand_price_edit,
  ],
})(OnDemandPricing);
