import * as React from 'react';
import { ReactElement } from 'react';
import { HomeBreadcrumbRegionStyled, MainTitleStyled } from './header.styled';
import BreadcrumbComponent from '../breadcrumb/breadcrumbComponent';
import { AppTitle } from '../homeDashboard/applicationCard.styled';

interface HeaderComponentProps {
  title: string;
}

function HeaderComponent({ title }: HeaderComponentProps): ReactElement {
  return (
    <>
      <HomeBreadcrumbRegionStyled>
        <BreadcrumbComponent />
      </HomeBreadcrumbRegionStyled>
      <MainTitleStyled>
        <AppTitle>{title}</AppTitle>
      </MainTitleStyled>
    </>
  );
}

export default HeaderComponent;
