import { Divider, Header } from 'semantic-ui-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  CLEAR_LOCATIONS_STORE,
  FETCH_LOCATIONS,
} from 'sharedStore/modules/locations/locations.ducks';
import { useDebounce } from 'app/pricing/standardPricing/components/helpers';
import {
  currentLocationsItemLoadingState,
  locationsSelectorWithOpenDate,
} from 'sharedStore/modules/locations/locations.selector';
import UuidTextArea from 'sharedComponents/uuidTextArea/uuidTextArea';
import RemovableLabel from 'sharedComponents/removableLabel/removableLabel';
import {
  inventoryReconciliationByLocationIsLoadingSelector,
  inventoryReconciliationByLocationLocationIdsSelector,
  inventoryReconciliationByLocationLocationsSelector,
} from 'app/adminPanel/store/modules/inventoryReconciliationByLocation/inventoryReconciliationByLocation.selector';
import {
  INVENTORY_RECONCILIATION_BY_LOCATION_SET_IS_LOCATION_IDS_VALID,
  INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATION_IDS,
  INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATIONS,
} from 'app/adminPanel/store/modules/inventoryReconciliationByLocation/inventoryReconciliationByLocation.ducks';
import { LocationSearch, LabelsWrapper } from './inventoryReconciliationByLocation.styled';

const InventoryReconciliationByLocation = () => {
  const [locationSearch, setLocationSearch] = useState<string>('');
  const [locationSearchResult, setLocationSearchResult] = useState<any[]>([]);

  const allLocations = useSelector(locationsSelectorWithOpenDate);
  const isLoading = useSelector(currentLocationsItemLoadingState);
  const selectedLocations = useSelector(inventoryReconciliationByLocationLocationsSelector);
  const isReconciliationLoading = useSelector(inventoryReconciliationByLocationIsLoadingSelector);
  const locationIds = useSelector(inventoryReconciliationByLocationLocationIdsSelector);

  const dispatch = useDispatch();
  const findLocationsByName = useCallback(
    (payload: string) => dispatch({ type: FETCH_LOCATIONS, payload }),
    [dispatch],
  );

  const handleLocationIdsValidation = useCallback(
    (isValid: boolean) =>
      dispatch({
        type: INVENTORY_RECONCILIATION_BY_LOCATION_SET_IS_LOCATION_IDS_VALID,
        payload: isValid,
      }),
    [dispatch],
  );

  const handleChangeLocationIdsTextArea = useCallback(
    (ids: string[]) => {
      dispatch({
        type: INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATION_IDS,
        payload: ids,
      });
    },
    [dispatch],
  );

  const addLocation = useCallback(
    (locationId: string) => {
      const selectedLocation = allLocations.find(location => location.id === locationId);
      const isNewLocation =
        selectedLocations.find(location => location.id === locationId) === undefined;
      if (selectedLocation && isNewLocation) {
        const newSelectedLocations = [...selectedLocations, selectedLocation];
        dispatch({
          type: INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATIONS,
          payload: newSelectedLocations,
        });
        dispatch({ type: CLEAR_LOCATIONS_STORE });
        setLocationSearch('');
      }
    },
    [dispatch, allLocations, selectedLocations],
  );

  const removeLocation = useCallback(
    (locationId: string) => () => {
      const newSelectedLocations = selectedLocations.filter(location => location.id !== locationId);
      dispatch({
        type: INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATIONS,
        payload: newSelectedLocations,
      });
    },
    [dispatch, selectedLocations],
  );

  const debouncedLocationSearch = useDebounce((value: string) => findLocationsByName(value), 500);
  const performLocationSearch = useCallback(
    (value: string) => {
      setLocationSearch(value);
      if (value) {
        debouncedLocationSearch(value);
      }
    },
    [setLocationSearch, debouncedLocationSearch],
  );

  useEffect(() => {
    setLocationSearchResult(
      allLocations.map(location => ({ title: location.name, id: location.id })),
    );
  }, [allLocations]);

  return (
    <>
      <LocationSearch
        fluid
        placeholder="Location search..."
        loading={isLoading}
        onResultSelect={(_e: any, data: any) => addLocation(data.result.id)}
        onSearchChange={(event: any) => performLocationSearch(event?.target?.value)}
        results={locationSearchResult}
        value={locationSearch}
        disabled={isReconciliationLoading}
      />
      <LabelsWrapper>
        {selectedLocations.map(location => (
          <RemovableLabel
            key={location.id}
            disabled={isReconciliationLoading}
            labelText={location?.name ?? ''}
            onDelete={removeLocation(location.id)}
          />
        ))}
      </LabelsWrapper>
      <Divider />
      <Header as="h5">Location IDs</Header>
      <UuidTextArea
        disabled={isReconciliationLoading}
        clear={isEmpty(locationIds)}
        handleValidation={useDebounce((value: boolean) => handleLocationIdsValidation(value), 500)}
        handleChange={useDebounce((value: string[]) => handleChangeLocationIdsTextArea(value), 500)}
      />
    </>
  );
};

export default InventoryReconciliationByLocation;
