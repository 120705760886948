import { RouteProps } from 'react-router-dom';
import ROUTES from './routes';
import Callback from '../../commonPages/callback';
import Login from '../../commonPages/login';
import LoggedOut from '../../commonPages/loggedOut';
import ErrorPage from '../../commonPages/error/error';

const unauthenticatedRoutes: RouteProps[] = [
  {
    exact: true,
    path: ROUTES.LOGIN,
    component: Login,
  },
  {
    exact: true,
    path: ROUTES.CALLBACK,
    component: Callback,
  },
  {
    exact: true,
    path: ROUTES.LOGOUT,
    component: LoggedOut,
  },
  {
    exact: true,
    path: ROUTES.ERROR,
    component: ErrorPage,
  },
];

export default unauthenticatedRoutes;
