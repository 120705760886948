import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Confirm, Table } from 'semantic-ui-react';
import { v4 as uuidV4 } from 'uuid';
import { Icon, IconName, IconSize } from '@wework/ray2';
import cn from 'classnames';
import {
  CellContainer,
  CurvesSearchTableWrapper,
  WarningCellContainer,
} from './discountCurves.styled';
import { CurveWarnings } from './discountCurvesModal';

interface DiscountCurvesConfirmationModalProps {
  header: string;
  warnings: CurveWarnings[];
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

function DiscountCurvesConfirmationModal({
  header,
  onConfirm,
  loading,
  onCancel,
  warnings,
}: DiscountCurvesConfirmationModalProps): ReactElement {
  const [openCurveIds, setOpenCurveIds] = useState<string[]>([]);

  const getOpenCloseRow = (curveId: string): boolean => !!openCurveIds?.find(id => id === curveId);
  const openCloseCurveWarnings = (curveId: string) => {
    const termIdx = openCurveIds.findIndex(id => id === curveId) ?? -1;
    if (termIdx > -1) {
      const modExpandedArr = [...openCurveIds];
      modExpandedArr.splice(termIdx, 1);
      setOpenCurveIds(modExpandedArr);
    } else {
      setOpenCurveIds([...openCurveIds, curveId]);
    }
  };

  return (
    <Confirm
      open={warnings.length > 0}
      onCancel={onCancel}
      onConfirm={onConfirm}
      closeIcon
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
        disabled: loading,
      }}
      confirmButton={{ content: 'Continue', loading }}
      header={header}
      content={
        <CurvesSearchTableWrapper>
          <Table className="curve-manage-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={2}> Curve Issues </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {warnings.map(warning => (
                <Table.Row key={warning.curveId}>
                  <td className="icon-size">
                    <Icon
                      size={IconSize.LARGE}
                      icon={
                        getOpenCloseRow(warning.curveId) ? IconName.CARET_DOWN : IconName.CARET_UP
                      }
                      onClick={() => openCloseCurveWarnings(warning.curveId)}
                    />
                  </td>
                  <td colSpan={1}>
                    <CellContainer
                      className={cn({
                        open: getOpenCloseRow(warning.curveId),
                        closed: !getOpenCloseRow(warning.curveId),
                      })}
                    >
                      <div
                        key={uuidV4()}
                      >{`Curve '${warning.curveName}' has following warnings`}</div>
                      {warning.curveWarnings.map(eachWarning => (
                        <WarningCellContainer key={uuidV4()}>{eachWarning}</WarningCellContainer>
                      ))}
                    </CellContainer>
                  </td>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </CurvesSearchTableWrapper>
      }
    />
  );
}

export default DiscountCurvesConfirmationModal;
