import { get, isEmpty, merge } from 'lodash';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import AppConfig from 'config';
import { errorHandlerActive } from 'utils/errorHandling/helpers';
import { getAuthToken } from 'index';
import {
  INVENTORY_RECONCILIATION_BY_LOCATION,
  INVENTORY_RECONCILIATION_BY_LOCATION_ERROR,
  INVENTORY_RECONCILIATION_BY_LOCATION_SUCCESS,
} from './inventoryReconciliationByLocation.ducks';
import {
  inventoryReconciliationByLocationLocationIdsSelector,
  inventoryReconciliationByLocationLocationsSelector,
} from './inventoryReconciliationByLocation.selector';
import { SET_JOB_STATUS } from '../jobStatus/jobStatus.ducks';
import { Location } from '../../../../../generated/voyager/graphql';

const inventoryReconciliationByLocationRequest = (
  locationIds: string[],
): Promise<AxiosResponse<any>[]> => {
  const inventoryReconciliationByLocationUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/ims-backfill/locations`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(inventoryReconciliationByLocationUrl, locationIds, { headers });
};

// Saga - to handle upload file.
export function* inventoryReconciliationByLocationWorker() {
  try {
    const locations: Location[] = yield select(inventoryReconciliationByLocationLocationsSelector);
    const locationIds: string[] = yield select(
      inventoryReconciliationByLocationLocationIdsSelector,
    );
    const allLocationIds: string[] = merge(
      locations.map(location => location.id),
      locationIds,
    );

    if (!isEmpty(allLocationIds)) {
      const response: AxiosResponse | undefined = yield call(
        inventoryReconciliationByLocationRequest,
        allLocationIds,
      );

      if (response?.status === 200) {
        yield put({ type: INVENTORY_RECONCILIATION_BY_LOCATION_SUCCESS });
        toast.success('Inventory reconciliation successfully started!');
        yield put({ type: SET_JOB_STATUS, data: { job: 'INVENTORY_RECONCILIATION_BY_LOCATION' } });
      } else {
        toast.error('Inventory reconciliation error');
        yield put({
          type: INVENTORY_RECONCILIATION_BY_LOCATION_ERROR,
          payload: response?.data?.message ?? '',
        });
      }
    }
  } catch (e: any) {
    toast.error(`Inventory reconciliation error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export function* inventoryReconciliationByLocationSaga(): any {
  yield all([
    takeLatest(INVENTORY_RECONCILIATION_BY_LOCATION, inventoryReconciliationByLocationWorker),
  ]);
}
