import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import cn from 'classnames';
import { PanelSectionItem, SectionBody, SectionHeader } from '../detailSidePanel.styled';
import {
  ItemBlockFinancial,
  ItemDetailBlockMarketPrice,
  ItemDetailBlockPriceHistory,
  ItemDetailBlockReservationPrice,
  ItemDetailBlockSuggestedPrice,
} from './detailItemBlockTypes';
import { PriceableItem } from '../../../generated/voyager/graphql';

export enum PriceableItemDetailType {
  market_price,
  reservation_price,
  suggested_price,
  price_history,
}
interface DetailItemHeaderPriceableProps {
  detailLabel: string;
  data: PriceableItem | null;
  type: PriceableItemDetailType;
}

function DetailItemHeaderPriceable({
  detailLabel,
  data,
  type,
}: DetailItemHeaderPriceableProps): ReactElement {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);
  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  const priceData = {
    marketPrice: [
      {
        label: 'Last5M',
        // value: isNumberNullFormat(metrics.prev1mMinPriceLocal),
        value: '150',
        highlight: false,
      },
      {
        label: 'Last4M',
        // value: isNumberNullFormat(metrics.next1mMinPriceLocal),
        value: '150',
        highlight: false,
      },
      {
        label: 'Last3M',
        // value: isNumberNullFormat(metrics.next1mMinPriceLocal),
        value: '150',
        highlight: false,
      },
      {
        label: 'Last2M',
        // value: isNumberNullFormat(metrics.next1mMinPriceLocal),
        value: '150',
        highlight: false,
      },
      {
        label: 'Current',
        // value: isNumberNullFormat(metrics.minPriceLocal),
        value: '150',
        highlight: true,
      },
    ],
    reservationPrice: [
      {
        label: 'Last5M',
        // value: isNumberNullFormat(metrics.prev1mMinPriceLocal),
        value: '150',
        highlight: false,
      },
      {
        label: 'Last4M',
        // value: isNumberNullFormat(metrics.next1mMinPriceLocal),
        value: '150',
        highlight: false,
      },
      {
        label: 'Last3M',
        // value: isNumberNullFormat(metrics.next1mMinPriceLocal),
        value: '150',
        highlight: false,
      },
      {
        label: 'Last2M',
        // value: isNumberNullFormat(metrics.next1mMinPriceLocal),
        value: '150',
        highlight: false,
      },
      {
        label: 'Current',
        // value: isNumberNullFormat(metrics.minPriceLocal),
        value: '150',
        highlight: true,
      },
    ],
  } as ItemBlockFinancial;

  return (
    <PanelSectionItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <SectionHeader className="section-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{detailLabel}</span>
          <i className="toggle-accordion-icon" />
        </button>
      </SectionHeader>
      <SectionBody className="section-body">
        {type === PriceableItemDetailType.market_price && ItemDetailBlockMarketPrice(priceData)}
        {type === PriceableItemDetailType.reservation_price &&
          ItemDetailBlockReservationPrice(priceData)}
        {type === PriceableItemDetailType.suggested_price && ItemDetailBlockSuggestedPrice(data)}
        {type === PriceableItemDetailType.price_history && ItemDetailBlockPriceHistory(data)}
      </SectionBody>
    </PanelSectionItem>
  );
}

export default DetailItemHeaderPriceable;
