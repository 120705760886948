import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { TextField } from '@wework/ray2';
import { useDispatch, useSelector } from 'react-redux';
import { GridApi, ICellRendererParams, IRowNode } from 'ag-grid-community';
import styled from 'styled-components';
import { validateInputNumberValue } from 'utils/helpers';
import { roundToAtMost2Digit } from '../../../helpers';
import { standardPricingEditModeSelector } from '../../../../store/modules/standardPricingItems/standardPricingItems.selector';
import { CREATE_STANDARD_WORKING_PRICES } from '../../../../store/modules/standardPricingItems/standardPricingItems.ducks';
import { CustomTableBodyCell, EditWrap } from '../../../pricingTable/pricingTable.styled';
import { Unassigned } from '../../../../../../../utils/sharedTypes';
import { WorkingPriceInput } from '../../../../../../../generated/voyager/graphql';

export const ItemDataValueStyled = styled.p`
  order: 0;
  flex: 1 1 auto;
  justify-self: flex-start;
  align-self: center;
  margin: 0;
`;

const WorkingPriceCellRenderer = (params: ICellRendererParams): ReactElement => {
  const editModeSelector = useSelector(standardPricingEditModeSelector);
  const [inputVal, setInputVal] = useState<number | undefined>();
  const [displayVal, setDisplayVal] = useState<string>('');
  const currency = params.data?.currentPrice?.currencyIsoCode;
  const options = currency ? { style: 'currency', currency } : {};

  const setValHelper = () => {
    const workingPrice = params?.value ?? params.data?.currentPrice?.price;
    const val = workingPrice > 0 ? roundToAtMost2Digit(workingPrice) : undefined;
    setInputVal(val);
    setDisplayVal(val ? new Intl.NumberFormat('en-US', options).format(val) : '-');
  };
  useEffect(setValHelper, [params]);

  const dispatch = useDispatch();
  const createStandardWorkingPrices = useCallback(
    (payload: WorkingPriceInput[]) => dispatch({ type: CREATE_STANDARD_WORKING_PRICES, payload }),
    [dispatch],
  );

  const onSubmit = () => {
    // Saving the new data.
    const prevWorkingPrice = params?.value ?? params.data?.currentPrice?.price;
    if (inputVal && inputVal !== prevWorkingPrice) {
      // 1) Creating a backend request for updating the data.
      createStandardWorkingPrices([
        {
          priceableItemId: params.data?.id,
          price: inputVal,
          recommended: params.data?.recommendedPrice?.price ?? 0,
        },
      ]);
      // 2) Updating the grid with new data.
      const gridApi: GridApi = params.api;
      const rowNode: IRowNode | Unassigned = gridApi.getRowNode(params.data?.id);
      if (rowNode) {
        const prevData = rowNode.data;
        const workingPrice = {
          ...prevData.workingPrice,
          price: inputVal,
          lastChangeReason: '',
        };
        rowNode.setData({ ...prevData, workingPrice });
        gridApi.refreshCells({ rowNodes: [rowNode] });
      }
    }
  };

  const cleanAndSetVal = (event: any) => {
    const field = params.colDef?.field ?? '';
    validateInputNumberValue(
      event,
      field,
      {
        wholeNumberValues: {
          fields: ['workingPrice.price'],
        },
      },
      value => {
        if (value) {
          setInputVal(value);
          setDisplayVal(new Intl.NumberFormat('en-US', options).format(Number(value)));
        }
      },
    );
  };

  return params.data ? (
    <CustomTableBodyCell>
      {editModeSelector ? (
        <EditWrap>
          <TextField
            type="text"
            value={inputVal}
            onChange={event => cleanAndSetVal(event)}
            onBlur={onSubmit}
          />
        </EditWrap>
      ) : (
        <ItemDataValueStyled>{displayVal}</ItemDataValueStyled>
      )}
    </CustomTableBodyCell>
  ) : (
    <> </>
  );
};

export default WorkingPriceCellRenderer;
