import { Action, handleActions } from 'redux-actions';
import { get } from 'lodash';
import initialState from '../../../utils/store/initialState';
import { DetailPanelSubSet, DisplayableItemSubSet } from '../../entities/detailPanel';
import { createRDXConstant } from '../../../utils/store/store.constants';

// Actions for handling the Side panel.
export const OPEN_SIDE_PANEL = createRDXConstant('OPEN_SIDE_PANEL');
export const CLOSE_SIDE_PANEL = createRDXConstant('CLOSE_SIDE_PANEL');

// Reducer
export const detailPanelReducer = handleActions<DetailPanelSubSet, any>(
  {
    // Reducer for handling the Side panel
    [OPEN_SIDE_PANEL]: (state: DetailPanelSubSet, action: Action<any>) => {
      const currentDisplayableItem = get(action, 'payload', {});
      return {
        ...state,
        currentDisplayableItem,
        sidePanelParam: true,
      };
    },
    [CLOSE_SIDE_PANEL]: (state: DetailPanelSubSet) => ({
      ...state,
      currentDisplayableItem: {} as DisplayableItemSubSet,
      sidePanelParam: false,
    }),
  },
  initialState.detailPanel,
);
