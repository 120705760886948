import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useDispatch } from 'react-redux';
import 'ag-grid-enterprise';
import { ColDef, ColGroupDef, GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Text, TextAreaProps } from '@wework/dieter-ui';
import { RowSelectedEvent } from 'ag-grid-community/dist/lib/events';
import { PageInfo } from '../../../../../generated/voyager/graphql';
import { defaultPageIngo, PriceSyncAttempt } from '../../../store/entities/priceSyncAttempt';
import {
  AgGridWrapper,
  ButtonRow,
  FilterBox,
  PageControlButton,
  PageTextArea,
  RowBox,
  RowComponent,
  TableLayoutPriceAttempts,
} from '../syncAttempts.styled';
import TableFilterBarAttempts from '../filterBar';

import { GridCustomWrapper } from '../../../../../styles/app.styled';
import { gridOptions } from './syncAttemptsTableAGGrid.settings';
import { inputNumberFormatterNoNegative } from '../../../../pricing/standardPricing/components/helpers';
import { DiscountSyncAttempt } from '../../../store/entities/discountSyncAttempt';

interface SyncAttempt {
  syncAttemptName: string;
  allSyncAttempts: DiscountSyncAttempt[] | PriceSyncAttempt[] | undefined;
  pageInfoSelected: PageInfo;
  selectedNodes: IRowNode[];
  rePublishAction: string;
  pageInfoAction: string;
  setFilterAction: string;
  columnDefs: (ColDef | ColGroupDef)[];
}

function SyncAttemptsTable({
  syncAttemptName,
  allSyncAttempts,
  pageInfoSelected,
  selectedNodes,
  rePublishAction,
  pageInfoAction,
  setFilterAction,
  columnDefs,
}: SyncAttempt): ReactElement {
  const dispatch = useDispatch();
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const perPage = defaultPageIngo.size;

  const uuidType = syncAttemptName.toLowerCase().concat('Uuid');

  const onGridReady = (params: GridReadyEvent): void => {
    if (params.columnApi) {
      params.columnApi.autoSizeAllColumns();
    }
    setGridApi(params?.api);
  };

  const setPageInfo = useCallback(
    (pageInfo: PageInfo) => {
      dispatch({
        type: pageInfoAction,
        payload: pageInfo,
      });
    },
    [dispatch, pageInfoAction],
  );

  function nextPage() {
    const pi: PageInfo = {
      size: perPage,
      page: pageInfoSelected.page + 1,
    };
    setPageInfo(pi);
  }

  function prevPage() {
    const pi: PageInfo = {
      size: perPage,
      page: pageInfoSelected.page - 1,
    };
    setPageInfo(pi);
  }

  const setGridData = () => {
    if (gridApi) {
      gridApi.setRowData(allSyncAttempts ?? []);
      gridApi.hideOverlay();
      gridApi.sizeColumnsToFit();
      gridApi.resetRowHeights();
      // to select rows which had been selected before page was changed
      if (selectedNodes.length !== 0) {
        gridApi.getRenderedNodes().forEach(node => {
          if (selectedNodes.map(r => r.data[uuidType]).includes(node.data[uuidType])) {
            node.setSelected(true);
          }
        });
      }
    }
  };

  const setSelectedNodesStandardRePublish = useCallback(
    (payload: IRowNode[]) => dispatch({ type: rePublishAction, payload }),
    [dispatch, rePublishAction],
  );

  const onRowSelected = (event: RowSelectedEvent) => {
    const newNode = event.node;
    const isChecked = event.node.isSelected();
    if (isChecked) {
      if (!selectedNodes.map(r => r.data[uuidType]).includes(newNode.data[uuidType])) {
        setSelectedNodesStandardRePublish([...selectedNodes, newNode]);
      }
    } else {
      setSelectedNodesStandardRePublish(
        selectedNodes.filter(row => row.data[uuidType] !== newNode.data[uuidType]),
      );
    }
  };

  // clear selected nodes in grid after 'Clear' button is pushed
  useEffect(() => {
    if (gridApi && selectedNodes.length === 0) {
      gridApi.getRenderedNodes().forEach(node => node.setSelected(false));
    }
  }, [gridApi, selectedNodes]);

  const isRowSelectable = (rowNode: IRowNode) =>
    rowNode.data ? rowNode.data.status === 'ERROR' : false;

  useEffect(setGridData, [allSyncAttempts, gridApi]);

  const onGridSizeChanged = useCallback(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
      gridApi.resetRowHeights();
    }
  }, [gridApi]);

  return (
    <TableLayoutPriceAttempts>
      <RowComponent>
        <FilterBox>
          <TableFilterBarAttempts
            syncAttemptName={syncAttemptName}
            setFilterAction={setFilterAction}
          />
        </FilterBox>
        <RowBox>
          <GridCustomWrapper>
            <AgGridWrapper className="ag-theme-alpine">
              <AgGridReact
                gridOptions={gridOptions}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                isRowSelectable={isRowSelectable}
                onRowSelected={onRowSelected}
                onGridSizeChanged={onGridSizeChanged}
                enableBrowserTooltips
              />
            </AgGridWrapper>
          </GridCustomWrapper>
          <ButtonRow>
            <Text>Page:</Text>
            <PageTextArea
              value={pageInfoSelected.page + 1}
              onChange={(_event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                if (typeof data.value !== 'undefined') {
                  const p = Number(inputNumberFormatterNoNegative(data.value.toString()));
                  const pi: PageInfo = {
                    size: perPage,
                    page: p - 1,
                  };
                  setPageInfo(pi);
                }
              }}
            />
            <Button.Group>
              <PageControlButton
                default
                disabled={!pageInfoSelected.page}
                onClick={() => prevPage()}
              >
                Back
              </PageControlButton>
              <PageControlButton
                default
                disabled={allSyncAttempts?.length !== perPage}
                onClick={() => nextPage()}
              >
                Next
              </PageControlButton>
            </Button.Group>
          </ButtonRow>
        </RowBox>
      </RowComponent>
    </TableLayoutPriceAttempts>
  );
}
export default SyncAttemptsTable;
