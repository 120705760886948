import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Chip, DatePicker } from '@wework/dieter-ui';
import { Button } from '@wework/ray2';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { cloneDeep, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { format, isBefore, startOfTomorrow } from 'date-fns';
import { Confirm, Popup, Modal } from 'semantic-ui-react';
import {
  ContentColumnManageCurve,
  ModalContentWrapperManageCurve,
  ModalSubTitleDatePicker,
  ModalTitle,
} from '../discountsOverride/discountsOverride.styled';
import { discountItemsTermTypes } from '../../store/modules/discountItems/discountItems.selector';
import { DataWithCallback, Unassigned } from '../../../../utils/sharedTypes';
import {
  Curve,
  CurveInput,
  MutationCreateCurveArgs,
  MutationUpdateCurveArgs,
  TermType,
} from '../../../../generated/voyager/graphql';
import {
  CREATE_CURVES,
  UPDATE_CURVES,
} from '../../store/modules/discountItems/discountItems.ducks';
import { roundToAtMost2Digit } from '../../../pricing/standardPricing/components/helpers';
import DiscountCurvesOverrideModalDetails from './curveManagementModalDetails/curveManagementModalDetails';
import DiscountCurvesOverrideModalHeader from './curveManagementModalHeader/curveManagementModalHeader';
import { trackAnalytics } from '../../../../utils/analytics/helpers';

export enum DiscountCurvesModalMode {
  VIEW,
  EDIT,
  CREATE,
}

export interface CurveDataPointInputMod {
  month: number;
  value: number | string;
}

export interface CurveTermInputMod {
  termId: string;
  dataPoints?: CurveDataPointInputMod[];
}

export interface Operator {
  id: string;
  name: string;
}

interface DiscountCurvesOverrideModalProps {
  discountCurvesOverrideModalClose: () => void;
  mode: DiscountCurvesModalMode;
  curveData?: Curve;
}

function DiscountCurvesOverrideModal({
  discountCurvesOverrideModalClose,
  mode,
  curveData,
}: DiscountCurvesOverrideModalProps): ReactElement {
  const [selectedTerms, setSelectedTerms] = useState<TermType[]>([]);
  const [curveInput, setCurveInput] = useState<
    Omit<CurveInput, 'curveTerms' | 'expirationDate'> | Unassigned
  >();
  const [curveTermInputMod, setCurveTermInputMod] = useState<CurveTermInputMod[] | Unassigned>();
  const [prevCurveTermInputMod, setPrevCurveTermInputMod] = useState<
    CurveTermInputMod[] | Unassigned
  >();
  const [expirationDatePickerOpen, setExpirationDatePickerOpen] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date>();
  const [selectedCurve, setSelectedCurve] = useState<Curve>();
  const [retainLinksSelector, setRetainLinksSelector] = useState<boolean>(false);
  const [linkInfoModal, setLinkInfoModal] = useState<boolean>(false);
  const [operator, setOperator] = useState<Operator>();

  const termTypes = useSelector(discountItemsTermTypes);

  const dispatch = useDispatch();
  const createCurves = useCallback(
    (payload: DataWithCallback<MutationCreateCurveArgs>) =>
      dispatch({ type: CREATE_CURVES, payload }),
    [dispatch],
  );
  const updateCurves = useCallback(
    (payload: DataWithCallback<MutationUpdateCurveArgs>) =>
      dispatch({ type: UPDATE_CURVES, payload }),
    [dispatch],
  );

  useEffect(() => {
    if (mode !== DiscountCurvesModalMode.CREATE) {
      // Modifying the selected terms.
      setSelectedTerms(
        termTypes.filter(eachTT =>
          curveData?.curveTerms?.find(eTerm => eTerm.term.id === eachTT.id),
        ) ?? [],
      );
      // Modifying the cureInput object.
      setCurveInput({
        name: curveData?.name ?? '',
        description: curveData?.description,
      });
      if (curveData?.expirationDate) {
        setExpirationDate(new Date(`${curveData.expirationDate}T00:00:00`));
      }
      // Modifying the curveTerms object.
      const curveTerms = curveData?.curveTerms.map(eachInMod => ({
        termId: eachInMod.term.id,
        dataPoints: eachInMod.dataPoints?.map(eachDP => ({
          month: eachDP.month,
          value: eachDP.value,
        })),
      }));
      setCurveTermInputMod(curveTerms);
      setPrevCurveTermInputMod(curveTerms ? cloneDeep(curveTerms) : []);
      setOperator(curveData?.operator);
    }
  }, [curveData, mode, termTypes]);

  function trackCurveUpdate() {
    trackAnalytics('Discount - Curve Update', {
      workflow: 'Discount curve update',
      object_type: 'Curve',
      object_name: 'Update',
    });
  }

  function trackCurveCreationWithoutTemplate() {
    trackAnalytics('Discount - Curve Creation Without A Template', {
      workflow: 'Discount curve publish',
      object_type: 'Curve',
      object_name: 'Publish',
    });
  }

  function trackCurveCreationByTemplate() {
    trackAnalytics('Discount - Curve Creation By A Template', {
      workflow: 'Discount curve publish',
      object_type: 'Curve',
      object_name: 'Publish',
    });
  }

  const applyAndCloseOverrides = () => {
    if (
      curveInput &&
      !isEmpty(curveInput) &&
      curveInput.name &&
      operator &&
      curveTermInputMod &&
      curveTermInputMod.length > 0 &&
      curveTermInputMod.every(eachInMod => eachInMod.dataPoints && eachInMod.dataPoints.length > 0)
    ) {
      const curveTerms = curveTermInputMod.map(eachInMod => ({
        termId: eachInMod.termId,
        dataPoints:
          eachInMod.dataPoints?.map(eachDP => ({
            month: eachDP.month,
            value:
              typeof eachDP.value === 'string'
                ? (roundToAtMost2Digit(parseFloat(eachDP.value)) * 100) / 10000
                : eachDP.value,
          })) ?? [],
      }));
      const expirationDateInput = expirationDate && format(expirationDate, 'yyyy-MM-dd');
      if (mode === DiscountCurvesModalMode.EDIT) {
        updateCurves({
          data: {
            curveId: curveData?.id,
            curveInput: { ...curveInput, curveTerms, expirationDate: expirationDateInput },
          },
          successCallback: () => {
            trackCurveUpdate();
            discountCurvesOverrideModalClose();
          },
        });
      } else if (mode === DiscountCurvesModalMode.CREATE) {
        createCurves({
          data: {
            curveInput: { ...curveInput, curveTerms, expirationDate: expirationDateInput },
            operatorId: operator.id,
            linkedCurveId: retainLinksSelector ? selectedCurve?.id ?? null : null,
          },
          successCallback: () => {
            if (selectedCurve?.id) {
              trackCurveCreationByTemplate();
            } else {
              trackCurveCreationWithoutTemplate();
            }
            discountCurvesOverrideModalClose();
          },
        });
      }
    } else {
      toast.error('Validation error - Required field not entered.', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'curveManagementCreationValidation',
      });
    }
  };

  const getTitleOfModal = (): string => {
    switch (mode) {
      case DiscountCurvesModalMode.CREATE:
        return 'Create New Discount Curve';
      case DiscountCurvesModalMode.EDIT:
        return 'Edit Discount Curve';
      case DiscountCurvesModalMode.VIEW:
        return 'Discount Curve Details';
      default:
        return '';
    }
  };

  return (
    <Modal
      open
      className="discount-override-modal discount-curve-management-modal"
      onClose={() => discountCurvesOverrideModalClose()}
      closeOnEscape={false}
    >
      <Modal.Content>
        <ModalContentWrapperManageCurve className="curve-management-modal">
          <ContentColumnManageCurve className="discount-curve small-bottom-interval">
            <ModalTitle className="no-indent">{getTitleOfModal()}</ModalTitle>
            <DiscountCurvesOverrideModalHeader
              mode={mode}
              retainLinksSelector={retainLinksSelector}
              selectedCurve={selectedCurve}
              curveInput={curveInput}
              setCurveInput={setCurveInput}
              setSelectedCurve={setSelectedCurve}
              setRetainLinksSelector={setRetainLinksSelector}
              setSelectedTerms={setSelectedTerms}
              setCurveTermInputMod={setCurveTermInputMod}
              setPrevCurveTermInputMod={setPrevCurveTermInputMod}
              operator={operator}
              setOperator={setOperator}
            />
          </ContentColumnManageCurve>
        </ModalContentWrapperManageCurve>
        <ModalContentWrapperManageCurve className="curve-management-modal-details">
          <ContentColumnManageCurve className="discount-curve small-top-interval scroll">
            <DiscountCurvesOverrideModalDetails
              curveData={curveData}
              selectedTerms={selectedTerms}
              mode={mode}
              curveTermInputMod={curveTermInputMod}
              prevCurveTermInputMod={prevCurveTermInputMod}
              setSelectedTerms={setSelectedTerms}
              setCurveTermInputMod={setCurveTermInputMod}
              setPrevCurveTermInputMod={setPrevCurveTermInputMod}
            />
          </ContentColumnManageCurve>
        </ModalContentWrapperManageCurve>
      </Modal.Content>
      <Modal.Actions>
        {mode === DiscountCurvesModalMode.VIEW ? (
          <>
            <ModalSubTitleDatePicker>
              {expirationDate
                ? `Expires on ${format(expirationDate, 'MMM d, yyyy')}`
                : 'This curve does not expire'}
            </ModalSubTitleDatePicker>
          </>
        ) : (
          <>
            <ModalSubTitleDatePicker>Expiration Date:</ModalSubTitleDatePicker>
            <Popup
              basic
              position="top left"
              trigger={
                <Chip
                  className={cn({ active: !!expirationDate }, 'date-picker-trigger')}
                  active={!!expirationDate}
                  onClick={() => setExpirationDatePickerOpen(prevState => !prevState)}
                >
                  {expirationDate ? format(expirationDate, 'MMM d, yyyy') : 'Select Date'}
                </Chip>
              }
              on="click"
              className="publish-data-popup"
              open={expirationDatePickerOpen}
              onClose={() => setExpirationDatePickerOpen(prevState => !prevState)}
              closeOnDocumentClick
              closeOnEscape
            >
              <Popup.Content>
                <DatePicker
                  selectedDays={expirationDate}
                  onDayClick={(day, { selected }) => {
                    if (!isBefore(day, startOfTomorrow())) {
                      setExpirationDate(selected ? undefined : day);
                      setExpirationDatePickerOpen(false);
                    }
                  }}
                  disabledDays={{
                    before: startOfTomorrow(),
                  }}
                />
              </Popup.Content>
            </Popup>
            {expirationDate
              ? `Curve will expire on ${format(expirationDate, 'MMM d, yyyy')}.`
              : 'Curve will not expire.'}
          </>
        )}
        <Button
          theme={'outline'}
          size={'medium'}
          onClick={() => discountCurvesOverrideModalClose()}
          className={'text-negative border-negative m-2xs'}
        >
          Cancel
        </Button>
        {mode !== DiscountCurvesModalMode.VIEW ? (
          <Button
            theme={'fill'}
            size={'medium'}
            onClick={() =>
              retainLinksSelector ? setLinkInfoModal(true) : applyAndCloseOverrides()
            }
          >
            Apply
          </Button>
        ) : (
          ''
        )}
      </Modal.Actions>
      <Confirm
        open={linkInfoModal}
        onCancel={() => setLinkInfoModal(false)}
        onConfirm={() => applyAndCloseOverrides()}
        closeIcon
        confirmButton={{ content: 'Continue' }}
        header={'Note Regarding Linking Reservables'}
        content={
          <div>
            <p>
              Linking of Discount curves with reservables happens in background, some of the curve
              association might appear after some time on the reservable
            </p>
          </div>
        }
      />
    </Modal>
  );
}

export default DiscountCurvesOverrideModal;
