import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@wework/ray2';
import { Dock } from 'react-dock';
import PanelBody from './sidePanelComponent.styled';
import {
  CLEAR_FILTER_PARAMS,
  MODIFY_FILTER_PARAMS,
} from '../../store/modules/filters/filters.ducks';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import {
  ClosePanelButton,
  PanelFilterList,
  PanelHeader,
  PanelTitle,
  SidePanelWrapper,
  PanelFooter,
} from '../../../../styles/app.styled';
import { filtersSelector } from '../../store/modules/filters/filters.selector';
import DiscountExpiryFilterDiscounts from '../../../../sharedComponents/filterComponents/discountExpiryFilter';
import SkuFilterShared from '../../../../sharedComponents/filterComponents/skuFilterShared';
import YesNoFilterShared from '../../../../sharedComponents/filterComponents/yesNoFilterShared';
import MinMaxFilterShared from '../../../../sharedComponents/filterComponents/minMaxFilterShared';

interface FilterSidePanelProps {
  isVisible: boolean;
  visibilityHandler: Function;
}
function FilterSidePanel({ isVisible, visibilityHandler }: FilterSidePanelProps): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  const clearFilterParams = useCallback(() => dispatch({ type: CLEAR_FILTER_PARAMS }), [dispatch]);

  // STATE
  const [localSidePanel, setLocalSidePanel] = useState<boolean>(isVisible);
  const [saveFilters, setSaveFilters] = useState<boolean>(false);
  const [clearFilters, setClearFilters] = useState<boolean>(false);

  useEffect(() => setLocalSidePanel(isVisible), [isVisible]);
  /**
   * Just close the side panel
   */
  const closeSidePanel = () => visibilityHandler();

  /**
   * Clear all filters and close the side panel
   */
  const clearAndCloseSidePanel = () => {
    clearFilterParams();
    setClearFilters(true);
    closeSidePanel();
  };

  function trackApplyFiltersButton() {
    trackAnalytics('Discount - Apply filters Button Click', {
      workflow: 'Discount Apply filters',
      object_type: 'button',
      object_name: 'Apply filters',
    });
  }
  /**
   * apply all filters and close side panel
   */
  const applyFilters = () => {
    trackApplyFiltersButton();
    setSaveFilters(true);
    closeSidePanel();
  };

  return (
    <SidePanelWrapper>
      <Dock
        position="right"
        isVisible={localSidePanel}
        dimMode="none"
        fluid={false}
        size={320}
        zIndex={100}
      >
        {/* you can pass a function as a child here */}
        <ClosePanelButton type="button" className="close" onClick={closeSidePanel} />
        <PanelHeader>
          <PanelTitle>Filters</PanelTitle>
        </PanelHeader>
        <PanelBody>
          <PanelFilterList>
            <DiscountExpiryFilterDiscounts
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              resetSaveFilters={() => setSaveFilters(false)}
              resetClearFilters={() => setClearFilters(false)}
              modifyFiltersAction={MODIFY_FILTER_PARAMS}
              filtersSelector={filtersSelector}
            />
            <SkuFilterShared
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              resetSaveFilters={() => setSaveFilters(false)}
              resetClearFilters={() => setClearFilters(false)}
              modifyFiltersAction={MODIFY_FILTER_PARAMS}
              filtersSelector={filtersSelector}
            />
            <YesNoFilterShared
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              resetSaveFilters={() => setSaveFilters(false)}
              resetClearFilters={() => setClearFilters(false)}
              modifyFiltersAction={MODIFY_FILTER_PARAMS}
              filtersSelector={filtersSelector}
            />
            <MinMaxFilterShared
              saveFilters={saveFilters}
              clearFilters={clearFilters}
              resetSaveFilters={() => setSaveFilters(false)}
              resetClearFilters={() => setClearFilters(false)}
              modifyFiltersAction={MODIFY_FILTER_PARAMS}
              filtersSelector={filtersSelector}
            />
          </PanelFilterList>
        </PanelBody>
        <PanelFooter>
          <ul>
            <li>
              <Button
                theme={'outline'}
                size={'medium'}
                className="clear-all-button"
                onClick={() => clearAndCloseSidePanel()}
              >
                Clear all
              </Button>
            </li>
            <li>
              <Button
                theme={'fill'}
                size={'medium'}
                className="apply-all-button"
                onClick={() => applyFilters()}
              >
                Apply filters
              </Button>
            </li>
          </ul>
        </PanelFooter>
      </Dock>
    </SidePanelWrapper>
  );
}

export default FilterSidePanel;
