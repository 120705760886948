// GraphQL Queries
import { gql } from '@apollo/client';

// Mutation to store the current market price.
export const SAVE_STANDARD_WORKING_PRICES_MUTATION = gql`
  mutation SaveWorkingPrices($workingPriceInputs: [WorkingPriceInput!]!) {
    saveWorkingPrices(workingPriceInputs: $workingPriceInputs) {
      errors
      success
    }
  }
`;

export const REVERT_PRICES_TO_LAST_PUBLISHED_MUTATION = gql`
  mutation ResetPrices($ids: [UUID!]!, $scope: WorkingPriceScope!) {
    resetPricesToLastPublished(ids: $ids, scope: $scope) {
      isReset
      details
      priceableItemId
    }
  }
`;

export const CANCEL_FUTURE_PRICES_MUTATION = gql`
  mutation CancelPrices($cancelFuturePriceInput: CancelFuturePriceInput!) {
    cancelFuturePrices(cancelFuturePriceInput: $cancelFuturePriceInput) {
      success
      errors
    }
  }
`;
