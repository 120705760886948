import { withRequestedAuthz } from '@wework/we-auth-react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import ROUTES from '../../../utils/routes/routes';
import { AuthzProps } from '../../../utils/constants';
import { MainRegionStyled, OverflowHack } from '../../../styles/app.styled';
import HeaderComponent from '../../../sharedComponents/header/header';
import { AdminPanelContentWrapper } from './adminPanelView.styled';
import {
  DataConsolidationButton,
  FunctionalZone,
} from '../../../styles/functionalZone/adminPanel.styled';
import { geoHierarchyReconciliationFetchStateSelector } from '../store/modules/geoHierarchyReconciliation/geoHierarchyReconciliation.selector';
import { GEO_HIERARCHY_RECONCILIATION } from '../store/modules/geoHierarchyReconciliation/geoHierarchyReconciliation.ducks';
import EmployeePermissions from '../../../utils/store/permissions';
import runningJobSelector from '../store/modules/jobStatus/jobStaus.selector';
import AnotherJobIsRunningTooltip from '../components/jobStatus/anotherJobIsRunningTooltip';

const GeoHierarchyReconciliationView = ({ requestedPermissions }: AuthzProps) => {
  const geoHierarchyReconciliationFetchState = useSelector(
    geoHierarchyReconciliationFetchStateSelector,
  );

  const job = useSelector(runningJobSelector);
  const dispatch = useDispatch();

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );
  const geoHierarchyReconciliation = useCallback(
    () => dispatch({ type: GEO_HIERARCHY_RECONCILIATION }),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_system_admin, false)) {
      redirectToAccessDenied();
    }
  }, [requestedPermissions, redirectToAccessDenied]);

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent title="Geo Hierarchy Reconciliation" />
          <AdminPanelContentWrapper>
            <FunctionalZone>
              <p>Start Geo Hierarchy Reconciliation</p>
              <DataConsolidationButton
                onClick={geoHierarchyReconciliation}
                disabled={geoHierarchyReconciliationFetchState.loading || job != null}
              >
                Start job
              </DataConsolidationButton>
              <AnotherJobIsRunningTooltip job={job} />
              <p>{geoHierarchyReconciliationFetchState.error}</p>
            </FunctionalZone>
          </AdminPanelContentWrapper>
        </OverflowHack>
      </MainRegionStyled>
    </>
  );
};

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_system_admin],
})(GeoHierarchyReconciliationView);
