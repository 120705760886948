import { gql } from '@apollo/client';

// Query to calculate SG&A for market(s)
export const GET_MARKET_SG_AND_A_QUERY = gql`
  query MarketSgAndA($marketIds: [ID!]!, $workingLocationIds: [ID!]) {
    marketSgAndA(marketIds: $marketIds, workingLocationIds: $workingLocationIds) {
      marketId
      sgAndA
    }
  }
`;

export const FETCH_GRID_BREAKEVEN_QUERY = gql`
  query LocationBreakEvens($geoLocationIds: [ID!]!) {
    locationBreakEvens(geoLocationIds: $geoLocationIds) {
      location {
        currencyIsoCode
        timeZone
        name
        id
        market {
          id
          name
          territory {
            name
            region {
              name
            }
          }
        }
      }
      current {
        breakEvenPrice
        rsf
        deskCapacity
        rent
        tenancyNNN
        opex
        totalBudgetRevenue
        sgAndA
        desksInYearMonths
        notes
        includedSGA
        showBreakEven
        steadyStateOcc
        validFrom
        publishedAt
        override {
          rsf
          deskCapacity
          rent
          tenancyNNN
          desksInYearMonths
          opex
          totalBudgetRevenue
          steadyStateOcc
          sgAndA
          includedSGA
          showBreakEven
          notes
        }
      }
      liveBudget {
        breakEvenPrice
        rsf
        deskCapacity
        rent
        tenancyNNN
        opex
        totalBudgetRevenue
        sgAndA
        desksInYearMonths
        steadyStateOcc
        updatedAt
      }
      override {
        rsf
        deskCapacity
        rent
        tenancyNNN
        opex
        totalBudgetRevenue
        desksInYearMonths
        steadyStateOcc
        sgAndA
        includedSGA
        showBreakEven
        notes
      }
      liveBudgetApplied {
        rsf
        deskCapacity
        rent
        tenancyNNN
        opex
        totalBudgetRevenue
        desksInYearMonths
        steadyStateOcc
      }
    }
  }
`;
