import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { useParams } from 'react-router-dom';
import { MainRegionStyled, OverflowHack } from '../../../styles/app.styled';
import {
  currentLocationsByIdsLoadState,
  getAllSelectedLocations,
} from '../../../sharedStore/modules/locations/locations.selector';
import { routerHistory } from '../../../utils/store/configureStore';
import BreadcrumbComponent from '../../../sharedComponents/breadcrumb/breadcrumbComponent';
import MultipleLocationWidget from '../../../sharedComponents/multipleLocationWidget/multipleLocationWidget';
import ROUTES from '../../../utils/routes/routes';
import BreakevenTable from '../components/breakevenTable/breakevenTable';
import {
  DISABLE_EDIT_MODE_BREAKEVEN,
  FETCH_BREAKEVEN_ITEMS,
  GET_MARKET_SG_AND_A,
} from '../store/modules/breakevenItems/breakevenItems.ducks';
import BottomPanelBreakeven from '../components/bottomPanel/bottomPanelBreakeven';
import { AuthzProps } from '../../../utils/constants';
import EmployeePermissions from '../../../utils/store/permissions';
import { MainTitleStyled } from '../../../sharedComponents/header/header.styled';
import { AppTitle } from '../../../sharedComponents/homeDashboard/applicationCard.styled';
import { titleName } from '../../../utils/helpers';
import currentPathNameSelector from '../../../sharedStore/modules/router/router.selector';
import { DecodeUrlInput, ShortUrlRouterParams } from '../../../sharedStore/entities/generateLink';
import { CLEAR_LOCATIONS_BY_IDS } from '../../../sharedStore/modules/locations/locations.ducks';
import {
  CLEAR_GENERATED_URL,
  DECODE_URL,
} from '../../../sharedStore/modules/generateLink/generateLink.ducks';
import { MarketSgAndAQueryVariables } from '../../../generated/voyager/graphql';

function BreakevenTablePage({ requestedPermissions }: AuthzProps): ReactElement {
  const { locationsUrl } = useParams<ShortUrlRouterParams>();

  const locationsByIdsLoaded = useSelector(currentLocationsByIdsLoadState);

  // DISPATCH PROPS
  const dispatch = useDispatch();

  const selectedLocations = useSelector(getAllSelectedLocations);
  const { pathname } = useSelector(currentPathNameSelector);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  // Dispatching props in functional component.
  const fetchBreakevenItems = useCallback(
    (payload: any) => dispatch({ type: FETCH_BREAKEVEN_ITEMS, payload }),
    [dispatch],
  );
  const getMarketSgAndA = useCallback(
    (payload: MarketSgAndAQueryVariables) => dispatch({ type: GET_MARKET_SG_AND_A, payload }),
    [dispatch],
  );

  const resetBreakevenState = useCallback(() => {
    dispatch({ type: DISABLE_EDIT_MODE_BREAKEVEN });
  }, [dispatch]);

  const decodeUrl = useCallback(
    (payload: DecodeUrlInput) => dispatch({ type: DECODE_URL, payload }),
    [dispatch],
  );

  const clearAll = useCallback(() => {
    dispatch({ type: CLEAR_LOCATIONS_BY_IDS });
    dispatch({ type: CLEAR_GENERATED_URL });
  }, [dispatch]);

  useEffect(() => {
    if (
      !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_breakeven_view, false)
    ) {
      redirectToAccessDenied();
    } else if (!!locationsUrl && !locationsByIdsLoaded) {
      decodeUrl({ locationsUrl });
    } else if (!isEmpty(selectedLocations)) {
      fetchBreakevenItems(selectedLocations.map(a => a.id));
      getMarketSgAndA({
        marketIds: selectedLocations.map(item => item.market.id),
        workingLocationIds: selectedLocations.map(item => item.id),
      });
    } else {
      toast.info('No location selected - Redirecting to breakeven home', {
        position: toast.POSITION.TOP_CENTER,
      });
      routerHistory.push({ pathname: ROUTES.BREAKEVEN });
    }

    return resetBreakevenState;
  });

  useEffect(
    () => () => {
      clearAll();
    },
    [clearAll],
  );

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <BreadcrumbComponent />
          <MainTitleStyled>
            <AppTitle>{titleName(pathname)}</AppTitle>
          </MainTitleStyled>
          <MultipleLocationWidget />
          <BreakevenTable />
        </OverflowHack>
      </MainRegionStyled>
      <BottomPanelBreakeven />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_breakeven_view],
})(BreakevenTablePage);
