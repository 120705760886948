import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@wework/ray2';
import cn from 'classnames';
import {
  PanelFilterItem,
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
  InputLabel,
  RangeBox,
  RangeItem,
} from '../../styles/app.styled';
import { PriceableItemFilter } from '../../sharedStore/entities/priceableItemFilters';
import { inputNumberFormatter } from '../../app/pricing/standardPricing/components/helpers';
import { FilterProps } from '../../utils/sharedTypes';

/**
 * MIN-MAX filter component. In this case SKU Occupancy range filter.
 *
 * @param props.saveFilters save filter flag.
 * @param props.clearFilters clear filter flag.
 * @param props.resetSaveFilters Need this to have the toggle working for consecutive times.
 * @param props.resetClearFilters Need this to have the toggle working for consecutive times.
 * @constructor
 */
function MinMaxFilterShared({
  saveFilters,
  clearFilters,
  resetSaveFilters,
  resetClearFilters,
  modifyFiltersAction,
  filtersSelector,
}: FilterProps): ReactElement {
  const filters: PriceableItemFilter[] = useSelector(filtersSelector);

  // STATE VARIABLES:
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const [lowerValue, setLowerValue] = useState<number | null>(null);
  const [higherValue, setHigherValue] = useState<number | null>(null);

  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  // DISPATCH PROPS:
  const dispatch = useDispatch();
  const addFilterParams = useCallback(
    (filterParams: PriceableItemFilter[], minMaxFilterSet: boolean) =>
      dispatch({ type: modifyFiltersAction, filterParams, minMaxFilterSet }),
    [dispatch, modifyFiltersAction],
  );

  const setMinMaxValue = (input: string, type: string) => {
    const stringReplaced = inputNumberFormatter(input);
    const currentValue = stringReplaced ? Number(stringReplaced) : null;

    switch (type) {
      case 'min':
        setLowerValue(currentValue);
        break;
      case 'max':
        setHigherValue(currentValue);
        break;
      default:
        break;
    }
  };

  const applyValuesCallback = () => {
    if (!saveFilters) {
      return;
    }

    const filterParams: PriceableItemFilter[] = [];

    if (lowerValue !== null || higherValue !== null) {
      // 2) Constructing the filter params.
      let filterParam: PriceableItemFilter = {
        filterName: 'sku',
      };
      filterParam = lowerValue !== null ? { ...filterParam, min: lowerValue } : filterParam;
      filterParam = higherValue !== null ? { ...filterParam, max: higherValue } : filterParam;
      filterParams.push(filterParam);
    }
    // 3) Storing the new filter params in store.
    addFilterParams(filterParams, true);
    resetSaveFilters();
  };
  useEffect(applyValuesCallback, [saveFilters]);

  const clearValuesCallback = () => {
    if (!clearFilters) {
      return;
    }

    setLowerValue(null);
    setHigherValue(null);
    resetClearFilters();
  };
  useEffect(clearValuesCallback, [clearFilters]);

  const applyValuesFromUrl = () => {
    const skuOccupancyFilter = filters.find(filter => filter.filterName === 'sku');
    const skuOccupancyMinVal = skuOccupancyFilter?.min;
    const skuOccupancyMaxVal = skuOccupancyFilter?.max;

    if (skuOccupancyMinVal) {
      setLowerValue(Number(skuOccupancyMinVal));
    }
    if (skuOccupancyMaxVal) {
      setHigherValue(Number(skuOccupancyMaxVal));
    }
  };
  useEffect(applyValuesFromUrl, [filters]);

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{'SKU Occupancy'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && (lowerValue !== null || higherValue !== null) && (
          <ActiveFilterValues>
            {lowerValue !== null ? lowerValue : 'NUMBER_MIN_VALUE'} -
            {higherValue !== null ? higherValue : 'NUMBER_MAX_VALUE'}
          </ActiveFilterValues>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <RangeBox>
          <RangeItem>
            {lowerValue !== null ? <InputLabel className="min-label">Min</InputLabel> : ''}
            <TextField
              placeholder="Min"
              onChange={(event: any): void => setMinMaxValue(event?.target?.value, 'min')}
              value={lowerValue !== null ? lowerValue : ''}
            />
            <b />
            {higherValue !== null ? <InputLabel className="max-label">Max</InputLabel> : ''}
            <TextField
              placeholder="Max"
              onChange={(event: any): void => setMinMaxValue(event?.target?.value, 'max')}
              value={higherValue !== null ? higherValue : ''}
            />
          </RangeItem>
        </RangeBox>
      </FilterBody>
    </PanelFilterItem>
  );
}

export default MinMaxFilterShared;
