import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { Popup } from 'semantic-ui-react';
import { ICellRendererParams } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import { formatDate, intlCurrencyFormatter } from 'utils/helpers';
import { roundToAtMost2Digit, toTitleCase } from '../../../standardPricing/components/helpers';
import {
  ChangelogList,
  ChangelogListItem,
  ChangelogTitle,
  CustomTableBodyCell,
} from '../../../standardPricing/components/pricingTable/pricingTable.styled';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import { PriceHierarchy, PricePreview } from '../../../../../generated/voyager/graphql';

const PriceCellRenderer = (params: ICellRendererParams): ReactElement => {
  const [displayVal, setDisplayVal] = useState<string>('');

  const [futurePricesValue, setFuturePricesValue] = useState<PriceHierarchy | null>(null);

  const setValHelper = () => {
    const id: string[] = params.column?.getColId()?.split('|') ?? [];
    const productTypeId = id[0];

    setFuturePricesValue(
      params.data?.futurePrices.find(
        (price: PriceHierarchy) => price.productId === productTypeId,
      ) ?? null,
    );

    const currentPrice = params.data?.currentPrices.find(
      (price: PriceHierarchy) => price.productId === productTypeId,
    );

    if (currentPrice && currentPrice.price) {
      setDisplayVal(
        intlCurrencyFormatter(
          currentPrice.currencyIsoCode,
          roundToAtMost2Digit(Number(currentPrice.price)),
        ),
      );
    } else {
      const pricePreview = params.data.pricePreviews.find(
        (eachPreview: PricePreview) => eachPreview.productId === productTypeId,
      );
      setDisplayVal(
        pricePreview && pricePreview.price
          ? intlCurrencyFormatter(
              params.data.currencyIsoCode,
              roundToAtMost2Digit(Number(pricePreview.price)),
            )
          : '-',
      );
    }
  };
  useEffect(setValHelper, [params]);

  const trackScheduledPriceOpen = () =>
    trackAnalytics('On Demand Scheduled Price', {
      workflow: 'On Demand Scheduled Price',
      object_type: 'button',
      object_name: 'Scheduled Price',
      object_value: params?.data?.id,
    });

  return params.data ? (
    <CustomTableBodyCell>
      {displayVal}
      {futurePricesValue && !isEmpty(futurePricesValue) ? (
        <Popup
          basic
          className="changelog-popup"
          trigger={<button className="on-demand-scheduled-trigger" type="button" />}
          on={'click'}
          onOpen={trackScheduledPriceOpen}
          content={
            <Popup.Content>
              <ChangelogTitle>Scheduled price change</ChangelogTitle>
              <ChangelogList>
                <ChangelogListItem key={futurePricesValue.id}>
                  {futurePricesValue.price
                    ? intlCurrencyFormatter(
                        futurePricesValue.currencyIsoCode ?? '',
                        futurePricesValue.price,
                      )
                    : '-'}{' '}
                  on {formatDate(futurePricesValue.validFrom, params.data.timeZone ?? null)} by
                  {` ${toTitleCase(futurePricesValue.createdBy)}`}
                </ChangelogListItem>
              </ChangelogList>
            </Popup.Content>
          }
        />
      ) : (
        <></>
      )}
    </CustomTableBodyCell>
  ) : (
    <> </>
  );
};

export default PriceCellRenderer;
