import { all, call, delay, put, race, take, takeLatest } from 'redux-saga/effects';
import { chunk, get, isEmpty } from 'lodash';
import { Action } from 'redux-actions';
import { toast } from 'react-toastify';
import { FetchResult } from '@apollo/client/link/core';
import {
  PageInfo,
  PublishWorkingPriceInput,
  PublishWorkingPricesMutation,
  TotalWorkingPricesQuery,
  WorkingPricePreview,
  WorkingPricePreviewInput,
  WorkingPriceScope,
  WorkingPricesQueryVariables,
} from '../../../../../../generated/voyager/graphql';
import { graphQLClient } from '../../../../../../index';
import {
  END_WORKING_PRICE_BATCH_FETCH,
  FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE,
  FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE_SUCCESS,
  START_WORKING_PRICE_BATCH_FETCH,
  STOP_FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE,
  WORKING_PRICE_PUBLISH,
  WORKING_PRICE_PUBLISH_SUCCESS,
} from './publishPrice.ducks';
import {
  FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE_QUERY,
  GET_TOTAL_WORKING_PRICES_QUERY,
} from './publishPrice.query';
import { errorHandlerActive } from '../../../../../../utils/errorHandling/helpers';
import { SET_SELECTED_NODES_STANDARD_PRICING } from '../standardPricingItems/standardPricingItems.ducks';
import PUBLISH_CURRENT_WORKING_PRICE from '../priceableItems/priceableItems.mutation';

const fetchWorkingPrices = (
  page: PageInfo,
  workingPricePreviewInput: WorkingPricePreviewInput,
): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE_QUERY,
    variables: { page, workingPricePreviewInput },
  });

const getTotalWorkingPricesItems = (
  workingPricePreviewInput: WorkingPricePreviewInput,
): Promise<FetchResult<TotalWorkingPricesQuery>> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: GET_TOTAL_WORKING_PRICES_QUERY,
    variables: { workingPricePreviewInput },
  });

const publishWorkingPriceByLocation = (
  action: PublishWorkingPriceInput,
): Promise<FetchResult<PublishWorkingPricesMutation>> =>
  graphQLClient.mutate({
    mutation: PUBLISH_CURRENT_WORKING_PRICE,
    variables: { publishWorkingPriceInput: action },
  });

function* publishWorkingPricesWorker(action: Action<PublishWorkingPriceInput>) {
  try {
    const input = action.payload;
    const batchSize = Number(process.env.REACT_APP_REQUEST_BATCH_SIZE);
    const adjustedBatchSize =
      input.scope === WorkingPriceScope.LOCATION ? Math.ceil(batchSize / 100) : batchSize;

    yield all(
      chunk(input.ids, adjustedBatchSize).map((batch, index) =>
        publishWorkingPrices(
          {
            ...input,
            ids: batch,
          },
          index,
        ),
      ),
    );

    yield put({ type: WORKING_PRICE_PUBLISH_SUCCESS });
    // Clearing selected nodes data on successful publish of prices.
    yield put({ type: SET_SELECTED_NODES_STANDARD_PRICING, payload: [] });
    toast.success(`Published Working Prices Successfully`);
  } catch (e: any) {
    toast.error(`Publish Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* publishWorkingPrices(input: PublishWorkingPriceInput, page: number) {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  yield delay(page * requestDelay);
  const { data, errors } = yield call(publishWorkingPriceByLocation, input);

  if (!isEmpty(errors) || !data?.publishWorkingPrices?.success) {
    const error = data?.publishWorkingPrices?.errors ?? errors[0];
    toast.error(`Price publish error - ${error}`);
    errorHandlerActive(new Error(error));
  }
}

function* fetchWorkingPriceData(
  page: PageInfo,
  workingPricePreviewInput: WorkingPricePreviewInput,
): any {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  yield delay(page.page * requestDelay);

  // Fetch data from backend.
  const { errors, data } = yield call(fetchWorkingPrices, page, workingPricePreviewInput);

  // If any errors stop the load and redirect to error page.
  if (!isEmpty(errors) || !data?.workingPricePreviews) {
    yield put({ type: END_WORKING_PRICE_BATCH_FETCH });
    errorHandlerActive(new Error(errors[0] ?? 'Error while fetching working price previews'));
  } else {
    // Store the data in the store.
    const priceableItems = data.workingPricePreviews.map((item: WorkingPricePreview) => ({
      ...item,
      page: page.page,
    }));
    yield put({ type: FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE_SUCCESS, payload: priceableItems });
  }
}

// Saga - fetch all Working prices in batches
function* fetchWorkingPricesWorker(action: Action<WorkingPricesQueryVariables>) {
  try {
    const input = action.payload;

    // Starting a batch fetch.
    yield put({ type: START_WORKING_PRICE_BATCH_FETCH });

    const { errors, data } = yield call(getTotalWorkingPricesItems, input.workingPricePreviewInput);
    if (!isEmpty(errors) || !data) {
      yield put({ type: END_WORKING_PRICE_BATCH_FETCH });
      errorHandlerActive(new Error(errors[0] ?? 'Error while calculating total working prices'));
      return;
    }
    const requestsNumber = Math.ceil(data.totalWorkingPrices / input.page.size);
    yield all(
      [...Array(requestsNumber).keys()].map(index =>
        fetchWorkingPriceData(
          {
            ...input.page,
            page: index + 1,
          },
          input.workingPricePreviewInput,
        ),
      ),
    );

    // End batch fetch.
    yield put({ type: END_WORKING_PRICE_BATCH_FETCH });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_WORKING_PRICE_BATCH_FETCH });
  }
}

export default function* publishPriceSaga(): any {
  yield takeLatest(
    [FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE],
    function* (args: Action<WorkingPricesQueryVariables>) {
      yield race({
        task: call(fetchWorkingPricesWorker, args),
        cancel: take(STOP_FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE),
      });
    },
  );
  yield all([takeLatest(WORKING_PRICE_PUBLISH, publishWorkingPricesWorker)]);
}
