import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { TextField } from '@wework/ray2';
import { Checkbox } from 'semantic-ui-react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  PanelFilterItem,
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
  RangeItem,
} from '../../styles/app.styled';
import { PriceableItemFilter } from '../../sharedStore/entities/priceableItemFilters';
import { inputNumberFormatter } from '../../app/pricing/standardPricing/components/helpers';
import { FilterProps } from '../../utils/sharedTypes';

/**
 * SKU filter component. In this case SKU filter.
 *
 * @param props.saveFilters save filter flag.
 * @param props.clearFilters clear filter flag.
 * @param props.resetSaveFilters Need this to have the toggle working for consecutive times.
 * @param props.resetClearFilters Need this to have the toggle working for consecutive times.
 * @constructor
 */
function SkuFilterShared({
  saveFilters,
  clearFilters,
  resetSaveFilters,
  resetClearFilters,
  modifyFiltersAction,
  filtersSelector,
}: FilterProps): ReactElement {
  const filters: PriceableItemFilter[] = useSelector(filtersSelector);

  // STATE VARIABLES:
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const [enableHotDesk, setEnableHotDesk] = useState<boolean>(false);
  const [enableDedicatedDesk, setEnableDedicatedDesk] = useState<boolean>(false);
  const [enablePersonalOffice, setEnablePersonalOffice] = useState<boolean>(false);

  const [personalOfficeMinValue, setPersonalOfficeMinValue] = useState<number | null>(null);
  const [personalOfficeMaxValue, setPersonalOfficeMaxValue] = useState<number | null>(null);

  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  // DISPATCH PROPS:
  const dispatch = useDispatch();
  const addFilterParams = useCallback(
    (filterParams: PriceableItemFilter[], skuFilterSet: boolean) =>
      dispatch({ type: modifyFiltersAction, filterParams, skuFilterSet }),
    [dispatch, modifyFiltersAction],
  );

  const setMinMaxValue = (input: string, type: string) => {
    // Remove anything that isn't a valid number
    const stringReplaced = inputNumberFormatter(input);
    const currentValue = stringReplaced ? Number(stringReplaced) : null;

    switch (type) {
      case 'min':
        setPersonalOfficeMinValue(currentValue);
        break;
      case 'max':
        setPersonalOfficeMaxValue(currentValue);
        break;
      default:
        break;
    }
  };

  const applyValuesCallback = () => {
    if (!saveFilters) {
      return;
    }

    // 2) Constructing filter params.
    const filterParams: PriceableItemFilter[] = [];
    if (enableHotDesk) {
      filterParams.push({
        filterName: 'hotDeskSelected',
        isSelected: enableHotDesk,
      });
    }
    if (enableDedicatedDesk) {
      filterParams.push({
        filterName: 'dedicatedDeskSelected',
        isSelected: enableDedicatedDesk,
      });
    }
    if (enablePersonalOffice) {
      let filterParam: PriceableItemFilter = {
        filterName: 'personalOfficeSelected',
        isSelected: enablePersonalOffice,
      };
      filterParam =
        personalOfficeMinValue !== null
          ? { ...filterParam, min: personalOfficeMinValue }
          : filterParam;
      filterParam =
        personalOfficeMaxValue !== null
          ? { ...filterParam, max: personalOfficeMaxValue }
          : filterParam;
      filterParams.push(filterParam);
    }

    // 3) Storing filter params.
    addFilterParams(filterParams, true);
    resetSaveFilters();
  };
  useEffect(applyValuesCallback, [saveFilters]);

  const clearValuesCallback = () => {
    if (!clearFilters) {
      return;
    }

    setEnableHotDesk(false);
    setEnableDedicatedDesk(false);
    setEnablePersonalOffice(false);
    setPersonalOfficeMinValue(null);
    setPersonalOfficeMaxValue(null);
    resetClearFilters();
  };
  useEffect(clearValuesCallback, [clearFilters]);

  // Resetting the input fields when user moves away from the Personal Office option.
  const resetFilters = () => {
    if (enablePersonalOffice) {
      setPersonalOfficeMinValue(null);
      setPersonalOfficeMaxValue(null);
    }
    setEnablePersonalOffice(!enablePersonalOffice);
  };

  const applyValuesFromUrl = () => {
    const hotDeskSelectedFilter = filters.find(filter => filter.filterName === 'hotDeskSelected');
    const dedicatedDeskSelectedFilter = filters.find(
      filter => filter.filterName === 'dedicatedDeskSelected',
    );
    const personalOfficeSelectedFilter = filters.find(
      filter => filter.filterName === 'personalOfficeSelected',
    );

    // note: filter?.isSelected can be string or boolean
    const hotDeskSelectedVal = hotDeskSelectedFilter?.isSelected;
    const dedicatedDeskSelectedVal = dedicatedDeskSelectedFilter?.isSelected;
    const personalOfficeSelectedVal = personalOfficeSelectedFilter?.isSelected;
    const personalOfficeMinVal = personalOfficeSelectedFilter?.min;
    const personalOfficeMaxVal = personalOfficeSelectedFilter?.max;

    setEnableHotDesk(hotDeskSelectedVal?.toString() === 'true');
    setEnableDedicatedDesk(dedicatedDeskSelectedVal?.toString() === 'true');
    setEnablePersonalOffice(personalOfficeSelectedVal?.toString() === 'true');

    if (personalOfficeMinVal) {
      setPersonalOfficeMinValue(Number(personalOfficeMinVal));
    }
    if (personalOfficeMaxVal) {
      setPersonalOfficeMaxValue(Number(personalOfficeMaxVal));
    }
  };
  useEffect(applyValuesFromUrl, [filters]);

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{'SKU'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && (
          <>
            <ActiveFilterValues>{enableHotDesk ? 'Hot Desk' : ''}</ActiveFilterValues>
            <ActiveFilterValues>{enableDedicatedDesk ? 'Dedicated Desk' : ''}</ActiveFilterValues>
          </>
        )}
        {!accordionOpen && enablePersonalOffice && (
          <ActiveFilterValues>
            {'Personal Office: '}
            {personalOfficeMinValue !== null ? personalOfficeMinValue : 'NUMBER_MIN_VALUE'} -
            {personalOfficeMaxValue !== null ? personalOfficeMaxValue : 'NUMBER_MAX_VALUE'}
          </ActiveFilterValues>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <Checkbox
          label={'Hot Desk'}
          id="hotDesk"
          checked={enableHotDesk}
          onChange={() => setEnableHotDesk(!enableHotDesk)}
        />
        <br />
        <Checkbox
          label={'Dedicated Desk'}
          id="dedicatedDesk"
          checked={enableDedicatedDesk}
          onChange={() => setEnableDedicatedDesk(!enableDedicatedDesk)}
        />
        <br />
        <Checkbox
          label={'Personal Office'}
          id="personalOffice"
          name="personalOffice"
          checked={enablePersonalOffice}
          onChange={() => resetFilters()}
        />
        <RangeItem>
          <TextField
            placeholder="Office Min"
            onChange={(event: any): void => setMinMaxValue(event?.target?.value, 'min')}
            value={personalOfficeMinValue !== null ? personalOfficeMinValue : ''}
            disabled={!enablePersonalOffice}
          />
          <b />
          <TextField
            placeholder="Office Max"
            onChange={(event: any): void => setMinMaxValue(event?.target?.value, 'max')}
            value={personalOfficeMaxValue !== null ? personalOfficeMaxValue : ''}
            disabled={!enablePersonalOffice}
          />
        </RangeItem>
      </FilterBody>
    </PanelFilterItem>
  );
}

export default SkuFilterShared;
