import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Dropdown } from '@wework/dieter-ui';
import { Button, IconName, IconType } from '@wework/ray2';
import { Input } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Filter,
  FilterLabel,
  FilterRegionStyled,
  FilterRow,
  SearchLocationPageStyled,
} from './multipleLocationSearch.styled';
import marketsSelector from '../../sharedStore/modules/markets/markets.selector';
import territoriesSelector from '../../sharedStore/modules/territories/territories.selector';
import regionsSelector from '../../sharedStore/modules/regions/regions.selector';
import { FETCH_TERRITORIES_BY_REGION } from '../../sharedStore/modules/territories/territories.ducks';
import { FETCH_MARKETS_BY_TERRITORY } from '../../sharedStore/modules/markets/markets.ducks';
import {
  CLEAR_SELECTED_LOCATIONS,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_BY_MARKET,
} from '../../sharedStore/modules/locations/locations.ducks';
import { PageInfo } from '../../generated/voyager/graphql';
import { useDebounce } from '../../app/pricing/standardPricing/components/helpers';
import { FETCH_REGIONS } from '../../sharedStore/modules/regions/regions.ducks';
import { trackAnalytics } from '../../utils/analytics/helpers';
import MultipleLocationSearchTableComponent from './multipleLocationSearchTable';
import {
  currentLocationsItemLoadingState,
  locationsSelectorWithOpenDate,
  marketLocationsSelectorWithOpenDate,
} from '../../sharedStore/modules/locations/locations.selector';
import { pageInfo } from '../../utils/store/store.constants';

interface MultipleLocationSearchComponentProps {
  allowDifferentCurrencies?: boolean;
}

function MultipleLocationSearchComponent({
  allowDifferentCurrencies,
}: MultipleLocationSearchComponentProps): ReactElement {
  // TRACKING CONSTANTS
  const eventTrack = 'Location Search Filter Selected';
  const workflowTrack = 'Location Search';
  const objectTypeTrack = 'dropdown';

  // LOCAL STATE VARIABLES
  const [tableViewItems, setTableViewItems] = useState<any[]>([]);
  const [territoryDDDisabled, setTerritoryDDDisabled] = useState<boolean>(true);
  const [marketDDDisabled, setMarketDDDisabled] = useState<boolean>(true);
  const [territoryDropDownValue, setTerritoryDropDownValue] = useState<string>('');
  const [marketDropDownValue, setMarketDropDownValue] = useState<string>('');
  const [regionDropDownValue, setRegionDropDownValue] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');

  const allLocations = useSelector(locationsSelectorWithOpenDate);
  const allMarketLocations = useSelector(marketLocationsSelectorWithOpenDate);
  const locationsLoading = useSelector(currentLocationsItemLoadingState);

  // DISPATCH PROPS
  const dispatch = useDispatch();
  const getAllRegions = useCallback(
    (payload: PageInfo) => dispatch({ type: FETCH_REGIONS, payload }),
    [dispatch],
  );
  const findLocationsByName = useCallback(
    (payload: string) => dispatch({ type: FETCH_LOCATIONS, payload }),
    [dispatch],
  );
  const getTerritoriesByRegionId = useCallback(
    (payload: string) => dispatch({ type: FETCH_TERRITORIES_BY_REGION, payload }),
    [dispatch],
  );
  const getMarketsByTerritoryId = useCallback(
    (payload: string) => dispatch({ type: FETCH_MARKETS_BY_TERRITORY, payload }),
    [dispatch],
  );
  const getLocationsByMarketId = useCallback(
    (payload: string) => dispatch({ type: FETCH_LOCATIONS_BY_MARKET, payload }),
    [dispatch],
  );
  const clearSelectedLocation = useCallback(
    () => dispatch({ type: CLEAR_SELECTED_LOCATIONS }),
    [dispatch],
  );

  // STATE PROPS
  const regions = useSelector(regionsSelector);
  const territories = useSelector(territoriesSelector);
  const markets = useSelector(marketsSelector);

  /**
   * Helper function to handle the filter component initialization.
   * NOTE: Writing this as a separate function to handle this as a useEffect callback.
   * https://stackoverflow.com/questions/55840294/how-to-fix-missing-dependency-warning-when-using-useeffect-react-hook
   */
  const handleFilterComponentInit = () => {
    // 1) Fetching all the regions and locations.
    getAllRegions(pageInfo);
    // 2) Clearing Selected Locations.
    clearSelectedLocation();
  };
  // Similar to ComponentDidMount - Called only once [on mount and unmount].
  useEffect(handleFilterComponentInit, []);

  const debouncedLocationSearch = useDebounce((value: string) => findLocationsByName(value), 500);

  const performSearch = (value: string) => {
    setSearchValue(value);
    if (marketDropDownValue) {
      setTableViewItems(
        allMarketLocations
          .filter((eachLocation: any) =>
            eachLocation.name.toLowerCase().includes(value.toLowerCase()),
          )
          .slice(0, 100),
      );
    } else if (value) {
      debouncedLocationSearch(value);
    } else {
      setTableViewItems([]);
    }
  };

  const setLocations = () => {
    if (locationsLoading) {
      setTableViewItems([]);
    } else {
      setTableViewItems(marketDropDownValue ? allMarketLocations : allLocations);
    }
  };
  useEffect(setLocations, [locationsLoading]);

  const setSingleRegion = () => {
    if (regions.length === 1) {
      const regionId = regions[0].value as string;
      getTerritoriesByRegionId(regionId);
      setTerritoryDDDisabled(false);
      setRegionDropDownValue(regionId);
    }
  };
  useEffect(setSingleRegion, [JSON.stringify(regions)]);

  // Methods used for resetting the filters dropdown.
  const resetTerritoryFilter = () => {
    setTerritoryDDDisabled(true);
    setTerritoryDropDownValue('');
    setTableViewItems([]);
    setSearchValue('');
  };
  const resetMarketFilter = () => {
    setMarketDDDisabled(true);
    setMarketDropDownValue('');
    setTableViewItems([]);
    setSearchValue('');
  };

  const onRegionChange = (_event: any, data: any) => {
    const regionDDV = data?.value;
    // 1) If same Region selected returning.
    if (isEqual(regionDDV, regionDropDownValue)) {
      return;
    }

    // Analytics track Region select event
    trackAnalytics(eventTrack, {
      workflow: workflowTrack,
      object_type: objectTypeTrack,
      object_name: 'Region Filter',
      object_value: regionDDV,
    });

    // 3) Reset Rest of filters
    resetTerritoryFilter();
    resetMarketFilter();

    // 4) If region selected not empty fetch territories and enable territory dropdown
    if (regionDDV) {
      // 4.1 Getting territories based on regionId selected
      getTerritoriesByRegionId(regionDDV);
      // 4.2 Enable the Territories DropDown
      setTerritoryDDDisabled(false);
    }
    // 5) Setting region in state.
    setRegionDropDownValue(regionDDV);
  };

  const onTerritoryChange = (_event: any, data: any) => {
    // 1) Get Territory Selected Value
    const territoryDDV = data?.value;
    // 2) If same territory selected Returning.
    if (isEqual(territoryDDV, territoryDropDownValue)) {
      return;
    }

    // Analytics track Territory select event
    trackAnalytics(eventTrack, {
      workflow: workflowTrack,
      object_type: objectTypeTrack,
      object_name: 'Territory Filter',
      object_value: territoryDDV,
    });

    // 3) Reset Rest of filters
    resetMarketFilter();

    // 4) If territory selected not empty fetch markets and enable market dropdown
    if (territoryDDV) {
      getMarketsByTerritoryId(territoryDDV);
      setMarketDDDisabled(false);
    }

    // 5) Setting territory in state.
    setTerritoryDropDownValue(territoryDDV);
  };

  const onMarketChange = (_event: any, data: any) => {
    const marketDDV = data?.value;
    if (isEqual(marketDDV, marketDropDownValue)) {
      return;
    }

    // reset table
    setTableViewItems([]);
    setSearchValue('');

    // Analytics track Market select event
    trackAnalytics(eventTrack, {
      workflow: workflowTrack,
      object_type: objectTypeTrack,
      object_name: 'Market Filter',
      object_value: marketDDV,
    });

    if (marketDDV) {
      getLocationsByMarketId(marketDDV);
    }

    setMarketDropDownValue(marketDDV);
  };

  /**
   * Tracking helper for location search.
   */
  const debouncedTrackSearching = useDebounce((event: string, value: any) => {
    trackAnalytics(event, { value });
  }, 1000);

  return (
    <SearchLocationPageStyled>
      <FilterRegionStyled>
        <FilterRow>
          <Filter>
            <Dropdown
              id="filter-region"
              className="selection"
              options={regions}
              onChange={onRegionChange}
              selectOnNavigation={false}
              scrolling
              search
              fluid
              placeholder="Region"
              clearable
              disabled={regions.length === 1}
              value={regionDropDownValue}
              selectOnBlur={false}
              onSearchChange={(event: any) =>
                debouncedTrackSearching(eventTrack, event?.target?.value)
              }
            />
            <FilterLabel className="filter-label" htmlFor="filter-region">
              Region
            </FilterLabel>
          </Filter>
          <Filter>
            <Dropdown
              id="filter-territory"
              className="selection"
              options={territories}
              onChange={onTerritoryChange}
              selectOnNavigation={false}
              scrolling
              search
              fluid
              placeholder="Territory"
              disabled={territoryDDDisabled}
              clearable
              value={territoryDropDownValue}
              selectOnBlur={false}
              onSearchChange={(event: any) =>
                debouncedTrackSearching(eventTrack, event?.target?.value)
              }
            />
            <FilterLabel className="filter-label" htmlFor="filter-territory">
              Territory
            </FilterLabel>
          </Filter>
          <Filter>
            <Dropdown
              id="filter-market"
              className="selection"
              options={markets}
              onChange={onMarketChange}
              selectOnNavigation={false}
              scrolling
              search
              fluid
              placeholder="Market"
              disabled={marketDDDisabled}
              clearable
              value={marketDropDownValue}
              selectOnBlur={false}
              onSearchChange={(event: any) =>
                debouncedTrackSearching(eventTrack, event?.target?.value)
              }
            />
            <FilterLabel className="filter-label" htmlFor="filter-market">
              Market
            </FilterLabel>
          </Filter>
        </FilterRow>
        <FilterRow>
          <Filter className="combo-dropdown">
            <Input
              fluid
              icon="search"
              title="Search Locations"
              placeholder="Search..."
              id="filter-locations"
              value={searchValue}
              onChange={(event: any) => performSearch(event?.target?.value)}
            />
          </Filter>
        </FilterRow>
      </FilterRegionStyled>
      {locationsLoading ? (
        <Button size="large" theme="text" icon={IconName.AIR} iconType={IconType.OUTLINE} loading />
      ) : (
        <MultipleLocationSearchTableComponent
          tableViewItems={tableViewItems}
          allowDifferentCurrencies={allowDifferentCurrencies}
        />
      )}
    </SearchLocationPageStyled>
  );
}

export default MultipleLocationSearchComponent;
