import { Action, handleActions } from 'redux-actions';
import initialState from '../../../../../utils/store/initialState';
import { SpacemanPricesConsolidationSubset } from '../../entities/spacemanPricesConsolidation';
import { createRDXConstant } from '../../../../../utils/store/store.constants';

export const SPACEMAN_PRICES_CONSOLIDATION = createRDXConstant('SPACEMAN_PRICES_CONSOLIDATION');
export const SPACEMAN_PRICES_CONSOLIDATION_SUCCESS = createRDXConstant(
  'SPACEMAN_PRICES_CONSOLIDATION_SUCCESS',
);
export const SPACEMAN_PRICES_CONSOLIDATION_ERROR = createRDXConstant(
  'SPACEMAN_PRICES_CONSOLIDATION_ERROR',
);

export const spacemanPricesConsolidationReducer = handleActions<
  SpacemanPricesConsolidationSubset,
  any
>(
  {
    [SPACEMAN_PRICES_CONSOLIDATION]: (state: SpacemanPricesConsolidationSubset) => ({
      ...state,
      spacemanPricesConsolidationState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [SPACEMAN_PRICES_CONSOLIDATION_SUCCESS]: (state: SpacemanPricesConsolidationSubset) => ({
      ...state,
      spacemanPricesConsolidationState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [SPACEMAN_PRICES_CONSOLIDATION_ERROR]: (
      state: SpacemanPricesConsolidationSubset,
      action: Action<string>,
    ) => ({
      ...state,
      spacemanPricesConsolidationState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
  },
  initialState.spacemanPricesConsolidation,
);
