import styled from 'styled-components';
import History from '../../../../../assets/history.svg';
import DetailsOff from '../../../../../assets/icon_details_inactive.svg';
import Calendar from '../../../../../assets/calendar.svg';
import {
  blue,
  darkBlue,
  darkGray,
  darkGreen,
  disabledGray,
  gray,
  green,
  lighterGray,
  lightestGray,
  lightGray,
  red,
} from '../../../../../styles/colors';
import { FlexVerticalHelper, ListReset } from '../../../../../styles/app.styled';

export const CustomTableBodyCell = styled.div`
  display: flex;
  justify-content: flex-start;
  &.single-line {
    flex-flow: row nowrap;
    align-content: center;
  }
  &.positive {
    color: ${green};
  }
  &.negative {
    color: ${red};
  }
  &.neutral {
    color: ${darkBlue};
  }
  > .pa-icon {
    width: 15px;
    height: 15px;
    margin-right: 15px;
  }
  &.physical-att {
    padding-top: 18px;
    > .internal-rooms-icon {
      margin-right: 4px;
    }
  }
  button.on-demand-scheduled-trigger,
  button.on-demand-changelog-trigger {
    margin-left: auto !important;
  }
  button.changelog-trigger,
  button.on-demand-changelog-trigger,
  button.details-drawer-trigger,
  button.on-demand-scheduled-trigger,
  button.scheduled-price-trigger {
    display: block;
    padding: 0;
    border: 0;
    box-shadow: none;
    margin: 0 0 0 4px;
    outline: none;
    width: 14px;
    height: 14px;
    background-color: white;
    order: 1;
    flex: 0 0 auto;
    align-self: center;
    justify-self: flex-end;
    cursor: pointer;
    &::before {
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      -webkit-mask: url(${History});
      mask: url(${History});
      -webkit-mask-position: center;
      -webkit-mask-size: contain;
      background-color: ${darkBlue};
    }
    &:hover::before,
    &.active::before {
      background-color: ${blue};
    }
  }
  button.scheduled-price-trigger,
  button.on-demand-scheduled-trigger {
    width: 15px;
    height: 15px;
    margin-left: 6px;
    &::before {
      -webkit-mask: url(${Calendar});
      mask: url(${Calendar});
      background-color: ${darkGreen};
    }
    &:hover::before,
    &.active::before {
      background-color: ${green};
    }
  }
  button.details-drawer-trigger {
    &::before {
      mask-size: contain;
      background-color: ${darkGray};
      -webkit-mask: url(${DetailsOff});
      mask: url(${DetailsOff});
    }
    &[disabled] {
      cursor: default;
      &::before {
        background-color: ${disabledGray};
      }
    }
    margin-right: 0;
    margin-left: 10px;
    width: 17px;
    height: 12px;
  }
`;
export const EditWrap = styled.div`
  position: relative;
  order: 0;
  flex: 1 1 auto;
  justify-self: flex-start;
  align-self: center;
  width: 100%;
  max-width: calc(100% - 21px);
  &.fluid-width {
    max-width: 100%;
  }
  > .relative {
    margin: 0;
    min-width: auto;
    width: 100%;
    > input {
      width: 100%;
      min-width: auto;
      padding: 5px 6px 4px;
      border: 1px solid ${gray};
      border-radius: 2px;
      margin: 0;
      font-size: 12px;
      color: ${darkBlue};
      outline: none;
      &[type='number'] {
        -moz-appearance: textfield;
      }
      &:focus {
        border-color: ${blue};
      }
      &::placeholder {
        font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-style: italic;
        font-size: 12px;
        color: ${lightGray};
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  > .relative {
    > textarea {
      width: 100%;
      height: 36px;
      padding: 4px 8px;
      background: white;
      border-radius: 2px;
      border: 1px solid ${gray};
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: ${darkBlue};
      resize: none;
      &:focus {
        border-color: ${blue};
        outline: none;
      }
      &::placeholder {
        color: ${darkBlue};
        opacity: 0.5;
      }
    }
  }
  > .ui.input {
    margin: 0;
    min-width: auto;
    width: 100%;
    > input {
      width: 100%;
      min-width: auto;
      padding: 5px 6px 4px;
      border: 1px solid ${gray};
      border-radius: 2px;
      margin: 0;
      font-size: 12px;
      color: ${darkBlue};
      outline: none;
      &[type='number'] {
        -moz-appearance: textfield;
      }
      &:focus {
        border-color: ${blue};
      }
      &::placeholder {
        font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-style: italic;
        font-size: 12px;
        color: ${lightGray};
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  > .reason-textbox {
    width: 100%;
    height: 36px;
    padding: 4px 8px;
    background: white;
    border-radius: 2px;
    border: 1px solid ${gray};
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${darkBlue};
    resize: none;
    &:focus {
      border-color: ${blue};
      outline: none;
    }
    &::placeholder {
      color: ${darkBlue};
      opacity: 0.5;
    }
  }
  > .remaining-chars-label {
    position: absolute;
    bottom: 10px;
    right: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: right;
    color: ${darkBlue};
    opacity: 0.5;
  }
`;
export const ChangelogTitle = styled.h4`
  margin: 0 0 4px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 700;
  color: ${darkBlue};
`;
export const ChangelogList = styled.ol`
  ${ListReset};
  padding-top: 5px;
  overflow-y: auto;
  max-height: 144px;
  ${FlexVerticalHelper};
  justify-content: flex-start;
  align-content: flex-start;
  border-top: 1px solid ${lighterGray};
  &.full-height {
    max-height: 100%;
    height: 100%;
    border-top: none;
  }
`;
export const ChangelogListItem = styled.li`
  flex: 1 1 auto;
  padding: 0 5px;
  line-height: 24px;
  font-size: 12px;
  font-weight: 400;
  color: ${darkBlue};
  &:nth-child(even) {
    background: ${lightestGray};
  }
  &.side-panel {
    line-height: 35px;
  }
`;
export const ViewingTableCount = styled.ul`
  height: 30px;
  ${ListReset};
  display: inline-block;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
export const ViewingCSVDownload = styled.ul`
  height: 30px;
  ${ListReset};
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  float: right;
  display: inline-block;
  &.align-on-demand {
    margin-top: 10px;
  }
`;
