import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Confirm } from 'semantic-ui-react';
import { format } from 'date-fns';
import { find, intersection, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { pluralize } from 'apollo/lib/utils';
import { zonedTimeToUtc } from 'date-fns-tz';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import { Override } from '../../../store/entities/discountItems';
import {
  BaseOverrideInput,
  DiscountOverrideInput,
  MutationPublishOverridesArgs,
} from '../../../../../generated/voyager/graphql';
import { PUBLISH_WORKING_DISCOUNTS } from '../../../store/modules/discountItems/discountItems.ducks';
import {
  discountItemsOverrides,
  prevDiscountItemsSubsetSelectorStore,
} from '../../../store/modules/discountItems/discountItems.selector';
import { getAllSelectedLocations } from '../../../../../sharedStore/modules/locations/locations.selector';
import { ConfirmProps } from '../../../../../sharedStore/entities/confirmProps';

interface PublishDiscountsConfirmProps extends ConfirmProps {
  publishDate?: Date;
  expirationDate?: Date;
}

function PublishWorkingPricesConfirm(props: PublishDiscountsConfirmProps): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();

  // Dispatching props in functional component.
  const publishWorkingDiscounts = useCallback(
    (payload: MutationPublishOverridesArgs) =>
      dispatch({ type: PUBLISH_WORKING_DISCOUNTS, payload }),
    [dispatch],
  );

  // STATE PROPS
  const discountOverrides = useSelector(discountItemsOverrides); // Need the override data.
  const selectedLocations = useSelector(getAllSelectedLocations);
  const allDiscounts = useSelector(prevDiscountItemsSubsetSelectorStore);

  // Calculate if the published priceable items have any overrides or base discounts in the future
  // or conflicting current overrides. Based on this info, users are warned about discount conflicts.
  // We only take same terms into account: e.x. if there's a future override for 6M and we publish only 12M discount,
  // there is no conflict and the 12M discount can be safely published.
  const [anyHasFutureDiscounts, anyHasFutureOverrides, anyHasConflictingCurrentOverrides] =
    discountOverrides
      .map(newDiscountItem => {
        // Find a corresponding priceable item for each of the published discounts
        const gridDiscounts = find(allDiscounts, { id: newDiscountItem.id })?.gridDiscounts ?? [];
        const publishDateInUtc = props.publishDate
          ? zonedTimeToUtc(
              format(props.publishDate, 'yyyy-MM-dd'),
              newDiscountItem.location.timeZone,
            )
          : new Date();

        // Calculate which terms types have base future discounts/future overrides/conflicting overrides
        const futureDiscountTermTypeLabels = gridDiscounts
          .filter(discount => discount.futureBaseDiscount)
          .map(discount => discount.termType.label);
        const futureOverrideTermTypeLabels = gridDiscounts
          .filter(discount => discount.futureOverride)
          .map(discount => discount.termType.label);
        const conflictingCurrentOverrideTermTypeLabels = gridDiscounts
          .filter(
            discount =>
              discount.override &&
              // an override is conflicting if it expires after the new discount is published
              new Date(discount.override.expirationDate) > publishDateInUtc,
          )
          .map(discount => discount.termType.label);

        // Calculate which terms types are being published
        const newDiscountTermTypeLabels = newDiscountItem.baseDiscounts?.map(
          newDiscount => newDiscount.termTypeLabel,
        );

        // Calculate which term types that are being published for this priceable item have
        // base future discounts/future overrides/conflicting overrides
        const hasFutureDiscounts = !isEmpty(
          intersection(futureDiscountTermTypeLabels, newDiscountTermTypeLabels),
        );
        const hasFutureOverrides = !isEmpty(
          intersection(futureOverrideTermTypeLabels, newDiscountTermTypeLabels),
        );
        const hasConflictingCurrentOverrides = !isEmpty(
          intersection(conflictingCurrentOverrideTermTypeLabels, newDiscountTermTypeLabels),
        );
        return [hasFutureDiscounts, hasFutureOverrides, hasConflictingCurrentOverrides];
      })
      // Calculate if any of the published priceable items has
      // base future discounts/future overrides/conflicting overrides
      .reduce(
        (
          [hasFutureDiscounts1, hasFutureOverrides1, hasConflictingCurrentOverrides1],
          [hasFutureDiscounts2, hasFutureOverrides2, hasConflictingCurrentOverrides2],
        ) => [
          hasFutureDiscounts1 || hasFutureDiscounts2,
          hasFutureOverrides1 || hasFutureOverrides2,
          hasConflictingCurrentOverrides1 || hasConflictingCurrentOverrides2,
        ],
        [false, false, false],
      );

  const transformDiscountType = (
    overridesArr: Override[],
    expirationDate?: string,
    publishDate?: string,
  ): MutationPublishOverridesArgs => {
    const tempOverrides: DiscountOverrideInput[] = overridesArr.map(eachOverride => {
      const baseDiscounts = eachOverride.baseDiscounts?.map(
        base =>
          ({
            termTypeLabel: base.termTypeLabel,
            value: base.value / 100,
          } as BaseOverrideInput),
      );
      return {
        priceableItemId: eachOverride.id,
        baseOverrides: baseDiscounts,
      };
    });
    return {
      publishDate,
      expirationDate,
      overrides: tempOverrides,
    };
  };

  /**
   * This method is used for batch update the backend price update.
   */
  const publishDiscounts = () => {
    const publishDateString = props.publishDate && format(props.publishDate, 'yyyy-MM-dd');
    const expirationDateString = props.expirationDate && format(props.expirationDate, 'yyyy-MM-dd');
    // Track batch Edit options and save.
    trackAnalytics('Discounts Overrides Publish', {
      workflow: 'Discounts Overrides Publish',
      object_type: 'date',
      object_name: 'Discounts Overrides Publish',
      object_value: publishDateString,
      location_uuid: selectedLocations,
    });
    publishWorkingDiscounts(
      transformDiscountType(discountOverrides, expirationDateString, publishDateString),
    );
    props.closeModal();
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={props.closeModal}
      onConfirm={publishDiscounts}
      cancelButton={{ content: 'Cancel', className: 'cancel-confirmation' }}
      closeIcon
      confirmButton={
        !props.expirationDate ? 'Continue to Apply Base Discount' : 'Continue to Apply Overrides'
      }
      header={`Publish ${!props.expirationDate ? 'Base Discounts' : 'Overrides'}`}
      content={
        <div>
          <p>{`You are going to publish ${
            !props.expirationDate ? 'base discounts' : 'overrides'
          } for ${pluralize(discountOverrides.length, 'reservable')}`}</p>
          {!props.expirationDate && (
            <p>
              - Publishing discounts without expiration date will be considered as publishing base
              discount and not overrides.
            </p>
          )}
          {anyHasFutureDiscounts && !props.expirationDate && (
            <p>
              - One or more selected reservables have base discounts scheduled to be published in
              the future. If you proceed, these discounts will be canceled.
            </p>
          )}
          {anyHasConflictingCurrentOverrides && (
            <p>
              - One or more selected reservables have currently active overrides that conflict with
              the selected publish date. If you proceed, these overrides will be cropped to the
              selected publish date.
            </p>
          )}
          {anyHasFutureOverrides && (
            <p>
              - One or more selected reservables have overrides scheduled to be published in the
              future. If you proceed, these overrides will be canceled.
            </p>
          )}
          <p>Do you want to proceed?</p>
        </div>
      }
    />
  );
}

export default PublishWorkingPricesConfirm;
