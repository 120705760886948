import { Action, handleActions } from 'redux-actions';
import { findIndex, get, isEmpty } from 'lodash';
import initialState from '../../../../../utils/store/initialState';
import { BreakevenItemsSubset } from '../../entities/breakevenItems';
import { breakevenItemsSubsetSelectorStore } from './breakevenItems.selector';
import { createRDXConstant } from '../../../../../utils/store/store.constants';
import {
  LocationBreakEvenDetail,
  LocationBreakEvensQuery,
  SgAndAForMarket,
  WorkingLocationBreakEvenInput,
} from '../../../../../generated/voyager/graphql';

// HELPERS
const workingBreakevenModifierHelper = (
  allItems: LocationBreakEvenDetail[],
  workingPrices: WorkingLocationBreakEvenInput,
): LocationBreakEvenDetail[] => {
  const newAllItems = [...allItems];
  const idx = findIndex(
    allItems,
    (eachItem: LocationBreakEvenDetail) => eachItem?.location.id === workingPrices.geoLocationUUID,
  );

  if (idx > -1) {
    let breakevenPrice: LocationBreakEvenDetail;
    if (!isEmpty(workingPrices.workingBreakEvenOverrideInput)) {
      breakevenPrice = {
        override: {
          ...allItems[idx]?.override,
          ...workingPrices?.workingBreakEvenOverrideInput,
        },
      } as LocationBreakEvenDetail;
    } else {
      breakevenPrice = {
        override: null,
      } as LocationBreakEvenDetail;
    }

    if (!isEmpty(workingPrices.workingLiveBudgetAppliedInput)) {
      breakevenPrice = {
        ...breakevenPrice,
        liveBudgetApplied: workingPrices?.workingLiveBudgetAppliedInput
          ? {
              ...allItems[idx]?.liveBudgetApplied,
              ...workingPrices?.workingLiveBudgetAppliedInput,
            }
          : null,
      } as LocationBreakEvenDetail;
    } else {
      breakevenPrice = {
        ...breakevenPrice,
        liveBudgetApplied: null,
      } as LocationBreakEvenDetail;
    }

    newAllItems.splice(idx, 1, { ...allItems[idx], ...breakevenPrice });
  }

  return newAllItems;
};

// Action Constants
export const FETCH_BREAKEVEN_ITEMS = createRDXConstant('FETCH_BREAKEVEN_ITEMS');
export const STOP_FETCH_BREAKEVEN_ITEMS = createRDXConstant('STOP_FETCH_BREAKEVEN_ITEMS');
export const FETCH_BREAKEVEN_ITEMS_SUCCESS = createRDXConstant('FETCH_BREAKEVEN_ITEMS_SUCCESS');
export const CLEAR_BREAKEVEN_ITEMS = createRDXConstant('CLEAR_BREAKEVEN_ITEMS');

export const START_BREAKEVEN_FETCH = createRDXConstant('START_BREAKEVEN_FETCH');
export const END_BREAKEVEN_FETCH = createRDXConstant('END_BREAKEVEN_FETCH');

export const ENABLE_EDIT_MODE_BREAKEVEN = createRDXConstant('ENABLE_EDIT_MODE_BREAKEVEN');
export const DISABLE_EDIT_MODE_BREAKEVEN = createRDXConstant('DISABLE_EDIT_MODE_BREAKEVEN');

// Action - breakeven change.
export const CREATE_BREAKEVEN_WORKING_DATA = createRDXConstant('CREATE_BREAKEVEN_WORKING_DATA');
// Action - breakeven change success update.
export const CREATE_BREAKEVEN_WORKING_DATA_SUCCESS = createRDXConstant(
  'CREATE_BREAKEVEN_WORKING_DATA_SUCCESS',
);

// Action - publish
export const SET_BREAKEVEN_DATA_TO_PUBLISH = createRDXConstant('SET_BREAKEVEN_DATA_TO_PUBLISH');
export const PUBLISH_BREAKEVEN_PRICES = createRDXConstant('PUBLISH_BREAKEVEN_PRICES');
export const PUBLISH_BREAKEVEN_PRICES_SUCCESS = createRDXConstant(
  'PUBLISH_BREAKEVEN_PRICES_SUCCESS',
);

// Action - get SG&A
export const GET_MARKET_SG_AND_A = createRDXConstant('GET_MARKET_SG_AND_A');
export const GET_MARKET_SG_AND_A_SUCCESS = createRDXConstant('GET_MARKET_SG_AND_A_SUCCESS');
export const CLEAR_MARKET_SG_AND_A = createRDXConstant('CLEAR_MARKET_SG_AND_A');

// Reducer
export const breakevenItemsReducer = handleActions<BreakevenItemsSubset, any>(
  {
    // Reducer for fetching all the priceable items in batches.
    [FETCH_BREAKEVEN_ITEMS]: (state: BreakevenItemsSubset) => ({
      ...state,
    }),
    [STOP_FETCH_BREAKEVEN_ITEMS]: (state: BreakevenItemsSubset) => ({
      ...state,
    }),
    [FETCH_BREAKEVEN_ITEMS_SUCCESS]: (
      state: BreakevenItemsSubset,
      action: Action<{ data: LocationBreakEvensQuery }>,
    ) => {
      const newItems = get(action, 'data.locationBreakEvens', []);

      return {
        ...state,
        allItems: newItems,
      };
    },
    [CLEAR_BREAKEVEN_ITEMS]: (state: BreakevenItemsSubset) => ({
      ...state,
      allItems: initialState.breakevenItems.allItems,
    }),
    // fetch progress Reducer.
    [START_BREAKEVEN_FETCH]: (state: BreakevenItemsSubset) => ({
      ...state,
      fetchInProgress: true,
    }),
    [END_BREAKEVEN_FETCH]: (state: BreakevenItemsSubset) => ({
      ...state,
      fetchInProgress: false,
    }),
    // Edit mode toggle Reducer.
    [ENABLE_EDIT_MODE_BREAKEVEN]: (state: BreakevenItemsSubset) => ({
      ...state,
      editMode: true,
    }),
    [DISABLE_EDIT_MODE_BREAKEVEN]: (state: BreakevenItemsSubset) => ({
      ...state,
      editMode: false,
    }),

    [CREATE_BREAKEVEN_WORKING_DATA]: (state: BreakevenItemsSubset) => ({
      ...state,
    }),
    [CREATE_BREAKEVEN_WORKING_DATA_SUCCESS]: (
      state: BreakevenItemsSubset,
      action: Action<WorkingLocationBreakEvenInput>,
    ) => {
      const breakevenItems = workingBreakevenModifierHelper(
        breakevenItemsSubsetSelectorStore(state) ?? [],
        action.payload,
      );

      return {
        ...state,
        allItems: [...breakevenItems],
      };
    },

    [SET_BREAKEVEN_DATA_TO_PUBLISH]: (
      state: BreakevenItemsSubset,
      action: Action<string[] | undefined>,
    ) => ({
      ...state,
      locationIdsToPublish: action.payload ?? [],
    }),
    [PUBLISH_BREAKEVEN_PRICES]: (state: BreakevenItemsSubset) => ({
      ...state,
      publishing: true,
    }),
    [PUBLISH_BREAKEVEN_PRICES_SUCCESS]: (state: BreakevenItemsSubset) => ({
      ...state,
      publishing: false,
      locationIdsToPublish: [],
    }),
    [GET_MARKET_SG_AND_A]: (state: BreakevenItemsSubset) => ({
      ...state,
      marketSgAndALoading: true,
    }),
    [GET_MARKET_SG_AND_A_SUCCESS]: (
      state: BreakevenItemsSubset,
      action: Action<SgAndAForMarket[]>,
    ) => ({
      ...state,
      marketSgAndALoading: false,
      marketSgAndA: action.payload ?? [],
    }),
    [CLEAR_MARKET_SG_AND_A]: (state: BreakevenItemsSubset) => ({
      ...state,
      marketSgAndA: initialState.breakevenItems.marketSgAndA,
    }),
  },
  initialState.breakevenItems,
);
