import { map } from 'lodash';
import { DropdownItemProps } from 'semantic-ui-react';
import { StoreInterface } from '../../../utils/store/store.interface';
import transformOption from '../../utils/store.transformers';

const regionsSelector = (state: StoreInterface): DropdownItemProps[] =>
  map(state?.regions?.allRegions, region =>
    region ? transformOption(region) : ({} as DropdownItemProps),
  );

export default regionsSelector;
