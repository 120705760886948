import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { push } from 'connected-react-router';
import { MainRegionStyled, SummaryWrapper } from '../../../../styles/app.styled';
import BreadcrumbComponent from '../../../../sharedComponents/breadcrumb/breadcrumbComponent';
import ROUTES from '../../../../utils/routes/routes';
import { AuthzProps } from '../../../../utils/constants';
import { CLEAR_ON_DEMAND_PRICING_STORE } from '../store/modules/onDemand/onDemandPricing.ducks';
import BottomPanelOnDemandPricingPublish from '../components/bottomPanel/onDemandBottomPanelPricingPublish';
import OnDemandPricingPublish from '../components/onDemandPricingPublish/onDemandPricingPublish';
import EmployeePermissions from '../../../../utils/store/permissions';
import {
  generateTreeRowData,
  generateWorkingPriceOnDemandArr,
  HierarchyInterface,
  transformDataToPublishSummaryOnDemand,
} from '../components/onDemandPricing.helpers';
import {
  onDemandPricingAllItemsSelector,
  onDemandPricingItemsToPublishSelector,
  onDemandPricingProductsToPublishSelector,
} from '../store/modules/onDemand/onDemandPricing.selector';
import { PublishSummaryOnDemand } from '../store/entities/onDemandPricing';

function OnDemandPricingPublishHome({ requestedPermissions }: AuthzProps): ReactElement {
  // LOCAL VARIABLE
  const [transformedWorkingPrice, setTransformedWorkingPrice] = useState<PublishSummaryOnDemand[]>(
    [],
  );
  const [workingPriceOnDemand, setWorkingPriceOnDemand] = useState<HierarchyInterface[]>([]);

  // STATE PROPS
  const allItems = useSelector(onDemandPricingAllItemsSelector);
  const itemsToPublish = useSelector(onDemandPricingItemsToPublishSelector);
  const productsToPublish = useSelector(onDemandPricingProductsToPublishSelector);

  // DISPATCH PROPS
  const dispatch = useDispatch();

  const clearOnDemandStore = useCallback(
    () => dispatch({ type: CLEAR_ON_DEMAND_PRICING_STORE }),
    [dispatch],
  );

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_on_demand_price_publish, false)) {
      redirectToAccessDenied();
    }

    return () => {
      clearOnDemandStore();
    };
  }, [clearOnDemandStore, redirectToAccessDenied, requestedPermissions]);

  // transform the data to the table
  const transformDataHelper = () => {
    if (itemsToPublish) {
      const idsToPublish = itemsToPublish.map(item => item.id);
      const hierarchicalDataOnDemand = generateWorkingPriceOnDemandArr(
        generateTreeRowData(allItems).filter(data => idsToPublish.includes(data.id)),
        productsToPublish.map(product => product.id),
      );
      setWorkingPriceOnDemand(hierarchicalDataOnDemand);
      setTransformedWorkingPrice(
        transformDataToPublishSummaryOnDemand(hierarchicalDataOnDemand, productsToPublish),
      );
    }
  };
  useEffect(transformDataHelper, [allItems, itemsToPublish, productsToPublish]);

  return (
    <>
      <MainRegionStyled>
        <BreadcrumbComponent />
        <SummaryWrapper>
          <OnDemandPricingPublish
            transformedWorkingPrice={transformedWorkingPrice}
            workingPriceOnDemand={workingPriceOnDemand}
          />
        </SummaryWrapper>
      </MainRegionStyled>
      <BottomPanelOnDemandPricingPublish transformedWorkingPrice={transformedWorkingPrice} />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_on_demand_price_publish],
})(OnDemandPricingPublishHome);
