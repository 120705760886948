import { Action } from 'redux-actions';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { graphQLClient } from '../../../../../../index';
import {
  FETCH_CURRENT_CHANGE_LOG,
  FETCH_CURRENT_CHANGE_LOG_SUCCESS,
  FETCH_FEATURE_CARD,
  FETCH_FEATURE_CARD_SUCCESS,
} from './priceableItems.ducks';
import {
  FETCH_FEATURE_CARD_BY_PRICEABLE_ITEMS_ID,
  FETCH_PRICEABLE_ITEMS_CHANGE_LOG_QUERY,
} from './priceableItems.query';
import { errorHandlerActive } from '../../../../../../utils/errorHandling/helpers';

const fetchPriceableItemById = (id: string): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_PRICEABLE_ITEMS_CHANGE_LOG_QUERY,
    variables: { id },
  });

// Saga - fetch priceable item changelog by id.
function* fetchPriceableItemChangeLogWorker(action: Action<string>) {
  try {
    const { errors, data } = yield call(fetchPriceableItemById, action?.payload ?? '');
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }
    yield put({ type: FETCH_CURRENT_CHANGE_LOG_SUCCESS, payload: data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

const fetchFeatureCardById = (id: string): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_FEATURE_CARD_BY_PRICEABLE_ITEMS_ID,
    variables: { id },
  });

function* fetchFeatureCard(action: Action<string>) {
  try {
    const id = action?.payload;
    const { errors, data } = yield call(fetchFeatureCardById, id);
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    yield put({ type: FETCH_FEATURE_CARD_SUCCESS, data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

export default function* priceableItemSaga(): any {
  yield all([takeEvery(FETCH_FEATURE_CARD, fetchFeatureCard)]);
  yield all([takeEvery(FETCH_CURRENT_CHANGE_LOG, fetchPriceableItemChangeLogWorker)]);
}
