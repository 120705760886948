import { ICellRendererParams } from 'ag-grid-community';
import React, { ReactElement } from 'react';
import { Popup } from 'semantic-ui-react';
import { v4 as uuidV4 } from 'uuid';
import { get, isEmpty } from 'lodash';
import {
  ChangelogList,
  ChangelogListItem,
  ChangelogTitle,
  CustomTableBodyCellCalendar,
} from '../discountAGGrid.styled';
import { FutureDiscountDataCombo } from '../../../store/entities/discountItems';
import {
  roundToAtMost2Digit,
  toTitleCase,
} from '../../../../pricing/standardPricing/components/helpers';
import { formatDate } from '../../../../../utils/helpers';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';

function OverridesScheduleRender(props: ICellRendererParams): ReactElement {
  const termMap = new Map([
    ['M2M', 'm2MDiscount'],
    ['6M', 'sixMDiscount'],
    ['12M', 'twelveMDiscount'],
    ['24M', 'twentyFourMDiscount'],
    ['36M', 'thirtySixMDiscount'],
  ]);

  function trackChangelog() {
    trackAnalytics('Discount - Scheduled Overrides Clicks', {
      workflow: 'Discount Show Scheduled Overrides',
      object_type: 'icon',
      object_name: 'Scheduled Overrides',
    });
  }

  const futureBaseDiscountComArr: FutureDiscountDataCombo[] = [];
  const futureOverrideDiscountComArr: FutureDiscountDataCombo[] = [];

  termMap.forEach((key: string, value: string) => {
    const futureDiscount = get(props, `data.${key}.futureBaseDiscount`);
    const termName: string = value;
    if (futureDiscount) {
      futureBaseDiscountComArr.push({ futureDiscount, termName });
    }
  });

  termMap.forEach((key: string, value: string) => {
    const futureDiscount = get(props, `data.${key}.futureOverride`);
    const termName: string = value;
    if (futureDiscount) {
      futureOverrideDiscountComArr.push({ futureDiscount, termName });
    }
  });

  const scheduledDiscount = () => {
    if (!isEmpty(futureBaseDiscountComArr) || !isEmpty(futureOverrideDiscountComArr)) {
      return (
        <>
          {!isEmpty(futureBaseDiscountComArr) ? (
            <>
              <ChangelogTitle>Scheduled Base Discounts</ChangelogTitle>
              <ChangelogList>
                {futureBaseDiscountComArr
                  .filter(foe => foe.futureDiscount)
                  .map(foe =>
                    foe.futureDiscount.base !== null ? (
                      <ChangelogListItem key={uuidV4()}>
                        <strong>{foe.termName}</strong>:{' '}
                        {roundToAtMost2Digit(foe.futureDiscount.base * 100)}% On{' '}
                        {formatDate(foe.futureDiscount?.publishedDate, props?.data?.timeZone)} by{' '}
                        {toTitleCase(foe.futureDiscount?.createdBy)}
                      </ChangelogListItem>
                    ) : (
                      <ChangelogListItem key={uuidV4()}>
                        <strong>{foe.termName}</strong>: No scheduled override
                      </ChangelogListItem>
                    ),
                  )}
              </ChangelogList>
            </>
          ) : (
            ''
          )}
          {!isEmpty(futureOverrideDiscountComArr) ? (
            <>
              <ChangelogTitle>Scheduled Overrides</ChangelogTitle>
              <ChangelogList>
                {futureOverrideDiscountComArr
                  .filter(foe => foe.futureDiscount)
                  .map(foe =>
                    foe.futureDiscount.base !== null ? (
                      <ChangelogListItem key={uuidV4()}>
                        <strong>{foe.termName}</strong>:{' '}
                        {roundToAtMost2Digit(foe.futureDiscount.base * 100)}% On{' '}
                        {formatDate(foe.futureDiscount?.publishedDate, props?.data?.timeZone)} by{' '}
                        {toTitleCase(foe.futureDiscount?.createdBy)}
                      </ChangelogListItem>
                    ) : (
                      <ChangelogListItem key={uuidV4()}>
                        <strong>{foe.termName}</strong>: No scheduled override
                      </ChangelogListItem>
                    ),
                  )}
              </ChangelogList>
            </>
          ) : (
            ''
          )}
        </>
      );
    }
    return <></>;
  };

  return (
    <CustomTableBodyCellCalendar className="single-line">
      {props.valueFormatted}
      {!isEmpty(futureBaseDiscountComArr) || !isEmpty(futureOverrideDiscountComArr) ? (
        <Popup
          basic
          className="changelog-popup"
          trigger={
            <button
              className="scheduled-reservable-trigger"
              type="button"
              onClick={() => trackChangelog()}
            />
          }
          on={'click'}
          content={<Popup.Content>{scheduledDiscount()}</Popup.Content>}
        />
      ) : (
        <></>
      )}
    </CustomTableBodyCellCalendar>
  );
}

export default OverridesScheduleRender;
