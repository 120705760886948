import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import {
  SwitchStyled,
  TableFilter,
  TableFilterRow,
} from '../../../../../styles/sharedTableFilterBar.styled';
import { AuthzProps } from '../../../../../utils/constants';
import {
  DISABLE_EDIT_MODE_ON_DEMAND,
  ENABLE_EDIT_MODE_ON_DEMAND,
  RESET_SELECTED_NODES_ON_DEMAND,
} from '../../store/modules/onDemand/onDemandPricing.ducks';
import {
  onDemandPricingEditMode,
  onDemandPricingLoadingSelector,
} from '../../store/modules/onDemand/onDemandPricing.selector';
import EmployeePermissions from '../../../../../utils/store/permissions';

const OnDemandViewEditModeSelector = ({ requestedPermissions }: AuthzProps): ReactElement => {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  const enableEditModeOnDemandView = useCallback(
    () => dispatch({ type: ENABLE_EDIT_MODE_ON_DEMAND }),
    [dispatch],
  );
  const disableEditModeOnDemandView = useCallback(
    () => dispatch({ type: DISABLE_EDIT_MODE_ON_DEMAND }),
    [dispatch],
  );
  const resetSelectedNodes = useCallback(
    () => dispatch({ type: RESET_SELECTED_NODES_ON_DEMAND }),
    [dispatch],
  );

  // STATE PROPS
  const editMode = useSelector(onDemandPricingEditMode);
  const batchFetch = useSelector(onDemandPricingLoadingSelector);

  const disableEditModeHelper = () => {
    resetSelectedNodes();
    disableEditModeOnDemandView();
  };

  const enableEditModeHelper = () => {
    enableEditModeOnDemandView();
  };

  const getEditModeSwitch = () => (
    <SwitchStyled className="switch">
      <input
        type="checkbox"
        checked={editMode ?? false}
        disabled={batchFetch}
        onChange={() => (editMode ? disableEditModeHelper() : enableEditModeHelper())}
      />
      <span className="slider" />
      <span className="on">ON</span>
      <span className="off">OFF</span>
    </SwitchStyled>
  );

  return get(requestedPermissions, EmployeePermissions.voyager_on_demand_price_edit, false) ? (
    <TableFilterRow>
      <TableFilter className="edit-table-filter">
        <p className="edit-mode-on-off">Edit</p>
        {getEditModeSwitch()}
      </TableFilter>
    </TableFilterRow>
  ) : (
    <TableFilterRow />
  );
};

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_on_demand_price_edit],
})(OnDemandViewEditModeSelector);
