import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import isUUID from 'validator/lib/isUUID';
import { currentMultipleLocationDetailsSelector } from '../../../../../../../sharedStore/modules/locations/locations.selector';
import { LocationTitleStyledStandardPricing } from '../standardPricingTableAGGrid.styled';
import { Location } from '../../../../../../../generated/voyager/graphql';

function LocationDetailsRenderer({ node, value, data }: ICellRendererParams): ReactElement {
  const locationDetails = useSelector(currentMultipleLocationDetailsSelector);
  const [location, setLocation] = useState<Location>();

  useEffect(() => {
    let locationId: string | null = node?.key;
    if (value && (!locationId || !isUUID(locationId))) {
      locationId = value;
    }
    if (data?.location?.id && (!locationId || !isUUID(locationId))) {
      locationId = data?.location?.id;
    }
    setLocation(find(locationDetails, ['id', locationId]) ?? ({} as Location));
  }, [locationDetails, node, value, data]);

  return (
    <LocationTitleStyledStandardPricing>
      <span className="name">{location?.name ?? '-'}</span>
    </LocationTitleStyledStandardPricing>
  );
}

export default LocationDetailsRenderer;
