import styled from 'styled-components';
import { blue, darkBlue, gray, lightGray, pureBlack } from '../../../../../../styles/colors';

export const StandardPricingEditWrapBreakeven = styled.div`
  position: relative;
  order: 0;
  flex: 1 1 auto;
  justify-self: flex-start;
  align-self: center;
  width: 100%;
  height: 100%;
  max-width: calc(100% - 21px);
  &.fluid-width {
    max-width: 100%;
  }
  > .ui.input {
    margin: 0;
    min-width: auto;
    width: 100%;
    > input {
      width: 100%;
      min-width: auto;
      padding: 5px 6px 4px;
      border: 1px solid ${gray};
      border-radius: 2px;
      margin: 0;
      font-size: 12px;
      color: ${darkBlue};
      outline: none;
      &[type='number'] {
        -moz-appearance: textfield;
      }
      &:focus {
        border-color: ${blue};
      }
      &::placeholder {
        font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-style: italic;
        font-size: 12px;
        color: ${lightGray};
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  > .relative .reason-textbox {
    width: 100%;
    height: 40px;
    padding: 4px 8px;
    margin-top: 8px;
    background: white;
    border-radius: 2px;
    border: 1px solid ${gray};
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: ${darkBlue};
    resize: none;
    &:focus {
      border-color: ${blue};
      outline: none;
    }
    &::placeholder {
      color: ${darkBlue};
      opacity: 0.5;
    }
  }
  > .remaining-chars-label {
    position: absolute;
    bottom: 24px;
    right: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: right;
    color: ${darkBlue};
    opacity: 0.5;
  }
`;

export const StandardPricingEditWrap = styled.div`
  position: relative;
  order: 0;
  flex: 1 1 auto;
  justify-self: flex-start;
  align-self: center;
  width: 100%;
  height: 100%;
  max-width: calc(100% - 21px);
  &.fluid-width {
    max-width: 100%;
  }
  > .ui.input {
    margin: 0;
    min-width: auto;
    width: 100%;
    > input {
      width: 100%;
      min-width: auto;
      padding: 5px 6px 4px;
      border: 1px solid ${gray};
      border-radius: 2px;
      margin: 0;
      font-size: 12px;
      color: ${darkBlue};
      outline: none;
      &[type='number'] {
        -moz-appearance: textfield;
      }
      &:focus {
        border-color: ${blue};
      }
      &::placeholder {
        font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-style: italic;
        font-size: 12px;
        color: ${lightGray};
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  > .relative {
    > .reason-textbox {
      width: 100%;
      height: 40px;
      padding: 4px 8px;
      margin-top: 8px;
      background: white;
      border-radius: 2px;
      border: 1px solid ${gray};
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: ${darkBlue};
      resize: none;
      &:focus {
        border-color: ${blue};
        outline: none;
      }
      &::placeholder {
        color: ${darkBlue};
        opacity: 0.5;
      }
    }
  }
  > .remaining-chars-label {
    position: absolute;
    bottom: 6px;
    right: 20px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    text-align: right;
    color: ${darkBlue};
    opacity: 0.5;
  }
`;

export const LocationTitleStyledStandardPricing = styled.div`
  padding: 0;
  margin: 0 0 14px;
  > span {
    flex: 0 0 auto;
    display: block;
  }
  > .name {
    font-size: 12px;
    font-weight: bold;
    color: ${darkBlue};
  }
  > .market {
    font-size: 10px;
    font-weight: lighter;
    color: ${pureBlack};
  }
`;
