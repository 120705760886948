import * as React from 'react';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import cn from 'classnames';
import { PanelSectionItem, SectionBody, SectionHeader } from '../detailSidePanel.styled';
import {
  ItemBlockDesks,
  ItemBlockFinancial,
  ItemBlockOccupancy,
  ItemDetailBlockDesks,
  ItemDetailBlockFinancial,
  ItemDetailBlockOccupancy,
} from './detailItemBlockTypes';
import {
  isNumberNullFormat,
  isPercentageNull,
} from '../../../app/pricing/standardPricing/components/helpers';
import { Location } from '../../../generated/voyager/graphql';

export enum LocationDetailItemType {
  desks,
  occupancy,
  financial,
}
interface DetailItemHeaderLocation {
  detailLabel: string;
  data: Location;
  type: LocationDetailItemType;
}

function DetailItemHeaderLocation({
  detailLabel,
  data,
  type,
}: DetailItemHeaderLocation): ReactElement {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);
  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  const metrics = data?.metrics;
  const currencyIsoCode = data?.currencyIsoCode;

  const deskCountData = useMemo(
    () =>
      ({
        hotDeskCount: metrics?.hotDeskCount,
        dedicatedDeskCount: metrics?.dedicatedDeskCount,
        sharedOfficeCount: metrics?.sharedOfficeCount,
        privateOfficeCount: metrics?.privateOfficeCount,
      } as ItemBlockDesks),
    [metrics],
  );

  const occupancyData = useMemo(
    () =>
      ({
        locationSku: {
          last6M: metrics?.trailing6mOccupancy,
          last3M: metrics?.trailing3mOccupancy,
          current: metrics?.currentOccupancy,
          next3M: metrics?.next3mOccupancy,
          next6M: metrics?.next6mOccupancy,
        },
        largeSku: [
          {
            label: 'Last 6M',
            value: isPercentageNull(metrics?.lgSkuPre6mOccupancy),
            highlight: false,
          },
          {
            label: 'Last 3M',
            value: isPercentageNull(metrics?.lgSkuPre3mOccupancy),
            highlight: false,
          },
          {
            label: 'Current',
            value: isPercentageNull(metrics?.largeSkuOccupancy),
            highlight: true,
          },
          {
            label: 'Next 3M',
            value: isPercentageNull(metrics?.lgSkuNext3mOccupancy),
            highlight: false,
          },
          {
            label: 'Next 6M',
            value: isPercentageNull(metrics?.lgSkuNext6mOccupancy),
            highlight: false,
          },
        ],
        smallSku: [
          {
            label: 'Last 6M',
            value: isPercentageNull(metrics?.smSkuPre6mOccupancy),
            highlight: false,
          },
          {
            label: 'Last 3M',
            value: isPercentageNull(metrics?.smSkuPre3mOccupancy),
            highlight: false,
          },
          {
            label: 'Current',
            value: isPercentageNull(metrics?.smallSkuOccupancy),
            highlight: true,
          },
          {
            label: 'Next 3M',
            value: isPercentageNull(metrics?.smSkuNext3mOccupancy),
            highlight: false,
          },
          {
            label: 'Next 6M',
            value: isPercentageNull(metrics?.smSkuNext6mOccupancy),
            highlight: false,
          },
        ],
        last6M: metrics?.trailing6mOccupancy,
      } as ItemBlockOccupancy),
    [metrics],
  );

  const financialData = useMemo(
    () =>
      ({
        minPrice: [
          {
            label: 'Last Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.prev1mMinPriceLocal),
            highlight: false,
          },
          {
            label: 'Current',
            value: isNumberNullFormat(currencyIsoCode, metrics?.minPriceLocal),
            highlight: true,
          },
          {
            label: 'Next Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.next1mMinPriceLocal),
            highlight: false,
          },
        ],
        avgNetArpm: [
          {
            label: 'Last Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.prev1mNetArpmLocal),
            highlight: false,
          },
          {
            label: 'Current',
            value: isNumberNullFormat(currencyIsoCode, metrics?.netArpmLocal),
            highlight: true,
          },
          {
            label: 'Next Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.next1mNetArpmLocal),
            highlight: false,
          },
        ],
        avgDiscount: [
          {
            label: 'Last Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.prev1mAvgDiscount),
            highlight: false,
          },
          {
            label: 'Current',
            value: isNumberNullFormat(currencyIsoCode, metrics?.avgDiscount),
            highlight: true,
          },
          {
            label: 'Next Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.next1mAvgDiscount),
            highlight: false,
          },
        ],
        largeSkuNetArpm: [
          {
            label: 'Last Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.prev1mLargeSkuNetArpmLocal),
            highlight: false,
          },
          {
            label: 'Current',
            value: isNumberNullFormat(currencyIsoCode, metrics?.lgSkuNetArpmLocal),
            highlight: true,
          },
          {
            label: 'Next Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.next1mLgSkuNetArpmLocal),
            highlight: false,
          },
        ],
        smallSkuNetArpm: [
          {
            label: 'Last Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.prev1mSmSkuNetArpmLocal),
            highlight: false,
          },
          {
            label: 'Current',
            value: isNumberNullFormat(currencyIsoCode, metrics?.smSkuNetArpmLocal),
            highlight: true,
          },
          {
            label: 'Next Month',
            value: isNumberNullFormat(currencyIsoCode, metrics?.next1mSmSkuNetArpmLocal),
            highlight: false,
          },
        ],
      } as ItemBlockFinancial),
    [metrics, currencyIsoCode],
  );

  if (!data?.metrics) {
    return <></>;
  }

  return (
    <PanelSectionItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <SectionHeader className="section-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{detailLabel}</span>
          <i className="toggle-accordion-icon" />
        </button>
      </SectionHeader>
      <SectionBody className="section-body">
        {type === LocationDetailItemType.desks && (
          <ItemDetailBlockDesks deskCountData={deskCountData} />
        )}
        {type === LocationDetailItemType.occupancy && (
          <ItemDetailBlockOccupancy occupancyData={occupancyData} />
        )}
        {type === LocationDetailItemType.financial && (
          <ItemDetailBlockFinancial financialData={financialData} />
        )}
      </SectionBody>
    </PanelSectionItem>
  );
}

export default DetailItemHeaderLocation;
