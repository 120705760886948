import { isEmpty, sortBy } from 'lodash';
import { PublishSummaryOnDemand } from '../store/entities/onDemandPricing';
import {
  GeoHierarchy,
  PriceHierarchyIdInput,
  Product,
} from '../../../../generated/voyager/graphql';

const getParentLoop = (
  id: string,
  orgHierarchy: string[],
  orgHierarchyNames: string[],
  data: any[],
): string[] => {
  const p: any = data.filter(i => i.id === id)[0];
  if (p.parentId) {
    orgHierarchy.unshift(p.id);
    orgHierarchyNames.unshift(p.name);
    return getParentLoop(p.parentId, orgHierarchy, orgHierarchyNames, data);
  }
  orgHierarchy.unshift(p.id);
  orgHierarchyNames.unshift(p.name);

  return orgHierarchy;
};

export interface HierarchyInterface extends GeoHierarchy {
  orgHierarchy?: string[];
  orgHierarchyNames?: string[];
}

/**
 * https://stackoverflow.com/questions/52421750/ag-grid-tree-data-structure
 * https://www.ag-grid.com/react-data-grid/tree-data/
 * This method generates an orgHierarchy array for each element of the input array.
 * The orgHierarchy shows the hierarchical tree structure of the input data.
 * To build this hierarchy used id and parentId fields of each element.
 * An element is a top-level node if the parentId is null.
 */
export const generateTreeRowData = <T1 extends GeoHierarchy, T2 extends HierarchyInterface>(
  data: T1[],
): T2[] => {
  const rowData: T2[] = [];
  data.forEach((i: T1) => {
    const d: any = { ...i };
    d.orgHierarchy = [];
    d.orgHierarchyNames = [];
    d.orgHierarchy.push(d.id);
    d.orgHierarchyNames.push(d.name);
    if (d.parentId) {
      getParentLoop(d.parentId, d.orgHierarchy, d.orgHierarchyNames, data);
    }
    rowData.push(d);
  });
  return rowData;
};

export const generateWorkingPricesOnDemandMutationParam = (
  allItems: GeoHierarchy[],
  productsIdsToCancel: string[],
): PriceHierarchyIdInput[] => {
  const returnVal: PriceHierarchyIdInput[] = [];
  allItems.forEach(eachItem => {
    if (eachItem.workingPrices && !isEmpty(eachItem.workingPrices)) {
      eachItem.workingPrices
        .filter(eachWorkingPrice => productsIdsToCancel.includes(eachWorkingPrice.productId))
        .forEach(eachWorkingPrice =>
          returnVal.push({
            geoGroupingId: eachItem.id,
            productId: eachWorkingPrice.productId,
          }),
        );
    }
  });
  return returnVal;
};

export const futurePriceOnDemandCancelAll = (
  allItems: GeoHierarchy[],
  productsIdsToCancel: string[],
): PriceHierarchyIdInput[] => {
  const returnVal: PriceHierarchyIdInput[] = [];
  allItems.forEach(eachItem => {
    if (eachItem.futurePrices && !isEmpty(eachItem.futurePrices)) {
      eachItem.futurePrices
        .filter(eachFuturePrice => productsIdsToCancel.includes(eachFuturePrice.productId))
        .forEach(eachFuturePrice =>
          returnVal.push({
            geoGroupingId: eachItem.id,
            productId: eachFuturePrice.productId,
          }),
        );
    }
  });
  return returnVal;
};

export const workingPriceOnDemandRevertAll = (
  allItems: GeoHierarchy[],
  productsIdsToRevert: string[],
): PriceHierarchyIdInput[] => {
  const returnVal: PriceHierarchyIdInput[] = [];
  allItems.forEach(eachItem => {
    if (eachItem.workingPrices && !isEmpty(eachItem.workingPrices)) {
      eachItem.workingPrices
        .filter(eachWorkingPrice => productsIdsToRevert.includes(eachWorkingPrice.productId))
        .forEach(eachWorkingPrice =>
          returnVal.push({
            geoGroupingId: eachItem.id,
            productId: eachWorkingPrice.productId,
          }),
        );
    }
  });
  return returnVal;
};

export const generateWorkingPriceOnDemandArr = <T1 extends HierarchyInterface>(
  allItems: T1[],
  productsIdsToPublish: string[],
): T1[] => {
  const returnVal: T1[] = [];
  allItems.forEach(eachItem => {
    if (
      eachItem.workingPrices &&
      !isEmpty(eachItem.workingPrices.filter(wp => productsIdsToPublish.includes(wp.productId)))
    ) {
      returnVal.push(eachItem);
    }
  });
  return returnVal;
};

export const transformDataToPublishSummaryOnDemand = (
  allItems: HierarchyInterface[],
  onDemandProducts: Product[],
): PublishSummaryOnDemand[] => {
  const returnVal: PublishSummaryOnDemand[] = [];
  allItems.forEach((eachItem: HierarchyInterface) => {
    let valueToPush = {
      name: eachItem.orgHierarchyNames?.join(', ') ?? '',
      currencyIsoCode: eachItem.currencyIsoCode,
    };
    onDemandProducts.forEach((eachProduct: Product) => {
      const tempOldPriceItem = eachItem.currentPrices.find(EI => EI.productId === eachProduct.id);
      const oldPriceItem = isEmpty(tempOldPriceItem)
        ? eachItem.pricePreviews.find(EI => EI.productId === eachProduct.id)
        : tempOldPriceItem;
      const newPriceItem = eachItem.workingPrices.find(EI => EI.productId === eachProduct.id);
      if (newPriceItem && oldPriceItem?.price !== newPriceItem.price) {
        valueToPush = {
          ...valueToPush,
          [`${eachProduct.name}| old_Price`]: oldPriceItem?.price,
          [`${eachProduct.name}| new_Price`]: newPriceItem.price,
        };
      }
    });
    returnVal.push(valueToPush);
  });
  return sortBy(returnVal, 'name');
};
