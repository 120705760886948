// GraphQL Queries
import { gql } from '@apollo/client';

const PUBLISH_CURRENT_WORKING_PRICE = gql`
  mutation PublishWorkingPrices($publishWorkingPriceInput: PublishWorkingPriceInput!) {
    publishWorkingPrices(publishWorkingPriceInput: $publishWorkingPriceInput) {
      errors
      success
    }
  }
`;

export default PUBLISH_CURRENT_WORKING_PRICE;
