// GraphQL Queries
import { gql } from '@apollo/client';

// Mutation to store the current on Demand Working price.
export const CREATE_ON_DEMAND_WORKING_PRICE_MUTATION = gql`
  mutation SaveWorkingPriceHierarchy($priceHierarchy: [PriceHierarchyInput!]!) {
    saveWorkingPriceHierarchy(priceHierarchy: $priceHierarchy) {
      success
      errors
      code
    }
  }
`;

// Mutation to publish on demand working price.
export const PUBLISH_ON_DEMAND_WORKING_PRICE_MUTATION = gql`
  mutation PublishWorkingPriceHierarchy(
    $priceHierarchyIds: [PriceHierarchyIdInput!]!
    $publishDate: Date
  ) {
    publishWorkingPriceHierarchy(priceHierarchyIds: $priceHierarchyIds, publishDate: $publishDate) {
      success
      errors
    }
  }
`;

// Mutation to cancel on demand future price.
export const CANCEL_ON_DEMAND_FUTURE_PRICE_MUTATION = gql`
  mutation CancelFuturePriceHierarchy($priceHierarchyIds: [PriceHierarchyIdInput!]!) {
    cancelFuturePriceHierarchy(priceHierarchyIds: $priceHierarchyIds) {
      success
      errors
      code
    }
  }
`;

// Mutation to revert on demand working price.
export const REVERT_ON_DEMAND_WORKING_PRICE_MUTATION = gql`
  mutation RevertWorkingPriceHierarchy($priceHierarchyIds: [PriceHierarchyIdInput!]!) {
    revertWorkingPriceHierarchy(priceHierarchyIds: $priceHierarchyIds) {
      success
      errors
      code
    }
  }
`;

export const UPDATE_GEO_HIERARCHY_ATTRIBUTES_MUTATION = gql`
  mutation UpdateGeoHierarchyAttributes(
    $geoGroupingIds: [UUID!]!
    $productIds: [UUID!]!
    $label: GeoHierarchyAttributeLabel!
    $newValue: String
  ) {
    updateGeoHierarchyAttributes(
      geoGroupingIds: $geoGroupingIds
      productIds: $productIds
      label: $label
      newValue: $newValue
    ) {
      success
      errors
      code
    }
  }
`;
