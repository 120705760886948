import { Action, handleActions } from 'redux-actions';
import initialState, {
  initialLocationReconciliation,
} from '../../../../../utils/store/initialState';
import { LocationReconciliationSubset } from '../../entities/locationReconciliation';
import { createRDXConstant } from '../../../../../utils/store/store.constants';
import { Location } from '../../../../../generated/voyager/graphql';
// Action Constants
export const LOCATION_RECONCILIATION = createRDXConstant('LOCATION_RECONCILIATION');

export const LOCATION_RECONCILIATION_SUCCESS = createRDXConstant('LOCATION_RECONCILIATION_SUCCESS');
export const LOCATION_RECONCILIATION_ERROR = createRDXConstant('LOCATION_RECONCILIATION_ERROR');

export const LOCATION_RECONCILIATION_CLEAR_DATA = createRDXConstant(
  'LOCATION_RECONCILIATION_CLEAR_DATA',
);

export const LOCATION_RECONCILIATION_SET_LOCATION_IDS = createRDXConstant(
  'LOCATION_RECONCILIATION_SET_LOCATION_IDS',
);

export const LOCATION_RECONCILIATION_SET_LOCATION = createRDXConstant(
  'LOCATION_RECONCILIATION_SET_LOCATION',
);

export const LOCATION_RECONCILIATION_SET_IS_LOCATION_IDS_VALID = createRDXConstant(
  'LOCATION_RECONCILIATION_SET_IS_LOCATION_IDS_VALID',
);

// Reducer
export const locationReconciliationReducer = handleActions<LocationReconciliationSubset, any>(
  {
    [LOCATION_RECONCILIATION]: (state: LocationReconciliationSubset) => ({
      ...state,
      isLoading: true,
    }),
    [LOCATION_RECONCILIATION_SUCCESS]: (state: LocationReconciliationSubset) => ({
      ...state,
      ...initialLocationReconciliation,
      isLoading: false,
    }),
    [LOCATION_RECONCILIATION_ERROR]: (state: LocationReconciliationSubset) => ({
      ...state,
      isLoading: false,
    }),
    [LOCATION_RECONCILIATION_CLEAR_DATA]: (state: LocationReconciliationSubset) => ({
      ...state,
      ...initialLocationReconciliation,
    }),
    [LOCATION_RECONCILIATION_SET_LOCATION]: (
      state: LocationReconciliationSubset,
      action: Action<Location[]>,
    ) => ({
      ...state,
      locations: action.payload,
    }),
    [LOCATION_RECONCILIATION_SET_LOCATION_IDS]: (
      state: LocationReconciliationSubset,
      action: Action<string[]>,
    ) => ({
      ...state,
      locationIds: action.payload,
    }),
    [LOCATION_RECONCILIATION_SET_IS_LOCATION_IDS_VALID]: (
      state: LocationReconciliationSubset,
      action: Action<boolean>,
    ) => ({
      ...state,
      isLocationIdsValid: action.payload,
    }),
  },
  initialState.locationReconciliation,
);
