import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { MainRegionStyled, OverflowHack } from '../../../../styles/app.styled';
import OnDemandPricing from '../components/onDemandPricing';
import { AuthzProps } from '../../../../utils/constants';
import ROUTES from '../../../../utils/routes/routes';
import PublishOnDemandChangesBottomPanelComponent from '../components/bottomPanel/onDemandChangesBottomPanel';
import BreadcrumbComponent from '../../../../sharedComponents/breadcrumb/breadcrumbComponent';
import { MainTitleStyled } from '../../../../sharedComponents/header/header.styled';
import EmployeePermissions from '../../../../utils/store/permissions';
import { AppTitle } from '../../../../sharedComponents/homeDashboard/applicationCard.styled';

function OnDemandPricingHome({ requestedPermissions }: AuthzProps): ReactElement {
  const dispatch = useDispatch();
  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_on_demand_price_view, false)) {
      redirectToAccessDenied();
    }
  }, [requestedPermissions, redirectToAccessDenied]);

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <BreadcrumbComponent />
          <MainTitleStyled>
            <AppTitle>On Demand Pricing Management</AppTitle>
          </MainTitleStyled>
          <OnDemandPricing />
        </OverflowHack>
      </MainRegionStyled>
      <PublishOnDemandChangesBottomPanelComponent />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [
    EmployeePermissions.voyager_on_demand_price_view,
    EmployeePermissions.voyager_on_demand_price_edit,
  ],
})(OnDemandPricingHome);
