import * as React from 'react';
import { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';
import { v4 as uuidV4 } from 'uuid';
import { Override } from '../../store/entities/discountItems';
import { Unassigned } from '../../../../utils/sharedTypes';
import {
  isPercentageNull,
  roundToAtMost2Digit,
} from '../../../pricing/standardPricing/components/helpers';
import { BaseOverrideInput } from '../../../../generated/voyager/graphql';

interface DiscountSummaryTableProps {
  discountOverrides: Override[];
}

function DiscountSummaryTable({ discountOverrides }: DiscountSummaryTableProps): ReactElement {
  return (
    <>
      {discountOverrides.map((eDO: Override) => {
        const originalDiscountM2M: BaseOverrideInput | Unassigned = eDO.originalDiscounts?.find(
          eOD => eOD.termTypeLabel === 'M2M',
        );
        const originalDiscount6M: BaseOverrideInput | Unassigned = eDO.originalDiscounts?.find(
          eOD => eOD.termTypeLabel === '6M',
        );
        const originalDiscount12M: BaseOverrideInput | Unassigned = eDO.originalDiscounts?.find(
          eOD => eOD.termTypeLabel === '12M',
        );
        const originalDiscount24M: BaseOverrideInput | Unassigned = eDO.originalDiscounts?.find(
          eOD => eOD.termTypeLabel === '24M',
        );
        const originalDiscount36M: BaseOverrideInput | Unassigned = eDO.originalDiscounts?.find(
          eOD => eOD.termTypeLabel === '36M',
        );
        const baseDiscountM2M: BaseOverrideInput | Unassigned = eDO.baseDiscounts?.find(
          eOD => eOD.termTypeLabel === 'M2M',
        );
        const baseDiscount6M: BaseOverrideInput | Unassigned = eDO.baseDiscounts?.find(
          eOD => eOD.termTypeLabel === '6M',
        );
        const baseDiscount12M: BaseOverrideInput | Unassigned = eDO.baseDiscounts?.find(
          eOD => eOD.termTypeLabel === '12M',
        );
        const baseDiscount24M: BaseOverrideInput | Unassigned = eDO.baseDiscounts?.find(
          eOD => eOD.termTypeLabel === '24M',
        );
        const baseDiscount36M: BaseOverrideInput | Unassigned = eDO.baseDiscounts?.find(
          eOD => eOD.termTypeLabel === '36M',
        );

        return (
          <Table.Row key={uuidV4()}>
            <Table.Cell>{eDO.name}</Table.Cell>
            <Table.Cell>
              <span className="base">
                {originalDiscountM2M && originalDiscountM2M.value >= 0
                  ? isPercentageNull(roundToAtMost2Digit(originalDiscountM2M.value))
                  : '-'}
              </span>
              <span className="override">
                {baseDiscountM2M && baseDiscountM2M.value >= 0
                  ? `${roundToAtMost2Digit(baseDiscountM2M.value)}%`
                  : '-'}
              </span>
            </Table.Cell>
            <Table.Cell>
              <span className="base">
                {originalDiscount6M && originalDiscount6M.value >= 0
                  ? isPercentageNull(roundToAtMost2Digit(originalDiscount6M.value))
                  : '-'}
              </span>
              <span className="override">
                {baseDiscount6M && baseDiscount6M.value >= 0
                  ? `${roundToAtMost2Digit(baseDiscount6M.value)}%`
                  : '-'}
              </span>
            </Table.Cell>
            <Table.Cell>
              <span className="base">
                {originalDiscount12M && originalDiscount12M.value >= 0
                  ? isPercentageNull(roundToAtMost2Digit(originalDiscount12M.value))
                  : '-'}
              </span>
              <span className="override">
                {baseDiscount12M && baseDiscount12M.value >= 0
                  ? `${roundToAtMost2Digit(baseDiscount12M.value)}%`
                  : '-'}
              </span>
            </Table.Cell>
            <Table.Cell>
              <span className="base">
                {originalDiscount24M && originalDiscount24M.value >= 0
                  ? isPercentageNull(roundToAtMost2Digit(originalDiscount24M.value))
                  : '-'}
              </span>
              <span className="override">
                {baseDiscount24M && baseDiscount24M.value >= 0
                  ? `${roundToAtMost2Digit(baseDiscount24M.value)}%`
                  : '-'}
              </span>
            </Table.Cell>
            <Table.Cell>
              <span className="base">
                {originalDiscount36M && originalDiscount36M.value >= 0
                  ? isPercentageNull(roundToAtMost2Digit(originalDiscount36M.value))
                  : '-'}
              </span>
              <span className="override">
                {baseDiscount36M && baseDiscount36M.value >= 0
                  ? `${roundToAtMost2Digit(baseDiscount36M.value)}%`
                  : '-'}
              </span>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </>
  );
}

export default DiscountSummaryTable;
