import { StoreInterface } from '../../../../../../utils/store/store.interface';
import { StandardPricingFiltersSubset } from '../../entities/standardPricingFilters';
import { PriceableItemFilter } from '../../../../../../sharedStore/entities/priceableItemFilters';

const standardPricingFilters = (state: StoreInterface) => state?.standardPricingFilters;

export const standardPricingFiltersSelector = (state: StoreInterface): PriceableItemFilter[] =>
  standardPricingFilters(state)?.filters;

export const standardPricingFiltersSelectorSubset = (
  state: StandardPricingFiltersSubset,
): PriceableItemFilter[] => state?.filters;
