// GraphQL Queries
import { gql } from '@apollo/client';

// Query to fetch changelog of priceable item by id.
export const FETCH_PRICEABLE_ITEMS_CHANGE_LOG_QUERY = gql`
  query PriceableItem($id: ID!) {
    priceableItem(id: $id) {
      currentPrice {
        currencyIsoCode
      }
      workingPrice {
        changeLog {
          id
          price
          createdAt
          createdBy
        }
      }
    }
  }
`;

export const FETCH_FEATURE_CARD_BY_PRICEABLE_ITEMS_ID = gql`
  query PriceableItemFeature($id: ID!) {
    priceableItem(id: $id) {
      location {
        id
        timeZone
      }
      currentPrice {
        currencyIsoCode
        changeLog {
          id
          price
          createdAt
          createdBy
        }
      }
      prices {
        id
        price
        marketPrice
        currencyIsoCode
        validFrom
        publishedAt
        createdBy
        lastChangeReason
      }
      recommendedPrice {
        date
        price
        minPrice
        maxPrice
        confidenceLevel
        features {
          ordinal
          value
        }
      }
    }
  }
`;
