import { Action, handleActions } from 'redux-actions';
import { ResetBreakEvenSubset } from '../../entities/resetBreakEven';
import initialState from '../../../../../utils/store/initialState';
import { createRDXConstant } from '../../../../../utils/store/store.constants';

export const RESET_BREAKEVEN = createRDXConstant('RESET_BREAKEVEN');
export const RESET_BREAKEVEN_SUCCESS = createRDXConstant('RESET_BREAKEVEN_SUCCESS');
export const RESET_BREAKEVEN_ERROR = createRDXConstant('RESET_BREAKEVEN_ERROR');

export const resetBreakEvenReducer = handleActions<ResetBreakEvenSubset, any>(
  {
    [RESET_BREAKEVEN]: (state: ResetBreakEvenSubset) => ({
      ...state,
      resetBreakEvenState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [RESET_BREAKEVEN_SUCCESS]: (state: ResetBreakEvenSubset) => ({
      ...state,
      resetBreakEvenState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [RESET_BREAKEVEN_ERROR]: (state: ResetBreakEvenSubset, action: Action<string>) => ({
      ...state,
      resetBreakEvenState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
  },
  initialState.resetBreakEven,
);
