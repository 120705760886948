import { StoreInterface } from '../../../../../utils/store/store.interface';
import { SpacemanPricesConsolidationSubset } from '../../entities/spacemanPricesConsolidation';
import { FetchState } from '../../../../../utils/sharedTypes';

export const spacemanPricesConsolidationSelector = (
  state: StoreInterface,
): SpacemanPricesConsolidationSubset => state?.spacemanPricesConsolidation;

export const spacemanPricesConsolidationLoadingStateSelector = (
  state: StoreInterface,
): FetchState => spacemanPricesConsolidationSelector(state)?.spacemanPricesConsolidationState;
