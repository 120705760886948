import React, { ReactElement, useCallback } from 'react';
import { Button } from '@wework/ray2';
import { useDispatch } from 'react-redux';
import SplashPage from './splash/splashPage';
import { LOGIN_REQUEST } from '../sharedStore/modules/auth/auth.ducks';
import LoginInfo from '../styles/sharedLogin.styled';

function LoggedOut(): ReactElement {
  // Dispatching Actions
  const dispatch = useDispatch();

  // Dispatching props in functional component.
  const loginRequested = useCallback(() => dispatch({ type: LOGIN_REQUEST }), [dispatch]);

  return (
    <SplashPage>
      <>
        <LoginInfo>You were successfully logged out.</LoginInfo>
        <Button onClick={loginRequested} theme={'fill'} size={'medium'}>
          Log in with Google
        </Button>
      </>
    </SplashPage>
  );
}

export default LoggedOut;
