import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { get, isEqual, isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Textarea } from '@wework/ray2';
import cn from 'classnames';
import { v4 as uuidV4 } from 'uuid';
import { EditableCellStyled } from '../breakevenTableAGGrid.styled';
import { editModeSelectorBreakeven } from '../../../store/modules/breakevenItems/breakevenItems.selector';
import { CustomTableBodyCell } from '../../../../pricing/standardPricing/components/pricingTable/pricingTable.styled';
import { CREATE_BREAKEVEN_WORKING_DATA } from '../../../store/modules/breakevenItems/breakevenItems.ducks';
import { errorHandlerActive } from '../../../../../utils/errorHandling/helpers';
import { StandardPricingEditWrapBreakeven } from '../../../../pricing/standardPricing/components/standardPricingView/standardPricingTable/standardPricingTableAGGrid.styled';
import { fieldsEditable, typeMap } from '../../constants';
import { formatDate } from '../../../../../utils/helpers';

function EditableCellNotesRenderer(props: ICellRendererParams): ReactElement {
  // STATE PROPS
  const editMode = useSelector(editModeSelectorBreakeven);

  // STATE VAR
  const [reason, setReason] = useState<string>();
  const [displayType, setDisplayType] = useState<string>();
  // Check if this cell is editable. Only cells in override columns are editable.
  const [editableFlag, setEditableFlag] = useState<boolean>(false);
  // Special Information Flag - Need for the last row.
  const [infoFlag, setInfoFlag] = useState<boolean>(false);
  const [overrideFlag, setOverrideFlag] = useState<boolean>(false);

  useEffect(() => {
    setEditableFlag(
      fieldsEditable.has(props.colDef?.field ?? '') && props.data?.type === 'override',
    );
    setInfoFlag(props.data?.type === 'toBePublished');
    const currVal = isObject(props.value) ? get(props.value, 'value', '') : props.value;
    setReason(currVal);
    setDisplayType(get(typeMap, props.value?.type, ''));
  }, [props]);

  useEffect(() => {
    setOverrideFlag(infoFlag && displayType !== 'No Change' && displayType !== '');
  }, [infoFlag, displayType]);

  const dispatch = useDispatch();
  const createBreakevenWorkingPrice = useCallback(
    (payload: any) => dispatch({ type: CREATE_BREAKEVEN_WORKING_DATA, payload }),
    [dispatch],
  );

  const onSubmit = () => {
    if (!reason) {
      return;
    }
    // Saving the new data.
    const field = props.colDef?.field ?? null;
    const newDisplayValue = reason;
    const prevDisplayValue = props.value;

    // 1) Creating a backend request for updating the data.
    if (!isEqual(newDisplayValue, prevDisplayValue)) {
      if (field !== null) {
        createBreakevenWorkingPrice({
          geoLocationUUID: props.data?.location?.id,
          workingBreakEvenOverrideInput: {
            [field]: newDisplayValue,
          },
        });
      } else {
        errorHandlerActive(new Error('Fatal error: coldDef field is empty.'));
      }
    }
  };

  const setReasonInput = (event: any) => {
    setReason(event?.target?.value);
  };

  const remainingChar = () => (reason === '-' ? '150' : `${150 - (reason?.length ?? 0)}`);

  const renderPublishedRow = () => {
    const publishedAt = props?.data?.publishedAt;

    const publishedDate = publishedAt ? formatDate(publishedAt) : '';
    return <span>{!reason ? `${publishedDate}` : `${publishedDate} - ${reason}`}</span>;
  };

  return editMode && editableFlag ? (
    <CustomTableBodyCell>
      <StandardPricingEditWrapBreakeven className="fluid-width">
        <Textarea
          id={uuidV4()}
          placeholder="Enter a reason"
          className="reason-textbox"
          maxLength={150}
          onChange={(event: any) => setReasonInput(event)}
          value={reason ?? ''}
          onBlur={onSubmit}
          onKeyDown={(event: any) => event.key === 'Enter' && onSubmit()}
        />
        <p className="remaining-chars-label">{remainingChar()}</p>
      </StandardPricingEditWrapBreakeven>
    </CustomTableBodyCell>
  ) : (
    <EditableCellStyled>
      {!reason && infoFlag ? (
        <span className="info">No Change</span>
      ) : (
        <EditableCellStyled>
          {props.data?.type === 'current' ? (
            renderPublishedRow()
          ) : (
            <>
              <span className={cn({ override: overrideFlag }, 'reason')}>{reason ?? ''}</span>
            </>
          )}
        </EditableCellStyled>
      )}
    </EditableCellStyled>
  );
}

export default EditableCellNotesRenderer;
