import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Chip } from '@wework/dieter-ui';
import { detailPanelParamSelector } from '../../../sharedStore/modules/detailPanel/detailPanel.selector';
import {
  CLOSE_SIDE_PANEL,
  OPEN_SIDE_PANEL,
} from '../../../sharedStore/modules/detailPanel/detailPanel.ducks';
import { DisplayableItemSubSet } from '../../../sharedStore/entities/detailPanel';
import { trackAnalytics } from '../../../utils/analytics/helpers';

interface DetailPanelTriggerProps {
  currentDisplayableItem: string;
  currentDisplayableItemType: string;
}

function DetailPanelTrigger({
  currentDisplayableItem,
  currentDisplayableItemType,
}: DetailPanelTriggerProps): ReactElement {
  const currentDisplayable: DisplayableItemSubSet = {
    id: currentDisplayableItem,
    type: currentDisplayableItemType,
  };

  // LOCAL STATE PROPS
  const [buttonActive, setButtonActive] = useState<boolean>(false);

  // DISPATCH PROPS
  const dispatch = useDispatch();
  const openSidePanel = useCallback(
    (payload: DisplayableItemSubSet) => dispatch({ type: OPEN_SIDE_PANEL, payload }),
    [dispatch],
  );
  const closeSidePanel = useCallback(() => dispatch({ type: CLOSE_SIDE_PANEL }), [dispatch]);

  // STATE PROPS
  const locationDetailsPanelVisible = useSelector(detailPanelParamSelector);

  /**
   * Track Detail drawer button clicked
   */
  const detailsDrawerButtonTrack = () => {
    trackAnalytics('Location View Details Clicked', {
      workflow: `${currentDisplayableItemType} View Details`,
      object_type: 'icon',
      object_name: 'view details',
      object_value: 'Open',
      location_uuid: currentDisplayableItem,
    });
  };

  const closeSidePanelHandler = () => {
    closeSidePanel();
    setButtonActive(false);
  };

  const openSidePanelHandler = () => {
    openSidePanel(currentDisplayable);
    setButtonActive(true);
    detailsDrawerButtonTrack();
  };

  return (
    <Chip
      className={cn(
        { active: buttonActive && locationDetailsPanelVisible },
        'location-view-details',
      )}
      onClick={() =>
        locationDetailsPanelVisible ? closeSidePanelHandler() : openSidePanelHandler()
      }
    >
      View Details
    </Chip>
  );
}

export default DetailPanelTrigger;
