import styled from 'styled-components';

interface ButtonsWrapperProps {
  justifyContent?: string | undefined;
}

const ButtonsWrapper = styled.div<ButtonsWrapperProps>(
  {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    'img.link.icon': {
      cursor: 'pointer',
    },
  },
  ({ justifyContent }) => ({
    justifyContent,
    paddingLeft: justifyContent === 'start' ? 4 : 0,
  }),
);

export default ButtonsWrapper;
