import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { format } from 'date-fns';

export const dateHelper = (params: ValueGetterParams): Date | null =>
  params.data.metrics?.locationOpenDate
    ? new Date(`${params.data.metrics?.locationOpenDate}T00:00:00`)
    : null;

export const locationSearchDateGetter = (params: ValueGetterParams): string => {
  const dateValue = dateHelper(params);
  return dateValue ? format(dateValue, 'PP') : '--';
};
