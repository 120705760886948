import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import UploadFilePage from 'commonPages/uploadFilePage';
import ROUTES from './routes';
import { AuthzProps } from '../constants';
import EmployeePermissions from '../store/permissions';

const UploadPricesRoute = ({ requestedPermissions }: AuthzProps) => {
  const sampleFileLink =
    'https://docs.google.com/spreadsheets/d/11oG8B4HpH_ZSSq1jnXFkQz70rwmzlVMzf5CSQ-lQZbA/edit?usp=sharing';
  const dispatch = useDispatch();
  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (
      !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_publish, false)
    ) {
      redirectToAccessDenied();
    }
  }, [redirectToAccessDenied, requestedPermissions]);

  return <UploadFilePage title="Prices" sampleFileLink={sampleFileLink} />;
};

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_price_publish],
})(UploadPricesRoute);
