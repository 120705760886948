import { StoreInterface } from '../../../../../../utils/store/store.interface';
import { PublishPricesSubset, WorkingPricesPagedItem } from '../../entities/publishPrice';

export const publishPricesSelector = (state: StoreInterface): PublishPricesSubset =>
  state?.publishPrice;

export const publishPricesSubsetSelector = (
  state: PublishPricesSubset,
): WorkingPricesPagedItem[] | null => state?.workingPrices;

export const publishPricesSelectorStore = (
  state: StoreInterface,
): WorkingPricesPagedItem[] | null => publishPricesSelector(state)?.workingPrices;

export const publishPricesBatchFetchSelectorStore = (state: StoreInterface): boolean =>
  publishPricesSelector(state)?.batchFetch;

export const publishPricesPublishSaveSelectorStore = (state: StoreInterface): boolean =>
  publishPricesSelector(state)?.publish;
