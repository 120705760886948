import { all, call, put, takeLatest } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { Action } from 'redux-actions';
import { graphQLClient } from '../../../index';
import {
  FETCH_TERRITORIES_BY_REGION,
  FETCH_TERRITORIES_BY_REGION_SUCCESS,
} from './territories.ducks';
import { errorHandlerActive } from '../../../utils/errorHandling/helpers';
import FETCH_TERRITORIES_BY_REGION_QUERY from './territories.query';

const fetchTerritoriesByRegion = (regionId: string): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_TERRITORIES_BY_REGION_QUERY,
    variables: { regionId },
  });

// Saga - fetch all the territories by selected region.
function* fetchTerritoriesByRegionWorker(action: Action<string>) {
  try {
    const { errors, data } = yield call(fetchTerritoriesByRegion, action?.payload ?? '');
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }
    yield put({ type: FETCH_TERRITORIES_BY_REGION_SUCCESS, payload: data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

export default function* territorySaga(): any {
  yield all([takeLatest(FETCH_TERRITORIES_BY_REGION, fetchTerritoriesByRegionWorker)]);
}
