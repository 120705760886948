import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Button } from '@wework/ray2';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  ErrorCatImage,
  ErrorPageButtonFlex,
  ErrorPageContainer,
  ErrorSubTitle,
  ErrorTitle,
} from './error.style';
import errorCat from '../../assets/error_cat.gif';
import errorDog from '../../assets/error_dog.gif';
import ROUTES from '../../utils/routes/routes';
import { randomBoolean } from '../../utils/helpers';

// Exported base (non-connected) component
function ErrorPageBase(): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  // Dispatching props in functional component.
  const redirectToHome = useCallback(() => dispatch(push(ROUTES.ROOT)), [dispatch]);

  return (
    <ErrorPageContainer>
      <ErrorTitle>Oops!</ErrorTitle>
      <ErrorSubTitle>Looks like you&#39;re stuck.</ErrorSubTitle>
      <ErrorCatImage src={randomBoolean() ? errorCat : errorDog} />
      <ErrorPageButtonFlex>
        <Button theme={'fill'} size={'medium'} onClick={redirectToHome}>
          Click to go home
        </Button>
      </ErrorPageButtonFlex>
    </ErrorPageContainer>
  );
}

export default ErrorPageBase;
