import React, { ReactElement } from 'react';
import WeLogo from '../../assets/we_work_logo.svg';
import Logo from '../../assets/logo_white.svg';
import {
  SplashPageBg,
  SplashPageContent,
  SplashPageWrapper,
  VoyagerLogoMainPage,
  WeWorkLogo,
  WeWorkLogoContainer,
} from './splashPage.styled';

interface SplashPageProps {
  children: React.ReactChild;
}

const SplashPage = ({ children }: SplashPageProps): ReactElement => (
  <SplashPageWrapper>
    <SplashPageBg>
      <SplashPageContent>
        <VoyagerLogoMainPage src={Logo} />
        {children}
        <WeWorkLogoContainer>
          <WeWorkLogo src={WeLogo} />
        </WeWorkLogoContainer>
      </SplashPageContent>
    </SplashPageBg>
  </SplashPageWrapper>
);

export default SplashPage;
