import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
  currentDisplayableItemSelector,
  detailPanelParamSelector,
} from '../../../../../../../sharedStore/modules/detailPanel/detailPanel.selector';
import {
  CLOSE_SIDE_PANEL,
  OPEN_SIDE_PANEL,
} from '../../../../../../../sharedStore/modules/detailPanel/detailPanel.ducks';
import { DisplayableItemSubSet } from '../../../../../../../sharedStore/entities/detailPanel';
import { trackAnalytics } from '../../../../../../../utils/analytics/helpers';
import { CustomTableBodyCell } from '../../../pricingTable/pricingTable.styled';

interface DetailPanelTriggerProps {
  data: any;
}
function DetailPanelTrigger({ data }: DetailPanelTriggerProps): ReactElement {
  const currentDisplayableItem: DisplayableItemSubSet = {
    id: data?.id,
    type: 'reservable',
  };

  // DISPATCH PROPS
  const dispatch = useDispatch();
  const openSidePanel = useCallback(
    (payload: DisplayableItemSubSet) => dispatch({ type: OPEN_SIDE_PANEL, payload }),
    [dispatch],
  );
  const closeSidePanel = useCallback(() => dispatch({ type: CLOSE_SIDE_PANEL }), [dispatch]);

  // STATE PROPS
  const itemDetailsPanelVisible = useSelector(detailPanelParamSelector);
  const displayableItem = useSelector(currentDisplayableItemSelector);

  /**
   * Track Detail drawer button clicked
   */
  const detailsDrawerButtonTrack = useCallback(() => {
    trackAnalytics('Priceable Item View Details Clicked', {
      workflow: 'Priceable Item View Details',
      object_type: 'icon',
      object_name: 'view details',
      object_value: 'Open',
      location_uuid: data?.location.id,
      priceable_item_uuid: data?.id,
      priceable_item_sku: data?.sku,
      priceable_item_capacity: data?.capacity,
      priceable_item_type: data?.productType,
    });
  }, [data]);

  const openClose = useCallback(() => {
    if (itemDetailsPanelVisible) {
      if (currentDisplayableItem.id === displayableItem.id) {
        closeSidePanel();
      } else {
        openSidePanel(currentDisplayableItem);
        detailsDrawerButtonTrack();
      }
    } else {
      openSidePanel(currentDisplayableItem);
      detailsDrawerButtonTrack();
    }
  }, [
    itemDetailsPanelVisible,
    currentDisplayableItem,
    displayableItem.id,
    detailsDrawerButtonTrack,
    openSidePanel,
    closeSidePanel,
  ]);

  const buttonActive = displayableItem?.id === currentDisplayableItem.id;

  return data ? (
    <CustomTableBodyCell>
      <button
        type="button"
        id={data?.id}
        className={cn({ active: buttonActive }, 'details-drawer-trigger')}
        onClick={openClose}
        disabled={false}
      />
    </CustomTableBodyCell>
  ) : (
    <> </>
  );
}

export default DetailPanelTrigger;
