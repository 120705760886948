import { StoreInterface } from '../../../../../utils/store/store.interface';

export const inventoryReconciliationSelector = (state: StoreInterface) =>
  state?.inventoryReconciliation;

export const inventoryReconciliationIsLoadingSelector = (state: StoreInterface): boolean =>
  inventoryReconciliationSelector(state)?.isLoading;

export const inventoryReconciliationInventoryIdsSelector = (state: StoreInterface): string[] =>
  inventoryReconciliationSelector(state)?.inventoryIds;

export const inventoryReconciliationIsInventoryIdsValidSelector = (
  state: StoreInterface,
): boolean => inventoryReconciliationSelector(state)?.isInventoryIdsValid;
