import styled from 'styled-components';
import { List } from '@wework/dieter-ui';
import { FlexHorizontalHelper } from '../../../styles/app.styled';
import { darkBlue, pureBlack } from '../../../styles/colors';
import Open from '../../../assets/open.svg';

export const ActionItemContainer = styled.div`
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  border: 1px solid #e1e4ea;
`;

export const ActionBlocksList = styled(List)`
  padding: 20px;
  list-style: none;
`;

export const BlockContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;

  &.open {
    > .section-header .toggle-accordion-icon::before {
      transform: rotate(180deg);
    }

    > .section-body {
      height: auto;
    }
  }

  &.closed {
    > .section-body {
      height: 0;
    }
  }
`;

export const BlockBody = styled.div`
  overflow: hidden;
`;
export const BlockHeader = styled.div`
  > button {
    ${FlexHorizontalHelper};
    justify-content: space-between;
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    outline: none;
    text-align: left;

    span {
      display: block;
      flex: 1 1 auto;
      margin: 0;
      line-height: 28px;
      font-size: 16px;
      font-weight: 700;
      color: ${darkBlue};
    }

    i {
      display: block;
      flex: 0 0 auto;
      width: 16px;
      height: 16px;
      padding: 0;
      background: none;
      border: none;
      margin: 6px 0 0 0;
      cursor: pointer;
      outline: none;

      &::before {
        display: block;
        width: 16px;
        height: 17px;
        position: relative;
        content: '';
        background-color: ${darkBlue};
        -webkit-mask: url(${Open});
        mask: url(${Open});
      }
    }
  }
`;

export const CollapsibleHeader = styled.button`
  display: flex;
  flex-flow: row wrap;
`;

export const NavigateButtonContainer = styled.div`
  float: right;
  top: -4px;
  position: relative;
  > button {
    cursor: pointer;
  }
`;

export const GroupName = styled.span`
  padding-left: 5px;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: ${pureBlack};
`;

export const LoadMoreSpinnerContainer = styled.div`
  float: right;
  margin-right: 15px;
`;

export const SpinnerContainer = styled.div`
  margin-left: 40px;
`;

export const LoadMoreButtonContainer = styled.div`
  float: right;
  margin: 5px;
  padding: 5px;
`;
