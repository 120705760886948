import { Label } from 'semantic-ui-react';
import React, { ReactElement } from 'react';
import { calculateCurveLabel } from '../../discountItems.helpers';
import CurveStatusLabelWrapper from './discountsCurveManagementCurveStatus.styled';

interface DiscountsCurveManagementCurveStatusProps {
  data: any;
}
const DiscountsCurveManagementCurveStatus = ({
  data,
}: DiscountsCurveManagementCurveStatusProps): ReactElement => {
  if (data) {
    const labelProps = calculateCurveLabel(data, false);
    return (
      <CurveStatusLabelWrapper>
        <Label {...labelProps} />
      </CurveStatusLabelWrapper>
    );
  }

  return <> </>;
};

export default DiscountsCurveManagementCurveStatus;
