import { find } from 'lodash';
import { StoreInterface } from '../../../utils/store/store.interface';
import { DisplayableItemSubSet } from '../../entities/detailPanel';
import { currentMultipleLocationDetailsSelector } from '../locations/locations.selector';

const detailPanelSelector = (state: StoreInterface) => state?.detailPanel;

export const detailPanelParamSelector = (state: StoreInterface): boolean =>
  detailPanelSelector(state)?.sidePanelParam;

export const currentDisplayableItemSelector = (state: StoreInterface): DisplayableItemSubSet =>
  detailPanelSelector(state)?.currentDisplayableItem;

export const currentDisplayableItemDetailsByIdSelector =
  (currentDisplayableItem: DisplayableItemSubSet) =>
  (state: StoreInterface): any => {
    const id = currentDisplayableItem.id;
    const type = currentDisplayableItem.type;
    if (id && type === 'location') {
      const location = find(currentMultipleLocationDetailsSelector(state), { id });
      return {
        displayableItemData: location,
      };
    }
    if (id && type === 'reservable') {
      const reservable = find(state.standardPricingItems.allItems, { id });
      const locationId = reservable?.location?.id;
      const location = find(currentMultipleLocationDetailsSelector(state), { id: locationId });
      return {
        displayableItemData: reservable,
        locationData: location,
      };
    }
    return null;
  };
