import styled from 'styled-components';
import {
  blue,
  darkBlue,
  darkGray,
  green,
  lighterGray,
  pureBlack,
  red,
  strongBlue,
} from '../../../../../styles/colors';
import {
  FlexHorizontalHelper,
  FlexVerticalHelper,
  ListReset,
} from '../../../../../styles/app.styled';

export const SummaryTitle = styled.h3`
  display: block;
  padding: 0;
  margin: 0 16px 20px 0;
  line-height: 36px;
  font-size: 24px;
  font-weight: 700;
  color: ${darkBlue};
`;
export const SummarySubTitle = styled.h4`
  display: block;
  padding: 0;
  margin: 0 16px 12px 0 !important;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${darkBlue};
  &.left-align {
    float: left;
  }
  &.success {
    padding-top: 30px;
    text-align: center;
  }
  &.no-margin-right {
    margin-right: 0 !important;
    display: inline-block;
  }
`;
// DISCOUNT PUBLISH
export const TitleBar = styled.div`
  ${FlexHorizontalHelper};
  > h4 {
    flex: 0 0 auto;
  }
  > p {
    flex: 1 1 auto;
    &.info {
      justify-self: flex-end;
      text-align: right;
      > svg {
        display: inline;
      }
    }
  }
`;
export const SummaryNote = styled.p`
  padding: 0 0 0 10px;
  margin: 0;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 30, 51, 0.4);
  &.clear-float {
    clear: both;
    float: left;
  }
  &.no-indent {
    padding: 0;
  }
  > b {
    font-weight: 400;
    color: ${blue};
  }
  // TOOLTIP ICON
  > .icon {
    display: inline-block;
    padding: 0;
    width: 12px;
    height: 12px;
    margin: 5px 0 0 6px;
    vertical-align: top;
    cursor: pointer;
  }
`;
export const SummaryWrapper = styled.div`
  display: block;
  max-width: 533px;
  &.data-wrapper {
    max-width: 100%;
    height: calc(100vh - 185px);
  }
  > .download-button {
    float: right;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    color: ${blue};
  }
  .download-button-icon {
    display: inline-block;
    margin-right: 5px;
  }
`;
export const LocationsChangesSummaries = styled.ol`
  max-height: calc(88vh - 100px);
  height: auto;
  width: 100%;
  ${ListReset};
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin: 0 0 30px 0;
  overflow-y: auto;
`;
export const EachLocationSummary = styled.li`
  width: 497px;
  padding: 25px 0 13px;
  border-top: 1px solid ${lighterGray};
  margin: 0 0 0 18px;
  &.each-location {
    width: calc(100% - 20px);
  }
  &.each-location-breakeven {
    width: max-content;
  }
  &:first-of-type {
    padding-top: 14px;
    border: none;
  }
  > h4 {
    margin-bottom: 0 !important;
  }
  > .border-visible.ui.table {
    border-collapse: collapse;
  }

  > .changes-summary-table.ui.table {
    width: 100%;
    margin: 0;
    &.ui[class*='very basic'].table:not(.sortable):not(.striped) {
      thead tr:first-child th {
        padding-top: 10px;
      }
    }
    &.nowrap {
      white-space: nowrap;
    }
    &.breakeven {
      > tbody {
        td {
          width: 120px;
          white-space: pre-wrap;
        }
      }
    }
    > thead {
      th {
        height: 17px;
        padding: 10px 15px 12px 0;
        background: white;
        border-radius: 0 !important;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 3;
        overflow: visible;
        text-transform: unset;
        font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;
        color: ${darkGray};
      }
    }
    > tbody {
      tr.top-align {
        > td {
          vertical-align: top;
        }
      }
      .summary-line td {
        border-bottom: 1pt solid ${lighterGray};
        font-weight: bold;
      }
      td {
        height: 22px;
        padding: 0 15px 12px 0;
        border: 0;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: ${pureBlack};
        .double {
          ${FlexHorizontalHelper}
          > .override {
            ${FlexVerticalHelper}
            span:last-of-type {
              font-size: 11px;
            }
          }
        }
        .red {
          color: ${red};
        }
        .green {
          color: ${green};
        }
        .base {
          margin-right: 16px;
          color: rgba(0, 30, 51, 0.4);
        }
        .override {
          color: ${blue};
        }
        > .icon {
          display: inline-block;
          padding: 0;
          width: 12px;
          height: 12px;
          margin: 4px 4px 0 0;
          vertical-align: top;
          cursor: pointer;
        }
      }
    }
  }
`;
export const PriceChip = styled.span`
  display: inline-block;
  height: 20px;
  padding: 3px 6px;
  background: ${strongBlue};
  margin: 1px 0;
  border-radius: 12px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: white;
`;
export const LoadingBox = styled.div`
  width: 100%;
  padding: 14px 18px 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;
export const WorkingOverlay = styled.div`
  position: absolute;
  top: 93px;
  right: 1px;
  bottom: 63px;
  left: 1px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.75);
  > h4 {
    animation: heartbeat 1s infinite;
  }
  @keyframes heartbeat {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 0.75;
    }
    50% {
      opacity: 0.55;
    }
    75% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
`;
