import { Button, IconName, IconType, TextField } from '@wework/ray2';
import { Grid } from 'semantic-ui-react';
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import ExcelIcon from 'assets/icon_excel_file.svg';
import { trackAnalytics } from 'utils/analytics/helpers';
import {
  UPLOAD_FILE_IS_ARPM_PRICE_UPLOADED,
  UPLOAD_FILE_SET_EXPIRATION_DATE,
  UPLOAD_FILE_SET_FILE,
  UPLOAD_FILE_SET_PUBLISH_DATE,
  UPLOAD_FILE_SET_REASON,
} from 'sharedStore/modules/uploadFile/uploadFile.ducks';
import {
  publishFileErrorsSelector,
  publishFileFormatErrorSelector,
  publishFileIsARPMPriceSelector,
  publishFileLoadedFileSelector,
  publishFileLoadingSelector,
  publishFileReasonSelector,
} from 'sharedStore/modules/uploadFile/uploadFile.selector';
import { TableFilter } from 'styles/sharedTableFilterBar.styled';
import {
  AgGridWrapper,
  FileDescription,
  FileDropzone,
  FormTitle,
  UploadFileStyled,
} from './uploadFile.styled';
import { columnDefs, gridOptions } from './uploadFile.settings';
import currentPathNameSelector from '../../sharedStore/modules/router/router.selector';
import ROUTES from '../../utils/routes/routes';
import TextLink from '../styledText/styledText';

interface AnalyticTitleProps {
  analyticTitle?: string;
}

const UploadFile = ({ analyticTitle }: AnalyticTitleProps): ReactElement => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [fileUpdateOnError, setFileUpdateOnError] = useState<boolean>(false);
  const loadedFile = useSelector(publishFileLoadedFileSelector);
  const reason = useSelector(publishFileReasonSelector);
  const isARPMPrice = useSelector(publishFileIsARPMPriceSelector);
  const fileErrors = useSelector(publishFileErrorsSelector);
  const fileFormatError = useSelector(publishFileFormatErrorSelector);
  const isLoading = useSelector(publishFileLoadingSelector);
  const { pathname } = useSelector(currentPathNameSelector);

  const dispatch = useDispatch();

  const setFile = useCallback(
    (file: File | null) => dispatch({ type: UPLOAD_FILE_SET_FILE, payload: file }),
    [dispatch],
  );

  const setReason = useCallback(
    (_reason: string) => dispatch({ type: UPLOAD_FILE_SET_REASON, payload: _reason }),
    [dispatch],
  );

  const setIsARPMPrice = useCallback(
    (payload: Boolean) => dispatch({ type: UPLOAD_FILE_IS_ARPM_PRICE_UPLOADED, payload }),
    [dispatch],
  );

  const setPublishDate = useCallback(
    (payload: Date | undefined) => dispatch({ type: UPLOAD_FILE_SET_PUBLISH_DATE, payload }),
    [dispatch],
  );

  const setExpirationDate = useCallback(
    (payload: Date | undefined) => dispatch({ type: UPLOAD_FILE_SET_EXPIRATION_DATE, payload }),
    [dispatch],
  );

  useEffect(() => {
    if (gridApi) {
      const displayErrors: any[] = fileFormatError
        ? [{ rowNumber: '', error: fileFormatError }]
        : fileErrors;
      gridApi.setRowData(displayErrors);
      gridApi.deselectAll();
    }
  }, [fileFormatError, fileErrors, gridApi]);

  const handleDrop = useCallback(
    acceptedFiles => {
      // Adding the new file and resetting the old reason and published date.
      if (acceptedFiles.length > 0) {
        let file = acceptedFiles[0] as File;
        if (file.name.endsWith('.csv')) {
          file = new File([file], file.name, { type: 'text/csv' });
        }
        setFile(file);
        if (!fileUpdateOnError) {
          setReason('');
        }
        setFileUpdateOnError(false);
        setPublishDate(undefined);
        setExpirationDate(undefined);
      }
    },
    [setFile, setReason, setPublishDate, setExpirationDate, fileUpdateOnError],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    multiple: false,
    noClick: true,
    noKeyboard: true,
  });

  /**
   * OnGridReady is an AgGrid event that receives a param with access to the api.
   * @param params Parameter passed from AG grid.
   */
  const onGridReady = (params: GridReadyEvent): void => {
    if (params.columnApi) {
      params.columnApi.autoSizeAllColumns();
    }
    setGridApi(params?.api);
  };

  const hasFileError = fileErrors?.length > 0;
  const hasError = hasFileError || !!fileFormatError;
  const errorsNumber = fileErrors?.length || (!!fileFormatError && 1);

  const trackHelperBtnClick = (object_name: String) =>
    trackAnalytics(`Batch ${analyticTitle} - ${object_name} Button Click`, {
      workflow: `Batch Upload Prices ${object_name}`,
      object_type: 'button',
      object_name,
    });

  return isLoading ? (
    <UploadFileStyled>
      <Button size="large" theme="text" icon={IconName.AIR} iconType={IconType.OUTLINE} loading />
    </UploadFileStyled>
  ) : (
    <UploadFileStyled>
      {hasError ? (
        <>
          <div className="upload-file-root">
            <p className="upload-file-float-left">
              <b>{errorsNumber} errors found.</b> Please resolve errors and try uploading again.
              <TextLink className="margin-left-space" onClick={() => gridApi?.exportDataAsCsv()}>
                Download Grid
              </TextLink>
            </p>
            <FileDropzone {...getRootProps()} className="hidden-dropzone">
              <input {...getInputProps()} />
            </FileDropzone>
            <span className="upload-file-float-right">
              {loadedFile?.name}
              <Button
                theme={'fill'}
                size={'medium'}
                type="button"
                onClick={() => {
                  setFileUpdateOnError(true);
                  open();
                  trackHelperBtnClick('Update File');
                }}
              >
                Update File
              </Button>
            </span>
          </div>
          <AgGridWrapper className="ag-theme-alpine">
            <AgGridReact
              gridOptions={gridOptions}
              columnDefs={columnDefs}
              onGridReady={onGridReady}
            />
          </AgGridWrapper>
        </>
      ) : (
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column verticalAlign="bottom" className="grid-column">
              <FileDropzone {...getRootProps()}>
                <input {...getInputProps()} />
                {loadedFile ? (
                  <>
                    <FileDescription>
                      <img src={ExcelIcon} alt="excel" />
                      <p>{loadedFile?.name}</p>
                    </FileDescription>
                    <div>
                      <Button
                        theme={'fill'}
                        size={'medium'}
                        onClick={() => {
                          setFile(null);
                          setReason('');
                          setPublishDate(undefined);
                          setExpirationDate(undefined);
                          setIsARPMPrice(true);
                          trackHelperBtnClick('Remove');
                        }}
                      >
                        Remove
                      </Button>
                      <Button
                        theme={'fill'}
                        size={'medium'}
                        type="button"
                        className={'ml-2xs'}
                        onClick={() => {
                          open();
                          trackHelperBtnClick('Replace');
                        }}
                      >
                        Replace
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p>{'Drop file here, or click to select the items'}</p>
                    <Button
                      theme={'fill'}
                      size={'medium'}
                      type="button"
                      onClick={() => {
                        open();
                        trackHelperBtnClick('Open File Dialog');
                      }}
                    >
                      Open File Dialog
                    </Button>
                  </>
                )}
              </FileDropzone>
            </Grid.Column>
          </Grid.Row>
          {loadedFile && pathname !== ROUTES.UPLOAD_DISCOUNTS_FROM_FILE ? (
            <>
              <Grid.Row className="fixed-height">
                <Grid.Column verticalAlign="bottom" className="upload-file-form-input">
                  <FormTitle>Please select the type of uploaded price:</FormTitle>
                  <TableFilter className="info-button-groups">
                    <Button
                      theme={isARPMPrice ? 'fill' : 'outline'}
                      size={'medium'}
                      onClick={() => setIsARPMPrice(true)}
                    >
                      ARPM
                    </Button>
                    <Button
                      theme={!isARPMPrice ? 'fill' : 'outline'}
                      size={'medium'}
                      onClick={() => setIsARPMPrice(false)}
                    >
                      List Price
                    </Button>
                  </TableFilter>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="fixed-height">
                <Grid.Column className="upload-file-form-input">
                  <FormTitle>Please add a Reason for the upload: </FormTitle>
                  <TextField
                    placeholder="Enter a reason"
                    value={reason}
                    onChange={(e: any) => setReason(e.target.value)}
                    maxLength={150}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          ) : (
            ''
          )}
        </Grid>
      )}
    </UploadFileStyled>
  );
};

export default UploadFile;
