import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@wework/ray2';
import { isEmpty } from 'lodash';
import { Confirm } from 'semantic-ui-react';
import { pluralize } from 'apollo/lib/utils';
import { IRowNode } from 'ag-grid-community';
import { toast } from 'react-toastify';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'get-wild';
import cn from 'classnames';
import { BottomPanelWrapper } from '../../../../styles/sharedBottomPanel.styled';
import {
  CLEAR_OVERRIDE_VALUE,
  SET_IDS_CANCEL_FUTURE_DISCOUNT,
} from '../../store/modules/discountItems/discountItems.ducks';
import {
  cancelFutureDiscountIdsSelector,
  cancelFutureDiscountLoadingSelector,
  discountItemsOverrides,
  mutateCurvesLoadingSelector,
} from '../../store/modules/discountItems/discountItems.selector';
import DiscountsOverrideModal from '../discountsOverride/discountsOverrideModal';
import { routerHistory } from '../../../../utils/store/configureStore';
import ROUTES from '../../../../utils/routes/routes';
import DiscountCurvesModal from '../discountCurves/discountCurvesModal';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import { AuthzProps } from '../../../../utils/constants';
import EmployeePermissions from '../../../../utils/store/permissions';
import CancelFutureDiscountsConfirm from './confirmationPopup/cancelFutureDiscountsConfirm';
import { FutureDiscountDataCombo } from '../../store/entities/discountItems';

interface BottomPanelComponentProps extends AuthzProps {
  selectedNodes: IRowNode[];
}

function BottomPanelComponent({
  requestedPermissions,
  selectedNodes,
}: BottomPanelComponentProps): ReactElement {
  const dispatch = useDispatch();

  const resetOverrides = useCallback(
    (payload: string[]) => dispatch({ type: CLEAR_OVERRIDE_VALUE, payload }),
    [dispatch],
  );

  // STATE PROPS
  const [resetConfirmationModalOpen, setResetConfirmationModalOpen] = useState(false);
  const [cancelDiscountsConfirmationModalOpen, setCancelDiscountsConfirmationModalOpen] =
    useState(false);
  const [modalOpenOverride, setModalOpenOverride] = useState<boolean>(false);
  const [curvesModalOpen, setCurvesModalOpen] = useState<boolean>(false);

  const overrides = useSelector(discountItemsOverrides);
  const cancelDiscountIds = useSelector(cancelFutureDiscountIdsSelector);
  const cancelDiscountLoading = useSelector(cancelFutureDiscountLoadingSelector);
  const mutateCurvesLoading = useSelector(mutateCurvesLoadingSelector);

  const discountRequestIsRunning = cancelDiscountLoading || mutateCurvesLoading;

  function trackPreviewButton() {
    trackAnalytics('Discount - Preview Button Click', {
      workflow: 'Discount - Preview',
      object_type: 'button',
      object_name: 'Preview',
    });
  }

  function redirectToDiscountPreviewPage() {
    trackPreviewButton();
    routerHistory.push({
      pathname: ROUTES.PUBLISH_DISCOUNT_SUMMARY,
    });
  }

  function trackApplyOverrideButton() {
    trackAnalytics('Discount - Apply Override Button Click', {
      workflow: 'Discount Override',
      object_type: 'button',
      object_name: 'Apply Override',
    });
  }

  const setIdsCancelFutureDiscounts = useCallback(
    (payload: string[]) => dispatch({ type: SET_IDS_CANCEL_FUTURE_DISCOUNT, payload }),
    [dispatch],
  );

  useEffect(() => {
    const cancelFuturePriceDiscountIdsArr: string[] = [];

    selectedNodes.forEach(node => {
      if (
        (!isEmpty(
          get(node, 'data.*.futureBaseDiscount').filter((x: FutureDiscountDataCombo) => !!x),
        ) ||
          !isEmpty(
            get(node, 'data.*.futureOverride').filter((x: FutureDiscountDataCombo) => !!x),
          )) &&
        node.id != null
      ) {
        cancelFuturePriceDiscountIdsArr.push(node.id);
      }
    });
    setIdsCancelFutureDiscounts(cancelFuturePriceDiscountIdsArr);
  }, [selectedNodes, setIdsCancelFutureDiscounts]);

  const onClickApplyOverride = () => {
    trackApplyOverrideButton();
    setModalOpenOverride(true);
  };

  const disableFlagCancelFutureDiscount =
    !cancelDiscountLoading &&
    (cancelDiscountIds.length === 0 ||
      !get(
        requestedPermissions,
        EmployeePermissions.voyager_dedicated_space_discount_publish,
        false,
      ) ||
      discountRequestIsRunning);

  const disableFlagRevertDiscount =
    isEmpty(
      selectedNodes.filter(node => overrides.find(override => override.id === node.data.id)),
    ) || discountRequestIsRunning;

  return (
    <BottomPanelWrapper>
      <Button
        className={cn(
          { 'text-red-80': disableFlagCancelFutureDiscount },
          'text-negative btm-panel-btn-space ray-btn-styling-disabled',
        )}
        theme={'text'}
        size={'medium'}
        onClick={() => {
          if (!discountRequestIsRunning) setCancelDiscountsConfirmationModalOpen(true);
        }}
        disabled={disableFlagCancelFutureDiscount}
        loading={cancelDiscountLoading}
      >
        Cancel Future Discounts
      </Button>
      <Button
        className={cn(
          { 'text-red-80': disableFlagRevertDiscount },
          'text-negative btm-panel-btn-space ray-btn-styling-disabled',
        )}
        theme={'text'}
        size={'medium'}
        onClick={() => {
          setResetConfirmationModalOpen(true);
        }}
        disabled={disableFlagRevertDiscount}
      >
        Revert Discounts to Last Published
      </Button>
      <Button
        theme={'outline'}
        size={'medium'}
        className="btm-panel-btn-space"
        onClick={() => onClickApplyOverride()}
        disabled={!selectedNodes.length || discountRequestIsRunning}
      >
        Apply Discounts
      </Button>
      {modalOpenOverride && (
        <DiscountsOverrideModal
          discountsOverrideModalClose={() => setModalOpenOverride(false)}
          rowData={selectedNodes}
        />
      )}
      <Button
        theme={'outline'}
        size={'medium'}
        className="btm-panel-btn-space"
        onClick={() => {
          if (!discountRequestIsRunning) setCurvesModalOpen(true);
        }}
        disabled={
          !mutateCurvesLoading &&
          (!selectedNodes.length ||
            !get(
              requestedPermissions,
              EmployeePermissions.voyager_dedicated_space_discount_curve_apply,
              false,
            ) ||
            discountRequestIsRunning)
        }
        loading={mutateCurvesLoading}
      >
        Manage Curves
      </Button>
      {curvesModalOpen && (
        <DiscountCurvesModal
          closeModal={() => setCurvesModalOpen(false)}
          priceableItemIds={selectedNodes.map((node: IRowNode) => node.data.id)}
        />
      )}
      <Button
        theme={'fill'}
        size={'medium'}
        onClick={redirectToDiscountPreviewPage}
        disabled={!overrides.length || discountRequestIsRunning}
      >
        Preview
      </Button>
      <Confirm
        open={resetConfirmationModalOpen}
        onCancel={() => setResetConfirmationModalOpen(false)}
        onConfirm={() => {
          const idsToClear = selectedNodes
            .map((node: IRowNode) => node.data.id)
            .filter((nodeId: any) => overrides.find(override => override.id === nodeId));
          resetOverrides(idsToClear);
          setResetConfirmationModalOpen(false);
          toast.success(
            `Reverted discounts for ${pluralize(idsToClear.length, 'item')} to last published`,
          );
        }}
        cancelButton={{ content: 'Cancel', className: 'cancel-confirmation' }}
        closeIcon
        confirmButton={{
          content: `Revert Discounts for ${pluralize(
            selectedNodes.filter(node => overrides.find(override => override.id === node.id))
              .length,
            'item',
          )}`,
        }}
        header="Revert Discounts"
        content={
          <div>
            <p>
              For the selected reservables, discounts will revert to what was last published - your
              unpublished overrides will be lost.
            </p>
            <p>Are you sure you want to revert discounts to last published?</p>
          </div>
        }
      />
      <CancelFutureDiscountsConfirm
        closeModal={() => setCancelDiscountsConfirmationModalOpen(false)}
        isOpen={cancelDiscountsConfirmationModalOpen}
      />
    </BottomPanelWrapper>
  );
}

export default withRequestedAuthz<BottomPanelComponentProps>({
  permissions: [
    EmployeePermissions.voyager_dedicated_space_discount_curve_apply,
    EmployeePermissions.voyager_dedicated_space_discount_publish,
  ],
})(BottomPanelComponent);
