import { Action, handleActions } from 'redux-actions';
import { get } from 'lodash';
import { createRDXConstant } from '../../utils/store.constants';
import { Job, JobStatusSubset } from '../../entities/jobStatus';
import initialState from '../../../../../utils/store/initialState';

export const CHECK_JOB_STATUS = createRDXConstant('CHECK_JOB_STATUS');
export const SET_JOB_STATUS = createRDXConstant('SET_JOB_STATUS');
// Reducer
export const JobStatusReducer = handleActions<JobStatusSubset, any>(
  {
    [CHECK_JOB_STATUS]: (state: JobStatusSubset) => ({
      ...state,
    }),
    [SET_JOB_STATUS]: (state: JobStatusSubset, action: Action<{ data: Job }>) => ({
      ...state,
      job: get(action, 'data.job', null),
    }),
  },
  initialState.jobStatus,
);
