import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { CustomTableBodyCell } from '../../../standardPricing/components/pricingTable/pricingTable.styled';
import { priceHistoryGeoSelector } from '../../store/modules/onDemand/onDemandPricing.selector';
import {
  CLOSE_PRICE_HISTORY_SIDE_PANEL,
  OPEN_PRICE_HISTORY_SIDE_PANEL,
} from '../../store/modules/onDemand/onDemandPricing.ducks';
import { GeoHierarchy } from '../../../../../generated/voyager/graphql';

const OpenPriceHistoryRenderer = (params: ICellRendererParams): ReactElement => {
  const currentGeo: GeoHierarchy = params.data;
  const dispatch = useDispatch();
  const openSidePanel = useCallback(
    (payload: GeoHierarchy) => dispatch({ type: OPEN_PRICE_HISTORY_SIDE_PANEL, payload }),
    [dispatch],
  );
  const closeSidePanel = useCallback(
    () => dispatch({ type: CLOSE_PRICE_HISTORY_SIDE_PANEL }),
    [dispatch],
  );

  const displayableGeo = useSelector(priceHistoryGeoSelector);

  const openClose = useCallback(() => {
    if (displayableGeo != null && displayableGeo?.id === currentGeo.id) {
      closeSidePanel();
    } else {
      openSidePanel(currentGeo);
    }
  }, [currentGeo, displayableGeo, openSidePanel, closeSidePanel]);

  const buttonActive = displayableGeo?.id === currentGeo.id;

  return (
    <CustomTableBodyCell>
      <button
        type="button"
        id={currentGeo?.id}
        className={cn({ active: buttonActive }, 'details-drawer-trigger')}
        onClick={openClose}
        disabled={false}
      />
    </CustomTableBodyCell>
  );
};

export default OpenPriceHistoryRenderer;
