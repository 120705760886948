import 'ag-grid-enterprise';
import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import CurveManagementTable from '../components/curveManagementTable/curveManagementTableAGGrid';
import { PageInfo } from '../../../generated/voyager/graphql';
import {
  CLEAR_DISCOUNT_ITEMS_CURVES,
  FETCH_DISCOUNT_ITEMS_CURVES,
  FETCH_DISCOUNT_ITEMS_TERM_TYPES,
} from '../store/modules/discountItems/discountItems.ducks';
import { MainRegionStyled, OverflowHack } from '../../../styles/app.styled';
import ROUTES from '../../../utils/routes/routes';
import { AuthzProps } from '../../../utils/constants';
import HeaderComponent from '../../../sharedComponents/header/header';
import EmployeePermissions from '../../../utils/store/permissions';
import { pageInfoSmall } from '../../../utils/store/store.constants';

function DiscountCurveManagementView({ requestedPermissions }: AuthzProps): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  // Dispatching props in functional component.
  const getGridData = useCallback(
    (page: PageInfo) => {
      const payload = {
        page,
        includeInactive: true,
      };
      dispatch({ type: FETCH_DISCOUNT_ITEMS_TERM_TYPES, payload: page });
      dispatch({ type: FETCH_DISCOUNT_ITEMS_CURVES, payload });
    },
    [dispatch],
  );

  const clearAll = useCallback(() => {
    dispatch({ type: CLEAR_DISCOUNT_ITEMS_CURVES });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (
      !get(
        requestedPermissions,
        EmployeePermissions.voyager_dedicated_space_discount_curve_manage,
        false,
      )
    ) {
      redirectToAccessDenied();
    } else {
      getGridData(pageInfoSmall);
    }

    return () => {
      clearAll();
    };
  });

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent title={'Manage Discount Curves'} />
          <CurveManagementTable />
        </OverflowHack>
      </MainRegionStyled>
    </>
  );
}
export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_discount_curve_manage],
})(DiscountCurveManagementView);
