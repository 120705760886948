import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetBreakEvenLoadingStateSelector } from '../../store/modules/resetBreakEven/resetBreakEven.selector';
import { RESET_BREAKEVEN } from '../../store/modules/resetBreakEven/resetBreakEven.ducks';
import { DataConsolidationButton } from '../../../../styles/functionalZone/adminPanel.styled';

const ResetBreakEven = () => {
  const resetBreakEvenLoadState = useSelector(resetBreakEvenLoadingStateSelector);

  const dispatch = useDispatch();
  const resetBreakevenSync = useCallback(() => dispatch({ type: RESET_BREAKEVEN }), [dispatch]);

  return (
    <>
      <DataConsolidationButton
        onClick={() => resetBreakevenSync()}
        disabled={resetBreakEvenLoadState.loading}
      >
        Reset BreakEven
      </DataConsolidationButton>
    </>
  );
};

export default ResetBreakEven;
