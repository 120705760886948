import * as React from 'react';
import { ReactElement } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { Icon, Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { get, isEqual, isNil } from 'lodash';
import cn from 'classnames';
import {
  roundToInteger,
  roundToIntegerReturnZero,
} from '../../../pricing/standardPricing/components/helpers';
import {
  getBooleanCurrentValue,
  getBooleanOverrideValue,
  getCurrentValueOrDash,
  getCurrentValueOrDashNumber,
  getOverrideSource,
  getOverrideValue,
  getOverrideValueOrDash,
  getOverrideValueOrDashNumber,
  getWorkingValue,
} from './breakevenPublishTable.helper';
import {
  marketSgAndALoadingSelector,
  marketSgAndASelector,
} from '../../store/modules/breakevenItems/breakevenItems.selector';
import Spinner from '../../../../assets/spinner.svg';
import {
  getAdjustedBy,
  getAdjustedByClassName,
  getBreakevenValue,
  getDifference,
} from '../breakevenTable/breakevenTable.helper';
import { intlCurrencyFormatter } from '../../../../utils/helpers';
import { LocationBreakEvenDetail } from '../../../../generated/voyager/graphql';

interface BreakevenPublishTableProps {
  item: LocationBreakEvenDetail;
}

function BreakevenPublishTable({ item }: BreakevenPublishTableProps): ReactElement {
  const marketSgAndAs = useSelector(marketSgAndASelector);
  const marketSgAndALoading = useSelector(marketSgAndALoadingSelector);

  const marketSgAndA = marketSgAndAs.find(
    mSgAndA => mSgAndA.marketId === item.location.market.id,
  )?.sgAndA;
  const rsf = getWorkingValue(item, 'rsf');
  const sgAndA = get(item.override, 'sgAndA') ?? (marketSgAndA && marketSgAndA * rsf);
  const roundedSgAndA = sgAndA && roundToInteger(sgAndA);
  const breakEvenPrice =
    sgAndA && getBreakevenValue(fieldName => getWorkingValue(item, fieldName), sgAndA);
  const roundedBreakEvenPrice = breakEvenPrice && roundToInteger(breakEvenPrice);

  const currentBreakEvenPrice =
    item.current?.breakEvenPrice && roundToInteger(item.current?.breakEvenPrice);

  const difference = getDifference(roundedBreakEvenPrice, currentBreakEvenPrice);
  const adjustedBy = getAdjustedBy(
    item.location.currencyIsoCode,
    difference,
    currentBreakEvenPrice,
  );
  const adjustedByClassName = getAdjustedByClassName(difference);

  const steadyStateOccOverride = getOverrideValue(item, 'steadyStateOcc');
  const currentSteadyStateOcc = item.current?.steadyStateOcc;

  const fetchRoundedBreakevenPrice = () =>
    !isNil(roundedBreakEvenPrice) &&
    !isNaN(roundedBreakEvenPrice) &&
    !isEqual(roundedBreakEvenPrice, currentBreakEvenPrice)
      ? intlCurrencyFormatter(item.location.currencyIsoCode, roundedBreakEvenPrice)
      : '-';

  const fetchRoundedSGA = () =>
    !isNil(roundedSgAndA) &&
    !isNaN(roundedSgAndA) &&
    !isEqual(roundedSgAndA, getCurrentValueOrDash(item, 'sgAndA'))
      ? intlCurrencyFormatter(item.location.currencyIsoCode, roundedSgAndA)
      : '-';

  const overrideSGAFlag = !isEqual(roundedSgAndA, getCurrentValueOrDash(item, 'sgAndA'));
  const overrideBreakevenFlag = !isEqual(roundedBreakEvenPrice, currentBreakEvenPrice);

  return (
    <Table basic="very" className="changes-summary-table nowrap breakeven">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Data Type</Table.HeaderCell>
          <Table.HeaderCell>Breakeven Price</Table.HeaderCell>
          <Table.HeaderCell>Steady State Occ</Table.HeaderCell>
          <Table.HeaderCell>RSF</Table.HeaderCell>
          <Table.HeaderCell>Desk Capacity</Table.HeaderCell>
          <Table.HeaderCell>Rent</Table.HeaderCell>
          <Table.HeaderCell>Tenancy/NNN</Table.HeaderCell>
          <Table.HeaderCell>OPEX</Table.HeaderCell>
          <Table.HeaderCell>SG&A</Table.HeaderCell>
          <Table.HeaderCell>Desks in Year Months</Table.HeaderCell>
          <Table.HeaderCell>Total Budgeted Revenue</Table.HeaderCell>
          <Table.HeaderCell>Included in SG&A?</Table.HeaderCell>
          <Table.HeaderCell>Show Breakeven?</Table.HeaderCell>
          <Table.HeaderCell>Notes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row key={uuidV4()} className="top-align">
          <Table.Cell>
            <span className="base">Published</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">
              {currentBreakEvenPrice
                ? intlCurrencyFormatter(item.location.currencyIsoCode, currentBreakEvenPrice)
                : '-'}
            </span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">
              {isNil(currentSteadyStateOcc)
                ? '-'
                : `${roundToIntegerReturnZero(currentSteadyStateOcc * 100, 1)}%`}
            </span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getCurrentValueOrDash(item, 'rsf')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getCurrentValueOrDash(item, 'deskCapacity')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getCurrentValueOrDashNumber(item, 'rent')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getCurrentValueOrDashNumber(item, 'tenancyNNN')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getCurrentValueOrDashNumber(item, 'opex')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getCurrentValueOrDashNumber(item, 'sgAndA')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getCurrentValueOrDash(item, 'desksInYearMonths')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getCurrentValueOrDashNumber(item, 'totalBudgetRevenue')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getBooleanCurrentValue(item, 'includedSGA')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{getBooleanCurrentValue(item, 'showBreakEven')}</span>
          </Table.Cell>
          <Table.Cell>
            <span className="base">{item.current?.notes ?? '-'}</span>
          </Table.Cell>
        </Table.Row>

        <Table.Row key={uuidV4()} className="top-align">
          <Table.Cell>
            <div className="override">To Be Published</div>
          </Table.Cell>
          <Table.Cell>
            <div className={cn({ override: overrideBreakevenFlag })}>
              {marketSgAndALoading ? <Icon as="img" src={Spinner} /> : fetchRoundedBreakevenPrice()}
            </div>
            <div className={adjustedByClassName}>
              {marketSgAndALoading ? <Icon as="img" src={Spinner} /> : adjustedBy ?? '-'}
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">
              <div>
                {isNil(steadyStateOccOverride)
                  ? '-'
                  : `${roundToIntegerReturnZero(steadyStateOccOverride * 100, 1)}%`}
              </div>
              <div>{getOverrideSource(item, 'steadyStateOcc')}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">
              <div>{getOverrideValueOrDash(item, 'rsf')}</div>
              <div>{getOverrideSource(item, 'rsf')}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">
              <div>{getOverrideValueOrDash(item, 'deskCapacity')}</div>
              <div>{getOverrideSource(item, 'deskCapacity')}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">
              <div>{getOverrideValueOrDashNumber(item, 'rent')}</div>
              <div>{getOverrideSource(item, 'rent')}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">
              <div>{getOverrideValueOrDashNumber(item, 'tenancyNNN')}</div>
              <div>{getOverrideSource(item, 'tenancyNNN')}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">
              <div>{getOverrideValueOrDashNumber(item, 'opex')}</div>
              <div>{getOverrideSource(item, 'opex')}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className={cn({ override: overrideSGAFlag })}>
              {marketSgAndALoading ? (
                <Icon as="img" src={Spinner} />
              ) : (
                <>
                  <div>{fetchRoundedSGA()}</div>
                  <div>{getOverrideSource(item, 'sgAndA')}</div>
                </>
              )}
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">
              <div>{getOverrideValueOrDash(item, 'desksInYearMonths')}</div>
              <div>{getOverrideSource(item, 'desksInYearMonths')}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">
              <div>{getOverrideValueOrDashNumber(item, 'totalBudgetRevenue')}</div>
              <div>{getOverrideSource(item, 'totalBudgetRevenue')}</div>
            </div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">{getBooleanOverrideValue(item, 'includedSGA')}</div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">{getBooleanOverrideValue(item, 'showBreakEven')}</div>
          </Table.Cell>
          <Table.Cell>
            <div className="override">{item.override?.notes ?? '-'}</div>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

export default BreakevenPublishTable;
