import styled from 'styled-components';

const PanelBody = styled.div`
  overflow-y: auto;
  height: calc(100% - 186px);
  .ui.pointing.secondary.menu {
    position: relative;
    margin: 0;
    .item {
      flex: 1 1 50%;
    }
    &::before {
      display: block;
      position: absolute;
      bottom: -11px;
      left: 0;
      right: 18px;
      z-index: 2;
      content: '';
      height: 10px;
      background: linear-gradient(-180deg, white 0%, rgba(255, 255, 255, 0.5) 100%);
    }
  }
  .ui.bottom.attached.segment.tab {
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 200px);
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    overflow-y: auto;
  }
`;

export default PanelBody;
