import cn from 'classnames';
import { TextField, Textarea } from '@wework/ray2';
import { toast } from 'react-toastify';
import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownItemProps, Checkbox, Dropdown, DropdownProps } from 'semantic-ui-react';
import { v4 as uuidV4 } from 'uuid';
import {
  BaseOverrideRowModified,
  BaseOverrideWrapper,
  ModalSubTitle,
  SpanWidth,
} from '../../discountsOverride/discountsOverride.styled';
import {
  Curve,
  CurveDataPoint,
  CurveInput,
  CurveTerm,
  QueryFindCurvesArgs,
  TermType,
} from '../../../../../generated/voyager/graphql';
import { Unassigned } from '../../../../../utils/sharedTypes';
import {
  CurveDataPointInputMod,
  CurveTermInputMod,
  DiscountCurvesModalMode,
  Operator,
} from '../curveManagementModal';
import {
  curvesSelector,
  discountItemsTermTypes,
  userOperatorsLoadingSelector,
  userOperatorsSelector,
} from '../../../store/modules/discountItems/discountItems.selector';
import { useDebounce } from '../../../../pricing/standardPricing/components/helpers';
import { calculateCurveLabel } from '../../discountItems.helpers';
import {
  CLEAR_CURVES,
  FIND_CURVES,
} from '../../../store/modules/discountItems/discountItems.ducks';

interface DiscountCurvesOverrideModalHeaderProps {
  mode: DiscountCurvesModalMode;
  curveInput: Omit<CurveInput, 'curveTerms' | 'expirationDate'> | Unassigned;
  setCurveInput: Function;
  setSelectedCurve: Function;
  setRetainLinksSelector: Function;
  setSelectedTerms: Function;
  setCurveTermInputMod: Function;
  setPrevCurveTermInputMod: Function;
  selectedCurve?: Curve;
  retainLinksSelector: boolean;
  operator?: Operator;
  setOperator: Function;
}

function DiscountCurvesOverrideModalHeader({
  mode,
  curveInput,
  setCurveInput,
  setSelectedCurve,
  setRetainLinksSelector,
  setSelectedTerms,
  setCurveTermInputMod,
  setPrevCurveTermInputMod,
  selectedCurve,
  retainLinksSelector,
  operator,
  setOperator,
}: DiscountCurvesOverrideModalHeaderProps): ReactElement {
  const dispatch = useDispatch();
  const findCurves = useCallback(
    (payload: QueryFindCurvesArgs) => dispatch({ type: FIND_CURVES, payload }),
    [dispatch],
  );
  const clearCurves = useCallback(() => dispatch({ type: CLEAR_CURVES }), [dispatch]);

  useEffect(() => {
    findCurves({ text: '', includeInactive: true });
  }, [findCurves]);

  const allCurves = useSelector(curvesSelector);
  const termTypes = useSelector(discountItemsTermTypes);

  const debouncedCurvesSearch = useDebounce(
    (text: string) => findCurves({ text, includeInactive: true }),
    500,
  );

  const templateCurveOnChange = (_event: any, data: DropdownProps) => {
    const curveSelected = allCurves?.find(curve => curve.id === data.value);
    const selectedTermDistribution = curveSelected?.curveTerms?.map(
      (eachCurveTerm: CurveTerm): CurveTermInputMod =>
        ({
          termId: eachCurveTerm.term.id,
          dataPoints: eachCurveTerm.dataPoints?.map(
            (eachDataPoint: CurveDataPoint): CurveDataPointInputMod => ({
              month: eachDataPoint.month,
              value: eachDataPoint.value,
            }),
          ),
        } as CurveTermInputMod),
    );
    const termIds = selectedTermDistribution?.map((eTerm): TermType => {
      const termSelector = termTypes.find(termType => termType.id === eTerm.termId);
      return termSelector || ({} as TermType);
    });
    setCurveTermInputMod(selectedTermDistribution ? cloneDeep(selectedTermDistribution) : []);
    setPrevCurveTermInputMod([]);
    setSelectedTerms(termIds || []);
    setSelectedCurve(curveSelected ? { ...curveSelected } : undefined);
  };

  const modifyCurveInput = (field: string, value: string) => {
    // Modifying field else if the object is new adding the required empty field
    if (curveInput) {
      setCurveInput({
        ...curveInput,
        [field]: value || '',
      });
    } else {
      setCurveInput({
        name: '',
        [field]: value || '',
      });
    }
  };

  const operators = useSelector(userOperatorsSelector);
  const operatorsLoading = useSelector(userOperatorsLoadingSelector);

  useEffect(() => {
    if (operator === undefined && operators.length > 0) {
      setOperator(operators[0]);
    }
  }, [operators, operator, setOperator]);

  useEffect(() => {
    if (operator?.id !== selectedCurve?.operator.id) {
      setRetainLinksSelector(false);
    }
  }, [setRetainLinksSelector, operator, selectedCurve]);

  return (
    <>
      <BaseOverrideWrapper
        className={cn({
          'no-margin': mode === DiscountCurvesModalMode.VIEW,
        })}
      >
        <BaseOverrideRowModified
          className={cn({ short: mode === DiscountCurvesModalMode.VIEW }, 'white-background')}
        >
          <li className="top-align-small">
            {mode === DiscountCurvesModalMode.VIEW ? (
              <>
                <ModalSubTitle className="no-indent  inline-subtitle">Name:</ModalSubTitle>
                <SpanWidth>{curveInput?.name ?? ''}</SpanWidth>
              </>
            ) : (
              <>
                <ModalSubTitle className="no-indent inline-subtitle edit-subtitle-name">
                  Name:
                </ModalSubTitle>
                <TextField
                  className="reservable-search"
                  placeholder="Name"
                  onChange={event => modifyCurveInput('name', event.target.value)}
                  value={curveInput?.name ?? ''}
                />
              </>
            )}
          </li>
          <li className="top-align">
            {mode === DiscountCurvesModalMode.VIEW ? (
              <>
                <ModalSubTitle className="no-indent  inline-subtitle">Description:</ModalSubTitle>
                <SpanWidth className="small">{curveInput?.description ?? ''}</SpanWidth>
              </>
            ) : (
              <>
                <ModalSubTitle className="no-indent  inline-subtitle edit-subtitle">
                  Description:
                </ModalSubTitle>
                <Textarea
                  placeholder="Enter a Description"
                  className="reason-textbox"
                  maxLength={150}
                  onChange={event => modifyCurveInput('description', event.target.value)}
                  value={curveInput?.description ?? ''}
                  id={uuidV4()}
                />
              </>
            )}
          </li>
        </BaseOverrideRowModified>
      </BaseOverrideWrapper>
      {mode === DiscountCurvesModalMode.CREATE ? (
        <BaseOverrideWrapper>
          <BaseOverrideRowModified className="white-background height-flex">
            <li>
              <ModalSubTitle className={'no-indent float-left'}>
                Select an operator id:
              </ModalSubTitle>
              <Dropdown
                placeholder="No operators available"
                selection
                search
                onChange={(_, data) =>
                  setOperator({
                    id: data.value,
                    name: data.text,
                  })
                }
                selectOnBlur={false}
                options={operators.map(o => ({
                  key: o.id,
                  text: o.name,
                  value: o.id,
                }))}
                disabled={operators.length < 2 || operatorsLoading}
                value={operator?.id}
              />
            </li>
            <li>
              <ModalSubTitle className="no-indent float-left">
                Populate from existing curve:
              </ModalSubTitle>
              <Dropdown
                placeholder="Search..."
                onChange={(_event, data) => templateCurveOnChange(_event, data)}
                onSearchChange={(event: any) =>
                  event?.target?.value ? debouncedCurvesSearch(event?.target?.value) : clearCurves()
                }
                clearable
                selection
                search
                selectOnBlur={false}
                options={allCurves.map(
                  curve =>
                    ({
                      key: curve.id,
                      text: curve.name,
                      value: curve.id,
                      label: calculateCurveLabel(curve),
                    } as DropdownItemProps),
                )}
                value={selectedCurve?.id}
              />
              <Checkbox
                label={
                  selectedCurve && selectedCurve?.appliedCount
                    ? `Keep ${selectedCurve?.appliedCount} reservable links`
                    : 'Keep reservable links'
                }
                checked={retainLinksSelector}
                onChange={() => {
                  setRetainLinksSelector(!retainLinksSelector);
                  if (!retainLinksSelector && !toast.isActive('linkSelectorToast')) {
                    toast.info(
                      'Selecting this will link all reservables associated with selected curve.',
                      {
                        position: toast.POSITION.TOP_CENTER,
                        toastId: 'linkSelectorToast',
                      },
                    );
                  }
                }}
                className="checkbox-margin"
                disabled={
                  !(
                    selectedCurve &&
                    selectedCurve?.appliedCount &&
                    selectedCurve.operator.id === operator?.id
                  )
                }
              />
            </li>
          </BaseOverrideRowModified>
        </BaseOverrideWrapper>
      ) : (
        <>
          <BaseOverrideWrapper>
            <BaseOverrideRowModified className={'white-background short'}>
              <li>
                <ModalSubTitle className={'no-indent float-left'}>Operator:</ModalSubTitle>
                <SpanWidth className={'small'}>{operator?.name ?? '-'}</SpanWidth>
              </li>
            </BaseOverrideRowModified>
          </BaseOverrideWrapper>
        </>
      )}
    </>
  );
}

export default DiscountCurvesOverrideModalHeader;
