import React, { ReactElement } from 'react';
import '@wework/dieter-ui/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import '@wework/ray2/dist/foundations.css';
import '@wework/ray2/dist/components.css';
import { toast } from 'react-toastify';
import { AppLayout, EnvLabel } from 'styles/app.styled';
import RootPage from './rootPage';
import config from '../config';

/* Adding configuration for displaying toast in the whole app.
 * NOTE: This can be overridden via the localized toast call */
toast.configure({
  position: toast.POSITION.BOTTOM_CENTER,
  autoClose: 4000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  theme: 'colored',
});

function App(): ReactElement {
  return (
    <AppLayout className="App" data-test={'app-container'}>
      {config.APP_ENV !== 'production' && (
        <EnvLabel className="env-label">{config.APP_ENV?.toUpperCase()}</EnvLabel>
      )}
      <RootPage />
    </AppLayout>
  );
}

export default App;
