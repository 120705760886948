// Action Constants
import { Action, handleActions } from 'redux-actions';
import { get, uniqBy } from 'lodash';
import { IRowNode } from 'ag-grid-community';
import { createRDXConstant } from '../../../../../utils/store/store.constants';
import initialState from '../../../../../utils/store/initialState';
import {
  defaultPageIngo,
  DiscountSyncAttempt,
  DiscountSyncAttemptFilter,
  DiscountSyncAttemptSubset,
} from '../../entities/discountSyncAttempt';
import { PageInfo } from '../../../../../generated/voyager/graphql';

export const RE_PUBLISH_DISCOUNT = createRDXConstant('RE_PUBLISH_DISCOUNT');

export const RE_PUBLISH_DISCOUNT_END = createRDXConstant('RE_PUBLISH_DISCOUNT_END');

export const FETCH_DISCOUNT_SYNC_ATTEMPTS_WITH_FILTER = createRDXConstant(
  'FETCH_DISCOUNT_SYNC_ATTEMPTS_WITH_FILTER',
);

export const FETCH_DISCOUNT_SYNC_ATTEMPTS_SUCCESS = createRDXConstant(
  'FETCH_DISCOUNT_SYNC_ATTEMPTS_SUCCESS',
);

export const DISCOUNT_SYNC_ATTEMPTS_SET_FILTER = createRDXConstant(
  'DISCOUNT_SYNC_ATTEMPTS_SET_FILTER',
);

export const DISCOUNT_SYNC_ATTEMPTS_SET_PAGE_INFO = createRDXConstant(
  'DISCOUNT_SYNC_ATTEMPTS_SET_PAGE_INFO',
);

export const SET_NODES_SELECTED_DISCOUNT_ADMIN = createRDXConstant(
  'SET_NODES_SELECTED_DISCOUNT_ADMIN',
);

// Reducer
export const discountRePublishingReducer = handleActions<DiscountSyncAttemptSubset, any>(
  {
    [FETCH_DISCOUNT_SYNC_ATTEMPTS_WITH_FILTER]: (state: DiscountSyncAttemptSubset) => ({
      ...state,
    }),
    [RE_PUBLISH_DISCOUNT_END]: (state: DiscountSyncAttemptSubset) => ({
      ...state,
      isLoading: false,
      selectedNodes: [],
    }),
    [RE_PUBLISH_DISCOUNT]: (state: DiscountSyncAttemptSubset) => ({
      ...state,
      isLoading: true,
    }),
    [FETCH_DISCOUNT_SYNC_ATTEMPTS_SUCCESS]: (
      state: DiscountSyncAttemptSubset,
      action: Action<{ data: DiscountSyncAttempt }>,
    ) => {
      let newDiscountSyncAttempt = get(action, 'data', []);
      newDiscountSyncAttempt = uniqBy(newDiscountSyncAttempt, 'id');

      return {
        ...state,
        allDiscountSyncAttempt: newDiscountSyncAttempt,
      };
    },
    [DISCOUNT_SYNC_ATTEMPTS_SET_FILTER]: (
      state: DiscountSyncAttemptSubset,
      action: Action<DiscountSyncAttemptFilter>,
    ) => ({
      ...state,
      discountSyncAttemptFilter: action.payload,
      pageInfo: defaultPageIngo,
    }),
    [DISCOUNT_SYNC_ATTEMPTS_SET_PAGE_INFO]: (
      state: DiscountSyncAttemptSubset,
      action: Action<PageInfo>,
    ) => ({
      ...state,
      pageInfo: action.payload,
    }),
    [SET_NODES_SELECTED_DISCOUNT_ADMIN]: (
      state: DiscountSyncAttemptSubset,
      action: Action<IRowNode[]>,
    ) => ({
      ...state,
      selectedNodes: action.payload,
    }),
  },
  initialState.discountRePublishing,
);
