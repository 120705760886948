import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Textarea } from '@wework/ray2';
import { GridApi, ICellRendererParams, IRowNode } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidV4 } from 'uuid';
import {
  CustomTableBodyCell,
  ItemDataValueStyled,
} from '../../../../../../discounts/components/discountsTable/discountAGGrid.styled';
import { StandardPricingEditWrap } from '../standardPricingTableAGGrid.styled';
import { standardPricingEditModeSelector } from '../../../../store/modules/standardPricingItems/standardPricingItems.selector';
import { CREATE_STANDARD_WORKING_PRICES } from '../../../../store/modules/standardPricingItems/standardPricingItems.ducks';
import { Unassigned } from '../../../../../../../utils/sharedTypes';
import { WorkingPriceInput } from '../../../../../../../generated/voyager/graphql';

function WorkingPriceReasonCellRenderer({ value, data, api }: ICellRendererParams): ReactElement {
  const editModeSelector = useSelector(standardPricingEditModeSelector);

  // State Variables.
  const [reason, setReason] = useState<string>();

  const dispatch = useDispatch();
  const createStandardWorkingPrices = useCallback(
    (payload: WorkingPriceInput[]) => dispatch({ type: CREATE_STANDARD_WORKING_PRICES, payload }),
    [dispatch],
  );

  const setValHelper = () => {
    const workingPriceReason = value ?? data?.workingPrice?.lastChangeReason;
    setReason(workingPriceReason);
  };
  useEffect(setValHelper, [value, data]);

  const onSubmit = () => {
    if (reason !== data?.workingPrice?.lastChangeReason) {
      const price = data?.workingPrice?.price ?? data?.currentPrice?.price;
      // 1) Creating a backend request for updating the data.
      createStandardWorkingPrices([
        {
          priceableItemId: data.id,
          price,
          lastChangeReason: reason,
          recommended: data?.recommendedPrice?.price ?? 0,
        },
      ]);
      // 2) Updating the grid with new data.
      const gridApi: GridApi = api;
      const rowNode: IRowNode | Unassigned = gridApi.getRowNode(data.id);
      if (rowNode) {
        const prevData = rowNode.data;
        const workingPrice = {
          ...prevData.workingPrice,
          price: data.workingPrice?.price,
          lastChangeReason: reason,
        };
        rowNode.setData({ ...prevData, workingPrice });
        gridApi.refreshCells({ rowNodes: [rowNode] });
      }
    }
  };

  function setReasonInput(event: any) {
    setReason(event.target.value);
  }

  const remainingChar = () => (reason === '-' ? '150' : `${150 - (reason?.length ?? 0)}`);

  return data ? (
    <CustomTableBodyCell className="single-line">
      {editModeSelector ? (
        <StandardPricingEditWrap className="fluid-width">
          <Textarea
            placeholder="Enter a reason"
            className="reason-textbox"
            maxLength={150}
            onChange={event => setReasonInput(event)}
            value={reason ?? ''}
            onBlur={onSubmit}
            id={uuidV4()}
          />
          <p className="remaining-chars-label">{remainingChar()}</p>
        </StandardPricingEditWrap>
      ) : (
        <ItemDataValueStyled>{reason ?? '-'}</ItemDataValueStyled>
      )}
    </CustomTableBodyCell>
  ) : (
    <> </>
  );
}

export default WorkingPriceReasonCellRenderer;
