import { get, isEmpty, merge } from 'lodash';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import AppConfig from 'config';
import { errorHandlerActive } from 'utils/errorHandling/helpers';
import { getAuthToken } from 'index';
import {
  LOCATION_RECONCILIATION,
  LOCATION_RECONCILIATION_ERROR,
  LOCATION_RECONCILIATION_SUCCESS,
} from './locationReconciliation.ducks';
import {
  locationReconciliationLocationIdsSelector,
  locationReconciliationLocationsSelector,
} from './locationReconciliation.selector';
import { SET_JOB_STATUS } from '../jobStatus/jobStatus.ducks';
import { Location } from '../../../../../generated/voyager/graphql';

const locationReconciliationRequest = (locationIds: string[]): Promise<AxiosResponse<any>[]> => {
  const locationReconciliationUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/location-backfill`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };

  return axios.post(locationReconciliationUrl, { locationIds }, { headers });
};

// Saga - to handle upload file.
export function* locationReconciliationWorker() {
  try {
    const locationIds: string[] = yield select(locationReconciliationLocationIdsSelector);
    const locations: Location[] = yield select(locationReconciliationLocationsSelector);
    const allLocationIds: string[] = merge(
      locations.map(location => location.id),
      locationIds,
    );

    if (!isEmpty(allLocationIds)) {
      const response: AxiosResponse | undefined = yield call(
        locationReconciliationRequest,
        allLocationIds,
      );
      if (response?.status === 200) {
        yield put({ type: LOCATION_RECONCILIATION_SUCCESS });
        toast.success('Location reconciliation successfully started!');
        yield put({ type: SET_JOB_STATUS, data: { job: 'LOCATION_RECONCILIATION' } });
      } else {
        toast.error('Location reconciliation error');
        yield put({ type: LOCATION_RECONCILIATION_ERROR, payload: response?.data?.message });
      }
    }
  } catch (e: any) {
    toast.error(`Location reconciliation error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export default function* locationReconciliationSaga(): any {
  yield all([takeLatest(LOCATION_RECONCILIATION, locationReconciliationWorker)]);
}
