import { Action, handleActions } from 'redux-actions';
import { get, sortBy } from 'lodash';
import { RegionsSubset } from '../../entities/regions';
import initialState from '../../../utils/store/initialState';
import { createRDXConstant } from '../../../utils/store/store.constants';

// Action Constants
// Action - fetch all regions.
export const FETCH_REGIONS = createRDXConstant('FETCH_REGIONS');
export const FETCH_REGIONS_SUCCESS = createRDXConstant('FETCH_REGIONS_SUCCESS');

// Reducer
export const regionsReducer = handleActions<RegionsSubset, any>(
  {
    // Reducer to fetch all regions.
    [FETCH_REGIONS]: (state: RegionsSubset) => ({
      ...state,
      fetchRegionsState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_REGIONS_SUCCESS]: (state: RegionsSubset, action: Action<any>) => {
      // TODO: Check if this SortBy is needed (Regions)
      const allRegions = sortBy(get(action, 'payload.regions', []), ['name']);
      return {
        ...state,
        fetchRegionsState: {
          loading: false,
          loaded: true,
          error: null,
        },
        allRegions,
      };
    },
  },
  initialState.regions,
);
