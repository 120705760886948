import { BatchMode } from '../../components/constants';
import { StandardPricingPagedItem } from '../entities/standardPricingItems';

const getWorkingPriceInput = (
  reason: string,
  numberInput: number,
  valueTypePercentage: boolean,
  exactValueMode: BatchMode,
  eachPriceableItem: StandardPricingPagedItem,
): any => {
  // 1) Getting the current Price i.e is displayed.
  const currentPrice = eachPriceableItem.currentPrice?.price ?? 0;
  let futureWorkingPrice: number;
  // 3) Calculating the future working price.
  // The radio button "Set the same price" is selected by default which means exactValueMode is on,
  // a exact price will be implemented as the working price.
  futureWorkingPrice = numberInput;
  // If exactValueMode is off
  if (exactValueMode === BatchMode.ADJUST_PRICE) {
    // If valueTypePercentage is false which means the number option is selected,
    // so adding/subtracting the input.
    if (!valueTypePercentage) {
      futureWorkingPrice = currentPrice + numberInput;
    } else {
      // If exactValueMode is off and valueTypePercentage is true,
      // percentage is selected so calculating percentage increase or decrease based on sign.
      futureWorkingPrice = currentPrice + currentPrice * (numberInput / 100);
    }
  } else if (exactValueMode === BatchMode.SUGGESTED_PRICE) {
    if (eachPriceableItem.recommendedPrice) {
      futureWorkingPrice = eachPriceableItem.recommendedPrice.price;
    } else {
      return null;
    }
  }

  // 4) Saving the current working price.
  return {
    priceableItemId: eachPriceableItem.id,
    price: futureWorkingPrice,
    recommended: eachPriceableItem.recommendedPrice?.price,
    lastChangeReason: reason ?? '',
  };
};

export default getWorkingPriceInput;
