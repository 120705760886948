import { List } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import isUUID from 'validator/lib/isUUID';
import { ListItemError, ListItemSuccess } from './uuidValidation.styled';

interface UuidValidationProps {
  ids: string[];
  handleValidation: (isValid: boolean) => void;
}

const UuidValidation = ({ ids, handleValidation }: UuidValidationProps) => {
  const errorMessages: JSX.Element[] = [];
  const filteredIds = ids.filter(s => !isEmpty(s));
  const duplicatedIds = new Set<string>();
  const invalidIds = new Set<string>();
  filteredIds.forEach(id => {
    if (!isUUID(id) && !invalidIds.has(id)) {
      invalidIds.add(id);
      errorMessages.push(
        <ListItemError as="li" key={`${id}-invalid`}>
          <i>{id}</i>
          {' invalid UUID format.'}
        </ListItemError>,
      );
    }
    if (filteredIds.indexOf(id) !== filteredIds.lastIndexOf(id) && !duplicatedIds.has(id)) {
      duplicatedIds.add(id);
      errorMessages.push(
        <ListItemError as="li" key={`${id}-duplicated`}>
          <i>{id}</i>
          {' duplicated.'}
        </ListItemError>,
      );
    }
  });
  const isValid = isEmpty(errorMessages);
  if (isValid && !isEmpty(filteredIds)) {
    errorMessages.push(
      <ListItemSuccess as="li" key="valid">
        Valid.
      </ListItemSuccess>,
    );
  }
  useEffect(() => {
    handleValidation(isValid);
  }, [ids, isValid, handleValidation]);

  return <List as="ul">{errorMessages}</List>;
};

export default UuidValidation;
