import React, { ReactElement } from 'react';
import { Button } from '@wework/ray2';
import { Header } from 'semantic-ui-react';
import SplashPage from './splash/splashPage';
import LoginInfo from '../styles/sharedLogin.styled';
import { logoutHelper } from '../index';

function AccessDenied(): ReactElement {
  return (
    <SplashPage>
      <>
        <Header>Access Denied</Header>
        <LoginInfo>
          We’re sorry, you do not have permission to access Voyager. Please sign in using a
          different account.
        </LoginInfo>
        <Button onClick={() => logoutHelper()} theme={'fill'} size={'medium'}>
          Return to Home
        </Button>
      </>
    </SplashPage>
  );
}

export default AccessDenied;
