import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Button, IconName } from '@wework/ray2';
import { Popup } from '@wework/dieter-ui';
import { Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { GENERATE_URL } from '../../sharedStore/modules/generateLink/generateLink.ducks';
import {
  generatedUrlSelector,
  generateUrlLoadingSelector,
} from '../../sharedStore/modules/generateLink/generateLink.selector';
import { PriceableItemFilter } from '../../sharedStore/entities/priceableItemFilters';
import { UserActionItemUrlProps } from '../../sharedStore/entities/userActionItems';
import { getAllSelectedLocations } from '../../sharedStore/modules/locations/locations.selector';
import { trackAnalytics } from '../../utils/analytics/helpers';
import { Location } from '../../generated/voyager/graphql';

interface GenerateLinkProps {
  disabled: boolean;
  routeName: string;
  filters: PriceableItemFilter[];
}

function GenerateLink(props: GenerateLinkProps): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  const generateUrl = useCallback(
    (payload: UserActionItemUrlProps) => dispatch({ type: GENERATE_URL, payload }),
    [dispatch],
  );

  const generatedUrl = useSelector(generatedUrlSelector);
  const generateUrlLoading = useSelector(generateUrlLoadingSelector);

  const generatedUrlFull = `${window.location.origin}${props.routeName}/${generatedUrl}`;

  const locationIds: Location[] = useSelector(getAllSelectedLocations);

  function trackGenerateButtonAnalytics(locationIdsArray: string[]) {
    trackAnalytics('Generate URL Button', {
      workflow: 'Generate URL Button Click',
      object_type: 'button',
      object_name: 'Generate URL',
      object_value: locationIdsArray,
    });
  }

  const generateLink = () => {
    if (!generatedUrl) {
      const locationIdsArray = sortBy(locationIds, 'name').map(location => location.id);

      const userActionPayload: UserActionItemUrlProps = {
        locationsIds: locationIdsArray,
        filterPathData: { filter: props.filters },
      };
      generateUrl(userActionPayload);
      trackGenerateButtonAnalytics(locationIdsArray);
    }
  };

  function trackGenerateLinkCopyButtonAnalytics() {
    trackAnalytics('Generate URL Copy Button', {
      workflow: 'Generate URL Copy Button Click',
      object_type: 'button',
      object_name: 'Generate URL Copy',
      object_value: generatedUrlFull,
    });
  }

  return (
    <Popup
      on={'click'}
      className={'generate-link-popup'}
      trigger={
        <Button
          theme={'fill'}
          size={'small'}
          disabled={props.disabled || generateUrlLoading}
          onClick={generateLink}
        >
          Generate Link
        </Button>
      }
      closeOnDocumentClick
      closeOnEscape
      size={'large'}
    >
      <Popup.Content>
        <Input
          type="text"
          value={generatedUrlFull}
          loading={generateUrlLoading}
          contentEditable={false}
          action={
            <Button
              loading={generateUrlLoading}
              theme={'fill'}
              icon={IconName.DOCUMENT}
              size={'medium'}
              onClick={() => {
                trackGenerateLinkCopyButtonAnalytics();
                navigator.clipboard
                  .writeText(generatedUrlFull)
                  .then(_ => toast.success('URL copied to clipboard!', { position: 'top-center' }))
                  .catch(e => toast.error(`Cannot copy: ${e}`));
              }}
            />
          }
        />
      </Popup.Content>
    </Popup>
  );
}

export default GenerateLink;
