import styled from 'styled-components';
import Open from '../../assets/open.svg';
import { blue, darkBlue, darkGray, lighterGray } from '../../styles/colors';
import { FlexHorizontalHelper, FlexVerticalHelper, ListReset } from '../../styles/app.styled';

export const PanelBodyPriceable = styled.div`
  overflow-y: auto;
  height: calc(100% - 186px);
  // Details Panel
  &.details-body {
    overflow-y: hidden;
    height: 100%;
  }
  .ui.pointing.secondary.menu {
    position: relative;
    margin: 0;
    .item {
      flex: 1 1 25%;
    }
    .item:nth-child(3) {
      flex: 1 1 50%;
    }
    &::before {
      display: block;
      position: absolute;
      bottom: -11px;
      left: 0;
      right: 18px;
      z-index: 2;
      content: '';
      height: 10px;
      background: linear-gradient(-180deg, white 0%, rgba(255, 255, 255, 0.5) 100%);
    }
  }
  .ui.bottom.attached.segment.tab {
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 200px);
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    overflow-y: auto;
  }
`;

export const PanelHeader = styled.header`
  padding: 20px 20px 20px;
`;
export const PanelHeadLabel = styled.span`
  display: block;
  height: 16px;
  margin: 0 0 12px;
  line-height: 16px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  color: ${darkGray};
`;

export const PanelBody = styled.div`
  overflow-y: auto;
  height: calc(100% - 186px);
  // Details Panel
  &.details-body {
    height: calc(100% - 156px);
    &.location {
      overflow-y: auto;
    }
  }
`;
export const PanelSectionList = styled.ul`
  ${ListReset};
  ${FlexVerticalHelper};
  width: 299px;
  > li {
    margin-top: 18px;
    &:first-of-type {
      padding-top: 0;
      margin-top: 0;
    }
    &.closed {
      padding-bottom: 26px;
    }
  }
`;
export const PanelSectionItem = styled.li`
  flex: 0 0 auto;
  width: 100%;
  padding: 12px 0 12px 20px;
  border-bottom: 1px solid ${lighterGray};
  &:last-of-type {
    border-bottom: none;
  }
  &.open {
    padding-bottom: 28px;
    > .section-header .toggle-accordion-icon::before {
      transform: rotate(180deg);
    }
    > .section-body {
      height: auto;
    }
  }
  &.closed {
    > .section-body {
      height: 0;
    }
  }
`;
export const SectionHeader = styled.div`
  > button {
    ${FlexHorizontalHelper};
    justify-content: space-between;
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    outline: none;
    text-align: left;
    span {
      display: block;
      flex: 1 1 auto;
      margin: 0;
      line-height: 28px;
      font-size: 16px;
      font-weight: 700;
      color: ${darkBlue};
    }
    i {
      display: block;
      flex: 0 0 auto;
      width: 16px;
      height: 16px;
      padding: 0;
      background: none;
      border: none;
      margin: 6px 0 0 0;
      cursor: pointer;
      outline: none;
      &::before {
        display: block;
        width: 16px;
        height: 17px;
        position: relative;
        content: '';
        background-color: ${darkBlue};
        -webkit-mask: url(${Open});
        mask: url(${Open});
      }
    }
  }
`;
export const SectionBody = styled.div`
  overflow: hidden;
`;
export const ListItemValue = styled.ol`
  ${ListReset};
  ${FlexVerticalHelper};
  padding-top: 10px;
  li {
    flex: 1 1 auto;
    ${FlexHorizontalHelper};
    justify-content: space-between;
    margin: 6px 0 0 0;
    span {
      display: block;
      width: auto;
      flex: 1 1 auto;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: ${darkBlue};
      &:last-of-type {
        width: 50px;
        flex: 0 0 50px;
        font-weight: 500;
        text-align: right;
      }
    }
  }
`;
export const DetailDefinition = styled.p`
  display: block;
  padding: 8px 0 24px;
  margin: 0;
  font-size: 14px;
  line-height: 22px;
  color: ${darkGray};
`;
export const InfoBlock = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${lighterGray};
  &:first-of-type {
    padding: 0 0 26px;
    border-top: none;
    > h4 {
      line-height: 19px;
      margin-bottom: 20px;
    }
  }
  /* RECHARTS OVERIDES */
  .recharts-wrapper.line-chart {
    .recharts-surface .recharts-cartesian-grid {
      .recharts-cartesian-grid-vertical {
        line {
          stroke: transparent;
        }
      }
    }
    .recharts-cartesian-axis-line {
      stroke: transparent;
    }
    .recharts-cartesian-axis-tick {
      text {
        fill: ${darkGray};
        font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
      }
      line {
        stroke: transparent;
      }
    }
  }
`;
export const InfoBlockTitle = styled.h4`
  padding: 0;
  margin: 0 0 14px;
  line-height: 28px;
  font-size: 16px;
  font-weight: 400;
  color: ${darkBlue};
`;
export const DataRow = styled.ol`
  ${ListReset};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  &.blocks3 > li {
    flex: 1 1 33%;
  }
  &.blocks4 {
    padding-bottom: 20px;
    > li {
      flex: 1 1 25%;
    }
  }
  &.blocks5 > li {
    flex: 1 1 20%;
  }
  &.x-axis {
    padding-top: 10px;
    margin: 0 0 0 34px;
    > li {
      flex: 1 1 calc(20% - 14px);
      margin-left: 14px;
      &:first-of-type {
        margin-left: 0;
      }
      > span {
        margin: 0 0 7px;
        font-size: 10px;
        line-height: 12px;
      }
      > strong {
        text-align: center;
      }
    }
  }
`;
export const DataBlock = styled.li`
  > span {
    display: block;
    margin: 0 0 8px;
    font-size: 12px;
    line-height: 14px;
    color: ${darkGray};
  }
  > strong {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: ${darkBlue};
  }
  &.current {
    > span,
    > strong {
      color: ${blue};
    }
  }
`;
export const DataList = styled.ol`
  flex: 0 0 260px;
  ${ListReset};
  ${FlexVerticalHelper};
  li {
    flex: 1 1 auto;
    ${FlexHorizontalHelper};
    margin: 12px 0 0;
    line-height: 20px;
    font-size: 14px;
    font-weight: 300;
    color: ${darkGray};
    &:first-of-type {
      margin: 0;
    }
    strong {
      flex: 0 0 54px;
      font-size: 16px;
      font-weight: 700;
      color: ${darkBlue};
    }
    span {
      flex: 1 1 auto;
      &:first-of-type {
        flex: 0 0 24px;
        font-size: 16px;
      }
    }
  }
`;
