import styled from 'styled-components';
import { FlexHorizontalWrappedHelper, ListReset } from '../../styles/app.styled';

export const ApplicationsDashboardList = styled.ul`
  ${ListReset};
  ${FlexHorizontalWrappedHelper};
  margin: 0 40px;
`;
export const ApplicationCardItem = styled.li`
  margin: 0 24px 24px 0;
`;
