import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@wework/ray2';
import { isEmpty } from 'lodash';
import {
  inventoryReconciliationByLocationIsLoadingSelector,
  inventoryReconciliationByLocationIsLocationIdsValidSelector,
  inventoryReconciliationByLocationLocationIdsSelector,
  inventoryReconciliationByLocationLocationsSelector,
} from 'app/adminPanel/store/modules/inventoryReconciliationByLocation/inventoryReconciliationByLocation.selector';
import { INVENTORY_RECONCILIATION_BY_LOCATION } from 'app/adminPanel/store/modules/inventoryReconciliationByLocation/inventoryReconciliationByLocation.ducks';
import { ActionsBox, BottomPanelWrapper } from 'styles/sharedBottomPanel.styled';
import runningJobSelector from '../../store/modules/jobStatus/jobStaus.selector';
import AnotherJobIsRunningTooltip from '../jobStatus/anotherJobIsRunningTooltip';

function BottomPanelInventoryReconciliationByLocation(): ReactElement {
  const isLoading = useSelector(inventoryReconciliationByLocationIsLoadingSelector);
  const selectedLocations = useSelector(inventoryReconciliationByLocationLocationsSelector);
  const selectedLocationIds = useSelector(inventoryReconciliationByLocationLocationIdsSelector);
  const job = useSelector(runningJobSelector);
  const isLocationIdsValid = useSelector(
    inventoryReconciliationByLocationIsLocationIdsValidSelector,
  );
  const isDisabled =
    (isEmpty(selectedLocations) && isEmpty(selectedLocationIds)) ||
    !isLocationIdsValid ||
    job != null;

  const dispatch = useDispatch();
  const inventoryReconciliationHandler = useCallback(
    () => dispatch({ type: INVENTORY_RECONCILIATION_BY_LOCATION }),
    [dispatch],
  );

  return (
    <BottomPanelWrapper className="publish-panel">
      <ActionsBox />
      <ActionsBox className="action-box-right">
        <AnotherJobIsRunningTooltip job={job} />
        <Button
          theme={'fill'}
          size={'medium'}
          loading={isLoading}
          disabled={isDisabled}
          onClick={inventoryReconciliationHandler}
        >
          Reconcile
        </Button>
      </ActionsBox>
    </BottomPanelWrapper>
  );
}

export default BottomPanelInventoryReconciliationByLocation;
