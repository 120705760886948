import { ICellRendererParams } from 'ag-grid-community';
import * as React from 'react';
import { ReactElement } from 'react';
import { isNil } from 'lodash';
import cn from 'classnames';
import { roundToInteger } from '../../../../pricing/standardPricing/components/helpers';
import { EditableCellStyled } from '../breakevenTableAGGrid.styled';
import { getAdjustedBy, getAdjustedByClassName, getDifference } from '../breakevenTable.helper';
import { intlCurrencyFormatter } from '../../../../../utils/helpers';

function BreakevenPriceCellRenderer(props: ICellRendererParams): ReactElement {
  const currencyIsoCode = props.data?.location.currencyIsoCode;
  const isToBePublished = props.data?.type === 'toBePublished';
  const breakEvenPrice = props.value?.value;
  const currentBreakEvenPrice =
    props.value?.publishedValue && roundToInteger(props.value?.publishedValue);

  const difference = getDifference(breakEvenPrice, currentBreakEvenPrice);
  const adjustedBy = getAdjustedBy(currencyIsoCode, difference, currentBreakEvenPrice);
  const adjustedByClassName = getAdjustedByClassName(difference);
  const overrideFlag = !!difference;

  const renderDiff = () =>
    isNil(breakEvenPrice) || isNaN(breakEvenPrice) ? (
      <span className="info">No Change</span>
    ) : (
      <>
        <span className={cn({ override: overrideFlag })}>
          {breakEvenPrice ? intlCurrencyFormatter(currencyIsoCode, breakEvenPrice) : '-'}
        </span>
        <span className={adjustedByClassName}>{adjustedBy ?? '-'}</span>
      </>
    );

  return isToBePublished ? (
    <EditableCellStyled>{renderDiff()}</EditableCellStyled>
  ) : (
    <span>
      {props.value ? intlCurrencyFormatter(currencyIsoCode, roundToInteger(props.value)) : ''}
    </span>
  );
}

export default BreakevenPriceCellRenderer;
