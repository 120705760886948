import { gql } from '@apollo/client';

// GraphQL Queries
// Query to fetch all territories by selected region.
const FETCH_TERRITORIES_BY_REGION_QUERY = gql`
  query TerritoriesByRegion($regionId: ID!) {
    territoriesByRegion(regionId: $regionId) {
      id
      name
    }
  }
`;

export default FETCH_TERRITORIES_BY_REGION_QUERY;
