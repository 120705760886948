import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@wework/ray2';
import { isEmpty } from 'lodash';
import { LOCATION_RECONCILIATION } from 'app/adminPanel/store/modules/locationReconciliation/locationReconciliation.ducks';
import {
  locationReconciliationIsLoadingSelector,
  locationReconciliationIsLocationIdsValidSelector,
  locationReconciliationLocationIdsSelector,
  locationReconciliationLocationsSelector,
} from 'app/adminPanel/store/modules/locationReconciliation/locationReconciliation.selector';
import { ActionsBox, BottomPanelWrapper } from '../../../../styles/sharedBottomPanel.styled';
import runningJobSelector from '../../store/modules/jobStatus/jobStaus.selector';
import AnotherJobIsRunningTooltip from '../jobStatus/anotherJobIsRunningTooltip';

function BottomPanelLocationReconciliation(): ReactElement {
  const isLoading = useSelector(locationReconciliationIsLoadingSelector);
  const selectedLocationIds = useSelector(locationReconciliationLocationIdsSelector);
  const selectedLocations = useSelector(locationReconciliationLocationsSelector);
  const isLocationIdsValid = useSelector(locationReconciliationIsLocationIdsValidSelector);
  const job = useSelector(runningJobSelector);
  const isDisabled =
    (isEmpty(selectedLocationIds) && isEmpty(selectedLocations)) ||
    !isLocationIdsValid ||
    job != null;

  const dispatch = useDispatch();
  const locationReconciliationHandler = useCallback(
    () => dispatch({ type: LOCATION_RECONCILIATION }),
    [dispatch],
  );

  return (
    <BottomPanelWrapper className="publish-panel">
      <ActionsBox />
      <ActionsBox className="action-box-right">
        <AnotherJobIsRunningTooltip job={job} />
        <Button
          theme={'fill'}
          size={'medium'}
          loading={isLoading}
          disabled={isDisabled}
          onClick={locationReconciliationHandler}
        >
          Reconcile
        </Button>
      </ActionsBox>
    </BottomPanelWrapper>
  );
}

export default BottomPanelLocationReconciliation;
