import { StoreInterface } from '../../../../../utils/store/store.interface';
import { DiscountFiltersSubset } from '../../entities/filters';
import { PriceableItemFilter } from '../../../../../sharedStore/entities/priceableItemFilters';

const discountFilters = (state: StoreInterface) => state?.discountFilters;

export const filtersSelector = (state: StoreInterface): PriceableItemFilter[] =>
  discountFilters(state)?.filters;

export const filtersSelectorSubset = (state: DiscountFiltersSubset): PriceableItemFilter[] =>
  state?.filters;
