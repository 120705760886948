import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { SkeletonParagraph, Tag } from '@wework/dieter-ui';
import { Label, Tab } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import OverviewTab from './tabs/overviewTab';
import OccupancyTab from './tabs/occupancyTab';
import FinancialHealthTab from './tabs/financialHealthTab';
import {
  LocationItemStyled,
  LocationsList,
  LocationsTabsWrapper,
  MultipleLocationWidgetWrapper,
} from './multipleLocationWidget.styled';
import {
  currentLocationsItemLoadState,
  currentMultipleLocationDetailsSelector,
  getAllSelectedLocations,
} from '../../sharedStore/modules/locations/locations.selector';
import { FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID } from '../../sharedStore/modules/locations/locations.ducks';
import { trackAnalytics } from '../../utils/analytics/helpers';

function MultipleLocationWidget(): ReactElement {
  // LOCAL VARIABLES
  const [locationsExpanded, setLocationsExpanded] = useState<boolean>(false);
  // STATE PROPS
  const dispatch = useDispatch();
  const locationsDetails = useSelector(currentMultipleLocationDetailsSelector);
  const locationsLoaded = useSelector(currentLocationsItemLoadState);
  const selectedLocations = useSelector(getAllSelectedLocations);

  // get location details
  const getLocationDetailsById = useCallback(
    (payload: string[]) => dispatch({ type: FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID, payload }),
    [dispatch],
  );

  /**
   * This method is used for fetching data from backend.
   */
  const fetchLocationData = () => {
    if (!isEmpty(selectedLocations)) {
      getLocationDetailsById(selectedLocations.map(a => a.id));
    }
  };
  useEffect(fetchLocationData, [selectedLocations, getLocationDetailsById]);

  const trackTabClicks = (menu: String) => {
    trackAnalytics('Location Widget Tab Clicks', {
      workflow: 'Click Location Widget',
      object_type: 'Tab',
      object_name: 'Location Widget',
      tabName: menu,
    });
  };

  /**
   * Track the locations card viewed
   */
  const assetWidgetTracking = () => {
    trackAnalytics('Location Cart Viewed', {
      workflow: 'Locations Card',
      object_type: 'card',
      object_name: locationsDetails,
      location_uuid: locationsDetails,
    });
  };
  useEffect(assetWidgetTracking, [locationsLoaded]);

  /**
   * Tab Panes Builder
   */
  const locationsPanes = [
    {
      menuItem: 'Overview',
      pane: {
        key: 'tab1',
        content: <OverviewTab locationsDetails={locationsDetails} />,
      },
    },
    {
      menuItem: 'Occupancy',
      pane: {
        key: 'tab2',
        content: <OccupancyTab locationsDetails={locationsDetails} />,
      },
    },
    {
      menuItem: 'Financial Health',
      pane: {
        key: 'tab3',
        content: <FinancialHealthTab locationsDetails={locationsDetails} />,
      },
    },
  ];

  return (
    <MultipleLocationWidgetWrapper>
      {locationsLoaded ? (
        <>
          <Label color="blue" size="tiny" corner="left" content={locationsDetails?.length} />
          <button
            type="button"
            className={cn(
              {
                active: locationsExpanded,
              },
              'toggle-locations-icon',
            )}
            onClick={() => setLocationsExpanded(!locationsExpanded)}
          />
          <LocationsTabsWrapper className={cn({ open: locationsExpanded })}>
            <LocationsList>
              {locationsDetails &&
                locationsDetails.map(location => (
                  <LocationItemStyled key={`location-widget-list-${location.id}`}>
                    <span className="name">{location.name ?? '-'}</span>
                    <span>
                      <span className="market">
                        {location.market.name}, {location.market.territory.name},{' '}
                        {location.market.territory.region.name}
                      </span>
                      <Tag color="green">Open</Tag>
                    </span>
                  </LocationItemStyled>
                ))}
            </LocationsList>
            <Tab
              className="locations-tabs"
              menu={{ secondary: true, pointing: true }}
              panes={locationsPanes}
              renderActiveOnly={false}
              onTabChange={(_e: any, data: any) =>
                trackTabClicks(locationsPanes[data?.activeIndex]?.menuItem)
              }
            />
          </LocationsTabsWrapper>
        </>
      ) : (
        <>
          <LocationsTabsWrapper className="loading">
            <SkeletonParagraph size="large" rows={2} />
          </LocationsTabsWrapper>
        </>
      )}
    </MultipleLocationWidgetWrapper>
  );
}

export default MultipleLocationWidget;
