import { Action, handleActions } from 'redux-actions';
import { createRDXConstant } from '../../../utils/store/store.constants';
import initialState from '../../../utils/store/initialState';
import { GenerateLinkSubset } from '../../../app/adminPanel/store/entities/generateLink';

export const GENERATE_URL = createRDXConstant('GENERATE_URL');
export const GENERATE_URL_FINISHED = createRDXConstant('GENERATE_URL_FINISHED');

export const DECODE_URL = createRDXConstant('DECODE_URL');
export const DECODE_URL_FINISHED = createRDXConstant('DECODE_URL_FINISHED');

export const CLEAR_GENERATED_URL = createRDXConstant('CLEAR_GENERATED_URL');

// Reducer
export const generateLinkReducer = handleActions<GenerateLinkSubset, any>(
  {
    [GENERATE_URL]: (state: GenerateLinkSubset) => ({
      ...state,
      generateUrlLoading: true,
    }),
    [GENERATE_URL_FINISHED]: (state: GenerateLinkSubset, action: Action<string | null>) => ({
      ...state,
      generateUrlLoading: false,
      generatedUrl: action.payload,
    }),

    [DECODE_URL]: (state: GenerateLinkSubset) => ({
      ...state,
      decodeUrlLoading: true,
    }),
    [DECODE_URL_FINISHED]: (state: GenerateLinkSubset) => ({
      ...state,
      decodeUrlLoading: false,
    }),

    [CLEAR_GENERATED_URL]: (state: GenerateLinkSubset) => ({
      ...state,
      generatedUrl: null,
    }),
  },
  initialState.generateLink,
);
