import * as React from 'react';
import { ErrorInfo } from 'react';
import { Button } from '@wework/ray2';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Unassigned } from '../sharedTypes';
import {
  ErrorCatImage,
  ErrorPageButtonFlex,
  ErrorPageContainer,
  ErrorSubTitle,
  ErrorTitle,
} from '../../commonPages/error/error.style';
import errorCat from '../../assets/error_cat.gif';
import errorDog from '../../assets/error_dog.gif';
import ROUTES from '../routes/routes';
import { randomBoolean } from '../helpers';

interface State {
  error: Error | Unassigned;
  errorInfo: ErrorInfo | Unassigned;
}

interface Props {
  navigatePage: (route: string) => void;
}

class ErrorBoundaryBase extends React.Component<any, State> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });
    // logging error messages to rollbar.
    window.Rollbar.error(error);
    window.Rollbar.log(errorInfo);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <ErrorPageContainer>
          <ErrorTitle>Oops!</ErrorTitle>
          <ErrorSubTitle>Looks like you&#39;re stuck.</ErrorSubTitle>
          <ErrorCatImage src={randomBoolean() ? errorCat : errorDog} />
          <ErrorPageButtonFlex>
            <Button
              theme={'fill'}
              size={'medium'}
              onClick={() => this.props.navigatePage(ROUTES.HOME)}
            >
              Click to go home
            </Button>
          </ErrorPageButtonFlex>
        </ErrorPageContainer>
      );
    }
    // Normally, just render children i.e the other components without any errors.
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): Props => ({
  navigatePage: (route: string) => dispatch(push(route)),
});

export default connect(null, mapDispatchToProps)(ErrorBoundaryBase);
