import { Action, handleActions } from 'redux-actions';
import { get, uniqBy } from 'lodash';
import initialState from '../../../../../../utils/store/initialState';
import { standardPricingFiltersSelectorSubset } from './standardPricingFilters.selector';
import { StandardPricingFiltersSubset } from '../../entities/standardPricingFilters';
import { createRDXConstant } from '../../../../../../utils/store/store.constants';
import { PriceableItemFilter } from '../../../../../../sharedStore/entities/priceableItemFilters';
import { modifyOldFilterHelper } from '../../../../../../utils/helpers';

// Action Constants
export const MODIFY_STANDARD_PRICING_FILTER_PARAMS = createRDXConstant(
  'MODIFY_STANDARD_PRICING_FILTER_PARAMS',
);
export const CLEAR_STANDARD_PRICING_FILTER_PARAMS = createRDXConstant(
  'CLEAR_STANDARD_PRICING_FILTER_PARAMS',
);

// Reducer
export const standardPricingFiltersReducer = handleActions<StandardPricingFiltersSubset, any>(
  {
    // Reducer to combine filters with existing filters.
    [MODIFY_STANDARD_PRICING_FILTER_PARAMS]: (
      state: StandardPricingFiltersSubset,
      action: Action<{
        filterParams: PriceableItemFilter[];
        skuFilterSet?: boolean;
        yesNoFilterSet?: boolean;
        minMaxFilterSet?: boolean;
      }>,
    ) => {
      // STEP 1: Accessing new Filter.
      const newFilter = get(action, 'filterParams', Array<PriceableItemFilter>());

      // STEP 2: Modifying old filter - This step will provide ability to remove filters.
      const oldFilterModified = modifyOldFilterHelper(
        [...standardPricingFiltersSelectorSubset(state)],
        get(action, 'skuFilterSet', false),
        get(action, 'yesNoFilterSet', false),
        get(action, 'minMaxFilterSet', false),
        get(action, 'discountExpiryFilterSet', false),
        get(action, 'userActionFiltersPricingSet', false),
      );

      // STEP 3: Merge the filter arrays based on the filter name,
      // We removed from old filters in previous step if needed,
      // and we will add new data in the filters in this step.
      const filters: PriceableItemFilter[] = uniqBy(
        [...newFilter, ...oldFilterModified],
        'filterName',
      );

      // STEP 4: Modify/ Save the filter.
      return {
        ...state,
        filters,
      };
    },
    // Reducer to clear all the filters.
    [CLEAR_STANDARD_PRICING_FILTER_PARAMS]: (state: StandardPricingFiltersSubset) => ({
      ...state,
      filters: [],
    }),
  },
  initialState.discountFilters,
);
