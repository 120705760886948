import styled from 'styled-components';
import { darkGray, pureBlack } from '../../styles/colors';
import { ListReset } from '../../styles/app.styled';

export const InfoTooltipContent = styled.div`
  ol {
    ${ListReset};
    li {
      font-size: 12px;
      line-height: 20px;
      color: ${darkGray};
      b {
        font-weight: 400;
        color: ${pureBlack};
      }
    }
  }
  p {
    margin: 0;
    font-size: 12px;
    line-height: 16px;
    color: ${darkGray};
  }
`;
export const InfoTooltipTitle = styled.h4`
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 8px;
`;
