import React, { useCallback, useEffect, useState } from 'react';
import { TextAreaProps } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import UuidValidation from 'sharedComponents/uuidValidation/uuidValidation';
import { TextAreaWrapper } from './uuidTextArea.styled';

interface UuidTextAreaInterface {
  disabled?: boolean;
  clear?: boolean;
  handleChange: (ids: string[]) => void;
  handleValidation: (isValid: boolean) => void;
}

const splitIds = (value: string | number | undefined): string[] =>
  value
    ?.toString()
    .split('\n')
    .map(s => s.trim()) || [];

const UuidTextArea = ({
  disabled,
  clear,
  handleChange,
  handleValidation,
}: UuidTextAreaInterface) => {
  const [enteredIds, setEnteredIds] = useState<string[]>([]);
  const onChange = useCallback(
    (_event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
      const ids: string[] = splitIds(data.value);
      setEnteredIds(ids);
      handleChange(ids.filter(id => !isEmpty(id)));
    },
    [setEnteredIds, handleChange],
  );

  useEffect(() => {
    if (clear) {
      setEnteredIds([]);
    }
  }, [clear]);

  return (
    <>
      <TextAreaWrapper onChange={onChange} value={enteredIds.join('\n')} disabled={disabled} />
      <UuidValidation ids={enteredIds} handleValidation={handleValidation} />
    </>
  );
};

export default UuidTextArea;
