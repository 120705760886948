import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  PanelFilterItem,
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
} from '../../styles/app.styled';
import { PriceableItemFilter } from '../../sharedStore/entities/priceableItemFilters';
import { FilterProps } from '../../utils/sharedTypes';

/**
 * User Action Filter component. In this case SKU filter.
 *
 * @param props.saveFilters save filter flag.
 * @param props.clearFilters clear filter flag.
 * @param props.resetSaveFilters Need this to have the toggle working for consecutive times.
 * @param props.resetClearFilters Need this to have the toggle working for consecutive times.
 * @constructor
 */
function UserActionFiltersPricing({
  saveFilters,
  clearFilters,
  resetSaveFilters,
  resetClearFilters,
  modifyFiltersAction,
  filtersSelector,
}: FilterProps): ReactElement {
  const filters: PriceableItemFilter[] = useSelector(filtersSelector);

  // STATE VARIABLES:
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const [enableEmptyPrice, setEnableEmptyPrice] = useState<boolean>(false);

  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  // DISPATCH PROPS:
  const dispatch = useDispatch();
  const addFilterParams = useCallback(
    (filterParams: PriceableItemFilter[], userActionFiltersPricingSet: boolean) =>
      dispatch({ type: modifyFiltersAction, filterParams, userActionFiltersPricingSet }),
    [dispatch, modifyFiltersAction],
  );

  const applyValuesCallback = () => {
    if (!saveFilters) {
      return;
    }
    // 2) Constructing filter params.
    const filterParams: PriceableItemFilter[] = [];
    if (enableEmptyPrice) {
      filterParams.push({
        filterName: 'emptyPriceSelected',
        isSelected: enableEmptyPrice,
      });
    }
    // 3) Storing filter params.
    addFilterParams(filterParams, true);
    resetSaveFilters();
  };
  useEffect(applyValuesCallback, [saveFilters]);

  const clearValuesCallback = () => {
    if (!clearFilters) {
      return;
    }
    setEnableEmptyPrice(false);
    resetClearFilters();
  };
  useEffect(clearValuesCallback, [clearFilters]);

  const applyValuesFromUrl = () => {
    const hotDeskSelectedFilter = filters.find(
      filter => filter.filterName === 'emptyPriceSelected',
    );
    // note: filter?.isSelected can be string or boolean
    const hotDeskSelectedVal = hotDeskSelectedFilter?.isSelected;
    setEnableEmptyPrice(hotDeskSelectedVal?.toString() === 'true');
  };
  useEffect(applyValuesFromUrl, [filters]);

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{'Action Items'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && (
          <>
            <ActiveFilterValues>{enableEmptyPrice ? 'Price 0 or Empty' : ''}</ActiveFilterValues>
          </>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <Checkbox
          label={'Price 0 or Empty'}
          id="emptyPrice"
          checked={enableEmptyPrice}
          onChange={() => setEnableEmptyPrice(!enableEmptyPrice)}
        />
      </FilterBody>
    </PanelFilterItem>
  );
}

export default UserActionFiltersPricing;
