import { isNil } from 'lodash';
import { GridDiscountPagedItem } from '../../entities/discountItems';
import { DiscountCurveResult, GridDiscount } from '../../../../../generated/voyager/graphql';

export const applyCurvesToPriceableItems = (
  oldPriceableItems: GridDiscountPagedItem[],
  applyCurvesResult: DiscountCurveResult[],
): GridDiscountPagedItem[] => {
  const priceableItems = [...oldPriceableItems];

  applyCurvesResult
    .filter(result => result.success && !isNil(result.appliedCurve))
    .forEach(result => {
      const itemIdx = priceableItems.findIndex(i => i.id === result.priceableItemId);
      const item = priceableItems[itemIdx];

      if (item) {
        const appliedCurves = [...item.appliedCurves];
        appliedCurves.push(result.appliedCurve!);
        priceableItems.splice(itemIdx, 1, {
          ...item,
          appliedCurves,
        });
      }
    });

  return priceableItems;
};

export const removeCurvesFromPriceableItems = (
  oldPriceableItems: GridDiscountPagedItem[],
  removeCurvesResult: DiscountCurveResult[],
): GridDiscountPagedItem[] => {
  const priceableItems = [...oldPriceableItems];

  removeCurvesResult
    .filter(result => result.success)
    .forEach(result => {
      const itemIdx = priceableItems.findIndex(i => i.id === result.priceableItemId);
      const item = priceableItems[itemIdx];

      if (item) {
        const appliedCurves = [...item.appliedCurves];
        const curveIdx = appliedCurves.findIndex(curve => curve.curve.id === result.curveId);

        if (curveIdx >= 0) {
          appliedCurves.splice(curveIdx, 1);

          priceableItems.splice(itemIdx, 1, {
            ...item,
            appliedCurves,
          });
        }
      }
    });

  return priceableItems;
};

export const setFutureDiscountsNull = (
  oldPriceableItems: GridDiscountPagedItem[],
  priceableItemsIds: string[],
): GridDiscountPagedItem[] => {
  const priceableItems: GridDiscountPagedItem[] = [];

  oldPriceableItems.forEach(priceableItem => {
    if (priceableItemsIds.includes(priceableItem.id)) {
      const gridDiscountsArr: GridDiscount[] = [];
      priceableItem.gridDiscounts.forEach(x => {
        const gridDiscount: GridDiscount = {
          ...x,
          futureBaseDiscount: null,
          futureOverride: null,
        };
        gridDiscountsArr.push(gridDiscount);
      });

      const newPriceableItem: GridDiscountPagedItem = {
        ...priceableItem,
        gridDiscounts: gridDiscountsArr,
      };
      priceableItems.push(newPriceableItem);
    } else {
      priceableItems.push(priceableItem);
    }
  });
  return priceableItems;
};
