import styled from 'styled-components';
import { TextArea } from 'semantic-ui-react';
import { lightGray } from '../../styles/colors';

// eslint-disable-next-line import/prefer-default-export
export const TextAreaWrapper = styled(TextArea)`
  width: 100%;
  min-height: 250px;
  resize: none;
  padding: 8px;
  border: 1px solid ${lightGray};
`;
