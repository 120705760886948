import { Action, handleActions } from 'redux-actions';
import initialState, {
  initialInventoryReconciliation,
} from '../../../../../utils/store/initialState';
import { InventoryReconciliationSubset } from '../../entities/inventoryReconciliation';
import { createRDXConstant } from '../../utils/store.constants';

// Action Constants
export const INVENTORY_RECONCILIATION = createRDXConstant('INVENTORY_RECONCILIATION');

export const INVENTORY_RECONCILIATION_SUCCESS = createRDXConstant(
  'INVENTORY_RECONCILIATION_SUCCESS',
);
export const INVENTORY_RECONCILIATION_ERROR = createRDXConstant('INVENTORY_RECONCILIATION_ERROR');

export const INVENTORY_RECONCILIATION_CLEAR_DATA = createRDXConstant(
  'INVENTORY_RECONCILIATION_CLEAR_DATA',
);

export const INVENTORY_RECONCILIATION_SET_INVENTORY_IDS = createRDXConstant(
  'INVENTORY_RECONCILIATION_SET_INVENTORY_IDS',
);

export const INVENTORY_RECONCILIATION_SET_IS_INVENTORY_IDS_VALID = createRDXConstant(
  'INVENTORY_RECONCILIATION_SET_IS_INVENTORY_IDS_VALID',
);

// Reducer
export const inventoryReconciliationReducer = handleActions<InventoryReconciliationSubset, any>(
  {
    [INVENTORY_RECONCILIATION]: (state: InventoryReconciliationSubset) => ({
      ...state,
      isLoading: true,
    }),
    [INVENTORY_RECONCILIATION_SUCCESS]: (state: InventoryReconciliationSubset) => ({
      ...state,
      ...initialInventoryReconciliation,
      isLoading: false,
    }),
    [INVENTORY_RECONCILIATION_ERROR]: (state: InventoryReconciliationSubset) => ({
      ...state,
      isLoading: false,
    }),
    [INVENTORY_RECONCILIATION_CLEAR_DATA]: (state: InventoryReconciliationSubset) => ({
      ...state,
      ...initialInventoryReconciliation,
    }),
    [INVENTORY_RECONCILIATION_SET_INVENTORY_IDS]: (
      state: InventoryReconciliationSubset,
      action: Action<string[]>,
    ) => ({
      ...state,
      inventoryIds: action.payload,
    }),
    [INVENTORY_RECONCILIATION_SET_IS_INVENTORY_IDS_VALID]: (
      state: InventoryReconciliationSubset,
      action: Action<boolean>,
    ) => ({
      ...state,
      isInventoryIdsValid: action.payload,
    }),
  },
  initialState.inventoryReconciliation,
);
