import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import {
  CLOSE_DISCOUNT_DETAILS_SIDE_PANEL,
  OPEN_DISCOUNT_DETAILS_SIDE_PANEL,
} from '../../../store/modules/discountItems/discountItems.ducks';
import {
  currentSelectedPriceableItemSelector,
  sidePanelParamSelector,
} from '../../../store/modules/discountItems/discountItems.selector';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import { CustomTableBodyCell } from '../discountAGGrid.styled';

interface DiscountsDetailsTriggerProps {
  data: any;
}

const DiscountsDetailsTrigger = ({ data }: DiscountsDetailsTriggerProps): ReactElement => {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  const openSidePanel = useCallback(
    () => dispatch({ type: OPEN_DISCOUNT_DETAILS_SIDE_PANEL, payload: data.id }),
    [dispatch, data],
  );
  const closeSidePanel = useCallback(
    () => dispatch({ type: CLOSE_DISCOUNT_DETAILS_SIDE_PANEL }),
    [dispatch],
  );
  // STATE PROPS
  const detailsPanelVisible = useSelector(sidePanelParamSelector);
  const currentSelectedItemId = useSelector(currentSelectedPriceableItemSelector);
  /**
   * Track Detail drawer button clicked
   */
  const detailsDrawerButtonTrack = () => {
    if (detailsPanelVisible) {
      trackAnalytics('Discount Item View Details Clicked', {
        workflow: 'Discount Item View Details',
        object_type: 'icon',
        object_name: 'view details',
        object_value: 'Open',
        priceable_item_uuid: data?.id,
      });
    }
  };
  useEffect(detailsDrawerButtonTrack, [detailsPanelVisible]);

  const buttonActive = data?.id === currentSelectedItemId;

  if (data) {
    return (
      <CustomTableBodyCell className="single-line">
        <button
          type="button"
          id={data?.id}
          className={cn({ active: buttonActive && detailsPanelVisible }, 'details-drawer-trigger')}
          onClick={() => (detailsPanelVisible ? closeSidePanel() : openSidePanel())}
        />
      </CustomTableBodyCell>
    );
  }

  return <> </>;
};

export default DiscountsDetailsTrigger;
