import * as React from 'react';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { GridApi } from 'ag-grid-community';
import cn from 'classnames';
import { Input } from 'semantic-ui-react';
import {
  TableFilter,
  TableFilterBarLoadingOverlay,
  TableFilterBarWrapper,
  TableFilterColumnsWrap,
  TableFilterRow,
} from '../../../../../styles/sharedTableFilterBar.styled';
import OnDemandViewEditModeSelector from './onDemandTableEditToggle';
import { onDemandPricingLoadingSelector } from '../../store/modules/onDemand/onDemandPricing.selector';
import TextLink from '../../../../../sharedComponents/styledText/styledText';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import onDemandTableDownloadConfig from '../onDemandPricingDownload.config';
import { ViewingCSVDownload } from '../../../standardPricing/components/pricingTable/pricingTable.styled';
import { Product } from '../../../../../generated/voyager/graphql';

interface OnDemandTableFilterBarProps {
  agGridApi?: GridApi;
  onDemandProducts: Product[];
}
function OnDemandTableFilterBar({
  agGridApi,
  onDemandProducts,
}: OnDemandTableFilterBarProps): ReactElement {
  // STATE PROPS
  const batchFetch = useSelector(onDemandPricingLoadingSelector);

  const trackHelper = (objectType: string, name: string, objectVal: string = '') =>
    trackAnalytics('On Demand Grid Filter', {
      workflow: 'On Demand Grid Filter',
      object_type: objectType,
      object_name: name,
      object_value: objectVal,
    });

  const setQuickFilter = (event: any): void => {
    if (agGridApi) {
      agGridApi.setQuickFilter(event.target.value);
      agGridApi.deselectAll();
      trackHelper('input', 'On Demand Search Input', event.target.value);
    }
  };
  const dataPresentInGrid = (): boolean => !!agGridApi?.getDisplayedRowCount();

  return (
    <TableFilterBarWrapper>
      {batchFetch ? <TableFilterBarLoadingOverlay /> : ''}
      <TableFilterColumnsWrap>
        <TableFilterRow>
          <TableFilter>
            <Input
              className="reservable-search"
              icon="search"
              iconPosition="left"
              title="Search Location or Reservables"
              placeholder="Filter Table View"
              onChange={setQuickFilter}
            />
            <TextLink
              className={cn({ hide: !dataPresentInGrid }, 'on-demand-margin')}
              onClick={() => {
                if (agGridApi) {
                  agGridApi.expandAll();
                  trackHelper('button', 'On Demand Expand All');
                }
              }}
            >
              Expand all rows
            </TextLink>
            <TextLink
              className={cn({ hide: !dataPresentInGrid }, 'on-demand-margin')}
              onClick={() => {
                if (agGridApi) {
                  agGridApi.collapseAll();
                  trackHelper('button', 'On Demand Collapse All');
                }
              }}
            >
              Collapse all rows
            </TextLink>
          </TableFilter>
        </TableFilterRow>
        <OnDemandViewEditModeSelector />
        <ViewingCSVDownload className={'align-on-demand'}>
          <TextLink
            className={cn({ hide: !dataPresentInGrid }, 'on-demand-margin')}
            onClick={() =>
              agGridApi?.exportDataAsCsv(onDemandTableDownloadConfig(onDemandProducts))
            }
          >
            Download Grid
          </TextLink>
        </ViewingCSVDownload>
      </TableFilterColumnsWrap>
    </TableFilterBarWrapper>
  );
}

export default OnDemandTableFilterBar;
