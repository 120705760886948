import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import AppConfig from '../../../../../config';
import { getAuthToken } from '../../../../../index';
import {
  SPACEMAN_PRICES_CONSOLIDATION,
  SPACEMAN_PRICES_CONSOLIDATION_ERROR,
  SPACEMAN_PRICES_CONSOLIDATION_SUCCESS,
} from './spacemanPricesConsolidation.ducks';

export const spacemanPricesConsolidationRequest = (): Promise<AxiosResponse<any>[]> => {
  const spacemanPricesConsolidationUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/init/spaceman-price-sync`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(spacemanPricesConsolidationUrl, null, { headers });
};

export function* spacemanPricesConsolidationWorker() {
  const response: AxiosResponse | undefined = yield call(spacemanPricesConsolidationRequest);
  if (response?.status === 200) {
    yield put({ type: SPACEMAN_PRICES_CONSOLIDATION_SUCCESS });
    toast.success(
      'Spaceman Prices consolidation job starts! An email will be sent to you once the job is done!',
    );
  } else {
    toast.error('Spaceman Prices consolidation job failed!');
    yield put({ type: SPACEMAN_PRICES_CONSOLIDATION_ERROR, payload: response?.data?.message });
  }
}

export default function* spacemanPricesConsolidationSaga(): any {
  yield all([takeLatest(SPACEMAN_PRICES_CONSOLIDATION, spacemanPricesConsolidationWorker)]);
}
