import { CellClassParams, HeaderClassParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { GridOptions } from 'ag-grid-community';
import { getRowId } from '../../app/discounts/components/discountsTable/discountAGGrid.helpers';
import TableLoadingRenderer from '../tableComponent/tableLoadingRenderer';
import EmptyTableComponentRenderer from '../tableComponent/tableEmpty';
import { locationSearchDateGetter } from './multipleLocationSearchAGGrid.helpers';
import StatusCellRenderer from './statusCellRenderer';
import { getMainMenuItemsWithoutReset } from '../../utils/helpers';

export const defaultColDef = {
  flex: 1,
  minWidth: 120,
};

const selectorDisabledCellClass = (params: CellClassParams): boolean =>
  params.context.selectedCurrency &&
  params.context.selectedCurrency !== params.data?.currencyIsoCode;

const selectorDisabled = (params: HeaderClassParams): string => {
  const allData: boolean[] = [];
  params.api.forEachNode(node => {
    allData.push(node.data.currencyIsoCode === params.context.selectedCurrency);
  });
  return !params.context.selectedCurrency || allData.some((eachData: boolean) => eachData)
    ? ''
    : 'disabled';
};

export const columnDefs = [
  {
    colId: 'selectionCheckbox',
    headerName: '',
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    maxWidth: 40,
    cellClassRules: {
      disabled: selectorDisabledCellClass,
    },
    suppressMenu: true,
    resizable: false,
    lockPosition: true,
    headerClass: selectorDisabled,
    tooltipValueGetter: (params: any): string =>
      selectorDisabledCellClass(params) ? 'This is a location with different currency' : '',
  },
  {
    headerName: 'Location',
    field: 'name',
    sortable: true,
  },
  {
    headerName: 'Status',
    field: 'description',
    cellClass: 'background-gray cell-vertical-alignment-center',
    maxWidth: 100,
    cellRenderer: StatusCellRenderer,
  },
  {
    headerName: 'Date Open',
    maxWidth: 150,
    valueGetter: locationSearchDateGetter,
  },
];

export const gridOptions: GridOptions = {
  rowSelection: 'multiple',
  tooltipMouseTrack: true,
  defaultColDef,
  loadingOverlayComponent: TableLoadingRenderer,
  noRowsOverlayComponent: EmptyTableComponentRenderer,
  suppressDragLeaveHidesColumns: true,
  getMainMenuItems: getMainMenuItemsWithoutReset,
  suppressNoRowsOverlay: true,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  groupDisplayType: 'custom',
  rowHeight: 56,
  getRowId,
  showOpenedGroup: true,
  groupDefaultExpanded: 2,
  debounceVerticalScrollbar: true,
  suppressColumnVirtualisation: true,
  suppressHorizontalScroll: true,
};
