import { gql } from '@apollo/client';

// GraphQL Queries
// Query to fetch all regions.
const FETCH_REGIONS_QUERY = gql`
  query Regions($page: PageInfo!) {
    regions(page: $page) {
      id
      name
    }
  }
`;

export default FETCH_REGIONS_QUERY;
