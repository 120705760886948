import 'ag-grid-enterprise';
import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useDispatch, useSelector } from 'react-redux';
import { IRowNode } from 'ag-grid-community';
import { SelectionChangedEvent } from 'ag-grid-community/dist/lib/events';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { MainRegionStyled, OverflowHack } from '../../../styles/app.styled';
import DiscountsTable from '../components/discountsTable/discountsTableAGGrid';
import BreadcrumbComponent from '../../../sharedComponents/breadcrumb/breadcrumbComponent';
import {
  CLEAR_DISCOUNT_ITEMS,
  CLEAR_OVERRIDE_VALUE,
  FETCH_DISCOUNT_ITEMS,
  FETCH_DISCOUNT_ITEMS_TERM_TYPES,
  PREV_DISCOUNT_ITEMS,
} from '../store/modules/discountItems/discountItems.ducks';
import {
  currentLocationsByIdsLoadState,
  getAllSelectedLocations,
} from '../../../sharedStore/modules/locations/locations.selector';
import { PageInfo } from '../../../generated/voyager/graphql';
import BottomPanelComponent from '../components/bottomPanel/bottomPanel';
import MultipleLocationWidget from '../../../sharedComponents/multipleLocationWidget/multipleLocationWidget';
import { MODIFY_FILTER_PARAMS, CLEAR_FILTER_PARAMS } from '../store/modules/filters/filters.ducks';
import ROUTES from '../../../utils/routes/routes';
import { routerHistory } from '../../../utils/store/configureStore';
import { AuthzProps } from '../../../utils/constants';
import EmployeePermissions from '../../../utils/store/permissions';
import { MainTitleStyled } from '../../../sharedComponents/header/header.styled';
import { AppTitle } from '../../../sharedComponents/homeDashboard/applicationCard.styled';
import { titleName } from '../../../utils/helpers';
import currentPathNameSelector from '../../../sharedStore/modules/router/router.selector';
import { pageInfoSmall } from '../../../utils/store/store.constants';
import { DecodeUrlInput, ShortUrlRouterParams } from '../../../sharedStore/entities/generateLink';
import { DECODE_URL } from '../../../sharedStore/modules/generateLink/generateLink.ducks';
import GenerateLink from '../../../sharedComponents/generateLink/generateLink';
import { filtersSelector } from '../store/modules/filters/filters.selector';
import { CLEAR_LOCATIONS_BY_IDS } from '../../../sharedStore/modules/locations/locations.ducks';

function DiscountTableView({ requestedPermissions }: AuthzProps): ReactElement {
  const { locationsUrl, filtersUrl } = useParams<ShortUrlRouterParams>();

  // STATE VAR
  const [selectedNodes, setSelectedNodes] = useState<IRowNode[]>([]);
  // STATE PROPS
  const selectedLocations = useSelector(getAllSelectedLocations);
  const locationsByIdsLoaded = useSelector(currentLocationsByIdsLoadState);
  const filters = useSelector(filtersSelector);
  const { pathname } = useSelector(currentPathNameSelector);

  // DISPATCH PROPS
  const dispatch = useDispatch();
  // Dispatching props in functional component.
  const getGridData = useCallback(
    (geoLocationUUID: string[], page: PageInfo) => {
      const payload = {
        page,
        filter: { geoLocationUUID },
      };
      dispatch({ type: FETCH_DISCOUNT_ITEMS_TERM_TYPES, payload: page });
      dispatch({ type: FETCH_DISCOUNT_ITEMS, payload });
    },
    [dispatch],
  );

  const decodeUrl = useCallback(
    (payload: DecodeUrlInput) => dispatch({ type: DECODE_URL, payload }),
    [dispatch],
  );

  const clearAll = useCallback(() => {
    dispatch({ type: PREV_DISCOUNT_ITEMS });
    dispatch({ type: CLEAR_DISCOUNT_ITEMS });
    dispatch({ type: CLEAR_OVERRIDE_VALUE });
    dispatch({ type: CLEAR_FILTER_PARAMS });
    dispatch({ type: CLEAR_LOCATIONS_BY_IDS });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (
      !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_discount_view, false)
    ) {
      redirectToAccessDenied();
    } else if (!!locationsUrl && !locationsByIdsLoaded) {
      decodeUrl({ locationsUrl, filtersUrl, setFiltersActionName: MODIFY_FILTER_PARAMS });
    } else if (!isEmpty(selectedLocations)) {
      getGridData(
        selectedLocations.map(a => a.id),
        pageInfoSmall,
      );
    } else {
      toast.info('No location selected - Redirecting to home', {
        position: toast.POSITION.TOP_CENTER,
      });
      routerHistory.push({ pathname: ROUTES.DISCOUNT });
    }
  }, [
    locationsUrl,
    filtersUrl,
    locationsByIdsLoaded,
    selectedLocations,
    decodeUrl,
    getGridData,
    redirectToAccessDenied,
    requestedPermissions,
  ]);

  useEffect(
    () => () => {
      clearAll();
    },
    [clearAll],
  );

  // On row selection changes tracking selected nodes.
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const tempSelectedNodes = event.api.getSelectedNodes();
    setSelectedNodes(tempSelectedNodes);
  };
  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <BreadcrumbComponent />
          <MainTitleStyled>
            <AppTitle>{titleName(pathname)}</AppTitle>
            <GenerateLink
              disabled={isEmpty(selectedLocations)}
              routeName={ROUTES.DISCOUNT_TABLEVIEW}
              filters={filters}
            />
          </MainTitleStyled>
          <MultipleLocationWidget />
          <DiscountsTable onSelectionChanged={onSelectionChanged} />
        </OverflowHack>
      </MainRegionStyled>
      <BottomPanelComponent selectedNodes={selectedNodes} />
    </>
  );
}
export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_discount_view],
})(DiscountTableView);
