import { StoreInterface } from '../../../../../utils/store/store.interface';
import { GeoHierarchyReconciliationSubset } from '../../entities/geoHierarchyReconciliation';
import { FetchState } from '../../../../../utils/sharedTypes';

export const geoHierarchyReconciliationSelector = (
  state: StoreInterface,
): GeoHierarchyReconciliationSubset => state?.geoHierarchyReconciliation;

export const geoHierarchyReconciliationFetchStateSelector = (state: StoreInterface): FetchState =>
  geoHierarchyReconciliationSelector(state)?.fetchState;
