import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { getAuthToken } from '../../../../../index';
import AppConfig from '../../../../../config';
import { errorHandlerActive } from '../../../../../utils/errorHandling/helpers';
import { CHECK_JOB_STATUS, SET_JOB_STATUS } from './jobStatus.ducks';

const checkIfJobIsRunningRequest = (): Promise<AxiosResponse<any>[]> => {
  const getSyncAttemptsUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/job-status`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };

  return axios.get(getSyncAttemptsUrl, { headers }).catch(error => error.response);
};

export function* checkIfJobIsRunning() {
  try {
    const response: AxiosResponse | undefined = yield call(checkIfJobIsRunningRequest);
    if (response?.status === 200) {
      yield put({ type: SET_JOB_STATUS, data: response.data });
    } else {
      toast.error(`Error while checking if any job is running - ${response?.data?.message}`);
    }
  } catch (e: any) {
    toast.error(
      `Error while checking if any job is running - ${get(e, 'message', 'Unknown Error')}`,
    );
    errorHandlerActive(new Error(e));
  }
}

export function* jobStatusSaga(): any {
  yield all([takeLatest(CHECK_JOB_STATUS, checkIfJobIsRunning)]);
}
