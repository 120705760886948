import * as React from 'react';
import { ReactElement } from 'react';
import { Icon, IconName, IconSize, IconType } from '@wework/ray2';
import { has } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import { CustomTableBodyCell } from '../discountAGGrid.styled';

const getValueIfExist = (object: any, propertyName: string) => {
  if (propertyName === 'key') {
    return uuidV4();
  }
  return has(object, `${propertyName}`) ? object[propertyName] : null;
};

const tableCellPhysicalAttributes = (props: { data: any }): ReactElement => {
  if (props.data) {
    return (
      <CustomTableBodyCell className="single-line physical-att">
        <Icon
          icon={IconName.WINDOW}
          size={IconSize.LARGE}
          type={IconType.OUTLINE}
          className={`${
            props.data?.physicalAttributes[0]?.value ? 'text-primary' : 'text-gray-70'
          } window-icon pa-icon`}
        />
        <Icon
          icon={IconName.DOOR}
          size={IconSize.LARGE}
          type={IconType.OUTLINE}
          className={`${
            getValueIfExist(props.data, 'internalRoomCount') ? 'text-primary' : 'text-gray-70'
          } internal-rooms-icon pa-icon`}
        />
        <p className="counter">{getValueIfExist(props.data, 'internalRoomCount')}</p>
      </CustomTableBodyCell>
    );
  }
  return <></>;
};

export default tableCellPhysicalAttributes;
