import { StoreInterface } from '../../../../../utils/store/store.interface';
import { SnowflakeSyncInitializationSubset } from '../../entities/snowflakeSyncInitialization';
import { FetchState } from '../../../../../utils/sharedTypes';

export const snowflakeSyncInitializationSelector = (
  state: StoreInterface,
): SnowflakeSyncInitializationSubset => state?.snowflakeSyncInitialization;

export const snowflakeSyncInitializationLoadingStateSelector = (
  state: StoreInterface,
): FetchState => snowflakeSyncInitializationSelector(state)?.priceRecommendationPushJobState;

export const liveBudgetPushJobLoadingStateSelector = (state: StoreInterface): FetchState =>
  snowflakeSyncInitializationSelector(state)?.liveBudgetPushJobState;

export const locationMetricsPushJobLoadingStateSelector = (state: StoreInterface): FetchState =>
  snowflakeSyncInitializationSelector(state)?.locationMetricsPushJobState;

export const reservableMetricsPushJobLoadingStateSelector = (state: StoreInterface): FetchState =>
  snowflakeSyncInitializationSelector(state)?.reservableMetricsPushJobState;

export const locationSKUOccupancyCalculationStateSelector = (state: StoreInterface): FetchState =>
  snowflakeSyncInitializationSelector(state)?.locationSKUOccupancyCalculationState;

export const marketSKUOccupancyCalculationStateSelector = (state: StoreInterface): FetchState =>
  snowflakeSyncInitializationSelector(state)?.marketSKUOccupancyCalculationState;
