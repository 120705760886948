import { Action, handleActions } from 'redux-actions';
import { get } from 'lodash';
import { TerritoriesSubset } from '../../entities/territories';
import initialState from '../../../utils/store/initialState';
import { createRDXConstant } from '../../../utils/store/store.constants';

// Action Constants
// Action - fetch all territories by region.
export const FETCH_TERRITORIES_BY_REGION = createRDXConstant('FETCH_TERRITORIES_BY_REGION');
export const FETCH_TERRITORIES_BY_REGION_SUCCESS = createRDXConstant(
  'FETCH_TERRITORIES_BY_REGION_SUCCESS',
);

// Reducer
export const territoriesReducer = handleActions<TerritoriesSubset, any>(
  {
    // Reducer to fetch all territories by selected region.
    [FETCH_TERRITORIES_BY_REGION]: (state: TerritoriesSubset) => ({
      ...state,
      fetchTerritoriesState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_TERRITORIES_BY_REGION_SUCCESS]: (state: TerritoriesSubset, action: Action<any>) => {
      const allTerritories = get(action, 'payload.territoriesByRegion', []);
      return {
        ...state,
        fetchTerritoriesState: {
          loading: false,
          loaded: true,
          error: null,
        },
        allTerritories,
      };
    },
  },
  initialState.territories,
);
