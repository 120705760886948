import * as React from 'react';
import { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';
import { SkeletonParagraph } from '@wework/dieter-ui';
import { LoadingBox, PricingTableWrapper, TableLayoutWrapper } from './tableComponent.styled';

const TableLoadingRenderer = (): ReactElement => (
  <TableLayoutWrapper>
    <PricingTableWrapper>
      <Table sortable fixed celled textAlign="left" className="pricing-table">
        <Table.Body>
          <Table.Row>
            <td className="empty-loading-placeholder" />
          </Table.Row>
        </Table.Body>
      </Table>
      <LoadingBox>
        <SkeletonParagraph size="large" rows={5} />
      </LoadingBox>
    </PricingTableWrapper>
  </TableLayoutWrapper>
);

export default TableLoadingRenderer;
