import { find, get, round } from 'lodash';
import { CellClassParams, ValueFormatterParams } from 'ag-grid-community';
import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import {
  StandardPricingPagedItem,
  StandardPricingTableViewItem,
} from '../../../store/entities/standardPricingItems';
import { isPercentageNull, roundToAtMost2Digit } from '../../helpers';
import { intlCurrencyFormatter } from '../../../../../../utils/helpers';
import { Location } from '../../../../../../generated/voyager/graphql';

const transformStandardPriceItemToTableView = (
  priceableItem: StandardPricingPagedItem,
  allLocationDetails: Location[],
): StandardPricingTableViewItem => {
  const locationDetails: Location =
    find(allLocationDetails, ['id', priceableItem?.location?.id]) ?? ({} as Location);
  const attributesArr = priceableItem.attributes;
  const sku = `${find(attributesArr, ['label', 'sku'])?.value}`;
  // Data calculated using Location Details.
  // - location sku occupancy for each sku
  const locationSkuOccupancy = isPercentageNull(
    find(locationDetails?.skuOccupancy, ['sku', sku])?.occupancy ?? 0,
  );
  // - market sku occupancy for each sku
  const marketSkuOccupancy = isPercentageNull(
    find(locationDetails?.market?.skuOccupancy, ['sku', sku])?.occupancy ?? 0,
  );
  // - lowest net price for each sku
  const maxDiscount = find(locationDetails?.maxDiscounts, ['sku', sku])?.discount ?? 0;
  const locationMinPrice = locationDetails?.minPrice ?? 0;
  const timeZone = locationDetails?.timeZone;

  return {
    ...priceableItem,
    locationSkuOccupancy,
    marketSkuOccupancy,
    maxDiscount,
    locationMinPrice,
    timeZone,
    sku,
  };
};

export const transformStandardPriceItemsToTableView = (
  priceableItems: StandardPricingPagedItem[],
  locationDetails: Location[],
): StandardPricingTableViewItem[] =>
  priceableItems.map(priceableItem =>
    priceableItem
      ? transformStandardPriceItemToTableView(priceableItem, locationDetails)
      : ({} as StandardPricingTableViewItem),
  );

export const stringToNumberValidationVF = (params: ValueFormatterParams): string => {
  if (!params.data) {
    return '';
  }
  const val = Number(params.value);
  return val ? roundToAtMost2Digit(val).toString() : '-';
};

export const stringToNumberValidationCurrencyVF = (params: ValueFormatterParams): string => {
  if (!params.data) {
    return '';
  }

  return Number(params.value)
    ? intlCurrencyFormatter(
        params.node?.data?.currentPrice?.currencyIsoCode,
        roundToAtMost2Digit(Number(params.value)),
      )
    : '-';
};

export const toPercentageVF = (params: ValueFormatterParams): string => {
  if (!params.data) {
    return '';
  }

  const val: number = params.value;
  return val ? isPercentageNull(val) : '-';
};

export const positiveNegativeFormatter = (params: ValueFormatterParams): string => {
  if (!params.data) {
    return '';
  }

  const val = params.value;
  if (val && val !== '-') {
    const formattedValue = intlCurrencyFormatter(
      params.node?.data?.currentPrice?.currencyIsoCode,
      val,
    );
    return Number(val) > 0 ? `+${formattedValue}` : formattedValue;
  }
  return val;
};

export const stringToStringValidationVF = (params: ValueFormatterParams): string => {
  if (!params.data) {
    return '';
  }

  return params.value ? params.value : '-';
};

export const colorPromoCellClass = (params: CellClassParams): string => {
  const value = params?.value;

  if (value && value !== '-') {
    const num = Number(value);
    return num > 0 ? 'positive' : 'negative';
  }

  return 'neutral';
};

export const getAttributesValueHelper = (params: ValueGetterParams): string => {
  const field = params.colDef.field;
  const val = field ? find(params.data?.attributes, ['label', field])?.value : null;
  return val ? `${val}` : '';
};

export const transformToListPrice = (params: ValueGetterParams): number => {
  if (!params.data) {
    return 0;
  }

  const field = params.colDef.field;
  const spatialWorkUnits: number =
    find(params.data?.attributes, ['label', 'spatialWorkUnits'])?.value ?? 0;
  const capacity: number = find(params.data?.attributes, ['label', 'capacity'])?.value ?? 0;
  const priceFactor: number =
    spatialWorkUnits && spatialWorkUnits > 0 ? spatialWorkUnits : capacity ?? 0;

  if (field) {
    const columnData: number = get(params.data, field);
    if (priceFactor > 0) {
      return columnData * priceFactor;
    }

    return columnData;
  }

  return 0;
};

export const workingPriceToListPrice = (params: ValueGetterParams): number => {
  if (!params.data) {
    return 0;
  }

  const field = params.colDef.field;
  const spatialWorkUnits: number =
    find(params.data?.attributes, ['label', 'spatialWorkUnits'])?.value ?? null;
  const capacity: number = find(params.data?.attributes, ['label', 'capacity'])?.value;
  const priceFactor: number =
    spatialWorkUnits && spatialWorkUnits > 0 ? spatialWorkUnits : capacity ?? 0;

  if (field) {
    const columnData: number = get(params.data, field) ?? get(params.data, 'currentPrice.price');
    if (priceFactor > 0) {
      return columnData * priceFactor;
    }

    return columnData;
  }

  return 0;
};

export const lowestNetPriceCalculationVG = (params: ValueGetterParams): string => {
  const workingPrice = get(params.data, 'workingPrice.price') ?? 0;
  const currentPrice = get(params.data, 'currentPrice.price') ?? 0;
  const maxDiscount = params.data?.maxDiscount ?? 0;
  const price = workingPrice > 0 ? workingPrice : currentPrice;

  const lowestNetPrice = price * (1 - maxDiscount);

  return `${lowestNetPrice.toFixed(0)}`;
};

export const lowestNetPriceMinusMinPriceCalculationVG = (params: ValueGetterParams): string => {
  const workingPrice = get(params.data, 'workingPrice.price') ?? 0;
  const currentPrice = get(params.data, 'currentPrice.price') ?? 0;
  const maxDiscount = params.data?.maxDiscount ?? 0;
  const locationMinPrice = params.data?.locationMinPrice ?? 0;
  const price = workingPrice > 0 ? workingPrice : currentPrice;

  const lowestNetPrice = price * (1 - maxDiscount);
  const lowestNetPriceMinusMinPrice = lowestNetPrice - locationMinPrice;

  return `${lowestNetPriceMinusMinPrice.toFixed(0)}`;
};

export const suggAndCurrPriceDiff = (params: ValueGetterParams): string => {
  if (!params.data) {
    return '';
  }

  const suggestedPrice = get(params.data, 'recommendedPrice.price') ?? 0;
  const currentPrice = get(params.data, 'currentPrice.price') ?? 0;

  if (!suggestedPrice || !currentPrice) {
    return '-';
  }

  const percent = ((suggestedPrice - currentPrice) / currentPrice) * 100;
  const val = percent.toFixed(0);
  return `${Number(val) > 0 ? `+${val}` : val}%`;
};

export const adjByField = (params: ValueGetterParams): string => {
  if (!params.data) {
    return '';
  }

  const workingPrice = get(params.data, 'workingPrice.price') ?? 0;
  const currentPrice = get(params.data, 'currentPrice.price') ?? 0;

  if (!workingPrice || !currentPrice) {
    return '-';
  }

  const workingPriceAfterRound = roundToAtMost2Digit(workingPrice);
  const currentPriceAfterRound = roundToAtMost2Digit(currentPrice);
  const percent = ((workingPriceAfterRound - currentPriceAfterRound) / currentPrice) * 100;
  const value = intlCurrencyFormatter(
    params.node?.data?.currentPrice?.currencyIsoCode,
    round(workingPrice - currentPrice),
  );

  // Check if diff is empty if so return '-'
  if (!percent) {
    return '-';
  }

  return `${value}(${percent.toFixed(0)}%)`;
};

export const colorAdjByFieldPromoCellClassGray = (
  params: CellClassParams,
  type: string,
): boolean => {
  let value;
  const workingPrice = get(params.data, 'workingPrice.price') ?? 0;
  const currentPrice = get(params.data, 'currentPrice.price') ?? 0;

  if (!workingPrice || !currentPrice) {
    value = 0;
  } else {
    value = Number((workingPrice - currentPrice).toFixed(0));
  }
  if (type === 'positive') {
    return value > 0;
  } else if (type === 'negative') {
    return value < 0;
  }
  return !value;
};

export const colorAdjByFieldPromoCellClassRules = {
  positive: (params: any): boolean => colorAdjByFieldPromoCellClassGray(params, 'positive'),
  negative: (params: any): boolean => colorAdjByFieldPromoCellClassGray(params, 'negative'),
  neutral: (params: any): boolean => colorAdjByFieldPromoCellClassGray(params, 'neutral'),
};
