import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { get, isEqual, isNil, isObject } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'semantic-ui-react';
import cn from 'classnames';
import {
  intlCurrencyFormatter,
  intlNumberFormatter,
  validateInputNumberValue,
} from 'utils/helpers';
import { EditableCellStyled, TextLink } from '../breakevenTableAGGrid.styled';
import {
  breakevenItemsSelectorStore,
  editModeSelectorBreakeven,
  marketSgAndASelector,
} from '../../../store/modules/breakevenItems/breakevenItems.selector';
import {
  CustomTableBodyCell,
  EditWrap,
} from '../../../../pricing/standardPricing/components/pricingTable/pricingTable.styled';
import {
  inputNumberFormatter,
  parseFloatSafe,
  roundToAtMostNDigit,
  roundToInteger,
} from '../../../../pricing/standardPricing/components/helpers';
import { CREATE_BREAKEVEN_WORKING_DATA } from '../../../store/modules/breakevenItems/breakevenItems.ducks';
import { errorHandlerActive } from '../../../../../utils/errorHandling/helpers';
import {
  convertToPercent,
  getBreakevenValue,
  getToBePublishedData,
} from '../breakevenTable.helper';
import {
  fieldsEditable,
  maxIntegerValue,
  percentValues,
  typeMap,
  valuesIncludedInCalculation,
  wholeNumberValues,
} from '../../constants';

function EditableCellRenderer(props: ICellRendererParams): ReactElement {
  // STATE PROPS
  const editMode = useSelector(editModeSelectorBreakeven);
  const marketSgAndAs = useSelector(marketSgAndASelector);
  const allItems = useSelector(breakevenItemsSelectorStore) ?? [];

  // STATE VAR
  const [displayValue, setDisplayValue] = useState<number | string>();
  const [displayValueNumber, setDisplayValueNumber] = useState<number | string>();
  const [displayType, setDisplayType] = useState<string>();
  // Check if this cell is editable. Only cells in override columns are editable.
  const [editableFlag, setEditableFlag] = useState<boolean>(false);
  // Special Information Flag - Need for the last row.
  const [infoFlag, setInfoFlag] = useState<boolean>(false);
  const [overrideFlag, setOverrideFlag] = useState<boolean>(false);
  const [percentageFlag, setPercentageFlag] = useState<boolean>(false);

  useEffect(() => {
    const field = props.colDef?.field;
    setEditableFlag(fieldsEditable.has(field ?? '') && props.data?.type === 'override');
    setInfoFlag(props.data?.type === 'toBePublished' || props.data?.type === 'current');
    setPercentageFlag(percentValues.includes(field ?? ''));

    const currVal = isObject(props.value) ? get(props.value, 'value', null) : props.value;
    const displayVal: number | undefined = parseFloatSafe(currVal);
    if (!isNil(displayVal)) {
      if (field === 'steadyStateOcc') {
        const disVal = roundToAtMostNDigit(convertToPercent(displayVal), 1);
        setDisplayValue(disVal);
        setDisplayValueNumber(disVal);
      } else if (field === 'desksInYearMonths' || field === 'deskCapacity' || field === 'rsf') {
        const disVal = roundToAtMostNDigit(displayVal, 0);
        setDisplayValue(intlNumberFormatter(disVal));
        setDisplayValueNumber(disVal);
      } else {
        const disVal = roundToInteger(displayVal);
        setDisplayValue(intlCurrencyFormatter(props.data?.location.currencyIsoCode, disVal));
        setDisplayValueNumber(disVal);
      }
    } else {
      // BC: Reset data being displayed if clearAll is executed i.e when user updated the store state
      // using external action.
      setDisplayValue(undefined);
      setDisplayValueNumber(undefined);
    }

    setDisplayType(get(typeMap, props.value?.type, ''));
  }, [props]);

  useEffect(() => {
    setOverrideFlag(
      props.data?.type === 'toBePublished' && !!displayType && displayType !== 'No Change',
    );
  }, [props, displayType]);

  useEffect(() => {
    const displayVal: number | undefined = parseFloatSafe(
      isObject(props.value) ? get(props.value, 'value', null) : props.value,
    );
    const field = props.colDef?.field;
    if (!isNil(displayVal)) {
      if (field === 'steadyStateOcc') {
        setDisplayValueNumber(roundToAtMostNDigit(convertToPercent(displayVal), 1));
      } else {
        setDisplayValueNumber(roundToInteger(displayVal));
      }
    }
  }, [props, editMode]);

  const dispatch = useDispatch();
  const createBreakevenWorkingPrice = useCallback(
    (payload: any) => dispatch({ type: CREATE_BREAKEVEN_WORKING_DATA, payload }),
    [dispatch],
  );

  const onSubmit = () => {
    // Saving the new data.
    const field = props.colDef?.field ?? null;
    let newDisplayValue =
      typeof displayValueNumber === 'string'
        ? parseFloatSafe(displayValueNumber)
        : displayValueNumber;
    const prevDisplayValue = props.value;
    newDisplayValue =
      percentageFlag && !isNil(newDisplayValue) ? (newDisplayValue * 100) / 10000 : newDisplayValue;

    // 1) Creating a backend request for updating the data.
    if (!isEqual(newDisplayValue, prevDisplayValue)) {
      if (field !== null) {
        createBreakevenWorkingPrice({
          geoLocationUUID: props.data?.location?.id,
          workingBreakEvenOverrideInput: {
            [field]: newDisplayValue,
          },
        });
      } else {
        errorHandlerActive(new Error('Fatal error: coldDef field is empty.'));
      }
    }
  };

  const validateCalculationResult = (field: string, value: number): boolean => {
    const item = allItems.find(i => i.location.id === props.data?.location?.id);
    if (!item) {
      return false;
    }
    const toBePublishedData = getToBePublishedData(item);

    const marketSgAndA = marketSgAndAs.find(
      mSgAndA => mSgAndA.marketId === props.data.location?.market?.id,
    )?.sgAndA;

    const sgAndA =
      field === 'sgAndA' && !isNaN(value)
        ? value
        : toBePublishedData.sgAndA?.value ??
          (marketSgAndA &&
            marketSgAndA *
              (field === 'rsf' && !isNaN(value) ? value : toBePublishedData.rsf?.value));

    if (sgAndA > maxIntegerValue) {
      return false;
    }

    const breakeven = getBreakevenValue(
      fieldName =>
        fieldName === field && !isNaN(value) ? value : toBePublishedData[fieldName]?.value,
      sgAndA,
    );

    return breakeven <= maxIntegerValue;
  };

  const cleanAndSetVal = (event: any) => {
    const currentValue = inputNumberFormatter(event?.target?.value);
    const numericalValue = parseFloat(currentValue);
    const field = props.colDef?.field ?? '';

    validateInputNumberValue(
      event,
      field,
      {
        percentValues: {
          fields: percentValues,
        },
        containsDecimalValues: {
          fields: wholeNumberValues,
        },
        wholeNumberValues: {
          fields: wholeNumberValues,
        },
        valuesIncludedInCalculation: {
          fields: valuesIncludedInCalculation,
          predicate: !validateCalculationResult(field, numericalValue),
        },
      },
      () => setDisplayValueNumber(currentValue),
    );
  };

  const clear = () => {
    const field = props.colDef?.field ?? null;
    if (field) {
      const clearedData =
        field === 'sgAndA'
          ? {
              geoLocationUUID: props.data?.location?.id,
              workingBreakEvenOverrideInput: {
                [field]: null,
              },
            }
          : {
              geoLocationUUID: props.data?.location?.id,
              workingBreakEvenOverrideInput: {
                [field]: null,
              },
              workingLiveBudgetAppliedInput: {
                [field]: null,
              },
            };
      createBreakevenWorkingPrice(clearedData);
    } else {
      errorHandlerActive(new Error('Fatal error: coldDef field is empty.'));
    }
  };

  return editMode && editableFlag ? (
    <CustomTableBodyCell>
      <EditWrap className="fluid-width">
        <Input
          type="text"
          value={displayValueNumber || ''}
          onChange={event => cleanAndSetVal(event)}
          onKeyDown={(event: any) => event.key === 'Enter' && onSubmit()}
          onBlur={onSubmit}
        />
      </EditWrap>
    </CustomTableBodyCell>
  ) : (
    <EditableCellStyled>
      {isNil(displayValue) && props.data?.type === 'toBePublished' ? (
        <span className="info">No Change</span>
      ) : (
        <>
          <span className={cn({ override: overrideFlag })}>
            {isNil(displayValue) ? '' : `${displayValue}${percentageFlag ? '%' : ''}`}
          </span>
          <span className="info">{infoFlag ? displayType : ''}</span>
          {editMode &&
            infoFlag &&
            (props.value?.type === 'override' || props.value?.type === 'liveBudgetApplied') && (
              <TextLink onClick={() => clear()}>Clear</TextLink>
            )}
        </>
      )}
    </EditableCellStyled>
  );
}

export default EditableCellRenderer;
