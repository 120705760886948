import { push } from 'connected-react-router';
import ROUTES from '../routes/routes';
import store from '../../index';

/**
 * Does not show error message to user and does not effect application functionality.
 * @param _e
 */
export const errorHandlerPassive = (_e: Error): void => {
  window.Rollbar.error(_e);
};

/**
 * Shows error message to user and effects application functionality.
 * Redirects the user to Error page.
 * @param _e
 */
export const errorHandlerActive = (_e: Error): void => {
  // 1) Adding rollbar error message.
  window.Rollbar.error(_e);
  // 2) Redirect user to error page.
  // Doing this because it will act as a reset mechanism for the application so that error does not happen again.
  store.dispatch(push(ROUTES.ERROR));
};
