// Exports a method that creates the modules modules
// http://redux.js.org/docs/api/createStore.html
import { createLogger } from 'redux-logger';
import { applyMiddleware, compose, createStore, Reducer, Store } from 'redux';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import Segment from '@redux-beacon/segment';
import { createMetaReducer, createMiddleware } from 'redux-beacon';
import config from 'config';
import initialState from './initialState';
import createRootReducer from './rootReducer';
import eventsMapper from '../analytics/storeEvents';

// Defining all the middlewares and reducers below
export const routerHistory = createBrowserHistory();

export const sagaMiddleware = createSagaMiddleware();

const loggerMiddleware = createLogger({
  level: 'info',
  collapsed: true,
  predicate: (_getState, action) => {
    const shouldSkipLog = action.meta?.skipLog;

    return (config.APP_ENV === 'staging' || config.APP_ENV === 'local') && !shouldSkipLog;
  },
});

const segment = Segment();

const segmentMiddleware = createMiddleware(eventsMapper, segment);
const segmentMetaReducer = createMetaReducer(eventsMapper, segment);

export const middlewares: any[] = [];

/* Redux-Sagas Middlewares */
middlewares.push(sagaMiddleware);

/* React Router Middlewares */
middlewares.push(routerMiddleware(routerHistory));

/* React Logger Middlewares */
middlewares.push(loggerMiddleware);

/* React Segment Middlewares */
middlewares.push(segmentMiddleware);

// Declaring global analytics var type.
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    analytics: any;
    Rollbar: import('rollbar');
  }
}

export const rootReducer: Reducer = createRootReducer(routerHistory, segmentMetaReducer);

export default function configureStore(): Store {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  return createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
}
