import React, { ReactElement } from 'react';
import { Button } from '@wework/ray2';
import { Header } from 'semantic-ui-react';
import SplashPage from './splash/splashPage';
import LoginInfo from '../styles/sharedLogin.styled';
import ROUTES from '../utils/routes/routes';
import { routerHistory } from '../utils/store/configureStore';

const redirectToHome = () =>
  routerHistory.push({
    pathname: ROUTES.HOME,
  });

function AccessDeniedPage(): ReactElement {
  return (
    <SplashPage>
      <>
        <Header>Access Denied</Header>
        <LoginInfo>
          We’re sorry, you do not have permission to access this page. Please request Permission or
          sign in using a different account.
        </LoginInfo>
        <Button onClick={() => redirectToHome()} theme={'fill'} size={'medium'}>
          Return to Home
        </Button>
      </>
    </SplashPage>
  );
}

export default AccessDeniedPage;
