export const lightestGray = '#f7f7f7';
export const lighterGray = '#e6e6e6';
export const filterBorderGray = '#eeeeee';
export const lightGray = '#B0B0B0';
export const gray = '#979797';
export const darkGray = '#666666';
export const disabledGray = '#d3d3d3';
export const lightestBlue = '#d1e9fb';
export const blue = '#0077cc';
export const strongBlue = '#04417a';
export const strongerBlue = '#1e58c6';
export const darkBlue = '#001e33';
export const red = '#CC0000';
export const darkGreen = '#086600';
export const green = '#00B377';
export const pureBlack = '#000000';
export const devEnvOrange = '#FFB858';
export const white = '#FFFFFF';
