import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import AppConfig from 'config';
import { errorHandlerActive } from 'utils/errorHandling/helpers';
import { getAuthToken } from 'index';
import {
  INVENTORY_RECONCILIATION,
  INVENTORY_RECONCILIATION_ERROR,
  INVENTORY_RECONCILIATION_SUCCESS,
} from './inventoryReconciliation.ducks';
import { inventoryReconciliationInventoryIdsSelector } from './inventoryReconciliation.selector';
import { SET_JOB_STATUS } from '../jobStatus/jobStatus.ducks';

export const inventoryReconciliationRequest = (
  inventoryIds: string[],
): Promise<AxiosResponse<any>[]> => {
  const inventoryReconciliationUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/ims-backfill/items`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };

  return axios.post(inventoryReconciliationUrl, inventoryIds, { headers });
};

// Saga - to handle upload file.
export function* inventroyReconciliationWorker() {
  try {
    const inventoryIds: string[] = yield select(inventoryReconciliationInventoryIdsSelector);

    if (!isEmpty(inventoryIds)) {
      const response: AxiosResponse | undefined = yield call(
        inventoryReconciliationRequest,
        inventoryIds,
      );
      if (response?.status === 200) {
        yield put({ type: INVENTORY_RECONCILIATION_SUCCESS });
        toast.success('Inventory reconciliation successfully started!');
        yield put({ type: SET_JOB_STATUS, data: { job: 'INVENTORY_RECONCILIATION' } });
      } else {
        toast.error('Inventory reconciliation error');
        yield put({ type: INVENTORY_RECONCILIATION_ERROR, payload: response?.data?.message });
      }
    }
  } catch (e: any) {
    toast.error(`Inventory reconciliation error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export function* inventoryReconciliationSaga(): any {
  yield all([takeLatest(INVENTORY_RECONCILIATION, inventroyReconciliationWorker)]);
}
