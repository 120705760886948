import { Action, handleActions } from 'redux-actions';
import { get } from 'lodash';
import initialState from '../../../utils/store/initialState';
import { AuthSubset } from '../../entities/auth';
import { createRDXConstant, initState } from '../../../utils/store/store.constants';

// Action Constants
// Auth0 Request - Try logging in using auth0
export const LOGIN_REQUEST = createRDXConstant('LOGIN_REQUEST');
export const LOGIN_REQUEST_SUCCESS = createRDXConstant('LOGIN_REQUEST_SUCCESS');

// Action - LOGOUT
export const LOGOUT_REQUEST = createRDXConstant('LOGOUT_REQUEST');
// Action - Redirect
export const REDIRECT_REQUEST = createRDXConstant('REDIRECT_REQUEST');
// Action - Verifying the authentication state of the user
export const AUTHENTICATE = createRDXConstant('AUTHENTICATE');
// Action - The user is un-authenticated
export const UN_AUTHENTICATED = createRDXConstant('UN_AUTHENTICATED');
// Action - Adding referrer in store for redirect
export const ADD_REFERRER = createRDXConstant('ADD_REFERRER');

// Reducer
export const authReducer = handleActions<AuthSubset, any>(
  {
    // Reducer to handle login request and load the tokens.
    [LOGIN_REQUEST]: (state: AuthSubset) => ({
      ...state,
      logIn: {
        loading: true,
        loaded: false,
        error: null,
      },
      unAuthenticated: false,
    }),
    [LOGIN_REQUEST_SUCCESS]: (state: AuthSubset, action: Action<any>) => {
      const token = get(action, 'payload.token', '');
      const user = get(action, 'payload.user', {});

      return {
        ...state,
        logIn: {
          loading: false,
          loaded: true,
          error: null,
        },
        token,
        user,
        unAuthenticated: false,
      };
    },

    // Reducer to handle the logout request
    [LOGOUT_REQUEST]: (state: AuthSubset) => ({
      ...state,
      logIn: { ...initState },
      logOut: { ...initState },
      token: '',
      currentUser: {
        userFetchState: { ...initState },
        userDetails: null,
      },
      unAuthenticated: true,
    }),

    // Reducer to handle the redirect request when the redirect is callback.
    [REDIRECT_REQUEST]: (state: AuthSubset) => ({
      ...state,
    }),

    // Reducer to authenticate the user.
    [AUTHENTICATE]: (state: AuthSubset) => ({
      ...state,
      logIn: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),

    // Reducer to mark the user un authenticated.
    [UN_AUTHENTICATED]: (state: AuthSubset) => ({
      ...state,
      logIn: {
        loading: false,
        loaded: true,
        error: null,
      },
      unAuthenticated: true,
    }),

    // Reducer to add the current path as referrer.
    [ADD_REFERRER]: (state: AuthSubset, action: Action<any>) => {
      const referrer = get(action, 'payload', null);
      return {
        ...state,
        referrer,
      };
    },
  },
  initialState.auth,
);
