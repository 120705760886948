import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { pluralize } from 'apollo/lib/utils';
import { v4 as uuidV4 } from 'uuid';
import {
  breakevenItemsToPublishSelector,
  breakevenPricesPublishing,
} from '../../store/modules/breakevenItems/breakevenItems.selector';
import BreakevenPublishTable from './breakevenPublishTable';
import {
  EachLocationSummary,
  LocationsChangesSummaries,
  SummaryNote,
  SummarySubTitle,
  SummaryTitle,
  SummaryWrapper,
  TitleBar,
  WorkingOverlay,
} from '../../../pricing/standardPricing/components/pricingPublish/pricingPublish.styled';
import { usePrevious } from '../../../pricing/standardPricing/components/helpers';
import { currentMultipleLocationDetailsSelector } from '../../../../sharedStore/modules/locations/locations.selector';
import { getLocationName } from './breakevenPublishTable.helper';

function BreakevenPublish(): ReactElement {
  const itemsToPublish = useSelector(breakevenItemsToPublishSelector) ?? [];
  const publishing = useSelector(breakevenPricesPublishing);
  const prevPublishing = usePrevious(publishing);
  const locationDetails = useSelector(currentMultipleLocationDetailsSelector);

  const [published, setPublished] = useState<boolean>(false);

  useEffect(() => {
    if (prevPublishing === true && !publishing) {
      setPublished(true);
    }
  }, [publishing, prevPublishing]);

  return (
    <div>
      <SummaryTitle>Review Changes</SummaryTitle>
      <SummaryWrapper className="data-wrapper">
        {publishing && (
          <WorkingOverlay>
            <SummarySubTitle>Working...</SummarySubTitle>
          </WorkingOverlay>
        )}

        {isEmpty(itemsToPublish) && published && (
          <SummarySubTitle className="success">
            Successfully published breakeven prices.
          </SummarySubTitle>
        )}
        {isEmpty(itemsToPublish) && !published && (
          <SummarySubTitle>No summary to Display</SummarySubTitle>
        )}
        {!isEmpty(itemsToPublish) && (
          <>
            <TitleBar>
              <SummarySubTitle>
                {`${pluralize(itemsToPublish?.length ?? 0, 'change')} will be published`}
              </SummarySubTitle>
              <SummaryNote>
                Note: <b>Changes</b> are highlighted in blue
              </SummaryNote>
            </TitleBar>
            <LocationsChangesSummaries>
              {itemsToPublish &&
                [...itemsToPublish].map(eachItem => (
                  <EachLocationSummary key={uuidV4()} className="each-location-breakeven">
                    <SummarySubTitle>
                      {getLocationName(eachItem.location.id, locationDetails)}
                    </SummarySubTitle>
                    <BreakevenPublishTable item={eachItem} />
                  </EachLocationSummary>
                ))}
            </LocationsChangesSummaries>
          </>
        )}
      </SummaryWrapper>
    </div>
  );
}

export default BreakevenPublish;
