import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Chip, DatePicker } from '@wework/dieter-ui';
import { Button } from '@wework/ray2';
import { Popup } from 'semantic-ui-react';
import {
  addDays,
  addMonths,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  startOfToday,
  subDays,
} from 'date-fns';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { utcToZonedTime } from 'date-fns-tz';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get, isEmpty } from 'lodash';
import {
  ActionsBox,
  ActionSubTitle,
  BottomPanelWrapper,
} from '../../../../styles/sharedBottomPanel.styled';
import {
  discountItemsOverrides,
  publishDiscountsPublishSaveSelectorStore,
} from '../../store/modules/discountItems/discountItems.selector';
import EmployeePermissions from '../../../../utils/store/permissions';
import { AuthzProps } from '../../../../utils/constants';
import PublishDiscountsConfirm from './confirmationPopup/publishDiscountsConfirm';

function BottomPanelDiscountPublish({ requestedPermissions }: AuthzProps): ReactElement {
  // LOCAL VARIABLES
  const [publishDateOpen, setPublishDatePickerOpen] = useState<boolean>(false);
  const [expirationDateOpen, setExpirationDatePickerOpen] = useState<boolean>(false);
  const [publishDate, setPublishDate] = useState<Date | undefined>(
    startOfMonth(addMonths(startOfToday(), 1)),
  );
  const [expirationDate, setExpirationDate] = useState<Date | undefined>();
  const [publishConfirmationModalOpen, setPublishConfirmationModalOpen] = useState(false);
  const discountOverrides = useSelector(discountItemsOverrides);
  const loading = useSelector(publishDiscountsPublishSaveSelectorStore);

  const utcDate = Date.now();
  const timeZones = discountOverrides.map(o => o.location.timeZone);
  const publishDateDisabledBefore = [...new Set(timeZones)]
    .map(timeZone => utcToZonedTime(utcDate, timeZone as string))
    .reduce((time1, time2) => (time1 > time2 ? time1 : time2), new Date());

  publishDateDisabledBefore.setHours(0, 0, 0, 0);

  const publishDateDisabledAfter = expirationDate && subDays(expirationDate, 1);
  const isPublishDateInRange = (date: Date) =>
    !isBefore(date, publishDateDisabledBefore) &&
    !(publishDateDisabledAfter && isAfter(date, publishDateDisabledAfter));

  const expirationDateDisabledBefore = addDays(publishDate || publishDateDisabledBefore, 1);
  const isExpirationDateInRange = (date: Date) => !isBefore(date, expirationDateDisabledBefore);
  const itemsToPublish = expirationDate ? 'Overrides' : 'Base discounts';

  /**
   * Date picker triggers Publish and Expiration dates.
   */
  function publishDatePickerTrigger(): ReactElement {
    return (
      <Chip
        className={cn({ active: !!publishDate }, 'date-picker-trigger')}
        active={!!publishDate}
        onClick={() => setPublishDatePickerOpen(prevState => !prevState)}
      >
        {publishDate ? format(publishDate, 'MMM d, yyyy') : 'Select Date'}
        <i />
      </Chip>
    );
  }

  function expirationDatePickerTrigger(): ReactElement {
    return (
      <Chip
        className={cn({ active: !!expirationDate }, 'date-picker-trigger')}
        active={!!expirationDate}
        onClick={() => setExpirationDatePickerOpen(prevState => !prevState)}
      >
        {expirationDate ? format(expirationDate, 'MMM d, yyyy') : 'Select Date'}
        <i />
      </Chip>
    );
  }

  return (
    <BottomPanelWrapper>
      <ActionsBox className="action-box-triple">
        <ActionSubTitle>Expiration Date:</ActionSubTitle>
        <Popup
          basic
          position="top left"
          trigger={expirationDatePickerTrigger()}
          on="click"
          className="publish-data-popup"
          open={expirationDateOpen}
          onClose={() => setExpirationDatePickerOpen(prevState => !prevState)}
          closeOnDocumentClick
          closeOnEscape
        >
          <Popup.Content>
            <DatePicker
              selectedDays={expirationDate}
              onDayClick={(day, { selected }) => {
                if (isExpirationDateInRange(day)) {
                  setExpirationDate(selected ? undefined : day);
                  setExpirationDatePickerOpen(false);
                }
              }}
              disabledDays={{ before: expirationDateDisabledBefore }}
            />
          </Popup.Content>
        </Popup>
        <ActionSubTitle>Published Date:</ActionSubTitle>
        <Popup
          basic
          position="top left"
          trigger={publishDatePickerTrigger()}
          on="click"
          className="publish-data-popup"
          open={publishDateOpen}
          onClose={() => setPublishDatePickerOpen(prevState => !prevState)}
          closeOnDocumentClick
          closeOnEscape
        >
          <Popup.Content>
            <DatePicker
              selectedDays={publishDate}
              onDayClick={(day, { selected }) => {
                if (isPublishDateInRange(day)) {
                  setPublishDate(selected ? undefined : day);
                  setPublishDatePickerOpen(false);
                }
              }}
              disabledDays={{
                before: publishDateDisabledBefore,
                after: publishDateDisabledAfter,
              }}
            />
          </Popup.Content>
        </Popup>
        {publishDate
          ? `${itemsToPublish} will publish on ${format(publishDate, 'MMM d, yyyy')}.`
          : `${itemsToPublish} will publish immediately.`}
        <Button
          disabled={
            !get(
              requestedPermissions,
              EmployeePermissions.voyager_dedicated_space_discount_publish,
              false,
            ) || isEmpty(discountOverrides)
          }
          loading={loading}
          theme={'fill'}
          size={'medium'}
          onClick={() => setPublishConfirmationModalOpen(true)}
        >
          Publish
        </Button>
      </ActionsBox>
      <PublishDiscountsConfirm
        closeModal={() => setPublishConfirmationModalOpen(false)}
        isOpen={publishConfirmationModalOpen}
        publishDate={publishDate}
        expirationDate={expirationDate}
      />
    </BottomPanelWrapper>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_discount_publish],
})(BottomPanelDiscountPublish);
