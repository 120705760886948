import { StoreInterface } from '../../../utils/store/store.interface';
import { GenerateLinkSubset } from '../../../app/adminPanel/store/entities/generateLink';

const generatedLinkSelector = (state: StoreInterface): GenerateLinkSubset => state?.generateLink;

export const generatedUrlSelector = (state: StoreInterface): string | null =>
  generatedLinkSelector(state)?.generatedUrl;

export const generateUrlLoadingSelector = (state: StoreInterface): boolean =>
  generatedLinkSelector(state)?.generateUrlLoading;
