import { useCallback, useEffect, useRef } from 'react';
import { debounce, filter, find, forEach, isEqual, isNil, reject } from 'lodash';
import { push } from 'connected-react-router';
import { routerHistory } from '../../../../utils/store/configureStore';
import store from '../../../../index';
import { FormattedValue } from '../store/entities/priceableItems';
import { nonPersonalOfficeProductType } from './constants';
import { Unassigned } from '../../../../utils/sharedTypes';
import { StandardPricingTableViewItem } from '../store/entities/standardPricingItems';
import { intlCurrencyFormatter } from '../../../../utils/helpers';
import { PriceableItemFilter } from '../../../../sharedStore/entities/priceableItemFilters';

/**
 * Helper function to convert number to  2 digit precision.
 */
export const roundToAtMostNDigit = (num: number, digits: number): number => {
  const powOf10 = 10 ** digits;
  return isNaN(num) ? 0 : Math.round(num * powOf10) / powOf10;
};

// TODO: Use this in other rounding places.
export const roundToAtMost2Digit = (num: number): number => roundToAtMostNDigit(num, 2);

export const roundToInteger = (num: number): number => roundToAtMostNDigit(num, 0);

export const roundToIntegerReturnZero = (
  num: number | null | undefined,
  digits: number = 0,
): number => (num ? roundToAtMostNDigit(num, digits) : 0);

export const parseFloatSafe = (stringToParse: string): number | undefined => {
  const parsed = parseFloat(stringToParse);
  return isNaN(parsed) ? undefined : parsed;
};

export const withSign = (num: number): string => {
  if (isNaN(num)) {
    return '0';
  }

  return num > 0 ? `+${num}` : num.toString();
};

const toPercentage = (num: number | Unassigned): string | undefined => {
  if (!isNil(num) && !isNaN(num)) {
    const percent = num * 100;
    return `${percent.toFixed(0)}%`;
  }

  return undefined;
};
export const isPercentageNull = (num: number | Unassigned): string => toPercentage(num) ?? '-';

export const isNumberNullFormat = (currencyIsoCode: string, num: number | Unassigned): string => {
  if (typeof num === 'number') {
    return intlCurrencyFormatter(currencyIsoCode, num);
  }

  return '-';
};

export const calculateListPrices = (price: number, capacity: string): number => {
  if (!price || !capacity) {
    return 0;
  }
  const capacityNbr: number = !isNaN(parseFloat(capacity)) ? parseFloat(capacity) : 0;
  if (capacityNbr === 0) {
    return price;
  }
  return price * capacityNbr;
};

export const calculateAdjByPublishPrices = (
  currentPrice: number,
  workingPrice: number,
): FormattedValue => {
  if (!workingPrice || !currentPrice) {
    return {
      value: 0,
      formatted: '-',
    };
  }

  const workingPriceAfterRound = roundToAtMost2Digit(workingPrice);
  const currentPriceAfterRound = roundToAtMost2Digit(currentPrice);
  const percent = ((workingPriceAfterRound - currentPriceAfterRound) / currentPrice) * 100;
  return {
    value: percent,
    formatted: `${percent.toFixed(0)}% / ${(workingPrice - currentPrice).toFixed(0)}`,
  };
};

export const calculateLowestNetPrice = (
  price: number,
  maxDiscount: number | Unassigned,
): { value: number; formatted: string } => {
  if (!price || !maxDiscount) {
    return {
      value: 0,
      formatted: '-',
    };
  }

  const value = price * (1 - maxDiscount);
  return {
    value,
    formatted: `${value.toFixed(0)}`,
  };
};

export function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y,
): obj is X & Record<Y, unknown> {
  return !!obj?.hasOwnProperty(prop);
}

function objectPropertyTypeString(object: any | Unassigned, propertyName: string): Boolean {
  return (
    typeof object === 'object' &&
    hasOwnProperty(object, propertyName) &&
    typeof object[propertyName] === 'string'
  );
}
export function getStringOfProperty(object: any | Unassigned, propertyName: string): string {
  if (objectPropertyTypeString(object, propertyName)) {
    return object[propertyName];
  } else if (typeof object === 'object' && object?.hasOwnProperty(propertyName)) {
    return object[propertyName].toString();
  }
  return '';
}

/**
 * This function is used for removing or adding new search query parameters or modifying the already existing ones.
 *
 * @param name Name of the search query parameter
 * @param value Value of the search query parameter.
 */
export const addSearchQueryOtherParams = (name: string, value: string | Unassigned): void => {
  // 1) Obtain  the already existing search param in the route.
  const searchParam = new URLSearchParams(routerHistory?.location?.search);
  // 2) Modify the search Param
  // If trying to set empty value then deleting the search param, else we are setting the new value and removing others.
  if (!isEqual(searchParam.get(name), value) && value) {
    searchParam.set(name, value);
  } else if (!value) {
    searchParam.delete(name);
  } else {
    return;
  }
  // 3) Replace the old search param with new one.
  store.dispatch(
    push({
      search: `?${searchParam.toString()}`,
    }),
  );
};

export const usePrevious = (value: any | undefined): any => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

/**
 * Creating Debounced hook for being used on input change
 * https://www.freecodecamp.org/news/debounce-and-throttle-in-react-with-hooks/
 *
 * @param callback
 * @param delay
 */
export function useDebounce(callback: Function, delay: number): any {
  // Memoization of a call back function to be reused on each change call.
  return useCallback(
    debounce((...args: any) => callback(...args), delay), // the action debounce function
    [delay], // will recreate if delay changes
  );
}

export const capitalizeFunc = (txt: string): string =>
  txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();

export const toTitleCase = (str: string | Unassigned): string => {
  const st = str ? str.split('@')[0].replace('.', ' ') : '';
  return st.replace(/\w\S*/g, capitalizeFunc);
};

export const inputNumberFormatter = (value: string): string =>
  // Remove anything that isn't valid in a number
  value
    .replace(/[^\d-.]/g, '')
    // Remove all dashes unless it is the first character
    .replace(/(?!^)-/g, '')
    // Remove all periods unless it is the first one
    .replace(/^([^.]*\.)(.*)$/, (_a: string, b: string, c: string) => b + c.replace(/\./g, ''));

export const inputNumberFormatterNoNegative = (value: string): string =>
  // Remove anything that isn't valid in a number
  value
    .replace(/[^\d.]/g, '')
    // Remove all dashes unless it is the first character
    .replace(/(?!^)-/g, '')
    // Remove all periods unless it is the first one
    .replace(/^([^.]*\.)(.*)$/, (_a: string, b: string, c: string) => b + c.replace(/\./g, ''));

export const standardPricingFilterHelperSelected = (
  objArr: StandardPricingTableViewItem[],
  filterParamObj: PriceableItemFilter | Unassigned,
  propertyName: string,
  propertyValue: any,
): StandardPricingTableViewItem[] =>
  filterParamObj
    ? filter(objArr, eachObj => {
        let propertyVal = find(eachObj.attributes, ['label', propertyName])?.value;
        // TODO: Doing a hack here. Need to fix it.
        if (propertyName === 'hasWindow') {
          propertyVal = !propertyVal || propertyVal === 'null' ? 'false' : propertyVal;
        }
        if (propertyName === 'internalRoomCount') {
          propertyVal = propertyVal !== 'null' && propertyVal !== '0' ? 'true' : 'false';
        }
        return propertyVal === propertyValue;
      })
    : objArr;

/**
 * -- GRID FILTERS
 */
export const standardPricingFilterHelperRange = (
  objArr: StandardPricingTableViewItem[],
  filterParamObj: PriceableItemFilter | Unassigned,
  propertyName: string,
  percentValue: boolean,
): StandardPricingTableViewItem[] =>
  filterParamObj
    ? filter(objArr, (obj: StandardPricingTableViewItem) => {
        let propertyVal;
        if (propertyName === 'locationSkuOccupancy') {
          const propertyValStr = obj.locationSkuOccupancy;
          propertyVal = Number(propertyValStr?.slice(0, -1)) ?? 0;
        } else {
          const propertyValStr = find(obj.attributes, ['label', propertyName])?.value;
          propertyVal = Number(propertyValStr) ?? 0;
        }
        propertyVal = Number.isNaN(propertyVal) ? 0 : propertyVal;

        const minVal: number | Unassigned = percentValue
          ? Number(filterParamObj?.min) / 100
          : filterParamObj?.min;
        const maxVal: number | Unassigned = percentValue
          ? Number(filterParamObj?.max) / 100
          : filterParamObj?.max;

        return propertyVal !== null
          ? propertyVal >= (minVal ?? Number.NEGATIVE_INFINITY) &&
              propertyVal <= (maxVal ?? Number.MAX_VALUE)
          : true;
      })
    : objArr;

export const standardPricingFilterHelperObjectArrayDiscount = (
  objArr: StandardPricingTableViewItem[],
  filterOptions: PriceableItemFilter[],
): StandardPricingTableViewItem[] => {
  let returnedArr: StandardPricingTableViewItem[];

  // Filter for SKU:
  // 1) Need to do this manually as it is a multiple option select.
  // 2) Need to do it first as it affects the rest of the filter.
  const HDFilterObject = find(filterOptions, ['filterName', 'hotDeskSelected']);
  const DDFilterObject = find(filterOptions, ['filterName', 'dedicatedDeskSelected']);
  const POFilterObject = find(filterOptions, ['filterName', 'personalOfficeSelected']);

  const EmptyPriceFilterObject = find(filterOptions, ['filterName', 'emptyPriceSelected']);

  returnedArr = EmptyPriceFilterObject
    ? filter(objArr, eachObj => !eachObj.currentPrice?.price)
    : objArr;

  // If none of the sku filter exist then return original value.
  if (HDFilterObject || DDFilterObject || POFilterObject) {
    // Filtering hot desk if filter param present.
    const hotDeskArr = HDFilterObject
      ? standardPricingFilterHelperSelected(
          returnedArr,
          HDFilterObject,
          'productType',
          nonPersonalOfficeProductType.hotDesk,
        )
      : [];
    // Filtering dedicated desk if filter param present.
    const dedicatedDeskArr = DDFilterObject
      ? standardPricingFilterHelperSelected(
          returnedArr,
          DDFilterObject,
          'productType',
          nonPersonalOfficeProductType.dedicatedDesk,
        )
      : [];
    // Filter personal office if filter param present.
    let personalOfficeArr: StandardPricingTableViewItem[] = [];
    if (POFilterObject) {
      personalOfficeArr = reject(returnedArr, eachObj => {
        const productType = find(eachObj.attributes, ['label', 'productType'])?.value;
        return (
          productType === nonPersonalOfficeProductType.hotDesk ||
          productType === nonPersonalOfficeProductType.dedicatedDesk
        );
      });
      personalOfficeArr = standardPricingFilterHelperRange(
        personalOfficeArr,
        POFilterObject,
        'capacity',
        false,
      );
    }

    returnedArr = hotDeskArr.concat(dedicatedDeskArr).concat(personalOfficeArr);
  }

  forEach(filterOptions, (filterObject: PriceableItemFilter) => {
    switch (filterObject.filterName) {
      case 'internalRoom':
        returnedArr = standardPricingFilterHelperSelected(
          returnedArr,
          filterObject,
          'internalRoomCount',
          filterObject?.isSelected?.toString(),
        );
        returnedArr = filterObject?.isSelected
          ? standardPricingFilterHelperRange(returnedArr, filterObject, 'internalRoomCount', false)
          : returnedArr;
        break;
      case 'hasWindow':
        returnedArr = standardPricingFilterHelperSelected(
          returnedArr,
          filterObject,
          'hasWindow',
          filterObject?.isSelected?.toString(),
        );
        break;
      case 'sku':
        returnedArr = standardPricingFilterHelperRange(
          returnedArr,
          filterObject,
          'locationSkuOccupancy',
          false,
        );
        break;
      default:
        break;
    }
  });

  return returnedArr;
};
