import { get } from 'lodash';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import AppConfig from 'config';
import {
  UPLOAD_FILE,
  UPLOAD_FILE_ERROR,
  UPLOAD_FILE_FORMAT_ERROR,
  UPLOAD_FILE_SUCCESS,
} from 'sharedStore/modules/uploadFile/uploadFile.ducks';
import {
  publishFileExpirationDateSelector,
  publishFileIsARPMPriceSelector,
  publishFileLoadedFileSelector,
  publishFilePublishDateSelector,
  publishFileReasonSelector,
} from 'sharedStore/modules/uploadFile/uploadFile.selector';
import { errorHandlerActive } from 'utils/errorHandling/helpers';
import { getAuthToken } from 'index';
import currentPathNameSelector from 'sharedStore/modules/router/router.selector';
import ROUTES from 'utils/routes/routes';

export const uploadFile = (
  loadedFile: File,
  reason: string | null,
  isARPMPrice: boolean,
  publishDate: string | null,
  expirationDate: string | null,
  pathname: string,
): Promise<AxiosResponse<any> | undefined | string> => {
  const fromData = new FormData();
  fromData.append('file', loadedFile);
  if (reason && pathname !== ROUTES.UPLOAD_DISCOUNTS_FROM_FILE) {
    fromData.append('reason', reason);
  }
  if (publishDate) {
    fromData.append('validFrom', publishDate);
  }
  if (expirationDate) {
    fromData.append('expirationDate', expirationDate);
  }
  fromData.append('isARPMPrice', String(isARPMPrice));

  const url =
    pathname === ROUTES.UPLOAD_DISCOUNTS_FROM_FILE
      ? `${AppConfig.BACKEND_REST_URL}/upload-discounts`
      : `${AppConfig.BACKEND_REST_URL}/upload-prices`;

  return axios
    .post(url, fromData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: `Bearer ${getAuthToken()}`,
      },
    })
    .catch(error => error.response);
};

// Saga - to handle upload file.
export function* onUploadFileWorker() {
  try {
    const loadedFile: File = yield select(publishFileLoadedFileSelector);
    if (loadedFile) {
      const reason: string = yield select(publishFileReasonSelector);
      const isARPMPrice: boolean = yield select(publishFileIsARPMPriceSelector);
      const publishDate: Date | null = yield select(publishFilePublishDateSelector);
      const expirationDate: Date | null = yield select(publishFileExpirationDateSelector);
      const { pathname } = yield select(currentPathNameSelector);
      const formattedPublishDate = publishDate ? format(publishDate, 'yyyy-MM-dd') : null;
      const formattedExpirationDate = expirationDate ? format(expirationDate, 'yyyy-MM-dd') : null;
      const response: AxiosResponse | undefined = yield call(
        uploadFile,
        loadedFile,
        reason,
        isARPMPrice,
        formattedPublishDate,
        formattedExpirationDate,
        pathname,
      );
      if (response?.status === 200) {
        yield put({ type: UPLOAD_FILE_SUCCESS });
        toast.success('Validation successful! This file was uploaded with no errors found.');
      } else if (response?.status === 400) {
        if (response.data.message) {
          toast.error(`File upload error`);
          yield put({ type: UPLOAD_FILE_FORMAT_ERROR, payload: response.data.message });
        } else {
          toast.error(`File upload error`);
          yield put({ type: UPLOAD_FILE_ERROR, payload: response.data });
        }
      } else {
        toast.error(`File upload error - ${get(response, 'message', 'Unknown Error')}`);
        errorHandlerActive(new Error(response?.toString()));
      }
    }
  } catch (e: any) {
    toast.error(`File upload error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export function* uploadFileSaga(): any {
  yield all([takeLatest(UPLOAD_FILE, onUploadFileWorker)]);
}
