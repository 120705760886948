import { find, get, isNil } from 'lodash';
import { getBooleanValue } from '../breakevenTable/breakevenTable.helper';
import { roundToInteger } from '../../../pricing/standardPricing/components/helpers';
import { intlCurrencyFormatter, intlNumberFormatter } from '../../../../utils/helpers';
import { Location, LocationBreakEvenDetail } from '../../../../generated/voyager/graphql';

export const getOverrideValue = (item: LocationBreakEvenDetail, fieldName: string): any =>
  get(item.override, fieldName) ?? get(item.liveBudgetApplied, fieldName);

export const getWorkingValue = (item: LocationBreakEvenDetail, fieldName: string): any =>
  getOverrideValue(item, fieldName) ?? get(item.current, fieldName);

export const getOverrideValueOrDash = (item: LocationBreakEvenDetail, fieldName: string): any => {
  const overrideValue = getOverrideValue(item, fieldName);
  return !isNil(overrideValue) ? intlNumberFormatter(roundToInteger(overrideValue)) : '-';
};

export const getOverrideValueOrDashNumber = (
  item: LocationBreakEvenDetail,
  fieldName: string,
): string => {
  const overrideValue = getOverrideValue(item, fieldName);
  return !isNil(overrideValue)
    ? intlCurrencyFormatter(item.location.currencyIsoCode, roundToInteger(overrideValue))
    : '-';
};

export const getCurrentValue = (item: LocationBreakEvenDetail, fieldName: string): any =>
  get(item.current, fieldName);

export const getCurrentValueOrDash = (item: LocationBreakEvenDetail, fieldName: string): any => {
  const currentValue = getCurrentValue(item, fieldName);
  return !isNil(currentValue) ? intlNumberFormatter(roundToInteger(currentValue)) : '-';
};

export const getCurrentValueOrDashNumber = (
  item: LocationBreakEvenDetail,
  fieldName: string,
): string => {
  const currentValue = getCurrentValue(item, fieldName);
  return !isNil(currentValue)
    ? intlCurrencyFormatter(item.location.currencyIsoCode, roundToInteger(currentValue))
    : '-';
};

export const getBooleanOverrideValue = (
  item: LocationBreakEvenDetail,
  fieldName: string,
): string => {
  const value = getOverrideValue(item, fieldName);
  return getBooleanValue(value);
};

export const getBooleanCurrentValue = (
  item: LocationBreakEvenDetail,
  fieldName: string,
): string => {
  const value = get(item.current, fieldName);
  return getBooleanValue(value);
};

export const getOverrideSource = (item: LocationBreakEvenDetail, fieldName: string): string => {
  if (!isNil(get(item.override, fieldName))) {
    return 'Override';
  } else if (!isNil(get(item.liveBudgetApplied, fieldName))) {
    return 'Live Budget';
  }
  return '';
};

export const getLocationName = (location: string, locationDetails: Location[]): string => {
  const locDetails: Location = find(locationDetails, ['id', location]) ?? ({} as Location);
  return `${locDetails.name}
    ${locDetails.market.name}, ${locDetails.market.territory.name},
    ${locDetails.market.territory.region.name}`;
};
