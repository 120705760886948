import * as React from 'react';
import { ReactElement } from 'react';
import { PanelSectionList } from '../detailSidePanel.styled';
import DetailItemHeaderLocation, { LocationDetailItemType } from './detailItemHeaderLocation';
import { Location } from '../../../generated/voyager/graphql';

interface DetailSidePanelLocationProps {
  data: Location;
}

function DetailSidePanelLocation({ data }: DetailSidePanelLocationProps): ReactElement {
  return (
    <PanelSectionList>
      <DetailItemHeaderLocation
        detailLabel={'Desks'}
        data={data}
        type={LocationDetailItemType.desks}
      />
      <DetailItemHeaderLocation
        detailLabel={'Occupancy'}
        data={data}
        type={LocationDetailItemType.occupancy}
      />
      <DetailItemHeaderLocation
        detailLabel={'Financial Health'}
        data={data}
        type={LocationDetailItemType.financial}
      />
    </PanelSectionList>
  );
}

export default DetailSidePanelLocation;
