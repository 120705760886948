import * as React from 'react';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { Button, ButtonSize, ButtonTheme } from '@wework/ray2';
import { trackAnalytics } from '../../utils/analytics/helpers';
import { AppDescription, ApplicationCard, AppTitle } from './applicationCard.styled';
import { routerHistory } from '../../utils/store/configureStore';

interface ItemButtonInfo {
  label: string;
  route: string;
}

function ApplicationCardComponent(
  iconClass: string,
  disabled: boolean,
  title: string,
  subtitle: string,
  description: string,
  buttons: ItemButtonInfo[],
): ReactElement {
  const [mainButton] = buttons;
  const additionalButtons = buttons.splice(1);

  const getLinkButton = (attr: ItemButtonInfo, additionalBtns: ItemButtonInfo[]) => (
    <div className="card-button">
      <NavLink
        to={{ pathname: attr.route }}
        onClick={() =>
          trackAnalytics('Homepage Application Card Clicked', {
            workflow: 'Homepage Navigation',
            object_type: 'link',
            object_name: iconClass,
            object_value: attr.route,
          })
        }
      >
        <Button size={ButtonSize.SMALL}>Manage</Button>
      </NavLink>
      {additionalBtns.length > 0 &&
        additionalBtns.map(addtnlButton => (
          <Button
            key={addtnlButton.route}
            className="card-additional-button"
            size={ButtonSize.SMALL}
            theme={ButtonTheme.OUTLINE}
            onClick={() => reRouteHelper(addtnlButton)}
          >
            {addtnlButton.label}
          </Button>
        ))}
    </div>
  );

  const reRouteHelper = (attr: ItemButtonInfo) => {
    trackAnalytics('Homepage Application Card Clicked', {
      workflow: 'Homepage Navigation',
      object_type: 'link',
      object_name: iconClass,
      object_value: attr.route,
    });
    routerHistory.push({
      pathname: attr.route,
    });
  };

  return (
    <ApplicationCard className={cn(iconClass, { disabled })}>
      <AppTitle>{`${title} ${subtitle}`}</AppTitle>
      <AppDescription>{description}</AppDescription>
      {getLinkButton(mainButton, additionalButtons)}
    </ApplicationCard>
  );
}

export default ApplicationCardComponent;
