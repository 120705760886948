import React, { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@wework/ray2';
import { isEmpty } from 'lodash';
import { INVENTORY_RECONCILIATION } from 'app/adminPanel/store/modules/inventoryReconciliation/inventoryReconciliation.ducks';
import {
  inventoryReconciliationInventoryIdsSelector,
  inventoryReconciliationIsInventoryIdsValidSelector,
  inventoryReconciliationIsLoadingSelector,
} from 'app/adminPanel/store/modules/inventoryReconciliation/inventoryReconciliation.selector';
import { ActionsBox, BottomPanelWrapper } from '../../../../styles/sharedBottomPanel.styled';
import runningJobSelector from '../../store/modules/jobStatus/jobStaus.selector';
import AnotherJobIsRunningTooltip from '../jobStatus/anotherJobIsRunningTooltip';

function BottomPanelInventoryReconciliation(): ReactElement {
  const isLoading = useSelector(inventoryReconciliationIsLoadingSelector);
  const selectedInventoryIds = useSelector(inventoryReconciliationInventoryIdsSelector);
  const isInventoryIdsValid = useSelector(inventoryReconciliationIsInventoryIdsValidSelector);
  const job = useSelector(runningJobSelector);
  const isDisabled = isEmpty(selectedInventoryIds) || !isInventoryIdsValid || job != null;

  const dispatch = useDispatch();
  const inventoryReconciliationHandler = useCallback(
    () => dispatch({ type: INVENTORY_RECONCILIATION }),
    [dispatch],
  );

  return (
    <BottomPanelWrapper className="publish-panel">
      <ActionsBox />
      <ActionsBox className="action-box-right">
        <AnotherJobIsRunningTooltip job={job} />
        <Button
          theme={'fill'}
          size={'medium'}
          loading={isLoading}
          disabled={isDisabled}
          onClick={inventoryReconciliationHandler}
        >
          Reconcile
        </Button>
      </ActionsBox>
    </BottomPanelWrapper>
  );
}

export default BottomPanelInventoryReconciliation;
