import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import {
  PriceHistoryContainer,
  PriceHistoryList,
  PriceHistoryListItem,
  PriceHistoryProductContainer,
} from '../onDemandPricing.styled';
import { formatDate, intlCurrencyFormatter } from '../../../../../utils/helpers';
import { toTitleCase } from '../../../standardPricing/components/helpers';
import {
  SectionBody,
  SectionHeader,
} from '../../../../../sharedComponents/sidePanelComponent/detailSidePanel.styled';
import { PriceHierarchy, Product } from '../../../../../generated/voyager/graphql';

interface PriceHistoryBlockComponentProps {
  product: Product;
  prices: PriceHierarchy[];
  timeZone?: string;
}

const PriceHistoryBlockComponent = ({
  product,
  prices,
  timeZone,
}: PriceHistoryBlockComponentProps): ReactElement => {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);
  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );
  const historyItems = prices
    .filter(priceItem => priceItem.productId === product.id)
    .map(priceItem => (
      <PriceHistoryListItem key={priceItem.id}>
        {priceItem.price ? intlCurrencyFormatter(priceItem.currencyIsoCode, priceItem.price) : '-'}{' '}
        on {formatDate(priceItem.validFrom, timeZone)} by
        {` ${toTitleCase(priceItem.createdBy)}`}
      </PriceHistoryListItem>
    ));

  return (
    <PriceHistoryContainer className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <SectionHeader className="section-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{product.name} Price History</span>
          <i className="toggle-accordion-icon" />
        </button>
      </SectionHeader>
      <SectionBody className="section-body">
        <PriceHistoryProductContainer>
          <PriceHistoryList as="ul">
            {historyItems && !isEmpty(historyItems) ? historyItems : <div>No Change History</div>}
          </PriceHistoryList>
        </PriceHistoryProductContainer>
      </SectionBody>
    </PriceHistoryContainer>
  );
};
export default PriceHistoryBlockComponent;
