import { ProcessCellForExportParams, ShouldRowBeSkippedParams } from 'ag-grid-community';
import { ProcessHeaderForExportParams } from 'ag-grid-community/dist/lib/interfaces/exportParams';
import { formatDate, processHeaderCallback } from '../../../../utils/helpers';
import { roundToAtMost2Digit } from '../../standardPricing/components/helpers';
import { PriceHierarchy, Product } from '../../../../generated/voyager/graphql';

const dynamicColumns = (products: Product[]): string[][] =>
  products.map(product => [`${product.id}|Current Prices`, `${product.id}|Published At`]);

const getPriceFromObject = (priceArray: any[], productId: string) =>
  priceArray?.find(price => price.productId === productId)?.price;

// BC: The data in the grid is tree data so skipRowGroups does not work in here.
const shouldRowBeSkipped = (params: ShouldRowBeSkippedParams): boolean =>
  params?.node.data.type !== 'Building';

const onDemandTableDownloadConfig = (products: Product[]) => ({
  skipRowGroups: true,
  skipColumnGroupHeaders: true,
  columnKeys: [
    'selectionCheckbox',
    'name',
    'timeZone',
    'currencyIsoCode',
    ...dynamicColumns(products).flat(),
  ],
  processCellCallback: (params: ProcessCellForExportParams) => {
    // Location Id
    const colId = params.column.getColId();
    if (colId === 'selectionCheckbox') {
      return params.node?.data.id;
    } else if (colId.split('|')[1] === 'Published At') {
      const productId = colId.split('|')[0];
      const value =
        params.node?.data.currentPrices.find(
          (price: PriceHierarchy) => price.productId === productId,
        )?.validFrom ?? null;
      return value ? formatDate(value, params.node?.data.timeZone ?? 'UTC') : '';
    } else if (colId.split('|')[1] === 'Current Prices') {
      const productId = colId.split('|')[0];
      let value = getPriceFromObject(params.node?.data.currentPrices, productId) ?? null;
      if (!value) {
        value = getPriceFromObject(params.node?.data.pricePreviews, productId) ?? null;
      }
      return value ? roundToAtMost2Digit(Number(value)) : '';
    }
    return params.value;
  },
  processHeaderCallback: (params: ProcessHeaderForExportParams) =>
    processHeaderCallback(params, products),
  shouldRowBeSkipped,
});

export default onDemandTableDownloadConfig;
