import { gql } from '@apollo/client';

// GraphQL Queries
// Query to fetch all markets by selected territory.
const FETCH_MARKETS_BY_TERRITORY_QUERY = gql`
  query MarketsByTerritory($territoryId: ID!) {
    marketsByTerritory(territoryId: $territoryId) {
      id
      name
    }
  }
`;

export default FETCH_MARKETS_BY_TERRITORY_QUERY;
