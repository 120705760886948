import { find } from 'lodash';
import { PublishPriceTableItem } from '../../store/entities/publishPrice';
import { calculateAdjByPublishPrices, calculateListPrices, roundToAtMost2Digit } from '../helpers';
import { groupBy } from '../../../../../utils/helpers';
import { WorkingPricePreview } from '../../../../../generated/voyager/graphql';

const transformPublishPricesItemToTableView = (
  workingPricePreview: WorkingPricePreview,
): PublishPriceTableItem => {
  const priceableItem = workingPricePreview.priceableItem;
  const attributesArr = priceableItem.attributes;
  const currentPrice = priceableItem.currentPrice?.price ?? 0;
  const workingPrice = priceableItem.workingPrice?.price ?? 0;

  const locationName = `
    ${priceableItem.location.name} 
    ${priceableItem.location.market.name}, ${priceableItem.location.market.territory.name}, 
    ${priceableItem.location.market.territory.region.name}
  `;

  const adjustSuggestedPrice = calculateAdjByPublishPrices(currentPrice, workingPrice).formatted;
  const capacity = `${find(attributesArr, ['label', 'capacity'])?.value}`;
  const spatialWorkUnits = `${find(attributesArr, ['label', 'spatialWorkUnits'])?.value}`;
  const priceFactor =
    spatialWorkUnits && Number(spatialWorkUnits) > 0 ? spatialWorkUnits : capacity ?? 0;

  const currentListPrice = calculateListPrices(currentPrice, priceFactor);
  const workingListPrice = calculateListPrices(workingPrice, priceFactor);
  const workingListPriceRounded = workingPricePreview.roundedListPrice ?? 0;

  return {
    id: priceableItem.id,
    locationId: `${priceableItem.location.id}`,
    locationName,
    officeName: priceableItem.name,
    sku: `${find(attributesArr, ['label', 'sku'])?.value}`,
    capacity: capacity ? Number(capacity) : 0,
    currentPrice: roundToAtMost2Digit(currentPrice),
    currentListPrice: roundToAtMost2Digit(currentListPrice),
    adjustSuggestedPrice,
    workingPrice: roundToAtMost2Digit(workingPrice),
    workingListPrice: roundToAtMost2Digit(workingListPrice),
    workingListPriceRounded: roundToAtMost2Digit(workingListPriceRounded),
    workingPriceCreatedBy: priceableItem.workingPrice?.createdBy ?? '',
    workingPriceReason: priceableItem.workingPrice?.lastChangeReason ?? '',
    currencyIsoCode: workingPricePreview?.currencyIsoCode ?? '',
  } as PublishPriceTableItem;
};

const transformPublishPricesItemToTableViewArr = (
  workingPrices: WorkingPricePreview[],
): Map<string, PublishPriceTableItem[]> => {
  const transformedData = workingPrices.map(workingPricePreview =>
    workingPricePreview
      ? transformPublishPricesItemToTableView(workingPricePreview)
      : ({} as PublishPriceTableItem),
  );

  return groupBy(transformedData, d => d.locationId);
};

export default transformPublishPricesItemToTableViewArr;
