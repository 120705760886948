import React, { useCallback, useEffect } from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AuthzProps } from 'utils/constants';
import ROUTES from 'utils/routes/routes';
import { MainRegionStyled, OverflowHack } from 'styles/app.styled';
import HeaderComponent from 'sharedComponents/header/header';
import { AdminPanelContentWrapper } from './adminPanelView.styled';
import {
  FETCH_SYNC_ATTEMPTS_WITH_FILTER,
  PRICE_SYNC_ATTEMPTS_SET_FILTER,
  PRICE_SYNC_ATTEMPTS_SET_PAGE_INFO,
  RE_PUBLISH_PRICE,
  SET_SELECTED_NODES_PRICE_RE_PUBLISH,
} from '../store/modules/priceSyncAttempts/priceSyncAttempts.ducks';
import {
  priceSyncAttemptIsLoadingSelector,
  priceSyncAttemptItemsSelector,
  priceSyncAttemptSelectedNodesSelector,
  priceSyncAttemptsFilterSelector,
  priceSyncAttemptsPageInfoSelector,
} from '../store/modules/priceSyncAttempts/priceSyncAttempts.selector';
import BottomPanelRePublish from '../components/bottomPanel/bottomPanelRePublish';
import EmployeePermissions from '../../../utils/store/permissions';
import SyncAttemptsTable from '../components/syncAttempts/syncAttemptsTable/SyncAttemptsAGGridTable';
import { priceGridColumnDefs } from '../components/syncAttempts/syncAttemptsTable/syncAttemptsTableAGGrid.settings';

const PriceSyncAttemptsView = ({ requestedPermissions }: AuthzProps) => {
  const dispatch = useDispatch();
  const filter = useSelector(priceSyncAttemptsFilterSelector);
  const pageInfo = useSelector(priceSyncAttemptsPageInfoSelector);

  const getGridData = useCallback(() => {
    dispatch({ type: FETCH_SYNC_ATTEMPTS_WITH_FILTER });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_system_admin, false)) {
      redirectToAccessDenied();
    } else {
      getGridData();
    }
  }, [getGridData, redirectToAccessDenied, requestedPermissions, filter, pageInfo]);

  const priceSyncAttemptsTableProps = {
    syncAttemptName: 'Price',
    allSyncAttempts: useSelector(priceSyncAttemptItemsSelector),
    pageInfoSelected: useSelector(priceSyncAttemptsPageInfoSelector),
    selectedNodes: useSelector(priceSyncAttemptSelectedNodesSelector),
    rePublishAction: SET_SELECTED_NODES_PRICE_RE_PUBLISH,
    pageInfoAction: PRICE_SYNC_ATTEMPTS_SET_PAGE_INFO,
    setFilterAction: PRICE_SYNC_ATTEMPTS_SET_FILTER,
    columnDefs: priceGridColumnDefs,
  };

  const bottomPanelRepublishProps = {
    syncAttemptName: 'Price',
    selectedNodes: useSelector(priceSyncAttemptSelectedNodesSelector),
    loadingSelector: useSelector(priceSyncAttemptIsLoadingSelector),
    selectedRePublishAction: SET_SELECTED_NODES_PRICE_RE_PUBLISH,
    rePublishAction: RE_PUBLISH_PRICE,
  };

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent title="Price Sync Attempts" />
          <AdminPanelContentWrapper>
            <SyncAttemptsTable {...priceSyncAttemptsTableProps} />
          </AdminPanelContentWrapper>
        </OverflowHack>
      </MainRegionStyled>
      <BottomPanelRePublish {...bottomPanelRepublishProps} />
    </>
  );
};

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_system_admin],
})(PriceSyncAttemptsView);
