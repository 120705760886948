import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Button } from '@wework/ray2';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsBox, BottomPanelWrapper } from '../../../../styles/sharedBottomPanel.styled';
import { breakevenItemsToPublishSelector } from '../../store/modules/breakevenItems/breakevenItems.selector';
import { GET_MARKET_SG_AND_A } from '../../store/modules/breakevenItems/breakevenItems.ducks';
import { routerHistory } from '../../../../utils/store/configureStore';
import ROUTES from '../../../../utils/routes/routes';
import { currentLocationsItemLoadState } from '../../../../sharedStore/modules/locations/locations.selector';
import { trackAnalytics } from '../../../../utils/analytics/helpers';
import { QueryMarketSgAndAArgs } from '../../../../generated/voyager/graphql';

function BottomPanelBreakevenComponent(): ReactElement {
  const locationDetailsLoadState = useSelector(currentLocationsItemLoadState);
  const itemsToPublish = useSelector(breakevenItemsToPublishSelector) ?? [];

  const dispatch = useDispatch();
  const getMarketSgAndA = useCallback(
    (payload: QueryMarketSgAndAArgs) => dispatch({ type: GET_MARKET_SG_AND_A, payload }),
    [dispatch],
  );

  const disabledPublishBtnMessage = (): string => {
    if (!itemsToPublish.length) {
      return 'No locations selected';
    } else if (!locationDetailsLoadState) {
      return 'Location Details not loaded';
    }
    return '';
  };

  function trackPublishButton() {
    trackAnalytics('Breakeven - Publish Button Click', {
      workflow: 'Breakeven Publish',
      object_type: 'button',
      object_name: 'Publish',
    });
  }

  const fetchMarketSGAAndRedirect = () => {
    trackPublishButton();
    getMarketSgAndA({
      marketIds: itemsToPublish.map(item => item.location.market.id),
      workingLocationIds: itemsToPublish.map(item => item.location.id),
    });
    routerHistory.push({
      pathname: ROUTES.BREAKEVEN_SUMMARY,
    });
  };

  return (
    <BottomPanelWrapper className="publish-panel">
      <ActionsBox />
      <ActionsBox className="action-box-triple">
        <span>{disabledPublishBtnMessage()}</span>
        <Button
          theme={'fill'}
          size={'medium'}
          className={'ml-sm'}
          onClick={() => fetchMarketSGAAndRedirect()}
          disabled={!itemsToPublish.length || !locationDetailsLoadState}
        >
          Preview
        </Button>
      </ActionsBox>
    </BottomPanelWrapper>
  );
}

export default BottomPanelBreakevenComponent;
