import { all, call, put, takeLatest } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Action } from 'redux-actions';
import { isEmpty, sortBy } from 'lodash';
import isUUID from 'validator/lib/isUUID';
import { push } from 'connected-react-router';
import { PriceableItemFilter } from 'sharedStore/entities/priceableItemFilters';
import {
  DECODE_URL,
  DECODE_URL_FINISHED,
  GENERATE_URL,
  GENERATE_URL_FINISHED,
} from './generateLink.ducks';
import AppConfig from '../../../config';
import { getAuthToken } from '../../../index';
import {
  FETCH_LOCATIONS_BY_IDS,
  FETCH_LOCATIONS_BY_IDS_FINISHED,
} from '../locations/locations.ducks';
import { errorHandlerActive } from '../../../utils/errorHandling/helpers';
import { DecodeUrlInput } from '../../entities/generateLink';
import { UserActionItemUrlProps } from '../../entities/userActionItems';

const urlShortener = (action: 'encode' | 'decode', url: string): Promise<AxiosResponse> => {
  const path = `${AppConfig.BACKEND_REST_URL}/url-shortener/${action}`;
  return axios
    .post(path, JSON.stringify({ url }), {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${getAuthToken()}`,
      },
    })
    .catch(error => error.response);
};

const toFilterString = (filter: PriceableItemFilter): string | undefined => {
  const filterValuesString = Object.keys(filter)
    .filter(key => filter[key as keyof PriceableItemFilter] !== undefined)
    .map(key => `${key}=${filter[key as keyof PriceableItemFilter]}`)
    .join('&');

  return filterValuesString ? `filterName=${filter.filterName}&${filterValuesString}` : undefined;
};

const toFilterObject = (filterString: string): PriceableItemFilter | undefined => {
  const filterObjectArray = filterString.split('&').map(filterValue => filterValue.split('='));

  const isValid =
    filterObjectArray.find(filter => filter[0] === 'filterName') &&
    filterObjectArray.find(
      filter =>
        ((filter[0] === 'min' || filter[0] === 'max') && !isNaN(+filter[1])) ||
        (filter[0] === 'isSelected' && typeof JSON.parse(filter[1]) === 'boolean') ||
        (filter[0] === 'textSearch' && !!filter[1]),
    );

  return isValid ? (Object.fromEntries(filterObjectArray) as PriceableItemFilter) : undefined;
};

function* generateUrlWorker(action: Action<UserActionItemUrlProps>) {
  try {
    const filters = action.payload.filterPathData.filter ?? [];
    const locationIdsString: string = action.payload.locationsIds.join(',');

    const routeName = action.payload.filterPathData.routeName;

    const encodeLocationsResponse: AxiosResponse | undefined = yield call(
      urlShortener,
      'encode',
      locationIdsString,
    );

    let resultUrl = encodeLocationsResponse?.data?.url;

    if (!(encodeLocationsResponse?.status === 200 && resultUrl)) {
      toast.error(
        `Generate locations URL error: ${
          encodeLocationsResponse?.data?.message ?? 'Unknown error'
        }`,
      );
    } else if (!isEmpty(filters)) {
      const filtersString = sortBy(filters, 'filterName')
        .map(filter => toFilterString(filter))
        .filter(filterString => !!filterString)
        .join(',');
      const encodeFiltersResponse: AxiosResponse | undefined = yield call(
        urlShortener,
        'encode',
        filtersString,
      );

      const filtersUrl = encodeFiltersResponse?.data?.url;

      if (!(encodeFiltersResponse?.status === 200 && filtersUrl)) {
        toast.error(
          `Generate filters URL error: ${encodeFiltersResponse?.data?.message ?? 'Unknown error'}`,
        );
      } else {
        resultUrl += `/${filtersUrl}`;
      }
    }

    yield put({ type: GENERATE_URL_FINISHED, payload: resultUrl });
    if (routeName) {
      yield put(push(`${routeName}/${resultUrl}`));
    }
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

function* decodeUrlWorker(action: Action<DecodeUrlInput>) {
  try {
    const { locationsUrl, filtersUrl, setFiltersActionName } = action.payload;

    const decodeLocationsResponse: AxiosResponse | undefined = yield call(
      urlShortener,
      'decode',
      locationsUrl,
    );

    const decodedLocationsUrl: string = decodeLocationsResponse?.data?.url;
    if (decodeLocationsResponse?.status === 200 && decodedLocationsUrl) {
      const ids = decodedLocationsUrl.split(',').filter(id => isUUID(id));

      if (isEmpty(ids)) {
        toast.info('The suggested URL cannot be decoded', {
          position: toast.POSITION.TOP_CENTER,
        });
        yield put({ type: FETCH_LOCATIONS_BY_IDS_FINISHED, payload: [] });
      } else {
        yield put({ type: FETCH_LOCATIONS_BY_IDS, payload: { ids } });

        if (filtersUrl) {
          const decodeFiltersResponse: AxiosResponse | undefined = yield call(
            urlShortener,
            'decode',
            filtersUrl,
          );

          const decodedFiltersUrl: string = decodeFiltersResponse?.data?.url;
          if (decodeFiltersResponse?.status === 200 && decodedFiltersUrl) {
            const filterParams = decodedFiltersUrl
              .split(',')
              .map(filterString => toFilterObject(filterString))
              .filter(filter => !!filter);

            yield put({ type: setFiltersActionName, filterParams });
          } else {
            toast.error(
              `Decode filters URL error: ${
                decodeFiltersResponse?.data?.message ?? 'Unknown error'
              }`,
            );
          }
        }
      }
    } else {
      yield put({ type: FETCH_LOCATIONS_BY_IDS_FINISHED, payload: [] });
      toast.error(
        `Decode locations URL error: ${decodeLocationsResponse?.data?.message ?? 'Unknown error'}`,
      );
      errorHandlerActive(new Error(decodeLocationsResponse?.toString()));
    }

    yield put({ type: DECODE_URL_FINISHED });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

export default function* generateLinkSaga(): any {
  yield all([takeLatest(GENERATE_URL, generateUrlWorker)]);
  yield all([takeLatest(DECODE_URL, decodeUrlWorker)]);
}
