import * as React from 'react';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dock } from 'react-dock';
import { Icon, IconName, IconSize, IconType } from '@wework/ray2';
import { Confirm, Dropdown, Tab, DropdownItemProps } from 'semantic-ui-react';
import cn from 'classnames';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get, isEqual } from 'lodash';
import { PanelBody, PanelFilterList, PanelHeadLabel } from '../sidePanelComponent.styled';
import DiscountsItemDetailHeader from './discountsItemDetailHeader';
import {
  currentDiscountDetailsByIdSelector,
  discountItemsTermTypes,
  getDiscountHistoryElements,
  mutateCurvesLoadingSelector,
} from '../../../store/modules/discountItems/discountItems.selector';
import {
  CLOSE_DISCOUNT_DETAILS_SIDE_PANEL,
  FETCH_DISCOUNT_HISTORY,
  REMOVE_CURVES,
} from '../../../store/modules/discountItems/discountItems.ducks';
import { flattenDiscounts } from '../../discountItems.helpers';
import { DataWithCallback, Unassigned } from '../../../../../utils/sharedTypes';
import { formatDate, formatDateFromDateString } from '../../../../../utils/helpers';
import DiscountCurvesModal from '../../discountCurves/discountCurvesModal';
import { CurveInfoBlock, CurveInfoRow } from './discountsDetailSidePanel.styled';
import { toTitleCase } from '../../../../pricing/standardPricing/components/helpers';
import { TableViewDiscountColumn } from '../../../store/entities/discountItems';
import { AuthzProps } from '../../../../../utils/constants';
import {
  ClosePanelButton,
  PanelHeader,
  PanelTitle,
  SidePanelWrapper,
} from '../../../../../styles/app.styled';
import DiscountsItemDiscountHistory from './discountsItemDiscountHistory';
import EmployeePermissions from '../../../../../utils/store/permissions';
import {
  GridDiscountCurve,
  MutationRemoveCurvesArgs,
  PriceableItem,
} from '../../../../../generated/voyager/graphql';

interface DiscountsDetailSidePanelProps extends AuthzProps {
  isVisible: boolean;
  priceableItemId: string;
}

const menuParams = { secondary: true, pointing: true };

function DiscountsDetailSidePanel({
  isVisible,
  priceableItemId,
  requestedPermissions,
}: DiscountsDetailSidePanelProps): ReactElement {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [addCurvesModalOpen, setAddCurvesModalOpen] = useState<boolean>(false);
  const [selectedCurve, setSelectedCurve] = useState<GridDiscountCurve>();

  // STATE PROPS
  const discountsDetails = useSelector(currentDiscountDetailsByIdSelector(priceableItemId));
  const termTypes = useSelector(discountItemsTermTypes);
  const mutateCurvesLoading = useSelector(mutateCurvesLoadingSelector);
  const fetchDiscountHistoryElements = useSelector(getDiscountHistoryElements, (left, right) =>
    isEqual(left, right),
  );

  const dispatch = useDispatch();
  const fetchPriceableItemFeature = useCallback(
    (payload: string) => dispatch({ type: FETCH_DISCOUNT_HISTORY, payload }),
    [dispatch],
  );

  const fetchData = () => {
    if (priceableItemId) {
      fetchPriceableItemFeature(priceableItemId);
    }
  };

  useEffect(fetchData, [priceableItemId]);

  // DISPATCH PROPS
  const closeSidePanel = useCallback(
    () => dispatch({ type: CLOSE_DISCOUNT_DETAILS_SIDE_PANEL }),
    [dispatch],
  );
  const removeCurvesAction = useCallback(
    (payload: DataWithCallback<MutationRemoveCurvesArgs>) =>
      dispatch({ type: REMOVE_CURVES, payload }),
    [dispatch],
  );
  const removeSelectedCurve = () => {
    if (!selectedCurve || !priceableItemId) {
      return;
    }

    removeCurvesAction({
      data: {
        input: {
          priceableItemIds: [priceableItemId],
          curveIds: [selectedCurve.curve.id],
        },
      },
      successCallback: () => setConfirmationModalOpen(false),
    });
  };

  const discountDetailsFlatten = (
    termLabel: string,
    priceableItemDiscountsDetails: PriceableItem | Unassigned,
  ): TableViewDiscountColumn => {
    if (priceableItemDiscountsDetails && priceableItemDiscountsDetails.gridDiscounts) {
      const baseDiscountArrayFlatten = flattenDiscounts(
        priceableItemDiscountsDetails.gridDiscounts,
      );
      return baseDiscountArrayFlatten.get(termLabel) ?? ({} as TableViewDiscountColumn);
    }
    return {} as TableViewDiscountColumn;
  };

  useEffect(() => {
    if (discountsDetails && discountsDetails.appliedCurves.length > 0) {
      setSelectedCurve(discountsDetails.appliedCurves[0]);
    } else {
      setSelectedCurve(undefined);
    }
  }, [discountsDetails]);

  const detailPanes = useMemo(
    () => [
      {
        menuItem: 'Reservable',
        pane: {
          key: 'tab1',
          content: (
            <PanelFilterList className="discounts-list">
              <CurveInfoRow className="center-align">
                <Dropdown
                  placeholder={
                    discountsDetails && discountsDetails.appliedCurves.length > 0
                      ? 'Select a curve'
                      : 'No curves applied'
                  }
                  selection
                  disabled={!(discountsDetails && discountsDetails.appliedCurves.length > 0)}
                  options={discountsDetails?.appliedCurves
                    .map(
                      curve =>
                        ({
                          key: curve.curve.id,
                          text: curve.curve.name,
                          value: curve.curve.id,
                        } as DropdownItemProps),
                    )
                    .concat({
                      key: 'no-curve',
                      text: 'Clear selection',
                      className: 'inactive',
                      icon: 'close',
                    } as DropdownItemProps)}
                  value={selectedCurve?.curve.id}
                  onChange={(_, data) =>
                    setSelectedCurve(
                      discountsDetails?.appliedCurves.find(curve => curve.curve.id === data.value),
                    )
                  }
                />
                {get(
                  requestedPermissions,
                  EmployeePermissions.voyager_dedicated_space_discount_curve_apply,
                  false,
                ) && (
                  <>
                    {selectedCurve && (
                      <Icon
                        icon={IconName.TRASH}
                        size={IconSize.SMALL}
                        type={IconType.OUTLINE}
                        onClick={() => setConfirmationModalOpen(true)}
                      />
                    )}
                    <Icon
                      icon={IconName.PLUS}
                      size={IconSize.SMALL}
                      type={IconType.OUTLINE}
                      onClick={() => setAddCurvesModalOpen(true)}
                    />
                  </>
                )}
              </CurveInfoRow>
              {selectedCurve && (
                <CurveInfoRow>
                  <CurveInfoBlock>
                    <div className="title">Expires</div>
                    <div className="content">
                      {selectedCurve.curve.expirationDate
                        ? formatDateFromDateString(selectedCurve.curve.expirationDate)
                        : '-'}
                    </div>
                  </CurveInfoBlock>
                  <CurveInfoBlock>
                    <div className="title">Last updated</div>
                    <div className="content">{`${formatDate(
                      selectedCurve.curve.updatedAt,
                    )} by ${toTitleCase(
                      selectedCurve.curve.lastUpdatedBy ?? selectedCurve.curve.createdBy,
                    )}`}</div>
                  </CurveInfoBlock>
                  <CurveInfoBlock>
                    <div className="title">Applied on</div>
                    <div className="content">{`${formatDate(
                      selectedCurve.appliedAt,
                    )} by ${toTitleCase(selectedCurve.appliedBy)}`}</div>
                  </CurveInfoBlock>
                </CurveInfoRow>
              )}
              {termTypes.map(termType => (
                <DiscountsItemDetailHeader
                  title={termType.label}
                  flattenDistributionData={discountDetailsFlatten(termType.label, discountsDetails)}
                  selectedCurveTerm={selectedCurve?.curve.curveTerms.find(
                    ct => ct.term.id === termType.id,
                  )}
                  key={termType.id}
                />
              ))}
            </PanelFilterList>
          ),
        },
      },
      {
        menuItem: 'Discount History',
        pane: {
          key: 'tab2',
          content: termTypes.map(termType => (
            <PanelFilterList key={termType.id} className="discounts-list">
              <DiscountsItemDiscountHistory
                data={
                  fetchDiscountHistoryElements?.filter(
                    eachItem => eachItem.termType.label === termType.label,
                  ) ?? []
                }
                label={termType.label}
                timeZone={discountsDetails?.location?.timeZone}
              />
            </PanelFilterList>
          )),
        },
      },
    ],
    [
      selectedCurve,
      discountsDetails,
      requestedPermissions,
      termTypes,
      fetchDiscountHistoryElements,
    ],
  );

  return (
    <SidePanelWrapper>
      <Dock
        position="right"
        isVisible={isVisible}
        dimMode="none"
        fluid={false}
        size={320}
        zIndex={100}
      >
        <ClosePanelButton type="button" className="close" onClick={() => closeSidePanel()} />
        <PanelHeader className="details-header">
          <PanelHeadLabel>Reservable Discounts Details</PanelHeadLabel>
          <PanelTitle>{discountsDetails?.name}</PanelTitle>
        </PanelHeader>
        <PanelBody className={cn({ 'selected-curve': selectedCurve }, 'discounts-details')}>
          <Tab
            className="detail-tabs"
            menu={menuParams}
            panes={detailPanes}
            renderActiveOnly={false}
          />
          <Confirm
            open={confirmationModalOpen}
            onCancel={() => setConfirmationModalOpen(false)}
            onConfirm={removeSelectedCurve}
            closeIcon
            cancelButton={{
              content: 'Cancel',
              className: 'cancel-confirmation',
              disabled: mutateCurvesLoading,
            }}
            confirmButton={{ content: 'Confirm', loading: mutateCurvesLoading }}
            content={`Are you sure you want to remove the curve '${selectedCurve?.curve.name}?`}
          />
          {addCurvesModalOpen && priceableItemId && (
            <DiscountCurvesModal
              closeModal={() => setAddCurvesModalOpen(false)}
              priceableItemIds={[priceableItemId]}
              filterCurveIds={discountsDetails?.appliedCurves.map(curve => curve.curve.id)}
              hideRemove
            />
          )}
        </PanelBody>
      </Dock>
    </SidePanelWrapper>
  );
}

export default withRequestedAuthz<DiscountsDetailSidePanelProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_discount_curve_apply],
})(DiscountsDetailSidePanel);
