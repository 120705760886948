// GraphQL Queries
import { gql } from '@apollo/client';

export const appliedCurveFragment = gql`
  fragment appliedCurve on GridDiscountCurve {
    curve {
      id
      name
      expirationDate
      createdBy
      lastUpdatedBy
      updatedAt
      curveTerms {
        term {
          id
          label
        }
        dataPoints {
          month
          value
        }
      }
    }
    appliedAt
    appliedBy
  }
`;

const gridDiscountOverrideFragment = gql`
  fragment gridDiscountOverride on GridDiscountOverride {
    base
    expirationDate
    publishDate
    createdBy
  }
`;

const gridBaseDiscountOverrideFragment = gql`
  fragment gridBaseDiscountOverride on GridBaseDiscount {
    base
    publishDate
    createdBy
  }
`;

export const FETCH_GRID_DISCOUNT_ITEMS_QUERY = gql`
  query GridDiscountItems($page: PageInfo!, $filter: PriceableItemFilter) {
    priceableItems(page: $page, filter: $filter) {
      id
      name
      attributes {
        label
        value
      }
      location {
        id
        timeZone
        operators {
          id
          name
        }
      }
      currentPrice {
        marketPrice
        currencyIsoCode
      }
      metrics {
        reservationPriceLocal
        isOccupied
        monthsVacant
      }
      gridDiscounts {
        termType {
          id
          label
          length
        }
        baseDiscount {
          ...gridBaseDiscountOverride
        }
        futureBaseDiscount {
          ...gridBaseDiscountOverride
        }
        override {
          ...gridDiscountOverride
        }
        futureOverride {
          ...gridDiscountOverride
          updatedAt
        }
      }
      appliedCurves {
        ...appliedCurve
      }
    }
  }
  ${appliedCurveFragment}
  ${gridDiscountOverrideFragment}
  ${gridBaseDiscountOverrideFragment}
`;

export const FETCH_DISCOUNT_ITEMS_TERM_TYPES_QUERY = gql`
  query DiscountItemsTermTypes($page: PageInfo!) {
    termTypes(page: $page) {
      id
      label
      length
      baseMaxDiscount
    }
  }
`;

export const FIND_CURVES_QUERY = gql`
  query FindCurves($text: String!, $includeInactive: Boolean) {
    findCurves(text: $text, includeInactive: $includeInactive) {
      id
      name
      description
      expirationDate
      isActive
      appliedCount
      operator {
        id
        name
      }
      curveTerms {
        term {
          id
          label
        }
        dataPoints {
          month
          value
        }
      }
    }
  }
`;

export const FETCH_ALL_CURVES = gql`
  query Curves($page: PageInfo!, $includeInactive: Boolean) {
    curves(page: $page, includeInactive: $includeInactive) {
      id
      name
      description
      expirationDate
      appliedCount
      createdBy
      createdAt
      lastUpdatedBy
      updatedAt
      isActive
      operator {
        id
        name
      }
      curveTerms {
        term {
          id
          label
          length
        }
        dataPoints {
          month
          value
        }
      }
    }
  }
`;

export const FETCH_USER_OPERATORS_QUERY = gql`
  query Operators {
    userOperators {
      id
      name
    }
  }
`;

export const FETCH_DISCOUNT_HISTORY_BY_PRICEABLE_ITEMS_ID = gql`
  query DiscountHistory($id: ID!) {
    priceableItem(id: $id) {
      discountsHistory {
        base
        termType {
          id
          label
        }
        base
        publishDate
        expirationDate
        createdBy
      }
    }
  }
`;

export const CANCEL_FUTURE_DISCOUNTS = gql`
  mutation CancelFutureDiscounts($cancelFutureDiscountInput: CancelFutureDiscountInput!) {
    cancelFutureDiscounts(cancelFutureDiscountInput: $cancelFutureDiscountInput) {
      success
      errors
    }
  }
`;
