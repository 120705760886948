import { Icon, IconName } from '@wework/ray2';
import React from 'react';
import LabelWrapper from './removeLabel.styled';

interface RemovableLabelInterface {
  labelText: string;
  disabled: boolean;
  onDelete: Function;
}

const RemovableLabel = ({ labelText, disabled, onDelete }: RemovableLabelInterface) => (
  <LabelWrapper image>
    {labelText}
    <Icon
      name="delete"
      onClick={() => onDelete()}
      icon={IconName.CLOSE}
      className={`${disabled ? 'text-primary' : 'text-gray-70'} ml-sm`}
    />
  </LabelWrapper>
);

export default RemovableLabel;
