// GraphQL Queries
import { gql } from '@apollo/client';

// Query to fetch all priceable items WITH WORKING PRICE for the location.
export const FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE_QUERY = gql`
  query WorkingPrices($workingPricePreviewInput: WorkingPricePreviewInput!, $page: PageInfo!) {
    workingPricePreviews(workingPricePreviewInput: $workingPricePreviewInput, page: $page) {
      priceableItem {
        id
        name
        attributes {
          label
          value
        }
        currentPrice {
          price
        }
        workingPrice {
          id
          price
          createdBy
          lastChangeReason
        }
        location {
          id
          name
          timeZone
          market {
            name
            territory {
              name
              region {
                name
              }
            }
          }
        }
      }
      roundedListPrice
      currencyIsoCode
    }
  }
`;

export const GET_TOTAL_WORKING_PRICES_QUERY = gql`
  query TotalWorkingPrices($workingPricePreviewInput: WorkingPricePreviewInput!) {
    totalWorkingPrices(workingPricePreviewInput: $workingPricePreviewInput)
  }
`;
