import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pluralize } from 'apollo/lib/utils';
import { Confirm, Checkbox } from 'semantic-ui-react';
import { ConfirmProps } from '../../../../../../sharedStore/entities/confirmProps';
import {
  ON_DEMAND_FUTURE_PRICE_CANCEL,
  RESET_SELECTED_NODES_ON_DEMAND,
} from '../../../store/modules/onDemand/onDemandPricing.ducks';
import {
  onDemandCancelPricesLoadingSelector,
  onDemandPricingProductsSelector,
  onDemandSelectedNodesSelector,
} from '../../../store/modules/onDemand/onDemandPricing.selector';
import { futurePriceOnDemandCancelAll } from '../../onDemandPricing.helpers';
import { DataWithCallback } from '../../../../../../utils/sharedTypes';
import { trackAnalytics } from '../../../../../../utils/analytics/helpers';
import { MutationCancelFuturePriceHierarchyArgs } from '../../../../../../generated/voyager/graphql';

function OnDemandCancelFuturePricesConfirm(props: ConfirmProps): ReactElement {
  const [productIdsToCancel, setProductIdsToCancel] = useState<string[]>([]);

  const dispatch = useDispatch();
  const cancelFuturePrices = useCallback(
    (payload: DataWithCallback<MutationCancelFuturePriceHierarchyArgs>) =>
      dispatch({
        type: ON_DEMAND_FUTURE_PRICE_CANCEL,
        payload,
      }),
    [dispatch],
  );
  const resetSelectedNodes = useCallback(
    () =>
      dispatch({
        type: RESET_SELECTED_NODES_ON_DEMAND,
      }),
    [dispatch],
  );

  const selectedNodes = useSelector(onDemandSelectedNodesSelector);
  const cancelLoading = useSelector(onDemandCancelPricesLoadingSelector);
  const products = useSelector(onDemandPricingProductsSelector);

  const resetSelectedProductIdsToCancel = () =>
    setProductIdsToCancel(products.map(product => product.id));

  const closeCancelConfirmationModal = () => {
    props.closeModal();
    resetSelectedNodes();
    resetSelectedProductIdsToCancel();
  };

  useEffect(resetSelectedProductIdsToCancel, [setProductIdsToCancel, products]);

  const confirmCancelFuturePrices = () => {
    trackAnalytics('On Demand - Cancel Future Prices Button Click', {
      workflow: 'On Demand - Cancel Future Prices',
      object_type: 'button',
      object_name: 'Cancel Future Prices',
      object_value: productIdsToCancel,
    });
    cancelFuturePrices({
      data: {
        priceHierarchyIds: futurePriceOnDemandCancelAll(
          selectedNodes.map(node => node.data),
          productIdsToCancel,
        ),
      },
      successCallback: closeCancelConfirmationModal,
    });
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={closeCancelConfirmationModal}
      onConfirm={confirmCancelFuturePrices}
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
        disabled: cancelLoading,
      }}
      closeIcon
      confirmButton={{
        content: `Cancel Prices for ${pluralize(
          selectedNodes.filter(node => node.data.futurePrices.length > 0).length,
          'item',
        )}`,
        disabled: !futurePriceOnDemandCancelAll(
          selectedNodes.map(node => node.data),
          productIdsToCancel,
        ).length,
        loading: cancelLoading,
      }}
      header="Cancel Prices"
      content={
        <div>
          <p>
            For <b>all</b> selected geo groupings, future scheduled prices will be canceled.
          </p>
          <p>Are you sure you want to cancel future prices?</p>
          {products.map(product => (
            <Checkbox
              key={product.id}
              label={`Cancel future ${product.name} prices`}
              checked={productIdsToCancel.includes(product.id)}
              onChange={(_, data) =>
                setProductIdsToCancel(
                  data.checked
                    ? [...productIdsToCancel, product.id]
                    : productIdsToCancel.filter(id => id !== product.id),
                )
              }
            />
          ))}
        </div>
      }
    />
  );
}

export default OnDemandCancelFuturePricesConfirm;
