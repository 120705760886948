import { Action } from 'redux-actions';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { DocumentNode } from 'graphql';
import { chunk, get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { FetchResult } from '@apollo/client/link/core';
import { pluralize } from 'apollo/lib/utils';
import {
  ApplyCurvesMutation,
  CancelFutureDiscountInput,
  DiscountCurveInput,
  DiscountCurveResult,
  GridDiscountItemsQueryVariables,
  MutationApplyCurvesArgs,
  MutationCreateCurveArgs,
  MutationDeleteCurveArgs,
  MutationPublishOverridesArgs,
  MutationRemoveCurvesArgs,
  MutationUpdateCurveArgs,
  PageInfo,
  PriceableItem,
  PublishOverridesMutation,
  QueryFindCurvesArgs,
  RemoveCurvesMutation,
} from '../../../../../generated/voyager/graphql';
import { graphQLClient } from '../../../../../index';
import { DataWithCallback } from '../../../../../utils/sharedTypes';
import { errorHandlerActive } from '../../../../../utils/errorHandling/helpers';
import {
  APPLY_CURVES,
  APPLY_CURVES_SUCCESS,
  CANCEL_FUTURE_DISCOUNT,
  CANCEL_FUTURE_DISCOUNT_SUCCESS,
  CREATE_CURVES,
  CREATE_CURVES_SUCCESS,
  DELETE_CURVES,
  DELETE_CURVES_SUCCESS,
  END_DISCOUNT_BATCH_FETCH,
  END_DISCOUNT_CURVES_BATCH_FETCH,
  FETCH_DISCOUNT_HISTORY,
  FETCH_DISCOUNT_HISTORY_SUCCESS,
  FETCH_DISCOUNT_ITEMS,
  FETCH_DISCOUNT_ITEMS_CURVES,
  FETCH_DISCOUNT_ITEMS_CURVES_SUCCESS,
  FETCH_DISCOUNT_ITEMS_SUCCESS,
  FETCH_DISCOUNT_ITEMS_TERM_TYPES,
  FETCH_DISCOUNT_ITEMS_TERM_TYPES_SUCCESS,
  FETCH_USER_OPERATORS,
  FETCH_USER_OPERATORS_SUCCESS,
  FIND_CURVES,
  FIND_CURVES_SUCCESS,
  PUBLISH_WORKING_DISCOUNTS,
  PUBLISH_WORKING_DISCOUNTS_SUCCESS,
  REMOVE_CURVES,
  REMOVE_CURVES_SUCCESS,
  START_DISCOUNT_BATCH_FETCH,
  START_DISCOUNT_CURVES_BATCH_FETCH,
  STOP_FETCH_DISCOUNT_ITEMS,
  STOP_FETCH_DISCOUNT_ITEMS_CURVES,
  UPDATE_CURVES,
  UPDATE_CURVES_SUCCESS,
} from './discountItems.ducks';
import {
  CANCEL_FUTURE_DISCOUNTS,
  FETCH_ALL_CURVES,
  FETCH_DISCOUNT_HISTORY_BY_PRICEABLE_ITEMS_ID,
  FETCH_DISCOUNT_ITEMS_TERM_TYPES_QUERY,
  FETCH_GRID_DISCOUNT_ITEMS_QUERY,
  FETCH_USER_OPERATORS_QUERY,
  FIND_CURVES_QUERY,
} from './discountItems.query';
import {
  APPLY_CURVES_MUTATION,
  CREATE_CURVE_MUTATION,
  DELETE_CURVE_MUTATION,
  PUBLISH_CURRENT_WORKING_DISCOUNTS,
  REMOVE_CURVES_MUTATION,
  UPDATE_CURVE_MUTATION,
} from './discountItems.mutation';

import { cancelFutureDiscountIdsSelector } from './discountItems.selector';
import { GET_TOTAL_PRICEABLE_ITEMS_QUERY } from '../../../../pricing/standardPricing/store/modules/standardPricingItems/standardPricingItems.query';
import { pageInfoSmall } from '../../../../../utils/store/store.constants';

const executeGraphQLQuery = (query: DocumentNode, variables?: any): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query,
    variables,
  });

function* fetchDiscountItemsData(variables: GridDiscountItemsQueryVariables): any {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  const page = variables.page.page;
  yield delay(page * requestDelay);

  // 1) Trying fetch the data
  const { errors, data } = yield call(
    executeGraphQLQuery,
    FETCH_GRID_DISCOUNT_ITEMS_QUERY,
    variables,
  );

  // 2) IF, there are any errors in fetching ending the batch fetch and failing the priceable items error.
  if (!isEmpty(errors) || !data?.priceableItems) {
    yield put({ type: END_DISCOUNT_BATCH_FETCH });
    errorHandlerActive(new Error(errors[0] ?? 'Error while fetching priceable items'));
  } else {
    // 3) Store the data in the store.
    const priceableItems = data.priceableItems.map((item: PriceableItem) => ({
      ...item,
      page,
    }));
    yield put({ type: FETCH_DISCOUNT_ITEMS_SUCCESS, payload: priceableItems });
  }
}

function* fetchDiscountCurvesItemsData(page: PageInfo, includeInactive: boolean = false): any {
  try {
    // 1) Trying fetch the data for next page.
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_ALL_CURVES, {
      page,
      includeInactive,
    });

    // 2) IF, there are any errors in fetching ending the batch fetch and failing the priceable items error.
    if (!isEmpty(errors)) {
      yield put({ type: END_DISCOUNT_CURVES_BATCH_FETCH });
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    // 3) If the discount Items data present try to load more data recursively.
    if (!isEmpty(data.curves)) {
      // 3.a) Store the data in the store.
      yield put({ type: FETCH_DISCOUNT_ITEMS_CURVES_SUCCESS, data });
      // 3.b) Fetch the next set of data.
      const nextPage = { page: page.page + 1, size: page.size };
      yield call(fetchDiscountCurvesItemsData, nextPage, includeInactive);
    }

    return;
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_DISCOUNT_CURVES_BATCH_FETCH });
  }
}

// Saga - for fetching the discount items in batches.
function* fetchDiscountItemsWorker(action: Action<GridDiscountItemsQueryVariables>) {
  try {
    const input = action.payload;
    // Starting Batch Fetch Discount
    yield put({ type: START_DISCOUNT_BATCH_FETCH });

    const { errors, data } = yield call(executeGraphQLQuery, GET_TOTAL_PRICEABLE_ITEMS_QUERY, {
      filter: input.filter,
    });
    if (!isEmpty(errors) || !data) {
      yield put({ type: END_DISCOUNT_BATCH_FETCH });
      errorHandlerActive(new Error(errors[0] ?? 'Error while calculating total priceable items'));
      return;
    }

    const requestsNumber = Math.ceil(data.totalPriceableItems / input.page.size);
    yield all(
      [...Array(requestsNumber).keys()].map(index =>
        fetchDiscountItemsData({
          ...input,
          page: {
            ...input.page,
            page: index + 1,
          },
        }),
      ),
    );

    // End batch fetch Discount.
    yield put({ type: END_DISCOUNT_BATCH_FETCH });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_DISCOUNT_BATCH_FETCH });
  }
}

// Saga - for fetching the discount items in batches.
function* fetchDiscountCurvesItemsWorker(action: any) {
  try {
    const payload = action.payload;
    // Starting Batch Fetch Discount
    yield put({ type: START_DISCOUNT_CURVES_BATCH_FETCH });
    // Calling the recursion for fetching the discount items.
    yield call(fetchDiscountCurvesItemsData, payload?.page as PageInfo, payload.includeInactive);
    // End batch fetch Discount.
    yield put({ type: END_DISCOUNT_CURVES_BATCH_FETCH });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_DISCOUNT_CURVES_BATCH_FETCH });
  }
}

// Saga - for fetching the discount items termTypes.
function* fetchDiscountItemsTermTypesWorker(action: Action<PageInfo>) {
  try {
    const page = action.payload;

    // Fetch discount items term types.
    const { errors, data } = yield call(
      executeGraphQLQuery,
      FETCH_DISCOUNT_ITEMS_TERM_TYPES_QUERY,
      { page },
    );

    // If errors in fetching then redirect to error page.
    if (!isEmpty(errors)) {
      yield put({ type: FETCH_DISCOUNT_ITEMS_TERM_TYPES_SUCCESS, data: null });
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    // Store the term types.
    yield put({ type: FETCH_DISCOUNT_ITEMS_TERM_TYPES_SUCCESS, data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: FETCH_DISCOUNT_ITEMS_TERM_TYPES_SUCCESS, data: null });
  }
}

// TODO: BC - Change the mutation working after mutation is available.
const publishWorkingDiscountsMutation = (
  variables: MutationPublishOverridesArgs,
): Promise<FetchResult<PublishOverridesMutation>> =>
  graphQLClient.mutate({
    mutation: PUBLISH_CURRENT_WORKING_DISCOUNTS,
    variables,
  });

function* publishWorkingDiscountsWorker(action: Action<MutationPublishOverridesArgs>) {
  try {
    const input = action.payload;
    const batchSize = Number(process.env.REACT_APP_REQUEST_BATCH_SIZE);

    yield all(
      chunk(input.overrides, batchSize).map((batch, index) =>
        publishWorkingDiscounts(
          {
            ...input,
            overrides: batch,
          },
          index,
        ),
      ),
    );

    yield put({ type: PUBLISH_WORKING_DISCOUNTS_SUCCESS });
    toast.success(`Published Discounts Override Successfully`);
  } catch (e: any) {
    toast.error(`Publish Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* publishWorkingDiscounts(input: MutationPublishOverridesArgs, page: number) {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  yield delay(page * requestDelay);
  const { errors, data } = yield call(publishWorkingDiscountsMutation, input);

  if (!isEmpty(errors) || !data?.publishOverrides?.success) {
    const error = data?.publishOverrides?.errors ?? errors[0];
    toast.error(`Publish Discounts Error - ${error}`);
    errorHandlerActive(new Error(error));
  }
}

function* findCurvesWorker(action: Action<QueryFindCurvesArgs>) {
  try {
    const { errors, data } = yield call(executeGraphQLQuery, FIND_CURVES_QUERY, action.payload);

    if (!isEmpty(errors)) {
      yield put({ type: FIND_CURVES_SUCCESS, data: null });
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    yield put({ type: FIND_CURVES_SUCCESS, data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: FIND_CURVES_SUCCESS, data: null });
  }
}

const applyCurvesMutation = (
  variables: MutationApplyCurvesArgs,
): Promise<FetchResult<ApplyCurvesMutation>> =>
  graphQLClient.mutate({
    mutation: APPLY_CURVES_MUTATION,
    variables,
  });

function* applyCurvesWorker(action: Action<DataWithCallback<{ input: DiscountCurveInput }>>) {
  try {
    const { errors, data } = yield call(applyCurvesMutation, action.payload.data);

    if (!isEmpty(errors)) {
      toast.error(`Apply curves error - ${errors[0]}`);
    } else if (data.applyCurves.some((result: DiscountCurveResult) => !result.success)) {
      const applyCurvesErrors = data.applyCurves
        .filter((result: DiscountCurveResult) => !result.success)
        .map(
          (result: DiscountCurveResult) =>
            `Reservable ${result.priceableItemId} curve ${result.curveId}: ${result.errors}`,
        );
      toast.error(`Some curves were not applied - ${applyCurvesErrors}`);
    } else {
      toast.success(`Applied Curves Successfully`);
      action.payload.successCallback();
    }

    yield put({ type: APPLY_CURVES_SUCCESS, payload: data?.applyCurves });
  } catch (e: any) {
    yield put({ type: APPLY_CURVES_SUCCESS, payload: null });
    toast.error(`Apply curves Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

const removeCurvesMutation = (
  variables: MutationRemoveCurvesArgs,
): Promise<FetchResult<RemoveCurvesMutation>> =>
  graphQLClient.mutate({
    mutation: REMOVE_CURVES_MUTATION,
    variables,
  });

function* removeCurvesWorker(action: Action<DataWithCallback<MutationRemoveCurvesArgs>>) {
  try {
    const { errors, data } = yield call(removeCurvesMutation, action.payload.data);

    if (!isEmpty(errors)) {
      toast.error(`Remove curves Error - ${errors[0]}`);
    } else if (data.removeCurves.some((result: DiscountCurveResult) => !result.success)) {
      const removeCurvesErrors = data.removeCurves
        .filter((result: DiscountCurveResult) => !result.success)
        .map(
          (result: DiscountCurveResult) =>
            `Reservable ${result.priceableItemId} curve ${result.curveId}: ${result.errors}`,
        );
      toast.error(`Some curves were not removed - ${removeCurvesErrors}`);
    } else {
      toast.success(`Removed Curves Successfully`);
      action.payload.successCallback();
    }

    yield put({ type: REMOVE_CURVES_SUCCESS, payload: data?.removeCurves });
  } catch (e: any) {
    yield put({ type: REMOVE_CURVES_SUCCESS, payload: null });
    toast.error(`Remove curves Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

const createCurvesMutation = (variables: MutationCreateCurveArgs): Promise<any> =>
  graphQLClient.mutate({
    mutation: CREATE_CURVE_MUTATION,
    variables,
  });

function* createCurvesWorker(action: Action<DataWithCallback<MutationCreateCurveArgs>>) {
  try {
    const { data, errors } = yield call(createCurvesMutation, action.payload.data);

    if (!isEmpty(errors) || !data?.createCurve.success) {
      toast.error(`Create curves Error - ${data?.createCurve.errors ?? errors[0]}`);
    } else {
      const payload = { page: pageInfoSmall, includeInactive: true };
      yield put({ type: FETCH_DISCOUNT_ITEMS_CURVES, payload });
      toast.success(`Created Curve Successfully`);
      action.payload.successCallback();
    }

    yield put({ type: CREATE_CURVES_SUCCESS });
  } catch (e: any) {
    yield put({ type: CREATE_CURVES_SUCCESS });
    toast.error(`Create curves Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

const updateCurvesMutation = (variables: MutationUpdateCurveArgs): Promise<any> =>
  graphQLClient.mutate({
    mutation: UPDATE_CURVE_MUTATION,
    variables,
  });

function* updateCurvesWorker(action: Action<DataWithCallback<MutationUpdateCurveArgs>>) {
  try {
    const { errors, data } = yield call(updateCurvesMutation, action.payload.data);

    if (!isEmpty(errors) || !data?.updateCurve.success) {
      toast.error(`Update curves Error - ${data?.updateCurve.errors ?? errors[0]}`);
    } else {
      const payload = { page: pageInfoSmall, includeInactive: true };
      yield put({ type: FETCH_DISCOUNT_ITEMS_CURVES, payload });
      toast.success(`Curve Updated Successfully`);
      action.payload.successCallback();
    }

    yield put({ type: UPDATE_CURVES_SUCCESS, payload: data });
  } catch (e: any) {
    yield put({ type: UPDATE_CURVES_SUCCESS, payload: null });
    toast.error(`Update curves Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

const deleteCurvesMutation = (variables: MutationDeleteCurveArgs): Promise<any> =>
  graphQLClient.mutate({
    mutation: DELETE_CURVE_MUTATION,
    variables,
  });

function* deleteCurvesWorker(action: Action<DataWithCallback<MutationDeleteCurveArgs>>) {
  try {
    const { errors, data } = yield call(deleteCurvesMutation, action.payload.data);

    if (!isEmpty(errors)) {
      toast.error(`Delete curves Error - ${errors[0]}`);
    } else {
      const payload = { page: pageInfoSmall, includeInactive: true };
      yield put({ type: FETCH_DISCOUNT_ITEMS_CURVES, payload });
      toast.success(`Deleted Curves Successfully`);
      action.payload.successCallback();
    }

    yield put({ type: DELETE_CURVES_SUCCESS, payload: data });
  } catch (e: any) {
    yield put({ type: DELETE_CURVES_SUCCESS, payload: null });
    toast.error(`Delete curves Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

function* fetchUserOperatorsWorker() {
  try {
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_USER_OPERATORS_QUERY);

    if (!isEmpty(errors)) {
      toast.error(`Fetch Operators Error - ${errors[0]}`);
    }

    yield put({ type: FETCH_USER_OPERATORS_SUCCESS, payload: data?.userOperators });
  } catch (e: any) {
    yield put({ type: FETCH_USER_OPERATORS_SUCCESS, payload: null });
    toast.error(`Fetch Operators Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

const fetchDiscountHistoryById = (id: string): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_DISCOUNT_HISTORY_BY_PRICEABLE_ITEMS_ID,
    variables: { id },
  });

function* fetchDiscountHistory(action: Action<string>) {
  try {
    const id = action?.payload;
    const { errors, data } = yield call(fetchDiscountHistoryById, id);
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    yield put({ type: FETCH_DISCOUNT_HISTORY_SUCCESS, data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

const cancelFutureDiscountsMutation = (
  cancelFutureDiscountInput: CancelFutureDiscountInput,
): Promise<any> =>
  graphQLClient.mutate({
    mutation: CANCEL_FUTURE_DISCOUNTS,
    variables: {
      cancelFutureDiscountInput,
    },
  });

function* cancelFutureDiscountsWorker(action: Action<DataWithCallback<CancelFutureDiscountInput>>) {
  try {
    const ids: string[] = yield select(cancelFutureDiscountIdsSelector);
    const input = action.payload.data;
    const batchSize = Number(process.env.REACT_APP_REQUEST_BATCH_SIZE);

    yield all(
      chunk(input.ids, batchSize).map((batch, index) =>
        cancelFutureDiscounts(
          {
            ...input,
            ids: batch,
          },
          index,
        ),
      ),
    );

    toast.success(`Canceled future discounts for ${pluralize(ids.length, 'item')}`);
    yield put({ type: CANCEL_FUTURE_DISCOUNT_SUCCESS, payload: ids });
    action.payload.successCallback();
  } catch (e: any) {
    toast.error(`Error while canceling future discounts: ${e.message}`);
    errorHandlerActive(e);
  }
}

function* cancelFutureDiscounts(input: CancelFutureDiscountInput, page: number) {
  const requestDelay = 1000 / Number(process.env.REACT_APP_REQUESTS_PER_SECOND);
  yield delay(page * requestDelay);

  const { data, errors } = yield call(cancelFutureDiscountsMutation, input);

  if (!isEmpty(errors) || !data?.cancelFutureDiscounts?.success) {
    const error = data?.cancelFutureDiscounts?.errors ?? errors[0];
    toast.error(`Cancel Future Discounts Error - ${error}`);
    errorHandlerActive(new Error(error));
  }
}

export default function* discountItemSaga(): any {
  yield takeLatest(
    [FETCH_DISCOUNT_ITEMS],
    function* (args: Action<GridDiscountItemsQueryVariables>) {
      yield race({
        task: call(fetchDiscountItemsWorker, args),
        cancel: take(STOP_FETCH_DISCOUNT_ITEMS),
      });
    },
  );
  yield takeLatest([FETCH_DISCOUNT_ITEMS_CURVES], function* (...args) {
    yield race({
      task: call(fetchDiscountCurvesItemsWorker, ...args),
      cancel: take(STOP_FETCH_DISCOUNT_ITEMS_CURVES),
    });
  });
  yield all([takeLatest(FETCH_DISCOUNT_ITEMS_TERM_TYPES, fetchDiscountItemsTermTypesWorker)]);
  yield all([takeLatest(PUBLISH_WORKING_DISCOUNTS, publishWorkingDiscountsWorker)]);
  yield all([takeLatest(FIND_CURVES, findCurvesWorker)]);
  yield all([takeLatest(APPLY_CURVES, applyCurvesWorker)]);
  yield all([takeLatest(REMOVE_CURVES, removeCurvesWorker)]);
  yield all([takeLatest(CREATE_CURVES, createCurvesWorker)]);
  yield all([takeLatest(UPDATE_CURVES, updateCurvesWorker)]);
  yield all([takeLatest(DELETE_CURVES, deleteCurvesWorker)]);
  yield all([takeLatest(FETCH_USER_OPERATORS, fetchUserOperatorsWorker)]);
  yield all([takeEvery(FETCH_DISCOUNT_HISTORY, fetchDiscountHistory)]);
  yield all([takeLatest(CANCEL_FUTURE_DISCOUNT, cancelFutureDiscountsWorker)]);
}
