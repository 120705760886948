import * as React from 'react';
import { ReactElement } from 'react';
import { Popup } from 'semantic-ui-react';
import { ICellRendererParams } from 'ag-grid-community';
import { Icon, IconSize } from '@wework/ray2';
import { AttributesCell } from '../onDemandPricing.styled';
import { supportedGeoHierarchyAttributes } from '../../store/entities/onDemandPricing';
import { GeoHierarchyAttributePreview } from '../../../../../generated/voyager/graphql';

function AttributeCellRenderer(params: ICellRendererParams): ReactElement {
  if (!params.data) {
    return <></>;
  }

  const id: string[] = params.column?.getColId()?.split('|') ?? [];
  const productId = id[0];

  return (
    <AttributesCell>
      {supportedGeoHierarchyAttributes.map(supAttr => {
        const attribute = params.data.attributePreviews.find(
          (attr: GeoHierarchyAttributePreview) =>
            attr.productId === productId && attr.label === supAttr.label,
        );

        let iconClass;
        switch (attribute?.value) {
          case 'true':
            iconClass = 'truthy';
            break;
          case 'false':
            iconClass = 'falsy';
            break;
          default:
            iconClass = 'neutral';
            break;
        }

        return (
          <Popup
            key={supAttr.label}
            basic
            trigger={<Icon className={iconClass} icon={supAttr.icon} size={IconSize.SMALL} />}
            on={'hover'}
          >
            <Popup.Content>
              {`${supAttr.displayName} = ${
                attribute
                  ? `${attribute.value} (defined at the ${attribute.type} level)`
                  : 'not set'
              }`}
            </Popup.Content>
          </Popup>
        );
      })}
    </AttributesCell>
  );
}

export default AttributeCellRenderer;
