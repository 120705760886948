import { List } from 'semantic-ui-react';
import styled from 'styled-components';
import { red, green } from 'styles/colors';

export const ListItemError = styled(List.Item)`
  color: ${red};
`;

export const ListItemSuccess = styled(List.Item)`
  color: ${green};
`;
