import { Action, handleActions } from 'redux-actions';
import { createRDXConstant } from '../../../../utils/store/store.constants';
import { ActionItemsSubset } from '../entities/actionItems';
import initialState from '../../../../utils/store/initialState';
import { ActionItemGroup, LocationActionItem } from '../../../../generated/voyager/graphql';
import { allActionItemsSelector } from './actionItems.selector';

export const GET_LOCATION_ACTION_ITEMS = createRDXConstant('GET_LOCATION_ACTION_ITEMS');
export const GET_LOCATION_ACTION_ITEMS_ERROR = createRDXConstant('GET_LOCATION_ACTION_ITEMS_ERROR');
export const GET_LOCATION_ACTION_ITEMS_SUCCESS = createRDXConstant(
  'GET_LOCATION_ACTION_ITEMS_SUCCESS',
);
export const CLEAR_ACTION_ITEMS_STORE = createRDXConstant('CLEAR_ACTION_ITEMS_STORE');

// Reducer
export const actionItemsReducer = handleActions<ActionItemsSubset, any>(
  {
    [GET_LOCATION_ACTION_ITEMS]: (
      state: ActionItemsSubset,
      action: Action<{ group: ActionItemGroup }>,
    ) => ({
      ...state,
      locationActionItemLoading: new Map([
        ...state.locationActionItemLoading,
        [action.payload.group, true],
      ]),
    }),
    [GET_LOCATION_ACTION_ITEMS_ERROR]: (
      state: ActionItemsSubset,
      action: Action<ActionItemGroup>,
    ) => ({
      ...state,
      locationActionItemLoading: new Map([
        ...state.locationActionItemLoading,
        [action.payload, false],
      ]),
    }),
    [CLEAR_ACTION_ITEMS_STORE]: (state: ActionItemsSubset) => ({
      ...state,
      actionItems: initialState.actionItems.actionItems,
      locationActionItemLoading: initialState.actionItems.locationActionItemLoading,
    }),
    [GET_LOCATION_ACTION_ITEMS_SUCCESS]: (
      state: ActionItemsSubset,
      action: Action<{
        group: ActionItemGroup;
        locations: LocationActionItem[];
      }>,
    ) => {
      const currentLocationActionItems =
        allActionItemsSelector(state).get(action.payload.group) ?? [];

      const newActionItems = new Map(state.actionItems);
      newActionItems.set(action.payload.group, [
        ...currentLocationActionItems,
        ...action.payload.locations,
      ]);

      return {
        ...state,
        locationActionItemLoading: new Map([
          ...state.locationActionItemLoading,
          [action.payload.group, false],
        ]),
        actionItems: newActionItems,
      };
    },
  },
  initialState.actionItems,
);
