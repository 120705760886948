import { Action, handleActions } from 'redux-actions';
import { get, uniqBy } from 'lodash';
import initialState from '../../../../../utils/store/initialState';
import { DiscountFiltersSubset } from '../../entities/filters';
import { filtersSelectorSubset } from './filters.selector';
import { createRDXConstant } from '../../../../../utils/store/store.constants';
import { PriceableItemFilter } from '../../../../../sharedStore/entities/priceableItemFilters';
import { modifyOldFilterHelper } from '../../../../../utils/helpers';

// Action Constants
export const MODIFY_FILTER_PARAMS = createRDXConstant('MODIFY_FILTER_PARAMS');
export const CLEAR_FILTER_PARAMS = createRDXConstant('CLEAR_FILTER_PARAMS');

// Reducer
export const discountFiltersReducer = handleActions<DiscountFiltersSubset, any>(
  {
    // Reducer to combine filters with existing filters.
    [MODIFY_FILTER_PARAMS]: (
      state: DiscountFiltersSubset,
      action: Action<{
        filterParams: PriceableItemFilter[];
        skuFilterSet?: boolean;
        yesNoFilterSet?: boolean;
        minMaxFilterSet?: boolean;
        discountExpiryFilterSet?: boolean;
      }>,
    ) => {
      // STEP 1: Accessing new Filter.
      const newFilter = get(action, 'filterParams', Array<PriceableItemFilter>());

      // STEP 2: Modifying old filter - This step will provide ability to remove filters.
      const oldFilterModified = modifyOldFilterHelper(
        [...filtersSelectorSubset(state)],
        get(action, 'skuFilterSet', false),
        get(action, 'yesNoFilterSet', false),
        get(action, 'minMaxFilterSet', false),
        get(action, 'discountExpiryFilterSet', false),
      );

      // STEP 3: Merge the filter arrays based on the filter name,
      // We removed from old filters in previous step if needed,
      // and we will add new data in the filters in this step.
      const filters: PriceableItemFilter[] = uniqBy(
        [...newFilter, ...oldFilterModified],
        'filterName',
      );

      // STEP 4: Modify/ Save the filter.
      return {
        ...state,
        filters,
      };
    },
    // Reducer to clear all the filters.
    [CLEAR_FILTER_PARAMS]: (state: DiscountFiltersSubset) => ({
      ...state,
      filters: [],
    }),
  },
  initialState.discountFilters,
);
