// 1  Adding apollo client to the application.
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  HttpOptions,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { getAuthToken } from '../../index';

// 2 Creating a http-link to apollo server.
export const link = (createClientOptions: HttpOptions): ApolloLink =>
  createHttpLink(createClientOptions);

// Getting auth token for graphql request
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAuthToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

// Retry link for network errors: https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
// If the response status is 429 Too Many Requests, wait for anywhere between 0-1 seconds
// (500ms on average, see the documentation for more info) and retry at most 5 times.
// The delay of each subsequent retry is multiplied by 2 each time.
const retryLink = new RetryLink({
  delay: {
    initial: Number(process.env.REACT_APP_MIN_BACKOFF) || 500,
    jitter: true,
  },
  attempts: {
    max: Number(process.env.REACT_APP_MAX_ATTEMPTS) || 5,
    retryIf: (_error, operation) => operation.getContext().response?.status === 429,
  },
});

// BELOW CODE IS FOR BATCHING.
// https://ednsquare.com/story/improving-performance-with-batching-client-graphql-queries------XxKSNF
export const batchLink = (createClientOptions: HttpOptions): BatchHttpLink =>
  new BatchHttpLink(createClientOptions);
// END: BATCHING END.

// 3 creating a apollo client with the above create http-link.
export const createClientFactory = (
  clientOptions: HttpOptions,
  batchClientOptions: HttpOptions,
): ApolloClient<unknown> => {
  const apolloLink = authLink.concat(retryLink);
  const httpLink = apolloLink.concat(link(clientOptions));
  const batchHttpLink = apolloLink.concat(batchLink(batchClientOptions));

  return new ApolloClient({
    link: split(
      operation => operation.getContext().important === true,
      batchHttpLink, // if the test is true -- Batch the request.
      httpLink, // otherwise, -- Do not Batch the request.
    ),
    cache: new InMemoryCache(),
    defaultOptions: {
      /**
       * Using the all policy is the best way to notify your users of potential issues while
       * still showing as much data as possible from your server. It saves both data and errors
       * into the Apollo Cache so your UI can use them.
       *
       * https://www.apollographql.com/docs/react/features/error-handling.html
       */
      query: {
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};
