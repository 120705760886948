import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, IconName, IconSize, IconType, TextField } from '@wework/ray2';
import cn from 'classnames';
import { Radio } from 'semantic-ui-react';
import {
  PanelFilterItem,
  ActiveFilterValues,
  FilterBody,
  FilterHeader,
  FilterItemOption,
  RangeItem,
} from '../../styles/app.styled';
import { PriceableItemFilter } from '../../sharedStore/entities/priceableItemFilters';
import { inputNumberFormatter } from '../../app/pricing/standardPricing/components/helpers';
import { FilterProps } from '../../utils/sharedTypes';

/**
 *  Yes-no filter component. In this case Physical Attributes filter.
 *
 * @param props.saveFilters save filter flag
 * @param props.clearFilters clear filter flag
 * @param props.resetSaveFilters Need this to have the toggle working for consecutive times.
 * @param props.resetClearFilters need this to have the toggle working for consecutive times.
 * @constructor
 */
function YesNoFilterShared({
  saveFilters,
  clearFilters,
  resetSaveFilters,
  resetClearFilters,
  modifyFiltersAction,
  filtersSelector,
}: FilterProps): ReactElement {
  const filters: PriceableItemFilter[] = useSelector(filtersSelector);

  // STATE VARIABLES:
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  // local component value store.
  const [hasWindow, setHasWindow] = useState<boolean | null>(null);
  const [enableInternalRoom, setEnableInternalRoom] = useState<boolean | null>(null);
  const [internalRoomMinValue, setInternalRoomMinValue] = useState<number | null>(null);
  const [internalRoomMaxValue, setInternalRoomMaxValue] = useState<number | null>(null);

  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  // DISPATCH PROPS:
  const dispatch = useDispatch();
  const addFilterParams = useCallback(
    (filterParams: PriceableItemFilter[], yesNoFilterSet: boolean) =>
      dispatch({ type: modifyFiltersAction, filterParams, yesNoFilterSet }),
    [dispatch, modifyFiltersAction],
  );

  const setMinMaxValue = (input: string, type: string) => {
    // Remove anything that isn't a valid number
    const stringReplaced = inputNumberFormatter(input);
    const currentValue = stringReplaced ? Number(stringReplaced) : null;

    switch (type) {
      case 'min':
        setInternalRoomMinValue(currentValue);
        break;
      case 'max':
        setInternalRoomMaxValue(currentValue);
        break;
      default:
        break;
    }
  };

  // If new value and old value are same resetting the filter option.
  const internalRoomSelector = (newValue: boolean) => {
    setInternalRoomMaxValue(null);
    setInternalRoomMinValue(null);
    setEnableInternalRoom(enableInternalRoom === newValue ? null : newValue);
  };

  // If new value and old value are same resetting the filter option.
  const windowSelector = (newValue: boolean) => {
    setHasWindow(hasWindow === newValue ? null : newValue);
  };

  const applyValuesCallback = () => {
    if (!saveFilters) {
      return;
    }

    // 2) Constructing filter params.
    const filterParams: PriceableItemFilter[] = [];
    // Adding or Removing window filter.
    if (hasWindow !== null) {
      filterParams.push({
        filterName: 'hasWindow',
        isSelected: hasWindow,
      });
    }
    // Adding or Removing internal room filter.
    if (enableInternalRoom !== null) {
      let filterParam: PriceableItemFilter = {
        filterName: 'internalRoom',
        isSelected: enableInternalRoom,
      };
      filterParam =
        internalRoomMinValue !== null ? { ...filterParam, min: internalRoomMinValue } : filterParam;
      filterParam =
        internalRoomMaxValue !== null ? { ...filterParam, max: internalRoomMaxValue } : filterParam;
      filterParams.push(filterParam);
    }

    // 3) Storing filter params.
    addFilterParams(filterParams, true);
    resetSaveFilters();
  };
  useEffect(applyValuesCallback, [saveFilters]);

  const clearValuesCallback = () => {
    if (!clearFilters) {
      return;
    }

    setHasWindow(null);
    setEnableInternalRoom(null);
    setInternalRoomMaxValue(null);
    setInternalRoomMinValue(null);
    resetClearFilters();
  };
  useEffect(clearValuesCallback, [clearFilters]);

  // On component Load parse url params.
  const applyValuesFromUrl = () => {
    const hasWindowFilter = filters.find(filter => filter.filterName === 'hasWindow');
    const internalRoomFilter = filters.find(filter => filter.filterName === 'internalRoom');

    // note: filter?.isSelected can be string or boolean
    const hasWindowSelectedVal = hasWindowFilter?.isSelected;
    const internalRoomSelectedVal = internalRoomFilter?.isSelected;
    const internalRoomMinVal = internalRoomFilter?.min;
    const internalRoomMaxVal = internalRoomFilter?.max;

    if (hasWindowSelectedVal !== undefined) {
      setHasWindow(hasWindowSelectedVal.toString() === 'true');
    }
    if (internalRoomSelectedVal !== undefined) {
      setEnableInternalRoom(internalRoomSelectedVal.toString() === 'true');
    }

    if (internalRoomMinVal) {
      setInternalRoomMinValue(Number(internalRoomMinVal));
    }
    if (internalRoomMaxVal) {
      setInternalRoomMaxValue(Number(internalRoomMaxVal));
    }
  };
  useEffect(applyValuesFromUrl, [filters]);

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{'Physical Attributes'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && hasWindow !== null && (
          <ActiveFilterValues>{hasWindow ? 'With Windows' : 'No Windows'}</ActiveFilterValues>
        )}
        {!accordionOpen && enableInternalRoom !== null && (
          <ActiveFilterValues>{!enableInternalRoom ? 'No Internal Rooms' : ''}</ActiveFilterValues>
        )}
        {!accordionOpen && enableInternalRoom !== null && (
          <ActiveFilterValues>
            {enableInternalRoom ? 'With Internal Rooms ' : ''}
            {internalRoomMinValue !== null ? internalRoomMinValue : 'NUMBER_MIN_VALUE'} -
            {internalRoomMaxValue !== null ? internalRoomMaxValue : 'NUMBER_MAX_VALUE'}
          </ActiveFilterValues>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <br />
        <p>Windows</p>
        <FilterItemOption>
          <Radio
            label={
              <span>
                <Icon
                  icon={IconName.WINDOW}
                  size={IconSize.SMALL}
                  type={IconType.OUTLINE}
                  className={'text-gray-70'}
                />
                No Windows
              </span>
            }
            name="hasWindow"
            value="none"
            checked={hasWindow !== null ? !hasWindow : false}
            onClick={() => windowSelector(false)}
          />
        </FilterItemOption>
        <FilterItemOption>
          <Radio
            label={
              <span>
                <Icon
                  icon={IconName.WINDOW}
                  size={IconSize.SMALL}
                  type={IconType.OUTLINE}
                  className={'text-primary'}
                />
                With Windows
              </span>
            }
            name="hasWindow"
            value="none"
            checked={hasWindow !== null ? hasWindow : false}
            onClick={() => windowSelector(true)}
          />
        </FilterItemOption>
        <br />
        <p>Internal Rooms</p>
        <FilterItemOption>
          <Radio
            label={
              <span>
                <Icon
                  icon={IconName.WINDOW}
                  size={IconSize.SMALL}
                  type={IconType.OUTLINE}
                  className={'text-gray-70'}
                />
                No Internal Rooms
              </span>
            }
            name="internalRoom"
            value="none"
            checked={enableInternalRoom !== null ? !enableInternalRoom : false}
            onClick={() => internalRoomSelector(false)}
          />
        </FilterItemOption>
        <FilterItemOption>
          <Radio
            label={
              <span>
                <Icon
                  icon={IconName.WINDOW}
                  size={IconSize.SMALL}
                  type={IconType.OUTLINE}
                  className={'text-primary'}
                />
                With Internal Rooms
              </span>
            }
            name="internalRoom"
            value="none"
            checked={enableInternalRoom !== null ? enableInternalRoom : false}
            onClick={() => internalRoomSelector(true)}
          />
        </FilterItemOption>
        <RangeItem>
          <TextField
            placeholder="Rooms Min"
            onChange={(event: any): void => setMinMaxValue(event?.target?.value, 'min')}
            value={internalRoomMinValue !== null ? internalRoomMinValue : ''}
            disabled={!enableInternalRoom}
          />
          <b />
          <TextField
            placeholder="Rooms Max"
            onChange={(event: any): void => setMinMaxValue(event?.target?.value, 'max')}
            value={internalRoomMaxValue !== null ? internalRoomMaxValue : ''}
            disabled={!enableInternalRoom}
          />
        </RangeItem>
      </FilterBody>
    </PanelFilterItem>
  );
}

export default YesNoFilterShared;
