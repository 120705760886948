import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Checkbox } from 'semantic-ui-react';
import {
  FilterBody,
  PanelFilterItem,
  ActiveFilterValues,
  FilterHeader,
} from '../../styles/app.styled';
import { PriceableItemFilter } from '../../sharedStore/entities/priceableItemFilters';
import { FilterProps } from '../../utils/sharedTypes';

/**
 * Discounts Expiration filters component
 *
 * @param props.saveFilters save filter flag.
 * @param props.clearFilters clear filter flag.
 * @param props.resetSaveFilters Need this to have the toggle working for consecutive times.
 * @param props.resetClearFilters Need this to have the toggle working for consecutive times.
 * @constructor
 */

function DiscountExpiryFilterDiscounts({
  saveFilters,
  clearFilters,
  resetSaveFilters,
  resetClearFilters,
  modifyFiltersAction,
  filtersSelector,
}: FilterProps): ReactElement {
  const filters: PriceableItemFilter[] = useSelector(filtersSelector);

  const [enableExpiringSoon, setEnableExpiringSoon] = useState<boolean>(false);
  const [enableNoDiscount, setEnableNoDiscount] = useState<boolean>(false);

  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);

  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  const dispatch = useDispatch();
  const addFilterParams = useCallback(
    (filterParams: PriceableItemFilter[], discountExpiryFilterSet: boolean) =>
      dispatch({ type: modifyFiltersAction, filterParams, discountExpiryFilterSet }),
    [dispatch, modifyFiltersAction],
  );

  const applyValuesCallback = () => {
    if (!saveFilters) {
      return;
    }

    // 2) Constructing filter params.
    const filterParams: PriceableItemFilter[] = [];
    if (enableExpiringSoon) {
      filterParams.push({
        filterName: 'DiscountExpiringSoon',
        isSelected: enableExpiringSoon,
      });
    }
    if (enableNoDiscount) {
      filterParams.push({
        filterName: 'No Discount',
        isSelected: enableNoDiscount,
      });
    }
    addFilterParams(filterParams, true);
    resetSaveFilters();
  };
  useEffect(applyValuesCallback, [saveFilters]);

  const clearValuesCallback = () => {
    if (!clearFilters) {
      return;
    }

    setEnableExpiringSoon(false);
    setEnableNoDiscount(false);
    resetClearFilters();
  };
  useEffect(clearValuesCallback, [clearFilters]);

  const applyValuesFromUrl = () => {
    const enableExpiringSoonFilter = filters.find(
      filter => filter.filterName === 'DiscountExpiringSoon',
    );
    const enableNoDiscountFilter = filters.find(filter => filter.filterName === 'No Discount');

    // note: filter?.isSelected can be string or boolean
    const enableExpiringSoonVal = enableExpiringSoonFilter?.isSelected;
    const enableNoDiscountVal = enableNoDiscountFilter?.isSelected;

    setEnableExpiringSoon(enableExpiringSoonVal?.toString() === 'true');
    setEnableNoDiscount(enableNoDiscountVal?.toString() === 'true');
  };
  useEffect(applyValuesFromUrl, [filters]);

  return (
    <PanelFilterItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <FilterHeader className="filter-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{'Action Items'}</span>
          <i className="toggle-accordion-icon" />
        </button>
        {!accordionOpen && (
          <>
            <ActiveFilterValues>
              {enableExpiringSoon ? 'Discount Expiring Soon' : ''}
            </ActiveFilterValues>
            <ActiveFilterValues>{enableExpiringSoon ? 'No Discount' : ''}</ActiveFilterValues>
          </>
        )}
      </FilterHeader>
      <FilterBody className="filter-body">
        <Checkbox
          label={'Discount Expiring Soon'}
          id="expiringDiscounts"
          checked={enableExpiringSoon}
          onChange={() => setEnableExpiringSoon(!enableExpiringSoon)}
        />
        <br />
        <Checkbox
          label={'No Discount'}
          id="noDiscounts"
          checked={enableNoDiscount}
          onChange={() => setEnableNoDiscount(!enableNoDiscount)}
        />
      </FilterBody>
    </PanelFilterItem>
  );
}

export default DiscountExpiryFilterDiscounts;
