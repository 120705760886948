import { LocationDescriptorObject } from 'history';

import { User } from '@auth0/auth0-spa-js';
import { StoreInterface } from '../../../utils/store/store.interface';
import { Unassigned } from '../../../utils/sharedTypes';

const authSelector = (state: StoreInterface) => state?.auth;

export const loggedInSelector = (state: StoreInterface): boolean | Unassigned =>
  authSelector(state)?.logIn?.loaded;

export const unAuthenticatedSelector = (state: StoreInterface): boolean | Unassigned =>
  authSelector(state)?.unAuthenticated;

export const tokenSelector = (state: StoreInterface): string | Unassigned =>
  authSelector(state)?.token;

export const referrerSelector = (state: StoreInterface): LocationDescriptorObject | Unassigned =>
  authSelector(state)?.referrer;

export const employeeUuidSelector = (state: StoreInterface): string | Unassigned =>
  authSelector(state)?.user['https://wework.com/employee_uuid'];

export const userSelector = (state: StoreInterface): User | undefined => authSelector(state)?.user;
