import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, Label } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { Button, ButtonSize, ButtonTheme } from '@wework/ray2';
import {
  ActionItemContainer,
  BlockBody,
  BlockContainer,
  BlockHeader,
  CollapsibleHeader,
  GroupName,
  LoadMoreButtonContainer,
  LoadMoreSpinnerContainer,
  SpinnerContainer,
} from './actionItems.styled';
import { ActionItemGroup, PageInfo } from '../../../generated/voyager/graphql';
import Spinner from '../../../assets/spinner.svg';
import {
  groupActionItemsSelector,
  locationActionItemsLoadingSelector,
} from '../store/modules/actionItems.selector';
import getGroupName from '../helpers';
import { actionItemsPageSize } from '../store/entities/actionItems';
import {
  CLEAR_ACTION_ITEMS_STORE,
  GET_LOCATION_ACTION_ITEMS,
} from '../store/modules/actionItems.ducks';
import NavigateButton from './navigateButton';
import { trackAnalytics } from '../../../utils/analytics/helpers';

interface ActionItemBlockProps {
  group: ActionItemGroup;
}

const ActionItemBlock = (props: ActionItemBlockProps) => {
  const [page, setPage] = useState(1);

  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);
  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  const dispatch = useDispatch();
  const getData = useCallback(
    (pageInfo: PageInfo) =>
      dispatch({
        type: GET_LOCATION_ACTION_ITEMS,
        payload: { group: props.group, page: pageInfo },
      }),
    [dispatch, props.group],
  );

  const clearAll = useCallback(() => {
    dispatch({ type: CLEAR_ACTION_ITEMS_STORE });
  }, [dispatch]);

  useEffect(() => {
    getData({ page, size: actionItemsPageSize });
  }, [getData, page]);

  useEffect(
    () => () => {
      clearAll();
    },
    [clearAll],
  );

  const loading = useSelector(locationActionItemsLoadingSelector(props.group));
  const locationItems = useSelector(groupActionItemsSelector(props.group));

  function toggleAccordionAndTrackAnalytics() {
    toggleAccordionState();
    trackAnalytics('Action Item Group Expand', {
      workflow: 'Action Item Accordion Button Toggle',
      object_type: 'button',
      object_name: 'Toggle Accordion',
      object_value: props.group.toString(),
    });
  }

  function setPageAndTrackAnalytics() {
    setPage(page + 1);
    trackAnalytics('Load more action items', {
      workflow: 'Load More Button Click',
      object_type: 'button',
      object_name: 'Load More',
      object_value: `${props.group.toString()} ${page + 1}`,
    });
  }

  return (
    <div>
      <BlockContainer className={cn({ open: accordionOpen, closed: !accordionOpen })}>
        <BlockHeader className="section-header">
          <CollapsibleHeader type="button" onClick={toggleAccordionAndTrackAnalytics}>
            <i className="toggle-accordion-icon" />
            <GroupName> {getGroupName(props.group)}</GroupName>
          </CollapsibleHeader>
        </BlockHeader>
        <BlockBody className="section-body">
          <div>
            {!isEmpty(locationItems) ? (
              <div>
                {locationItems.map(item => (
                  <ActionItemContainer key={item.locationId}>
                    {item.count !== null && (
                      <Label color={'blue'} size={'small'} circular basic content={item.count} />
                    )}{' '}
                    {item.locationName}
                    <NavigateButton group={props.group} location={item.locationId} />
                  </ActionItemContainer>
                ))}
                {loading ? (
                  <LoadMoreSpinnerContainer>
                    <Icon as="img" link src={Spinner} size={'large'} />
                  </LoadMoreSpinnerContainer>
                ) : (
                  <LoadMoreButtonContainer>
                    <Button
                      className="card-additional-button"
                      size={ButtonSize.SMALL}
                      theme={ButtonTheme.OUTLINE}
                      disabled={locationItems.length % actionItemsPageSize !== 0}
                      onClick={setPageAndTrackAnalytics}
                    >
                      Load more ...
                    </Button>
                  </LoadMoreButtonContainer>
                )}
              </div>
            ) : (
              <SpinnerContainer>
                {loading ? <Icon as="img" link src={Spinner} size={'large'} /> : 'No location'}
              </SpinnerContainer>
            )}
          </div>
        </BlockBody>
      </BlockContainer>
    </div>
  );
};
export default ActionItemBlock;
