import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import cn from 'classnames';
import { v4 as uuidV4 } from 'uuid';
import { Table } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { isPercentageNull } from '../../../../pricing/standardPricing/components/helpers';
import { TableBlockWrapper } from './discountsDetailSidePanel.styled';
import { TableViewDiscountColumn } from '../../../store/entities/discountItems';
import {
  PanelSectionItem,
  SectionBody,
  SectionHeader,
} from '../../../../../sharedComponents/sidePanelComponent/detailSidePanel.styled';
import { CurveTerm } from '../../../../../generated/voyager/graphql';

interface DiscountsItemDetailHeaderProps {
  title: string;
  flattenDistributionData: TableViewDiscountColumn;
  selectedCurveTerm?: CurveTerm;
}

function DiscountsItemDetailHeader({
  title,
  flattenDistributionData,
  selectedCurveTerm,
}: DiscountsItemDetailHeaderProps): ReactElement {
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);
  const toggleAccordionState = useCallback(
    () => setAccordionOpen(prevState => !prevState),
    [setAccordionOpen],
  );

  if (isEmpty(flattenDistributionData)) {
    return <></>;
  }

  return (
    <PanelSectionItem className={cn({ open: accordionOpen, closed: !accordionOpen })}>
      <SectionHeader className="section-header">
        <button type="button" onClick={toggleAccordionState}>
          <span>{title}</span>
          <i className="toggle-accordion-icon" />
        </button>
      </SectionHeader>
      <SectionBody className="section-body">
        <TableBlockWrapper className="term-table">
          <Table basic="very" className="discounts-terms-details-table">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Month Term</Table.HeaderCell>
                <Table.HeaderCell>Base Discount</Table.HeaderCell>
                <Table.HeaderCell>Discount Curve</Table.HeaderCell>
                <Table.HeaderCell>Total Discount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!isEmpty(flattenDistributionData) ? (
                flattenDistributionData.distribution.map(value => {
                  const curveValue = selectedCurveTerm
                    ? selectedCurveTerm.dataPoints.find(dp => dp.month === value.month)?.value ?? 0
                    : null;
                  const total = Math.min((value.base ?? 0) + (curveValue ?? 0), 1);

                  return (
                    <Table.Row key={uuidV4()}>
                      <Table.Cell>M{value.month}</Table.Cell>
                      <Table.Cell>{isPercentageNull(value.base)}</Table.Cell>
                      <Table.Cell>{isPercentageNull(curveValue)}</Table.Cell>
                      <Table.Cell>{isPercentageNull(total)}</Table.Cell>
                    </Table.Row>
                  );
                })
              ) : (
                <Table.Row className="no-data">
                  <Table.Cell colSpan={4}>No data available</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </TableBlockWrapper>
      </SectionBody>
    </PanelSectionItem>
  );
}

export default DiscountsItemDetailHeader;
