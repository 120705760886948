/* Styled components */
import styled from 'styled-components';
import { darkBlue, darkGray, lighterGray } from '../../styles/colors';
import { device } from '../../styles/app.styled';

/* Base sizing variables */
export const CellWidth = '104px';
export const CellWidthSmall = '80px';
export const CellWidthLarge = '124px';
export const CellWidthCheckbox = '32px';
export const CellHeight = '56px';
export const LoadingCellHeight = '168px';
export const CellTextareaWidth = '338px';

export const RegularWidthColumnCount = 16;

/* Responsive size variables */
export const CellWidthLaptopL = '104px';
export const CellWidthDesktop = '124px';

export const TableLayoutWrapper = styled.div`
  position: relative;
  max-width: 100%;
  border: 0;
  margin: 0;
  padding-top: 61px;
  font-size: 14px;
  color: ${darkGray};
`;
export const PricingTableWrapper = styled.div`
  position: relative;
  max-height: calc(75vh - 70px);
  padding: 0 0 2px;
  margin: 40px;
  overflow: hidden;
  > .pricing-table.ui.table {
    * {
      box-sizing: border-box;
    }
    box-shadow: 0 1px 0 0 ${lighterGray};
    border: 0;
    border-radius: 0 0.25rem 0.25rem 0.25rem;
    margin: 0;
    > tbody {
      tr {
        td {
          height: ${CellHeight};
          background: white;
          border: 0;
          border-top: 1px solid ${lighterGray};
          line-height: 20px;
          font-size: 12px;
          font-weight: 400;
          color: ${darkBlue};
          /* Loading spinner */
          &.empty-loading-placeholder {
            height: ${LoadingCellHeight};
          }
        }
      }
    }
  }
`;
export const LoadingBox = styled.div`
  /* Width need to be almost the same as the table (-2px from borders) */
  width: calc(
    ${CellWidth} * ${RegularWidthColumnCount} - 2px + ${CellWidthSmall} + ${CellWidthCheckbox} * 2 +
      ${CellWidthLarge} + ${CellTextareaWidth}
  );
  @media all and ${device.laptopL} {
    width: calc(
      ${CellWidthLaptopL} * ${RegularWidthColumnCount} - 2px + ${CellWidthSmall} +
        ${CellWidthCheckbox} * 2 + ${CellWidthLarge} + ${CellTextareaWidth}
    );
  }
  @media all and ${device.desktop} {
    width: calc(
      ${CellWidthDesktop} * ${RegularWidthColumnCount} - 2px + ${CellWidthSmall} +
        ${CellWidthCheckbox} * 2 + ${CellWidthLarge} + ${CellTextareaWidth}
    );
  }
  height: ${LoadingCellHeight};
  padding: 8px;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  right: 1px;
  bottom: 0;
  left: 1px;
  z-index: 4;
`;
