import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { isEmpty } from 'lodash';
import { graphQLClient } from '../../../index';
import { FETCH_MARKETS_BY_TERRITORY, FETCH_MARKETS_BY_TERRITORY_SUCCESS } from './markets.ducks';
import { errorHandlerActive } from '../../../utils/errorHandling/helpers';
import FETCH_MARKETS_BY_TERRITORY_QUERY from './markets.query';

const fetchMarketsByTerritory = (territoryId: string): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_MARKETS_BY_TERRITORY_QUERY,
    variables: { territoryId },
  });

// Saga - fetch all markets based on selected market.
function* fetchMarketsByTerritoryWorker(action: Action<string>) {
  try {
    const { errors, data } = yield call(fetchMarketsByTerritory, action?.payload ?? '');
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }
    yield put({ type: FETCH_MARKETS_BY_TERRITORY_SUCCESS, payload: data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

export default function* marketSaga(): any {
  yield all([takeLatest(FETCH_MARKETS_BY_TERRITORY, fetchMarketsByTerritoryWorker)]);
}
