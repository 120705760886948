import { gql } from '@apollo/client';

const FETCH_LOCATIONS_ACTION_ITEMS = gql`
  query LocationActionItems($group: ActionItemGroup!, $page: PageInfo!) {
    locationActionItems(group: $group, page: $page) {
      locationId
      locationName
      count
    }
  }
`;

export default FETCH_LOCATIONS_ACTION_ITEMS;
