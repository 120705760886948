import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { push } from 'connected-react-router';
import { MainRegionStyled, SummaryWrapper } from '../../../styles/app.styled';
import DiscountPublish from '../components/discountPublish/discountPublish';
import BreadcrumbComponent from '../../../sharedComponents/breadcrumb/breadcrumbComponent';
import BottomPanelDiscountPublish from '../components/bottomPanel/bottomPanelDiscountPublish';
import { CLEAR_PREV_DISCOUNT_ITEMS } from '../store/modules/discountItems/discountItems.ducks';
import ROUTES from '../../../utils/routes/routes';
import { AuthzProps } from '../../../utils/constants';
import EmployeePermissions from '../../../utils/store/permissions';

function DiscountPublishHome({ requestedPermissions }: AuthzProps): ReactElement {
  // DISPATCH PROPS
  const dispatch = useDispatch();

  const clearPrevDiscountItems = useCallback(() => {
    dispatch({ type: CLEAR_PREV_DISCOUNT_ITEMS });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (
      !get(
        requestedPermissions,
        EmployeePermissions.voyager_dedicated_space_discount_publish,
        false,
      )
    ) {
      redirectToAccessDenied();
    }

    return () => {
      clearPrevDiscountItems();
    };
  }, [clearPrevDiscountItems, requestedPermissions, redirectToAccessDenied]);

  return (
    <>
      <MainRegionStyled>
        <BreadcrumbComponent />
        <SummaryWrapper>
          <DiscountPublish />
        </SummaryWrapper>
      </MainRegionStyled>
      <BottomPanelDiscountPublish />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_discount_publish],
})(DiscountPublishHome);
