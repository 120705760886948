import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { REDIRECT_REQUEST } from '../sharedStore/modules/auth/auth.ducks';

function Callback(): null {
  // Dispatching Actions
  const dispatch = useDispatch();
  // Dispatching props in functional component.
  const redirectRequest = useCallback(() => dispatch({ type: REDIRECT_REQUEST }), [dispatch]);

  const helperRedirectRequest = () => {
    redirectRequest();
  };

  React.useEffect(helperRedirectRequest, []);

  return null;
}

export default Callback;
