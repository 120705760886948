// GraphQL Queries
import { gql } from '@apollo/client';

// Mutation to store the current market price.
export const CREATE_BREAKEVEN_WORKING_DATA_MUTATION = gql`
  mutation WorkingLocationBreakEven(
    $workingLocationBreakEvenInput: WorkingLocationBreakEvenInput!
  ) {
    workingLocationBreakEven(workingLocationBreakEvenInput: $workingLocationBreakEvenInput) {
      success
      errors
    }
  }
`;

export const PUBLISH_LOCATION_BREAKEVEN_DATA_MUTATION = gql`
  mutation PublishLocationBreakEvenData($locationIds: [ID!]!, $validFrom: Date) {
    publishLocationBreakEvenData(locationIds: $locationIds, validFrom: $validFrom) {
      success
      errors
    }
  }
`;
