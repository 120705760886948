import * as React from 'react';
import { ReactElement } from 'react';
import { Redirect, RouteProps } from 'react-router-dom';
import InventoryReconciliationView from 'app/adminPanel/pages/inventoryReconciliationView';
import InventoryReconciliationByLocationView from 'app/adminPanel/pages/inventoryReconciliationByLocationView';
import AdminPanelView from 'app/adminPanel/pages/adminPanelView';
import ROUTES from './routes';
import VoyagerHome from '../../commonPages/voyagerHome';
import ErrorPage from '../../commonPages/error/error';
import AccessDenied from '../../commonPages/accessDenied';
import DiscountTableView from '../../app/discounts/pages/discountTableView';
import PricingPublishHome from '../../app/pricing/standardPricing/pages/pricingPublishHome';
import OnDemandPricingHome from '../../app/pricing/onDemandPricing/pages/onDemandPricingHome';
import StandardPricingTableView from '../../app/pricing/standardPricing/pages/standardPricingTableView';
import DiscountPublishHome from '../../app/discounts/pages/discountPublishHome';
import MultipleLocationSearchHome from '../../commonPages/multipleLocationSearchHome';
import { MultipleLocationSearchProps } from '../sharedTypes';
import BreakevenTableView from '../../app/breakeven/pages/breakevenTableView';
import BreakevenPublishHome from '../../app/breakeven/pages/breakevenPublishHome';
import AccessDeniedPage from '../../commonPages/accessDeniedPage';
import DiscountCurveManagementView from '../../app/discounts/pages/discountCurveManagementView';
import UploadDiscountsRoute from './uploadDiscountsRoute';
import UploadPricesRoute from './uploadPricesRoute';
import LocationReconciliationView from '../../app/adminPanel/pages/locationReconciliationView';
import ResetBreakEvenView from '../../app/adminPanel/pages/resetBreakEvenView';
import OnDemandPricingPublishHome from '../../app/pricing/onDemandPricing/pages/onDemandPricingPublishHome';
import SnowflakeSyncInitializationView from '../../app/adminPanel/pages/snowflakeSyncInitializationView';
import SpacemanPricesConsolidationView from '../../app/adminPanel/pages/spacemanPricesConsolidationView';
import GeoHierarchyReconciliationView from '../../app/adminPanel/pages/geoHierarchyReconciliationView';
import PublishPricesView from '../../app/adminPanel/pages/priceSyncAttemptsView';
import ActionItemView from '../../app/actionItems/pages/actionItemView';
import PublishDiscountsView from '../../app/adminPanel/pages/discountSyncAttemptsView';

const pricingProps: MultipleLocationSearchProps = {
  title: 'Pricing Management',
  buttons: [
    {
      title: 'Price',
      route: ROUTES.STANDARD_PRICING,
    },
  ],
};
const discountProps: MultipleLocationSearchProps = {
  title: 'Discounts Management',
  buttons: [
    {
      title: 'Discount',
      route: ROUTES.DISCOUNT_TABLEVIEW,
    },
  ],
};
const breakevenProps: MultipleLocationSearchProps = {
  title: 'Breakeven Price',
  allowDifferentCurrencies: true,
  buttons: [
    {
      title: 'Manage Breakeven Price',
      route: ROUTES.BREAKEVEN_TABLEVIEW,
    },
  ],
};

const authenticatedRoutes: RouteProps[] = [
  {
    exact: true,
    path: ROUTES.ROOT,
    render(): any {
      return <Redirect to={ROUTES.HOME} />;
    },
  },
  {
    exact: false,
    path: ROUTES.HOME,
    component: VoyagerHome,
  },
  {
    exact: true,
    path: ROUTES.PRICING,
    component(): ReactElement {
      return <MultipleLocationSearchHome {...pricingProps} />;
    },
  },
  {
    exact: false,
    path: ROUTES.UPLOAD_PRICING_FROM_FILE,
    component: UploadPricesRoute,
  },
  {
    exact: false,
    path: `${ROUTES.STANDARD_PRICING}/:locationsUrl?/:filtersUrl?`,
    component: StandardPricingTableView,
  },
  {
    exact: false,
    path: ROUTES.PUBLISH_SUMMARY,
    component: PricingPublishHome,
  },
  {
    exact: false,
    path: ROUTES.ON_DEMAND_PRICING,
    component: OnDemandPricingHome,
  },
  {
    exact: true,
    path: ROUTES.DISCOUNT,
    component(): ReactElement {
      return <MultipleLocationSearchHome {...discountProps} />;
    },
  },
  {
    exact: false,
    path: ROUTES.UPLOAD_DISCOUNTS_FROM_FILE,
    component: UploadDiscountsRoute,
  },
  {
    exact: false,
    path: `${ROUTES.DISCOUNT_TABLEVIEW}/:locationsUrl?/:filtersUrl?`,
    component: DiscountTableView,
  },
  {
    exact: false,
    path: ROUTES.PUBLISH_DISCOUNT_SUMMARY,
    component: DiscountPublishHome,
  },
  {
    exact: false,
    path: ROUTES.PUBLISH_ON_DEMAND_SUMMARY,
    component: OnDemandPricingPublishHome,
  },
  {
    exact: true,
    path: ROUTES.BREAKEVEN,
    component(): ReactElement {
      return <MultipleLocationSearchHome {...breakevenProps} />;
    },
  },
  {
    exact: false,
    path: `${ROUTES.BREAKEVEN_TABLEVIEW}/:locationsUrl?`,
    component: BreakevenTableView,
  },
  {
    exact: false,
    path: ROUTES.BREAKEVEN_SUMMARY,
    component: BreakevenPublishHome,
  },
  {
    exact: true,
    path: ROUTES.ERROR,
    component: ErrorPage,
  },
  {
    exact: true,
    path: ROUTES.ACCESS_DENIED,
    component: AccessDenied,
  },
  {
    exact: true,
    path: ROUTES.ACCESS_DENIED_PAGE,
    component: AccessDeniedPage,
  },
  {
    exact: true,
    path: ROUTES.DISCOUNT_CURVE_MANAGEMENT,
    component: DiscountCurveManagementView,
  },
  {
    exact: false,
    path: ROUTES.ACTION_ITEMS,
    component: ActionItemView,
  },
  {
    exact: true,
    path: ROUTES.ADMIN_PANEL,
    component: AdminPanelView,
  },
  {
    exact: true,
    path: ROUTES.INVENTORY_RECONCILIATION,
    component: InventoryReconciliationView,
  },
  {
    exact: true,
    path: ROUTES.INVENTORY_RECONCILIATION_BY_LOCATION,
    component: InventoryReconciliationByLocationView,
  },
  {
    exact: true,
    path: ROUTES.LOCATION_RECONCILIATION,
    component: LocationReconciliationView,
  },
  {
    exact: true,
    path: ROUTES.GEO_HIERARCHY_RECONCILIATION,
    component: GeoHierarchyReconciliationView,
  },
  {
    exact: true,
    path: ROUTES.RESET_BREAKEVEN,
    component: ResetBreakEvenView,
  },
  {
    exact: true,
    path: ROUTES.SNOWFLAKE_SYNC_INITIALIZATION,
    component: SnowflakeSyncInitializationView,
  },
  {
    exact: true,
    path: ROUTES.SPACEMAN_PRICES_CONSOLIDATION,
    component: SpacemanPricesConsolidationView,
  },
  {
    exact: true,
    path: ROUTES.PRICE_SYNC_ATTEMPTS,
    component: PublishPricesView,
  },
  {
    exact: true,
    path: ROUTES.DISCOUNT_SYNC_ATTEMPTS,
    component: PublishDiscountsView,
  },
];

export default authenticatedRoutes;
