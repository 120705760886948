import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import AppConfig from '../../../../../config';
import { getAuthToken } from '../../../../../index';
import {
  GEO_HIERARCHY_RECONCILIATION,
  GEO_HIERARCHY_RECONCILIATION_ERROR,
  GEO_HIERARCHY_RECONCILIATION_SUCCESS,
} from './geoHierarchyReconciliation.ducks';
import { SET_JOB_STATUS } from '../jobStatus/jobStatus.ducks';

export const geoHierarchyReconciliationRequest = (): Promise<AxiosResponse<any>[]> => {
  const geoHierarchyReconciliationUrl = `${AppConfig.BACKEND_EXTRACTOR_REST_URL}/admin/geo-hierarchy-backfill`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(geoHierarchyReconciliationUrl, null, { headers });
};

export function* geoHierarchyReconciliationWorker() {
  const response: AxiosResponse | undefined = yield call(geoHierarchyReconciliationRequest);
  if (response?.status === 200) {
    yield put({ type: GEO_HIERARCHY_RECONCILIATION_SUCCESS });
    toast.success(
      'Geo hierarchy reconciliation is started! An email will be sent to you once the job is done.',
    );
    yield put({ type: SET_JOB_STATUS, data: { job: 'GEO_HIERARCHY_RECONCILIATION' } });
  } else {
    toast.error('Geo hierarchy reconciliation error');
    yield put({ type: GEO_HIERARCHY_RECONCILIATION_ERROR, payload: response?.data?.message });
  }
}

export default function* geoHierarchyReconciliationSaga(): any {
  yield all([takeLatest(GEO_HIERARCHY_RECONCILIATION, geoHierarchyReconciliationWorker)]);
}
