import { ColSpanParams, GetQuickFilterTextParams, GridOptions, IRowNode } from 'ag-grid-community';
import {
  ColDef,
  ColGroupDef,
  HeaderClassParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { find } from 'lodash';
import { GetRowIdParams } from 'ag-grid-community/dist/lib/interfaces/iCallbackParams';
import { GetRowIdFunc } from 'ag-grid-community/dist/lib/entities/gridOptions';
import { currentMultipleLocationDetailsSelector } from 'sharedStore/modules/locations/locations.selector';
import store from 'index';
import EmptyTableComponentRenderer from 'sharedComponents/tableComponent/tableEmpty';
import {
  dateAtLocationZoneToStringVF,
  getMainMenuItemsWithoutReset,
  getMaxPageInSkuGroup,
} from 'utils/helpers';
import physicalAttributesCellRender from './renderers/physicalAttributesCellRender';
import {
  adjByField,
  colorAdjByFieldPromoCellClassRules,
  colorPromoCellClass,
  getAttributesValueHelper,
  lowestNetPriceCalculationVG,
  lowestNetPriceMinusMinPriceCalculationVG,
  positiveNegativeFormatter,
  stringToNumberValidationCurrencyVF,
  stringToNumberValidationVF,
  stringToStringValidationVF,
  suggAndCurrPriceDiff,
  toPercentageVF,
  transformToListPrice,
  workingPriceToListPrice,
} from './standardPricingTable.helper';
import WorkingPriceReasonCellRenderer from './renderers/workingPriceReasonCellRenderer';
import DetailPanelTrigger from './renderers/detailPanelTrigger';
import WorkingPriceCellRenderer from './renderers/workingPriceCellRenderer';
import LocationDetailsRenderer from './renderers/locationDetailsRenderer';
import ChangelogAndScheduleCellRender from './renderers/changelogAndScheduleCellRenderer';
import TableLoadingRenderer from '../../../../../../sharedComponents/tableComponent/tableLoadingRenderer';
import { StandardPricingTableViewItem } from '../../../store/entities/standardPricingItems';
import { Location } from '../../../../../../generated/voyager/graphql';

export const defaultColDef: ColDef = {
  flex: 1,
  resizable: true,
  menuTabs: ['generalMenuTab'],
  suppressMovable: true,
  getQuickFilterText(params: GetQuickFilterTextParams): string {
    const field = params.colDef.field;
    // just search by the reservable name or location name in the grid
    if (field === 'name') {
      return params.value;
    } else if (field === 'location.id') {
      const state = store.getState();
      const locationDetails = currentMultipleLocationDetailsSelector(state);
      const location = find(locationDetails, ['id', params.value]) ?? ({} as Location);
      return location.name;
    }
    return '';
  },
};

const getRowId: GetRowIdFunc = (data: GetRowIdParams): string => data.data?.id;

const selectorDisabled = (params: HeaderClassParams): string =>
  params.context.isListPriceMode ? 'disabled' : '';

const shouldRerenderChangelogAndSchedule = (
  pi1?: StandardPricingTableViewItem,
  pi2?: StandardPricingTableViewItem,
): boolean => pi1?.workingPrice?.price === pi2?.workingPrice?.price && pi1?.prices === pi2?.prices;

export const columnDefs = (editPermissions: boolean): (ColDef | ColGroupDef)[] => [
  {
    headerName: 'Overview',
    headerClass: 'bg-color-light',
    children: [
      {
        colId: 'selectionCheckbox',
        headerName: '',
        checkboxSelection: editPermissions,
        headerCheckboxSelection: editPermissions,
        headerCheckboxSelectionFilteredOnly: true,
        width: 40,
        minWidth: 40,
        maxWidth: 40,
        headerClass: selectorDisabled,
        cellClassRules: {
          disabled: (params: any) => params.context.isListPriceMode,
        },
        suppressMenu: true,
        resizable: false,
        lockPosition: true,
      },
      {
        headerName: '',
        cellRenderer: DetailPanelTrigger,
        width: 30,
        maxWidth: 30,
        minWidth: 30,
        cellClass: 'rh-center no-padding',
        suppressMenu: true,
        resizable: false,
        lockPosition: true,
      },
      {
        maxWidth: 45,
        width: 45,
        minWidth: 45,
        cellClass: 'rh-center no-padding',
        cellRenderer: ChangelogAndScheduleCellRender,
        valueGetter: (params: ValueGetterParams) => params.data,
        equals: shouldRerenderChangelogAndSchedule,
        suppressMenu: true,
        resizable: false,
        lockPosition: true,
      },
      {
        field: 'location.id',
        rowGroup: true,
        filter: true,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Location',
        showRowGroup: 'location.id',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
          innerRenderer: LocationDetailsRenderer,
          suppressCount: true,
        },
        colSpan: (params: ColSpanParams) => {
          const field = params?.node?.field;
          if (field === 'location.id') {
            return 2;
          }
          return 1;
        },
        minWidth: 160,
        width: 160,
        colId: 'locationName',
      },
      {
        field: 'sku',
        rowGroup: true,
        filter: true,
        sortable: true,
        sort: 'asc',
        comparator: (_: any, _2: any, skuGroup1: IRowNode, skuGroup2: IRowNode) =>
          getMaxPageInSkuGroup(skuGroup1) - getMaxPageInSkuGroup(skuGroup2),
        hide: true,
      },
      {
        headerName: 'Sku',
        showRowGroup: 'sku',
        cellRenderer: 'agGroupCellRenderer',
        minWidth: 110,
        width: 110,
        colId: 'sku',
      },
      {
        field: 'page',
        sort: 'asc',
        hide: true,
      },
      {
        headerName: 'Reservable Name',
        field: 'name',
        filter: true,
        sortable: true,
        cellClass: 'background-gray',
        minWidth: 140,
        width: 140,
      },
      {
        headerName: 'Physical Attributes',
        cellRenderer: physicalAttributesCellRender,
        minWidth: 115,
        width: 115,
        colId: 'physicalAttributes',
      },
      {
        // Need this for download grid multiple column physical attributes.
        cellRenderer: physicalAttributesCellRender,
        colId: 'physicalAttributes2',
        hide: true,
      },
      {
        headerName: 'SqFt (RSF)',
        sortable: true,
        field: 'squareFootage',
        cellClass: 'background-gray',
        minWidth: 105,
        width: 105,
        valueGetter: getAttributesValueHelper,
        valueFormatter: stringToNumberValidationVF,
      },
    ],
  },
  {
    headerName: 'Performance Data',
    headerClass: 'bg-color-dark',
    children: [
      {
        headerName: 'Current ARPM Price',
        colId: 'currentPriceARPM',
        sortable: true,
        field: 'currentPrice.price',
        minWidth: 160,
        width: 160,
        valueFormatter: stringToNumberValidationCurrencyVF,
        headerTooltip: 'Current ARPM Price',
      },
      {
        headerName: 'Current List Price',
        colId: 'currentPriceLP',
        sortable: true,
        field: 'currentPrice.price',
        minWidth: 160,
        width: 160,
        valueGetter: transformToListPrice,
        valueFormatter: stringToNumberValidationCurrencyVF,
        headerTooltip: 'Current List Price',
      },
      {
        headerName: 'Location SKU Occupancy',
        sortable: true,
        cellClass: 'background-gray',
        field: 'locationSkuOccupancy',
        minWidth: 150,
        width: 150,
        headerTooltip: 'Location SKU Occupancy',
      },
      {
        headerName: 'Market SKU Occupancy',
        sortable: true,
        field: 'marketSkuOccupancy',
        minWidth: 140,
        width: 140,
        headerTooltip: 'Market SKU Occupancy',
      },
      {
        headerName: 'Months Vacant',
        sortable: true,
        cellClass: 'background-gray',
        field: 'metrics.monthsVacant',
        minWidth: 120,
        width: 120,
        valueFormatter: stringToNumberValidationVF,
        colId: 'monthsVacant',
      },
    ],
  },
  {
    headerName: 'Pricing',
    headerClass: 'bg-color-light',
    children: [
      {
        headerName: 'Last Published',
        sortable: true,
        minWidth: 130,
        width: 130,
        valueFormatter: dateAtLocationZoneToStringVF,
        colId: 'lastPublished',
        field: 'currentPrice.validFrom',
      },
      {
        headerName: 'Suggested Price',
        colId: 'suggestedPriceARPM',
        sortable: true,
        cellClass: 'background-gray',
        field: 'recommendedPrice.price',
        minWidth: 170,
        width: 170,
        valueFormatter: stringToNumberValidationCurrencyVF,
        headerTooltip: 'Suggested Price',
      },
      {
        headerName: 'Suggested Price',
        colId: 'suggestedPriceLP',
        sortable: true,
        cellClass: 'background-gray',
        field: 'recommendedPrice.price',
        minWidth: 170,
        width: 170,
        valueGetter: transformToListPrice,
        valueFormatter: stringToNumberValidationCurrencyVF,
        headerTooltip: 'Suggested Price',
      },
      {
        headerName: 'Sugg. & Curr. Price Diff %',
        sortable: true,
        minWidth: 150,
        width: 150,
        valueGetter: suggAndCurrPriceDiff,
        cellClass: colorPromoCellClass,
        headerTooltip: 'Sugg. & Curr. Price Diff %',
        colId: 'suggCurrPriceDiff',
      },
      {
        headerName: 'Working Price',
        colId: 'workingPriceARPM',
        sortable: true,
        cellRenderer: WorkingPriceCellRenderer,
        cellClass: 'background-gray rh-center no-padding-right',
        field: 'workingPrice.price',
        minWidth: 150,
        width: 150,
        valueFormatter: stringToNumberValidationCurrencyVF,
      },
      {
        headerName: 'Working Price',
        colId: 'workingPriceLP',
        sortable: true,
        cellClass: 'background-gray',
        field: 'workingPrice.price',
        minWidth: 150,
        width: 150,
        valueGetter: workingPriceToListPrice,
        valueFormatter: stringToNumberValidationCurrencyVF,
      },
      {
        headerName: 'Adj by',
        sortable: true,
        minWidth: 160,
        width: 160,
        valueGetter: adjByField,
        cellClassRules: colorAdjByFieldPromoCellClassRules,
        colId: 'adjBy',
      },
      {
        headerName: 'Reason',
        sortable: true,
        cellClass: 'background-gray rh-center',
        field: 'workingPrice.lastChangeReason',
        minWidth: 250,
        width: 250,
        cellRenderer: WorkingPriceReasonCellRenderer,
        valueFormatter: stringToStringValidationVF,
        colId: 'lastChangeReason',
      },
    ],
  },
  {
    headerName: 'Net Price',
    headerClass: 'bg-color-dark',
    children: [
      {
        headerName: 'Peak Discount',
        colId: 'peakDiscount',
        sortable: true,
        field: 'maxDiscount',
        minWidth: 130,
        width: 130,
        valueFormatter: toPercentageVF,
        headerTooltip: 'Peak Discount',
      },
      {
        headerName: 'Lowest Net Price',
        sortable: true,
        cellClass: 'background-gray',
        minWidth: 140,
        width: 140,
        valueGetter: lowestNetPriceCalculationVG,
        valueFormatter: stringToNumberValidationCurrencyVF,
        headerTooltip: 'Lowest Net Price',
        colId: 'lowestNetPrice',
      },
      {
        headerName: 'Lowest Net - Breakeven',
        sortable: true,
        minWidth: 150,
        width: 150,
        cellClass: colorPromoCellClass,
        valueGetter: lowestNetPriceMinusMinPriceCalculationVG,
        valueFormatter: positiveNegativeFormatter,
        headerTooltip: 'Lowest Net - Breakeven',
        colId: 'lowestNetBreakeven',
      },
    ],
  },
];

export const gridOptions: GridOptions = {
  rowSelection: 'multiple',
  defaultColDef,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  groupDisplayType: 'custom',
  rowHeight: 56,
  groupSelectsChildren: true,
  groupSelectsFiltered: true,
  suppressDragLeaveHidesColumns: true,
  getRowId,
  loadingOverlayComponent: TableLoadingRenderer,
  noRowsOverlayComponent: EmptyTableComponentRenderer,
  showOpenedGroup: true,
  groupDefaultExpanded: 2,
  getMainMenuItems: getMainMenuItemsWithoutReset,
  debounceVerticalScrollbar: true,
  suppressColumnVirtualisation: true,
  suppressGroupRowsSticky: true,
};
