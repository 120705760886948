import { Icon } from '@wework/dieter-ui';
import React from 'react';
import { LabelWrapper } from './removableLabel.styled';

interface RemovableLabelInterface {
  labelText: string;
  disabled: boolean;
  onDelete: Function;
}

const RemovableLabel = ({ labelText, disabled, onDelete }: RemovableLabelInterface) => (
  <LabelWrapper image>
    {labelText}
    <Icon name="delete" disabled={disabled} onClick={onDelete} />
  </LabelWrapper>
);

export default RemovableLabel;
