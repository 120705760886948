import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UploadFile from 'sharedComponents/uploadFile/uploadFile';
import HeaderComponent from 'sharedComponents/header/header';
import BottomPanelUploadFile from 'sharedComponents/uploadFile/bottomPanelUploadFile';
import { OverflowHack } from 'styles/app.styled';
import {
  publishFileErrorsSelector,
  publishFileFormatErrorSelector,
  publishFileLoadedFileSelector,
  publishFileLoadingSelector,
  publishFileReasonSelector,
} from 'sharedStore/modules/uploadFile/uploadFile.selector';
import {
  RESET_UPLOAD_FILE_STATE,
  UPLOAD_FILE,
  UPLOAD_FILE_SET_EXPIRATION_DATE,
  UPLOAD_FILE_SET_PUBLISH_DATE,
} from 'sharedStore/modules/uploadFile/uploadFile.ducks';
import ROUTES from '../utils/routes/routes';
import currentPathNameSelector from '../sharedStore/modules/router/router.selector';

interface UploadFilePageProps {
  title: string;
  sampleFileLink: string;
}

const UploadFilePage = ({ title, sampleFileLink }: UploadFilePageProps): ReactElement => {
  const loadedFile = useSelector(publishFileLoadedFileSelector);
  const reason = useSelector(publishFileReasonSelector);
  const isLoading = useSelector(publishFileLoadingSelector);
  const fileErrors = useSelector(publishFileErrorsSelector);
  const fileFormatError = useSelector(publishFileFormatErrorSelector);
  const { pathname } = useSelector(currentPathNameSelector);
  const isReadyToUpload =
    !!loadedFile && (!!reason || pathname === ROUTES.UPLOAD_DISCOUNTS_FROM_FILE) && !isLoading;
  const dispatch = useDispatch();
  const uploadFile = useCallback(() => dispatch({ type: UPLOAD_FILE }), [dispatch]);
  const hasFileError = fileErrors?.length > 0;
  const hasError = hasFileError || !!fileFormatError;
  const buttonTitle = hasError ? 'Re-upload' : 'Upload';
  const fullTitle = `Upload ${title}`;

  const resetUploadFileState = useCallback(
    () => dispatch({ type: RESET_UPLOAD_FILE_STATE }),
    [dispatch],
  );

  const handleSetPublishDate = useCallback(
    (payload: Date | undefined) => dispatch({ type: UPLOAD_FILE_SET_PUBLISH_DATE, payload }),
    [dispatch],
  );

  const handleSetExpirationDate = useCallback(
    (payload: Date | undefined) => dispatch({ type: UPLOAD_FILE_SET_EXPIRATION_DATE, payload }),
    [dispatch],
  );

  // On Unmount clear all the state.
  useEffect(
    () => () => {
      resetUploadFileState();
    },
    [resetUploadFileState],
  );

  return (
    <>
      <OverflowHack>
        <HeaderComponent title={fullTitle} />
        <UploadFile analyticTitle={fullTitle} />
      </OverflowHack>
      <BottomPanelUploadFile
        buttonTitle={buttonTitle}
        isLoading={isLoading}
        sampleFileLink={sampleFileLink}
        title={title}
        isEnabled={isReadyToUpload}
        publishFile={uploadFile}
        handleSetPublishDate={handleSetPublishDate}
        handleSetExpirationDate={handleSetExpirationDate}
      />
    </>
  );
};

export default UploadFilePage;
