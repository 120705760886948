import { ReactElement, useCallback } from 'react';
import { pluralize } from 'apollo/lib/utils';
import { Confirm } from 'semantic-ui-react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfirmProps } from '../../../../../sharedStore/entities/confirmProps';
import { PUBLISH_BREAKEVEN_PRICES } from '../../../store/modules/breakevenItems/breakevenItems.ducks';
import { breakevenItemsToPublishSelector } from '../../../store/modules/breakevenItems/breakevenItems.selector';
import { MutationPublishLocationBreakEvenDataArgs } from '../../../../../generated/voyager/graphql';

function PublishBreakevenConfirm(props: ConfirmProps): ReactElement {
  const itemsToPublish = useSelector(breakevenItemsToPublishSelector) ?? [];

  const dispatch = useDispatch();

  const publishBreakevenPricesCallback = useCallback(
    (payload: MutationPublishLocationBreakEvenDataArgs) =>
      dispatch({ type: PUBLISH_BREAKEVEN_PRICES, payload }),
    [dispatch],
  );

  const publishBreakevenPrices = () => {
    publishBreakevenPricesCallback({
      locationIds: itemsToPublish.map(item => item.location.id),
      validFrom: null, // publishDate && format(publishDate, 'yyyy-MM-dd'),
    });
    props.closeModal();
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={props.closeModal}
      onConfirm={publishBreakevenPrices}
      cancelButton={{ content: 'Cancel', className: 'cancel-confirmation' }}
      closeIcon
      confirmButton={'Publish'}
      header="Publish Breakeven Prices"
      content={
        <div>
          <p>
            {`You are going to publish breakeven price for ${pluralize(
              itemsToPublish.length,
              'location',
            )}.`}
          </p>
          <p>Do you want to proceed?</p>
        </div>
      }
    />
  );
}

export default PublishBreakevenConfirm;
