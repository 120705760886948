import { v4 as uuidV4 } from 'uuid';
import { DropdownItemProps } from 'semantic-ui-react';
import { Market, Region, Territory, Location } from '../../generated/voyager/graphql';

const transformOption = (object: Market | Location | Region | Territory): DropdownItemProps => ({
  key: object.id ?? uuidV4(),
  text: object.name ?? '-',
  value: object.id ?? '-',
});

export default transformOption;
