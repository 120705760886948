import axios, { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import AppConfig from '../../../../../config';
import { getAuthToken } from '../../../../../index';
import {
  RESET_BREAKEVEN,
  RESET_BREAKEVEN_ERROR,
  RESET_BREAKEVEN_SUCCESS,
} from './resetBreakEven.ducks';

export const resetBreakEvenRequest = (): Promise<AxiosResponse<any>[]> => {
  const resetBreakEvenUrl = `${AppConfig.BACKEND_REST_URL}/admin/breakeven/publish-live-budget`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  return axios.post(resetBreakEvenUrl, null, { headers });
};

export function* resetBreakEvenWorker() {
  const response: AxiosResponse | undefined = yield call(resetBreakEvenRequest);
  if (response?.status === 200) {
    yield put({ type: RESET_BREAKEVEN_SUCCESS });
    toast.success('Reset Breakeven is finished!');
  } else {
    toast.error('Reset Breakeven failed!');
    yield put({ type: RESET_BREAKEVEN_ERROR, payload: response?.data?.message });
  }
}

export default function* resetBreakEvenSaga(): any {
  yield all([takeLatest(RESET_BREAKEVEN, resetBreakEvenWorker)]);
}
