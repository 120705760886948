import { ColDef, ColGroupDef, GetQuickFilterTextParams, GridOptions } from 'ag-grid-community';
import { getMainMenuItemsWithoutReset } from 'utils/helpers';
import TableLoadingRenderer from '../../../../sharedComponents/tableComponent/tableLoadingRenderer';
import BreakevenLocationDetailsRenderer from './renderers/locationDetailsRenderer';
import EditableCellRenderer from './renderers/editableCellRenderer';
import EditableCellCheckBoxRenderer from './renderers/editableCellCheckBoxRenderer';
import BreakevenRowDetailsRenderer from './renderers/rowDetailsRenderer';
import { toPercentageVFBreakeven } from './breakevenTable.helper';
import EditableCellNotesRenderer from './renderers/editableCellNotesRenderer';
import BreakevenPriceCellRenderer from './renderers/breakevenPriceCellRenderer';
import InfoHeaderComponent from '../agGridHeaderInfo/infoHeaderComponent';

export const defaultColDef: ColDef = {
  flex: 1,
  menuTabs: ['generalMenuTab'],
  suppressMovable: true,
  getQuickFilterText(params: GetQuickFilterTextParams): string {
    const field = params.colDef.field;
    // just search by the reservable name in the grid
    if (field === 'location.id') {
      return params.data?.location?.name;
    }
    return '';
  },
  resizable: true,
  sortable: false,
  filter: true,
};

export const columnDefs = (editPermission: boolean): (ColDef | ColGroupDef)[] => [
  {
    field: 'location.id',
    rowGroup: true,
    hide: true,
  },
  {
    headerName: 'Location',
    wrapText: true,
    field: 'type',
    cellRenderer: BreakevenRowDetailsRenderer,
    minWidth: 400,
    width: 400,
    cellStyle: { paddingLeft: '45px' },
    cellClass: 'actions-button-cell',
    headerCheckboxSelection: editPermission,
    headerCheckboxSelectionFilteredOnly: true,
    lockPosition: true,
  },
  {
    headerName: 'Breakeven Price',
    cellRenderer: BreakevenPriceCellRenderer,
    field: 'breakEvenPrice',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Steady State Occ',
    cellRenderer: EditableCellRenderer,
    field: 'steadyStateOcc',
    minWidth: 130,
    width: 170,
    valueFormatter: toPercentageVFBreakeven,
  },
  {
    headerName: 'RSF (annualized)',
    cellRenderer: EditableCellRenderer,
    field: 'rsf',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Desk Capacity  (annualized)',
    cellRenderer: EditableCellRenderer,
    field: 'deskCapacity',
    minWidth: 170,
    width: 170,
  },
  {
    headerName: 'Rent  (annualized)',
    cellRenderer: EditableCellRenderer,
    field: 'rent',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Tenancy/NNN  (annualized)',
    cellRenderer: EditableCellRenderer,
    field: 'tenancyNNN',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Opex  (annualized)',
    cellRenderer: EditableCellRenderer,
    field: 'opex',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'SG&A  (annualized)',
    cellRenderer: EditableCellRenderer,
    field: 'sgAndA',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Desks In Year Months',
    cellRenderer: EditableCellRenderer,
    field: 'desksInYearMonths',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Total Budgeted Revenue  (annualized)',
    cellRenderer: EditableCellRenderer,
    field: 'totalBudgetRevenue',
    minWidth: 180,
    width: 180,
  },
  {
    headerName: 'Included in SG&A?',
    field: 'includedSGA',
    cellRenderer: EditableCellCheckBoxRenderer,
    minWidth: 110,
    width: 110,
    headerComponent: InfoHeaderComponent,
    headerComponentParams: {
      info: 'If unchecked, this building will be excluded from the market SG&A calculation.',
    },
  },
  {
    headerName: 'Show Breakeven?',
    field: 'showBreakEven',
    cellRenderer: EditableCellCheckBoxRenderer,
    minWidth: 120,
    width: 120,
    headerComponent: InfoHeaderComponent,
    headerComponentParams: {
      info: "If unchecked, this building's Breakeven will not publish across systems.",
    },
  },
  {
    headerName: 'Note',
    field: 'notes',
    cellRenderer: EditableCellNotesRenderer,
    minWidth: 250,
    width: 250,
    wrapText: true,
  },
];

export const gridOptions = (editPermission: boolean): GridOptions => ({
  getRowId: (data: any) => `${data.data?.location?.id}_${data.data?.type}`,
  rowSelection: 'multiple',
  defaultColDef,
  suppressNoRowsOverlay: true,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  suppressDragLeaveHidesColumns: true,
  loadingOverlayComponent: TableLoadingRenderer,
  groupDisplayType: 'groupRows',
  groupRowRenderer: 'agGroupCellRenderer',
  groupRowRendererParams: {
    innerRenderer: BreakevenLocationDetailsRenderer,
    checkbox: editPermission,
    suppressCount: true,
  },
  groupDefaultExpanded: 1,
  getMainMenuItems: getMainMenuItemsWithoutReset,
});
