import { Action } from 'redux-actions';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { findIndex, get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { ApolloQueryResult } from '@apollo/client';
import { FetchResult } from '@apollo/client/link/core';
import { DocumentNode } from 'graphql';
import { errorHandlerActive } from '../../../../../utils/errorHandling/helpers';
import {
  CREATE_BREAKEVEN_WORKING_DATA,
  CREATE_BREAKEVEN_WORKING_DATA_SUCCESS,
  END_BREAKEVEN_FETCH,
  FETCH_BREAKEVEN_ITEMS,
  FETCH_BREAKEVEN_ITEMS_SUCCESS,
  GET_MARKET_SG_AND_A,
  GET_MARKET_SG_AND_A_SUCCESS,
  PUBLISH_BREAKEVEN_PRICES,
  PUBLISH_BREAKEVEN_PRICES_SUCCESS,
  START_BREAKEVEN_FETCH,
} from './breakevenItems.ducks';
import store, { graphQLClient } from '../../../../../index';
import { FETCH_GRID_BREAKEVEN_QUERY, GET_MARKET_SG_AND_A_QUERY } from './breakevenItems.query';
import {
  CREATE_BREAKEVEN_WORKING_DATA_MUTATION,
  PUBLISH_LOCATION_BREAKEVEN_DATA_MUTATION,
} from './breakevenItems.mutation';
import { breakevenItemsSelectorStore, marketSgAndASelector } from './breakevenItems.selector';
import { removeTypeName } from '../../../components/breakevenTable/breakevenTable.helper';
import {
  LocationBreakEvenDetail,
  MarketSgAndAQuery,
  MarketSgAndAQueryVariables,
  MutationPublishLocationBreakEvenDataArgs,
  PublishLocationBreakEvenDataMutation,
  SgAndAForMarket,
  WorkingLocationBreakEvenInput,
} from '../../../../../generated/voyager/graphql';

const executeGraphQLQuery = (query: DocumentNode, variable: Object): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query,
    variables: variable,
  });

// TODO: Doing long way around since we might need batch fetch if number of location is huge.[NOT part of MVP]
function* fetchBreakevenItemsData(geoLocationIds: string[]): any {
  try {
    // 1) Trying fetch the data for next page.
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_GRID_BREAKEVEN_QUERY, {
      geoLocationIds,
    });

    // 2) IF, there are any errors in fetching ending the batch fetch and failing the priceable items error.
    if (!isEmpty(errors)) {
      yield put({ type: END_BREAKEVEN_FETCH });
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    // 3) If the breakeven Items data present try to load more data recursively.
    if (!isEmpty(data.locationBreakEvens)) {
      // 3.a) Store the data in the store.
      yield put({ type: FETCH_BREAKEVEN_ITEMS_SUCCESS, data });
    }

    return;
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_BREAKEVEN_FETCH });
  }
}

function* fetchBreakevenItemsWorker(action: Action<string[]>) {
  try {
    const geoLocationIds = action.payload;
    // Starting Batch Fetch Breakeven.
    yield put({ type: START_BREAKEVEN_FETCH });
    // Calling the recursion for fetching the breakeven items.
    yield call(fetchBreakevenItemsData, geoLocationIds);
    // End batch fetch Breakeven.
    yield put({ type: END_BREAKEVEN_FETCH });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
    yield put({ type: END_BREAKEVEN_FETCH });
  }
}

// Batching the update request.
const updateBreakevenWorkingData = (
  workingLocationBreakEvenInput: WorkingLocationBreakEvenInput,
): Promise<any> =>
  graphQLClient.mutate({
    mutation: CREATE_BREAKEVEN_WORKING_DATA_MUTATION,
    variables: { workingLocationBreakEvenInput },
    context: { important: true },
  });

function* updateBreakevenWorkingDataWorker(action: Action<WorkingLocationBreakEvenInput>) {
  try {
    let workingLocationBreakEvenInput: WorkingLocationBreakEvenInput = action?.payload ?? {};
    const currBreakevenItems: LocationBreakEvenDetail[] =
      breakevenItemsSelectorStore(store.getState()) ?? [];
    const locationItem: LocationBreakEvenDetail | undefined = currBreakevenItems.find(
      item => item.location.id === workingLocationBreakEvenInput.geoLocationUUID,
    );
    const workingLiveBudgetAppliedInput =
      workingLocationBreakEvenInput?.workingLiveBudgetAppliedInput !== null
        ? {
            ...removeTypeName(locationItem?.liveBudgetApplied),
            ...workingLocationBreakEvenInput?.workingLiveBudgetAppliedInput,
          }
        : null;
    const workingBreakEvenOverrideInput =
      workingLocationBreakEvenInput?.workingBreakEvenOverrideInput !== null
        ? {
            ...removeTypeName(locationItem?.override),
            ...workingLocationBreakEvenInput?.workingBreakEvenOverrideInput,
          }
        : null;
    workingLocationBreakEvenInput = {
      geoLocationUUID: workingLocationBreakEvenInput.geoLocationUUID,
      workingLiveBudgetAppliedInput: isEmpty(workingLiveBudgetAppliedInput)
        ? null
        : workingLiveBudgetAppliedInput,
      workingBreakEvenOverrideInput: isEmpty(workingBreakEvenOverrideInput)
        ? null
        : workingBreakEvenOverrideInput,
    };
    const { data, errors } = yield call(updateBreakevenWorkingData, workingLocationBreakEvenInput);

    if (!isEmpty(errors) || !data?.workingLocationBreakEven?.success) {
      const error = data?.workingLocationBreakEven?.errors ?? errors[0];
      toast.error(`Batch save error - ${error}`);
      errorHandlerActive(new Error(error));
      return;
    }

    yield put({
      type: CREATE_BREAKEVEN_WORKING_DATA_SUCCESS,
      payload: workingLocationBreakEvenInput,
    });

    yield put({
      type: GET_MARKET_SG_AND_A,
      payload: {
        marketIds: locationItem?.location.market.id,
        workingLocationIds: locationItem?.location.id,
      },
    });

    toast.success('Successfully updated the working price.');
  } catch (e: any) {
    toast.error(`Batch save error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

const publishBreakevenPricesMutation = (
  variables: MutationPublishLocationBreakEvenDataArgs,
): Promise<FetchResult<PublishLocationBreakEvenDataMutation>> =>
  graphQLClient.mutate<PublishLocationBreakEvenDataMutation>({
    mutation: PUBLISH_LOCATION_BREAKEVEN_DATA_MUTATION,
    variables,
  });

function* publishBreakevenPrices(action: Action<MutationPublishLocationBreakEvenDataArgs>) {
  try {
    const { data, errors } = yield call(publishBreakevenPricesMutation, action.payload);

    if (!isEmpty(errors) || !data?.publishLocationBreakEvenData?.success) {
      const error = data?.publishLocationBreakEvenData?.errors ?? errors[0];
      toast.error(`Publish Breakeven Prices Error - ${error}`);
      errorHandlerActive(new Error(error));
      return;
    }

    yield put({ type: PUBLISH_BREAKEVEN_PRICES_SUCCESS });
    toast.success(`Published changes for ${action.payload.locationIds.length} buildings`);
  } catch (e: any) {
    toast.error(`Publish Error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

const getMarketSgAndA = (
  variables: MarketSgAndAQueryVariables,
): Promise<ApolloQueryResult<MarketSgAndAQuery>> =>
  graphQLClient.query<MarketSgAndAQuery>({
    fetchPolicy: 'network-only',
    query: GET_MARKET_SG_AND_A_QUERY,
    variables,
  });

function* getMarketSgAndAWorker(action: Action<MarketSgAndAQueryVariables>) {
  try {
    const { errors, data } = yield call(getMarketSgAndA, action.payload);
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }
    const oldSGA: SgAndAForMarket[] = marketSgAndASelector(store.getState()) ?? [];
    const newSGA: SgAndAForMarket[] = data?.marketSgAndA;
    newSGA.forEach((eachNewSGA: SgAndAForMarket) => {
      const idx = findIndex(oldSGA, ['marketId', eachNewSGA.marketId]);
      if (idx > -1) {
        oldSGA.splice(idx, 1, eachNewSGA);
      } else {
        oldSGA.push(eachNewSGA);
      }
    });

    yield put({ type: GET_MARKET_SG_AND_A_SUCCESS, payload: [...oldSGA] });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

export default function* breakevenItemSaga(): any {
  yield all([takeLatest(FETCH_BREAKEVEN_ITEMS, fetchBreakevenItemsWorker)]);
  yield all([takeLatest(CREATE_BREAKEVEN_WORKING_DATA, updateBreakevenWorkingDataWorker)]);
  yield all([takeLatest(PUBLISH_BREAKEVEN_PRICES, publishBreakevenPrices)]);
  yield all([takeEvery(GET_MARKET_SG_AND_A, getMarketSgAndAWorker)]);
}
