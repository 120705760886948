import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { MainRegionStyled, SummaryWrapper } from '../../../styles/app.styled';
import BreadcrumbComponent from '../../../sharedComponents/breadcrumb/breadcrumbComponent';
import BreakevenPublish from '../components/breakevenPublish/breakevenPublish';
import BottomPanelBreakevenPublish from '../components/bottomPanel/bottomPanelBreakevenPublish';
import {
  CLEAR_BREAKEVEN_ITEMS,
  CLEAR_MARKET_SG_AND_A,
  SET_BREAKEVEN_DATA_TO_PUBLISH,
} from '../store/modules/breakevenItems/breakevenItems.ducks';
import ROUTES from '../../../utils/routes/routes';
import { AuthzProps } from '../../../utils/constants';
import EmployeePermissions from '../../../utils/store/permissions';

function BreakevenPublishHomePage({ requestedPermissions }: AuthzProps): ReactElement {
  const dispatch = useDispatch();
  const clearAll = useCallback(() => {
    dispatch({ type: CLEAR_BREAKEVEN_ITEMS });
    dispatch({ type: SET_BREAKEVEN_DATA_TO_PUBLISH });
    dispatch({ type: CLEAR_MARKET_SG_AND_A });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (
      !get(
        requestedPermissions,
        EmployeePermissions.voyager_dedicated_space_breakeven_publish,
        false,
      )
    ) {
      redirectToAccessDenied();
    }

    return () => {
      clearAll();
    };
  }, [clearAll, requestedPermissions, redirectToAccessDenied]);

  return (
    <>
      <MainRegionStyled>
        <BreadcrumbComponent />
        <SummaryWrapper>
          <BreakevenPublish />
        </SummaryWrapper>
      </MainRegionStyled>
      <BottomPanelBreakevenPublish />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_breakeven_publish],
})(BreakevenPublishHomePage);
