import styled from 'styled-components';
import { FlexHorizontalHelper, ListReset } from '../../../../styles/app.styled';

export const ViewingTableCount = styled.ul`
  height: 30px;
  ${ListReset};
  ${FlexHorizontalHelper};
  align-items: flex-start;
`;
export const AgGridWrapper = styled.div`
  height: 100vh;
  max-height: calc(85vh - 150px);
  margin: 0 40px;
  padding-bottom: 14px;
`;
export const TableLayoutCurveManagement = styled.div`
  margin-top: 16px;
`;
