import styled from 'styled-components';
import { FlexHorizontalHelper, FlexVerticalHelper } from '../../../../../../styles/app.styled';
import { blue, darkBlue, darkGray, gray, lighterGray } from '../../../../../../styles/colors';

export const BatchEditing = styled.div`
  width: 100%;
  position: relative;
  ${FlexVerticalHelper};
  > .switch {
    flex: 0 0 auto;
    margin: 0 12px 0 0;
  }
  /* APPLY BUTTON */
  > .ui.basic.primary.button {
    flex: 0 0 auto;
    min-height: 30px;
    height: 35px;
    padding: 4px 12px;
    margin: 0;
    line-height: 22px;
    font-size: 12px;
    white-space: nowrap;
  }
  /* DISABLED OVERLAY */
  &.disabled {
    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: transparent;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }
`;
export const BatchEditModeSelector = styled.div`
  flex: 0 0 auto;
  ${FlexVerticalHelper};
  margin: 0 0 5px 0;
  .ui.radio.checkbox {
    flex: 0 0 auto;
    margin-bottom: 8px;
    label {
      padding-left: 24px;
      margin: 0;
      font-size: 12px;
      line-height: 14px;
      &::before {
        top: 0;
        width: 14px;
        height: 14px;
      }
      &::after {
        top: 0;
        width: 14px;
        height: 14px;
      }
    }
  }
`;
export const BatchModeValueWrapper = styled.div`
  flex: 0 0 auto;
  ${FlexHorizontalHelper};
  margin: 0 0 8px 0;
  /* VALUE INPUT */
  > .relative {
    flex: 1 1 auto;
    height: 30px;
    > input {
      min-width: 49%;
      flex: 0 0 auto;
      padding: 3px 6px;
      margin: 0;
      border: 1px solid ${gray};
      border-radius: 4px;
      line-height: 22px;
      font-size: 14px;
      color: ${darkBlue};
      width: 100%;
    }
    &:last-of-type {
      margin-left: 2%;
    }
    > input.full {
      margin-left: 0;
      width: 100%;
    }
    > input.cropped {
      min-width: 28%;
      border-right: none;
      border-radius: 4px 0 0 4px;
    }
  }
  /* VALUE TYPE SELECTOR */
  > .adjust-by-box {
    flex: 0 0 auto;
    ${FlexHorizontalHelper};
    > button {
      flex: 0 0 auto;
      width: 30px;
      height: 30px;
      background: ${lighterGray};
      padding: 0;
      border: 1px solid ${gray};
      border-right: 0;
      border-radius: 0;
      margin: 0;
      outline: none;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      color: ${darkGray};
      &:last-of-type {
        border-left: 0;
        border-right: 1px solid ${gray};
        border-radius: 0 4px 4px 0;
      }
      &.active {
        background: ${blue};
        border-color: ${blue};
        color: white;
      }
    }
  }
`;
