import { Action, handleActions } from 'redux-actions';
import { get } from 'lodash';
import { PriceableItemsSubset } from '../../entities/priceableItems';
import initialState from '../../../../../../utils/store/initialState';
import { createRDXConstant } from '../../../../../../utils/store/store.constants';
import { PriceableItem } from '../../../../../../generated/voyager/graphql';

// Action Constants
// Action - for fetching Changelog based on priceable item id.
export const FETCH_CURRENT_CHANGE_LOG = createRDXConstant('FETCH_CURRENT_CHANGE_LOG');
export const FETCH_CURRENT_CHANGE_LOG_SUCCESS = createRDXConstant(
  'FETCH_CURRENT_CHANGE_LOG_SUCCESS',
);

// Action for fetching feature card elements to explain suggested reason.
export const FETCH_FEATURE_CARD = createRDXConstant('FETCH_FEATURE_CARD');
export const FETCH_FEATURE_CARD_SUCCESS = createRDXConstant('FETCH_FEATURE_CARD_SUCCESS');

// Reducer
export const priceableItemsReducer = handleActions<PriceableItemsSubset, any>(
  {
    // Reducer for fetching the change log of priceable items.
    [FETCH_CURRENT_CHANGE_LOG]: (state: PriceableItemsSubset) => ({
      ...state,
      priceableItemChangeLogState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_CURRENT_CHANGE_LOG_SUCCESS]: (state: PriceableItemsSubset, action: Action<any>) => {
      const priceableItemChangeLog = get(action, 'payload.priceableItem', null);
      return {
        ...state,
        priceableItemChangeLogState: {
          loading: false,
          loaded: true,
          error: null,
        },
        priceableItemChangeLog,
      };
    },

    // Reducer for fetching feature card elements to explain suggested reason
    [FETCH_FEATURE_CARD]: (state: PriceableItemsSubset) => ({
      ...state,
      featureCardElementsState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_FEATURE_CARD_SUCCESS]: (state: PriceableItemsSubset, action: Action<any>) => {
      const featureCardElements = get(action, 'data.priceableItem', {} as PriceableItem);
      return {
        ...state,
        featureCardElementsState: {
          loading: false,
          loaded: true,
          error: null,
        },
        featureCardElements,
      };
    },
  },
  initialState.priceableItems,
);
