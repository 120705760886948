import * as React from 'react';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon, IconName, IconSize } from '@wework/ray2';
import { Chip, DatePicker } from '@wework/dieter-ui';
import { Popup } from 'semantic-ui-react';
import { format, isBefore } from 'date-fns';
import cn from 'classnames';
import { utcToZonedTime } from 'date-fns-tz';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { flatten, get, isEmpty } from 'lodash';
import { CSVLink } from 'react-csv';
import {
  ActionsBox,
  ActionSubTitle,
  BottomPanelWrapper,
  CSVDownloadContainer,
} from '../../../../../styles/sharedBottomPanel.styled';
import {
  publishPricesBatchFetchSelectorStore,
  publishPricesPublishSaveSelectorStore,
  publishPricesSelectorStore,
} from '../../store/modules/publishPrice/publishPrice.selector';
import PublishWorkingPricesConfirm from './confirmationPopup/publishWorkingPricesConfirm';
import EmployeePermissions from '../../../../../utils/store/permissions';
import { AuthzProps } from '../../../../../utils/constants';
import { PublishPriceTableItem } from '../../store/entities/publishPrice';

interface BottomPanelProps {
  transformedWorkingPrice: Map<string, PublishPriceTableItem[]>;
}

function BottomPanelPricingPublish(props: BottomPanelProps & AuthzProps): ReactElement {
  // LOCAL VARIABLES
  const [open, setOpen] = useState<boolean>(false);
  const [publishDate, setPublishDate] = useState<{ selectedDay: Date | undefined }>({
    selectedDay: undefined,
  });
  const [publishConfirmationModalOpen, setPublishConfirmationModalOpen] = useState(false);

  // STATE PROPS
  const publish = useSelector(publishPricesPublishSaveSelectorStore);
  const publishPricesSelector = useSelector(publishPricesSelectorStore);
  const batchFetch = useSelector(publishPricesBatchFetchSelectorStore);

  const utcDate = Date.now();
  const timeZones = publishPricesSelector?.map(o => o.priceableItem.location.timeZone);
  const publishDateDisabledBefore = [...new Set(timeZones)]
    .map(timeZone => utcToZonedTime(utcDate, timeZone as string))
    .reduce((time1, time2) => (time1 > time2 ? time1 : time2), new Date());

  publishDateDisabledBefore.setHours(0, 0, 0, 0);

  /**
   * Date picker trigger.
   */
  function datePickerTrigger(): ReactElement {
    return (
      <Chip
        className={cn({ active: !!publishDate.selectedDay }, 'date-picker-trigger')}
        active={!!publishDate.selectedDay}
        onClick={() => setOpen(prevState => !prevState)}
      >
        {publishDate.selectedDay ? format(publishDate.selectedDay, 'MMM d, yyyy') : 'Select Date'}
        <i />
      </Chip>
    );
  }

  const data = [...(props.transformedWorkingPrice?.values() ?? [])];
  const headers = [
    { label: 'Location ID', key: 'locationId' },
    { label: 'Location Name', key: 'locationName' },
    { label: 'Office Name', key: 'officeName' },
    { label: 'Sku', key: 'sku' },
    { label: 'Capacity', key: 'capacity' },
    { label: 'Current Price', key: 'currentPrice' },
    { label: 'Current List Price', key: 'currentListPrice' },
    { label: 'Adjust Suggested Price', key: 'adjustSuggestedPrice' },
    { label: 'Working Price', key: 'workingPrice' },
    { label: 'Working List Price', key: 'workingListPrice' },
    { label: 'Working Price Rounded', key: 'workingListPriceRounded' },
    { label: 'Working Price CreatedBy', key: 'workingPriceCreatedBy' },
    { label: 'Working Price Reason', key: 'workingPriceReason' },
  ];

  return (
    <BottomPanelWrapper className="publish-panel">
      {!batchFetch && !isEmpty(props.transformedWorkingPrice) ? (
        <CSVDownloadContainer>
          <CSVLink
            data={flatten(data)}
            headers={headers}
            filename={'my-file.csv'}
            className="btn btn-primary download-button"
            target="_blank"
          >
            <Icon
              icon={IconName.DOWNLOAD}
              size={IconSize.SMALL}
              className="download-button-icon"
              color={'black'}
            />
            Download CSV File
          </CSVLink>
        </CSVDownloadContainer>
      ) : (
        <div />
      )}
      <ActionsBox>
        <ActionSubTitle>Set a published date:</ActionSubTitle>
        <Popup
          basic
          position="top left"
          trigger={datePickerTrigger()}
          on="click"
          className="publish-data-popup"
          open={open}
          onClose={() => setOpen(prevState => !prevState)}
          closeOnDocumentClick
          closeOnEscape
        >
          <Popup.Content>
            <DatePicker
              selectedDays={publishDate.selectedDay}
              onDayClick={(day, { selected }) => {
                if (!isBefore(day, publishDateDisabledBefore)) {
                  setPublishDate({ selectedDay: selected ? undefined : day });
                  setOpen(false);
                }
              }}
              disabledDays={{ before: publishDateDisabledBefore }}
            />
          </Popup.Content>
        </Popup>
        <ActionSubTitle className="info-message">
          {publishDate.selectedDay
            ? `Prices will publish on ${format(publishDate.selectedDay, 'MMM d, yyyy')}.`
            : 'Prices will publish immediately.'}
        </ActionSubTitle>
        <Button
          loading={publish}
          theme={'fill'}
          size={'medium'}
          onClick={() => setPublishConfirmationModalOpen(true)}
          className={'ml-2xs'}
          disabled={
            !get(
              props.requestedPermissions,
              EmployeePermissions.voyager_dedicated_space_price_publish,
              false,
            ) || isEmpty(publishPricesSelector)
          }
        >
          Publish
        </Button>
      </ActionsBox>
      <PublishWorkingPricesConfirm
        closeModal={() => setPublishConfirmationModalOpen(false)}
        isOpen={publishConfirmationModalOpen}
        publishDate={publishDate.selectedDay}
      />
    </BottomPanelWrapper>
  );
}

export default withRequestedAuthz<BottomPanelProps & AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_price_publish],
})(BottomPanelPricingPublish);
