import { ICellRendererParams } from 'ag-grid-community';
import * as React from 'react';
import { ReactElement } from 'react';
import { CustomTableBodyCell } from '../../../pricingTable/pricingTable.styled';
import ChangelogAndSchedule from './changelogAndSchedule';

function ChangelogAndScheduleCellRender(params: ICellRendererParams): ReactElement {
  return params.data ? (
    <CustomTableBodyCell>
      <ChangelogAndSchedule {...params} />
    </CustomTableBodyCell>
  ) : (
    <></>
  );
}

export default ChangelogAndScheduleCellRender;
