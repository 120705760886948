import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import { DataConsolidationButton } from '../../../../styles/functionalZone/adminPanel.styled';
import { SPACEMAN_PRICES_CONSOLIDATION } from '../../store/modules/spacemanPricesConsolidation/spacemanPricesConsolidation.ducks';
import { spacemanPricesConsolidationLoadingStateSelector } from '../../store/modules/spacemanPricesConsolidation/spacemanPricesConsolidation.selector';

const SpacemanPricesConsolidation = () => {
  const spacemanPricesConsolidationLoadState = useSelector(
    spacemanPricesConsolidationLoadingStateSelector,
  );

  const dispatch = useDispatch();
  const spacemanPricesConsolidationSync = useCallback(
    () => dispatch({ type: SPACEMAN_PRICES_CONSOLIDATION }),
    [dispatch],
  );

  return (
    <>
      <DataConsolidationButton
        onClick={() => spacemanPricesConsolidationSync()}
        disabled={spacemanPricesConsolidationLoadState.loading}
      >
        Spaceman Prices Consolidation
      </DataConsolidationButton>
    </>
  );
};

export default SpacemanPricesConsolidation;
