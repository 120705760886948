import React from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { MainRegionStyled, OverflowHack } from 'styles/app.styled';
import HeaderComponent from '../../../sharedComponents/header/header';
import ActionItemsList from '../components/actionItemList';
import { AuthzProps } from '../../../utils/constants';
import EmployeePermissions from '../../../utils/store/permissions';

const ActionItemView = ({ requestedPermissions }: AuthzProps) => (
  <MainRegionStyled>
    <OverflowHack>
      <HeaderComponent title="Action Items" />
      <ActionItemsList requestedPermissions={requestedPermissions} />
    </OverflowHack>
  </MainRegionStyled>
);
export default withRequestedAuthz<AuthzProps>({
  permissions: [
    EmployeePermissions.voyager_dedicated_space_price_view,
    EmployeePermissions.voyager_dedicated_space_discount_view,
    EmployeePermissions.voyager_dedicated_space_breakeven_view,
  ],
})(ActionItemView);
