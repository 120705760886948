import { isEmpty } from 'lodash';
import { StoreInterface } from '../../../../../utils/store/store.interface';
import { Unassigned } from '../../../../../utils/sharedTypes';
import { BreakevenItemsSubset } from '../../entities/breakevenItems';
import { LocationBreakEvenDetail, SgAndAForMarket } from '../../../../../generated/voyager/graphql';

const breakevenItemsSelector = (state: StoreInterface) => state?.breakevenItems;

export const breakevenItemsSelectorStore = (
  state: StoreInterface,
): LocationBreakEvenDetail[] | Unassigned => breakevenItemsSelector(state)?.allItems;

export const breakevenItemsSubsetSelectorStore = (
  state: BreakevenItemsSubset,
): LocationBreakEvenDetail[] | Unassigned => state?.allItems;

export const fetchStateSelectorBreakevenStore = (state: StoreInterface): boolean =>
  breakevenItemsSelector(state)?.fetchInProgress;

export const editModeSelectorBreakeven = (state: StoreInterface): boolean =>
  breakevenItemsSelector(state)?.editMode;

export const breakevenLocationIdsToPublish = (state: StoreInterface): string[] =>
  breakevenItemsSelector(state)?.locationIdsToPublish;

export const breakevenPricesPublishing = (state: StoreInterface): boolean =>
  breakevenItemsSelector(state)?.publishing;

export const marketSgAndASelector = (state: StoreInterface): SgAndAForMarket[] =>
  breakevenItemsSelector(state)?.marketSgAndA;

export const marketSgAndALoadingSelector = (state: StoreInterface): boolean =>
  breakevenItemsSelector(state)?.marketSgAndALoading;

export const breakevenItemsToPublishSelector = (
  state: StoreInterface,
): LocationBreakEvenDetail[] | Unassigned => {
  const idsToPublish = breakevenLocationIdsToPublish(state);
  return breakevenItemsSelectorStore(state)?.filter(
    item =>
      (isEmpty(idsToPublish) || idsToPublish.includes(item.location.id)) &&
      (item.override !== null || item.liveBudgetApplied !== null),
  );
};
