import * as React from 'react';
import { ReactElement, useEffect } from 'react';
import { SkeletonParagraph } from '@wework/dieter-ui';
import { Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import {
  EachLocationSummary,
  LoadingBox,
  LocationsChangesSummaries,
  SummarySubTitle,
  SummaryTitle,
  SummaryWrapper,
  WorkingOverlay,
} from './onDemandPricingPublish.styled';
import {
  onDemandPricingProductsToPublishSelector,
  onDemandPublishPricesSelector,
} from '../../store/modules/onDemand/onDemandPricing.selector';
import { HierarchyInterface } from '../onDemandPricing.helpers';
import {
  isNumberNullFormat,
  roundToAtMost2Digit,
} from '../../../standardPricing/components/helpers';
import { routerHistory } from '../../../../../utils/store/configureStore';
import { PublishSummaryOnDemand } from '../../store/entities/onDemandPricing';

interface OnDemandTableProps {
  transformedWorkingPrice: PublishSummaryOnDemand[];
  workingPriceOnDemand: HierarchyInterface[];
}

function OnDemandPricingPublish(props: OnDemandTableProps): ReactElement {
  const productsToPublish = useSelector(onDemandPricingProductsToPublishSelector);
  const publish = useSelector(onDemandPublishPricesSelector);

  /**
   * Number of changes for first subtitle.
   */
  const numberOfChanges = () => {
    if (props?.workingPriceOnDemand.length) {
      return (
        <SummarySubTitle className="left-align">
          {props?.workingPriceOnDemand.length} changes will be published.
        </SummarySubTitle>
      );
    }
    return <></>;
  };

  /**
   * display publishing message
   */
  useEffect(() => {
    if (!publish.loading && publish.loaded) {
      routerHistory.goBack();
    }
  }, [publish]);

  const stringValueToPrint = (tempVal: any, prop: string): string => {
    if (tempVal[prop] && typeof tempVal[prop] === 'number') {
      return isNumberNullFormat(
        tempVal.currencyIsoCode,
        roundToAtMost2Digit(Number(tempVal[prop])),
      );
    } else if (tempVal[prop] === null || tempVal[prop] === 0) {
      return '-';
    }
    return '';
  };

  return (
    <div>
      <SummaryTitle>Review Changes</SummaryTitle>
      <SummaryWrapper className="data-wrapper">
        {numberOfChanges()}
        {/* CONTENT TABLE */}
        {!publish.loading && !isEmpty(props.workingPriceOnDemand) && (
          <>
            <LocationsChangesSummaries>
              <EachLocationSummary className="each-location border-visible">
                <Table basic="very" className="changes-summary-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Name (Country, Market, Building)</Table.HeaderCell>
                      {productsToPublish.map(eachProd => (
                        <React.Fragment key={eachProd.name}>
                          <Table.HeaderCell>{`${eachProd.name} - Old Price`}</Table.HeaderCell>
                          <Table.HeaderCell>{`${eachProd.name} - New Price`}</Table.HeaderCell>
                        </React.Fragment>
                      ))}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {props.transformedWorkingPrice.map(workingPrice => (
                      <Table.Row key={uuidV4()}>
                        <Table.Cell>{workingPrice.name}</Table.Cell>
                        {productsToPublish.map(eachProd => (
                          <React.Fragment key={eachProd.name}>
                            <Table.Cell>
                              {stringValueToPrint(workingPrice, `${eachProd.name}| old_Price`)}
                            </Table.Cell>
                            <Table.Cell>
                              {stringValueToPrint(workingPrice, `${eachProd.name}| new_Price`)}
                            </Table.Cell>
                          </React.Fragment>
                        ))}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </EachLocationSummary>
            </LocationsChangesSummaries>
          </>
        )}
        {!publish.loaded && !publish.loading && isEmpty(props.transformedWorkingPrice) && (
          <SummarySubTitle>No summary to Display</SummarySubTitle>
        )}
        {publish.loading && (
          <LoadingBox>
            <SkeletonParagraph size="large" rows={5} />
          </LoadingBox>
        )}
        {publish.loading && (
          <WorkingOverlay>
            <SummarySubTitle>Working...</SummarySubTitle>
          </WorkingOverlay>
        )}
        {publish.loaded && !publish.loading && isEmpty(props.transformedWorkingPrice) && (
          <SummarySubTitle className="success">Successfully published prices.</SummarySubTitle>
        )}
      </SummaryWrapper>
    </div>
  );
}

export default OnDemandPricingPublish;
