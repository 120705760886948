import { gql } from '@apollo/client';
import { appliedCurveFragment } from './discountItems.query';

export const PUBLISH_CURRENT_WORKING_DISCOUNTS = gql`
  mutation PublishOverrides(
    $overrides: [DiscountOverrideInput!]
    $expirationDate: Date
    $publishDate: Date
  ) {
    publishOverrides(
      overrides: $overrides
      expirationDate: $expirationDate
      publishDate: $publishDate
    ) {
      errors
      success
    }
  }
`;

export const APPLY_CURVES_MUTATION = gql`
  mutation ApplyCurves($input: DiscountCurveInput!) {
    applyCurves(input: $input) {
      priceableItemId
      curveId
      appliedCurve {
        ...appliedCurve
      }
      errors
      success
    }
  }
  ${appliedCurveFragment}
`;

export const REMOVE_CURVES_MUTATION = gql`
  mutation RemoveCurves($input: DiscountCurveInput!) {
    removeCurves(input: $input) {
      priceableItemId
      curveId
      errors
      success
    }
  }
`;

export const CREATE_CURVE_MUTATION = gql`
  mutation CreateCurve($curveInput: CurveInput!, $operatorId: String!, $linkedCurveId: UUID) {
    createCurve(curveInput: $curveInput, operatorId: $operatorId, linkedCurveId: $linkedCurveId) {
      errors
      success
    }
  }
`;

export const UPDATE_CURVE_MUTATION = gql`
  mutation UpdateCurve($curveId: UUID!, $curveInput: CurveInput!) {
    updateCurve(curveId: $curveId, curveInput: $curveInput) {
      errors
      success
    }
  }
`;

export const DELETE_CURVE_MUTATION = gql`
  mutation DeleteCurve($curveId: UUID!) {
    deleteCurve(curveId: $curveId) {
      errors
      success
    }
  }
`;
