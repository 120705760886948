import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Button, TextField } from '@wework/ray2';
import { Checkbox } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { discountCurvesFilterSelector } from 'app/discounts/store/modules/discountItems/discountItems.selector';
import { DiscountCurveStatus } from 'app/discounts/store/entities/discountItems';
import {
  TableFilter,
  TableFilterBarWrapper,
  TableFilterColumnsWrap,
  TableFilterRow,
} from '../../../../styles/sharedTableFilterBar.styled';
import DiscountCurvesOverrideModal, {
  DiscountCurvesModalMode,
} from '../curveManagementModal/curveManagementModal';
import {
  APPLY_CURVE_FILTER,
  CLEAR_CURVES,
  FETCH_USER_OPERATORS,
  RESET_CURVE_FILTER,
} from '../../store/modules/discountItems/discountItems.ducks';
import CheckboxWrapper from './tableFilterBar.styled';

function TableFilterBarCurveManagement(props: { agGridApi: any }): ReactElement {
  const [modalOpenOverride, setModalOpenOverride] = useState<boolean>(false);
  const discountCurvesFilter = useSelector(discountCurvesFilterSelector);
  const handleQuickFilter = (event: any) => {
    props.agGridApi.setQuickFilter(event.target.value);
  };

  const dispatch = useDispatch();
  const clearCurves = useCallback(() => dispatch({ type: CLEAR_CURVES }), [dispatch]);
  const applyFilter = useCallback(
    (payload: DiscountCurveStatus) => dispatch({ type: APPLY_CURVE_FILTER, payload }),
    [dispatch],
  );
  const resetFilter = useCallback(() => dispatch({ type: RESET_CURVE_FILTER }), [dispatch]);
  const fetchOperators = useCallback(() => dispatch({ type: FETCH_USER_OPERATORS }), [dispatch]);

  const curveFilterToggle = useCallback(
    (filterName: DiscountCurveStatus) => {
      applyFilter(filterName);
    },
    [applyFilter],
  );

  useEffect(() => {
    if (props.agGridApi) {
      props.agGridApi.onFilterChanged();
    }
  }, [props.agGridApi, discountCurvesFilter]);

  useEffect(
    () => () => {
      resetFilter();
    },
    [resetFilter],
  );

  const createCurveOnClick = () => {
    fetchOperators();
    setModalOpenOverride(true);
  };

  return (
    <TableFilterBarWrapper>
      <TableFilterColumnsWrap>
        <TableFilterRow>
          <TableFilter>
            <TextField
              className="reservable-search reservable-search-extra-width2"
              // icon="search"
              // iconPosition="left"
              placeholder="Search by Name or Description"
              onChange={(event: any): void => handleQuickFilter(event)}
              onKeyPress={(event: any): void => handleQuickFilter(event)}
            />
          </TableFilter>
          <TableFilter>
            <CheckboxWrapper>
              <Checkbox
                label="Active"
                id="active"
                checked={discountCurvesFilter.has(DiscountCurveStatus.ACTIVE)}
                onClick={() => curveFilterToggle(DiscountCurveStatus.ACTIVE)}
              />
            </CheckboxWrapper>
          </TableFilter>
          <TableFilter>
            <CheckboxWrapper>
              <Checkbox
                label="In-active"
                id="inactive"
                checked={discountCurvesFilter.has(DiscountCurveStatus.INACTIVE)}
                onClick={() => curveFilterToggle(DiscountCurveStatus.INACTIVE)}
              />
            </CheckboxWrapper>
          </TableFilter>
          <TableFilter className="right-align">
            <Button size="medium" theme="fill" onClick={createCurveOnClick}>
              Create New Discount Curve
            </Button>
            {modalOpenOverride && (
              <DiscountCurvesOverrideModal
                discountCurvesOverrideModalClose={() => {
                  setModalOpenOverride(false);
                  clearCurves();
                }}
                mode={DiscountCurvesModalMode.CREATE}
              />
            )}
          </TableFilter>
          <TableFilterRow />
        </TableFilterRow>
      </TableFilterColumnsWrap>
    </TableFilterBarWrapper>
  );
}

export default TableFilterBarCurveManagement;
