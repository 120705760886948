import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { Button, TextField } from '@wework/ray2';
import { Checkbox } from 'semantic-ui-react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import { pluralize } from 'apollo/lib/utils';
import { inputNumberFormatter } from '../../helpers';
import {
  standardPricingItemsSubsetSelectorStore,
  standardPricingSelectedNodesSelector,
} from '../../../store/modules/standardPricingItems/standardPricingItems.selector';
import { CREATE_STANDARD_WORKING_PRICES } from '../../../store/modules/standardPricingItems/standardPricingItems.ducks';
import getWorkingPriceInput from '../../../store/utils/store.helpers';
import { BatchMode } from '../../constants';
import {
  BatchEditing,
  BatchEditModeSelector,
  BatchModeValueWrapper,
} from './standardPricingBatchEdit.styled';
import { WorkingPriceInput } from '../../../../../../generated/voyager/graphql';

interface StandardBatchEditingComponentProps {
  closePopup: Function;
}
function StandardBatchEditingComponent({
  closePopup,
}: StandardBatchEditingComponentProps): ReactElement {
  // STATE PROP VAR
  const allItems = useSelector(standardPricingItemsSubsetSelectorStore) ?? [];
  const selectedNodes = useSelector(standardPricingSelectedNodesSelector);

  // DISPATCH PROP VAR
  const dispatch = useDispatch();
  // Dispatching props in functional component.
  const saveWorkingPriceBatch = useCallback(
    (payload: any) => dispatch({ type: CREATE_STANDARD_WORKING_PRICES, payload }),
    [dispatch],
  );

  // LOCAL STATE VAR
  const [input, setInput] = useState<any>('');
  const [valueTypePercentage, setValueTypePercentage] = useState<any>(true);
  const [batchMode, setBatchMode] = useState<BatchMode>(BatchMode.SAME_PRICE);
  const [reason, setReason] = useState<string>('');

  const cleanAndSetVal = (event: any) => {
    let currentValue = event.target.value;
    currentValue = inputNumberFormatter(currentValue);
    setInput(currentValue);
  };

  const disableApplyButton = (): boolean =>
    batchMode === BatchMode.SUGGESTED_PRICE ? !reason : !input;

  /**
   * This method is used for batch update the backend price update.
   */
  const saveBatchEditingPrice = () => {
    const numberInput = Number(input);
    // // Track batch Edit options and save.
    // trackAnalytics( 'Priceable Item Price Batch Edited', {
    //   workflow: 'Priceable Item Price Edit',
    //   object_type: 'input',
    //   object_name: 'Priceable Item Batch Edit',
    //   object_value: numberInput,
    //   location_uuid: currentLocationSelection,
    //   priceable_item_uuids: batchSelectionPriceableItems,
    // });
    const allWorkingPriceInputs: WorkingPriceInput[] = [];
    selectedNodes.forEach(eachNode => {
      const priceableItem = find(allItems, ['id', eachNode.id]);
      if (priceableItem) {
        const workingPriceInput = getWorkingPriceInput(
          reason,
          numberInput,
          valueTypePercentage,
          batchMode,
          priceableItem,
        );
        if (workingPriceInput) {
          allWorkingPriceInputs.push(workingPriceInput);
        }
      }
    });
    saveWorkingPriceBatch(allWorkingPriceInputs);

    // 2) Proactively updating the grid with new data
    selectedNodes.forEach(rowNode => {
      const workingPriceChange = find(allWorkingPriceInputs, ['priceableItemId', rowNode.data.id]);
      if (workingPriceChange) {
        const prevData = rowNode.data;
        const workingPrice = {
          ...prevData.workingPrice,
          price: workingPriceChange.price,
          lastChangeReason: reason,
        };
        rowNode.setData({ ...prevData, workingPrice });
      }
    });

    closePopup();
  };

  return (
    <BatchEditing>
      <BatchEditModeSelector>
        <Checkbox
          radio
          label="Set the same price"
          name="batchEditMethods"
          checked={batchMode === BatchMode.SAME_PRICE}
          onChange={() => setBatchMode(BatchMode.SAME_PRICE)}
        />
        <Checkbox
          radio
          label="Adjust prices"
          name="batchEditMethods"
          checked={batchMode === BatchMode.ADJUST_PRICE}
          onChange={() => setBatchMode(BatchMode.ADJUST_PRICE)}
        />
        <Checkbox
          radio
          label="Set to suggested price"
          name="batchEditMethods"
          checked={batchMode === BatchMode.SUGGESTED_PRICE}
          onChange={() => setBatchMode(BatchMode.SUGGESTED_PRICE)}
        />
      </BatchEditModeSelector>

      <BatchModeValueWrapper>
        {batchMode !== BatchMode.SUGGESTED_PRICE && (
          <TextField
            type="text"
            value={input}
            onChange={event => cleanAndSetVal(event)}
            className={cn({ cropped: batchMode === BatchMode.ADJUST_PRICE })}
          />
        )}
        {batchMode === BatchMode.ADJUST_PRICE && (
          <div className="adjust-by-box">
            <button
              type="button"
              className={cn({ active: valueTypePercentage })}
              onClick={() => setValueTypePercentage(true)}
            >
              %
            </button>
            <button
              type="button"
              className={cn({ active: !valueTypePercentage })}
              onClick={() => setValueTypePercentage(false)}
            >
              #
            </button>
          </div>
        )}
        <TextField
          type="text"
          placeholder="Enter a Reason"
          value={reason}
          onChange={event => setReason(event.target.value)}
          className={cn({ full: batchMode === BatchMode.SUGGESTED_PRICE })}
        />
      </BatchModeValueWrapper>

      <Button
        theme={'fill'}
        size={'medium'}
        disabled={disableApplyButton()}
        onClick={saveBatchEditingPrice}
      >
        {`Apply to ${pluralize(selectedNodes.length, 'item')}`}
      </Button>
    </BatchEditing>
  );
}

export default StandardBatchEditingComponent;
