import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { LocationDescriptorObject } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { configure } from '@wework/we-auth-react';
import ROUTES from '../utils/routes/routes';
import {
  employeeUuidSelector,
  loggedInSelector,
  referrerSelector,
  unAuthenticatedSelector,
  userSelector,
} from '../sharedStore/modules/auth/auth.selector';
import { ADD_REFERRER, AUTHENTICATE } from '../sharedStore/modules/auth/auth.ducks';
import authenticatedRoutes from '../utils/routes/routes.authenticated';
import unauthenticatedRoutes from '../utils/routes/routes.unauthenticated';
import { usePageViews, useUserLogin } from '../utils/analytics/helpers';
import currentPathNameSelector from '../sharedStore/modules/router/router.selector';
import SidebarComponent from '../sharedComponents/sidebar/sidebar';
import { MainRegionStyled } from '../styles/app.styled';
import AppConfig from '../config';
import { auth0 } from '../index';

function logoutFlagExist() {
  return window.localStorage.getItem('from_logout') === 'true';
}

function removeLogoutFlag() {
  window.localStorage.removeItem('from_logout');
}

function RootPage(): ReactElement | null {
  const [weAuthFlag, setWeAuthFlag] = useState<boolean>(false);
  // TRACKING:
  usePageViews();
  useUserLogin();

  // Dispatching Actions
  const dispatch = useDispatch();
  // Dispatching props in functional component.
  const setReferrer = useCallback(
    (payload: LocationDescriptorObject) => dispatch({ type: ADD_REFERRER, payload }),
    [dispatch],
  );
  const authenticateUser = useCallback(() => dispatch({ type: AUTHENTICATE }), [dispatch]);

  const redirectToLogout = useCallback(() => dispatch(push(ROUTES.LOGOUT)), [dispatch]);

  // Mapping state props
  const isLoggedIn = useSelector(loggedInSelector);
  const isUnAuthenticated = useSelector(unAuthenticatedSelector);
  const location = useSelector(currentPathNameSelector);
  const employeeUuid = useSelector(employeeUuidSelector);

  const loggedInLoadData = () => {
    // NOTE: we don't want to set referrer when its /callback because
    // it is the url navigated to when auth sdk logs in successfully
    if (location?.pathname !== ROUTES.CALLBACK) {
      setReferrer(location);
      authenticateUser();
    }
  };

  const loggedOutLoadData = () => {
    // After successful sign out we are left with a freshly mounted app.
    // The "from_logout" flag let's us know we came from the logout action
    // and that we should navigate to the logged out page
    if (logoutFlagExist()) {
      removeLogoutFlag();
      redirectToLogout();
      authenticateUser();
    }
  };

  // BC: BUG FIX for fast switching screen and no data loaded on grid.
  // Will look better when we remove the store completely
  const resetReferrerHelper = () => {
    dispatch({ type: ADD_REFERRER, payload: null });
  };

  useEffect(resetReferrerHelper, []);

  /** LOGIN CYCLE:
   * 1) Try to authenticate the user and mark the referrer page.
   * 2) Once authenticated and logged in:
   *    a) Fetch the current user info and roles.
   *    b) Redirect to the appropriate page after login.
   *      - If error redirect to access denied page.
   *      - If no error redirect to home page.
   * 3) Once Authenticated setup we-auth library config needed for permission injection.
   * 4) After the we-auth library is set up redirect to authenticated app.
   */
  // Try to authenticate the user.
  useEffect(loggedInLoadData, []);
  // If Logged out then reset data and logging in state.
  useEffect(loggedOutLoadData, []);

  // BC: Wait for employeeId to be present - which happens after authenticate and then config weAuth.
  const loadWeAuth = () => {
    if (employeeUuid) {
      // initialize we-auth-react
      configure({
        environment: AppConfig.AUTH0_APP_ENV ?? '',
        contextClient: {
          getAccessToken: () => auth0.getTokenSilently(),
          getEmployeeUuid: () => employeeUuid,
        },
      });
      setWeAuthFlag(true);
    }
  };
  useEffect(loadWeAuth, [employeeUuid]);

  if (logoutFlagExist()) {
    return null;
  } else if (isLoggedIn && !isUnAuthenticated && weAuthFlag) {
    return (
      <>
        <SidebarComponent />
        <MainRegionStyled>
          <AuthenticatedApp />
        </MainRegionStyled>
      </>
    );
  }

  return (
    <MainRegionStyled>
      <UnauthenticatedApp />
    </MainRegionStyled>
  );
}

function AuthenticatedApp(): ReactElement {
  // STATE PROPS
  const currentReferrer = useSelector(referrerSelector);
  const user = useSelector(userSelector);
  if (user) {
    window.Rollbar.configure({
      payload: {
        person: {
          id: user['https://wework.com/employee_uuid'],
          ...user,
        },
      },
    });
  }

  return (
    <Switch>
      {authenticatedRoutes.map(route => (
        <Route key={`${route.path}`} {...route} />
      ))}
      {
        <Redirect
          to={`${currentReferrer?.pathname ?? ''}${currentReferrer?.search ?? ''}` ?? ROUTES.HOME}
        />
      }
    </Switch>
  );
}

const UnauthenticatedApp = () => (
  <Switch>
    {unauthenticatedRoutes.map(route => (
      <Route key={`${route.path}`} {...route} />
    ))}
    <Redirect to={ROUTES.LOGIN} />
  </Switch>
);

export default RootPage;
