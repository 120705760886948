import { Search, SearchProps } from 'semantic-ui-react';
import styled from 'styled-components';

export const LabelsWrapper = styled.div`
  padding-top: 16px;
`;

export const LocationSearch = styled(Search)<SearchProps>({
  [`.ui.input`]: {
    width: '100%',
  },
  [`.ui.input > input `]: {
    borderRadius: '0.25rem !important',
  },
});
