import * as React from 'react';
import { ReactElement } from 'react';
import cn from 'classnames';
import { v4 as uuidV4 } from 'uuid';
import { Checkbox } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { isEmpty } from 'lodash';
import { TextLink } from '../../../../breakeven/components/breakevenTable/breakevenTableAGGrid.styled';
import CustomTermsInfo from '../../discountCustomTermsInfo';
import { SummaryNote } from '../../../../pricing/standardPricing/components/pricingPublish/pricingPublish.styled';
import { inputNumberFormatterNoNegative } from '../../../../pricing/standardPricing/components/helpers';
import { discountItemsTermTypes } from '../../../store/modules/discountItems/discountItems.selector';
import { Unassigned } from '../../../../../utils/sharedTypes';
import { convertToPercent } from '../../../../breakeven/components/breakevenTable/breakevenTable.helper';
import { CurveTermInputMod, DiscountCurvesModalMode } from '../curveManagementModal';
import {
  BaseOverrideRow,
  BaseOverrideWrapper,
  ModalSubTitle,
  OverrideBox,
  OverrideDisplayBox,
} from '../../discountsOverride/discountsOverride.styled';
import { Curve, TermType } from '../../../../../generated/voyager/graphql';

interface DiscountCurvesOverrideModalDetailsProps {
  curveData?: Curve;
  selectedTerms: TermType[];
  curveTermInputMod: CurveTermInputMod[] | Unassigned;
  prevCurveTermInputMod: CurveTermInputMod[] | Unassigned;
  mode: DiscountCurvesModalMode;
  setSelectedTerms: Function;
  setCurveTermInputMod: Function;
  setPrevCurveTermInputMod: Function;
}

function DiscountCurvesOverrideModalDetails({
  curveData,
  selectedTerms,
  curveTermInputMod,
  prevCurveTermInputMod,
  mode,
  setSelectedTerms,
  setCurveTermInputMod,
  setPrevCurveTermInputMod,
}: DiscountCurvesOverrideModalDetailsProps): ReactElement {
  const termTypes = useSelector(discountItemsTermTypes);

  const modifyCurveInputMonth = (value: string, id: string, index: number) => {
    // Validation of input.
    if (parseFloat(value) < 0 || parseFloat(value) > 100) {
      toast.error('Validation error - Value should be between 0 - 100%.', {
        position: toast.POSITION.TOP_CENTER,
        toastId: 'curveManagementCreationValidation',
      });
      return;
    }

    const tempCurveInputs =
      curveTermInputMod && !isEmpty(curveTermInputMod) ? [...curveTermInputMod] : [];

    const termIdx = tempCurveInputs?.findIndex(eTerm => eTerm.termId === id) ?? -1;
    const monthIdx =
      tempCurveInputs && termIdx > -1
        ? tempCurveInputs[termIdx].dataPoints?.findIndex(eDP => eDP.month === index) ?? -1
        : -1;
    if (!isEmpty(tempCurveInputs) && termIdx > -1) {
      const dataPoints = tempCurveInputs[termIdx].dataPoints ?? undefined;
      // Modifying field else if the object is new adding the required empty field
      if (dataPoints && monthIdx > -1) {
        // Deleting if the value is empty.
        // Can only delete after adding so assuming the termIdx and monthIdx is not null in this case.
        if (value) {
          dataPoints.splice(monthIdx, 1, {
            month: index,
            value,
          });
        } else {
          dataPoints.splice(monthIdx, 1);
        }
        tempCurveInputs[termIdx].dataPoints = dataPoints;
      } else if (dataPoints) {
        dataPoints.push({ month: index, value });
        tempCurveInputs[termIdx].dataPoints = dataPoints;
      }
    } else {
      tempCurveInputs.push({
        termId: id,
        dataPoints: [{ month: index, value }],
      });
    }
    setCurveTermInputMod(tempCurveInputs);
  };

  const disableOverrideBox = (termType: any): boolean =>
    selectedTerms.find(selectedTerm => selectedTerm.id === termType.id) === undefined;

  const isOverridden = (termId: string, month: number) => {
    let currentValue: string | number | undefined =
      curveTermInputMod
        ?.find(eachT => eachT.termId === termId)
        ?.dataPoints?.find(eachDP => eachDP.month === month)?.value ?? undefined;
    currentValue = typeof currentValue === 'string' ? parseFloat(currentValue) : currentValue;
    currentValue = typeof currentValue === 'number' ? convertToPercent(currentValue) : currentValue;
    let previousValue: number | undefined =
      curveData?.curveTerms
        ?.find(eachT => eachT.term.id === termId)
        ?.dataPoints?.find(eachDP => eachDP.month === month)?.value ?? undefined;
    previousValue = previousValue ? convertToPercent(previousValue) : previousValue;
    return currentValue !== previousValue && currentValue !== undefined;
  };

  const changeTermSelection = (term: TermType, selected?: boolean) => {
    const foundTerm = selectedTerms.find(selectedTerm => selectedTerm.id === term.id);
    if (selected) {
      if (!foundTerm) {
        setSelectedTerms(selectedTerms.concat(term));
      }
    } else {
      setSelectedTerms(selectedTerms.filter(t => t.id !== term.id));
      setCurveTermInputMod(curveTermInputMod?.filter(t => t.termId !== foundTerm?.id));
      setPrevCurveTermInputMod(curveTermInputMod?.filter(t => t.termId !== foundTerm?.id));
    }
  };

  const getCurrentTermValue = (termId: string, month: number): string | number | undefined => {
    const currVal =
      curveTermInputMod
        ?.find(eachT => eachT.termId === termId)
        ?.dataPoints?.find(eachDP => eachDP.month === month)?.value ?? undefined;
    return typeof currVal === 'number' ? convertToPercent(currVal) : currVal;
  };

  const getTermValuePercent = (
    termId: string,
    month: number,
    termDistributionArr: CurveTermInputMod[] | Unassigned,
  ): string | number | undefined => {
    const termDistribution = termDistributionArr?.find(eachT => eachT.termId === termId);
    const termVal =
      termDistribution?.dataPoints?.find(eachDP => eachDP.month === month)?.value ?? undefined;
    if (termVal) {
      return `${typeof termVal === 'number' ? convertToPercent(termVal) : termVal}%`;
    } else if (termDistribution) {
      return '0%';
    }
    return '-';
  };

  const monthDiscountInputRenderers = () =>
    termTypes &&
    termTypes.map(termType => (
      <React.Fragment key={termType.id}>
        <li
          key={`li1-${termType.id}`}
          className={cn({
            'smaller-margin': mode === DiscountCurvesModalMode.EDIT,
          })}
        >
          {Array(termType.length)
            .fill(termType.length)
            .map((_termTypeTemp, index) =>
              mode === DiscountCurvesModalMode.VIEW ? (
                <OverrideDisplayBox
                  key={`curr-${termType.id}-${index.toString()}`}
                  className="bottom-margin"
                >
                  <span>{getTermValuePercent(termType.id, index + 1, curveTermInputMod)}</span>
                </OverrideDisplayBox>
              ) : (
                <OverrideBox
                  key={`curr-${termType.id}-${index.toString()}`}
                  className={cn(
                    {
                      'disabled-box': disableOverrideBox(termType),
                    },
                    'bottom-margin',
                  )}
                >
                  <input
                    key={`input-${termType.id}-${index.toString()}`}
                    type="text"
                    onChange={(event: any) =>
                      modifyCurveInputMonth(
                        inputNumberFormatterNoNegative(event.target.value),
                        termType.id,
                        index + 1,
                      )
                    }
                    value={getCurrentTermValue(termType.id, index + 1) || ''}
                    className={cn(
                      {
                        filled:
                          isOverridden(termType.id, index + 1) && !disableOverrideBox(termType),
                      },
                      { 'init-color': !isOverridden(termType.id, index + 1) },
                      'curve-input',
                    )}
                    disabled={disableOverrideBox(termType)}
                  />
                  <span>%</span>
                </OverrideBox>
              ),
            )}
        </li>
        {mode === DiscountCurvesModalMode.EDIT ? (
          <li key={`li2-${termType.id}`} className={'smaller-gap larger-margin'}>
            {Array(termType.length)
              .fill(termType.length)
              .map((_termTypeTemp, index) => (
                <OverrideDisplayBox
                  key={`prev-${termType.id}-${index.toString()}`}
                  className="bottom-margin"
                >
                  <span>{getTermValuePercent(termType.id, index + 1, prevCurveTermInputMod)}</span>
                </OverrideDisplayBox>
              ))}
          </li>
        ) : (
          ''
        )}
      </React.Fragment>
    ));

  const termMonthsRenderers = () => (
    <li
      className={cn({
        'smaller-margin smaller-gap': mode === DiscountCurvesModalMode.EDIT,
      })}
    >
      {Array(36)
        .fill(36)
        .map((_termType, index) => (
          <OverrideDisplayBox key={uuidV4()} className="bottom-margin">
            <span>M{index + 1}</span>
          </OverrideDisplayBox>
        ))}
    </li>
  );

  const termsRenderers = () =>
    termTypes.map(term => (
      <li
        key={uuidV4()}
        className={cn({
          'larger-gap larger-margin': mode === DiscountCurvesModalMode.EDIT,
        })}
      >
        {mode === DiscountCurvesModalMode.VIEW ? (
          <span>{term.label}</span>
        ) : (
          <Checkbox
            label={term.label}
            value={term.id}
            checked={selectedTerms.find(selectedTerm => selectedTerm.id === term.id) !== undefined}
            onChange={(_, data) => changeTermSelection(term, data.checked)}
          />
        )}
      </li>
    ));

  return (
    <BaseOverrideWrapper>
      <BaseOverrideRow className="white-background extra-height">
        <li>
          <div>
            <ModalSubTitle className={'no-indent  inline-subtitle'}>Select Terms</ModalSubTitle>
            {mode === DiscountCurvesModalMode.VIEW ? (
              <></>
            ) : (
              <>
                <TextLink
                  className="margin-left-space modified-size"
                  onClick={() => setSelectedTerms(termTypes)}
                >
                  Select All
                </TextLink>
                <span> </span>
                <TextLink
                  className="margin-left-space modified-size"
                  onClick={() => {
                    setSelectedTerms([]);
                    setCurveTermInputMod([]);
                    setPrevCurveTermInputMod([]);
                  }}
                >
                  Clear All
                </TextLink>
              </>
            )}
          </div>
          <CustomTermsInfo
            tooltipPosition={'bottom right'}
            textOverride={'Select terms this curve will be applied to.'}
            className="clear-float"
          />
        </li>
        {termsRenderers()}
      </BaseOverrideRow>
      <BaseOverrideRow className="white-background extra-height">
        <li>
          <ModalSubTitle className="no-indent">Enter Discounts</ModalSubTitle>
          <SummaryNote className="info no-indent">
            Enter discount curve value for selected terms.
          </SummaryNote>
        </li>
        {termMonthsRenderers()}
        {monthDiscountInputRenderers()}
      </BaseOverrideRow>
    </BaseOverrideWrapper>
  );
}
export default DiscountCurvesOverrideModalDetails;
