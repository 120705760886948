import { Action, handleActions } from 'redux-actions';
import { get } from 'lodash';
import { MarketsSubset } from '../../entities/markets';
import initialState from '../../../utils/store/initialState';
import { createRDXConstant } from '../../../utils/store/store.constants';

// Action Constants
// Action - fetch all markets by territory id.
export const FETCH_MARKETS_BY_TERRITORY = createRDXConstant('FETCH_MARKETS_BY_TERRITORY');
export const FETCH_MARKETS_BY_TERRITORY_SUCCESS = createRDXConstant(
  'FETCH_MARKETS_BY_TERRITORY_SUCCESS',
);

// Reducer
export const marketsReducer = handleActions<MarketsSubset, any>(
  {
    // Reducer to fetch all markets by territory id.
    [FETCH_MARKETS_BY_TERRITORY]: (state: MarketsSubset) => ({
      ...state,
      fetchMarketsState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_MARKETS_BY_TERRITORY_SUCCESS]: (state: MarketsSubset, action: Action<any>) => {
      const allMarkets = get(action, 'payload.marketsByTerritory', []);
      return {
        ...state,
        fetchMarketsState: {
          loading: false,
          loaded: true,
          error: null,
        },
        allMarkets,
      };
    },
  },
  initialState.markets,
);
