import {
  ColDef,
  ColGroupDef,
  GetQuickFilterTextParams,
  GridOptions,
  ValueFormatterParams,
} from 'ag-grid-community';
import {
  dateToStringVF,
  getRowId,
  getStringOfTermNames,
  toTitleCaseVF,
  truncateCellValue,
} from '../discountsTable/discountAGGrid.helpers';
import './curveManagementTableAGGrid.css';
import DiscountsCurveManagementOptionTrigger from './renderers/discountCurveManagementLinkRenderer';
import TableLoadingRenderer from '../../../../sharedComponents/tableComponent/tableLoadingRenderer';
import EmptyTableComponentRenderer from '../../../../sharedComponents/tableComponent/tableEmpty';
import DiscountsCurveManagementCurveStatus from './renderers/discountsCurveManagementCurveStatus';
import { getCurveStatus } from '../discountItems.helpers';
import { formatDateFromDateString } from '../../../../utils/helpers';

const ROW_HEIGHT = 56;

export const defaultColDef: ColDef = {
  flex: 1,
  minWidth: 125,
  suppressMenu: true,
  wrapText: true,
  cellClass: ['cell-vertical-alignment-center'],
  getQuickFilterText(params: GetQuickFilterTextParams): string {
    const field = params.colDef.field;
    // just search by the reservable name in the grid
    if (field === 'name' || field === 'description') {
      return params.value;
    }
    return '';
  },
};

export const columnDefs: (ColDef | ColGroupDef)[] = [
  {
    headerName: 'Curve Name',
    field: 'name',
    sortable: true,
  },
  {
    headerName: 'Description',
    field: 'description',
    sortable: true,
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
    tooltipField: 'description',
    valueFormatter: truncateCellValue(12, ROW_HEIGHT),
  },
  {
    headerName: 'Terms',
    field: 'curveTerms',
    sortable: true,
    valueFormatter: getStringOfTermNames,
  },
  {
    headerName: 'Operator',
    field: 'operator.name',
    sortable: false,
  },
  {
    headerName: 'Created By',
    field: 'createdBy',
    sortable: true,
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
    valueFormatter: toTitleCaseVF,
  },
  {
    headerName: '# linked reservables',
    field: 'appliedCount',
    maxWidth: 125,
    sortable: true,
  },
  {
    headerName: 'Last Updated',
    field: 'updatedAt',
    sortable: true,
    maxWidth: 125,
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
    valueFormatter: dateToStringVF,
  },
  {
    headerName: 'Last Updated By',
    field: 'lastUpdatedBy',
    sortable: true,
    valueFormatter: toTitleCaseVF,
  },
  {
    headerName: 'Expiration Date',
    field: 'expirationDate',
    sortable: true,
    maxWidth: 125,
    cellClass: ['background-gray', 'cell-vertical-alignment-center'],
    valueFormatter: (params: ValueFormatterParams) =>
      params.value ? formatDateFromDateString(params.value) : '-',
  },
  {
    headerName: 'Active',
    field: 'active',
    sortable: true,
    maxWidth: 220,
    cellRenderer: DiscountsCurveManagementCurveStatus,
    cellClass: ['no-padding', 'background-gray', 'cell-vertical-alignment-center'],
    comparator: (_: any, _2: any, curve1: any, curve2: any) => {
      const curveStatus1 = getCurveStatus(curve1.data);
      const curveStatus2 = getCurveStatus(curve2.data);
      return String(curveStatus1).localeCompare(String(curveStatus2));
    },
  },
  {
    headerName: '',
    minWidth: 80,
    maxWidth: 80,
    cellClass: ['no-padding', 'cell-vertical-alignment-center'],
    cellRenderer: DiscountsCurveManagementOptionTrigger,
  },
];

export const gridOptions: GridOptions = {
  defaultColDef,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  groupDisplayType: 'custom',
  rowHeight: ROW_HEIGHT,
  getRowId,
  loadingOverlayComponent: TableLoadingRenderer,
  noRowsOverlayComponent: EmptyTableComponentRenderer,
  showOpenedGroup: true,
  debounceVerticalScrollbar: true,
  suppressColumnVirtualisation: true,
};
