import { useDispatch } from 'react-redux';
import React, { useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { MainRegionStyled, OverflowHack } from 'styles/app.styled';
import ROUTES from '../../../utils/routes/routes';
import { AuthzProps } from '../../../utils/constants';
import { AdminPanelContentWrapper } from './adminPanelView.styled';
import ResetBreakEven from '../components/resetBreakEven/resetBreakEven';
import HeaderComponent from '../../../sharedComponents/header/header';
import { FunctionalZone } from '../../../styles/functionalZone/adminPanel.styled';
import EmployeePermissions from '../../../utils/store/permissions';

const ResetBreakEvenView = ({ requestedPermissions }: AuthzProps) => {
  const dispatch = useDispatch();

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_system_admin, false)) {
      redirectToAccessDenied();
    }
  }, [requestedPermissions, redirectToAccessDenied]);

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent title="Reset BreakEven" />
          <AdminPanelContentWrapper>
            <FunctionalZone>
              <p>Reset the BreakEven data by clicking this button!</p>
              <ResetBreakEven />
            </FunctionalZone>
          </AdminPanelContentWrapper>
        </OverflowHack>
      </MainRegionStyled>
    </>
  );
};

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_system_admin],
})(ResetBreakEvenView);
