import * as React from 'react';
import { ReactElement } from 'react';
import { Table } from 'semantic-ui-react';
import { differenceInCalendarMonths, endOfDay, format } from 'date-fns';
import { TableWrap } from '../multipleLocationWidget.styled';
import DetailPanelTrigger from './detailPanelTrigger';
import { Location } from '../../../generated/voyager/graphql';

interface OverviewTabProps {
  locationsDetails: Location[];
}

function OverviewTab({ locationsDetails }: OverviewTabProps): ReactElement {
  return (
    <>
      <TableWrap>
        <Table basic="very" className="varColumns3 locations-tab-table">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date Open</Table.HeaderCell>
              <Table.HeaderCell>Currency</Table.HeaderCell>
              <Table.HeaderCell>Desks</Table.HeaderCell>
              <Table.HeaderCell>Time Zone</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {locationsDetails.map(location => {
              let locationOpenDateStr = '--';
              let getMonthsOpenStr = '--';
              if (location.metrics?.locationOpenDate) {
                const locationOpenDate = Date.parse(
                  `${location.metrics.locationOpenDate}T00:00:00`,
                );
                const getMonthsOpen = differenceInCalendarMonths(
                  endOfDay(new Date()),
                  locationOpenDate,
                );

                if (locationOpenDate) {
                  locationOpenDateStr = format(locationOpenDate, 'PP');
                }

                if (getMonthsOpen) {
                  getMonthsOpenStr = `${getMonthsOpen}M`;
                }
              }

              return (
                <Table.Row key={`location-widget-table-${location.id}`}>
                  <Table.Cell>{`${locationOpenDateStr} (${getMonthsOpenStr})`}</Table.Cell>
                  <Table.Cell>{location.currencyIsoCode ?? '-'}</Table.Cell>
                  <Table.Cell>{location.deskCount ?? '-'}</Table.Cell>
                  <Table.Cell>{location.timeZone ?? '-'}</Table.Cell>
                  <Table.Cell>
                    <DetailPanelTrigger
                      currentDisplayableItem={location.id}
                      currentDisplayableItemType={'location'}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </TableWrap>
    </>
  );
}

export default OverviewTab;
