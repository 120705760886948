import { Action, handleActions } from 'redux-actions';
import { sortBy, uniqBy } from 'lodash';
import { PublishPricesSubset, WorkingPricesPagedItem } from '../../entities/publishPrice';
import { publishPricesSubsetSelector } from './publishPrice.selector';
import initialState, {
  initialPublishPricesSubset,
} from '../../../../../../utils/store/initialState';
import { createRDXConstant } from '../../../../../../utils/store/store.constants';

// Action - for fetching priceable Items with working price by batch.
export const FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE = createRDXConstant(
  'FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE',
);
export const STOP_FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE = createRDXConstant(
  'STOP_FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE',
);
export const FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE_SUCCESS = createRDXConstant(
  'FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE_SUCCESS',
);
export const START_WORKING_PRICE_BATCH_FETCH = createRDXConstant('START_WORKING_PRICE_BATCH_FETCH');
export const END_WORKING_PRICE_BATCH_FETCH = createRDXConstant('END_WORKING_PRICE_BATCH_FETCH');
export const WORKING_PRICE_PUBLISH = createRDXConstant('WORKING_PRICE_PUBLISH');
export const WORKING_PRICE_PUBLISH_SUCCESS = createRDXConstant('WORKING_PRICE_PUBLISH_SUCCESS');
export const CLEAR_PRICE_PUBLISH = createRDXConstant('CLEAR_PRICE_PUBLISH');
// Reducer
export const publishPriceReducer = handleActions<PublishPricesSubset, any>(
  {
    // Reducer for fetching all the priceable items with working price.
    [FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE]: (state: PublishPricesSubset) => ({
      ...state,
    }),
    [STOP_FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE]: (state: PublishPricesSubset) => ({
      ...state,
    }),
    [FETCH_PRICEABLE_ITEMS_WITH_WORKING_PRICE_SUCCESS]: (
      state: PublishPricesSubset,
      action: Action<WorkingPricesPagedItem[]>,
    ) => {
      const oldWorkingPrices = publishPricesSubsetSelector(state) ?? [];
      const workingPrices = [...oldWorkingPrices, ...action.payload];

      return {
        ...state,
        workingPrices: sortBy(uniqBy(workingPrices, 'priceableItem.id'), 'page'),
      };
    },

    // Reducer for batch fetch working prices from gateway.
    [START_WORKING_PRICE_BATCH_FETCH]: (state: PublishPricesSubset) => ({
      ...state,
      batchFetch: true,
    }),
    [END_WORKING_PRICE_BATCH_FETCH]: (state: PublishPricesSubset) => ({
      ...state,
      batchFetch: false,
    }),

    [CLEAR_PRICE_PUBLISH]: (state: PublishPricesSubset) => ({
      ...state,
      ...initialPublishPricesSubset,
    }),

    // Reducer for Publishing all working price changes.
    [WORKING_PRICE_PUBLISH]: (state: PublishPricesSubset) => ({
      ...state,
      publish: true,
    }),
    [WORKING_PRICE_PUBLISH_SUCCESS]: (state: PublishPricesSubset) => ({
      ...state,
      workingPrices: [],
      publish: false,
    }),
  },
  initialState.publishPrice,
);
