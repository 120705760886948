import styled from 'styled-components';

const bgImg =
  'https://ctfassets.imgix.net/6vy33zo2mgy3/uYDw6FybNhKp4BrRldMw1/a1171b7db3a84cebe3c6e5c427d6f408/WeWork_Weihai-final-large-3.jpg';

export const SplashPageWrapper = styled.main`
  height: 100%;
  width: 100%;
`;
export const SplashPageBg = styled.div`
  background-image: url(${bgImg});
  background-size: cover;
  border-radius: 8px;
  display: flex;
  height: 100%;
`;
export const SplashPageContent = styled.div`
  background: linear-gradient(0deg, #285dff 0%, #83c0ff 100%);
  padding: 2rem 4rem;
  position: relative;
`;
export const WeWorkLogo = styled.img`
  width: 100%;
  max-width: 274px;
  position: absolute;
  bottom: 0;
`;
export const WeWorkLogoContainer = styled.div`
  position: absolute;
  bottom: 0;
`;
export const VoyagerLogoMainPage = styled.img`
  width: 100%;
  max-width: 274px;
  padding: 54px 0 125px;
`;
