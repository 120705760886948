import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { loggedInSelector } from '../../sharedStore/modules/auth/auth.selector';
import { auth0 } from '../../index';
import { usePrevious } from '../../app/pricing/standardPricing/components/helpers';
import { errorHandlerPassive } from '../errorHandling/helpers';

/**
 * Tracking for Page Views
 *
 * - Track only if page path name change and not for search param change.
 */
export function usePageViews(): void {
  // Current Location
  const location = useLocation();
  // Previous Location
  const prevLocation = usePrevious(location);

  // Only if there is a change in location pathname tracking the location change
  try {
    React.useEffect(
      () => () =>
        (!prevLocation || prevLocation?.pathname !== location?.pathname) &&
        window?.analytics?.page(location?.pathname, {
          screenWidth: window?.screen?.availWidth,
          screenHeight: window?.screen?.availHeight,
        }),
      [location, prevLocation],
    );
  } catch (e: any) {
    errorHandlerPassive(e);
  }
}

/**
 * Tacking for user Login
 *
 * - Track only user login change.
 */
export function useUserLogin(): void {
  // Current Logged In state
  const loggedIn = useSelector(loggedInSelector);
  // Previous Logged In state
  const prevLoggedIn = usePrevious(loggedIn);
  React.useEffect(() => {
    // Only if logged in and the prevLoggedIn state is not same.
    if (loggedIn && prevLoggedIn !== loggedIn) {
      // Get User if logged in.
      auth0
        .getUser()
        .then(value =>
          window?.analytics?.identify(get(value, 'https://wework.com/employee_uuid', ''), value),
        )
        .catch(error => errorHandlerPassive(error));
    }
  }, [loggedIn, prevLoggedIn]);
}

/**
 * Tacking Dropdown UserInput helper functions
 *
 * - Track only debounced input change in dropdown.
 */
export const trackAnalytics = (event: string, properties: Object): void => {
  try {
    // User info
    if (window && window.analytics) {
      window.analytics.track(event, {
        ...properties,
      });
    }
  } catch (e: any) {
    errorHandlerPassive(e);
  }
};
