import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { pluralize } from 'apollo/lib/utils';
import { Confirm } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { IRowNode } from 'ag-grid-community';
import {
  MutationResetPricesToLastPublishedArgs,
  WorkingPriceScope,
} from '../../../../../../generated/voyager/graphql';
import {
  REVERT_WORKING_PRICES,
  SET_SELECTED_NODES_STANDARD_PRICING,
} from '../../../store/modules/standardPricingItems/standardPricingItems.ducks';
import {
  standardPricingRevertPricesLoadingSelector,
  standardPricingSelectedNodesSelector,
} from '../../../store/modules/standardPricingItems/standardPricingItems.selector';
import { ConfirmProps } from '../../../../../../sharedStore/entities/confirmProps';
import { DataWithCallback } from '../../../../../../utils/sharedTypes';

function ResetWorkingPricesConfirm(props: ConfirmProps): ReactElement {
  const selectedNodes = useSelector(standardPricingSelectedNodesSelector);
  const resetLoading = useSelector(standardPricingRevertPricesLoadingSelector);

  const dispatch = useDispatch();
  const resetPricesToLastPublished = useCallback(
    (payload: DataWithCallback<MutationResetPricesToLastPublishedArgs>) =>
      dispatch({ type: REVERT_WORKING_PRICES, payload }),
    [dispatch],
  );
  const resetSelectedNodes = useCallback(
    (payload: IRowNode[] = []) => dispatch({ type: SET_SELECTED_NODES_STANDARD_PRICING, payload }),
    [dispatch],
  );

  const closeResetConfirmationModal = () => {
    props.closeModal();
    resetSelectedNodes();
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={closeResetConfirmationModal}
      onConfirm={() =>
        resetPricesToLastPublished({
          data: {
            ids: selectedNodes.filter(node => !!node.data.workingPrice).map(node => node.id),
            scope: WorkingPriceScope.PRICEABLE_ITEM,
          },
          successCallback: closeResetConfirmationModal,
        })
      }
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
        disabled: resetLoading,
      }}
      closeIcon
      confirmButton={{
        content: `Revert Prices for ${pluralize(
          selectedNodes.filter(node => !!node.data.workingPrice).length,
          'item',
        )}`,
        loading: resetLoading,
      }}
      header="Revert Prices"
      content={
        <div>
          <p>
            For the selected reservables, working prices will revert to what was last published -
            your unpublished changes will be lost.
          </p>
          <p>Are you sure you want to revert prices to last published?</p>
        </div>
      }
    />
  );
}

export default ResetWorkingPricesConfirm;
