import React, { useCallback, useEffect } from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AuthzProps } from 'utils/constants';
import ROUTES from 'utils/routes/routes';
import { MainRegionStyled, OverflowHack } from 'styles/app.styled';
import HeaderComponent from 'sharedComponents/header/header';
import { AdminPanelContentWrapper } from './adminPanelView.styled';
import {
  DISCOUNT_SYNC_ATTEMPTS_SET_FILTER,
  DISCOUNT_SYNC_ATTEMPTS_SET_PAGE_INFO,
  FETCH_DISCOUNT_SYNC_ATTEMPTS_WITH_FILTER,
  RE_PUBLISH_DISCOUNT,
  SET_NODES_SELECTED_DISCOUNT_ADMIN,
} from '../store/modules/discountSyncAttempts/discountSyncAttempts.ducks';

import BottomPanelRePublish from '../components/bottomPanel/bottomPanelRePublish';
import EmployeePermissions from '../../../utils/store/permissions';
import {
  discountSyncAttemptIsLoadingSelector,
  discountSyncAttemptItemsSelector,
  discountSyncAttemptSelectedNodesSelector,
  discountSyncAttemptsFilterSelector,
  discountSyncAttemptsPageInfoSelector,
} from '../store/modules/discountSyncAttempts/discountSyncAttempts.selector';
import SyncAttemptsTable from '../components/syncAttempts/syncAttemptsTable/SyncAttemptsAGGridTable';
import { discountGridColumnDefs } from '../components/syncAttempts/syncAttemptsTable/syncAttemptsTableAGGrid.settings';

const DiscountSyncAttemptsView = ({ requestedPermissions }: AuthzProps) => {
  const dispatch = useDispatch();
  const filter = useSelector(discountSyncAttemptsFilterSelector);
  const pageInfo = useSelector(discountSyncAttemptsPageInfoSelector);

  const getGridData = useCallback(() => {
    dispatch({ type: FETCH_DISCOUNT_SYNC_ATTEMPTS_WITH_FILTER });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_system_admin, false)) {
      redirectToAccessDenied();
    } else {
      getGridData();
    }
  }, [getGridData, redirectToAccessDenied, requestedPermissions, filter, pageInfo]);

  const discountSyncAttemptsTableProps = {
    syncAttemptName: 'Discount',
    allSyncAttempts: useSelector(discountSyncAttemptItemsSelector),
    pageInfoSelected: useSelector(discountSyncAttemptsPageInfoSelector),
    selectedNodes: useSelector(discountSyncAttemptSelectedNodesSelector),
    rePublishAction: SET_NODES_SELECTED_DISCOUNT_ADMIN,
    pageInfoAction: DISCOUNT_SYNC_ATTEMPTS_SET_PAGE_INFO,
    setFilterAction: DISCOUNT_SYNC_ATTEMPTS_SET_FILTER,
    columnDefs: discountGridColumnDefs,
  };

  const bottomPanelRepublishProps = {
    selectedNodes: useSelector(discountSyncAttemptSelectedNodesSelector),
    loadingSelector: useSelector(discountSyncAttemptIsLoadingSelector),
    selectedRePublishAction: SET_NODES_SELECTED_DISCOUNT_ADMIN,
    rePublishAction: RE_PUBLISH_DISCOUNT,
    syncAttemptName: 'Discount',
  };

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <HeaderComponent title="Discount Sync Attempts" />
          <AdminPanelContentWrapper>
            <SyncAttemptsTable {...discountSyncAttemptsTableProps} />
          </AdminPanelContentWrapper>
        </OverflowHack>
      </MainRegionStyled>
      <BottomPanelRePublish {...bottomPanelRepublishProps} />
    </>
  );
};

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_system_admin],
})(DiscountSyncAttemptsView);
