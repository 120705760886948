import styled from 'styled-components';
import { blue, darkBlue, darkGray, lighterGray, white } from '../../styles/colors';
import Expand from '../../assets/expand_inactive.svg';
import {
  device,
  FlexHorizontalHelper,
  FlexVerticalHelper,
  ListReset,
} from '../../styles/app.styled';

export const MultipleLocationWidgetWrapper = styled.div`
  position: relative;
  display: block;
  margin: 3.625vh 40px 23px 40px;
  * {
    box-sizing: border-box;
  }
  > .toggle-locations-icon {
    position: absolute;
    top: 8px;
    right: 16px;
    z-index: 3;
    width: 22px;
    height: 22px;
    padding: 0;
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    outline: none;
    &::before {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      content: '';
      background-color: ${darkGray};
      -webkit-mask: url(${Expand});
      mask: url(${Expand});
    }
    &:hover::before,
    &.active::before {
      background-color: ${blue};
    }
  }
  > .ui.corner.blue.label {
    border: none;
    font-weight: bold !important;
    display: flex;
    align-items: baseline;
    padding-left: 6px !important;
    padding-top: 4px !important;
    color: ${white} !important;
  }
`;
export const LocationsTabsWrapper = styled.div`
  position: relative;
  padding: 0;
  background: white;
  border-radius: 4px;
  border: 1px solid ${lighterGray};
  ${FlexHorizontalHelper};
  height: 100%;
  max-height: 102px;
  overflow-y: auto;
  &.open {
    max-height: calc(100vh - 175px);
  }
  &.loading {
    > div {
      width: 98%;
      margin: 0.75rem auto 0;
    }
  }
  > .locations-tabs {
    order: 1;
    @media all and ${device.tablet} {
      flex: 1 1 58%;
    }
    @media all and ${device.tabletL} {
      flex: 1 1 70%;
    }
    .ui.pointing.secondary.menu {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      margin: 0;
      @media all and ${device.tablet} {
        width: 42%;
      }
      @media all and ${device.tabletL} {
        width: 30%;
      }
      .item {
        flex: 1 1 auto;
        padding: 9px 0 6px;
        border-width: 1px;
        justify-content: center;
        border-color: ${lighterGray};
        &.active {
          border-color: ${blue};
        }
      }
    }
    .ui.bottom.attached.segment.tab {
      padding: 0;
      background: none;
      z-index: 1;
      border-radius: 4px;
      border: none;
      margin: 0;
      @media all and ${device.tablet} {
        width: calc(100% - 10px);
      }
      @media all and ${device.tabletL} {
        width: calc(100% - 20px);
      }
    }
  }
`;
export const LocationsList = styled.ol`
  order: 0;
  ${ListReset};
  ${FlexVerticalHelper};
  padding-top: 39px;
  @media all and ${device.tablet} {
    width: 42%;
    flex: 0 0 42%;
  }
  @media all and ${device.tabletL} {
    width: 30%;
    flex: 0 0 30%;
  }
`;
export const LocationItemStyled = styled.li`
  height: 61px;
  flex: 0 0 auto;
  border-top: 1px solid ${lighterGray};
  border-right: 1px solid ${lighterGray};
  ${FlexVerticalHelper};
  justify-content: center;
  @media all and ${device.tablet} {
    padding: 0 10px 0 0;
    margin: 0 0 0 10px;
  }
  @media all and ${device.tabletL} {
    padding: 0 20px 0 0;
    margin: 0 0 0 20px;
  }
  &:first-of-type {
    border-top-color: transparent;
  }
  > .name,
  > p {
    flex: 0 0 auto;
    width: 100%;
    align-self: center;
  }
  > .name {
    order: 0;
    display: block;
    margin: 0 0 5px 0;
    line-height: 18px;
    font-size: 14px;
    font-weight: 700;
    color: ${darkBlue};
  }
  > p {
    order: 1;
    margin: 0;
    line-height: 16px;
    > .market {
      display: inline;
      padding: 0;
      margin: 0;
      line-height: 16px;
      font-size: 12px;
      font-weight: 400;
      color: ${darkBlue};
    }
    > .ui.tag.label {
      display: inline-block;
      margin: 0 0 0 6px;
    }
    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
`;
/* TABS TABLE */
export const TableWrap = styled.div`
  > .ui.very.basic.table.locations-tab-table {
    thead tr th {
      height: 39px;
      line-height: 16px;
      padding: 4px 0 0 6.31%;
      @media all and ${device.tablet} {
        padding-left: 4%;
      }
      @media all and ${device.tabletL} {
        padding-left: 6.31%;
      }
    }
    tbody {
      tr {
        td {
          height: 61px;
          border-top: 1px solid ${lighterGray};
          border-bottom: none;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          @media all and ${device.tablet} {
            padding-left: 4%;
          }
          @media all and ${device.tabletL} {
            padding-left: 6.31%;
          }
          > .ui.basic.circular.button.location-view-details {
            margin: 0;
            font-size: 12px;
            line-height: 14px;
            font-weight: 700;
            @media all and ${device.tablet} {
              padding: 4px 7px;
            }
            @media all and ${device.tabletL} {
              padding: 8px 14px;
            }
          }
          &:last-of-type {
            text-align: right;
          }
        }
      }
    }
    &.varColumns2 {
      thead tr th {
        &:nth-child(1) {
          width: 22%;
        }
        &:nth-child(2) {
          width: 25%;
        }
      }
      @media all and ${device.tablet} {
        thead tr th {
          &:nth-child(1) {
            width: 28%;
          }
          &:nth-child(2) {
            width: 30%;
          }
        }
      }
      @media all and ${device.tabletL} {
        thead tr th {
          &:nth-child(1) {
            width: 22%;
          }
          &:nth-child(2) {
            width: 25%;
          }
        }
      }
    }
    &.varColumns3 {
      thead tr th {
        &:nth-child(1) {
          width: 22%;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 14%;
        }
      }
      @media all and ${device.tablet} {
        thead tr th {
          &:nth-child(1) {
            width: 30%;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: 20%;
          }
        }
      }
      @media all and ${device.tabletL} {
        thead tr th {
          &:nth-child(1) {
            width: 22%;
          }
          &:nth-child(2),
          &:nth-child(3) {
            width: 14%;
          }
        }
      }
    }
    &.varColumns4 {
      thead tr th {
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 18%;
        }
        &:nth-child(3) {
          width: 17%;
        }
        &:nth-child(4) {
          width: 20%;
        }
      }
      @media all and ${device.tablet} {
        thead tr th {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            width: 20%;
          }
        }
      }
      @media all and ${device.tabletL} {
        thead tr th {
          &:nth-child(1) {
            width: 20%;
          }
          &:nth-child(2) {
            width: 18%;
          }
          &:nth-child(3) {
            width: 17%;
          }
          &:nth-child(4) {
            width: 20%;
          }
        }
      }
    }
  }
`;
