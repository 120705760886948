import styled from 'styled-components';

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  .ui.checkbox input.hidden {
    z-index: 1;
  }
  .ui.checkbox input[type='checkbox'] {
    top: 4px;
    opacity: unset !important;
  }
`;

export default CheckboxWrapper;
