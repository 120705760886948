import React, { ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@wework/ray2';
import { isEmpty } from 'lodash';
import { IRowNode } from 'ag-grid-community';
import { BottomPanelWrapper } from '../../../../styles/sharedBottomPanel.styled';
import RePublishingConfirm from '../syncAttempts/rePublishingConfirm';

interface BottomPanelRePublishProps {
  selectedNodes: IRowNode[];
  loadingSelector: boolean;
  selectedRePublishAction: string;
  rePublishAction: string;
  syncAttemptName: string;
}

function BottomPanelRePublish({
  selectedNodes,
  loadingSelector,
  selectedRePublishAction,
  rePublishAction,
  syncAttemptName,
}: BottomPanelRePublishProps): ReactElement {
  const isDisabled = isEmpty(selectedNodes);

  const [confirmModalOpen, setConfirmationModalOpen] = useState(false);

  const dispatch = useDispatch();
  const setSelectedNodesStandardRePublish = useCallback(
    (payload: IRowNode[]) => dispatch({ type: selectedRePublishAction, payload }),
    [dispatch, selectedRePublishAction],
  );

  return (
    <BottomPanelWrapper>
      <Button
        theme={'fill'}
        size={'medium'}
        className="btm-panel-btn-space "
        disabled={isDisabled}
        onClick={() => setSelectedNodesStandardRePublish([])}
      >
        Clear
      </Button>
      <Button
        theme={'fill'}
        size={'medium'}
        className={'ml-sm'}
        disabled={isDisabled}
        onClick={() => setConfirmationModalOpen(true)}
      >
        Re-publish
      </Button>
      <RePublishingConfirm
        closeModal={() => setConfirmationModalOpen(false)}
        isOpen={confirmModalOpen}
        selectedNodes={selectedNodes}
        loadingSelector={loadingSelector}
        rePublishAction={rePublishAction}
        syncAttemptName={syncAttemptName}
      />
    </BottomPanelWrapper>
  );
}

export default BottomPanelRePublish;
