import axios, { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Action } from 'redux-actions';
import AppConfig from '../../../../../config';
import { getAuthToken } from '../../../../../index';
import { errorHandlerActive } from '../../../../../utils/errorHandling/helpers';
import {
  FETCH_DISCOUNT_SYNC_ATTEMPTS_SUCCESS,
  FETCH_DISCOUNT_SYNC_ATTEMPTS_WITH_FILTER,
  RE_PUBLISH_DISCOUNT,
  RE_PUBLISH_DISCOUNT_END,
} from './discountSyncAttempts.ducks';
import { DataWithCallback } from '../../../../../utils/sharedTypes';
import { DiscountSyncAttemptFilter } from '../../entities/discountSyncAttempt';
import {
  discountSyncAttemptsFilterSelector,
  discountSyncAttemptsPageInfoSelector,
} from './discountSyncAttempts.selector';
import { PageInfo } from '../../../../../generated/voyager/graphql';

const getAllDiscountSyncAttemptsWithFilterRequest = (
  filter: DiscountSyncAttemptFilter,
  page: PageInfo,
): Promise<AxiosResponse<any>[]> => {
  const getSyncAttemptsUrl = `${AppConfig.BACKEND_IPP_REST_URL}/admin/discount-sync-attempt`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  const params = {
    size: page.size,
    page: page.page,
  };

  return axios
    .post(
      getSyncAttemptsUrl,
      {
        discountUuid: filter?.discountUuid,
        reservableUuid: filter?.reservableUuid,
        status: filter?.status,
        startCreatedAt: filter?.startCreatedAt,
        endCreatedAt: filter?.endCreatedAt,
        startScheduledFor: filter?.startScheduledFor,
        endScheduledFor: filter?.endScheduledFor,
      },
      { headers, params },
    )
    .catch(error => error.response);
};

const publishDiscountSyncAttemptsRequest = (ids: String[]): Promise<AxiosResponse<any>[]> => {
  const getSyncAttemptsUrl = `${AppConfig.BACKEND_IPP_REST_URL}/admin/discount-sync-attempt/re-publish`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };

  return axios.post(getSyncAttemptsUrl, ids, { headers }).catch(error => error.response);
};

export function* fetchDiscountSyncAttemptDataWithFilter() {
  try {
    const filter: DiscountSyncAttemptFilter = yield select(discountSyncAttemptsFilterSelector);
    const pageInfo: PageInfo = yield select(discountSyncAttemptsPageInfoSelector);
    const { status, data }: AxiosResponse = yield call(
      getAllDiscountSyncAttemptsWithFilterRequest,
      filter,
      pageInfo,
    );
    if (status === 200 || status === 404) {
      yield put({ type: FETCH_DISCOUNT_SYNC_ATTEMPTS_SUCCESS, data });
    } else {
      toast.error('Fetching error');
    }
  } catch (e: any) {
    toast.error(`Fetching error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export function* rePublishDiscountSyncAttempt(action: Action<DataWithCallback<string[]>>) {
  try {
    const ids: string[] = action.payload.data;
    const { status, data }: AxiosResponse = yield call(publishDiscountSyncAttemptsRequest, ids);
    if (status === 200) {
      if (data.length === 0) {
        toast.success('Re-publishing successful');
      } else {
        toast.error(`Re-publishing failed for ${data}`);
      }
    } else {
      toast.error(`Re-publishing failed: ${data.message}`);
    }
    yield put({ type: RE_PUBLISH_DISCOUNT_END });
    action.payload.successCallback();
    fetchDiscountSyncAttemptDataWithFilter();
  } catch (e: any) {
    toast.error(`Re-publishing error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export default function* DiscountRePublishSaga(): any {
  yield all([
    takeLatest(FETCH_DISCOUNT_SYNC_ATTEMPTS_WITH_FILTER, fetchDiscountSyncAttemptDataWithFilter),
  ]);
  yield all([takeLatest(RE_PUBLISH_DISCOUNT, rePublishDiscountSyncAttempt)]);
}
