import { OnDemandPricingItemsSubset } from 'app/pricing/onDemandPricing/store/entities/onDemandPricing';
import { UploadFileSubset } from '../../sharedStore/entities/uploadFile';
import { StoreInterface } from './store.interface';
import { RegionsSubset } from '../../sharedStore/entities/regions';
import { LocationsSubset } from '../../sharedStore/entities/locations';
import { MarketsSubset } from '../../sharedStore/entities/markets';
import { TerritoriesSubset } from '../../sharedStore/entities/territories';
import { PriceableItemsSubset } from '../../app/pricing/standardPricing/store/entities/priceableItems';
import { AuthSubset } from '../../sharedStore/entities/auth';
import {
  DiscountCurveStatus,
  DiscountItemsSubset,
} from '../../app/discounts/store/entities/discountItems';
import { DiscountFiltersSubset } from '../../app/discounts/store/entities/filters';
import { DetailPanelSubSet, DisplayableItemSubSet } from '../../sharedStore/entities/detailPanel';
import { PublishPricesSubset } from '../../app/pricing/standardPricing/store/entities/publishPrice';
import { StandardPricingItemsSubset } from '../../app/pricing/standardPricing/store/entities/standardPricingItems';
import { StandardPricingFiltersSubset } from '../../app/pricing/standardPricing/store/entities/standardPricingFilters';
import { BreakevenItemsSubset } from '../../app/breakeven/store/entities/breakevenItems';
import { InventoryReconciliationSubset } from '../../app/adminPanel/store/entities/inventoryReconciliation';
import { InventoryReconciliationByLocationSubset } from '../../app/adminPanel/store/entities/inventoryReconciliationByLocation';
import { LocationReconciliationSubset } from '../../app/adminPanel/store/entities/locationReconciliation';
import { ResetBreakEvenSubset } from '../../app/adminPanel/store/entities/resetBreakEven';
import { SnowflakeSyncInitializationSubset } from '../../app/adminPanel/store/entities/snowflakeSyncInitialization';
import { SpacemanPricesConsolidationSubset } from '../../app/adminPanel/store/entities/spacemanPricesConsolidation';
import {
  defaultFilter,
  defaultPageIngo,
  PriceSyncAttemptSubset,
} from '../../app/adminPanel/store/entities/priceSyncAttempt';
import { GeoHierarchyReconciliationSubset } from '../../app/adminPanel/store/entities/geoHierarchyReconciliation';
import { JobStatusSubset } from '../../app/adminPanel/store/entities/jobStatus';
import { ActionItemsSubset } from '../../app/actionItems/store/entities/actionItems';
import { ActionItemGroup, LocationActionItem } from '../../generated/voyager/graphql';
import { initState } from './store.constants';
import {
  defaultDiscountFilter,
  DiscountSyncAttemptSubset,
} from '../../app/adminPanel/store/entities/discountSyncAttempt';
import { GenerateLinkSubset } from '../../app/adminPanel/store/entities/generateLink';

const initialStateRegionSubset: RegionsSubset = {
  fetchRegionsState: { ...initState },
  allRegions: [],
  currentRegionSelection: {
    id: '',
    name: '',
    __typename: 'Region',
  },
};

const initialStateLocationsSubset: LocationsSubset = {
  fetchLocationsState: { ...initState },
  fetchLocationsByIdsState: { ...initState },
  allLocations: [],
  allMarketLocations: [],
  currentMultipleLocationDetails: [],
  locationsSelected: [],
};

const initialStateMarketsSubset: MarketsSubset = {
  fetchMarketsState: { ...initState },
  allMarkets: [],
};

const initialStateTerritoriesSubset: TerritoriesSubset = {
  fetchTerritoriesState: { ...initState },
  allTerritories: [],
};

const initialStatePriceableItemsSubset: PriceableItemsSubset = {
  priceableItemChangeLog: null,
  featureCardElements: null,
  priceableItemChangeLogState: { ...initState },
  featureCardElementsState: { ...initState },
};

const initialAuthState: AuthSubset = {
  logIn: { ...initState },
  referrer: null,
  token: '',
  user: {},
  unAuthenticated: false,
};

export const initialUploadFileState: UploadFileSubset = {
  isLoading: false,
  loadedFile: null,
  reason: '',
  isARPMPrice: true,
  publishDate: null,
  expirationDate: null,
  fileErrors: [],
  fileFormatError: '',
};

export const initialDiscountItems: DiscountItemsSubset = {
  allDiscountItems: [],
  allPrevDiscountItems: [],
  batchFetchInProgressDiscount: false,
  sidePanelParam: false,
  currentSelectedPriceableItemId: null,
  termTypes: [],
  overrides: [],
  publish: false,
  curves: [],
  curvesLoading: false,
  mutateCurvesLoading: false,
  createCurvesLoading: false,
  updateCurvesLoading: false,
  allDiscountCurves: [],
  batchFetchInProgressAllDiscountCurves: false,
  curveFilters: new Set<DiscountCurveStatus>([
    DiscountCurveStatus.ACTIVE,
    DiscountCurveStatus.INACTIVE,
  ]),
  userOperatorsLoading: false,
  userOperators: [],
  discountHistory: null,
  discountHistoryFetchState: { ...initState },
  cancelFutureDiscountIds: [],
  cancelFutureDiscountLoading: false,
};

const initialDiscountFilters: DiscountFiltersSubset = {
  filters: [],
};

const initialDetailPanelSubSet: DetailPanelSubSet = {
  sidePanelParam: false,
  currentDisplayableItem: {} as DisplayableItemSubSet,
};

export const initialPublishPricesSubset: PublishPricesSubset = {
  workingPrices: [],
  batchFetch: false,
  publish: false,
};

export const initialStandardPricingItems: StandardPricingItemsSubset = {
  allItems: [],
  selectedNodes: [],
  editMode: false,
  batchFetch: false,
  isListPriceMode: false,
  cancelFuturePricesLoading: false,
  revertWorkingPricesLoading: false,
  batchEditLoading: false,
};

const initialStandardPricingFiltersSubset: StandardPricingFiltersSubset = {
  filters: [],
};

export const initialBreakevenItems: BreakevenItemsSubset = {
  allItems: [],
  fetchInProgress: false,
  editMode: false,
  locationIdsToPublish: [],
  publishing: false,
  marketSgAndA: [],
  marketSgAndALoading: false,
};

export const initialInventoryReconciliation: InventoryReconciliationSubset = {
  isLoading: false,
  isInventoryIdsValid: true,
  inventoryIds: [],
};

export const initialInventoryReconciliationByLocation: InventoryReconciliationByLocationSubset = {
  isLoading: false,
  isLocationIdsValid: true,
  locations: [],
  locationIds: [],
};

export const initialLocationReconciliation: LocationReconciliationSubset = {
  isLoading: false,
  isLocationIdsValid: true,
  locations: [],
  locationIds: [],
};

export const initialGeoHierarchyReconciliation: GeoHierarchyReconciliationSubset = {
  fetchState: { ...initState },
};

export const initialResetBreakEven: ResetBreakEvenSubset = {
  resetBreakEvenState: { ...initState },
};

export const initialSnowflakeSyncInitialization: SnowflakeSyncInitializationSubset = {
  priceRecommendationPushJobState: { ...initState },
  liveBudgetPushJobState: { ...initState },
  locationMetricsPushJobState: { ...initState },
  reservableMetricsPushJobState: { ...initState },
  locationSKUOccupancyCalculationState: { ...initState },
  marketSKUOccupancyCalculationState: { ...initState },
};

export const initialSpacemanPricesConsolidationInitialization: SpacemanPricesConsolidationSubset = {
  spacemanPricesConsolidationState: { ...initState },
};

export const initialPriceSyncAttempt: PriceSyncAttemptSubset = {
  isLoading: false,
  allPriceSyncAttempt: [],
  priceSyncAttemptFilter: defaultFilter,
  pageInfo: defaultPageIngo,
  selectedNodes: [],
};

export const initialOnDemandPricingItems: OnDemandPricingItemsSubset = {
  allItems: [],
  itemsToPublish: [],
  productsToPublish: [],
  isLoadingFetchState: false,
  onDemandProducts: [],
  productsLoadingFetchState: false,
  editMode: false,
  publishPrices: initState,
  setPricesLoading: false,
  cancelPricesLoading: false,
  revertPricesLoading: false,
  currentChangeLog: [],
  currentChangeLogFetchState: initState,
  selectedNodes: [],
  priceHistory: [],
  priceHistoryLoadingFetchState: false,
  priceHistoryGeo: null,
  updateGeoHierarchyAttributesLoading: false,
};

export const initialDiscountSyncAttempt: DiscountSyncAttemptSubset = {
  isLoading: false,
  allDiscountSyncAttempt: [],
  discountSyncAttemptFilter: defaultDiscountFilter,
  pageInfo: defaultPageIngo,
  selectedNodes: [],
};

export const initialJobStatus: JobStatusSubset = {
  job: null,
};

export const initialActionItems: ActionItemsSubset = {
  actionItems: new Map<ActionItemGroup, LocationActionItem[]>([
    [ActionItemGroup.LOCATION_NO_BREAKEVEN, []],
    [ActionItemGroup.LOCATION_NO_LIVE_BUDGET, []],
    [ActionItemGroup.PRICEABLE_ITEM_NO_CURRENT_PRICE, []],
    [ActionItemGroup.PRICEABLE_ITEM_NO_DISCOUNT, []],
    [ActionItemGroup.PRICEABLE_ITEM_EXPIRING_OVERRIDE, []],
  ]),

  locationActionItemLoading: new Map<ActionItemGroup, boolean>([
    [ActionItemGroup.LOCATION_NO_BREAKEVEN, false],
    [ActionItemGroup.LOCATION_NO_LIVE_BUDGET, false],
    [ActionItemGroup.PRICEABLE_ITEM_NO_CURRENT_PRICE, false],
    [ActionItemGroup.PRICEABLE_ITEM_NO_DISCOUNT, false],
    [ActionItemGroup.PRICEABLE_ITEM_EXPIRING_OVERRIDE, false],
  ]),
};

export const initialGenerateLink: GenerateLinkSubset = {
  generatedUrl: null,
  generateUrlLoading: false,
  decodeUrlLoading: false,
};

const initialState: StoreInterface = {
  regions: initialStateRegionSubset,
  locations: initialStateLocationsSubset,
  markets: initialStateMarketsSubset,
  territories: initialStateTerritoriesSubset,
  priceableItems: initialStatePriceableItemsSubset,
  auth: initialAuthState,
  discountItems: initialDiscountItems,
  discountFilters: initialDiscountFilters,
  detailPanel: initialDetailPanelSubSet,
  publishPrice: initialPublishPricesSubset,
  standardPricingItems: initialStandardPricingItems,
  standardPricingFilters: initialStandardPricingFiltersSubset,
  breakevenItems: initialBreakevenItems,
  uploadFile: initialUploadFileState,
  inventoryReconciliation: initialInventoryReconciliation,
  inventoryReconciliationByLocation: initialInventoryReconciliationByLocation,
  locationReconciliation: initialLocationReconciliation,
  geoHierarchyReconciliation: initialGeoHierarchyReconciliation,
  resetBreakEven: initialResetBreakEven,
  snowflakeSyncInitialization: initialSnowflakeSyncInitialization,
  spacemanPricesConsolidation: initialSpacemanPricesConsolidationInitialization,
  priceRePublishing: initialPriceSyncAttempt,
  discountRePublishing: initialDiscountSyncAttempt,
  onDemandPricingItems: initialOnDemandPricingItems,
  jobStatus: initialJobStatus,
  actionItems: initialActionItems,
  generateLink: initialGenerateLink,
};

export default initialState;
