import styled from 'styled-components';
import { Button, TextArea } from 'semantic-ui-react';
import { FlexHorizontalHelper } from '../../../../styles/app.styled';
import { darkBlue, gray } from '../../../../styles/colors';

export const AgGridWrapper = styled.div`
  height: 70.25vh;
  width: calc(100vw - 450px);
  min-width: 500px;
  padding-bottom: 10px;
`;
export const TableLayoutPriceAttempts = styled.div`
  margin-top: 10px;
`;
export const DateItems = styled.div`
  justify-content: flex-start;
  margin: 5px;
`;
export const UuidAreaWrapper = styled(TextArea)`
  min-height: 80px;
  min-width: 100px;
  width: 250px;
  resize: none;
  border: 1px solid ${gray};
`;
export const PageTextArea = styled(TextArea)`
  height: 25px;
  width: 40px;
  resize: none;
  margin: 5px;
`;
export const PageControlButton = styled(Button)`
  height: 35px;
  margin: 5px;
`;
export const FilterButton = styled(Button)`
  display: flex;
  justify-content: center;
`;
export const BottomDiv = styled.div`
  display: flex;
  justify-content: center;
`;
export const BottomUuid = styled.div`
  max-width: 200px;
`;
export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 20px;
`;
export const SubTitle = styled.h4`
  padding: 0;
  margin: 10px 8px !important;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${darkBlue};
  display: inline-block;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const ActionsBox = styled.div`
  flex: 1 1 50%;
  max-width: 281px;
  ${FlexHorizontalHelper};

  &.action-box-left {
    justify-content: flex-start;

    span {
      margin-top: 10px;
    }
  }

  > .ui.button {
    margin-left: 10px;
  }

  &.action-box-triple {
    flex: 1 1 25%;
    max-width: 25%;
    justify-content: flex-start;

    &:last-of-type {
      flex: 1 1 50%;
      line-height: 40px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;
export const ActionSubTitle = styled.h4`
  padding: 0;
  margin: 10px 0 !important;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
`;
export const ButtonDate = styled(Button)`
  width: 120px;
  max-width: 100px;
  justify-content: flex-end;
`;
export const RowBox = styled.div`
  flex: 0 0 auto;
`;
export const FilterBox = styled.div`
  max-width: 360px;
`;
export const FilterContainer = styled.div`
  > span {
    margin: 3px 3px 0 0;
    color: #666;
  }
  .ui.checkbox input[type='checkbox'] {
    top: 4px;
  }
`;
export const RowComponent = styled.div`
  display: flex;
  justify-content: flex-start;
  list-style-type: none;
`;
