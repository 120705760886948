import { forOwn, get, isEmpty, isNil, omit } from 'lodash';
import { ValueFormatterParams } from 'ag-grid-community';
import { ToBePublishedObject } from '../../store/entities/breakevenItems';
import {
  roundToAtMostNDigit,
  roundToInteger,
  withSign,
} from '../../../pricing/standardPricing/components/helpers';
import { breakevenRows, notInheritableProps } from '../constants';
import { intlCurrencyFormatter } from '../../../../utils/helpers';
import {
  CurrentLocationBreakEven,
  LocationBreakEvenDetail,
  SgAndAForMarket,
  WorkingBreakEvenOverrideInput,
} from '../../../../generated/voyager/graphql';

export const getBreakevenValue = (
  valueGetter: (fieldName: string) => any,
  sgAndA: number,
): number => {
  const rent = valueGetter('rent');
  const tenancy = valueGetter('tenancyNNN');
  const opex = valueGetter('opex');
  const deskCapacity = valueGetter('deskCapacity');
  const steadyStateOcc = valueGetter('steadyStateOcc');

  const operationalCost = rent + tenancy + opex + sgAndA;
  const occupancy = deskCapacity * steadyStateOcc;

  return occupancy ? operationalCost / occupancy : 0;
};

export const getToBePublishedData = (breakevenItem: any): any => {
  const breakevenDataOverride = breakevenItem.override;
  const breakevenDataLiveBudgetApplied = breakevenItem.liveBudgetApplied;
  const breakevenDataCurrent = breakevenItem.current;

  const toBePublishedData: any = {};
  forOwn(breakevenDataLiveBudgetApplied, (value, key) => {
    if (value != null) {
      const tempObject: ToBePublishedObject = {
        value,
        type: 'liveBudgetApplied',
      };
      toBePublishedData[key] = { ...tempObject };
    }
  });

  forOwn(breakevenDataOverride, (value, key) => {
    if (value != null) {
      const tempObject: ToBePublishedObject = {
        value,
        type: 'override',
      };
      toBePublishedData[key] = { ...tempObject };
    }
  });

  if (!isEmpty(toBePublishedData)) {
    forOwn(breakevenDataCurrent, (value, key) => {
      if (!toBePublishedData.hasOwnProperty(key) && !notInheritableProps.includes(key)) {
        const tempObject: ToBePublishedObject = {
          value,
          type: 'noChange',
        };
        toBePublishedData[key] = { ...tempObject };
      }
    });
  }

  return toBePublishedData;
};

const transformBreakevenItemToTableView = (
  breakevenItem: any,
  row_param: string,
  marketSgAndAs: SgAndAForMarket[],
): any => {
  const row = row_param === 'published' ? 'current' : row_param;
  // Need to combine BREAK EVEN OVERRIDE and LIVE BUDGET APPLIED
  if (row === 'toBePublished') {
    const toBePublishedData: any = getToBePublishedData(breakevenItem);

    const marketSgAndA = marketSgAndAs.find(
      mSgAndA => mSgAndA.marketId === breakevenItem.location.market.id,
    )?.sgAndA;
    const sgAndA = toBePublishedData.sgAndA ?? {
      value: marketSgAndA && marketSgAndA * toBePublishedData.rsf?.value,
    };
    const breakEvenPrice = {
      value:
        sgAndA.value &&
        getBreakevenValue(fieldName => toBePublishedData[fieldName]?.value, sgAndA.value),
    };

    toBePublishedData.sgAndA = { ...sgAndA, value: sgAndA.value && roundToInteger(sgAndA.value) };
    toBePublishedData.breakEvenPrice = {
      ...breakEvenPrice,
      value: breakEvenPrice.value && roundToInteger(breakEvenPrice.value),
      publishedValue: breakevenItem.current?.breakEvenPrice,
    };

    return {
      location: breakevenItem?.location,
      type: 'toBePublished',
      ...toBePublishedData,
    };
  }

  // Current we need to display override label for overrides in the grid
  if (row === 'current') {
    const breakevenDataCurrent: CurrentLocationBreakEven =
      breakevenItem.current ?? ({} as CurrentLocationBreakEven);
    const breakevenDataCurrentOverride: WorkingBreakEvenOverrideInput =
      breakevenDataCurrent?.override ?? ({} as WorkingBreakEvenOverrideInput);
    const breakevenDataCurrentProperties = omit(breakevenDataCurrent, [
      '__typename',
      'validFrom',
      'override',
    ]);

    const current: any = {};

    forOwn(breakevenDataCurrentProperties, (value, key) => {
      const overrideValue = get(breakevenDataCurrentOverride, key, null);
      if (value !== null) {
        if (overrideValue === value) {
          const tempObject: ToBePublishedObject = {
            value,
            type: 'override',
          };
          current[key] = { ...tempObject };
        } else {
          current[key] = value;
        }
      }
    });

    return {
      location: breakevenItem?.location,
      type: 'current',
      ...current,
    };
  }

  return {
    location: breakevenItem.location,
    type: row,
    ...breakevenItem[row],
  };
};

export const transformBreakevenItemsToTableView = (
  breakevenItems: LocationBreakEvenDetail[],
  marketSgAndAs: SgAndAForMarket[],
): any[] => {
  const items: any[] = [];

  breakevenItems.forEach(breakevenItem =>
    breakevenRows.forEach(row =>
      items.push(transformBreakevenItemToTableView(breakevenItem, row, marketSgAndAs)),
    ),
  );

  return items;
};

export const toPercentageVFBreakeven = (params: ValueFormatterParams): string => {
  if (!params.data) {
    return '';
  }
  const val: number = params.value;
  if (val != null && !isNaN(val)) {
    const percent = val * 100;
    return `${percent.toFixed(0)}%`;
  }
  return '';
};

export const getCurrencyFormattedPrice = (params: ValueFormatterParams): string =>
  params.value
    ? intlCurrencyFormatter(params.data.location.currencyIsoCode, Number(params.value))
    : '-';

export const removeTypeName = (object: any): any => {
  const { __typename, ...restObject } = object ?? {};
  return restObject;
};

export const convertToPercent = (num: number): number => Math.round(num * 10000) / 100;

export const getDifference = (
  breakEvenPrice?: number | null,
  currentBreakEvenPrice?: number | null,
): number | undefined => {
  if (isNil(breakEvenPrice) || isNil(currentBreakEvenPrice)) {
    return undefined;
  }

  return breakEvenPrice - currentBreakEvenPrice;
};

export const getAdjustedBy = (
  currencyIsoCode: string,
  difference?: number | null,
  currentBreakEvenPrice?: number | null,
): string => {
  if (isNil(difference) || isNil(currentBreakEvenPrice)) {
    return '-';
  }

  const percentDifference =
    (difference / (currentBreakEvenPrice === 0 ? difference : currentBreakEvenPrice)) * 100;
  return `${intlCurrencyFormatter(currencyIsoCode, difference)}(${withSign(
    roundToAtMostNDigit(percentDifference, 1),
  )}%)`;
};

export const getAdjustedByClassName = (difference?: number | null): string | undefined => {
  if (isNil(difference)) {
    return undefined;
  }

  if (difference > 0) {
    return 'green';
  } else if (difference < 0) {
    return 'red';
  }
  return undefined;
};

export const getBooleanValue = (value: any): string => {
  if (value === true) {
    return 'Yes';
  } else if (value === false) {
    return 'No';
  }
  return '-';
};

export const checkIfOverrideExist = (allItems: LocationBreakEvenDetail[], locationId: string) => {
  const item = allItems?.find(previousItem => previousItem.location.id === locationId);
  return item && (!isEmpty(item?.override) || !isEmpty(item?.liveBudgetApplied));
};
