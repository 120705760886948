import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { push } from 'connected-react-router';
import { MainRegionStyled, SummaryWrapper } from '../../../../styles/app.styled';
import PricingPublish from '../components/pricingPublish/pricingPublish';
import { CLEAR_PRICE_PUBLISH } from '../store/modules/publishPrice/publishPrice.ducks';
import BreadcrumbComponent from '../../../../sharedComponents/breadcrumb/breadcrumbComponent';
import BottomPanelPricingPublish from '../components/bottomPanel/bottomPanelPricingPublish';
import ROUTES from '../../../../utils/routes/routes';
import { AuthzProps } from '../../../../utils/constants';
import EmployeePermissions from '../../../../utils/store/permissions';
import transformPublishPricesItemToTableViewArr from '../components/pricingPublish/pricingPublish.transformer';
import {
  publishPricesBatchFetchSelectorStore,
  publishPricesSelectorStore,
} from '../store/modules/publishPrice/publishPrice.selector';
import { PublishPriceTableItem } from '../store/entities/publishPrice';

function PricingPublishHome({ requestedPermissions }: AuthzProps): ReactElement {
  const [transformedWorkingPrice, setTransformedWorkingPrice] = useState<
    Map<string, PublishPriceTableItem[]>
  >(new Map());

  // DISPATCH PROPS
  const dispatch = useDispatch();
  const allWorkingPrices = useSelector(publishPricesSelectorStore);
  const batchFetch = useSelector(publishPricesBatchFetchSelectorStore);

  const clearAll = useCallback(() => {
    dispatch({ type: CLEAR_PRICE_PUBLISH });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );
  // transform the data to the table
  const transformDataHelper = () => {
    if (!batchFetch && allWorkingPrices) {
      setTransformedWorkingPrice(transformPublishPricesItemToTableViewArr(allWorkingPrices));
    }
  };
  useEffect(transformDataHelper, [batchFetch, allWorkingPrices]);
  useEffect(() => {
    if (
      !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_publish, false)
    ) {
      redirectToAccessDenied();
    }

    return () => {
      clearAll();
    };
  }, [clearAll, redirectToAccessDenied, requestedPermissions]);

  return (
    <>
      <MainRegionStyled>
        <BreadcrumbComponent />
        <SummaryWrapper>
          <PricingPublish transformedWorkingPrice={transformedWorkingPrice} />
        </SummaryWrapper>
      </MainRegionStyled>
      <BottomPanelPricingPublish transformedWorkingPrice={transformedWorkingPrice} />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_price_publish],
})(PricingPublishHome);
