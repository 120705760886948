import styled from 'styled-components';
import { FlexHorizontalHelper, FlexVerticalHelper } from '../../../../styles/app.styled';
import { blue, darkBlue, gray, green, lightGray, red } from '../../../../styles/colors';

export const LocationTitleStyledBreakeven = styled.div`
  padding: 0;
  > .name {
    font-size: 12px;
    color: ${darkBlue};
  }
  .double {
    ${FlexHorizontalHelper}
    > .second {
      margin-left: 25px;
      margin-top: 18px;
      ${FlexVerticalHelper}
      *:first-child {
        max-height: 40%;
      }
    }
  }
  .override {
    color: ${blue};
  }
  .dropdown {
    color: #07c;
    > i.dropdown.icon {
      margin-left: 5px;
    }
  }
  .info {
    height: 10px;
    font-size: 11px;
    font-style: italic;
    color: ${lightGray};
  }
`;
export const EditableCellStyled = styled.div`
  margin: 0 0 14px;
  line-height: normal;
  height: 100%;
  > span {
    padding-top: 7px;
    &:first-of-type {
      padding-top: 16px;
    }
    flex: 0 0 auto;
    display: block;
  }
  > span.info {
    font-size: 11px;
    color: ${lightGray};
    margin-bottom: 5px;
  }
  > span.reason {
    overflow: auto;
    height: 100%;
    padding-bottom: 14px;
  }
  > span.red {
    color: ${red};
  }
  > span.green {
    color: ${green};
  }
  > span.override {
    color: ${blue};
  }
`;
export const TextLink = styled.span`
  line-height: 12px;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  color: #07c;
  text-decoration: underline;
  margin-bottom: 8px;
  margin-top: 2px;
  &.margin-left-space {
    margin-left: 5px;
  }
  &.modified-size {
    line-height: 32px;
    font-size: 12px;
  }
  &.margin-left {
    float: right;
    margin-left: 25px;
  }
  &.hide {
    display: none;
  }
`;
export const LiveBudgetLastUpdated = styled.span`
  color: ${gray};
  margin-left: 4px;
`;
