import styled from 'styled-components';
import { lighterGray, lightestGray } from '../../styles/colors';
import { FlexHorizontalHelper } from '../../styles/app.styled';

export const UploadFileStyled = styled.div`
  margin: 0 3.34vw;
  padding: 6.625vh 0 0;
  p,
  button {
    font-family: Apercu, Helvetica Neue, Arial, Helvetica, sans-serif;
  }
  div {
    &.upload-file-root {
      display: flow-root;
    }
    > .upload-file-float-left {
      float: left;
      margin-top: 10px;
    }
    > .upload-file-float-right {
      float: right;
      .ui.basic.active.button {
        margin-left: 10px;
      }
    }
  }
  .ui.grid > .row {
    justify-content: center;
    &.fixed-height {
      height: 80px;
    }
    > .column {
      ${FlexHorizontalHelper};
      justify-content: space-between;
      @media (max-width: 767px) {
        min-height: 0;
      }
      &.upload-file-form-input {
        width: 510px;
      }
      > .ui.input > input {
        margin-top: 5px;
        min-height: 45px;
      }
      > .ui.buttons > button {
        margin-top: 5px;
      }
    }
    > .column.grid-column {
      @media (max-width: 767px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }
`;

export const FileDropzone = styled.div`
  height: 50vh;
  min-width: 120px;
  width: 100%;
  border: 1px solid ${lighterGray};
  background: ${lightestGray};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    padding: 0.5rem 0.75rem;
    text-align: center;
  }
  &.hidden-dropzone {
    display: none;
  }
`;

export const FormTitle = styled.p`
  font-weight: bold;
  margin: 16px 16px 0 0 !important;
`;

export const FileDescription = styled.div`
  display: flex;
  padding: 0.5rem 4px 0.5rem 0.75rem;
  img {
    width: 16px;
  }
`;

export const AgGridWrapper = styled.div`
  height: 100vh;
  min-height: 300px;
  max-height: calc(100vh - 292px);
  padding-bottom: 14px;
  margin-top: 10px;
  @media (max-width: 768px) {
    max-height: calc(100vh - 600px);
  }
`;
