import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pluralize } from 'apollo/lib/utils';
import { Confirm, Checkbox } from 'semantic-ui-react';
import { ConfirmProps } from '../../../../../../sharedStore/entities/confirmProps';
import {
  ON_DEMAND_WORKING_PRICE_REVERT,
  RESET_SELECTED_NODES_ON_DEMAND,
} from '../../../store/modules/onDemand/onDemandPricing.ducks';
import {
  onDemandPricingProductsSelector,
  onDemandRevertPricesLoadingSelector,
  onDemandSelectedNodesSelector,
} from '../../../store/modules/onDemand/onDemandPricing.selector';
import { workingPriceOnDemandRevertAll } from '../../onDemandPricing.helpers';
import { DataWithCallback } from '../../../../../../utils/sharedTypes';
import { trackAnalytics } from '../../../../../../utils/analytics/helpers';
import { MutationRevertWorkingPriceHierarchyArgs } from '../../../../../../generated/voyager/graphql';

function OnDemandRevertWorkingPricesConfirm(props: ConfirmProps): ReactElement {
  const [productIdsToRevert, setProductIdsToRevert] = useState<string[]>([]);

  const dispatch = useDispatch();
  const revertWorkingPrices = useCallback(
    (payload: DataWithCallback<MutationRevertWorkingPriceHierarchyArgs>) =>
      dispatch({
        type: ON_DEMAND_WORKING_PRICE_REVERT,
        payload,
      }),
    [dispatch],
  );
  const resetSelectedNodes = useCallback(
    () =>
      dispatch({
        type: RESET_SELECTED_NODES_ON_DEMAND,
      }),
    [dispatch],
  );

  const selectedNodes = useSelector(onDemandSelectedNodesSelector);
  const revertLoading = useSelector(onDemandRevertPricesLoadingSelector);
  const products = useSelector(onDemandPricingProductsSelector);

  const resetSelectedProductIdsToRevert = () => {
    setProductIdsToRevert(products.map(product => product.id));
  };

  const closeRevertConfirmationModal = () => {
    props.closeModal();
    resetSelectedNodes();
    resetSelectedProductIdsToRevert();
  };

  useEffect(resetSelectedProductIdsToRevert, [setProductIdsToRevert, products]);

  const confirmRevertPrices = () => {
    trackAnalytics('On Demand - Revert Prices Button Click', {
      workflow: 'On Demand - Revert Prices',
      object_type: 'button',
      object_name: 'Revert Prices',
      object_value: productIdsToRevert,
    });
    revertWorkingPrices({
      data: {
        priceHierarchyIds: workingPriceOnDemandRevertAll(
          selectedNodes.map(node => node.data),
          productIdsToRevert,
        ),
      },
      successCallback: closeRevertConfirmationModal,
    });
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={closeRevertConfirmationModal}
      onConfirm={confirmRevertPrices}
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
        disabled: revertLoading,
      }}
      closeIcon
      confirmButton={{
        content: `Revert Prices for ${pluralize(
          selectedNodes.filter(node => node.data.workingPrices.length > 0).length,
          'item',
        )}`,
        disabled: !workingPriceOnDemandRevertAll(
          selectedNodes.map(node => node.data),
          productIdsToRevert,
        ).length,
        loading: revertLoading,
      }}
      header="Revert Prices"
      content={
        <div>
          <p>
            For <b>all</b> selected geo groupings, working prices will be reverted.
          </p>
          <p>Are you sure you want to revert working prices?</p>
          {products.map(product => (
            <Checkbox
              key={product.id}
              label={`Revert working ${product.name} prices`}
              checked={productIdsToRevert.includes(product.id)}
              onChange={(_, data) =>
                setProductIdsToRevert(
                  data.checked
                    ? [...productIdsToRevert, product.id]
                    : productIdsToRevert.filter(id => id !== product.id),
                )
              }
            />
          ))}
        </div>
      }
    />
  );
}

export default OnDemandRevertWorkingPricesConfirm;
