import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { find, groupBy, isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import {
  EachLocationSummary,
  LocationsChangesSummaries,
  SummaryNote,
  SummarySubTitle,
  SummaryTitle,
  SummaryWrapper,
  TitleBar,
  WorkingOverlay,
} from '../../../pricing/standardPricing/components/pricingPublish/pricingPublish.styled';
import DiscountSummaryTable from './discountPublishTable';
import {
  discountItemsOverrides,
  publishDiscountsPublishSaveSelectorStore,
} from '../../store/modules/discountItems/discountItems.selector';
import {
  currentMultipleLocationDetailsSelector,
  getAllSelectedLocations,
} from '../../../../sharedStore/modules/locations/locations.selector';
import CustomTermsInfo from '../discountCustomTermsInfo';
import { usePrevious } from '../../../pricing/standardPricing/components/helpers';
import { Override } from '../../store/entities/discountItems';
import { routerHistory } from '../../../../utils/store/configureStore';
import { Location } from '../../../../generated/voyager/graphql';

function DiscountPublish(): ReactElement {
  // LOCAL VARIABLE
  const [publishing, setPublishing] = useState<boolean>(false);

  // STATE PROPS
  const [transformedDiscountOverride, setTransformedDiscountOverride] =
    useState<{ location: string; items: Override[] }[]>();
  const selectedLocations = useSelector(getAllSelectedLocations);
  const publish = useSelector(publishDiscountsPublishSaveSelectorStore);
  const prevPublish = usePrevious(publish);
  const discountOverrides = useSelector(discountItemsOverrides);
  const locationDetails = useSelector(currentMultipleLocationDetailsSelector);

  /**
   * display publishing message
   */
  useEffect(() => {
    if (prevPublish === true && !publish) {
      setPublishing(true);
      routerHistory.goBack();
    }
  }, [publish, prevPublish]);

  // transform the data to the table
  const transformDataHelper = () => {
    if (discountOverrides) {
      const result = groupBy(discountOverrides, 'location.id');
      const groupByLocation = Object.keys(result).map(key => ({
        location: key,
        items: result[key],
      }));
      setTransformedDiscountOverride([...groupByLocation]);
    }
  };
  useEffect(transformDataHelper, [discountOverrides]);
  /**
   * Number of changes for first subtitle.
   */
  const numberOfChanges = () => {
    if (!isEmpty(discountOverrides)) {
      return (
        <SummarySubTitle className="no-margin-right">
          {discountOverrides?.length && discountOverrides.length > 1
            ? `${discountOverrides.length} changes will be published in `
            : '1 change will be published in '}
          {selectedLocations?.length && selectedLocations.length > 1
            ? `${selectedLocations.length} locations`
            : '1 location'}
          :
        </SummarySubTitle>
      );
    }
    return <></>;
  };

  const getLocationName = (location: string) => {
    const locDetails: Location = find(locationDetails, ['id', location]) ?? ({} as Location);
    return `${locDetails.name}
    ${locDetails.market?.name}, ${locDetails.market?.territory?.name},
    ${locDetails.market?.territory?.region?.name}`;
  };

  return (
    <div>
      <SummaryTitle>Review Changes</SummaryTitle>
      <SummaryWrapper className="data-wrapper">
        {!isEmpty(discountOverrides) && (
          <>
            <TitleBar>
              {numberOfChanges()}
              <SummaryNote>
                Note: <b>Overrides</b> are highlighted in blue
              </SummaryNote>
              <CustomTermsInfo tooltipPosition={'bottom right'} />
            </TitleBar>
            <LocationsChangesSummaries>
              {transformedDiscountOverride &&
                [...transformedDiscountOverride].map(eachDiscountOverride => (
                  <EachLocationSummary key={uuidV4()} className="each-location">
                    <SummarySubTitle>
                      {getLocationName(eachDiscountOverride.location)}
                    </SummarySubTitle>
                    <Table basic="very" className="changes-summary-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>SKU / Reservable</Table.HeaderCell>
                          <Table.HeaderCell>M2M</Table.HeaderCell>
                          <Table.HeaderCell>6M</Table.HeaderCell>
                          <Table.HeaderCell>12M</Table.HeaderCell>
                          <Table.HeaderCell>24M</Table.HeaderCell>
                          <Table.HeaderCell>36M</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <DiscountSummaryTable discountOverrides={eachDiscountOverride.items} />
                      </Table.Body>
                    </Table>
                  </EachLocationSummary>
                ))}
            </LocationsChangesSummaries>
          </>
        )}
        {isEmpty(discountOverrides) && !publishing && (
          <SummarySubTitle>No summary to Display</SummarySubTitle>
        )}
        {publish && (
          <WorkingOverlay>
            <SummarySubTitle>Working...</SummarySubTitle>
          </WorkingOverlay>
        )}
        {publishing && isEmpty(discountOverrides) && (
          <SummarySubTitle className="success">Successfully published discounts.</SummarySubTitle>
        )}
      </SummaryWrapper>
    </div>
  );
}

export default DiscountPublish;
