import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { Button } from '@wework/ray2';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { ActionsBox, BottomPanelWrapper } from '../../../../styles/sharedBottomPanel.styled';
import {
  breakevenItemsToPublishSelector,
  breakevenPricesPublishing,
} from '../../store/modules/breakevenItems/breakevenItems.selector';
import { usePrevious } from '../../../pricing/standardPricing/components/helpers';
import { routerHistory } from '../../../../utils/store/configureStore';
import EmployeePermissions from '../../../../utils/store/permissions';
import { AuthzProps } from '../../../../utils/constants';
import PublishBreakevenConfirm from './confirmationPopup/publishBreakevenConfirm';

function BottomPanelBreakevenPublish({ requestedPermissions }: AuthzProps): ReactElement {
  const [publishConfirmationModalOpen, setPublishConfirmationModalOpen] = useState(false);

  const itemsToPublish = useSelector(breakevenItemsToPublishSelector) ?? [];
  const publishing = useSelector(breakevenPricesPublishing);
  const prevPublishing = usePrevious(publishing);

  useEffect(() => {
    if (prevPublishing === true && !publishing) {
      routerHistory.goBack();
    }
  }, [publishing, prevPublishing]);

  return (
    <BottomPanelWrapper className="publish-panel">
      <ActionsBox />
      <ActionsBox className="action-box-right">
        <span>{'Prices will publish immediately.'}</span>
        <Button
          loading={publishing}
          theme={'fill'}
          size={'medium'}
          className={'ml-sm'}
          onClick={() => setPublishConfirmationModalOpen(true)}
          disabled={
            !itemsToPublish.length ||
            !get(
              requestedPermissions,
              EmployeePermissions.voyager_dedicated_space_breakeven_publish,
              false,
            )
          }
        >
          Publish
        </Button>
      </ActionsBox>
      <PublishBreakevenConfirm
        closeModal={() => setPublishConfirmationModalOpen(false)}
        isOpen={publishConfirmationModalOpen}
      />
    </BottomPanelWrapper>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_breakeven_publish],
})(BottomPanelBreakevenPublish);
