import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, DatePicker, Icon, Popup, TextAreaProps } from '@wework/dieter-ui';
import cn from 'classnames';
import { addDays, format } from 'date-fns';
import {
  TableFilter,
  TableFilterColumnsWrap,
} from '../../../../styles/sharedTableFilterBar.styled';
import {
  ActionsBox,
  ActionSubTitle,
  BottomDiv,
  BottomUuid,
  ButtonDate,
  DateItems,
  FilterButton,
  FilterContainer,
  IconWrapper,
  SubTitle,
  UuidAreaWrapper,
} from './syncAttempts.styled';
import { PriceSyncAttemptFilter } from '../../store/entities/priceSyncAttempt';
import Trash from '../../../../assets/trash.svg';
import Calendar from '../../../../assets/calendar.svg';
import UuidValidation from '../../../../sharedComponents/uuidValidation/uuidValidation';
import { DiscountSyncAttemptFilter } from '../../store/entities/discountSyncAttempt';

interface FilterBar {
  syncAttemptName: string;
  setFilterAction: string;
}

function TableFilterBarAttempts({ syncAttemptName, setFilterAction }: FilterBar): ReactElement {
  const dispatch = useDispatch();

  const [createdFromDatePickerOpen, setCreatedFromDatePickerOpen] = useState<boolean>(false);
  const [createdFromDate, setCreatedFromDate] = useState<Date | undefined>(undefined);
  const [createdToDatePickerOpen, setCreatedToDatePickerOpen] = useState<boolean>(false);
  const [createdToDate, setCreatedToDate] = useState<Date | undefined>(undefined);
  const [scheduledFromDatePickerOpen, setScheduledFromDatePickerOpen] = useState<boolean>(false);
  const [scheduledFromDate, setScheduledFromDate] = useState<Date | undefined>(undefined);
  const [scheduledToDatePickerOpen, setScheduledToDatePickerOpen] = useState<boolean>(false);
  const [scheduledToDate, setScheduledToDate] = useState<Date | undefined>(undefined);

  const [isReceivedStatusFlag, setReceivedStatusFlag] = useState<boolean>(true);
  const [isErrorStatusFlag, setErrorStatusFlag] = useState<boolean>(true);
  const [isCompleteStatusFlag, setCompleteStatusFlag] = useState<boolean>(true);
  const [isCanceledStatusFlag, setCanceledStatusFlag] = useState<boolean>(true);

  const [uuids, setUuids] = useState<string[]>([]);
  const [reservableUuids, setReservableUuids] = useState<string[]>([]);
  const [isValidUuids, setValidUuids] = useState<boolean>(true);

  const uuidType = syncAttemptName.toLowerCase().concat('Uuid');

  const getCreatedFromDatePickerTrigger = (): ReactElement => (
    <div>
      <ButtonDate
        basic
        className={cn({ active: !!createdFromDate }, 'date-picker-trigger')}
        active={!!createdFromDate}
        onClick={() => setCreatedFromDatePickerOpen(prevState => !prevState)}
      >
        {createdFromDate ? (
          <div>{format(createdFromDate, 'MMM d, yyyy')}</div>
        ) : (
          <IconWrapper>
            <Icon as="img" src={Calendar} size="small" /> from
          </IconWrapper>
        )}
      </ButtonDate>
      {createdFromDate ? (
        <Icon
          as="img"
          src={Trash}
          size="small"
          onClick={() => {
            setCreatedFromDate(undefined);
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
  const getCreatedToDatePickerTrigger = (): ReactElement => (
    <div>
      <ButtonDate
        basic
        className={cn({ active: !!createdToDate }, 'date-picker-trigger')}
        active={!!createdToDate}
        onClick={() => setCreatedToDatePickerOpen(prevState => !prevState)}
      >
        {createdToDate ? (
          <div>{format(createdToDate, 'MMM d, yyyy')}</div>
        ) : (
          <IconWrapper>
            <Icon as="img" src={Calendar} size="small" />
            to
          </IconWrapper>
        )}
      </ButtonDate>
      {createdToDate ? (
        <Icon
          as="img"
          src={Trash}
          size="small"
          onClick={() => {
            setCreatedToDate(undefined);
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
  const getScheduledFromDatePickerTrigger = (): ReactElement => (
    <div>
      <ButtonDate
        basic
        className={cn({ active: !!scheduledFromDate }, 'date-picker-trigger')}
        active={!!scheduledFromDate}
        onClick={() => setScheduledFromDatePickerOpen(prevState => !prevState)}
      >
        {scheduledFromDate ? (
          format(scheduledFromDate, 'MMM d, yyyy')
        ) : (
          <IconWrapper>
            <Icon as="img" src={Calendar} size="small" />
            from
          </IconWrapper>
        )}
      </ButtonDate>
      {scheduledFromDate ? (
        <Icon
          as="img"
          src={Trash}
          size="small"
          onClick={() => {
            setScheduledFromDate(undefined);
          }}
        />
      ) : (
        ''
      )}
    </div>
  );
  const getScheduledToDatePickerTrigger = (): ReactElement => (
    <div>
      <ButtonDate
        basic
        className={cn({ active: !!scheduledToDate }, 'date-picker-trigger')}
        active={!!scheduledToDate}
        onClick={() => setScheduledToDatePickerOpen(prevState => !prevState)}
      >
        {scheduledToDate ? (
          format(scheduledToDate, 'MMM d, yyyy')
        ) : (
          <IconWrapper>
            <Icon as="img" src={Calendar} size="small" />
            to
          </IconWrapper>
        )}
        <i />
      </ButtonDate>
      {scheduledToDate ? (
        <Icon
          as="img"
          src={Trash}
          size="small"
          onClick={() => {
            setScheduledToDate(undefined);
          }}
        />
      ) : (
        ''
      )}
    </div>
  );

  const filterApply = () => {
    const startCreatedAt = createdFromDate ? createdFromDate.toISOString() : null;
    const endCreatedAt = createdToDate ? createdToDate.toISOString() : null;
    const startScheduledFor = scheduledFromDate ? scheduledFromDate.toISOString() : null;
    const endScheduledFor = scheduledToDate ? scheduledToDate.toISOString() : null;
    const statuses: string[] = [];
    if (isCompleteStatusFlag) statuses.push('COMPLETE');
    if (isCanceledStatusFlag) statuses.push('CANCELED');
    if (isErrorStatusFlag) statuses.push('ERROR');
    if (isReceivedStatusFlag) statuses.push('RECEIVED');
    const uuid = uuids.length > 0 && !uuids.includes('') ? uuids : null;
    const reservableUuid =
      reservableUuids.length > 0 && !reservableUuids.includes('') ? reservableUuids : null;

    const filterResult: any = {
      startCreatedAt,
      endCreatedAt,
      startScheduledFor,
      endScheduledFor,
      status: statuses,
      [uuidType]: uuid,
      reservableUuid,
    };

    if (isValidUuids) {
      changeFilter(filterResult);
    }
  };

  const changeFilter = useCallback(
    (filter: PriceSyncAttemptFilter | DiscountSyncAttemptFilter) => {
      dispatch({
        type: setFilterAction,
        payload: filter,
      });
    },
    [dispatch, setFilterAction],
  );

  const splitUuids = (value: string | number | undefined): string[] =>
    value
      ?.toString()
      .split('\n')
      .map(s => s.trim()) || [];

  return (
    <div>
      <TableFilterColumnsWrap>
        <FilterContainer>
          <TableFilter>
            <div>
              <DateItems>
                <SubTitle>Created:</SubTitle>
                <ActionsBox className="action-box-left">
                  <Popup
                    basic
                    position="top left"
                    trigger={getCreatedFromDatePickerTrigger()}
                    on="click"
                    className="publish-data-popup"
                    open={createdFromDatePickerOpen}
                    onClose={() => setCreatedFromDatePickerOpen(prevState => !prevState)}
                    closeOnDocumentClick
                    closeOnEscape
                  >
                    <Popup.Content>
                      <DatePicker
                        selectedDays={createdFromDate ?? undefined}
                        onDayClick={day => {
                          setCreatedFromDate(day);
                          setCreatedFromDatePickerOpen(false);
                        }}
                        disabledDays={createdToDate ? { after: addDays(createdToDate, -1) } : []}
                      />
                    </Popup.Content>
                  </Popup>
                  <Popup
                    basic
                    position="top left"
                    trigger={getCreatedToDatePickerTrigger()}
                    on="click"
                    className="publish-data-popup"
                    open={createdToDatePickerOpen}
                    onClose={() => setCreatedToDatePickerOpen(prevState => !prevState)}
                    closeOnDocumentClick
                    closeOnEscape
                  >
                    <Popup.Content>
                      <DatePicker
                        selectedDays={createdToDate ?? undefined}
                        onDayClick={day => {
                          setCreatedToDate(day);
                          setCreatedToDatePickerOpen(false);
                        }}
                        disabledDays={
                          createdFromDate ? { before: addDays(createdFromDate, 1) } : []
                        }
                      />
                    </Popup.Content>
                  </Popup>
                </ActionsBox>
              </DateItems>
            </div>
          </TableFilter>
          <TableFilter>
            <div>
              <DateItems>
                <ActionSubTitle>Scheduled from:</ActionSubTitle>
                <ActionsBox className="action-box-left">
                  <Popup
                    basic
                    position="top left"
                    trigger={getScheduledFromDatePickerTrigger()}
                    on="click"
                    className="publish-data-popup"
                    open={scheduledFromDatePickerOpen}
                    onClose={() => setScheduledFromDatePickerOpen(prevState => !prevState)}
                    closeOnDocumentClick
                    closeOnEscape
                  >
                    <Popup.Content>
                      <DatePicker
                        selectedDays={scheduledFromDate ?? undefined}
                        onDayClick={day => {
                          setScheduledFromDate(day);
                          setScheduledToDatePickerOpen(false);
                        }}
                        disabledDays={
                          scheduledToDate ? { after: addDays(scheduledToDate, -1) } : []
                        }
                      />
                    </Popup.Content>
                  </Popup>
                  <Popup
                    basic
                    position="top right"
                    trigger={getScheduledToDatePickerTrigger()}
                    on="click"
                    className="publish-data-popup"
                    open={scheduledToDatePickerOpen}
                    onClose={() => setScheduledToDatePickerOpen(prevState => !prevState)}
                    closeOnDocumentClick
                    closeOnEscape
                  >
                    <Popup.Content>
                      <DatePicker
                        selectedDays={scheduledToDate ?? undefined}
                        onDayClick={day => {
                          setScheduledToDate(day);
                          setScheduledToDatePickerOpen(false);
                        }}
                        disabledDays={
                          scheduledFromDate ? { before: addDays(scheduledFromDate, 1) } : []
                        }
                      />
                    </Popup.Content>
                  </Popup>
                </ActionsBox>
              </DateItems>
            </div>
          </TableFilter>
          <span />
          <TableFilter>
            <div>Push status:</div>
            <div>
              <Checkbox
                checked={isReceivedStatusFlag}
                onChange={() => setReceivedStatusFlag(!isReceivedStatusFlag)}
                label="Received"
              />
            </div>
            <div>
              <Checkbox
                checked={isCanceledStatusFlag}
                onChange={() => setCanceledStatusFlag(!isCanceledStatusFlag)}
                label="Canceled"
              />
            </div>
            <div>
              <Checkbox
                checked={isErrorStatusFlag}
                onChange={() => setErrorStatusFlag(!isErrorStatusFlag)}
                label="Error"
              />
            </div>
            <div>
              <Checkbox
                checked={isCompleteStatusFlag}
                onChange={() => setCompleteStatusFlag(!isCompleteStatusFlag)}
                label="Completed"
              />
            </div>
          </TableFilter>
          <span />

          <TableFilter>
            <div> {syncAttemptName.concat(' UUID')}</div>
            <UuidAreaWrapper
              onChange={(_event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                const ids: string[] = splitUuids(data.value);
                setUuids(ids);
              }}
              value={uuids.join('\n')}
              disabled={false}
            />
          </TableFilter>
          <TableFilter>
            <div> Reservable UUID</div>
            <UuidAreaWrapper
              onChange={(_event: React.ChangeEvent<HTMLTextAreaElement>, data: TextAreaProps) => {
                const ids: string[] = splitUuids(data.value);
                setReservableUuids(ids);
              }}
              value={reservableUuids.join('\n')}
              disabled={false}
            />
          </TableFilter>
          <BottomDiv>
            <FilterButton
              className="primary"
              content={'Apply filter'}
              onClick={() => {
                filterApply();
              }}
            />
          </BottomDiv>
        </FilterContainer>
      </TableFilterColumnsWrap>
      <BottomUuid>
        <UuidValidation
          ids={reservableUuids.concat(uuids)}
          handleValidation={(value: boolean) => setValidUuids(value)}
        />
      </BottomUuid>
    </div>
  );
}

export default TableFilterBarAttempts;
