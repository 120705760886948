import { Button, IconName } from '@wework/ray2';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import React, { useCallback } from 'react';
import cn from 'classnames';
import { ActionItemGroup } from '../../../generated/voyager/graphql';
import {
  ActionItemsRouteFilters,
  UserActionItemUrlProps,
} from '../../../sharedStore/entities/userActionItems';
import ROUTES from '../../../utils/routes/routes';
import { generatedUrlSelector } from '../../../sharedStore/modules/generateLink/generateLink.selector';
import { GENERATE_URL } from '../../../sharedStore/modules/generateLink/generateLink.ducks';
import { NavigateButtonContainer } from './actionItems.styled';
import { trackAnalytics } from '../../../utils/analytics/helpers';

interface NavigateButtonProps {
  group: ActionItemGroup;
  location: string;
}

const NavigateButton = (props: NavigateButtonProps) => {
  const rowTypeMap: Record<string, ActionItemsRouteFilters> = {
    [ActionItemGroup.PRICEABLE_ITEM_NO_CURRENT_PRICE]: {
      routeName: ROUTES.STANDARD_PRICING,
      filter: [{ filterName: 'emptyPriceSelected', isSelected: true }],
    },
    [ActionItemGroup.PRICEABLE_ITEM_NO_DISCOUNT]: {
      routeName: ROUTES.DISCOUNT_TABLEVIEW,
      filter: [{ filterName: 'No Discount', isSelected: true }],
    },
    [ActionItemGroup.LOCATION_NO_BREAKEVEN]: {
      routeName: ROUTES.BREAKEVEN_TABLEVIEW,
      filter: [],
    },
    [ActionItemGroup.PRICEABLE_ITEM_EXPIRING_OVERRIDE]: {
      routeName: ROUTES.DISCOUNT_TABLEVIEW,
      filter: [{ filterName: 'DiscountExpiringSoon', isSelected: true }],
    },
    [ActionItemGroup.LOCATION_NO_LIVE_BUDGET]: {
      routeName: ROUTES.BREAKEVEN_TABLEVIEW,
      filter: [],
    },
  };

  const dispatch = useDispatch();

  const generateUrl = useCallback(
    (payload: UserActionItemUrlProps) => dispatch({ type: GENERATE_URL, payload }),
    [dispatch],
  );

  const generatedUrl = useSelector(generatedUrlSelector);

  function trackNavButton() {
    trackAnalytics('Navigate Button Click', {
      workflow: 'Navigate Action Item Button',
      object_type: 'button',
      object_name: 'NavigateActionItem',
      navigate_group: props.group.toString(),
      navigate_location: props.location,
    });
  }

  return (
    <NavigateButtonContainer>
      <i className="toggle-accordion-icon" />
      <Button
        className={cn('navigatebutton')}
        theme={'outline'}
        icon={IconName.ARROW_RIGHT}
        size={'small'}
        onClick={() => {
          trackNavButton();
          const userActionPayload: UserActionItemUrlProps = {
            locationsIds: [props.location],
            filterPathData: get(rowTypeMap, props.group, {} as ActionItemsRouteFilters),
          };

          if (!generatedUrl) {
            generateUrl(userActionPayload);
          }
        }}
      />
    </NavigateButtonContainer>
  );
};

export default NavigateButton;
