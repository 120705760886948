import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { isEmpty } from 'lodash';
import { PageInfo } from '../../../generated/voyager/graphql';
import { FETCH_REGIONS, FETCH_REGIONS_SUCCESS } from './regions.ducks';
import { graphQLClient } from '../../../index';
import FETCH_REGIONS_QUERY from './regions.query';
import { errorHandlerActive } from '../../../utils/errorHandling/helpers';
import { pageInfo } from '../../../utils/store/store.constants';

const fetchRegions = (page: PageInfo): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query: FETCH_REGIONS_QUERY,
    variables: { page },
  });

// Saga - fetch all the regions.
function* fetchRegionsWorker(action: Action<PageInfo>) {
  try {
    const { errors, data } = yield call(fetchRegions, action?.payload ?? pageInfo);
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }
    yield put({ type: FETCH_REGIONS_SUCCESS, payload: data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

export default function* regionSaga(): any {
  yield all([takeLatest(FETCH_REGIONS, fetchRegionsWorker)]);
}
