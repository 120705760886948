import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useSelector } from 'react-redux';
import { find } from 'lodash';
import cn from 'classnames';
import { currentMultipleLocationDetailsSelector } from '../../../../../sharedStore/modules/locations/locations.selector';
import { LocationTitleStyledBreakeven } from '../breakevenTableAGGrid.styled';
import { breakevenItemsSelectorStore } from '../../../store/modules/breakevenItems/breakevenItems.selector';
import { checkIfOverrideExist } from '../breakevenTable.helper';

function BreakevenLocationDetailsRenderer(props: ICellRendererParams): ReactElement {
  const locationDetails = useSelector(currentMultipleLocationDetailsSelector);
  const [name, setName] = useState<String>();
  const allItems = useSelector(breakevenItemsSelectorStore) ?? [];
  const locationId: string | null = props.node?.key;

  useEffect(() => {
    if (locationId) {
      setName(find(locationDetails, ['id', locationId])?.name ?? '-');
    }
  }, [locationDetails, locationId]);

  return (
    <LocationTitleStyledBreakeven>
      <span className={cn({ override: checkIfOverrideExist(allItems, locationId ?? '') }, 'name')}>
        {name}
      </span>
    </LocationTitleStyledBreakeven>
  );
}

export default BreakevenLocationDetailsRenderer;
