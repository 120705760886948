import * as React from 'react';
import { ReactElement } from 'react';
import { Icon, IconName, IconSize, IconType } from '@wework/ray2';
import { find } from 'lodash';
import { ICellRendererParams } from 'ag-grid-community';
import { CustomTableBodyCell } from 'app/discounts/components/discountsTable/discountAGGrid.styled';
import { Unassigned } from '../../../../../../../utils/sharedTypes';

const physicalAttributesCellRender = ({ data }: ICellRendererParams): ReactElement => {
  if (data?.attributes) {
    const hasWindow: boolean = find(data?.attributes, ['label', 'hasWindow'])?.value === 'true';
    const internalRoomVal: string | Unassigned = find(data?.attributes, [
      'label',
      'internalRoomCount',
    ])?.value;
    const internalRoomPresent: boolean = internalRoomVal !== 'null' && internalRoomVal !== '0';
    const internalRoomCount =
      !internalRoomVal || internalRoomVal === 'null' ? '0' : internalRoomVal;

    return (
      <CustomTableBodyCell className="single-line physical-att">
        <Icon
          icon={IconName.WINDOW}
          size={IconSize.LARGE}
          type={IconType.OUTLINE}
          className={`${hasWindow ? 'text-primary' : 'text-gray-70'} window-icon pa-icon`}
        />
        <Icon
          icon={IconName.DOOR}
          size={IconSize.LARGE}
          type={IconType.OUTLINE}
          className={`${
            internalRoomPresent ? 'text-primary' : 'text-gray-70'
          } internal-rooms-icon pa-icon`}
        />
        <p className="counter">{internalRoomCount}</p>
      </CustomTableBodyCell>
    );
  }
  return <> </>;
};

export default physicalAttributesCellRender;
