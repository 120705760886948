import styled from 'styled-components';
import { blue, darkBlue, gray, lighterGray, lightestBlue, red, white } from './colors';
import { FlexHorizontalEndHelper, FlexHorizontalHelper } from './app.styled';

/* BOTTOM PANEL: PRICING, DISCOUNTS */
export const BottomPanelWrapper = styled.div`
  grid-column: 2/5;
  grid-row: 1/5;
  position: absolute;
  z-index: 3;
  right: 0;
  bottom: 0;
  left: 0;
  ${FlexHorizontalEndHelper};
  width: 100%;
  height: 56px;
  padding: 8px 40px;
  margin: 0;
  background: white;
  box-shadow: inset 0 1px 0 0 ${lightestBlue}, 0 0 8px 0 rgba(0, 0, 0, 0.16);
  transition: 0.23s;
  &.publish-panel {
    justify-content: space-between;
  }
  > .ray-btn-styling {
    &:not(:disabled) {
      background-color: ${white};
      color: ${red};
      &:hover {
        background-color: ${lighterGray};
      }
    }
  }
  > .ray-btn-styling-disabled {
    &:disabled {
      background-color: ${white};
    }
    &:not(:disabled) {
      &:hover {
        background-color: ${lighterGray};
        color: #ec0000;
      }
    }
  }
  > .btm-panel-btn-space {
    &[disabled] {
      svg {
        filter: invert(40%) sepia(14%) saturate(811%) hue-rotate(182deg) brightness(86%)
          contrast(90%);
      }
    }
    img.icon {
      background-color: rgba(0, 0, 0, 0) !important;
      margin-right: 10px !important;
    }
    svg {
      filter: invert(8%) sepia(100%) saturate(6207%) hue-rotate(246deg) brightness(107%)
        contrast(143%);
    }
    margin: 0 24px 0 0;
  }
`;
export const BottomPanelLocationsCounter = styled.span`
  margin: 10px 24px 0 0;
  justify-content: flex-start;
  flex: auto;
  button.clear-all {
    margin-left: 10px;
    border: none;
    cursor: pointer;
    color: ${blue};
    outline: none;
    line-height: 15px;
    font-size: 12px;
    font-weight: 400;
    padding: 0;
    background: none;
  }
`;
export const ActionsBox = styled.div`
  ${FlexHorizontalHelper};
  &.action-box-right {
    justify-content: flex-end;
    span {
      margin-top: 10px;
    }
  }
  > .ui.button {
    margin-left: 10px;
  }
  &.action-box-triple {
    justify-content: flex-end;
    &:last-of-type {
      line-height: 40px;
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;
export const ActionSubTitle = styled.h4`
  padding: 0;
  margin: 10px 0 !important;
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  color: ${darkBlue};
  display: inline-block;
  &.info-message {
    color: ${gray};
  }
`;

export const CSVDownloadContainer = styled.div`
  float: left;
  margin: 10px 10px !important;
  line-height: 24px;
  display: inline-block;
  .download-button-icon {
    display: inline-block;
    margin-right: 5px;
  }
`;
