import styled from 'styled-components';
import Open from '../../../../assets/open.svg';
import { darkBlue, darkGray } from '../../../../styles/colors';
import { FlexHorizontalHelper, FlexVerticalHelper, ListReset } from '../../../../styles/app.styled';

export const PanelHeadLabel = styled.span`
  display: block;
  height: 16px;
  margin: 0 0 12px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  color: ${darkGray};
`;

export const PanelBody = styled.div`
  overflow-y: auto;
  height: calc(100% - 186px);
  &.discounts-details {
    height: calc(100vh - 156px);
    overflow-y: auto;
    &.selected-curve {
      height: calc(100vh - 156px);
    }
  }
  .ui.pointing.secondary.menu {
    position: relative;
    margin: 0;
    .item {
      flex: 1 1 50%;
    }
    &::before {
      display: block;
      position: absolute;
      bottom: -11px;
      left: 0;
      right: 18px;
      z-index: 2;
      content: '';
      height: 10px;
      background: linear-gradient(-180deg, white 0%, rgba(255, 255, 255, 0.5) 100%);
    }
  }
  .ui.bottom.attached.segment.tab {
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 200px);
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    overflow-y: auto;
  }
`;
export const PanelFilterList = styled.ul`
  width: calc(100% - 20px);
  ${ListReset};
  ${FlexVerticalHelper};
  &.discounts-list {
    width: 299px;
    > li {
      margin-top: 18px;
      &:first-of-type {
        margin-top: 0;
      }
      &.closed {
        padding-bottom: 26px;
      }
    }
  }
`;

export const FilterHeader = styled.div`
  > button {
    ${FlexHorizontalHelper};
    justify-content: space-between;
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    outline: none;
    text-align: left;
    span {
      display: block;
      flex: 1 1 auto;
      margin: 0;
      line-height: 28px;
      font-size: 16px;
      font-weight: 400;
      color: ${darkBlue};
    }
    i {
      display: block;
      flex: 0 0 auto;
      width: 16px;
      height: 16px;
      padding: 0;
      background: none;
      border: none;
      margin: 6px 0 0 0;
      cursor: pointer;
      outline: none;
      &::before {
        display: block;
        width: 16px;
        height: 17px;
        position: relative;
        content: '';
        background-color: ${darkBlue};
        -webkit-mask: url(${Open});
        mask: url(${Open});
      }
    }
  }
`;
export const FilterBody = styled.div`
  overflow: hidden;
  > .term-table {
    padding-top: 24px;
  }
`;
