import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { IRowNode } from 'ag-grid-community';
import { useDispatch } from 'react-redux';
import { Confirm } from 'semantic-ui-react';
import { pluralize } from 'apollo/lib/utils';
import { DataWithCallback } from '../../../../utils/sharedTypes';

interface ConfirmProps {
  closeModal: () => void;
  isOpen: boolean;
  selectedNodes: IRowNode[];
  loadingSelector: boolean;
  rePublishAction: string;
  syncAttemptName: string;
}

function RePublishingConfirm({
  closeModal,
  isOpen,
  selectedNodes,
  loadingSelector,
  rePublishAction,
  syncAttemptName,
}: ConfirmProps): ReactElement {
  const dispatch = useDispatch();
  const rePublish = useCallback(
    (payload: DataWithCallback<string[]>) => dispatch({ type: rePublishAction, payload }),
    [dispatch, rePublishAction],
  );

  const closeRePublishConfirmationModal = () => {
    closeModal();
  };

  const rePublishElements = (ids: string[]) => {
    rePublish({
      data: ids,
      successCallback: () => closeRePublishConfirmationModal(),
    });
  };

  const uuidType = syncAttemptName.toLowerCase().concat('Uuid');
  function rePublishPriceDiscounts() {
    rePublishElements(selectedNodes.map(node => node.data[uuidType]));
  }

  return (
    <Confirm
      open={isOpen}
      onCancel={closeRePublishConfirmationModal}
      onConfirm={() => rePublishPriceDiscounts()}
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
        disabled: loadingSelector,
      }}
      closeIcon
      confirmButton={{
        content: `Yes`,
        loading: loadingSelector,
      }}
      header={`Re-publish ${syncAttemptName}`}
      content={
        <div>
          <p>
            Are you sure you want to re-publish{' '}
            {pluralize(selectedNodes.length, `${syncAttemptName} sync attempt`)}{' '}
          </p>
        </div>
      }
    />
  );
}

export default RePublishingConfirm;
