import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback } from 'react';
import {
  liveBudgetPushJobLoadingStateSelector,
  locationMetricsPushJobLoadingStateSelector,
  locationSKUOccupancyCalculationStateSelector,
  marketSKUOccupancyCalculationStateSelector,
  reservableMetricsPushJobLoadingStateSelector,
  snowflakeSyncInitializationLoadingStateSelector,
} from '../../store/modules/snowflakeSyncInitialization/snowflakeSyncInitialization.selector';
import {
  LIVE_BUDGET_PUSH_JOB,
  LOCATION_METRICS_PUSH_JOB,
  LOCATION_SKU_OCCUPANCY_CALCULATION,
  MARKET_SKU_OCCUPANCY_CALCULATION,
  PRICE_RECOMMENDATION_PUSH_JOB,
  RESERVABLE_METRICS_PUSH_JOB,
} from '../../store/modules/snowflakeSyncInitialization/snowflakeSyncInitialization.ducks';
import { SnowflakeButton, SnowflakeFunctionalZone } from './SnowflakeSyncInitialization.styled';
import runningJobSelector from '../../store/modules/jobStatus/jobStaus.selector';
import AnotherJobIsRunningTooltip from '../jobStatus/anotherJobIsRunningTooltip';

const SnowflakeSyncInitialization = () => {
  const snowflakeSyncInitializationLoadState = useSelector(
    snowflakeSyncInitializationLoadingStateSelector,
  );
  const liveBudgetPushJobLoadState = useSelector(liveBudgetPushJobLoadingStateSelector);
  const locationMetricsPushJobLoadState = useSelector(locationMetricsPushJobLoadingStateSelector);
  const reservableMetricsPushJobLoadState = useSelector(
    reservableMetricsPushJobLoadingStateSelector,
  );
  const locationSKUOccupancyCalculationState = useSelector(
    locationSKUOccupancyCalculationStateSelector,
  );
  const marketSKUOccupancyCalculationState = useSelector(
    marketSKUOccupancyCalculationStateSelector,
  );
  const job = useSelector(runningJobSelector);
  const dispatch = useDispatch();
  const priceRecommendationPushJobSync = useCallback(
    () => dispatch({ type: PRICE_RECOMMENDATION_PUSH_JOB }),
    [dispatch],
  );
  const liveBudgetPushJobSync = useCallback(
    () => dispatch({ type: LIVE_BUDGET_PUSH_JOB }),
    [dispatch],
  );
  const locationMetricsPushJobSync = useCallback(
    () => dispatch({ type: LOCATION_METRICS_PUSH_JOB }),
    [dispatch],
  );
  const reservableMetricsPushJobSync = useCallback(
    () => dispatch({ type: RESERVABLE_METRICS_PUSH_JOB }),
    [dispatch],
  );
  const locationSKUOccupancyCalculationSync = useCallback(
    () => dispatch({ type: LOCATION_SKU_OCCUPANCY_CALCULATION }),
    [dispatch],
  );
  const marketSKUOccupancyCalculationSync = useCallback(
    () => dispatch({ type: MARKET_SKU_OCCUPANCY_CALCULATION }),
    [dispatch],
  );
  return (
    <SnowflakeFunctionalZone>
      <AnotherJobIsRunningTooltip job={job} />
      <div>
        <span>Start price recommendation push job by clicking this button!</span>
        <SnowflakeButton
          className="snowflakeButton"
          onClick={() => priceRecommendationPushJobSync()}
          disabled={snowflakeSyncInitializationLoadState.loading || job != null}
        >
          Price Recommendation Push Job
        </SnowflakeButton>
      </div>
      <div>
        <span>Start live budget push job by clicking this button!</span>
        <SnowflakeButton
          className="snowflakeButton"
          onClick={() => liveBudgetPushJobSync()}
          disabled={liveBudgetPushJobLoadState.loading || job != null}
        >
          Live Budget Push Job
        </SnowflakeButton>
      </div>
      <div>
        <span>Start location metrics push job by clicking this button!</span>
        <SnowflakeButton
          className="snowflakeButton"
          onClick={() => locationMetricsPushJobSync()}
          disabled={locationMetricsPushJobLoadState.loading || job != null}
        >
          Location Metrics Push Job
        </SnowflakeButton>
      </div>
      <div>
        <span>Start reservable metrics push job by clicking this button!</span>
        <SnowflakeButton
          className="snowflakeButton"
          onClick={() => reservableMetricsPushJobSync()}
          disabled={reservableMetricsPushJobLoadState.loading || job != null}
        >
          Reservable Metrics Push Job
        </SnowflakeButton>
      </div>
      <div>
        <span>Start location SKU occupancy calculation by clicking this button!</span>
        <SnowflakeButton
          className="snowflakeButton"
          onClick={() => locationSKUOccupancyCalculationSync()}
          disabled={locationSKUOccupancyCalculationState.loading}
        >
          Location SKU occupancy calculation Job
        </SnowflakeButton>
      </div>
      <div>
        <span>Start market SKU occupancy calculation by clicking this button!</span>
        <SnowflakeButton
          className="snowflakeButton"
          onClick={() => marketSKUOccupancyCalculationSync()}
          disabled={marketSKUOccupancyCalculationState.loading}
        >
          Market SKU occupancy calculation Job
        </SnowflakeButton>
      </div>
    </SnowflakeFunctionalZone>
  );
};

export default SnowflakeSyncInitialization;
