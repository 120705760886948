import * as React from 'react';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import DetailItemHeaderPriceable, { PriceableItemDetailType } from './detailItemHeaderPriceable';
import DetailItemHeaderLocation, { LocationDetailItemType } from './detailItemHeaderLocation';
import { FETCH_FEATURE_CARD } from '../../../app/pricing/standardPricing/store/modules/priceableItems/priceableItems.ducks';
import { getFetchFeatureCardElements } from '../../../app/pricing/standardPricing/store/modules/priceableItems/priceableItems.selector';
import { PanelFilterList } from '../../../styles/app.styled';
import { PanelBodyPriceable } from '../detailSidePanel.styled';
import { Location, PriceableItem } from '../../../generated/voyager/graphql';

interface DetailSidePanelPriceableProps {
  data: PriceableItem;
  location: Location;
}

const menuParams = { secondary: true, pointing: true };

function DetailSidePanelPriceable({ data, location }: DetailSidePanelPriceableProps): ReactElement {
  const fetchFeatureCardElements = useSelector(getFetchFeatureCardElements, (left, right) =>
    isEqual(left, right),
  );

  const dispatch = useDispatch();
  const fetchPriceableItemFeature = useCallback(
    (payload: string) => dispatch({ type: FETCH_FEATURE_CARD, payload }),
    [dispatch],
  );

  const fetchData = () => {
    if (data?.id) {
      fetchPriceableItemFeature(data.id);
    }
  };

  useEffect(fetchData, [data?.id]);

  /**
   * Tabs content: tabs for Reservable Data and Sku Data
   * Each PriceableItemDetailHeader needs the detailLabel (for title, clickable accordion), the detailDefinition
   * (intro text), the data and the type. Type define what content block type will use to render the data,
   * available types are sku and price, suggested is for the content of the feature card when transferred to this panel.
   */
  /**
   * Tab Panes Builder
   */
  const detailPanes = useMemo(
    () => [
      {
        menuItem: 'Reservable',
        pane: {
          key: 'tab1',
          content: (
            <PanelFilterList className="details-list">
              <DetailItemHeaderPriceable
                detailLabel={'Suggested Price'}
                data={fetchFeatureCardElements}
                type={PriceableItemDetailType.suggested_price}
              />
            </PanelFilterList>
          ),
        },
      },
      {
        menuItem: 'SKU',
        pane: {
          key: 'tab2',
          content: (
            <PanelFilterList className="details-list">
              <DetailItemHeaderLocation
                detailLabel={'Location SKU Occupancy'}
                data={location}
                type={LocationDetailItemType.occupancy}
              />
            </PanelFilterList>
          ),
        },
      },
      {
        menuItem: 'Price History',
        pane: {
          key: 'tab3',
          content: (
            <PanelFilterList className="details-list">
              <DetailItemHeaderPriceable
                detailLabel={'Price History'}
                data={fetchFeatureCardElements}
                type={PriceableItemDetailType.price_history}
              />
            </PanelFilterList>
          ),
        },
      },
    ],
    [fetchFeatureCardElements, location],
  );
  /**
   * Tabs Builder
   */
  return (
    <PanelBodyPriceable className="details-body">
      <Tab className="detail-tabs" menu={menuParams} panes={detailPanes} renderActiveOnly={false} />
    </PanelBodyPriceable>
  );
}

export default DetailSidePanelPriceable;
