import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';
import cn from 'classnames';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import {
  SwitchStyled,
  TableFilter,
  TableFilterRow,
} from '../../../../../../styles/sharedTableFilterBar.styled';
import {
  DISABLE_EDIT_MODE_STANDARD_PRICING,
  ENABLE_EDIT_MODE_STANDARD_PRICING,
} from '../../../store/modules/standardPricingItems/standardPricingItems.ducks';
import {
  standardPricingBatchFetchStoreSelector,
  standardPricingEditModeSelector,
} from '../../../store/modules/standardPricingItems/standardPricingItems.selector';
import { InfoTooltipContent } from '../../../../../../sharedComponents/tooltip/infoTooltip.styled';
import { AuthzProps } from '../../../../../../utils/constants';
import EmployeePermissions from '../../../../../../utils/store/permissions';

interface StandardTableViewEditModeSelectorProps extends AuthzProps {
  agGridApi: any;
  disabled: boolean;
}

const StandardTableViewEditModeSelector = ({
  agGridApi,
  disabled,
  requestedPermissions,
}: StandardTableViewEditModeSelectorProps): ReactElement => {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  const enableEditModeStandardPricingView = useCallback(
    () => dispatch({ type: ENABLE_EDIT_MODE_STANDARD_PRICING }),
    [dispatch],
  );
  const disableEditModeStandardPricingView = useCallback(
    () => dispatch({ type: DISABLE_EDIT_MODE_STANDARD_PRICING }),
    [dispatch],
  );

  // STATE PROPS
  const editMode = useSelector(standardPricingEditModeSelector);
  const batchFetch = useSelector(standardPricingBatchFetchStoreSelector);

  const disableEditModeHelper = () => {
    agGridApi.deselectAll();
    disableEditModeStandardPricingView();
  };

  const enableEditModeHelper = () => {
    agGridApi.ensureColumnVisible('peakDiscount');
    enableEditModeStandardPricingView();
  };

  const getEditModeSwitch = () => (
    <SwitchStyled className="switch">
      <input
        type="checkbox"
        checked={editMode ?? false}
        disabled={batchFetch || disabled}
        onChange={() => (editMode ? disableEditModeHelper() : enableEditModeHelper())}
      />
      <span className={cn({ disabled }, 'slider')} />
      <span className="on">ON</span>
      <span className="off">OFF</span>
    </SwitchStyled>
  );

  return get(
    requestedPermissions,
    EmployeePermissions.voyager_dedicated_space_price_edit,
    false,
  ) ? (
    <TableFilterRow>
      <TableFilter className="edit-table-filter">
        <p className="edit-mode-on-off">Edit</p>
        {disabled ? (
          <Popup basic position={'top left'} trigger={getEditModeSwitch()} on="hover">
            <Popup.Content>
              <InfoTooltipContent>Edit mode disabled in list price mode</InfoTooltipContent>
            </Popup.Content>
          </Popup>
        ) : (
          getEditModeSwitch()
        )}
      </TableFilter>
    </TableFilterRow>
  ) : (
    <></>
  );
};

export default withRequestedAuthz<StandardTableViewEditModeSelectorProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_price_edit],
})(StandardTableViewEditModeSelector);
