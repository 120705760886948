import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dock } from 'react-dock';
import { PanelBody, PanelHeadLabel } from './detailSidePanel.styled';
import { CLOSE_SIDE_PANEL } from '../../sharedStore/modules/detailPanel/detailPanel.ducks';
import { currentDisplayableItemDetailsByIdSelector } from '../../sharedStore/modules/detailPanel/detailPanel.selector';
import { DisplayableItemSubSet } from '../../sharedStore/entities/detailPanel';
import DetailSidePanelLocation from './detailSidePanel/detailSidePanelLocation';
import DetailSidePanelPriceable from './detailSidePanel/detailSidePanelPriceable';
import {
  ClosePanelButton,
  PanelHeader,
  PanelTitle,
  SidePanelWrapper,
} from '../../styles/app.styled';

interface DetailSidePanelProps {
  isVisible: boolean;
  currentDisplayableItem: DisplayableItemSubSet;
}
function DetailSidePanel({
  isVisible,
  currentDisplayableItem,
}: DetailSidePanelProps): ReactElement {
  // STATE PROPS
  const displayedData = useSelector(
    currentDisplayableItemDetailsByIdSelector(currentDisplayableItem),
  );

  // DISPATCH PROPS
  const dispatch = useDispatch();
  const closeSidePanel = useCallback(() => dispatch({ type: CLOSE_SIDE_PANEL }), [dispatch]);

  useEffect(
    () => () => {
      closeSidePanel();
    },
    [closeSidePanel],
  );

  if (!displayedData) {
    return <></>;
  }

  return (
    <SidePanelWrapper>
      <Dock
        position="right"
        isVisible={isVisible}
        dimMode="none"
        fluid={false}
        size={320}
        zIndex={100}
      >
        <ClosePanelButton type="button" onClick={closeSidePanel} />
        <PanelHeader>
          <PanelHeadLabel>{currentDisplayableItem.type}</PanelHeadLabel>
          <PanelTitle>{displayedData.displayableItemData?.name}</PanelTitle>
        </PanelHeader>
        <PanelBody className={`details-body ${currentDisplayableItem.type}`}>
          {currentDisplayableItem.type === 'location' && (
            <DetailSidePanelLocation data={displayedData.displayableItemData} />
          )}
          {currentDisplayableItem.type === 'reservable' && (
            <DetailSidePanelPriceable
              data={displayedData.displayableItemData}
              location={displayedData.locationData}
            />
          )}
        </PanelBody>
      </Dock>
    </SidePanelWrapper>
  );
}

export default DetailSidePanel;
