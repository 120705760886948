import { StoreInterface } from '../../../../../../utils/store/store.interface';
import { FetchState, Unassigned } from '../../../../../../utils/sharedTypes';
import { MarketPriceChange, PriceableItem } from '../../../../../../generated/voyager/graphql';

const priceableItemsSelector = (state: StoreInterface) => state?.priceableItems;

export const priceableItemChangeLogSelector = (
  state: StoreInterface,
): MarketPriceChange[] | Unassigned =>
  priceableItemsSelector(state)?.priceableItemChangeLog?.workingPrice?.changeLog;

export const priceableItemCurrencyIsoSelector = (state: StoreInterface): string | Unassigned =>
  priceableItemsSelector(state)?.priceableItemChangeLog?.currentPrice?.currencyIsoCode;

export const priceableItemChangeLogStateSelector = (
  state: StoreInterface,
): FetchState | Unassigned => priceableItemsSelector(state)?.priceableItemChangeLogState;

export const getFetchFeatureCardState = (state: StoreInterface): FetchState =>
  state?.priceableItems?.featureCardElementsState;

export const getFetchFeatureCardElements = (state: StoreInterface): PriceableItem | null =>
  state?.priceableItems?.featureCardElements;
