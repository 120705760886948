import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { push } from 'connected-react-router';
import { useParams } from 'react-router-dom';
import { MainRegionStyled, OverflowHack } from '../../../../styles/app.styled';
import {
  currentLocationsByIdsLoadState,
  getAllSelectedLocations,
} from '../../../../sharedStore/modules/locations/locations.selector';
import { routerHistory } from '../../../../utils/store/configureStore';
import {
  CLEAR_STANDARD_PRICING_STORE,
  FETCH_STANDARD_PRICING_ITEMS,
} from '../store/modules/standardPricingItems/standardPricingItems.ducks';
import BreadcrumbComponent from '../../../../sharedComponents/breadcrumb/breadcrumbComponent';
import MultipleLocationWidget from '../../../../sharedComponents/multipleLocationWidget/multipleLocationWidget';
import StandardPricingTable from '../components/standardPricingView/standardPricingTable/standardPricingTableAGGrid';
import PublishChangesBottomPanelComponent from '../components/bottomPanel/publishChangesBottomPanel';
import {
  MODIFY_STANDARD_PRICING_FILTER_PARAMS,
  CLEAR_STANDARD_PRICING_FILTER_PARAMS,
} from '../store/modules/standardPricingFilters/standardPricingFilters.ducks';
import ROUTES from '../../../../utils/routes/routes';
import { AuthzProps } from '../../../../utils/constants';
import { CLOSE_SIDE_PANEL } from '../../../../sharedStore/modules/detailPanel/detailPanel.ducks';
import EmployeePermissions from '../../../../utils/store/permissions';
import { MainTitleStyled } from '../../../../sharedComponents/header/header.styled';
import { AppTitle } from '../../../../sharedComponents/homeDashboard/applicationCard.styled';
import currentPathNameSelector from '../../../../sharedStore/modules/router/router.selector';
import { titleName } from '../../../../utils/helpers';
import { pageInfoSmall } from '../../../../utils/store/store.constants';
import { DECODE_URL } from '../../../../sharedStore/modules/generateLink/generateLink.ducks';
import GenerateLink from '../../../../sharedComponents/generateLink/generateLink';
import {
  DecodeUrlInput,
  ShortUrlRouterParams,
} from '../../../../sharedStore/entities/generateLink';
import { standardPricingFiltersSelector } from '../store/modules/standardPricingFilters/standardPricingFilters.selector';
import { CLEAR_LOCATIONS_BY_IDS } from '../../../../sharedStore/modules/locations/locations.ducks';

function StandardPricingTableView({ requestedPermissions }: AuthzProps): ReactElement {
  const { locationsUrl, filtersUrl } = useParams<ShortUrlRouterParams>();

  // DISPATCH PROPS
  const dispatch = useDispatch();

  const selectedLocations = useSelector(getAllSelectedLocations);
  const locationsByIdsLoaded = useSelector(currentLocationsByIdsLoadState);
  const filters = useSelector(standardPricingFiltersSelector);
  const { pathname } = useSelector(currentPathNameSelector);

  // Dispatching props in functional component.
  const fetchStandardPricingItems = useCallback(
    (payload: any) => dispatch({ type: FETCH_STANDARD_PRICING_ITEMS, payload }),
    [dispatch],
  );

  const decodeUrl = useCallback(
    (payload: DecodeUrlInput) => dispatch({ type: DECODE_URL, payload }),
    [dispatch],
  );

  const clearAll = useCallback(() => {
    dispatch({ type: CLEAR_STANDARD_PRICING_STORE });
    dispatch({ type: CLEAR_STANDARD_PRICING_FILTER_PARAMS });
    dispatch({ type: CLOSE_SIDE_PANEL });
    dispatch({ type: CLEAR_LOCATIONS_BY_IDS });
  }, [dispatch]);

  const redirectToAccessDenied = useCallback(
    () => dispatch(push(ROUTES.ACCESS_DENIED_PAGE)),
    [dispatch],
  );

  useEffect(() => {
    if (!get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_view, false)) {
      redirectToAccessDenied();
    } else if (!!locationsUrl && !locationsByIdsLoaded) {
      decodeUrl({
        locationsUrl,
        filtersUrl,
        setFiltersActionName: MODIFY_STANDARD_PRICING_FILTER_PARAMS,
      });
    } else if (!isEmpty(selectedLocations)) {
      fetchStandardPricingItems({
        page: pageInfoSmall,
        filter: { geoLocationUUID: selectedLocations.map(a => a.id) },
        priceFilter: { futurePricesOnly: true },
      });
    } else {
      toast.info('No location selected - Redirecting to pricing home', {
        position: toast.POSITION.TOP_CENTER,
      });
      routerHistory.push({ pathname: ROUTES.PRICING });
    }
  }, [
    locationsUrl,
    filtersUrl,
    locationsByIdsLoaded,
    selectedLocations,
    decodeUrl,
    fetchStandardPricingItems,
    redirectToAccessDenied,
    requestedPermissions,
  ]);

  useEffect(
    () => () => {
      clearAll();
    },
    [clearAll],
  );

  return (
    <>
      <MainRegionStyled>
        <OverflowHack>
          <BreadcrumbComponent />
          <MainTitleStyled>
            <AppTitle>{titleName(pathname)}</AppTitle>
            <GenerateLink
              disabled={isEmpty(selectedLocations)}
              routeName={ROUTES.STANDARD_PRICING}
              filters={filters}
            />
          </MainTitleStyled>
          <MultipleLocationWidget />
          <StandardPricingTable />
        </OverflowHack>
      </MainRegionStyled>
      <PublishChangesBottomPanelComponent showBatchButtons />
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [EmployeePermissions.voyager_dedicated_space_price_view],
})(StandardPricingTableView);
