import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { Confirm } from 'semantic-ui-react';
import { pluralize } from 'apollo/lib/utils';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  generateWorkingPriceOnDemandArr,
  generateWorkingPricesOnDemandMutationParam,
} from '../../onDemandPricing.helpers';
import { ConfirmProps } from '../../../../../../sharedStore/entities/confirmProps';
import { trackAnalytics } from '../../../../../../utils/analytics/helpers';
import {
  onDemandPricingItemsToPublishSelector,
  onDemandPricingProductsToPublishSelector,
} from '../../../store/modules/onDemand/onDemandPricing.selector';
import { ON_DEMAND_WORKING_PRICE_PUBLISH } from '../../../store/modules/onDemand/onDemandPricing.ducks';
import { MutationPublishWorkingPriceHierarchyArgs } from '../../../../../../generated/voyager/graphql';

interface OnDemandPublishWorkingPricesConfirmProps extends ConfirmProps {
  publishDate?: Date;
}

function OnDemandPublishWorkingPricesConfirm(
  props: OnDemandPublishWorkingPricesConfirmProps,
): ReactElement {
  const itemsToPublish = useSelector(onDemandPricingItemsToPublishSelector);
  const productsToPublish = useSelector(onDemandPricingProductsToPublishSelector);

  const workingPricesToBePublished = generateWorkingPriceOnDemandArr(
    itemsToPublish,
    productsToPublish.map(product => product.id),
  );
  const hasFuturePrices = !isEmpty(
    workingPricesToBePublished.filter(eachItem => !isEmpty(eachItem.futurePrices)),
  );

  // DISPATCH PROPS
  const dispatch = useDispatch();

  const publishWorkingPriceLocation = useCallback(
    (payload: MutationPublishWorkingPriceHierarchyArgs) =>
      dispatch({ type: ON_DEMAND_WORKING_PRICE_PUBLISH, payload }),
    [dispatch],
  );

  /**
   * This method is used for batch update the backend price update.
   */
  const publishPrices = () => {
    const dateString = props.publishDate ? format(props.publishDate, 'yyyy-MM-dd') : null;
    // Track batch Edit options and save.
    trackAnalytics('On Demand Working Prices Publish', {
      workflow: 'On Demand Working Prices Publish',
      object_type: 'date',
      object_name: 'Working Prices Publish',
      object_value: dateString,
    });

    publishWorkingPriceLocation({
      publishDate: dateString,
      priceHierarchyIds: generateWorkingPricesOnDemandMutationParam(
        itemsToPublish,
        productsToPublish.map(product => product.id),
      ),
    });

    props.closeModal();
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={props.closeModal}
      onConfirm={publishPrices}
      cancelButton={{ content: 'Cancel', className: 'cancel-confirmation' }}
      closeIcon
      confirmButton={'Publish'}
      header="Publish Prices"
      content={
        <div>
          <p>{`You are going to publish ${pluralize(
            workingPricesToBePublished.length,
            'working price',
          )}.`}</p>
          {hasFuturePrices && (
            <p>
              - One or more selected geo groupings have prices scheduled to be published in the
              future. If you proceed, these prices will be canceled.
            </p>
          )}
          <p>Do you want to proceed?</p>
        </div>
      }
    />
  );
}

export default OnDemandPublishWorkingPricesConfirm;
