import { Action, handleActions } from 'redux-actions';
import initialState from '../../../../../utils/store/initialState';
import { SnowflakeSyncInitializationSubset } from '../../entities/snowflakeSyncInitialization';
import { createRDXConstant } from '../../../../../utils/store/store.constants';

export const PRICE_RECOMMENDATION_PUSH_JOB = createRDXConstant('PRICE_RECOMMENDATION_PUSH_JOB');
export const PRICE_RECOMMENDATION_PUSH_JOB_SUCCESS = createRDXConstant(
  'PRICE_RECOMMENDATION_PUSH_JOB_SUCCESS',
);
export const PRICE_RECOMMENDATION_PUSH_JOB_ERROR = createRDXConstant(
  'PRICE_RECOMMENDATION_PUSH_JOB_ERROR',
);
export const LIVE_BUDGET_PUSH_JOB = createRDXConstant('LIVE_BUDGET_PUSH_JOB');
export const LIVE_BUDGET_PUSH_JOB_SUCCESS = createRDXConstant('LIVE_BUDGET_PUSH_JOB_SUCCESS');
export const LIVE_BUDGET_PUSH_JOB_ERROR = createRDXConstant('LIVE_BUDGET_PUSH_JOB_ERROR');

export const LOCATION_METRICS_PUSH_JOB = createRDXConstant('LOCATION_METRICS_PUSH_JOB');
export const LOCATION_METRICS_PUSH_JOB_SUCCESS = createRDXConstant(
  'LOCATION_METRICS_PUSH_JOB_SUCCESS',
);
export const LOCATION_METRICS_PUSH_JOB_ERROR = createRDXConstant('LOCATION_METRICS_PUSH_JOB_ERROR');

export const RESERVABLE_METRICS_PUSH_JOB = createRDXConstant('RESERVABLE_METRICS_PUSH_JOB');
export const RESERVABLE_METRICS_PUSH_JOB_SUCCESS = createRDXConstant(
  'RESERVABLE_METRICS_PUSH_JOB_SUCCESS',
);
export const RESERVABLE_METRICS_PUSH_JOB_ERROR = createRDXConstant(
  'RESERVABLE_METRICS_PUSH_JOB_ERROR',
);

export const LOCATION_SKU_OCCUPANCY_CALCULATION = createRDXConstant(
  'LOCATION_SKU_OCCUPANCY_CALCULATION',
);
export const LOCATION_SKU_OCCUPANCY_CALCULATION_SUCCESS = createRDXConstant(
  'LOCATION_SKU_OCCUPANCY_CALCULATION_SUCCESS',
);
export const LOCATION_SKU_OCCUPANCY_CALCULATION_ERROR = createRDXConstant(
  'LOCATION_SKU_OCCUPANCY_CALCULATION_ERROR',
);

export const MARKET_SKU_OCCUPANCY_CALCULATION = createRDXConstant(
  'MARKET_SKU_OCCUPANCY_CALCULATION',
);
export const MARKET_SKU_OCCUPANCY_CALCULATION_SUCCESS = createRDXConstant(
  'MARKET_SKU_OCCUPANCY_CALCULATION_SUCCESS',
);
export const MARKET_SKU_OCCUPANCY_CALCULATION_ERROR = createRDXConstant(
  'MARKET_SKU_OCCUPANCY_CALCULATION_ERROR',
);

export const snowflakeSyncInitializationReducer = handleActions<
  SnowflakeSyncInitializationSubset,
  any
>(
  {
    [PRICE_RECOMMENDATION_PUSH_JOB]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      priceRecommendationState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [PRICE_RECOMMENDATION_PUSH_JOB_SUCCESS]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      priceRecommendationState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [PRICE_RECOMMENDATION_PUSH_JOB_ERROR]: (
      state: SnowflakeSyncInitializationSubset,
      action: Action<string>,
    ) => ({
      ...state,
      priceRecommendationState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
    [LIVE_BUDGET_PUSH_JOB]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      liveBudgetPushJobState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [LIVE_BUDGET_PUSH_JOB_SUCCESS]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      liveBudgetPushJobState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [LIVE_BUDGET_PUSH_JOB_ERROR]: (
      state: SnowflakeSyncInitializationSubset,
      action: Action<string>,
    ) => ({
      ...state,
      liveBudgetPushJobState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
    [LOCATION_METRICS_PUSH_JOB]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      locationMetricsPushJobState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [LOCATION_METRICS_PUSH_JOB_SUCCESS]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      locationMetricsPushJobState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [LOCATION_METRICS_PUSH_JOB_ERROR]: (
      state: SnowflakeSyncInitializationSubset,
      action: Action<string>,
    ) => ({
      ...state,
      locationMetricsPushJobState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
    [RESERVABLE_METRICS_PUSH_JOB]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      reservableMetricsPushJobState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [RESERVABLE_METRICS_PUSH_JOB_SUCCESS]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      reservableMetricsPushJobState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [RESERVABLE_METRICS_PUSH_JOB_ERROR]: (
      state: SnowflakeSyncInitializationSubset,
      action: Action<string>,
    ) => ({
      ...state,
      reservableMetricsPushJobState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
    [LOCATION_SKU_OCCUPANCY_CALCULATION]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      locationSKUOccupancyCalculationState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [LOCATION_SKU_OCCUPANCY_CALCULATION_SUCCESS]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      locationSKUOccupancyCalculationState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [LOCATION_SKU_OCCUPANCY_CALCULATION_ERROR]: (
      state: SnowflakeSyncInitializationSubset,
      action: Action<string>,
    ) => ({
      ...state,
      locationSKUOccupancyCalculationState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
    [MARKET_SKU_OCCUPANCY_CALCULATION]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      marketSKUOccupancyCalculationState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [MARKET_SKU_OCCUPANCY_CALCULATION_SUCCESS]: (state: SnowflakeSyncInitializationSubset) => ({
      ...state,
      marketSKUOccupancyCalculationState: {
        loading: false,
        loaded: true,
        error: null,
      },
    }),
    [MARKET_SKU_OCCUPANCY_CALCULATION_ERROR]: (
      state: SnowflakeSyncInitializationSubset,
      action: Action<string>,
    ) => ({
      ...state,
      marketSKUOccupancyCalculationState: {
        loading: false,
        loaded: false,
        error: action.payload,
      },
    }),
  },
  initialState.snowflakeSyncInitialization,
);
