import { Action, handleActions } from 'redux-actions';
import { get, sortBy } from 'lodash';
import initialState from '../../../utils/store/initialState';
import { LocationsSubset } from '../../entities/locations';
import { createRDXConstant } from '../../../utils/store/store.constants';
import { Location } from '../../../generated/voyager/graphql';

// Action Constants
// Action - fetch all locations
export const FETCH_LOCATIONS = createRDXConstant('FETCH_LOCATIONS');
export const FETCH_LOCATIONS_SUCCESS = createRDXConstant('FETCH_LOCATIONS_SUCCESS');

export const FETCH_LOCATIONS_BY_IDS = createRDXConstant('FETCH_LOCATIONS_BY_IDS');
export const FETCH_LOCATIONS_BY_IDS_FINISHED = createRDXConstant('FETCH_LOCATIONS_BY_IDS_FINISHED');
export const CLEAR_LOCATIONS_BY_IDS = createRDXConstant('CLEAR_LOCATIONS_BY_IDS');

export const CLEAR_LOCATIONS_STORE = createRDXConstant('CLEAR_LOCATIONS_STORE');

// Action - fetch locations based on market selection
export const FETCH_LOCATIONS_BY_MARKET = createRDXConstant('FETCH_LOCATIONS_BY_MARKET');
export const FETCH_LOCATIONS_BY_MARKET_SUCCESS = createRDXConstant(
  'FETCH_LOCATIONS_BY_MARKET_SUCCESS',
);

// Action - fetch multiple location details for current location selection.
export const FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID = createRDXConstant(
  'FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID',
);
export const FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID_SUCCESS = createRDXConstant(
  'FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID_SUCCESS',
);

// Action - selected locations modifications
export const MODIFY_SELECTED_LOCATIONS = createRDXConstant('MODIFY_SELECTED_LOCATIONS');
export const CLEAR_SELECTED_LOCATIONS = createRDXConstant('CLEAR_SELECTED_LOCATIONS');

// Reducer
export const locationsReducer = handleActions<LocationsSubset, any>(
  {
    // Reducer for fetching all the locations.
    [FETCH_LOCATIONS]: (state: LocationsSubset) => ({
      ...state,
      fetchLocationsState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_LOCATIONS_SUCCESS]: (state: LocationsSubset, action: Action<any>) => {
      const allLocations = sortBy(get(action, 'payload.locationsByName', {}), ['name']);
      return {
        ...state,
        fetchLocationsState: {
          loading: false,
          loaded: true,
          error: null,
        },
        allLocations,
      };
    },

    [FETCH_LOCATIONS_BY_IDS]: (state: LocationsSubset) => ({
      ...state,
      fetchLocationsByIdsState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_LOCATIONS_BY_IDS_FINISHED]: (state: LocationsSubset, action: Action<Location[]>) => ({
      ...state,
      fetchLocationsByIdsState: {
        loading: false,
        loaded: true,
        error: null,
      },
      locationsSelected: sortBy(action.payload, ['name']),
    }),
    [CLEAR_LOCATIONS_BY_IDS]: (state: LocationsSubset) => ({
      ...state,
      fetchLocationsByIdsState: initialState.locations.fetchLocationsByIdsState,
    }),

    [CLEAR_LOCATIONS_STORE]: (state: LocationsSubset) => ({
      ...state,
      allLocations: initialState.locations.allLocations,
      allMarketLocations: initialState.locations.allMarketLocations,
      fetchLocationsState: initialState.locations.fetchLocationsState,
    }),

    // Reducer for getting all the locations for selected market.
    [FETCH_LOCATIONS_BY_MARKET]: (state: LocationsSubset) => ({
      ...state,
      fetchLocationsState: {
        loading: true,
        loaded: false,
        error: null,
      },
    }),
    [FETCH_LOCATIONS_BY_MARKET_SUCCESS]: (state: LocationsSubset, action: Action<any>) => {
      const allMarketLocations = get(action, 'payload.locationsByMarket', {});
      return {
        ...state,
        fetchLocationsState: {
          loading: false,
          loaded: true,
          error: null,
        },
        allMarketLocations,
      };
    },

    // Reducer for fetch multiple location details
    [FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID]: (state: LocationsSubset) => ({
      ...state,
      fetchLocationsState: {
        loaded: false,
        loading: true,
        error: null,
      },
    }),
    [FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID_SUCCESS]: (
      state: LocationsSubset,
      action: Action<any>,
    ) => {
      const currentMultipleLocationDetails = get(action, 'payload.locationsByIds', []);
      return {
        ...state,
        fetchLocationsState: {
          loading: false,
          loaded: true,
          error: null,
        },
        currentMultipleLocationDetails,
      };
    },

    // Reducer for modifying all selected locations.
    [MODIFY_SELECTED_LOCATIONS]: (state: LocationsSubset, action: Action<any>) => {
      const locationsSelected = get(action, 'payload', {});
      return {
        ...state,
        locationsSelected,
      };
    },
    [CLEAR_SELECTED_LOCATIONS]: (state: LocationsSubset) => ({
      ...state,
      locationsSelected: initialState.locations.locationsSelected,
    }),
  },
  initialState.locations,
);
