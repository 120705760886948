import styled from 'styled-components';
import { blue, darkGray } from '../../styles/colors';
import { FlexHorizontalHelper, ListReset } from '../../styles/app.styled';

export const SearchLocationPageStyled = styled.div`
  margin: 0 40px;
`;
export const FilterRegionStyled = styled.div`
  display: block;
  padding: 3.625vh 0 25px;
  position: sticky;
  position: -webkit-sticky;
  background: white;
  top: calc(-6.625vh + 24px);
  z-index: 5;
  * {
    box-sizing: border-box;
  }
`;
export const FilterRow = styled.ol`
  ${ListReset}
  ${FlexHorizontalHelper}
  width: 100%;
  padding-top: 7px;
  margin-top: 18px;
  justify-content: flex-start;
  align-content: flex-start;
  &:first-of-type {
    margin-top: 0;
  }
`;
export const Filter = styled.li`
  position: relative;
  flex: 0 0 32%;
  width: 18.05vw;
  margin-right: 1.74vw;
  &.combo-dropdown {
    flex: 0 0 100%;
    width: 57.63vw;
  }
  > button.ui.button {
    margin: 0;
  }
  .ui.selection.dropdown.active,
  .ui.selection.dropdown.visible {
    z-index: 9;
  }
  .ui.selection.active.dropdown + .filter-label,
  .ui.selection.dropdown:focus + .filter-label {
    color: ${blue};
  }
  .ui.selection.dropdown.disabled + .filter-label {
    display: none;
  }
`;
export const FilterLabel = styled.label`
  position: absolute;
  top: -7px;
  left: 8px;
  z-index: 10;
  display: inline-block;
  padding: 0 6px 0 4px;
  background: white;
  font-family: Apercu Mono, monospace;
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  color: ${darkGray};
`;
