import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dock } from 'react-dock';
import { Tab, Table } from 'semantic-ui-react';
import {
  ClosePanelButton,
  PanelFilterList,
  PanelFooter,
  PanelHeader,
  PanelTitle,
  SidePanelWrapper,
} from '../../../../../styles/app.styled';
import { CLOSE_PRICE_HISTORY_SIDE_PANEL } from '../../store/modules/onDemand/onDemandPricing.ducks';
import {
  onDemandPriceHistorySelector,
  onDemandPricingProductsSelector,
  priceHistoryGeoSelector,
  priceHistoryLoadingSelector,
} from '../../store/modules/onDemand/onDemandPricing.selector';
import {
  PanelBodyPriceable,
  PanelHeadLabel,
  PanelSectionItem,
} from '../../../../../sharedComponents/sidePanelComponent/detailSidePanel.styled';
import PriceHistoryBlockComponent from './priceHistoryBlockComponent';
import { PriceHistoryBlock, PriceHistoryContainer } from '../onDemandPricing.styled';

interface PriceHistorySidePanelProps {
  isVisible: boolean;
}

const OnDemandDetailsSidePanel = ({ isVisible }: PriceHistorySidePanelProps): ReactElement => {
  const dispatch = useDispatch();

  const closeSidePanel = useCallback(
    () => dispatch({ type: CLOSE_PRICE_HISTORY_SIDE_PANEL }),
    [dispatch],
  );

  const onDemandProducts = useSelector(onDemandPricingProductsSelector);
  const priceHistoryLoading = useSelector(priceHistoryLoadingSelector);
  const priceHistory = useSelector(onDemandPriceHistorySelector);
  const priceHistoryGeo = useSelector(priceHistoryGeoSelector);

  return (
    <SidePanelWrapper>
      <Dock
        position="right"
        isVisible={isVisible}
        dimMode="none"
        fluid={false}
        size={320}
        zIndex={100}
      >
        <ClosePanelButton type="button" className="close" onClick={closeSidePanel} />
        <PanelHeader>
          <PanelHeadLabel>On Demand</PanelHeadLabel>
          <PanelTitle>{priceHistoryGeo?.name}</PanelTitle>
        </PanelHeader>

        {priceHistoryLoading ? (
          'Loading...'
        ) : (
          <PanelBodyPriceable className="details-body">
            <Tab
              className="detail-tabs"
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: 'Details',
                  pane: {
                    key: 'tab1',
                    content: (
                      <PanelFilterList className="details-list">
                        <PanelSectionItem>
                          <PriceHistoryContainer>
                            <Table basic="very">
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell>
                                    <b>Time Zone</b>
                                  </Table.Cell>
                                  <Table.Cell>{priceHistoryGeo?.timeZone ?? 'UTC'}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>
                                    <b>Currency</b>
                                  </Table.Cell>
                                  <Table.Cell>{priceHistoryGeo?.currencyIsoCode ?? '-'}</Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </PriceHistoryContainer>
                        </PanelSectionItem>
                      </PanelFilterList>
                    ),
                  },
                },
                {
                  menuItem: 'Price History',
                  pane: {
                    key: 'tab2',
                    content: (
                      <PanelFilterList className="details-list">
                        {priceHistoryLoading ? (
                          'Loading...'
                        ) : (
                          <PriceHistoryBlock>
                            {onDemandProducts.map(product => (
                              <PriceHistoryBlockComponent
                                key={product.id}
                                product={product}
                                prices={priceHistory}
                                timeZone={priceHistoryGeo?.timeZone ?? undefined}
                              />
                            ))}
                          </PriceHistoryBlock>
                        )}
                      </PanelFilterList>
                    ),
                  },
                },
              ]}
              renderActiveOnly={false}
            />
          </PanelBodyPriceable>
        )}
        <PanelFooter />
      </Dock>
    </SidePanelWrapper>
  );
};

export default OnDemandDetailsSidePanel;
