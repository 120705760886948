/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** DateTime Scalars -> https://github.com/graphql-java/graphql-java-extended-scalars#datetime-scalars */
  Date: { input: any; output: any };
  /** java.time.Instant */
  Instant: { input: any; output: any };
  /** UUID V4 */
  UUID: { input: any; output: any };
};

/** An enum that represent group of action item */
export enum ActionItemGroup {
  LOCATION_NO_BREAKEVEN = 'LOCATION_NO_BREAKEVEN',
  LOCATION_NO_LIVE_BUDGET = 'LOCATION_NO_LIVE_BUDGET',
  PRICEABLE_ITEM_EXPIRING_OVERRIDE = 'PRICEABLE_ITEM_EXPIRING_OVERRIDE',
  PRICEABLE_ITEM_NO_CURRENT_PRICE = 'PRICEABLE_ITEM_NO_CURRENT_PRICE',
  PRICEABLE_ITEM_NO_DISCOUNT = 'PRICEABLE_ITEM_NO_DISCOUNT',
}

export type BaseOverrideInput = {
  termTypeLabel: Scalars['String']['input'];
  value: Scalars['Float']['input'];
};

export type BreakevenChangeReason = {
  __typename?: 'BreakevenChangeReason';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type BreakevenChangeReasonCreation = {
  content: Scalars['String']['input'];
};

export type BreakevenChangeReasonResponse = {
  __typename?: 'BreakevenChangeReasonResponse';
  breakevenChangeReason?: Maybe<BreakevenChangeReason>;
  error?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export enum BusinessLine {
  DEDICATED_SPACE = 'DEDICATED_SPACE',
  ON_DEMAND = 'ON_DEMAND',
}

export enum CancelDiscountScope {
  DISCOUNT = 'DISCOUNT',
  PRICEABLE_ITEM = 'PRICEABLE_ITEM',
}

export type CancelFutureDiscountInput = {
  ids: Array<Scalars['UUID']['input']>;
  scope: CancelDiscountScope;
};

export type CancelFuturePriceInput = {
  ids: Array<Scalars['UUID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  scope: CancelPriceScope;
};

/** Cancel Price Scope: you can cancel a single future price or all future prices for a priceable item */
export enum CancelPriceScope {
  PRICE = 'PRICE',
  PRICEABLE_ITEM = 'PRICEABLE_ITEM',
}

export type CapacityFactor = {
  __typename?: 'CapacityFactor';
  factor: Scalars['Int']['output'];
  fromCapacity: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  toCapacity?: Maybe<Scalars['Int']['output']>;
};

export type CapacityFactorInput = {
  factor: Scalars['Int']['input'];
  fromCapacity: Scalars['Int']['input'];
  toCapacity?: InputMaybe<Scalars['Int']['input']>;
};

export type Currency = {
  __typename?: 'Currency';
  capacityFactors: Array<CapacityFactor>;
  id: Scalars['ID']['output'];
  isoCode: Scalars['String']['output'];
};

export type CurrencyCreationResult = {
  __typename?: 'CurrencyCreationResult';
  currency?: Maybe<Currency>;
  details?: Maybe<Scalars['String']['output']>;
  isCreated: Scalars['Boolean']['output'];
};

export type CurrencyInput = {
  capacityFactors?: InputMaybe<Array<CapacityFactorInput>>;
  isoCode: Scalars['String']['input'];
};

export type CurrentLocationBreakEven = {
  __typename?: 'CurrentLocationBreakEven';
  breakEvenPrice?: Maybe<Scalars['Float']['output']>;
  createdBy: Scalars['String']['output'];
  deskCapacity?: Maybe<Scalars['Int']['output']>;
  desksInYearMonths?: Maybe<Scalars['Int']['output']>;
  includedSGA: Scalars['Boolean']['output'];
  liveBudgetApplied?: Maybe<LiveBudgetApplied>;
  notes?: Maybe<Scalars['String']['output']>;
  opex?: Maybe<Scalars['Float']['output']>;
  override?: Maybe<LocationBreakEvenOverride>;
  publishedAt: Scalars['Instant']['output'];
  rent?: Maybe<Scalars['Float']['output']>;
  rsf?: Maybe<Scalars['Float']['output']>;
  sgAndA?: Maybe<Scalars['Float']['output']>;
  showBreakEven: Scalars['Boolean']['output'];
  steadyStateOcc?: Maybe<Scalars['Float']['output']>;
  tenancyNNN?: Maybe<Scalars['Float']['output']>;
  totalBudgetRevenue?: Maybe<Scalars['Float']['output']>;
  validFrom: Scalars['Instant']['output'];
};

export type Curve = {
  __typename?: 'Curve';
  appliedCount: Scalars['Int']['output'];
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['String']['output'];
  curveTerms: Array<CurveTerm>;
  description?: Maybe<Scalars['String']['output']>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  lastUpdatedBy?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  operator: Operator;
  updatedAt: Scalars['Instant']['output'];
};

export type CurveCurveTermsArgs = {
  termIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CurveDataPoint = {
  __typename?: 'CurveDataPoint';
  month: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type CurveDataPointInput = {
  month: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type CurveInput = {
  curveTerms: Array<CurveTermInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  name: Scalars['String']['input'];
};

export type CurveTerm = {
  __typename?: 'CurveTerm';
  dataPoints: Array<CurveDataPoint>;
  term: TermType;
};

export type CurveTermInput = {
  dataPoints: Array<CurveDataPointInput>;
  termId: Scalars['UUID']['input'];
};

/**
 * An object used for Discounts.
 * Discounts are generated based on base discount
 */
export type Discount = {
  __typename?: 'Discount';
  base: Scalars['Float']['output'];
  createdBy: Scalars['String']['output'];
  curves: Array<DiscountCurve>;
  publishDate: Scalars['Instant']['output'];
  termType: TermType;
};

export type DiscountCurve = {
  __typename?: 'DiscountCurve';
  dataPoints: Array<CurveDataPoint>;
  name: Scalars['String']['output'];
};

export type DiscountCurveInput = {
  curveIds: Array<Scalars['UUID']['input']>;
  priceableItemIds: Array<Scalars['UUID']['input']>;
};

export type DiscountCurveResult = {
  __typename?: 'DiscountCurveResult';
  appliedCurve?: Maybe<GridDiscountCurve>;
  curveId?: Maybe<Scalars['UUID']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  priceableItemId?: Maybe<Scalars['UUID']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DiscountHistory = {
  __typename?: 'DiscountHistory';
  base: Scalars['Float']['output'];
  createdBy: Scalars['String']['output'];
  expirationDate?: Maybe<Scalars['Instant']['output']>;
  publishDate: Scalars['Instant']['output'];
  termType: TermType;
};

/** Describes an override creation intent for a single priceable item. */
export type DiscountOverrideInput = {
  baseOverrides?: InputMaybe<Array<BaseOverrideInput>>;
  priceableItemId: Scalars['ID']['input'];
};

export type GenericResponse = {
  __typename?: 'GenericResponse';
  code?: Maybe<Scalars['Int']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type GeoHierarchy = {
  __typename?: 'GeoHierarchy';
  /**
   * A list of all existing attribute previews for this geo grouping.
   * If an attribute is not set for this geo grouping, it is inherited from parent, if any.
   */
  attributePreviews: Array<GeoHierarchyAttributePreview>;
  /**
   * A list of attributes for this geo grouping.
   * If an attribute is not present in this list, its value is inherited from the parent, if any.
   */
  attributes: Array<GeoHierarchyAttribute>;
  code?: Maybe<Scalars['String']['output']>;
  currencyIsoCode: Scalars['String']['output'];
  currentPrices: Array<PriceHierarchy>;
  futurePrices: Array<PriceHierarchy>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['UUID']['output']>;
  priceHistory: Array<PriceHierarchy>;
  pricePreviews: Array<PricePreview>;
  timeZone?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  workingPrices: Array<WorkingPriceHierarchy>;
};

export type GeoHierarchyAttributePreviewsArgs = {
  productIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type GeoHierarchyCurrentPricesArgs = {
  productIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type GeoHierarchyFuturePricesArgs = {
  productIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type GeoHierarchyPriceHistoryArgs = {
  productIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type GeoHierarchyPricePreviewsArgs = {
  productIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type GeoHierarchyWorkingPricesArgs = {
  productIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** An attribute for a geo grouping and a product. */
export type GeoHierarchyAttribute = {
  __typename?: 'GeoHierarchyAttribute';
  /** The attribute label. */
  label: GeoHierarchyAttributeLabel;
  /** The associated product id from Product Service. */
  productId: Scalars['UUID']['output'];
  /** The attribute value. */
  value: Scalars['String']['output'];
};

export enum GeoHierarchyAttributeLabel {
  NON_LINEAR_PRICING_ENABLED = 'NON_LINEAR_PRICING_ENABLED',
}

/** An attribute preview for a geo grouping and a product. */
export type GeoHierarchyAttributePreview = {
  __typename?: 'GeoHierarchyAttributePreview';
  /** The attribute label. */
  label: GeoHierarchyAttributeLabel;
  /** The associated product id from Product Service. */
  productId: Scalars['UUID']['output'];
  /**
   * The attribute inheritance type.
   * It shows the level where the attribute was defined.
   */
  type: Scalars['String']['output'];
  /** The attribute value. */
  value: Scalars['String']['output'];
};

export type GeoHierarchyFilter = {
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Returns only geo hierarchy entities that are direct children of the given parentIds. */
  parentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** If topLevelOnly is set to true, only top level hierarchy entities are returned. */
  topLevelOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GridBaseDiscount = {
  __typename?: 'GridBaseDiscount';
  base: Scalars['Float']['output'];
  createdBy: Scalars['String']['output'];
  publishDate: Scalars['Instant']['output'];
};

export type GridDiscount = {
  __typename?: 'GridDiscount';
  baseDiscount?: Maybe<GridBaseDiscount>;
  futureBaseDiscount?: Maybe<GridBaseDiscount>;
  futureOverride?: Maybe<GridDiscountOverride>;
  override?: Maybe<GridDiscountOverride>;
  termType: TermType;
};

export type GridDiscountCurve = {
  __typename?: 'GridDiscountCurve';
  appliedAt: Scalars['Instant']['output'];
  appliedBy: Scalars['String']['output'];
  curve: Curve;
};

export type GridDiscountOverride = {
  __typename?: 'GridDiscountOverride';
  base?: Maybe<Scalars['Float']['output']>;
  createdBy: Scalars['String']['output'];
  expirationDate: Scalars['Instant']['output'];
  publishDate: Scalars['Instant']['output'];
  updatedAt: Scalars['Instant']['output'];
};

export type GroupSummary = {
  __typename?: 'GroupSummary';
  locations: Array<LocationInfo>;
  /** change name, group key? */
  sku: Scalars['String']['output'];
};

export type GroupedLocationPriceSummary = {
  __typename?: 'GroupedLocationPriceSummary';
  avg: Scalars['Float']['output'];
  currencyIsoCode: Scalars['String']['output'];
  group: Scalars['String']['output'];
  locationUUID: Scalars['UUID']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export enum InternalRooms {
  INTERNAL_ROOM = 'INTERNAL_ROOM',
  NO_INTERNAL_ROOM = 'NO_INTERNAL_ROOM',
}

export type InventoryBaseDiscount = {
  __typename?: 'InventoryBaseDiscount';
  base: Scalars['Float']['output'];
  id: Scalars['UUID']['output'];
};

export type InventoryCurve = {
  __typename?: 'InventoryCurve';
  curveTerms: Array<InventoryCurveTerm>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
};

export type InventoryCurveDataPoint = {
  __typename?: 'InventoryCurveDataPoint';
  month: Scalars['Int']['output'];
  value: Scalars['Float']['output'];
};

export type InventoryCurveTerm = {
  __typename?: 'InventoryCurveTerm';
  dataPoints: Array<InventoryCurveDataPoint>;
  term: InventoryTermType;
};

export type InventoryDiscount = {
  __typename?: 'InventoryDiscount';
  discount?: Maybe<InventoryBaseDiscount>;
  term: InventoryTermType;
};

export type InventoryFilter = {
  geoLocationUUID?: InputMaybe<Array<Scalars['UUID']['input']>>;
  refUUID?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  /**
   * Returns curves for all terms.
   * If "customTermLengths" is provided, the query returns data for the requested terms only.
   */
  curves: Array<InventoryCurve>;
  /**
   * Returns discounts for all terms.
   * If "customTermLengths" is provided, the query returns data for the requested terms only.
   */
  discounts: Array<InventoryDiscount>;
  /** Voyager inventory id */
  id: Scalars['UUID']['output'];
  /** Location id from geo service */
  locationId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<InventoryPrice>;
  /** Inventory id from its original source */
  refUUID: Scalars['UUID']['output'];
};

export type InventoryItemCurvesArgs = {
  customTermLengths?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InventoryItemDiscountsArgs = {
  customTermLengths?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InventoryPrice = {
  __typename?: 'InventoryPrice';
  currencyIsoCode: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  listPrice: Scalars['Float']['output'];
};

/** The input required to find an inventory price. */
export type InventoryPriceInput = {
  /** The inventory capacity used to calculate OnDemand prices. If not set, defaults to 1. */
  capacity?: InputMaybe<Scalars['Int']['input']>;
  /** The IMS inventory id. */
  inventoryId: Scalars['UUID']['input'];
  /** The Spaceman location id. Required for OnDemand products. */
  locationId?: InputMaybe<Scalars['UUID']['input']>;
  /** The Product Service product id. */
  productId: Scalars['UUID']['input'];
};

/** The list price for an inventory item. */
export type InventoryPriceResponse = {
  __typename?: 'InventoryPriceResponse';
  /** The currency ISO code associated with this price, ex. USD. */
  currencyIsoCode: Scalars['String']['output'];
  /** The IMS inventory id. */
  inventoryId: Scalars['UUID']['output'];
  /** The list price value of the inventory item */
  listPrice: Scalars['Float']['output'];
  /** The Voyager price id. */
  priceId: Scalars['UUID']['output'];
};

export type InventoryTermType = {
  __typename?: 'InventoryTermType';
  customLength?: Maybe<Scalars['Int']['output']>;
  label: Scalars['String']['output'];
  length: Scalars['Int']['output'];
};

export type LiveBudget = {
  __typename?: 'LiveBudget';
  breakEvenPrice?: Maybe<Scalars['Float']['output']>;
  deskCapacity?: Maybe<Scalars['Int']['output']>;
  desksInYearMonths?: Maybe<Scalars['Int']['output']>;
  opex?: Maybe<Scalars['Float']['output']>;
  rent?: Maybe<Scalars['Float']['output']>;
  rsf?: Maybe<Scalars['Float']['output']>;
  sgAndA?: Maybe<Scalars['Float']['output']>;
  steadyStateOcc?: Maybe<Scalars['Float']['output']>;
  tenancyNNN?: Maybe<Scalars['Float']['output']>;
  totalBudgetRevenue?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['Instant']['output'];
};

export type LiveBudgetApplied = {
  __typename?: 'LiveBudgetApplied';
  deskCapacity?: Maybe<Scalars['Int']['output']>;
  desksInYearMonths?: Maybe<Scalars['Int']['output']>;
  opex?: Maybe<Scalars['Float']['output']>;
  rent?: Maybe<Scalars['Float']['output']>;
  rsf?: Maybe<Scalars['Float']['output']>;
  steadyStateOcc?: Maybe<Scalars['Float']['output']>;
  tenancyNNN?: Maybe<Scalars['Float']['output']>;
  totalBudgetRevenue?: Maybe<Scalars['Float']['output']>;
};

export type Location = {
  __typename?: 'Location';
  currencyIsoCode: Scalars['String']['output'];
  /** SUM of desks counts for each priceable items of this location */
  deskCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  /** A last price change for each location */
  lastPriceChange?: Maybe<MarketPriceChange>;
  market: Market;
  /** A list of max discount by sku for each location */
  maxDiscounts: Array<MaxDiscount>;
  /** A group of metrics for the location */
  metrics?: Maybe<LocationMetrics>;
  /** Minimum price for a location */
  minPrice?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  operators: Array<Operator>;
  /**
   * A list of occupancy by sku at location level. The way to get to sku occupancy, we take all the reservables for
   * which we are calculating occupancy for across the location and it's to sum the is_occupied = TRUEs over the total
   * count of reservables that match the sku (excluding all unopen reservables).
   */
  skuOccupancy: Array<SkuOccupancy>;
  timeZone: Scalars['String']['output'];
};

/** An object used for location action item. */
export type LocationActionItem = {
  __typename?: 'LocationActionItem';
  /** The total number of reservables in this location that require action, if applicable */
  count?: Maybe<Scalars['Int']['output']>;
  /** ID of location */
  locationId: Scalars['UUID']['output'];
  /** Name of location */
  locationName: Scalars['String']['output'];
};

export type LocationBreakEvenDetail = {
  __typename?: 'LocationBreakEvenDetail';
  current?: Maybe<CurrentLocationBreakEven>;
  liveBudget?: Maybe<LiveBudget>;
  liveBudgetApplied?: Maybe<LiveBudgetApplied>;
  location: Location;
  override?: Maybe<LocationBreakEvenOverride>;
};

export type LocationBreakEvenOverride = {
  __typename?: 'LocationBreakEvenOverride';
  deskCapacity?: Maybe<Scalars['Int']['output']>;
  desksInYearMonths?: Maybe<Scalars['Int']['output']>;
  includedSGA?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  opex?: Maybe<Scalars['Float']['output']>;
  rent?: Maybe<Scalars['Float']['output']>;
  rsf?: Maybe<Scalars['Float']['output']>;
  sgAndA?: Maybe<Scalars['Float']['output']>;
  showBreakEven?: Maybe<Scalars['Boolean']['output']>;
  steadyStateOcc?: Maybe<Scalars['Float']['output']>;
  tenancyNNN?: Maybe<Scalars['Float']['output']>;
  totalBudgetRevenue?: Maybe<Scalars['Float']['output']>;
};

export type LocationBreakEvenPublishResponse = {
  __typename?: 'LocationBreakEvenPublishResponse';
  errors?: Maybe<Scalars['String']['output']>;
  invalidLocationIds?: Maybe<Array<Scalars['UUID']['output']>>;
  success: Scalars['Boolean']['output'];
};

/** change name (group info?) */
export type LocationInfo = {
  __typename?: 'LocationInfo';
  info: Array<SummaryInfo>;
  /** consider: if we use location, then we create a cycle */
  location: Location;
};

/** An entity used for locations metrics relevant to the price process. */
export type LocationMetrics = {
  __typename?: 'LocationMetrics';
  /**
   * SUM of Net Paid Price in Local Currency per Spaceman / SUM of occupied Desks, for current month
   * Net Paid Price = Reservation Price Local Currency - Total Monthly Discounts Local Currency for the Reservation
   */
  averageNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** current month average discount */
  avgDiscount?: Maybe<Scalars['Float']['output']>;
  /**
   * For Open, Un-Archived Offices (exc DD, HD, Shared Office Desks) with a capacity of 50 Desks or more:
   * SUM of Net Paid Price in Local Currency per Spaceman / SUM of occupied Desks, for current month
   * Net Paid Price = Reservation Price Local Currency - Total Monthly Discounts Local Currency for the Reservation
   */
  avgNetArpmLargeSku?: Maybe<Scalars['Float']['output']>;
  /**
   * For Open, Un-Archived Offices (exc DD, HD, Shared Office Desks) with a capacity of less than 50 Desks:
   * SUM of Net Paid Price in Local Currency per Spaceman / SUM of occupied Desks, for current month
   * Net Paid Price = Reservation Price Local Currency - Total Monthly Discounts Local Currency for the Reservation
   */
  avgNetArpmSmallSku?: Maybe<Scalars['Float']['output']>;
  /** SUM of occupied desks for current month BOM / SUM of capacity desks for current month BOM */
  currentOccupancy?: Maybe<Scalars['Float']['output']>;
  /** dedicated desk count */
  dedicatedDeskCount?: Maybe<Scalars['Int']['output']>;
  /** hot desk count */
  hotDeskCount?: Maybe<Scalars['Int']['output']>;
  /**
   * For Open, Un-Archived Offices (exc DD, HD, Shared Office Desks) with a capacity of 50 Desks or more:
   * SUM of occupied desks BOM for current month / SUM of capacity desks BOM for current month
   */
  largeSkuOccupancy?: Maybe<Scalars['Float']['output']>;
  /** large list price */
  lgListPrice?: Maybe<Scalars['Float']['output']>;
  /** current month large sku net arpm */
  lgSkuNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** large SKU: next 3 months occupancy */
  lgSkuNext3mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** large SKU: next 6 months occupancy */
  lgSkuNext6mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** large SKU: current month occupancy */
  lgSkuOccupancy?: Maybe<Scalars['Float']['output']>;
  /** large SKU: previous 3 months occupancy */
  lgSkuPre3mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** large SKU: previous 6 months occupancy */
  lgSkuPre6mOccupancy?: Maybe<Scalars['Float']['output']>;
  /**
   * Date at which the location started generating net positive revenue or date at which the first reservable opened for
   * operations at the location, per Spaceman
   */
  locationOpenDate?: Maybe<Scalars['Date']['output']>;
  /** current month minimum price */
  minPriceLocal?: Maybe<Scalars['Float']['output']>;
  /** current month average net arpm */
  netArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** next month average discount */
  next1mAvgDiscount?: Maybe<Scalars['Float']['output']>;
  /** next month large sku net arpm */
  next1mLgSkuNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** next month minimum price */
  next1mMinPriceLocal?: Maybe<Scalars['Float']['output']>;
  /** next month average net arpm */
  next1mNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** next month small sku net arpm */
  next1mSmSkuNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /**
   * SUM of occupied desks for next 3 months inc current month BOM / SUM of capacity desks for next 3 months inc current
   * month
   */
  next3mOccupancy?: Maybe<Scalars['Float']['output']>;
  /**
   * SUM of occupied desks for next 6 months inc current month BOM / SUM of capacity desks for next 6 months inc current
   * month
   */
  next6mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** previous month average discount */
  prev1mAvgDiscount?: Maybe<Scalars['Float']['output']>;
  /** previous month large sku net arpm */
  prev1mLargeSkuNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** previous month minimum price */
  prev1mMinPriceLocal?: Maybe<Scalars['Float']['output']>;
  /** previous month average net arpm */
  prev1mNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** previous month small sku net arpm */
  prev1mSmSkuNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** private office count */
  privateOfficeCount?: Maybe<Scalars['Int']['output']>;
  /** shared office count */
  sharedOfficeCount?: Maybe<Scalars['Int']['output']>;
  /** small list price */
  smListPrice?: Maybe<Scalars['Float']['output']>;
  /** current month small sku net arpm */
  smSkuNetArpmLocal?: Maybe<Scalars['Float']['output']>;
  /** small SKU: next 3 months occupancy */
  smSkuNext3mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** small SKU: next 3 months occupancy */
  smSkuNext6mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** small SKU: current month occupancy */
  smSkuOccupancy?: Maybe<Scalars['Float']['output']>;
  /** small SKU: previous 3 months occupancy */
  smSkuPre3mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** small SKU: previous 6 months occupancy */
  smSkuPre6mOccupancy?: Maybe<Scalars['Float']['output']>;
  /**
   * For Open, Un-Archived Offices (exc DD, HD, Shared Office Desks) with a capacity of less than 50 desks:
   * SUM of occupied desks BOM for current month / SUM of capacity desks BOM for current month
   */
  smallSkuOccupancy?: Maybe<Scalars['Float']['output']>;
  /**
   * SUM of occupied desks for trailing 3 months inc current month BOM / SUM of capacity desks for training 3 months inc
   * current month
   */
  trailing3mOccupancy?: Maybe<Scalars['Float']['output']>;
  /**
   * SUM of occupied desks for trailing 6 months inc current month BOM / SUM of capacity desks for training 6 months inc
   * current month
   */
  trailing6mOccupancy?: Maybe<Scalars['Float']['output']>;
};

export type LocationPriceSummary = {
  __typename?: 'LocationPriceSummary';
  avg: Scalars['Float']['output'];
  currencyIsoCode: Scalars['String']['output'];
  locationUUID?: Maybe<Scalars['UUID']['output']>;
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type Market = {
  __typename?: 'Market';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /**
   * A list of occupancy by sku at market level. The way to get to sku occupancy, we take all the reservables for which
   * we are calculating occupancy for across the market and it's to sum the is_occupied = TRUEs over the total
   * count of reservables that match the sku (excluding all unopen reservables).
   */
  skuOccupancy: Array<SkuOccupancy>;
  territory: Territory;
};

/** A market price at a given point in time */
export type MarketPrice = {
  __typename?: 'MarketPrice';
  changeLog: Array<MarketPriceChange>;
  /** user who did the change */
  createdBy?: Maybe<Scalars['String']['output']>;
  currencyIsoCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** reason for the price change */
  lastChangeReason?: Maybe<Scalars['String']['output']>;
  /** Market Price */
  marketPrice: Scalars['Float']['output'];
  /** ARPM */
  price: Scalars['Float']['output'];
  publishedAt: Scalars['Instant']['output'];
  recommended?: Maybe<Scalars['Float']['output']>;
  validFrom: Scalars['Instant']['output'];
};

/** A record of working price changes */
export type MarketPriceChange = {
  __typename?: 'MarketPriceChange';
  createdAt: Scalars['Instant']['output'];
  /** user who did the change */
  createdBy?: Maybe<Scalars['String']['output']>;
  currencyIsoCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** ARPM */
  price: Scalars['Float']['output'];
  /** reason for the price change */
  reason?: Maybe<Scalars['String']['output']>;
  recommended?: Maybe<Scalars['Float']['output']>;
};

export type MarketPriceInput = {
  /** reason for the price change */
  lastChangeReason?: InputMaybe<Scalars['String']['input']>;
  /** ARPM */
  price: Scalars['Float']['input'];
  priceableItemId: Scalars['UUID']['input'];
  recommended?: InputMaybe<Scalars['Float']['input']>;
  validFrom: Scalars['Date']['input'];
};

/** An object used for max discount. */
export type MaxDiscount = {
  __typename?: 'MaxDiscount';
  discount?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCapacityFactors?: Maybe<Currency>;
  /** Apply curves to selected priceable items */
  applyCurves: Array<DiscountCurveResult>;
  archiveChangeReason: BreakevenChangeReasonResponse;
  /** Cancel future discounts */
  cancelFutureDiscounts: GenericResponse;
  /**
   * Cancel future price hierarchy for geo groupings and products.
   * Future price must exist for the given geo hierarchy + product combo, otherwise an error is returned.
   */
  cancelFuturePriceHierarchy: GenericResponse;
  cancelFuturePrices: GenericResponse;
  /** Changes a market price. */
  changeMarketPrice?: Maybe<MarketPrice>;
  createChangeReason: BreakevenChangeReasonResponse;
  createCurrencies: Array<CurrencyCreationResult>;
  /** Create a discount curve from the input */
  createCurve: GenericResponse;
  deleteCurrency?: Maybe<Scalars['Boolean']['output']>;
  /** Delete (archive) a discount curve by id */
  deleteCurve: GenericResponse;
  publishLocationBreakEvenData: LocationBreakEvenPublishResponse;
  /** Accepts a set of override input types and creates them all using the passed expiration date. */
  publishOverrides: GenericResponse;
  /**
   * Publish working price hierarchy for geo groupings and products.
   * Working price must exist for the given geo hierarchy + product combo, otherwise an error is returned.
   */
  publishWorkingPriceHierarchy: GenericResponse;
  /** Will check the scope and publish either the whole scope or individual priceable items. */
  publishWorkingPrices: GenericResponse;
  /**
   * Pushes prices to RabitMQ.
   * This is a utility mutation which is supposed to be used by admins as a recovery
   * if some prices didn't make it to the queue.
   */
  pushPrices: Scalars['Boolean']['output'];
  removeCapacityFactors?: Maybe<Currency>;
  /** Remove curves from selected priceable items */
  removeCurves: Array<DiscountCurveResult>;
  resetPricesToLastPublished: Array<WorkingPriceResetResult>;
  /**
   * Revert working price hierarchy for geo groupings and products.
   * Working price must exist for the given geo hierarchy + product combo, otherwise an error is returned.
   */
  revertWorkingPriceHierarchy: GenericResponse;
  /**
   * Save working price hierarchy for geo groupings and products.
   * These changes will not be propagated downstream until published.
   */
  saveWorkingPriceHierarchy: GenericResponse;
  /**
   * Creates or updates a list of working prices.
   * History will be kept, the working price is the last change.
   */
  saveWorkingPrices: GenericResponse;
  updateChangeReason: BreakevenChangeReasonResponse;
  /** Update a discount curve by id with values from the input */
  updateCurve: GenericResponse;
  /**
   * Updates the attribute value for all geo grouping ids and product ids.
   * If the value is null, the attribute is deleted.
   */
  updateGeoHierarchyAttributes: GenericResponse;
  workingLocationBreakEven: GenericResponse;
  /** Creates or updates a working prices given a location, sku and attribute combination. */
  workingPriceByGeoSkuAttr: GenericResponse;
};

export type MutationAddCapacityFactorsArgs = {
  capacityFactors: Array<CapacityFactorInput>;
  currencyId: Scalars['ID']['input'];
};

export type MutationApplyCurvesArgs = {
  input: DiscountCurveInput;
};

export type MutationArchiveChangeReasonArgs = {
  id: Scalars['ID']['input'];
};

export type MutationCancelFutureDiscountsArgs = {
  cancelFutureDiscountInput: CancelFutureDiscountInput;
};

export type MutationCancelFuturePriceHierarchyArgs = {
  priceHierarchyIds: Array<PriceHierarchyIdInput>;
};

export type MutationCancelFuturePricesArgs = {
  cancelFuturePriceInput: CancelFuturePriceInput;
};

export type MutationChangeMarketPriceArgs = {
  marketPriceInput: MarketPriceInput;
};

export type MutationCreateChangeReasonArgs = {
  breakevenChangeReasonCreation: BreakevenChangeReasonCreation;
};

export type MutationCreateCurrenciesArgs = {
  currencies: Array<CurrencyInput>;
};

export type MutationCreateCurveArgs = {
  curveInput: CurveInput;
  linkedCurveId?: InputMaybe<Scalars['UUID']['input']>;
  operatorId: Scalars['String']['input'];
};

export type MutationDeleteCurrencyArgs = {
  id: Scalars['ID']['input'];
};

export type MutationDeleteCurveArgs = {
  curveId: Scalars['UUID']['input'];
};

export type MutationPublishLocationBreakEvenDataArgs = {
  locationIds: Array<Scalars['ID']['input']>;
  validFrom?: InputMaybe<Scalars['Date']['input']>;
};

export type MutationPublishOverridesArgs = {
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  overrides?: InputMaybe<Array<DiscountOverrideInput>>;
  publishDate?: InputMaybe<Scalars['Date']['input']>;
};

export type MutationPublishWorkingPriceHierarchyArgs = {
  priceHierarchyIds: Array<PriceHierarchyIdInput>;
  publishDate?: InputMaybe<Scalars['Date']['input']>;
};

export type MutationPublishWorkingPricesArgs = {
  publishWorkingPriceInput: PublishWorkingPriceInput;
};

export type MutationPushPricesArgs = {
  ids: Array<Scalars['UUID']['input']>;
};

export type MutationRemoveCapacityFactorsArgs = {
  capacityFactorIds: Array<Scalars['ID']['input']>;
  currencyId: Scalars['ID']['input'];
};

export type MutationRemoveCurvesArgs = {
  input: DiscountCurveInput;
};

export type MutationResetPricesToLastPublishedArgs = {
  ids: Array<Scalars['UUID']['input']>;
  scope: WorkingPriceScope;
};

export type MutationRevertWorkingPriceHierarchyArgs = {
  priceHierarchyIds: Array<PriceHierarchyIdInput>;
};

export type MutationSaveWorkingPriceHierarchyArgs = {
  priceHierarchy: Array<PriceHierarchyInput>;
};

export type MutationSaveWorkingPricesArgs = {
  workingPriceInputs: Array<WorkingPriceInput>;
};

export type MutationUpdateChangeReasonArgs = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type MutationUpdateCurveArgs = {
  curveId: Scalars['UUID']['input'];
  curveInput: CurveInput;
};

export type MutationUpdateGeoHierarchyAttributesArgs = {
  geoGroupingIds: Array<Scalars['UUID']['input']>;
  label: GeoHierarchyAttributeLabel;
  newValue?: InputMaybe<Scalars['String']['input']>;
  productIds: Array<Scalars['UUID']['input']>;
};

export type MutationWorkingLocationBreakEvenArgs = {
  workingLocationBreakEvenInput: WorkingLocationBreakEvenInput;
};

export type MutationWorkingPriceByGeoSkuAttrArgs = {
  workingByGeoAttrSkuPriceInput: WorkingByGeoAttrSkuPriceInput;
};

export type Operator = {
  __typename?: 'Operator';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PageInfo = {
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
};

export type PriceFilter = {
  futurePricesOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PriceHierarchy = {
  __typename?: 'PriceHierarchy';
  changelog: Array<PriceHierarchyChange>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['String']['output'];
  currencyIsoCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  productId: Scalars['UUID']['output'];
  publishedAt: Scalars['Instant']['output'];
  validFrom: Scalars['Instant']['output'];
};

export type PriceHierarchyChange = {
  __typename?: 'PriceHierarchyChange';
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['String']['output'];
  currencyIsoCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type PriceHierarchyIdInput = {
  geoGroupingId: Scalars['UUID']['input'];
  productId: Scalars['UUID']['input'];
};

export type PriceHierarchyInput = {
  id: PriceHierarchyIdInput;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type PricePreview = {
  __typename?: 'PricePreview';
  currencyIsoCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Float']['output'];
  productId: Scalars['UUID']['output'];
  type: Scalars['String']['output'];
};

/** A price recommendation */
export type PriceRecommendation = {
  __typename?: 'PriceRecommendation';
  /** confidence Level of the algorithm */
  confidenceLevel?: Maybe<Scalars['String']['output']>;
  /** Date of the last recommendation generation run */
  date: Scalars['Instant']['output'];
  /** most important features driving the recommendation */
  features: Array<RecommendationFeature>;
  maxPrice?: Maybe<Scalars['Float']['output']>;
  minPrice?: Maybe<Scalars['Float']['output']>;
  /** The recommended price */
  price: Scalars['Float']['output'];
};

export enum PriceSummaryGrouping {
  CAPACITY = 'CAPACITY',
  HAS_WINDOW = 'HAS_WINDOW',
  PRODUCT_TYPE = 'PRODUCT_TYPE',
  SKU = 'SKU',
}

/** An entity which represents anything that can be priced in this system. */
export type PriceableItem = {
  __typename?: 'PriceableItem';
  appliedCurves: Array<GridDiscountCurve>;
  /** Attributes used for the pricing process. */
  attributes: Array<PriceableItemAttribute>;
  /** last valid market and published price, known to the system. */
  currentPrice?: Maybe<MarketPrice>;
  /**
   * A list of Discount
   * @deprecated Please use inventoryItem(s).discounts and inventoryItem(s).curves instead
   */
  discounts: Array<Discount>;
  /** All valid published discount history including discounts and override discounts */
  discountsHistory: Array<DiscountHistory>;
  gridDiscounts: Array<GridDiscount>;
  id: Scalars['ID']['output'];
  /** Location is a location in the geo service */
  location: Location;
  /** A group of metrics for the priceable item */
  metrics?: Maybe<ReservableMetrics>;
  name: Scalars['String']['output'];
  /** All valid market and published prices, known to the system. */
  prices: Array<MarketPrice>;
  /** A price recommendation for a concrete priceable item */
  recommendedPrice?: Maybe<PriceRecommendation>;
  refType: Scalars['String']['output'];
  refUUID: Scalars['UUID']['output'];
  /** Non published MarketPrice, and there can only be one or null. */
  workingPrice?: Maybe<WorkingPrice>;
};

/** An entity which represents anything that can be priced in this system. */
export type PriceableItemDiscountsArgs = {
  customTermLengths?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** An entity which represents anything that can be priced in this system. */
export type PriceableItemPricesArgs = {
  filter?: InputMaybe<PriceFilter>;
};

/** An attribute used for the pricing process, ex: capacity, squareFootage, hasWindow, etc. */
export type PriceableItemAttribute = {
  __typename?: 'PriceableItemAttribute';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type PriceableItemFilter = {
  businessLine?: InputMaybe<Array<Scalars['UUID']['input']>>;
  geoLocationUUID?: InputMaybe<Array<Scalars['UUID']['input']>>;
  productId?: InputMaybe<Array<Scalars['UUID']['input']>>;
  refUUID?: InputMaybe<Array<Scalars['UUID']['input']>>;
  withWorkingPrice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProductPrice = {
  __typename?: 'ProductPrice';
  capacity?: Maybe<Scalars['Int']['output']>;
  currencyIsoCode: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  price: Scalars['Float']['output'];
  refUUID: Scalars['UUID']['output'];
};

export type ProductPriceContext = {
  capacity?: InputMaybe<Scalars['Int']['input']>;
  refUUID: Scalars['UUID']['input'];
};

export type PublishWorkingPriceInput = {
  ids: Array<Scalars['ID']['input']>;
  scope: WorkingPriceScope;
  validFrom?: InputMaybe<Scalars['Date']['input']>;
};

export type Query = {
  __typename?: 'Query';
  breakevenChangeReasons: Array<BreakevenChangeReason>;
  currencies: Array<Currency>;
  currency?: Maybe<Currency>;
  currencyByIsoCode?: Maybe<Currency>;
  /** Get a discount curve by id */
  curve?: Maybe<Curve>;
  /**
   * Fetches all discount curves, paginated.
   * If includeInactive is set to true, deleted and expired curves are returned as well
   */
  curves: Array<Curve>;
  /**
   * Search discount curves by name or description
   * Returned curves contain all words from the text in any order
   * If includeInactive is set to true, deleted and expired curves are returned as well
   */
  findCurves: Array<Curve>;
  /** Fetches all geo hierarchy entities (optionally) paginated and (optionally) filtered. */
  geoHierarchies: Array<GeoHierarchy>;
  /**
   * Given a set of spaceman locations and a grouping key, return summary statistics for each one of
   * the passed locations grouped by the value of the key. See `PriceSummaryGrouping` for groupings.
   * To filter groups, pass an optional groupValues parameter.
   */
  groupedLocationPriceSummary: Array<GroupedLocationPriceSummary>;
  /** Find a dedicated space item by ID */
  inventoryItem?: Maybe<InventoryItem>;
  /** Fetches all dedicated space items, paginated */
  inventoryItems: Array<InventoryItem>;
  /**
   * Fetches inventory prices for a set of inputs.
   * It is assumed that the inventory ids are unique, so the returned prices can be mapped to inputs by inventory id.
   */
  inventoryPrices: Array<InventoryPriceResponse>;
  /** Find a location by ID */
  location?: Maybe<Location>;
  /** Returns list of locations of selected group */
  locationActionItems: Array<LocationActionItem>;
  locationBreakEvens: Array<LocationBreakEvenDetail>;
  /**
   * Given a set of spaceman locations, return summary statistics for each one of
   * the passed locations.
   */
  locationPriceSummary: Array<LocationPriceSummary>;
  /** Fetches all locations, paginated */
  locations: Array<Location>;
  /** Fetches locations given a list of locations ids. */
  locationsByIds: Array<Location>;
  /** Fetches all locations by market */
  locationsByMarket: Array<Location>;
  locationsByName: Array<Location>;
  /** Find a market by ID */
  market?: Maybe<Market>;
  marketSgAndA: Array<SgAndAForMarket>;
  /** Fetches all market, paginated */
  markets: Array<Market>;
  /** Fetches all market by territory */
  marketsByTerritory: Array<Market>;
  /** Find a priceable item by ID */
  priceableItem?: Maybe<PriceableItem>;
  /** Fetches all priceable item, paginated and optionally filtered */
  priceableItems: Array<PriceableItem>;
  /**
   * Fetches product price for a set of price requests.
   * "refUUIDs" is deprecated and will soon be removed in favor of "contexts".
   */
  productPrices: Array<ProductPrice>;
  productsByBusinessLine: Array<Product>;
  /** Find a region by ID */
  region?: Maybe<Region>;
  /** Fetches all regions, paginated */
  regions: Array<Region>;
  summaryForLocations: Array<GroupSummary>;
  /** Fetches all term types, paginated */
  termTypes: Array<TermType>;
  /** Fetches all territory, paginated */
  territories: Array<Territory>;
  /** Fetches all territory by region */
  territoriesByRegion: Array<Territory>;
  /** Find a territory by ID */
  territory?: Maybe<Territory>;
  /** Returns a total number of priceable items that satisfy the filter criteria */
  totalPriceableItems: Scalars['Int']['output'];
  /** Returns a total number of working prices that satisfy the filter criteria */
  totalWorkingPrices: Scalars['Int']['output'];
  /** Returns all operators available for the current user */
  userOperators: Array<Operator>;
  /** Fetches all working price previews, paginated and optionally filtered */
  workingPricePreviews: Array<WorkingPricePreview>;
};

export type QueryBreakevenChangeReasonsArgs = {
  page?: InputMaybe<PageInfo>;
};

export type QueryCurrenciesArgs = {
  page?: InputMaybe<PageInfo>;
};

export type QueryCurrencyArgs = {
  id: Scalars['ID']['input'];
};

export type QueryCurrencyByIsoCodeArgs = {
  isoCode: Scalars['String']['input'];
};

export type QueryCurveArgs = {
  id: Scalars['UUID']['input'];
};

export type QueryCurvesArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<PageInfo>;
};

export type QueryFindCurvesArgs = {
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
  text: Scalars['String']['input'];
};

export type QueryGeoHierarchiesArgs = {
  filter?: InputMaybe<GeoHierarchyFilter>;
  page?: InputMaybe<PageInfo>;
};

export type QueryGroupedLocationPriceSummaryArgs = {
  groupValues?: InputMaybe<Array<Scalars['String']['input']>>;
  grouping: PriceSummaryGrouping;
  locationIDs: Array<Scalars['UUID']['input']>;
};

export type QueryInventoryItemArgs = {
  refUUID: Scalars['UUID']['input'];
};

export type QueryInventoryItemsArgs = {
  filter?: InputMaybe<InventoryFilter>;
  page?: InputMaybe<PageInfo>;
};

export type QueryInventoryPricesArgs = {
  inputs: Array<InventoryPriceInput>;
};

export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
};

export type QueryLocationActionItemsArgs = {
  group: ActionItemGroup;
  page: PageInfo;
};

export type QueryLocationBreakEvensArgs = {
  geoLocationIds: Array<Scalars['ID']['input']>;
};

export type QueryLocationPriceSummaryArgs = {
  ids: Array<Scalars['UUID']['input']>;
};

export type QueryLocationsArgs = {
  page?: InputMaybe<PageInfo>;
};

export type QueryLocationsByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type QueryLocationsByMarketArgs = {
  marketId: Scalars['ID']['input'];
};

export type QueryLocationsByNameArgs = {
  name: Scalars['String']['input'];
};

export type QueryMarketArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMarketSgAndAArgs = {
  marketIds: Array<Scalars['ID']['input']>;
  workingLocationIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryMarketsArgs = {
  page?: InputMaybe<PageInfo>;
};

export type QueryMarketsByTerritoryArgs = {
  territoryId: Scalars['ID']['input'];
};

export type QueryPriceableItemArgs = {
  id: Scalars['ID']['input'];
};

export type QueryPriceableItemsArgs = {
  filter?: InputMaybe<PriceableItemFilter>;
  page?: InputMaybe<PageInfo>;
};

export type QueryProductPricesArgs = {
  contexts?: Array<ProductPriceContext>;
  productUUID: Scalars['UUID']['input'];
  refUUIDs?: Array<Scalars['UUID']['input']>;
};

export type QueryProductsByBusinessLineArgs = {
  businessLine: BusinessLine;
};

export type QueryRegionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryRegionsArgs = {
  page?: InputMaybe<PageInfo>;
};

export type QuerySummaryForLocationsArgs = {
  locations: Array<Scalars['ID']['input']>;
};

export type QueryTermTypesArgs = {
  page?: InputMaybe<PageInfo>;
};

export type QueryTerritoriesArgs = {
  page?: InputMaybe<PageInfo>;
};

export type QueryTerritoriesByRegionArgs = {
  regionId: Scalars['ID']['input'];
};

export type QueryTerritoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryTotalPriceableItemsArgs = {
  filter?: InputMaybe<PriceableItemFilter>;
};

export type QueryTotalWorkingPricesArgs = {
  workingPricePreviewInput: WorkingPricePreviewInput;
};

export type QueryWorkingPricePreviewsArgs = {
  page?: InputMaybe<PageInfo>;
  workingPricePreviewInput: WorkingPricePreviewInput;
};

/**
 * one of the most important features driving the recommendation
 * the ordinal field indicates importance, lower numbers being more important.
 */
export type RecommendationFeature = {
  __typename?: 'RecommendationFeature';
  /** the importance of the value, 1 based. Lower values are more important. */
  ordinal: Scalars['Int']['output'];
  /** the value (name) of the feature */
  value: Scalars['String']['output'];
};

export type Region = {
  __typename?: 'Region';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** An entity used for reservable metrics relevant to the price process. */
export type ReservableMetrics = {
  __typename?: 'ReservableMetrics';
  /** True if reservable is occupied in current month. Will be NULL if reservable is not open yet. */
  isOccupied?: Maybe<Scalars['Boolean']['output']>;
  /** True if reservable is occupied in next 3 month. Will be NULL if reservable is not open yet. */
  isOccupiedNext3m?: Maybe<Scalars['Boolean']['output']>;
  /** True if reservable is occupied in next 6 month. Will be NULL if reservable is not open yet. */
  isOccupiedNext6m?: Maybe<Scalars['Boolean']['output']>;
  /** True if reservable is occupied in previous 3 month. Will be NULL if reservable is not open yet. */
  isOccupiedPrev3m?: Maybe<Scalars['Boolean']['output']>;
  /** True if reservable is occupied in previous 6 month. Will be NULL if reservable is not open yet. */
  isOccupiedPrev6m?: Maybe<Scalars['Boolean']['output']>;
  /**
   * If reservable is open and unoccupied, it is the number of months since the last member moved out and the current
   * date
   */
  monthsVacant?: Maybe<Scalars['Int']['output']>;
  /**
   * Gross reservation price (excluding discounts) in local currency for the current month. Will be Null if reservable is
   * not occupied
   */
  reservationPriceLocal?: Maybe<Scalars['Float']['output']>;
};

export type SgAndAForMarket = {
  __typename?: 'SgAndAForMarket';
  marketId: Scalars['UUID']['output'];
  sgAndA?: Maybe<Scalars['Float']['output']>;
};

/** An entity used for sku occupancy aggregation. */
export type SkuOccupancy = {
  __typename?: 'SkuOccupancy';
  /** Next 3 months occupancy */
  next3mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** Next 6 months occupancy */
  next6mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** Current Occupancy */
  occupancy?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  /** Past 3 months occupancy */
  trailing3mOccupancy?: Maybe<Scalars['Float']['output']>;
  /** Past 6 months occupancy */
  trailing6mOccupancy?: Maybe<Scalars['Float']['output']>;
};

export type SummaryInfo = {
  __typename?: 'SummaryInfo';
  avg?: Maybe<Scalars['Float']['output']>;
  key: Array<Scalars['String']['output']>;
};

/**
 * An object used for term types.
 * ex: M2M, 6M, 12M, 24M, 36M
 */
export type TermType = {
  __typename?: 'TermType';
  baseMaxDiscount: Scalars['Float']['output'];
  customLength?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  length: Scalars['Float']['output'];
};

export type Territory = {
  __typename?: 'Territory';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  region: Region;
};

export enum Windows {
  NO_WINDOWS = 'NO_WINDOWS',
  WINDOWS = 'WINDOWS',
}

export type WorkingBreakEvenOverrideInput = {
  deskCapacity?: InputMaybe<Scalars['Int']['input']>;
  desksInYearMonths?: InputMaybe<Scalars['Int']['input']>;
  includedSGA?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  opex?: InputMaybe<Scalars['Float']['input']>;
  rent?: InputMaybe<Scalars['Float']['input']>;
  rsf?: InputMaybe<Scalars['Float']['input']>;
  sgAndA?: InputMaybe<Scalars['Float']['input']>;
  showBreakEven?: InputMaybe<Scalars['Boolean']['input']>;
  steadyStateOcc?: InputMaybe<Scalars['Float']['input']>;
  tenancyNNN?: InputMaybe<Scalars['Float']['input']>;
  totalBudgetRevenue?: InputMaybe<Scalars['Float']['input']>;
};

/** Updates working prices given price,  location, sku and attributes. */
export type WorkingByGeoAttrSkuPriceInput = {
  geoLocationId: Scalars['UUID']['input'];
  hasInternalRooms: InternalRooms;
  hasWindows: Windows;
  price: Scalars['Float']['input'];
  sku: Scalars['String']['input'];
};

export type WorkingLiveBudgetAppliedInput = {
  deskCapacity?: InputMaybe<Scalars['Int']['input']>;
  desksInYearMonths?: InputMaybe<Scalars['Int']['input']>;
  opex?: InputMaybe<Scalars['Float']['input']>;
  rent?: InputMaybe<Scalars['Float']['input']>;
  rsf?: InputMaybe<Scalars['Float']['input']>;
  steadyStateOcc?: InputMaybe<Scalars['Float']['input']>;
  tenancyNNN?: InputMaybe<Scalars['Float']['input']>;
  totalBudgetRevenue?: InputMaybe<Scalars['Float']['input']>;
};

export type WorkingLocationBreakEvenInput = {
  geoLocationUUID: Scalars['ID']['input'];
  workingBreakEvenOverrideInput?: InputMaybe<WorkingBreakEvenOverrideInput>;
  workingLiveBudgetAppliedInput?: InputMaybe<WorkingLiveBudgetAppliedInput>;
};

/** A market price not yet published. When it is published it becomes a market price. */
export type WorkingPrice = {
  __typename?: 'WorkingPrice';
  /** All price changes stored for this working price known to the system. */
  changeLog: Array<MarketPriceChange>;
  /** user who did the change */
  createdBy?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** reason for the price change */
  lastChangeReason?: Maybe<Scalars['String']['output']>;
  /** ARPM */
  price: Scalars['Float']['output'];
  recommended?: Maybe<Scalars['Float']['output']>;
};

export type WorkingPriceHierarchy = {
  __typename?: 'WorkingPriceHierarchy';
  changelog: Array<PriceHierarchyChange>;
  createdAt: Scalars['Instant']['output'];
  createdBy: Scalars['String']['output'];
  currencyIsoCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
  productId: Scalars['UUID']['output'];
};

export type WorkingPriceInput = {
  /** reason for the price change */
  lastChangeReason?: InputMaybe<Scalars['String']['input']>;
  /** ARPM */
  price: Scalars['Float']['input'];
  priceableItemId: Scalars['UUID']['input'];
  recommended?: InputMaybe<Scalars['Float']['input']>;
};

/** Preview of what the working price will look like after published */
export type WorkingPricePreview = {
  __typename?: 'WorkingPricePreview';
  /** capacity */
  capacity?: Maybe<Scalars['Int']['output']>;
  currencyIsoCode: Scalars['String']['output'];
  /** the rounding factor applied */
  factor?: Maybe<Scalars['Int']['output']>;
  /** the priceableItem which has a working price */
  priceableItem: PriceableItem;
  /** the working price, converted to list price and rounded */
  roundedListPrice?: Maybe<Scalars['Int']['output']>;
};

export type WorkingPricePreviewInput = {
  ids: Array<Scalars['ID']['input']>;
  scope: WorkingPriceScope;
};

export type WorkingPriceResetResult = {
  __typename?: 'WorkingPriceResetResult';
  details?: Maybe<Scalars['String']['output']>;
  isReset: Scalars['Boolean']['output'];
  priceableItemId: Scalars['UUID']['output'];
};

/** Working Price Scope: you can publish or reset a whole location's prices */
export enum WorkingPriceScope {
  LOCATION = 'LOCATION',
  PRICEABLE_ITEM = 'PRICEABLE_ITEM',
}

export type LocationActionItemsQueryVariables = Exact<{
  group: ActionItemGroup;
  page: PageInfo;
}>;

export type LocationActionItemsQuery = {
  __typename?: 'Query';
  locationActionItems: Array<{
    __typename?: 'LocationActionItem';
    locationId: any;
    locationName: string;
    count?: number | null;
  }>;
};

export type WorkingLocationBreakEvenMutationVariables = Exact<{
  workingLocationBreakEvenInput: WorkingLocationBreakEvenInput;
}>;

export type WorkingLocationBreakEvenMutation = {
  __typename?: 'Mutation';
  workingLocationBreakEven: {
    __typename?: 'GenericResponse';
    success: boolean;
    errors?: string | null;
  };
};

export type PublishLocationBreakEvenDataMutationVariables = Exact<{
  locationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  validFrom?: InputMaybe<Scalars['Date']['input']>;
}>;

export type PublishLocationBreakEvenDataMutation = {
  __typename?: 'Mutation';
  publishLocationBreakEvenData: {
    __typename?: 'LocationBreakEvenPublishResponse';
    success: boolean;
    errors?: string | null;
  };
};

export type MarketSgAndAQueryVariables = Exact<{
  marketIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  workingLocationIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type MarketSgAndAQuery = {
  __typename?: 'Query';
  marketSgAndA: Array<{ __typename?: 'SgAndAForMarket'; marketId: any; sgAndA?: number | null }>;
};

export type LocationBreakEvensQueryVariables = Exact<{
  geoLocationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type LocationBreakEvensQuery = {
  __typename?: 'Query';
  locationBreakEvens: Array<{
    __typename?: 'LocationBreakEvenDetail';
    location: {
      __typename?: 'Location';
      currencyIsoCode: string;
      timeZone: string;
      name: string;
      id: string;
      market: {
        __typename?: 'Market';
        id: string;
        name: string;
        territory: {
          __typename?: 'Territory';
          name: string;
          region: { __typename?: 'Region'; name: string };
        };
      };
    };
    current?: {
      __typename?: 'CurrentLocationBreakEven';
      breakEvenPrice?: number | null;
      rsf?: number | null;
      deskCapacity?: number | null;
      rent?: number | null;
      tenancyNNN?: number | null;
      opex?: number | null;
      totalBudgetRevenue?: number | null;
      sgAndA?: number | null;
      desksInYearMonths?: number | null;
      notes?: string | null;
      includedSGA: boolean;
      showBreakEven: boolean;
      steadyStateOcc?: number | null;
      validFrom: any;
      publishedAt: any;
      override?: {
        __typename?: 'LocationBreakEvenOverride';
        rsf?: number | null;
        deskCapacity?: number | null;
        rent?: number | null;
        tenancyNNN?: number | null;
        desksInYearMonths?: number | null;
        opex?: number | null;
        totalBudgetRevenue?: number | null;
        steadyStateOcc?: number | null;
        sgAndA?: number | null;
        includedSGA?: boolean | null;
        showBreakEven?: boolean | null;
        notes?: string | null;
      } | null;
    } | null;
    liveBudget?: {
      __typename?: 'LiveBudget';
      breakEvenPrice?: number | null;
      rsf?: number | null;
      deskCapacity?: number | null;
      rent?: number | null;
      tenancyNNN?: number | null;
      opex?: number | null;
      totalBudgetRevenue?: number | null;
      sgAndA?: number | null;
      desksInYearMonths?: number | null;
      steadyStateOcc?: number | null;
      updatedAt: any;
    } | null;
    override?: {
      __typename?: 'LocationBreakEvenOverride';
      rsf?: number | null;
      deskCapacity?: number | null;
      rent?: number | null;
      tenancyNNN?: number | null;
      opex?: number | null;
      totalBudgetRevenue?: number | null;
      desksInYearMonths?: number | null;
      steadyStateOcc?: number | null;
      sgAndA?: number | null;
      includedSGA?: boolean | null;
      showBreakEven?: boolean | null;
      notes?: string | null;
    } | null;
    liveBudgetApplied?: {
      __typename?: 'LiveBudgetApplied';
      rsf?: number | null;
      deskCapacity?: number | null;
      rent?: number | null;
      tenancyNNN?: number | null;
      opex?: number | null;
      totalBudgetRevenue?: number | null;
      desksInYearMonths?: number | null;
      steadyStateOcc?: number | null;
    } | null;
  }>;
};

export type PublishOverridesMutationVariables = Exact<{
  overrides?: InputMaybe<Array<DiscountOverrideInput> | DiscountOverrideInput>;
  expirationDate?: InputMaybe<Scalars['Date']['input']>;
  publishDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type PublishOverridesMutation = {
  __typename?: 'Mutation';
  publishOverrides: { __typename?: 'GenericResponse'; errors?: string | null; success: boolean };
};

export type ApplyCurvesMutationVariables = Exact<{
  input: DiscountCurveInput;
}>;

export type ApplyCurvesMutation = {
  __typename?: 'Mutation';
  applyCurves: Array<{
    __typename?: 'DiscountCurveResult';
    priceableItemId?: any | null;
    curveId?: any | null;
    errors?: string | null;
    success: boolean;
    appliedCurve?:
      | ({ __typename?: 'GridDiscountCurve' } & {
          ' $fragmentRefs'?: { AppliedCurveFragment: AppliedCurveFragment };
        })
      | null;
  }>;
};

export type RemoveCurvesMutationVariables = Exact<{
  input: DiscountCurveInput;
}>;

export type RemoveCurvesMutation = {
  __typename?: 'Mutation';
  removeCurves: Array<{
    __typename?: 'DiscountCurveResult';
    priceableItemId?: any | null;
    curveId?: any | null;
    errors?: string | null;
    success: boolean;
  }>;
};

export type CreateCurveMutationVariables = Exact<{
  curveInput: CurveInput;
  operatorId: Scalars['String']['input'];
  linkedCurveId?: InputMaybe<Scalars['UUID']['input']>;
}>;

export type CreateCurveMutation = {
  __typename?: 'Mutation';
  createCurve: { __typename?: 'GenericResponse'; errors?: string | null; success: boolean };
};

export type UpdateCurveMutationVariables = Exact<{
  curveId: Scalars['UUID']['input'];
  curveInput: CurveInput;
}>;

export type UpdateCurveMutation = {
  __typename?: 'Mutation';
  updateCurve: { __typename?: 'GenericResponse'; errors?: string | null; success: boolean };
};

export type DeleteCurveMutationVariables = Exact<{
  curveId: Scalars['UUID']['input'];
}>;

export type DeleteCurveMutation = {
  __typename?: 'Mutation';
  deleteCurve: { __typename?: 'GenericResponse'; errors?: string | null; success: boolean };
};

export type AppliedCurveFragment = {
  __typename?: 'GridDiscountCurve';
  appliedAt: any;
  appliedBy: string;
  curve: {
    __typename?: 'Curve';
    id: any;
    name: string;
    expirationDate?: any | null;
    createdBy: string;
    lastUpdatedBy?: string | null;
    updatedAt: any;
    curveTerms: Array<{
      __typename?: 'CurveTerm';
      term: { __typename?: 'TermType'; id: string; label: string };
      dataPoints: Array<{ __typename?: 'CurveDataPoint'; month: number; value: number }>;
    }>;
  };
} & { ' $fragmentName'?: 'AppliedCurveFragment' };

export type GridDiscountOverrideFragment = {
  __typename?: 'GridDiscountOverride';
  base?: number | null;
  expirationDate: any;
  publishDate: any;
  createdBy: string;
} & { ' $fragmentName'?: 'GridDiscountOverrideFragment' };

export type GridBaseDiscountOverrideFragment = {
  __typename?: 'GridBaseDiscount';
  base: number;
  publishDate: any;
  createdBy: string;
} & { ' $fragmentName'?: 'GridBaseDiscountOverrideFragment' };

export type GridDiscountItemsQueryVariables = Exact<{
  page: PageInfo;
  filter?: InputMaybe<PriceableItemFilter>;
}>;

export type GridDiscountItemsQuery = {
  __typename?: 'Query';
  priceableItems: Array<{
    __typename?: 'PriceableItem';
    id: string;
    name: string;
    attributes: Array<{
      __typename?: 'PriceableItemAttribute';
      label: string;
      value?: string | null;
    }>;
    location: {
      __typename?: 'Location';
      id: string;
      timeZone: string;
      operators: Array<{ __typename?: 'Operator'; id: string; name: string }>;
    };
    currentPrice?: {
      __typename?: 'MarketPrice';
      marketPrice: number;
      currencyIsoCode: string;
    } | null;
    metrics?: {
      __typename?: 'ReservableMetrics';
      reservationPriceLocal?: number | null;
      isOccupied?: boolean | null;
      monthsVacant?: number | null;
    } | null;
    gridDiscounts: Array<{
      __typename?: 'GridDiscount';
      termType: { __typename?: 'TermType'; id: string; label: string; length: number };
      baseDiscount?:
        | ({ __typename?: 'GridBaseDiscount' } & {
            ' $fragmentRefs'?: {
              GridBaseDiscountOverrideFragment: GridBaseDiscountOverrideFragment;
            };
          })
        | null;
      futureBaseDiscount?:
        | ({ __typename?: 'GridBaseDiscount' } & {
            ' $fragmentRefs'?: {
              GridBaseDiscountOverrideFragment: GridBaseDiscountOverrideFragment;
            };
          })
        | null;
      override?:
        | ({ __typename?: 'GridDiscountOverride' } & {
            ' $fragmentRefs'?: { GridDiscountOverrideFragment: GridDiscountOverrideFragment };
          })
        | null;
      futureOverride?:
        | ({ __typename?: 'GridDiscountOverride'; updatedAt: any } & {
            ' $fragmentRefs'?: { GridDiscountOverrideFragment: GridDiscountOverrideFragment };
          })
        | null;
    }>;
    appliedCurves: Array<
      { __typename?: 'GridDiscountCurve' } & {
        ' $fragmentRefs'?: { AppliedCurveFragment: AppliedCurveFragment };
      }
    >;
  }>;
};

export type DiscountItemsTermTypesQueryVariables = Exact<{
  page: PageInfo;
}>;

export type DiscountItemsTermTypesQuery = {
  __typename?: 'Query';
  termTypes: Array<{
    __typename?: 'TermType';
    id: string;
    label: string;
    length: number;
    baseMaxDiscount: number;
  }>;
};

export type FindCurvesQueryVariables = Exact<{
  text: Scalars['String']['input'];
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type FindCurvesQuery = {
  __typename?: 'Query';
  findCurves: Array<{
    __typename?: 'Curve';
    id: any;
    name: string;
    description?: string | null;
    expirationDate?: any | null;
    isActive: boolean;
    appliedCount: number;
    operator: { __typename?: 'Operator'; id: string; name: string };
    curveTerms: Array<{
      __typename?: 'CurveTerm';
      term: { __typename?: 'TermType'; id: string; label: string };
      dataPoints: Array<{ __typename?: 'CurveDataPoint'; month: number; value: number }>;
    }>;
  }>;
};

export type CurvesQueryVariables = Exact<{
  page: PageInfo;
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type CurvesQuery = {
  __typename?: 'Query';
  curves: Array<{
    __typename?: 'Curve';
    id: any;
    name: string;
    description?: string | null;
    expirationDate?: any | null;
    appliedCount: number;
    createdBy: string;
    createdAt: any;
    lastUpdatedBy?: string | null;
    updatedAt: any;
    isActive: boolean;
    operator: { __typename?: 'Operator'; id: string; name: string };
    curveTerms: Array<{
      __typename?: 'CurveTerm';
      term: { __typename?: 'TermType'; id: string; label: string; length: number };
      dataPoints: Array<{ __typename?: 'CurveDataPoint'; month: number; value: number }>;
    }>;
  }>;
};

export type OperatorsQueryVariables = Exact<{ [key: string]: never }>;

export type OperatorsQuery = {
  __typename?: 'Query';
  userOperators: Array<{ __typename?: 'Operator'; id: string; name: string }>;
};

export type DiscountHistoryQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type DiscountHistoryQuery = {
  __typename?: 'Query';
  priceableItem?: {
    __typename?: 'PriceableItem';
    discountsHistory: Array<{
      __typename?: 'DiscountHistory';
      base: number;
      publishDate: any;
      expirationDate?: any | null;
      createdBy: string;
      termType: { __typename?: 'TermType'; id: string; label: string };
    }>;
  } | null;
};

export type CancelFutureDiscountsMutationVariables = Exact<{
  cancelFutureDiscountInput: CancelFutureDiscountInput;
}>;

export type CancelFutureDiscountsMutation = {
  __typename?: 'Mutation';
  cancelFutureDiscounts: {
    __typename?: 'GenericResponse';
    success: boolean;
    errors?: string | null;
  };
};

export type PriceHierarchyFragment = {
  __typename?: 'PriceHierarchy';
  id: string;
  price?: number | null;
  currencyIsoCode: string;
  validFrom: any;
  createdBy: string;
  publishedAt: any;
  productId: any;
} & { ' $fragmentName'?: 'PriceHierarchyFragment' };

export type WorkingPriceSubsetHierarchyFragment = {
  __typename?: 'WorkingPriceHierarchy';
  id: string;
  price?: number | null;
  currencyIsoCode: string;
  productId: any;
} & { ' $fragmentName'?: 'WorkingPriceSubsetHierarchyFragment' };

export type GeoHierarchiesQueryVariables = Exact<{
  productIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
}>;

export type GeoHierarchiesQuery = {
  __typename?: 'Query';
  geoHierarchies: Array<{
    __typename?: 'GeoHierarchy';
    id: any;
    name: string;
    timeZone?: string | null;
    currencyIsoCode: string;
    parentId?: any | null;
    type: string;
    currentPrices: Array<
      { __typename?: 'PriceHierarchy' } & {
        ' $fragmentRefs'?: { PriceHierarchyFragment: PriceHierarchyFragment };
      }
    >;
    futurePrices: Array<
      { __typename?: 'PriceHierarchy' } & {
        ' $fragmentRefs'?: { PriceHierarchyFragment: PriceHierarchyFragment };
      }
    >;
    workingPrices: Array<
      { __typename?: 'WorkingPriceHierarchy' } & {
        ' $fragmentRefs'?: {
          WorkingPriceSubsetHierarchyFragment: WorkingPriceSubsetHierarchyFragment;
        };
      }
    >;
    pricePreviews: Array<{
      __typename?: 'PricePreview';
      id: string;
      price: number;
      type: string;
      productId: any;
    }>;
    attributePreviews: Array<{
      __typename?: 'GeoHierarchyAttributePreview';
      label: GeoHierarchyAttributeLabel;
      value: string;
      type: string;
      productId: any;
    }>;
  }>;
};

export type ProductsQueryVariables = Exact<{ [key: string]: never }>;

export type ProductsQuery = {
  __typename?: 'Query';
  productsByBusinessLine: Array<{ __typename?: 'Product'; id: string; name: string }>;
};

export type WorkingPriceHierarchyFragment = {
  __typename?: 'WorkingPriceHierarchy';
  id: string;
  price?: number | null;
  currencyIsoCode: string;
  createdAt: any;
  createdBy: string;
  productId: any;
  changelog: Array<{
    __typename?: 'PriceHierarchyChange';
    id: string;
    price?: number | null;
    currencyIsoCode: string;
    createdBy: string;
    createdAt: any;
  }>;
} & { ' $fragmentName'?: 'WorkingPriceHierarchyFragment' };

export type GeoHierarchiesChangeLogQueryVariables = Exact<{
  filter?: InputMaybe<GeoHierarchyFilter>;
  productIds?: InputMaybe<Array<Scalars['UUID']['input']> | Scalars['UUID']['input']>;
}>;

export type GeoHierarchiesChangeLogQuery = {
  __typename?: 'Query';
  geoHierarchies: Array<{
    __typename?: 'GeoHierarchy';
    id: any;
    name: string;
    workingPrices: Array<
      { __typename?: 'WorkingPriceHierarchy' } & {
        ' $fragmentRefs'?: { WorkingPriceHierarchyFragment: WorkingPriceHierarchyFragment };
      }
    >;
  }>;
};

export type GeoHierarchiesPriceHistoryQueryVariables = Exact<{
  filter?: InputMaybe<GeoHierarchyFilter>;
}>;

export type GeoHierarchiesPriceHistoryQuery = {
  __typename?: 'Query';
  geoHierarchies: Array<{
    __typename?: 'GeoHierarchy';
    id: any;
    name: string;
    timeZone?: string | null;
    currencyIsoCode: string;
    parentId?: any | null;
    priceHistory: Array<
      { __typename?: 'PriceHierarchy' } & {
        ' $fragmentRefs'?: { PriceHierarchyFragment: PriceHierarchyFragment };
      }
    >;
  }>;
};

export type SaveWorkingPriceHierarchyMutationVariables = Exact<{
  priceHierarchy: Array<PriceHierarchyInput> | PriceHierarchyInput;
}>;

export type SaveWorkingPriceHierarchyMutation = {
  __typename?: 'Mutation';
  saveWorkingPriceHierarchy: {
    __typename?: 'GenericResponse';
    success: boolean;
    errors?: string | null;
    code?: number | null;
  };
};

export type PublishWorkingPriceHierarchyMutationVariables = Exact<{
  priceHierarchyIds: Array<PriceHierarchyIdInput> | PriceHierarchyIdInput;
  publishDate?: InputMaybe<Scalars['Date']['input']>;
}>;

export type PublishWorkingPriceHierarchyMutation = {
  __typename?: 'Mutation';
  publishWorkingPriceHierarchy: {
    __typename?: 'GenericResponse';
    success: boolean;
    errors?: string | null;
  };
};

export type CancelFuturePriceHierarchyMutationVariables = Exact<{
  priceHierarchyIds: Array<PriceHierarchyIdInput> | PriceHierarchyIdInput;
}>;

export type CancelFuturePriceHierarchyMutation = {
  __typename?: 'Mutation';
  cancelFuturePriceHierarchy: {
    __typename?: 'GenericResponse';
    success: boolean;
    errors?: string | null;
    code?: number | null;
  };
};

export type RevertWorkingPriceHierarchyMutationVariables = Exact<{
  priceHierarchyIds: Array<PriceHierarchyIdInput> | PriceHierarchyIdInput;
}>;

export type RevertWorkingPriceHierarchyMutation = {
  __typename?: 'Mutation';
  revertWorkingPriceHierarchy: {
    __typename?: 'GenericResponse';
    success: boolean;
    errors?: string | null;
    code?: number | null;
  };
};

export type UpdateGeoHierarchyAttributesMutationVariables = Exact<{
  geoGroupingIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  productIds: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  label: GeoHierarchyAttributeLabel;
  newValue?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateGeoHierarchyAttributesMutation = {
  __typename?: 'Mutation';
  updateGeoHierarchyAttributes: {
    __typename?: 'GenericResponse';
    success: boolean;
    errors?: string | null;
    code?: number | null;
  };
};

export type PublishWorkingPricesMutationVariables = Exact<{
  publishWorkingPriceInput: PublishWorkingPriceInput;
}>;

export type PublishWorkingPricesMutation = {
  __typename?: 'Mutation';
  publishWorkingPrices: {
    __typename?: 'GenericResponse';
    errors?: string | null;
    success: boolean;
  };
};

export type PriceableItemQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PriceableItemQuery = {
  __typename?: 'Query';
  priceableItem?: {
    __typename?: 'PriceableItem';
    currentPrice?: { __typename?: 'MarketPrice'; currencyIsoCode: string } | null;
    workingPrice?: {
      __typename?: 'WorkingPrice';
      changeLog: Array<{
        __typename?: 'MarketPriceChange';
        id: string;
        price: number;
        createdAt: any;
        createdBy?: string | null;
      }>;
    } | null;
  } | null;
};

export type PriceableItemFeatureQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;

export type PriceableItemFeatureQuery = {
  __typename?: 'Query';
  priceableItem?: {
    __typename?: 'PriceableItem';
    location: { __typename?: 'Location'; id: string; timeZone: string };
    currentPrice?: {
      __typename?: 'MarketPrice';
      currencyIsoCode: string;
      changeLog: Array<{
        __typename?: 'MarketPriceChange';
        id: string;
        price: number;
        createdAt: any;
        createdBy?: string | null;
      }>;
    } | null;
    prices: Array<{
      __typename?: 'MarketPrice';
      id: string;
      price: number;
      marketPrice: number;
      currencyIsoCode: string;
      validFrom: any;
      publishedAt: any;
      createdBy?: string | null;
      lastChangeReason?: string | null;
    }>;
    recommendedPrice?: {
      __typename?: 'PriceRecommendation';
      date: any;
      price: number;
      minPrice?: number | null;
      maxPrice?: number | null;
      confidenceLevel?: string | null;
      features: Array<{ __typename?: 'RecommendationFeature'; ordinal: number; value: string }>;
    } | null;
  } | null;
};

export type WorkingPricesQueryVariables = Exact<{
  workingPricePreviewInput: WorkingPricePreviewInput;
  page: PageInfo;
}>;

export type WorkingPricesQuery = {
  __typename?: 'Query';
  workingPricePreviews: Array<{
    __typename?: 'WorkingPricePreview';
    roundedListPrice?: number | null;
    currencyIsoCode: string;
    priceableItem: {
      __typename?: 'PriceableItem';
      id: string;
      name: string;
      attributes: Array<{
        __typename?: 'PriceableItemAttribute';
        label: string;
        value?: string | null;
      }>;
      currentPrice?: { __typename?: 'MarketPrice'; price: number } | null;
      workingPrice?: {
        __typename?: 'WorkingPrice';
        id: string;
        price: number;
        createdBy?: string | null;
        lastChangeReason?: string | null;
      } | null;
      location: {
        __typename?: 'Location';
        id: string;
        name: string;
        timeZone: string;
        market: {
          __typename?: 'Market';
          name: string;
          territory: {
            __typename?: 'Territory';
            name: string;
            region: { __typename?: 'Region'; name: string };
          };
        };
      };
    };
  }>;
};

export type TotalWorkingPricesQueryVariables = Exact<{
  workingPricePreviewInput: WorkingPricePreviewInput;
}>;

export type TotalWorkingPricesQuery = { __typename?: 'Query'; totalWorkingPrices: number };

export type SaveWorkingPricesMutationVariables = Exact<{
  workingPriceInputs: Array<WorkingPriceInput> | WorkingPriceInput;
}>;

export type SaveWorkingPricesMutation = {
  __typename?: 'Mutation';
  saveWorkingPrices: { __typename?: 'GenericResponse'; errors?: string | null; success: boolean };
};

export type ResetPricesMutationVariables = Exact<{
  ids: Array<Scalars['UUID']['input']> | Scalars['UUID']['input'];
  scope: WorkingPriceScope;
}>;

export type ResetPricesMutation = {
  __typename?: 'Mutation';
  resetPricesToLastPublished: Array<{
    __typename?: 'WorkingPriceResetResult';
    isReset: boolean;
    details?: string | null;
    priceableItemId: any;
  }>;
};

export type CancelPricesMutationVariables = Exact<{
  cancelFuturePriceInput: CancelFuturePriceInput;
}>;

export type CancelPricesMutation = {
  __typename?: 'Mutation';
  cancelFuturePrices: { __typename?: 'GenericResponse'; success: boolean; errors?: string | null };
};

export type StandardPricingItemsQueryVariables = Exact<{
  page: PageInfo;
  filter?: InputMaybe<PriceableItemFilter>;
  priceFilter?: InputMaybe<PriceFilter>;
}>;

export type StandardPricingItemsQuery = {
  __typename?: 'Query';
  priceableItems: Array<{
    __typename?: 'PriceableItem';
    id: string;
    name: string;
    attributes: Array<{
      __typename?: 'PriceableItemAttribute';
      label: string;
      value?: string | null;
    }>;
    currentPrice?: {
      __typename?: 'MarketPrice';
      price: number;
      publishedAt: any;
      validFrom: any;
      currencyIsoCode: string;
    } | null;
    workingPrice?: {
      __typename?: 'WorkingPrice';
      id: string;
      price: number;
      lastChangeReason?: string | null;
    } | null;
    prices: Array<{
      __typename?: 'MarketPrice';
      id: string;
      price: number;
      publishedAt: any;
      validFrom: any;
      createdBy?: string | null;
    }>;
    recommendedPrice?: { __typename?: 'PriceRecommendation'; price: number } | null;
    metrics?: {
      __typename?: 'ReservableMetrics';
      reservationPriceLocal?: number | null;
      isOccupied?: boolean | null;
      monthsVacant?: number | null;
    } | null;
    location: { __typename?: 'Location'; id: string };
  }>;
};

export type TotalPriceableItemsQueryVariables = Exact<{
  filter?: InputMaybe<PriceableItemFilter>;
}>;

export type TotalPriceableItemsQuery = { __typename?: 'Query'; totalPriceableItems: number };

export const AppliedCurveFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'appliedCurve' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridDiscountCurve' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curve' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curveTerms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'term' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataPoints' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedBy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AppliedCurveFragment, unknown>;
export const GridDiscountOverrideFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'gridDiscountOverride' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridDiscountOverride' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GridDiscountOverrideFragment, unknown>;
export const GridBaseDiscountOverrideFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'gridBaseDiscountOverride' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridBaseDiscount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GridBaseDiscountOverrideFragment, unknown>;
export const PriceHierarchyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'priceHierarchy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceHierarchy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceHierarchyFragment, unknown>;
export const WorkingPriceSubsetHierarchyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'workingPriceSubsetHierarchy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkingPriceHierarchy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkingPriceSubsetHierarchyFragment, unknown>;
export const WorkingPriceHierarchyFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'workingPriceHierarchy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkingPriceHierarchy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changelog' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkingPriceHierarchyFragment, unknown>;
export const LocationActionItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocationActionItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'group' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ActionItemGroup' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInfo' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationActionItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'group' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'group' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'locationId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'locationName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationActionItemsQuery, LocationActionItemsQueryVariables>;
export const WorkingLocationBreakEvenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'WorkingLocationBreakEven' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'workingLocationBreakEvenInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'WorkingLocationBreakEvenInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workingLocationBreakEven' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workingLocationBreakEvenInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workingLocationBreakEvenInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  WorkingLocationBreakEvenMutation,
  WorkingLocationBreakEvenMutationVariables
>;
export const PublishLocationBreakEvenDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishLocationBreakEvenData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locationIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'validFrom' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishLocationBreakEvenData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locationIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locationIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'validFrom' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'validFrom' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublishLocationBreakEvenDataMutation,
  PublishLocationBreakEvenDataMutationVariables
>;
export const MarketSgAndADocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MarketSgAndA' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'marketIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workingLocationIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketSgAndA' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'marketIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'marketIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workingLocationIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workingLocationIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'marketId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sgAndA' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<MarketSgAndAQuery, MarketSgAndAQueryVariables>;
export const LocationBreakEvensDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'LocationBreakEvens' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'geoLocationIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'locationBreakEvens' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'geoLocationIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'geoLocationIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'market' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'territory' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'region' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'breakEvenPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rsf' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deskCapacity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenancyNNN' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'opex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalBudgetRevenue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sgAndA' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'desksInYearMonths' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includedSGA' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showBreakEven' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'steadyStateOcc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'override' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'rsf' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'deskCapacity' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tenancyNNN' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'desksInYearMonths' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'opex' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalBudgetRevenue' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'steadyStateOcc' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sgAndA' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'includedSGA' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'showBreakEven' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveBudget' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'breakEvenPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rsf' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deskCapacity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenancyNNN' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'opex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalBudgetRevenue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sgAndA' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'desksInYearMonths' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'steadyStateOcc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'override' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rsf' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deskCapacity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenancyNNN' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'opex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalBudgetRevenue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'desksInYearMonths' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'steadyStateOcc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sgAndA' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'includedSGA' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'showBreakEven' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'notes' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'liveBudgetApplied' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'rsf' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'deskCapacity' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rent' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tenancyNNN' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'opex' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalBudgetRevenue' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'desksInYearMonths' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'steadyStateOcc' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LocationBreakEvensQuery, LocationBreakEvensQueryVariables>;
export const PublishOverridesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishOverrides' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'overrides' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscountOverrideInput' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'expirationDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishOverrides' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'overrides' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'overrides' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'expirationDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'expirationDate' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publishDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishOverridesMutation, PublishOverridesMutationVariables>;
export const ApplyCurvesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ApplyCurves' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscountCurveInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'applyCurves' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'priceableItemId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'curveId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliedCurve' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'appliedCurve' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'appliedCurve' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridDiscountCurve' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curve' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curveTerms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'term' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataPoints' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedBy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ApplyCurvesMutation, ApplyCurvesMutationVariables>;
export const RemoveCurvesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemoveCurves' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'DiscountCurveInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeCurves' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'priceableItemId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'curveId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RemoveCurvesMutation, RemoveCurvesMutationVariables>;
export const CreateCurveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCurve' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'curveInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CurveInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'operatorId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'linkedCurveId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCurve' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'curveInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'curveInput' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'operatorId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'operatorId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'linkedCurveId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'linkedCurveId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateCurveMutation, CreateCurveMutationVariables>;
export const UpdateCurveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCurve' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'curveId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'curveInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CurveInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCurve' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'curveId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'curveId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'curveInput' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'curveInput' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCurveMutation, UpdateCurveMutationVariables>;
export const DeleteCurveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCurve' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'curveId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCurve' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'curveId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'curveId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteCurveMutation, DeleteCurveMutationVariables>;
export const GridDiscountItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GridDiscountItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInfo' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceableItemFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceableItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'operators' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'marketPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationPriceLocal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOccupied' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthsVacant' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'gridDiscounts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'baseDiscount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'gridBaseDiscountOverride' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'futureBaseDiscount' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'gridBaseDiscountOverride' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'override' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'gridDiscountOverride' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'futureOverride' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'gridDiscountOverride' },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'appliedCurves' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'appliedCurve' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'gridBaseDiscountOverride' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridBaseDiscount' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'gridDiscountOverride' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridDiscountOverride' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'base' } },
          { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'appliedCurve' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridDiscountCurve' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curve' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curveTerms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'term' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataPoints' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'appliedBy' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GridDiscountItemsQuery, GridDiscountItemsQueryVariables>;
export const DiscountItemsTermTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DiscountItemsTermTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInfo' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'termTypes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                { kind: 'Field', name: { kind: 'Name', value: 'baseMaxDiscount' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscountItemsTermTypesQuery, DiscountItemsTermTypesQueryVariables>;
export const FindCurvesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FindCurves' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeInactive' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'findCurves' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'text' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeInactive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeInactive' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'appliedCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'operator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curveTerms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'term' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataPoints' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindCurvesQuery, FindCurvesQueryVariables>;
export const CurvesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Curves' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInfo' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'includeInactive' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'curves' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'includeInactive' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'includeInactive' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'appliedCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastUpdatedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isActive' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'operator' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'curveTerms' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'term' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'length' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataPoints' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurvesQuery, CurvesQueryVariables>;
export const OperatorsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Operators' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userOperators' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<OperatorsQuery, OperatorsQueryVariables>;
export const DiscountHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DiscountHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceableItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'discountsHistory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'termType' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'base' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DiscountHistoryQuery, DiscountHistoryQueryVariables>;
export const CancelFutureDiscountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelFutureDiscounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'cancelFutureDiscountInput' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelFutureDiscountInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelFutureDiscounts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cancelFutureDiscountInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cancelFutureDiscountInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelFutureDiscountsMutation, CancelFutureDiscountsMutationVariables>;
export const GeoHierarchiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeoHierarchies' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoHierarchies' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPrices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'priceHierarchy' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'futurePrices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'priceHierarchy' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingPrices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'workingPriceSubsetHierarchy' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pricePreviews' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'productIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributePreviews' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'productIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'priceHierarchy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceHierarchy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'workingPriceSubsetHierarchy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkingPriceHierarchy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GeoHierarchiesQuery, GeoHierarchiesQueryVariables>;
export const ProductsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Products' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productsByBusinessLine' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'businessLine' },
                value: { kind: 'EnumValue', value: 'ON_DEMAND' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProductsQuery, ProductsQueryVariables>;
export const GeoHierarchiesChangeLogDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeoHierarchiesChangeLog' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GeoHierarchyFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoHierarchies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingPrices' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'productIds' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'workingPriceHierarchy' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'workingPriceHierarchy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkingPriceHierarchy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changelog' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GeoHierarchiesChangeLogQuery, GeoHierarchiesChangeLogQueryVariables>;
export const GeoHierarchiesPriceHistoryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GeoHierarchiesPriceHistory' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'GeoHierarchyFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'geoHierarchies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceHistory' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'priceHierarchy' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'priceHierarchy' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceHierarchy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
          { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productId' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GeoHierarchiesPriceHistoryQuery,
  GeoHierarchiesPriceHistoryQueryVariables
>;
export const SaveWorkingPriceHierarchyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveWorkingPriceHierarchy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priceHierarchy' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceHierarchyInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveWorkingPriceHierarchy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceHierarchy' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priceHierarchy' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveWorkingPriceHierarchyMutation,
  SaveWorkingPriceHierarchyMutationVariables
>;
export const PublishWorkingPriceHierarchyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishWorkingPriceHierarchy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priceHierarchyIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceHierarchyIdInput' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Date' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishWorkingPriceHierarchy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceHierarchyIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priceHierarchyIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publishDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'publishDate' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PublishWorkingPriceHierarchyMutation,
  PublishWorkingPriceHierarchyMutationVariables
>;
export const CancelFuturePriceHierarchyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelFuturePriceHierarchy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priceHierarchyIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceHierarchyIdInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelFuturePriceHierarchy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceHierarchyIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priceHierarchyIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelFuturePriceHierarchyMutation,
  CancelFuturePriceHierarchyMutationVariables
>;
export const RevertWorkingPriceHierarchyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RevertWorkingPriceHierarchy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priceHierarchyIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceHierarchyIdInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'revertWorkingPriceHierarchy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceHierarchyIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priceHierarchyIds' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RevertWorkingPriceHierarchyMutation,
  RevertWorkingPriceHierarchyMutationVariables
>;
export const UpdateGeoHierarchyAttributesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateGeoHierarchyAttributes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'geoGroupingIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'label' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GeoHierarchyAttributeLabel' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newValue' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateGeoHierarchyAttributes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'geoGroupingIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'geoGroupingIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productIds' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'productIds' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'label' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'label' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newValue' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newValue' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateGeoHierarchyAttributesMutation,
  UpdateGeoHierarchyAttributesMutationVariables
>;
export const PublishWorkingPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PublishWorkingPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'publishWorkingPriceInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PublishWorkingPriceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publishWorkingPrices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'publishWorkingPriceInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'publishWorkingPriceInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PublishWorkingPricesMutation, PublishWorkingPricesMutationVariables>;
export const PriceableItemDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PriceableItem' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceableItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changeLog' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceableItemQuery, PriceableItemQueryVariables>;
export const PriceableItemFeatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PriceableItemFeature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceableItem' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'changeLog' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prices' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'marketPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastChangeReason' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'minPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxPrice' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'confidenceLevel' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'features' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'ordinal' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PriceableItemFeatureQuery, PriceableItemFeatureQueryVariables>;
export const WorkingPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'WorkingPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workingPricePreviewInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkingPricePreviewInput' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInfo' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'workingPricePreviews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workingPricePreviewInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workingPricePreviewInput' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'priceableItem' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'attributes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPrice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'price' } }],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workingPrice' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'lastChangeReason' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timeZone' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'market' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'territory' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'region' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'name' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'roundedListPrice' } },
                { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<WorkingPricesQuery, WorkingPricesQueryVariables>;
export const TotalWorkingPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalWorkingPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workingPricePreviewInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkingPricePreviewInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalWorkingPrices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workingPricePreviewInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'workingPricePreviewInput' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalWorkingPricesQuery, TotalWorkingPricesQueryVariables>;
export const SaveWorkingPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SaveWorkingPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'workingPriceInputs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkingPriceInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'saveWorkingPrices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'workingPriceInputs' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'workingPriceInputs' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveWorkingPricesMutation, SaveWorkingPricesMutationVariables>;
export const ResetPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'UUID' } },
              },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scope' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'WorkingPriceScope' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPricesToLastPublished' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'ids' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scope' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scope' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isReset' } },
                { kind: 'Field', name: { kind: 'Name', value: 'details' } },
                { kind: 'Field', name: { kind: 'Name', value: 'priceableItemId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ResetPricesMutation, ResetPricesMutationVariables>;
export const CancelPricesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelPrices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'cancelFuturePriceInput' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CancelFuturePriceInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelFuturePrices' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'cancelFuturePriceInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'cancelFuturePriceInput' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errors' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CancelPricesMutation, CancelPricesMutationVariables>;
export const StandardPricingItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StandardPricingItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'PageInfo' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceableItemFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priceFilter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'priceableItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'page' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attributes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencyIsoCode' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'workingPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastChangeReason' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'prices' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'priceFilter' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'publishedAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'validFrom' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdBy' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'recommendedPrice' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'price' } }],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'metrics' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reservationPriceLocal' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isOccupied' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'monthsVacant' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'location' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<StandardPricingItemsQuery, StandardPricingItemsQueryVariables>;
export const TotalPriceableItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TotalPriceableItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'PriceableItemFilter' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'totalPriceableItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<TotalPriceableItemsQuery, TotalPriceableItemsQueryVariables>;
