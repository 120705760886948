import { StoreInterface } from '../../../../../utils/store/store.interface';
import { Location } from '../../../../../generated/voyager/graphql';

export const locationReconciliationSelector = (state: StoreInterface) =>
  state?.locationReconciliation;

export const locationReconciliationIsLoadingSelector = (state: StoreInterface): boolean =>
  locationReconciliationSelector(state)?.isLoading;

export const locationReconciliationLocationIdsSelector = (state: StoreInterface): string[] =>
  locationReconciliationSelector(state)?.locationIds;

export const locationReconciliationLocationsSelector = (state: StoreInterface): Location[] =>
  locationReconciliationSelector(state)?.locations ?? [];

export const locationReconciliationIsLocationIdsValidSelector = (state: StoreInterface): boolean =>
  locationReconciliationSelector(state)?.isLocationIdsValid;
