import * as React from 'react';
import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@wework/ray2';
import { isEmpty } from 'lodash';
import { Input } from 'semantic-ui-react';
import TableViewDataSelectorsDiscounts from './tableViewDataSelectorsDiscounts';
import {
  TableFilter,
  TableFilterBarLoadingOverlay,
  TableFilterBarWrapper,
  TableFilterColumnsWrap,
  TableFilterRow,
} from '../../../../styles/sharedTableFilterBar.styled';
import { batchFetchStateSelectorDiscountStore } from '../../store/modules/discountItems/discountItems.selector';
import FilterSidePanel from '../tableFilterSidePanel/filterSidePanel';
import { filtersSelector } from '../../store/modules/filters/filters.selector';
import { getNumberOfFilterApplied } from '../../../../utils/helpers';

interface TableFilterBarDiscountsProps {
  expandDiscountViewToggle: Function;
  expandDiscountView: boolean;
  agGridApi: any;
}

function TableFilterBarDiscounts({
  expandDiscountViewToggle,
  expandDiscountView,
  agGridApi,
}: TableFilterBarDiscountsProps): ReactElement {
  // STATE PROPS
  const batchFetchStateDiscount = useSelector(batchFetchStateSelectorDiscountStore);
  const filters = useSelector(filtersSelector);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const visibilityHandler = () => setIsVisible(!isVisible);

  return (
    <TableFilterBarWrapper>
      {batchFetchStateDiscount ? <TableFilterBarLoadingOverlay /> : ''}
      <TableFilterColumnsWrap>
        <TableFilterRow>
          <TableFilter>
            <Input
              className="reservable-search"
              icon="search"
              iconPosition="left"
              title="Search Location or Reservables"
              placeholder="Search"
              onChange={(event: any): void => agGridApi.setQuickFilter(event.target.value)}
              onKeyPress={(event: any): void => agGridApi.setQuickFilter(event.target.value)}
            />
          </TableFilter>
          <TableFilter>
            <Button
              theme={`${!isEmpty(filters) ? 'fill' : 'outline'}`}
              size={'medium'}
              className={'side-panel-trigger'}
              onClick={() => setIsVisible(!isVisible)}
            >
              {getNumberOfFilterApplied(filters)}
            </Button>
          </TableFilter>
          <TableFilter>
            <Button
              size="medium"
              theme={expandDiscountView ? 'fill' : 'outline'}
              className="side-panel-trigger"
              onClick={() => expandDiscountViewToggle()}
            >
              Expand Discount View
            </Button>
          </TableFilter>
        </TableFilterRow>
        {TableViewDataSelectorsDiscounts()}
        <TableFilterRow />
      </TableFilterColumnsWrap>
      <FilterSidePanel isVisible={isVisible} visibilityHandler={visibilityHandler} />
    </TableFilterBarWrapper>
  );
}

export default TableFilterBarDiscounts;
