import { StoreInterface } from '../../../../utils/store/store.interface';
import { ActionItemGroup, LocationActionItem } from '../../../../generated/voyager/graphql';
import { ActionItemsSubset } from '../entities/actionItems';

const actionItemsSelector = (state: StoreInterface) => state?.actionItems;

export const allActionItemsSelector = (state: ActionItemsSubset) => state?.actionItems;

export const groupActionItemsSelector =
  (group: ActionItemGroup) =>
  (state: StoreInterface): LocationActionItem[] =>
    actionItemsSelector(state)?.actionItems.get(group) ?? [];

export const locationActionItemsLoadingSelector =
  (group: ActionItemGroup) =>
  (state: StoreInterface): boolean =>
    actionItemsSelector(state)?.locationActionItemLoading.get(group) ?? false;
