import { Action, handleActions } from 'redux-actions';
import initialState, {
  initialInventoryReconciliationByLocation,
} from '../../../../../utils/store/initialState';
import { createRDXConstant } from '../../utils/store.constants';
import { InventoryReconciliationByLocationSubset } from '../../entities/inventoryReconciliationByLocation';
import { Location } from '../../../../../generated/voyager/graphql';

// Action Constants
export const INVENTORY_RECONCILIATION_BY_LOCATION = createRDXConstant(
  'INVENTORY_RECONCILIATION_BY_LOCATION',
);

export const INVENTORY_RECONCILIATION_BY_LOCATION_SUCCESS = createRDXConstant(
  'INVENTORY_RECONCILIATION_BY_LOCATION_SUCCESS',
);
export const INVENTORY_RECONCILIATION_BY_LOCATION_ERROR = createRDXConstant(
  'INVENTORY_RECONCILIATION_BY_LOCATION_ERROR',
);

export const INVENTORY_RECONCILIATION_BY_LOCATION_CLEAR_DATA = createRDXConstant(
  'INVENTORY_RECONCILIATION_BY_LOCATION_CLEAR_DATA',
);

export const INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATION_IDS = createRDXConstant(
  'INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATION_IDS',
);

export const INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATIONS = createRDXConstant(
  'INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATIONS',
);

export const INVENTORY_RECONCILIATION_BY_LOCATION_SET_IS_LOCATION_IDS_VALID = createRDXConstant(
  'INVENTORY_RECONCILIATION_BY_LOCATION_SET_IS_LOCATION_IDS_VALID',
);

// Reducer
export const inventoryReconciliationByLocationReducer = handleActions<
  InventoryReconciliationByLocationSubset,
  any
>(
  {
    [INVENTORY_RECONCILIATION_BY_LOCATION]: (state: InventoryReconciliationByLocationSubset) => ({
      ...state,
      isLoading: true,
    }),
    [INVENTORY_RECONCILIATION_BY_LOCATION_SUCCESS]: (
      state: InventoryReconciliationByLocationSubset,
    ) => ({
      ...state,
      ...initialInventoryReconciliationByLocation,
      isLoading: false,
    }),
    [INVENTORY_RECONCILIATION_BY_LOCATION_ERROR]: (
      state: InventoryReconciliationByLocationSubset,
    ) => ({
      ...state,
      isLoading: false,
    }),
    [INVENTORY_RECONCILIATION_BY_LOCATION_CLEAR_DATA]: (
      state: InventoryReconciliationByLocationSubset,
    ) => ({
      ...state,
      ...initialInventoryReconciliationByLocation,
    }),
    [INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATION_IDS]: (
      state: InventoryReconciliationByLocationSubset,
      action: Action<string[]>,
    ) => ({
      ...state,
      locationIds: action.payload,
    }),
    [INVENTORY_RECONCILIATION_BY_LOCATION_SET_LOCATIONS]: (
      state: InventoryReconciliationByLocationSubset,
      action: Action<Location[]>,
    ) => ({
      ...state,
      locations: action.payload,
    }),
    [INVENTORY_RECONCILIATION_BY_LOCATION_SET_IS_LOCATION_IDS_VALID]: (
      state: InventoryReconciliationByLocationSubset,
      action: Action<boolean>,
    ) => ({
      ...state,
      isLocationIdsValid: action.payload,
    }),
  },
  initialState.inventoryReconciliationByLocation,
);
