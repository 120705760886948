import * as React from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { SkeletonParagraph } from '@wework/dieter-ui';
import { Table } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { v4 as uuidV4 } from 'uuid';
import {
  EachLocationSummary,
  LoadingBox,
  LocationsChangesSummaries,
  PriceChip,
  SummarySubTitle,
  SummaryTitle,
  SummaryWrapper,
  WorkingOverlay,
} from './pricingPublish.styled';
import {
  publishPricesBatchFetchSelectorStore,
  publishPricesPublishSaveSelectorStore,
  publishPricesSelectorStore,
} from '../../store/modules/publishPrice/publishPrice.selector';
import { roundToAtMost2Digit, usePrevious } from '../helpers';
import { PublishPriceTableItem } from '../../store/entities/publishPrice';
import { routerHistory } from '../../../../../utils/store/configureStore';
import { intlCurrencyFormatter } from '../../../../../utils/helpers';

interface PricingPublishProps {
  transformedWorkingPrice: Map<string, PublishPriceTableItem[]>;
}

function PricingPublish(props: PricingPublishProps): ReactElement {
  // LOCAL VARIABLE
  const [publishing, setPublishing] = useState<boolean>(false);

  // STATE PROPS
  const allWorkingPrices = useSelector(publishPricesSelectorStore);
  const batchFetch = useSelector(publishPricesBatchFetchSelectorStore);
  const publish = useSelector(publishPricesPublishSaveSelectorStore);
  const prevPublish = usePrevious(publish);

  /**
   * Number of changes for first subtitle.
   */
  const numberOfChanges = () => {
    if (!isEmpty(allWorkingPrices) && !isEmpty(props?.transformedWorkingPrice)) {
      return (
        <SummarySubTitle className="left-align">
          {allWorkingPrices?.length} changes will be published in{' '}
          {props?.transformedWorkingPrice?.size && props?.transformedWorkingPrice?.size > 1
            ? `${props?.transformedWorkingPrice?.size} locations`
            : '1 location'}
          :
        </SummarySubTitle>
      );
    }
    return <></>;
  };

  /**
   * display publishing message
   */
  useEffect(() => {
    if (prevPublish === true && !publish) {
      setPublishing(true);
      routerHistory.goBack();
    }
  }, [publish, prevPublish]);

  const totalCapacityCalculation = (workingPrices: PublishPriceTableItem[]): number => {
    let totalCapacity: number = 0;
    if (workingPrices) {
      workingPrices.forEach(wp => {
        totalCapacity += wp.capacity;
      });
    }
    return totalCapacity;
  };

  const avgListPriceCalculation = (
    workingPrices: PublishPriceTableItem[],
    price: string,
  ): string => {
    let totalListPrice: number = 0;
    const capacities = totalCapacityCalculation(workingPrices);

    workingPrices.forEach(wp => {
      totalListPrice +=
        (price === 'previous' ? wp.currentListPrice : wp.workingListPriceRounded) * wp.capacity;
    });

    if (capacities !== 0) {
      return intlCurrencyFormatter(
        workingPrices[0]?.currencyIsoCode ?? '',
        roundToAtMost2Digit(totalListPrice / capacities),
      );
    }
    return '0';
  };

  return (
    <div>
      <SummaryTitle>Review Changes</SummaryTitle>
      <SummaryWrapper className="data-wrapper">
        {numberOfChanges()}
        {/* CONTENT TABLE */}
        {!batchFetch && !isEmpty(props.transformedWorkingPrice) && (
          <>
            <LocationsChangesSummaries>
              {props.transformedWorkingPrice &&
                [...props.transformedWorkingPrice.values()].map(workingPrices => (
                  <EachLocationSummary key={uuidV4()} className="each-location border-visible">
                    <SummarySubTitle>{workingPrices[0].locationName}</SummarySubTitle>
                    <Table basic="very" className="changes-summary-table">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>SKU / Reservable</Table.HeaderCell>
                          <Table.HeaderCell>Adj. By</Table.HeaderCell>
                          <Table.HeaderCell>Previous ARPM</Table.HeaderCell>
                          <Table.HeaderCell>New ARPM</Table.HeaderCell>
                          <Table.HeaderCell>Previous List Price</Table.HeaderCell>
                          <Table.HeaderCell>New List Price</Table.HeaderCell>
                          <Table.HeaderCell>New Rounded List Price</Table.HeaderCell>
                          <Table.HeaderCell>Reason</Table.HeaderCell>
                          <Table.HeaderCell>Created By</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row className="summary-line">
                          <Table.Cell>All changed reservables</Table.Cell>
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell>
                            {avgListPriceCalculation(workingPrices, 'previous')}
                          </Table.Cell>
                          <Table.Cell />
                          <Table.Cell>{avgListPriceCalculation(workingPrices, 'now')}</Table.Cell>
                          <Table.Cell />
                          <Table.Cell />
                        </Table.Row>
                        {workingPrices.map(wp => (
                          <Table.Row key={uuidV4()}>
                            <Table.Cell>{`${wp.sku} / ${wp.officeName}`}</Table.Cell>
                            <Table.Cell>{wp.adjustSuggestedPrice}</Table.Cell>
                            <Table.Cell>
                              {intlCurrencyFormatter(wp.currencyIsoCode, wp.currentPrice)}
                            </Table.Cell>
                            <Table.Cell>
                              {intlCurrencyFormatter(wp.currencyIsoCode, wp.workingPrice)}
                            </Table.Cell>
                            <Table.Cell>
                              {intlCurrencyFormatter(wp.currencyIsoCode, wp.currentListPrice)}
                            </Table.Cell>
                            <Table.Cell>
                              {intlCurrencyFormatter(wp.currencyIsoCode, wp.workingListPrice)}
                            </Table.Cell>
                            <Table.Cell>
                              <PriceChip>
                                {intlCurrencyFormatter(
                                  wp.currencyIsoCode,
                                  wp.workingListPriceRounded,
                                )}
                              </PriceChip>
                            </Table.Cell>
                            <Table.Cell>{wp.workingPriceReason}</Table.Cell>
                            <Table.Cell>{wp.workingPriceCreatedBy}</Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </EachLocationSummary>
                ))}
            </LocationsChangesSummaries>
          </>
        )}
        {!batchFetch && isEmpty(props.transformedWorkingPrice) && !publishing && (
          <SummarySubTitle>No summary to Display</SummarySubTitle>
        )}
        {batchFetch && (
          <LoadingBox>
            <SkeletonParagraph size="large" rows={5} />
          </LoadingBox>
        )}
        {publish && (
          <WorkingOverlay>
            <SummarySubTitle>Working...</SummarySubTitle>
          </WorkingOverlay>
        )}
        {publishing && isEmpty(props.transformedWorkingPrice) && (
          <SummarySubTitle className="success">Successfully published prices.</SummarySubTitle>
        )}
      </SummaryWrapper>
    </div>
  );
}

export default PricingPublish;
