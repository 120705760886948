import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Job } from '../../store/entities/jobStatus';
import InfoTooltip from '../../../../sharedComponents/tooltip/infoTooltip';
import { CHECK_JOB_STATUS } from '../../store/modules/jobStatus/jobStatus.ducks';

interface TooltipProps {
  job: Job | null;
}

const AnotherJobIsRunningTooltip = (props: TooltipProps) => {
  const dispatch = useDispatch();
  const checkJobStatus = useCallback(() => dispatch({ type: CHECK_JOB_STATUS }), [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkJobStatus();
    }, 5000);
    return () => clearInterval(interval);
  }, [checkJobStatus]);

  return (
    <div>
      {props.job != null ? (
        <InfoTooltip popupContent={<p>You can&apos;t run job because another one is running</p>} />
      ) : (
        ''
      )}
    </div>
  );
};
export default AnotherJobIsRunningTooltip;
