import { gql } from '@apollo/client';

// GraphQL Queries
// Query to Fetch all the locations by name contains.
export const FETCH_LOCATIONS_QUERY = gql`
  query Locations($name: String!) {
    locationsByName(name: $name) {
      id
      name
      market {
        id
      }
      currencyIsoCode
      metrics {
        locationOpenDate
      }
    }
  }
`;

export const FETCH_LOCATIONS_BY_IDS_QUERY = gql`
  query LocationsByIds($ids: [UUID!]) {
    locationsByIds(ids: $ids) {
      id
      name
      market {
        id
      }
      currencyIsoCode
      metrics {
        locationOpenDate
      }
    }
  }
`;

// Query to fetch all the locations in a given market.
export const FETCH_LOCATIONS_BY_MARKET_QUERY = gql`
  query LocationsByMarket($marketId: ID!) {
    locationsByMarket(marketId: $marketId) {
      id
      name
      currencyIsoCode
      market {
        id
      }
      metrics {
        locationOpenDate
      }
    }
  }
`;

// Query to fetch all the location details from the multiple locations id.
export const FETCH_MULTIPLE_LOCATION_DETAILS_QUERY = gql`
  query LocationDetailsByIds($ids: [UUID!]) {
    locationsByIds(ids: $ids) {
      id
      name
      timeZone
      market {
        id
        name
        territory {
          id
          name
          region {
            id
            name
          }
        }
        skuOccupancy {
          sku
          trailing3mOccupancy
          trailing6mOccupancy
          occupancy
          next3mOccupancy
          next6mOccupancy
        }
      }
      currencyIsoCode
      metrics {
        locationOpenDate
        currentOccupancy
        trailing3mOccupancy
        trailing6mOccupancy
        next3mOccupancy
        next6mOccupancy
        averageNetArpmLocal
        largeSkuOccupancy
        smallSkuOccupancy
        avgNetArpmLargeSku
        avgNetArpmSmallSku
        hotDeskCount
        dedicatedDeskCount
        sharedOfficeCount
        privateOfficeCount
        lgSkuPre6mOccupancy
        lgSkuPre3mOccupancy
        lgSkuOccupancy
        lgSkuNext3mOccupancy
        lgSkuNext6mOccupancy
        smSkuPre6mOccupancy
        smSkuPre3mOccupancy
        smSkuOccupancy
        smSkuNext3mOccupancy
        smSkuNext6mOccupancy
        prev1mMinPriceLocal
        minPriceLocal
        next1mMinPriceLocal
        prev1mNetArpmLocal
        netArpmLocal
        next1mNetArpmLocal
        prev1mAvgDiscount
        avgDiscount
        next1mAvgDiscount
        prev1mLargeSkuNetArpmLocal
        lgSkuNetArpmLocal
        next1mLgSkuNetArpmLocal
        prev1mSmSkuNetArpmLocal
        smSkuNetArpmLocal
        next1mSmSkuNetArpmLocal
        smListPrice
        lgListPrice
      }
      skuOccupancy {
        sku
        occupancy
        trailing3mOccupancy
        trailing6mOccupancy
        next3mOccupancy
        next6mOccupancy
      }
      minPrice
      deskCount
      maxDiscounts {
        sku
        discount
      }
    }
  }
`;
