import * as React from 'react';
import { ReactElement, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Confirm, Icon } from 'semantic-ui-react';
import { DataWithCallback } from 'utils/sharedTypes';
import Delete from '../../../../../assets/delete.svg';
import Pencil from '../../../../../assets/pencil.svg';
import Eye from '../../../../../assets/eye.svg';
import DiscountCurvesOverrideModal, {
  DiscountCurvesModalMode,
} from '../../curveManagementModal/curveManagementModal';
import {
  CLEAR_CURVES,
  CLEAR_DISCOUNT_ITEMS_CURVES,
  DELETE_CURVES,
} from '../../../store/modules/discountItems/discountItems.ducks';
import ButtonsWrapper from './discountCurveManagementLinkRenderer.styled';
import { trackAnalytics } from '../../../../../utils/analytics/helpers';
import { MutationDeleteCurveArgs } from '../../../../../generated/voyager/graphql';

interface DiscountsCurveManagementOptionTriggerProps {
  data: any;
}
const DiscountsCurveManagementOptionTrigger = ({
  data,
}: DiscountsCurveManagementOptionTriggerProps): ReactElement => {
  const [modalOpenOverride, setModalOpenOverride] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

  const setModalOpenInViewMode = () => {
    setModalOpenOverride(true);
    setEditMode(false);
  };

  const setModalOpenInEditMode = () => {
    setModalOpenOverride(true);
    setEditMode(true);
  };

  const dispatch = useDispatch();
  const clearCurves = useCallback(() => dispatch({ type: CLEAR_CURVES }), [dispatch]);
  const clearAllCurves = useCallback(
    () => dispatch({ type: CLEAR_DISCOUNT_ITEMS_CURVES }),
    [dispatch],
  );

  const deleteCurveAction = useCallback(
    (payload: DataWithCallback<MutationDeleteCurveArgs>) =>
      dispatch({ type: DELETE_CURVES, payload }),
    [dispatch],
  );

  function trackCurveDeleted() {
    trackAnalytics('Discount - Curve Deletion', {
      workflow: 'Discount curve deletion',
      object_type: 'Curve',
      object_name: 'Delete',
    });
  }

  const deleteCurve = () => {
    deleteCurveAction({
      data: {
        curveId: data.id,
      },
      successCallback: () => {
        trackCurveDeleted();
        clearAllCurves();
        setConfirmOpen(false);
      },
    });
  };

  if (data) {
    return (
      <>
        <ButtonsWrapper justifyContent={data.isActive ? 'space-around' : 'start'}>
          <Icon as="img" link src={Eye} onClick={() => setModalOpenInViewMode()} />
          {data.isActive && (
            <Icon as="img" link src={Pencil} onClick={() => setModalOpenInEditMode()} />
          )}
          {data.isActive && (
            <Icon as="img" link src={Delete} onClick={() => setConfirmOpen(true)} />
          )}
        </ButtonsWrapper>
        {modalOpenOverride && (
          <DiscountCurvesOverrideModal
            discountCurvesOverrideModalClose={() => {
              setModalOpenOverride(false);
              clearCurves();
            }}
            mode={editMode ? DiscountCurvesModalMode.EDIT : DiscountCurvesModalMode.VIEW}
            curveData={data}
          />
        )}
        {confirmOpen && (
          <Confirm
            open={confirmOpen}
            onCancel={() => setConfirmOpen(false)}
            onConfirm={deleteCurve}
            closeIcon
            confirmButton={{ content: 'Delete' }}
            header={'Delete discount curve'}
            content={
              <div>
                <p>
                  Are you sure you want to delete <b>{data.name}</b> curve?
                </p>
              </div>
            }
          />
        )}
      </>
    );
  }

  return <> </>;
};

export default DiscountsCurveManagementOptionTrigger;
