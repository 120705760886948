import styled from 'styled-components';

const LoginInfo = styled.div`
  margin: 2rem 0 4em;
  color: #fff;
  font-size: 16px;
  width: 287px;
  height: 54px;
  font-weight: normal;
  line-height: 27px;
`;

export default LoginInfo;
