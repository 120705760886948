import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SwitchStyled,
  TableFilter,
  TableFilterRow,
} from '../../../../styles/sharedTableFilterBar.styled';
import {
  editModeSelectorBreakeven,
  fetchStateSelectorBreakevenStore,
} from '../../store/modules/breakevenItems/breakevenItems.selector';
import {
  DISABLE_EDIT_MODE_BREAKEVEN,
  ENABLE_EDIT_MODE_BREAKEVEN,
} from '../../store/modules/breakevenItems/breakevenItems.ducks';

const BreakevenViewEditModeSelector = (): ReactElement => {
  // DISPATCH PROPS
  const dispatch = useDispatch();
  const enableEditModeBreakeven = useCallback(
    () => dispatch({ type: ENABLE_EDIT_MODE_BREAKEVEN }),
    [dispatch],
  );

  const disableEditModeBreakeven = useCallback(
    () => dispatch({ type: DISABLE_EDIT_MODE_BREAKEVEN }),
    [dispatch],
  );

  // STATE PROPS
  const editMode = useSelector(editModeSelectorBreakeven);
  const batchFetch = useSelector(fetchStateSelectorBreakevenStore);

  return (
    <TableFilterRow>
      <TableFilter className="edit-table-filter">
        <p className="edit-mode-on-off">Edit</p>
        <SwitchStyled className="switch">
          <input
            type="checkbox"
            checked={editMode}
            disabled={batchFetch}
            onChange={() => (editMode ? disableEditModeBreakeven() : enableEditModeBreakeven())}
          />
          <span className="slider" />
          <span className="on">ON</span>
          <span className="off">OFF</span>
        </SwitchStyled>
      </TableFilter>
    </TableFilterRow>
  );
};

export default BreakevenViewEditModeSelector;
