import { Action } from 'redux-actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { differenceBy, isEmpty } from 'lodash';
import { DocumentNode } from 'graphql';
import { toast } from 'react-toastify';
import {
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_BY_IDS,
  FETCH_LOCATIONS_BY_IDS_FINISHED,
  FETCH_LOCATIONS_BY_MARKET,
  FETCH_LOCATIONS_BY_MARKET_SUCCESS,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID,
  FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID_SUCCESS,
} from './locations.ducks';
import {
  FETCH_LOCATIONS_BY_IDS_QUERY,
  FETCH_LOCATIONS_BY_MARKET_QUERY,
  FETCH_LOCATIONS_QUERY,
  FETCH_MULTIPLE_LOCATION_DETAILS_QUERY,
} from './locations.query';
import { errorHandlerActive } from '../../../utils/errorHandling/helpers';
import { graphQLClient } from '../../../index';
import { Location, PageInfo, QueryLocationsByIdsArgs } from '../../../generated/voyager/graphql';

const executeGraphQLQuery = (query: DocumentNode, variable: Object): Promise<any> =>
  graphQLClient.query({
    fetchPolicy: 'network-only',
    query,
    variables: variable,
  });

// Saga - fetch all locations from gateway.
function* fetchLocationsWorker(action: Action<PageInfo>) {
  try {
    const { errors, data } = yield call(executeGraphQLQuery, FETCH_LOCATIONS_QUERY, {
      name: action.payload,
    });
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }
    yield put({ type: FETCH_LOCATIONS_SUCCESS, payload: data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

function* fetchLocationsByIdsWorker(action: Action<QueryLocationsByIdsArgs>) {
  try {
    const { errors, data } = yield call(
      executeGraphQLQuery,
      FETCH_LOCATIONS_BY_IDS_QUERY,
      action.payload,
    );

    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    // An extra validation for a case when a page is loaded from a short URL.
    // The URL could be created with an API or a location currency could change after the URL was generated.
    // This never happens when locations are selected using the UI.
    const locationsByIds: Location[] = data.locationsByIds;
    const locationsWithSameCurrency = locationsByIds.filter(
      location => location.currencyIsoCode === locationsByIds[0].currencyIsoCode,
    );

    if (locationsWithSameCurrency.length !== locationsByIds.length) {
      toast.warn(
        `Some location(s) are not displayed because they have different currency: ${differenceBy(
          locationsByIds,
          locationsWithSameCurrency,
          'currencyIsoCode',
        )
          .map(location => `${location.name} (${location.currencyIsoCode})`)
          .join(', ')}`,
      );
    }

    yield put({ type: FETCH_LOCATIONS_BY_IDS_FINISHED, payload: locationsWithSameCurrency });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

// Saga - fetch all locations based on selected market.
function* fetchLocationsByMarketWorker(action: Action<string>) {
  try {
    const marketId = action?.payload ?? null;
    if (marketId === null) {
      errorHandlerActive(new Error('marketId cannot be null.'));
    }

    const variable = { marketId };
    const { errors, data } = yield call(
      executeGraphQLQuery,
      FETCH_LOCATIONS_BY_MARKET_QUERY,
      variable,
    );
    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }
    yield put({ type: FETCH_LOCATIONS_BY_MARKET_SUCCESS, payload: data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

// Saga - fetch all location details based on multiple selected locations.
function* fetchMultipleLocationDetailsByIdWorker(action: Action<string[]>) {
  try {
    const ids = action?.payload ?? null;
    if (isEmpty(ids)) {
      errorHandlerActive(new Error('id cannot be null.'));
    }

    const { errors, data } = yield call(
      executeGraphQLQuery,
      FETCH_MULTIPLE_LOCATION_DETAILS_QUERY,
      { ids },
    );

    if (!isEmpty(errors)) {
      errorHandlerActive(new Error(errors[0]));
      return;
    }

    yield put({ type: FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID_SUCCESS, payload: data });
  } catch (e: any) {
    errorHandlerActive(new Error(e));
  }
}

export default function* locationSaga(): any {
  yield all([takeLatest(FETCH_LOCATIONS, fetchLocationsWorker)]);
  yield all([takeLatest(FETCH_LOCATIONS_BY_IDS, fetchLocationsByIdsWorker)]);
  yield all([takeLatest(FETCH_LOCATIONS_BY_MARKET, fetchLocationsByMarketWorker)]);
  yield all([
    takeLatest(FETCH_MULTIPLE_LOCATION_DETAILS_BY_ID, fetchMultipleLocationDetailsByIdWorker),
  ]);
}
