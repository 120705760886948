import { filter, find, forEach, get, maxBy, reject, sortBy } from 'lodash';
import stable from 'stable';
import { LabelProps } from 'semantic-ui-react/dist/commonjs/elements/Label';
import { compareAsc, compareDesc } from 'date-fns';
import { Unassigned } from '../../../utils/sharedTypes';
import {
  DiscountCurveStatus,
  FlattenDiscountDistribution,
  GridDiscountPagedItem,
  TableViewDiscountColumn,
  TableViewDiscountItem,
  TableViewDiscountItemDiscountData,
  TableViewLowestNetPriceColumn,
} from '../store/entities/discountItems';
import {
  calculateLowestNetPrice,
  getStringOfProperty,
  roundToAtMost2Digit,
} from '../../pricing/standardPricing/components/helpers';
import { nonPersonalOfficeProductType } from '../../pricing/standardPricing/components/constants';
import { PriceableItemFilter } from '../../../sharedStore/entities/priceableItemFilters';
import { Curve, GridDiscount, Location } from '../../../generated/voyager/graphql';

const convertStringToNumber = (str: string | Unassigned): string =>
  !str || str === 'null' ? '0' : str;

export const flattenDiscounts = (
  discounts: GridDiscount[],
): Map<string, TableViewDiscountColumn> => {
  const flatDiscountArray = new Map<string, TableViewDiscountColumn>();

  discounts.forEach(d => {
    const baseOrOverride = d.override?.base ?? d.baseDiscount?.base;
    const base = d.baseDiscount?.base;
    const discountDistribution: FlattenDiscountDistribution[] = [];
    for (let i = 0; i < d.termType.length; i += 1) {
      const month = i + 1;

      // Creating distribution data
      discountDistribution.push({
        month,
        base: baseOrOverride,
      });
    }

    const futureOverride = d.futureOverride
      ? {
          base: d.futureOverride.base,
          expDate: d.futureOverride.expirationDate,
          publishedDate: d.futureOverride.publishDate,
          createdBy: d.futureOverride.createdBy,
          updatedAt: d.futureOverride.updatedAt,
        }
      : null;
    const futureBaseDiscount = d.futureBaseDiscount
      ? {
          base: d.futureBaseDiscount.base,
          publishedDate: d.futureBaseDiscount.publishDate,
          createdBy: d.futureBaseDiscount.createdBy,
        }
      : null;

    // Creating flat term discount
    flatDiscountArray.set(d.termType.label, {
      base,
      baseOrOverride,
      isBaseDiscountOverridden: false,
      distribution: discountDistribution,
      futureOverride,
      futureBaseDiscount,
    } as TableViewDiscountColumn);
  });
  return flatDiscountArray;
};

const transformDiscountItemToTableViewDiscountData = (
  discountItem: GridDiscountPagedItem,
): TableViewDiscountItemDiscountData => {
  let m2MDiscount = {} as TableViewDiscountColumn;
  let sixMDiscount = {} as TableViewDiscountColumn;
  let twelveMDiscount = {} as TableViewDiscountColumn;
  let twentyFourMDiscount = {} as TableViewDiscountColumn;
  let thirtySixMDiscount = {} as TableViewDiscountColumn;

  if (discountItem.gridDiscounts) {
    const flatDiscountArray = flattenDiscounts(discountItem.gridDiscounts);

    const discountColData = (key: string): TableViewDiscountColumn =>
      ({
        base: flatDiscountArray.get(key)?.base ?? null,
        baseOrOverride: flatDiscountArray.get(key)?.baseOrOverride ?? null,
        distribution: flatDiscountArray.get(key)?.distribution ?? [],
        isBaseDiscountOverridden: flatDiscountArray.get(key)?.isBaseDiscountOverridden,
        futureOverride: flatDiscountArray.get(key)?.futureOverride,
        futureBaseDiscount: flatDiscountArray.get(key)?.futureBaseDiscount,
      } as TableViewDiscountColumn);
    // TODO: @andrulo - change to use the term type value instead of hardcoded keys
    m2MDiscount = discountColData('M2M');
    sixMDiscount = discountColData('6M');
    twelveMDiscount = discountColData('12M');
    twentyFourMDiscount = discountColData('24M');
    thirtySixMDiscount = discountColData('36M');
  }

  return {
    m2MDiscount,
    sixMDiscount,
    twelveMDiscount,
    twentyFourMDiscount,
    thirtySixMDiscount,
    curvesApplied: discountItem.appliedCurves.length,
  };
};

/**
 * Discount grid transform
 */
const transformDiscountItemToTableView = (
  discountItem: GridDiscountPagedItem,
  locationDetails: Location,
): TableViewDiscountItem => {
  const attributesArr = discountItem.attributes;
  const hasWindow: boolean = find(attributesArr, ['label', 'hasWindow'])?.value === 'true';
  const internalRoomVal: string | Unassigned = find(attributesArr, [
    'label',
    'internalRoomCount',
  ])?.value;
  const internalRoomPresent: boolean = internalRoomVal !== 'null' && internalRoomVal !== '0';

  const sku = `${find(attributesArr, ['label', 'sku'])?.value}`;

  const marketPrice = roundToAtMost2Digit(+[discountItem.currentPrice?.marketPrice]) ?? 0;

  const currencyIsoCode = discountItem.currentPrice?.currencyIsoCode;

  const timeZone = locationDetails?.timeZone;

  // location sku occupancy for each sku
  const locationSkuOccupancy = find(locationDetails.skuOccupancy, ['sku', sku]);

  // market sku occupancy for each sku
  const marketSkuOccupancy = find(locationDetails.market?.skuOccupancy, ['sku', sku]);

  let lowestNetPrice = {} as TableViewLowestNetPriceColumn;
  // flatten discounts
  const discountData = transformDiscountItemToTableViewDiscountData(discountItem);
  if (discountItem.gridDiscounts) {
    const comparableTermsDiscounts = [
      discountData.m2MDiscount,
      discountData.sixMDiscount,
      discountData.twelveMDiscount,
      discountData.twentyFourMDiscount,
      discountData.thirtySixMDiscount,
    ];
    const maxValBase = maxBy(comparableTermsDiscounts, 'base');
    const lowestNetPriceCalc = calculateLowestNetPrice(
      +[marketPrice],
      +[maxValBase?.baseOrOverride],
    );
    lowestNetPrice = {
      value: lowestNetPriceCalc.value,
      formatted: lowestNetPriceCalc.formatted,
    };
  }

  let lastPublished: Date | null = null;
  let expDate: Date | null = null;
  discountItem.gridDiscounts.forEach(gd => {
    // If the base discount is set, update Last Publish Date.
    if (gd.baseDiscount) {
      // If the current publishDate is empty, assign the new published Date to it.
      // If the new published Date is later than the current publishDate; update it.
      if (gd.baseDiscount.publishDate) {
        const convertedPublishDate = new Date(gd.baseDiscount.publishDate);
        if (lastPublished === null || compareDesc(lastPublished, convertedPublishDate) === 1) {
          lastPublished = convertedPublishDate;
        }
      }
    }
    // If the base discount is set, update the Last Publish Date and Exp Date.
    if (gd.override) {
      // If the current publishDate is empty, assign the new published Date to it.
      // If the new published Date is later than the current publishDate; update it.
      if (gd.override.publishDate) {
        const convertedPublishDate = new Date(gd.override.publishDate);
        if (lastPublished === null || compareDesc(lastPublished, convertedPublishDate) === 1) {
          lastPublished = convertedPublishDate;
        }
      }
      // If the current Exp Date is empty, assign the new Exp Date to it.
      // If the new Exp Date is earlier than the current Exp Date; update it.
      if (gd.override.expirationDate) {
        const convertedExpDate = new Date(gd.override.expirationDate);
        if (expDate === null || compareAsc(expDate, convertedExpDate) === 1) {
          expDate = convertedExpDate;
        }
      }
    }
  });

  return {
    id: discountItem.id,
    officeName: discountItem.name,
    sku,
    page: discountItem.page,
    capacity: `${find(attributesArr, ['label', 'capacity'])?.value}`,
    productType: `${find(attributesArr, ['label', 'productType'])?.value}`,
    hasWindow,
    internalRoomPresent,
    physicalAttributes: [{ name: 'window', value: hasWindow }],
    internalRoomCount: +[
      convertStringToNumber(find(attributesArr, ['label', 'internalRoomCount'])?.value),
    ],
    marketPrice,
    lowestNetPrice,
    reservationGrossPrice: +[discountItem.metrics?.reservationPriceLocal] ?? 0,
    monthsVacant: +[discountItem.metrics?.monthsVacant] ?? 0,
    locationSKUOccupancy: locationSkuOccupancy?.occupancy ?? 0,
    marketSKUOccupancy: marketSkuOccupancy?.occupancy ?? 0,
    isOccupied: discountItem?.metrics?.isOccupied ?? false,
    lastPublished,
    expDate,
    location: discountItem.location,
    ...discountData,
    currencyIsoCode,
    timeZone,
  };
};

export const transformDiscountItemToTableViewArr = (
  discountItems: GridDiscountPagedItem[],
  allLocationDetails: Location[],
): TableViewDiscountItem[] => {
  const tableViewDiscountItems = discountItems.map(discountItem => {
    const locationDetails: Location =
      find(allLocationDetails, ['id', discountItem.location?.id]) ?? ({} as Location);
    return discountItem
      ? transformDiscountItemToTableView(discountItem, locationDetails)
      : ({} as TableViewDiscountItem);
  });

  // Sorting string with both numbers and letters.
  const propertyName = 'sku';
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  const sortFunction = (a: TableViewDiscountItem, b: TableViewDiscountItem) =>
    collator.compare(getStringOfProperty(a, propertyName), getStringOfProperty(b, propertyName));
  return sortBy(stable(tableViewDiscountItems, sortFunction), (ele: any): number => {
    const identifier = `${ele.sku}`;
    const rank: any = {
      HD: 1,
      DD: 2,
    };
    return rank[identifier];
  });
};

function computeIsDiscountExpiringWithin14Days(dateTime: string | null) {
  if (dateTime == null) return false;
  const lookback = new Date(dateTime);
  lookback.setDate(lookback.getDate() - 14);
  return new Date() >= lookback;
}

function computeNoDiscount(obj: TableViewDiscountItem) {
  return (
    obj.m2MDiscount?.base == null ||
    obj.sixMDiscount?.base == null ||
    obj.twelveMDiscount?.base == null ||
    obj.twentyFourMDiscount?.base == null ||
    obj.thirtySixMDiscount?.base == null
  );
}

/**
 * -- GRID FILTERS
 */
const convertToPercent = (percentValue: boolean, filterParamValue: number) =>
  percentValue ? Number(filterParamValue) / 100 : filterParamValue;
const filterHelperRange = (
  objArr: TableViewDiscountItem[],
  filterParam: PriceableItemFilter | Unassigned,
  propertyName: string,
  percentValue: boolean,
): TableViewDiscountItem[] =>
  filterParam
    ? filter(objArr, (obj: TableViewDiscountItem) => {
        const objValue: number | null = Number(get(obj, propertyName, null));
        const minVal: number | Unassigned =
          'min' in filterParam ? convertToPercent(percentValue, filterParam?.min ?? 0) : null;
        const maxVal: number | Unassigned =
          'max' in filterParam ? convertToPercent(percentValue, filterParam?.max ?? 0) : null;
        return objValue !== null
          ? objValue >= (minVal ?? Number.MIN_VALUE) && objValue <= (maxVal ?? Number.MAX_VALUE)
          : true;
      })
    : objArr;

const filterHelperSelected = (
  objArr: TableViewDiscountItem[],
  filterParam: PriceableItemFilter | Unassigned,
  propertyName: string,
  propertyValue: any,
): TableViewDiscountItem[] =>
  filterParam ? filter(objArr, [propertyName, propertyValue]) : objArr;

export const filterHelperObjectArrayDiscount = (
  objArr: TableViewDiscountItem[],
  filterOptions: PriceableItemFilter[],
): TableViewDiscountItem[] => {
  let returnedArr: TableViewDiscountItem[];

  // Filter for SKU:
  // 1) Need to do this manually as it is a multiple option select.
  // 2) Need to do it first as it affects the rest of the filter.
  const HDFilterObject = find(filterOptions, ['filterName', 'hotDeskSelected']);
  const DDFilterObject = find(filterOptions, ['filterName', 'dedicatedDeskSelected']);
  const POFilterObject = find(filterOptions, ['filterName', 'personalOfficeSelected']);
  const DiscountExpiryFilterObject = find(filterOptions, ['filterName', 'DiscountExpiringSoon']);
  const HasDiscountFilterObject = find(filterOptions, ['filterName', 'No Discount']);

  returnedArr = DiscountExpiryFilterObject?.isSelected
    ? filter(objArr, eachObj => computeIsDiscountExpiringWithin14Days(eachObj?.expDate))
    : objArr;

  returnedArr = HasDiscountFilterObject?.isSelected
    ? filter(returnedArr, obj => computeNoDiscount(obj))
    : returnedArr;

  if (HDFilterObject || DDFilterObject || POFilterObject) {
    // Filtering hot desk if filter param present.
    const hotDeskArr = HDFilterObject
      ? filterHelperSelected(
          returnedArr,
          HDFilterObject,
          'productType',
          nonPersonalOfficeProductType.hotDesk,
        )
      : [];
    // Filtering dedicated desk if filter param present.
    const dedicatedDeskArr = DDFilterObject
      ? filterHelperSelected(
          returnedArr,
          DDFilterObject,
          'productType',
          nonPersonalOfficeProductType.dedicatedDesk,
        )
      : [];
    // Filter personal office if filter param present.
    let personalOfficeArr: TableViewDiscountItem[] = [];
    if (POFilterObject) {
      personalOfficeArr = reject(
        returnedArr,
        eachObj =>
          eachObj?.productType === nonPersonalOfficeProductType.hotDesk ||
          eachObj?.productType === nonPersonalOfficeProductType.dedicatedDesk,
      );
      personalOfficeArr = filterHelperRange(personalOfficeArr, POFilterObject, 'capacity', false);
    }
    returnedArr = hotDeskArr.concat(dedicatedDeskArr).concat(personalOfficeArr);
  }

  forEach(filterOptions, (filterObject: PriceableItemFilter) => {
    switch (filterObject?.filterName) {
      case 'internalRoom':
        returnedArr = filterHelperSelected(
          returnedArr,
          filterObject,
          'internalRoomPresent',
          filterObject?.isSelected?.toString() === 'true', // can be string or boolean
        );
        returnedArr = filterObject?.isSelected
          ? filterHelperRange(returnedArr, filterObject, 'internalRoomCount', false)
          : returnedArr;
        break;
      case 'hasWindow':
        returnedArr = filterHelperSelected(
          returnedArr,
          filterObject,
          'hasWindow',
          filterObject?.isSelected?.toString() === 'true', // can be string or boolean
        );
        break;
      case 'sku':
        returnedArr = filterHelperRange(returnedArr, filterObject, 'locationSKUOccupancy', true);
        break;
      default:
        break;
    }
  });

  return returnedArr;
};

/**
 * -- END GRID FILTERS
 */

export const getCurveStatus = (curve: Curve): DiscountCurveStatus =>
  curve.isActive ? DiscountCurveStatus.ACTIVE : DiscountCurveStatus.INACTIVE;

export const calculateCurveLabel = (
  curve: Curve,
  corner: boolean | 'right' | 'left' | undefined = 'right',
): LabelProps => {
  if (curve.isActive) {
    return {
      color: 'green',
      content: 'Active',
      circular: true,
      corner,
      tag: true,
    };
  }
  return {
    color: 'red',
    content: 'In-active',
    circular: true,
    corner,
    tag: true,
  };
};
