import * as React from 'react';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { Input } from 'semantic-ui-react';
import {
  TableFilter,
  TableFilterBarLoadingOverlay,
  TableFilterBarWrapper,
  TableFilterColumnsWrap,
  TableFilterRow,
} from '../../../../styles/sharedTableFilterBar.styled';
import { fetchStateSelectorBreakevenStore } from '../../store/modules/breakevenItems/breakevenItems.selector';

function TableFilterBarBreakeven(props: { agGridApi: any }): ReactElement {
  // STATE PROPS
  const fetchBreakevenState = useSelector(fetchStateSelectorBreakevenStore);

  const handleQuickFilter = (event: any) => {
    props.agGridApi.setQuickFilter(event.target.value);
  };

  return (
    <TableFilterBarWrapper>
      {fetchBreakevenState ? <TableFilterBarLoadingOverlay /> : ''}
      <TableFilterColumnsWrap>
        <TableFilterRow>
          <TableFilter>
            <Input
              className="reservable-search"
              icon="search"
              iconPosition="left"
              placeholder="Search Locations"
              onChange={(event: any): void => handleQuickFilter(event)}
              onKeyPress={(event: any): void => handleQuickFilter(event)}
            />
          </TableFilter>
        </TableFilterRow>
        <TableFilterRow />
      </TableFilterColumnsWrap>
    </TableFilterBarWrapper>
  );
}

export default TableFilterBarBreakeven;
