import { GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import TableLoadingRenderer from '../../../../../sharedComponents/tableComponent/tableLoadingRenderer';
import { formatDate, getMainMenuItemsWithoutReset } from '../../../../../utils/helpers';
import EmptyTableComponentRenderer from '../../../../../sharedComponents/tableComponent/tableEmpty';

const dateFormatting = (params: ValueFormatterParams): string => {
  if (!params.value) {
    return '-';
  }
  return formatDate(params.value);
};

export const defaultColDef: ColDef = {
  flex: 1,
  menuTabs: ['generalMenuTab'],
  suppressMovable: true,
  resizable: true,
  sortable: false,
  filter: true,
};

export const discountGridColumnDefs = [
  {
    colId: 'selectionCheckbox',
    headerName: '',
    width: 40,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    minWidth: 40,
    maxWidth: 40,
    suppressMenu: true,
    resizable: false,
    lockPosition: true,
  },
  {
    headerName: 'Discount Uuid',
    field: 'discountUuid',
    minWidth: 280,
  },
  {
    headerName: 'Reservable Uuid',
    field: 'reservableUuid',
    minWidth: 280,
  },

  {
    headerName: 'Discount',
    field: 'discount',
    minWidth: 100,
    width: 200,
  },
  {
    headerName: 'Status',
    field: 'status',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Created At',
    field: 'createdAt',
    minWidth: 130,
    width: 170,
    valueFormatter: dateFormatting,
  },
  {
    headerName: 'Scheduled For',
    field: 'scheduledFor',
    minWidth: 130,
    width: 170,
    valueFormatter: dateFormatting,
  },
  {
    headerName: 'Term Length',
    field: 'termLength',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Expiration Status',
    field: 'expirationStatus',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Expiration Date',
    field: 'expirationDate',
    minWidth: 130,
    width: 170,
    valueFormatter: dateFormatting,
  },
];

export const priceGridColumnDefs = [
  {
    colId: 'selectionCheckbox',
    headerName: '',
    width: 40,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    minWidth: 40,
    maxWidth: 40,
    suppressMenu: true,
    resizable: false,
    lockPosition: true,
  },
  {
    headerName: 'Price Uuid',
    field: 'priceUuid',
    minWidth: 280,
  },
  {
    headerName: 'Reservable Uuid',
    field: 'reservableUuid',
    minWidth: 280,
  },

  {
    headerName: 'Price',
    field: 'listPrice',
    minWidth: 100,
    width: 200,
  },
  {
    headerName: 'Currency',
    field: 'currencyIsoCode',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Status',
    field: 'status',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Last Message',
    field: 'lastMessage',
    minWidth: 130,
    width: 170,
  },
  {
    headerName: 'Created At',
    field: 'createdAt',
    minWidth: 130,
    width: 170,
    valueFormatter: dateFormatting,
  },
  {
    headerName: 'Scheduled For',
    field: 'scheduledFor',
    minWidth: 130,
    width: 170,
    valueFormatter: dateFormatting,
  },
];

export const gridOptions: GridOptions = {
  rowSelection: 'multiple',
  defaultColDef,
  suppressMaxRenderedRowRestriction: true,
  suppressAnimationFrame: true,
  suppressContextMenu: true,
  suppressCellFocus: true,
  suppressRowClickSelection: true,
  suppressRowHoverHighlight: true,
  suppressAggFuncInHeader: true,
  groupDisplayType: 'custom',
  rowHeight: 56,
  groupSelectsChildren: true,
  groupSelectsFiltered: true,
  suppressDragLeaveHidesColumns: true,
  showOpenedGroup: true,
  groupDefaultExpanded: 2,
  loadingOverlayComponent: TableLoadingRenderer,
  noRowsOverlayComponent: EmptyTableComponentRenderer,
  getMainMenuItems: getMainMenuItemsWithoutReset,
  debounceVerticalScrollbar: true,
  suppressColumnVirtualisation: true,
};
