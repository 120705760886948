import { ProcessCellForExportParams } from 'ag-grid-community';
import { find } from 'lodash';
import { Unassigned } from '../../../../utils/sharedTypes';
import { isOccupiedVF } from './discountAGGrid.helpers';
import {
  formatDate,
  getPhysicalAttributesDetails,
  processHeaderCallback,
} from '../../../../utils/helpers';
import {
  isPercentageNull,
  roundToAtMost2Digit,
} from '../../../pricing/standardPricing/components/helpers';
import { Location, PriceableItem } from '../../../../generated/voyager/graphql';

export const hiddenColumns = [
  'm2MDiscountDV',
  'sixMDiscountDV',
  'twelveMDiscountDV',
  'twentyFourMDiscountDV',
  'thirtySixMDiscountDV',
  'm2MDiscountNP',
  'sixMDiscountNP',
  'twelveMDiscountNP',
  'twentyFourMDiscountNP',
  'thirtySixMDiscountNP',
];

const allNumberVF = [...hiddenColumns, 'monthsVacant', 'reservationGrossPrice'];

export const discountsTableDownloadConfig = (
  locationDetails: Location[],
  allDiscountItems: PriceableItem[] | Unassigned,
) => ({
  skipRowGroups: true,
  columnKeys: [
    'rowSelector',
    'locationName',
    'sku',
    'officeName',
    'physicalAttributes',
    'physicalAttributes2',
    'marketPrice',
    'lowestNetPrice',
    'locationSKUOccupancy',
    'marketSKUOccupancy',
    'isOccupied',
    'monthsVacant',
    'reservationGrossPrice',
    'reservationNetPrice',
    'lastPublished',
    'expDate',
    'm2MDiscount',
    'sixMDiscount',
    'twelveMDiscount',
    'twentyFourMDiscount',
    'thirtySixMDiscount',
    'm2MDiscountDV',
    'sixMDiscountDV',
    'twelveMDiscountDV',
    'twentyFourMDiscountDV',
    'thirtySixMDiscountDV',
    'm2MDiscountNP',
    'sixMDiscountNP',
    'twelveMDiscountNP',
    'twentyFourMDiscountNP',
    'thirtySixMDiscountNP',
    'curvesApplied',
  ],
  processCellCallback: (params: ProcessCellForExportParams) => {
    // Location name
    if (params.column.getColId() === 'rowSelector') {
      return params.node?.data.id;
    } else if (params.column.getColId() === 'locationName') {
      const location = find(locationDetails, ['id', params.value]) ?? ({} as Location);
      return location?.name;
      // Physical attributes
    } else if (params.column.getColId() === 'physicalAttributes') {
      return getPhysicalAttributesDetails(params, 'window');
    } else if (params.column.getColId() === 'physicalAttributes2') {
      return getPhysicalAttributesDetails(params, 'internalRoomPresent');
    } else if (params.column.getColId() === 'lastPublished') {
      if (!params.value) {
        return '-';
      }

      return formatDate(params.value);
    } else if (params.column.getColId() === 'expDate') {
      if (!params.value) {
        return '-';
      }
      const location = find(locationDetails, ['id', params.node?.data?.location?.id]);

      return formatDate(params.value, location?.timeZone ?? undefined);
    } else if (allNumberVF.includes(params.column.getColId())) {
      return params.value ? roundToAtMost2Digit(params.value) : '-';
    } else if (params.column.getColId() === 'isOccupied') {
      return isOccupiedVF(params.value);
    } else if (
      params.column.getColId() === 'locationSKUOccupancy' ||
      params.column.getColId() === 'marketSKUOccupancy'
    ) {
      return isPercentageNull(params.value);
    } else if (params.column.getColId() === 'lowestNetPrice') {
      return params.value ? `${params.value.formatted}` : '-';
    } else if (params.column.getColId() === 'curvesApplied') {
      const allCurves = find(allDiscountItems, { id: params.node?.data?.id })?.appliedCurves ?? [];
      return allCurves ? allCurves.map(eachCurve => eachCurve.curve.name).toString() : '';
    }
    return params.value;
  },
  processHeaderCallback,
});
