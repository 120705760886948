import axios, { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { Action } from 'redux-actions';
import AppConfig from '../../../../../config';
import { getAuthToken } from '../../../../../index';
import { errorHandlerActive } from '../../../../../utils/errorHandling/helpers';
import {
  FETCH_SYNC_ATTEMPTS_SUCCESS,
  FETCH_SYNC_ATTEMPTS_WITH_FILTER,
  RE_PUBLISH_PRICE,
  RE_PUBLISH_PRICE_END,
} from './priceSyncAttempts.ducks';
import { DataWithCallback, Unassigned } from '../../../../../utils/sharedTypes';
import { PriceSyncAttemptFilter } from '../../entities/priceSyncAttempt';
import {
  priceSyncAttemptsFilterSelector,
  priceSyncAttemptsPageInfoSelector,
} from './priceSyncAttempts.selector';
import { PageInfo } from '../../../../../generated/voyager/graphql';

const getAllPriceSyncAttemptsWithFilterRequest = (
  filter: any | Unassigned,
  page: any | Unassigned,
): Promise<AxiosResponse<any>[]> => {
  const getSyncAttemptsUrl = `${AppConfig.BACKEND_IPP_REST_URL}/admin/price-sync-attempt`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };
  const params = {
    size: page.size,
    page: page.page,
  };

  return axios
    .post(
      getSyncAttemptsUrl,
      {
        priceUuid: filter?.priceUuid,
        reservableUuid: filter?.reservableUuid,
        status: filter?.status,
        startCreatedAt: filter?.startCreatedAt,
        endCreatedAt: filter?.endCreatedAt,
        startScheduledFor: filter?.startScheduledFor,
        endScheduledFor: filter?.endScheduledFor,
      },
      { headers, params },
    )
    .catch(error => error.response);
};

const publishPriceSyncAttemptsRequest = (ids: String[]): Promise<AxiosResponse<any>[]> => {
  const getSyncAttemptsUrl = `${AppConfig.BACKEND_IPP_REST_URL}/admin/price-sync-attempt/re-publish`;
  const headers = {
    'Content-Type': 'application/json;charset=utf-8',
    authorization: `Bearer ${getAuthToken()}`,
  };

  return axios.post(getSyncAttemptsUrl, ids, { headers }).catch(error => error.response);
};

export function* fetchSyncAttemptDataWithFilter() {
  try {
    const filter: PriceSyncAttemptFilter = yield select(priceSyncAttemptsFilterSelector);
    const pageInfo: PageInfo = yield select(priceSyncAttemptsPageInfoSelector);
    const response: AxiosResponse | undefined = yield call(
      getAllPriceSyncAttemptsWithFilterRequest,
      filter,
      pageInfo,
    );
    if (response?.status === 200 || response?.status === 404) {
      yield put({ type: FETCH_SYNC_ATTEMPTS_SUCCESS, data: response.data });
    } else {
      toast.error('Fetching error');
    }
  } catch (e: any) {
    toast.error(`Fetching error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export function* rePublishSyncAttempt(action: Action<DataWithCallback<string[]>>) {
  try {
    const ids: string[] = action.payload.data;
    const response: AxiosResponse | undefined = yield call(publishPriceSyncAttemptsRequest, ids);
    if (response?.status === 200) {
      if (response.data.length === 0) {
        toast.success('Re-publishing successful');
      } else {
        toast.error(`Re-publishing failed for ${response.data}`);
      }
    } else {
      toast.error(`Re-publishing failed: ${response?.data?.message}`);
    }
    yield put({ type: RE_PUBLISH_PRICE_END });
    action.payload.successCallback();
    fetchSyncAttemptDataWithFilter();
  } catch (e: any) {
    toast.error(`Re-publishing error - ${get(e, 'message', 'Unknown Error')}`);
    errorHandlerActive(new Error(e));
  }
}

export default function* priceRePublishSaga(): any {
  yield all([takeLatest(FETCH_SYNC_ATTEMPTS_WITH_FILTER, fetchSyncAttemptDataWithFilter)]);
  yield all([takeLatest(RE_PUBLISH_PRICE, rePublishSyncAttempt)]);
}
