import styled, { css } from 'styled-components';
import Sprite from '@wework/dieter-ui/dist/themes/dieter/assets/images/sprite.svg';
import {
  blue,
  darkBlue,
  darkGray,
  devEnvOrange,
  filterBorderGray,
  lightestBlue,
  pureBlack,
} from './colors';
import Open from '../assets/open.svg';

const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  tabletL: '1024px',
  laptop: '1280px',
  laptopL: '1440px',
  desktop: '1600px',
  desktopL: '1980px',
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
};

/* HELPERS */
export const ListReset = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;
export const FlexHorizontalHelper = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;
export const FlexHorizontalWrappedHelper = css`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;
export const FlexVerticalHelper = css`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`;
export const FlexHorizontalEndHelper = css`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`;

/* MAIN LAYOUT GRID COMPONENTS */
export const AppLayout = styled.section`
  position: relative;
  * {
    box-sizing: border-box;
  }
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: white;
  display: grid;
  grid-template-columns: fit-content(228px) 8px 1fr 8px;
  grid-template-rows: 68px 19px 1fr 8px;
  grid-gap: 0 0;
  justify-content: stretch;
  align-items: stretch;
`;
export const EnvLabel = styled.div`
  width: 100px;
  position: absolute;
  top: 0;
  left: calc(50% - 50px);
  z-index: 9;
  background: ${devEnvOrange};
  border-radius: 0 0 4px 4px;
  text-align: center;
  line-height: 16px;
  font-size: 12px;
  font-weight: 700;
  color: ${pureBlack};
`;
export const MainRegionStyled = styled.div`
  grid-column: 2/5;
  grid-row: 1/5;
  z-index: 2;
  overflow-y: auto;
  padding-bottom: 0;
`;
export const OverflowHack = styled.div`
  position: relative;
`;
export const SummaryWrapper = styled.div`
  position: relative;
  height: calc(100% - 66px);
  padding: 0;
  margin: 0 40px;
`;
export const GridCustomWrapper = styled.div`
  position: relative;
  max-width: 100%;
  padding: 0;
  border: 0;
  margin: 0;
`;

// TODO: BC:  came from STANDARD pricing side panel components.
export const SidePanelWrapper = styled.aside`
  div[style*='box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 4px'] {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16) !important;
    border: 0 !important;
    border-left: 1px solid ${lightestBlue}!important;
    div[style*='cursor: col-resize'] {
      cursor: default !important;
    }
  }
`;
export const ClosePanelButton = styled.button`
  position: absolute;
  top: 28px;
  right: 20px;
  z-index: 2;
  display: block;
  width: 16px;
  height: 16px;
  padding: 0;
  background: none;
  border: none;
  margin: 0;
  cursor: pointer;
  outline: none;
  &::before {
    display: block;
    width: 24px;
    height: 24px;
    top: -4px;
    left: -4px;
    position: relative;
    content: '';
    background-color: ${pureBlack};
    -webkit-mask: url(${Sprite}#x);
    mask: url(${Sprite}#x);
  }
`;
export const PanelHeader = styled.header`
  padding: 20px 20px 15px;
`;
export const PanelTitle = styled.h2`
  padding: 0;
  margin: 0;
  color: ${darkBlue};
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
`;
export const PanelBody = styled.div`
  overflow-y: auto;
  height: calc(100% - 186px);
  // Details Panel
  &.details-body {
    overflow-y: hidden;
    height: calc(100% - 98px);
  }
  .ui.pointing.secondary.menu {
    position: relative;
    margin: 0;
    .item {
      flex: 1 1 50%;
    }
    &::before {
      display: block;
      position: absolute;
      bottom: -11px;
      left: 0;
      right: 18px;
      z-index: 2;
      content: '';
      height: 10px;
      background: linear-gradient(-180deg, white 0%, rgba(255, 255, 255, 0.5) 100%);
    }
  }
  .ui.bottom.attached.segment.tab {
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 200px);
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    overflow-y: auto;
  }
`;
export const PanelFilterList = styled.ul`
  width: calc(100% - 20px);
  ${ListReset};
  ${FlexVerticalHelper};
  &.details-list {
    width: 299px;
    > li {
      margin-top: 18px;
    }
  }
`;
export const PanelFilterItem = styled.li`
  flex: 0 0 auto;
  width: 100%;
  padding: 12px 0 12px 20px;
  border-bottom: 1px solid ${filterBorderGray};
  &:last-of-type {
    border-bottom: none;
  }
  &.open {
    padding-bottom: 28px;
    > .filter-header .toggle-accordion-icon::before {
      transform: rotate(180deg);
    }
    > .filter-body {
      height: auto;
    }
  }
  &.closed {
    > .filter-body {
      height: 0;
    }
  }
`;
export const FilterHeader = styled.div`
  > button {
    ${FlexHorizontalHelper};
    justify-content: space-between;
    width: 100%;
    padding: 0;
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    outline: none;
    text-align: left;
    span {
      display: block;
      flex: 1 1 auto;
      margin: 0;
      line-height: 28px;
      font-size: 16px;
      font-weight: 400;
      color: ${darkBlue};
    }
    i {
      display: block;
      flex: 0 0 auto;
      width: 16px;
      height: 16px;
      padding: 0;
      background: none;
      border: none;
      margin: 6px 0 0 0;
      cursor: pointer;
      outline: none;
      &::before {
        display: block;
        width: 16px;
        height: 17px;
        position: relative;
        content: '';
        background-color: ${darkBlue};
        -webkit-mask: url(${Open});
        mask: url(${Open});
      }
    }
  }
`;
export const ActiveFilterValues = styled.p`
  padding: 0;
  margin: 0;
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
  color: ${blue};
`;
export const FilterBody = styled.div`
  overflow: hidden;
  .ui.checkbox input.hidden {
    z-index: 1;
  }
  .ui.checkbox input[type='checkbox'] {
    top: 4px;
    opacity: unset !important;
  }
`;
export const FilterItemOption = styled.div`
  min-height: 24px;
  padding: 0;
  margin: 14px 0 0;
  > .ui.checkbox {
    width: 100%;
    svg {
      display: inline-block;
      margin: -1px 10px 0 4px;
    }
    input[type='checkbox'] + label {
      color: ${darkGray} !important;
    }
    input[type='checkbox']:checked + label {
      color: ${darkBlue} !important;
    }
  }
  .ui.radio.checkbox {
    input[type='radio'] {
      color: ${darkGray};
      z-index: 1 !important;
      opacity: 1 !important;
      top: 3px;
      position: inherit;
    }
  }
`;
export const RangeBox = styled.div`
  display: block;
  > div {
    position: relative;
    margin: 18px 0 0;
  }
  > ul {
    ${ListReset};
    ${FlexHorizontalHelper};
    justify-content: space-between;
    > li {
      button {
        display: block;
        height: 24px;
        padding: 0 2px;
        background: none;
        border: none;
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        outline: none;
        cursor: pointer;
        &.apply-button {
          color: ${blue};
        }
      }
    }
  }
`;
export const RangeItem = styled.div`
  ${FlexHorizontalHelper};
  justify-content: space-between;
  padding: 0;
  margin: 0;
  > .ui.input {
    min-width: 120px;
    width: 120px;
  }
  > div {
    margin-top: 10px;
    > input {
      min-width: 120px;
      width: 120px;
    }
  }
  > b {
    display: block;
    width: 16px;
    height: 2px;
    background-color: ${darkGray};
    margin: 40px 4px 0;
  }
`;
export const InputLabel = styled.label`
  position: absolute;
  top: -7px;
  left: 8px;
  z-index: 10;
  display: inline-block;
  padding: 0 6px 0 4px;
  background: white;
  font-family: Apercu Mono, monospace;
  font-size: 12px;
  height: 12px;
  line-height: 12px;
  color: ${darkGray};
  &.min-label {
    left: 8px;
  }
  &.max-label {
    left: auto;
    right: 80px;
  }
`;
export const PanelFooter = styled.footer`
  height: 104px;
  padding: 32px 20px;
  bottom: 32px;
  position: relative;
  > ul {
    ${ListReset};
    ${FlexHorizontalHelper};
    li {
      flex: 1 1 50%;
      text-align: left;
      &:last-of-type {
        text-align: right;
      }
      .ui.button {
        flex: 0 0 auto;
        margin: 0;
      }
    }
  }
`;
export const AgGridWrapper = styled.div`
  height: calc(100vh - 100px);
  padding-bottom: 70px;
  margin: 0 40px;
`;
export const MarginWrapper = styled.div`
  margin: 0 40px;
`;
