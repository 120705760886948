import { FetchState, Unassigned } from '../../../../utils/sharedTypes';
import {
  BaseOverrideInput,
  Curve,
  DiscountHistory,
  Location,
  Operator,
  PriceableItem,
  TermType,
} from '../../../../generated/voyager/graphql';

export enum DiscountCurveStatus {
  ACTIVE,
  INACTIVE,
}

export interface DiscountItemsSubset {
  allDiscountItems: GridDiscountPagedItem[];
  allPrevDiscountItems: GridDiscountPagedItem[];
  batchFetchInProgressDiscount: boolean;
  sidePanelParam: boolean;
  currentSelectedPriceableItemId: string | null;
  termTypes: TermType[];
  overrides: Override[];
  publish: boolean;
  curves: Curve[];
  curvesLoading: boolean;
  mutateCurvesLoading: boolean;
  createCurvesLoading: boolean;
  updateCurvesLoading: boolean;
  allDiscountCurves: Curve[];
  batchFetchInProgressAllDiscountCurves: boolean;
  curveFilters: Set<DiscountCurveStatus>;
  userOperatorsLoading: boolean;
  userOperators: Operator[];
  discountHistory: DiscountHistory[] | null;
  discountHistoryFetchState: FetchState;
  cancelFutureDiscountIds: string[];
  cancelFutureDiscountLoading: boolean;
}

export interface GridDiscountPagedItem extends PriceableItem {
  page: number;
}

export interface Override {
  id: string;
  baseDiscounts: BaseOverrideInput[] | null;
  name: string;
  page: number;
  originalDiscounts: BaseOverrideInput[] | null;
  location: Omit<Location, 'currencyIsoCode' | 'market' | 'maxDiscounts' | 'name' | 'skuOccupancy'>;
}

export interface AttributesDiscountItems {
  name: string;
  value: boolean;
}

export interface TableViewDiscountColumn {
  base: number | null;
  baseOrOverride: number | null;
  distribution: FlattenDiscountDistribution[];
  isBaseDiscountOverridden: boolean;
  futureOverride: FutureOverride | null;
  futureBaseDiscount: BaseDiscount | null;
}

export interface TableViewLowestNetPriceColumn {
  value: number;
  formatted: string;
}

export interface FutureOverride {
  base: number;
  expDate: any;
  publishedDate: any;
  createdBy: string;
  updatedAt: any;
}

export interface BaseDiscount {
  base: number;
  publishedDate: any;
  createdBy: string;
}

export interface FutureDiscountDataCombo {
  futureDiscount: BaseDiscount | FutureOverride;
  termName: string;
}

export interface FlattenDiscountDistribution {
  month: number;
  base: number | undefined;
}

export interface TableViewDiscountItemDiscountData {
  m2MDiscount: TableViewDiscountColumn;

  sixMDiscount: TableViewDiscountColumn;

  twelveMDiscount: TableViewDiscountColumn;

  twentyFourMDiscount: TableViewDiscountColumn;

  thirtySixMDiscount: TableViewDiscountColumn;

  curvesApplied: number;
}

export interface TableViewDiscountItem extends TableViewDiscountItemDiscountData {
  id: string;

  officeName: string;

  sku: string;

  page: number;

  capacity: string;

  productType: string;

  hasWindow: boolean;

  physicalAttributes: AttributesDiscountItems[];

  internalRoomCount: number;

  internalRoomPresent: boolean;

  marketPrice: number;

  lowestNetPrice: TableViewLowestNetPriceColumn;

  locationSKUOccupancy: number;

  marketSKUOccupancy: number;

  isOccupied: boolean;

  monthsVacant: number;

  reservationGrossPrice: number;

  lastPublished: string | null;

  expDate: string | null;

  location: Omit<Location, 'currencyIsoCode' | 'market' | 'maxDiscounts' | 'name' | 'skuOccupancy'>;

  currencyIsoCode: string | Unassigned;

  timeZone?: string;
}
