import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { isEmpty } from 'lodash';
import { pluralize } from 'apollo/lib/utils';
import { Confirm } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import {
  PublishWorkingPriceInput,
  WorkingPriceScope,
} from '../../../../../../generated/voyager/graphql';
import { getAllSelectedLocations } from '../../../../../../sharedStore/modules/locations/locations.selector';
import { standardPricingSelectedNodesSelector } from '../../../store/modules/standardPricingItems/standardPricingItems.selector';
import { WORKING_PRICE_PUBLISH } from '../../../store/modules/publishPrice/publishPrice.ducks';
import { ConfirmProps } from '../../../../../../sharedStore/entities/confirmProps';
import { trackAnalytics } from '../../../../../../utils/analytics/helpers';

interface PublishWorkingPricesConfirmProps extends ConfirmProps {
  publishDate?: Date;
}

function PublishWorkingPricesConfirm(props: PublishWorkingPricesConfirmProps): ReactElement {
  const selectedLocations = useSelector(getAllSelectedLocations);
  const selectedNodes = useSelector(standardPricingSelectedNodesSelector);

  // DISPATCH PROPS
  const dispatch = useDispatch();

  const publishWorkingPriceLocation = useCallback(
    (payload: PublishWorkingPriceInput) => dispatch({ type: WORKING_PRICE_PUBLISH, payload }),
    [dispatch],
  );

  /**
   * This method is used for batch update the backend price update.
   */
  const publishPrices = () => {
    const dateString = props.publishDate ? format(props.publishDate, 'yyyy-MM-dd') : null;
    // Track batch Edit options and save.
    trackAnalytics('Working Prices Publish', {
      workflow: 'Working Prices Publish',
      object_type: 'date',
      object_name: 'Working Prices Publish',
      object_value: dateString,
      location_uuid: selectedLocations,
      selected_reservables: selectedNodes.length,
    });
    if (!isEmpty(selectedNodes)) {
      publishWorkingPriceLocation({
        ids: selectedNodes.map(node => node.data.id),
        scope: WorkingPriceScope.PRICEABLE_ITEM,
        validFrom: dateString,
      });
    } else {
      publishWorkingPriceLocation({
        ids: selectedLocations.map(location => location.id),
        scope: WorkingPriceScope.LOCATION,
        validFrom: dateString,
      });
    }
    props.closeModal();
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={props.closeModal}
      onConfirm={publishPrices}
      cancelButton={{ content: 'Cancel', className: 'cancel-confirmation' }}
      closeIcon
      confirmButton={'Publish'}
      header="Publish Prices"
      content={
        <div>
          <p>
            {`You are going to publish ${
              isEmpty(selectedNodes)
                ? `all prices in ${pluralize(selectedLocations.length, 'location')}`
                : pluralize(selectedNodes.filter(node => node.data.workingPrice).length, 'price')
            }`}
          </p>
          <p>Do you want to proceed?</p>
        </div>
      }
    />
  );
}

export default PublishWorkingPricesConfirm;
