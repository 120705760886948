import * as React from 'react';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { TextField } from '@wework/ray2';
import { Confirm, Icon, Popup } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { GridApi, ICellRendererParams, IRowNode } from 'ag-grid-community';
import { findIndex, isEmpty, sortBy } from 'lodash';
import { format } from 'date-fns-tz';
import { intlCurrencyFormatter, validateInputNumberValue } from 'utils/helpers';
import {
  currentOnDemandChangeLog,
  currentOnDemandChangeLogFetchState,
  onDemandPricingEditMode,
  onDemandSetPricesLoadingSelector,
} from '../../store/modules/onDemand/onDemandPricing.selector';
import {
  inputNumberFormatter,
  parseFloatSafe,
  roundToAtMost2Digit,
  toTitleCase,
} from '../../../standardPricing/components/helpers';
import {
  ChangelogList,
  ChangelogListItem,
  ChangelogTitle,
  CustomTableBodyCell,
  EditWrap,
} from '../../../standardPricing/components/pricingTable/pricingTable.styled';
import {
  CREATE_ON_DEMAND_WORKING_PRICE,
  FETCH_CURRENT_ON_DEMAND_CHANGE_LOG,
} from '../../store/modules/onDemand/onDemandPricing.ducks';
import Spinner from '../../../../../assets/spinner.svg';
import { DataWithCallback, Unassigned } from '../../../../../utils/sharedTypes';
import {
  GeoHierarchiesChangeLogQueryVariables,
  GeoHierarchy,
  PriceHierarchy,
  PriceHierarchyChange,
  PriceHierarchyInput,
  WorkingPriceHierarchy,
} from '../../../../../generated/voyager/graphql';

const OnDemandPriceCellRenderer = (params: ICellRendererParams): ReactElement => {
  const editModeSelector = useSelector(onDemandPricingEditMode);
  const changeLogFetchState = useSelector(currentOnDemandChangeLogFetchState);
  const changeLog = useSelector(currentOnDemandChangeLog);
  const loading = useSelector(onDemandSetPricesLoadingSelector);
  const [inputVal, setInputVal] = useState<number | null>(null);
  const [displayVal, setDisplayVal] = useState<string>('');
  const [productTypeId, setProductTypeId] = useState<string>('');
  const [sortedPriceableItemChangeLog, setSortedPriceableItemChangeLog] = useState<
    PriceHierarchyChange[]
  >([]);
  const [workingPricesValue, setWorkingPricesValue] = useState<WorkingPriceHierarchy | null>(null);
  const [bulkPricePopupOpen, setBulkPricePopupOpen] = useState<boolean>(false);

  const dispatch = useDispatch();
  const createOnDemandWorkingPrice = useCallback(
    (payload: DataWithCallback<PriceHierarchyInput[]>) =>
      dispatch({ type: CREATE_ON_DEMAND_WORKING_PRICE, payload }),
    [dispatch],
  );

  const fetchOnDemandItemChangeLog = useCallback(
    (payload: GeoHierarchiesChangeLogQueryVariables) =>
      dispatch({ type: FETCH_CURRENT_ON_DEMAND_CHANGE_LOG, payload }),
    [dispatch],
  );

  const setValHelper = () => {
    const id: string[] = params.column?.getColId()?.split('|') ?? [];
    const productTId = id[0];
    setProductTypeId(productTId);

    const originalValWorkingPrice = params.data.workingPrices.find(
      (price: WorkingPriceHierarchy) => price.productId === productTId,
    );
    const originalValCurrentPrice = params.data.currentPrices.find(
      (price: PriceHierarchy) => price.productId === productTId,
    );
    setWorkingPricesValue(originalValWorkingPrice);

    const currentPriceValue = originalValWorkingPrice
      ? originalValWorkingPrice.price
      : originalValCurrentPrice?.price;
    const val =
      typeof currentPriceValue === 'number' ? roundToAtMost2Digit(currentPriceValue) : null;
    setInputVal(val || null);
    setDisplayVal(
      val ? intlCurrencyFormatter(params.data.currencyIsoCode, roundToAtMost2Digit(val)) : '-',
    );
  };
  useEffect(setValHelper, [params]);

  const processNewChangeLog = () => {
    setSortedPriceableItemChangeLog(sortBy(changeLog[0]?.workingPrices[0]?.changelog, 'createdAt'));
  };

  useEffect(processNewChangeLog, [changeLog]);

  const createOnDemandWorkingPriceForGeoGroupings = (
    geoGroupingIds: string[],
    withFailCallback: boolean,
    successCallback?: () => void,
  ) => {
    createOnDemandWorkingPrice({
      data: geoGroupingIds.map(geoGroupingId => ({
        id: {
          geoGroupingId,
          productId: productTypeId,
        },
        price: inputVal || null,
      })),
      successCallback: () => {
        // Updating the grid with new data.
        const gridApi: GridApi = params.api;
        geoGroupingIds.forEach(geoGroupingId => {
          const rowNode: IRowNode | Unassigned = gridApi.getRowNode(geoGroupingId);
          if (rowNode) {
            const prevData = rowNode.data;

            const idx = findIndex(
              prevData.workingPrices,
              (eachItem: WorkingPriceHierarchy) => eachItem?.productId === productTypeId,
            );
            let workingPricesArr;

            if (idx > -1) {
              workingPricesArr = [...prevData.workingPrices];
              workingPricesArr.splice(idx, 1, {
                ...prevData?.workingPrices[idx],
                price: inputVal || null,
              } as WorkingPriceHierarchy);
            } else {
              workingPricesArr = [
                ...prevData.workingPrices,
                {
                  price: inputVal || null,
                  productId: productTypeId,
                } as WorkingPriceHierarchy,
              ];
            }

            const newData: GeoHierarchy = {
              ...prevData,
              workingPrices: [...workingPricesArr],
            };
            rowNode.setData(newData);
            gridApi.refreshCells({ rowNodes: [rowNode] });
          }
        });
        if (successCallback) {
          successCallback();
        }
      },
      failCallback: withFailCallback ? () => setBulkPricePopupOpen(true) : undefined,
    });
  };

  const getOriginalPrice = () => {
    const originalValWorkingPrice = params.data.workingPrices.find(
      (price: WorkingPriceHierarchy) => price.productId === productTypeId,
    );
    const originalValCurrentPrice = params.data.currentPrices.find(
      (price: PriceHierarchy) => price.productId === productTypeId,
    );

    return originalValWorkingPrice ? originalValWorkingPrice.price : originalValCurrentPrice?.price;
  };

  const onSubmit = () => {
    // Saving the new data.
    const prevWorkingPrice = getOriginalPrice();

    if (inputVal !== prevWorkingPrice && (inputVal || prevWorkingPrice)) {
      createOnDemandWorkingPriceForGeoGroupings([params.data.id], params.data.type !== 'Building');
    }
  };

  const changeLogListItems = () => {
    if (changeLogFetchState?.loading) {
      return <Icon as="img" src={Spinner} />;
    }

    return !isEmpty(sortedPriceableItemChangeLog) ? (
      sortedPriceableItemChangeLog.map(changeLogItem => (
        <ChangelogListItem key={changeLogItem.id}>
          {changeLogItem.price
            ? intlCurrencyFormatter(changeLogItem.currencyIsoCode ?? '', changeLogItem.price)
            : '-'}{' '}
          on {format(new Date(changeLogItem.createdAt), 'Pp')} by
          {` ${toTitleCase(changeLogItem.createdBy)}`}
        </ChangelogListItem>
      ))
    ) : (
      <div>No Change Log</div>
    );
  };

  const cleanAndSetVal = (event: any) => {
    const currentValue = inputNumberFormatter(event?.target?.value);
    const field = params.colDef?.field ?? '';
    validateInputNumberValue(
      event,
      field,
      {
        wholeNumberValues: {
          fields: ['Current Prices'],
        },
      },
      () => {
        setInputVal(parseFloatSafe(currentValue) ?? null);
        setDisplayVal(
          currentValue
            ? new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: params.data.currencyIsoCode,
              }).format(Number(currentValue))
            : '-',
        );
      },
    );
  };

  const closeBulkPricePopup = () => {
    cleanAndSetVal({ target: { value: getOriginalPrice().toString() } });
    setBulkPricePopupOpen(false);
  };

  return params.data ? (
    <CustomTableBodyCell>
      {editModeSelector ? (
        <>
          <EditWrap>
            <TextField
              type="text"
              value={inputVal || ''}
              onChange={event => cleanAndSetVal(event)}
              onBlur={onSubmit}
              disabled={loading}
            />
          </EditWrap>
          <Confirm
            open={bulkPricePopupOpen}
            onCancel={closeBulkPricePopup}
            onConfirm={() => {
              const buildingIds = params.node.allLeafChildren
                .filter(childNode => childNode.data.type === 'Building')
                .map(childNode => childNode.data.id);
              createOnDemandWorkingPriceForGeoGroupings(buildingIds, false, closeBulkPricePopup);
            }}
            cancelButton={{
              content: 'Cancel',
              className: 'cancel-confirmation',
              disabled: loading,
            }}
            closeIcon
            confirmButton={{
              content: 'Yes',
              loading,
            }}
            header={'Set building prices?'}
            content={
              <div>
                <p>
                  {`Price cannot be set at the ${params.data.type} level 
                    because you are not allowed to access some buildings in this ${params.data.type}.`}
                </p>
                <br />
                <p>Do you want to set the price for each accessible building individually?</p>
              </div>
            }
          />
        </>
      ) : (
        <>
          {displayVal}
          {!isEmpty(workingPricesValue) && (
            <Popup
              basic
              className="changelog-popup"
              trigger={
                <button
                  className="on-demand-changelog-trigger"
                  type="button"
                  onClick={() =>
                    fetchOnDemandItemChangeLog({
                      filter: { ids: [params.data.id] },
                      productIds: [productTypeId],
                    } as GeoHierarchiesChangeLogQueryVariables)
                  }
                />
              }
              on={'click'}
              content={
                <Popup.Content>
                  <ChangelogTitle>Change Log for {params.data.name}</ChangelogTitle>
                  <ChangelogList>{changeLogListItems()}</ChangelogList>
                </Popup.Content>
              }
            />
          )}
        </>
      )}
    </CustomTableBodyCell>
  ) : (
    <> </>
  );
};

export default OnDemandPriceCellRenderer;
