export enum BatchMode {
  SAME_PRICE,
  ADJUST_PRICE,
  SUGGESTED_PRICE,
}

export const nonPersonalOfficeProductType = {
  hotDesk: 'HOT_DESK',
  dedicatedDesk: 'DEDICATED_DESK',
};
