import { StoreInterface } from '../../../utils/store/store.interface';
import { LocationsSubset } from '../../entities/locations';
import { Unassigned } from '../../../utils/sharedTypes';
import { Location } from '../../../generated/voyager/graphql';

const locationSelector = (state: StoreInterface): LocationsSubset => state?.locations;

export const currentLocationsItemLoadState = (state: StoreInterface): boolean | Unassigned =>
  locationSelector(state)?.fetchLocationsState?.loaded;

export const currentLocationsItemLoadingState = (state: StoreInterface): boolean | Unassigned =>
  locationSelector(state)?.fetchLocationsState?.loading;

export const currentLocationsByIdsLoadState = (state: StoreInterface): boolean | Unassigned =>
  locationSelector(state)?.fetchLocationsByIdsState?.loaded;

export const currentLocationsByIdsLoadingState = (state: StoreInterface): boolean | Unassigned =>
  locationSelector(state)?.fetchLocationsByIdsState?.loading;

export const currentMultipleLocationDetailsSelector = (state: StoreInterface): Location[] =>
  state?.locations?.currentMultipleLocationDetails;

export const locationsSelectorWithOpenDate = (state: StoreInterface): Location[] =>
  locationSelector(state).allLocations;

export const marketLocationsSelectorWithOpenDate = (state: StoreInterface): Location[] =>
  locationSelector(state).allMarketLocations;

export const getAllSelectedLocations = (state: StoreInterface): Location[] =>
  locationSelector(state)?.locationsSelected;
