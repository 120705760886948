import { connectRouter } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';
import { History } from 'history';
import { onDemandPricingItemsReducer } from 'app/pricing/onDemandPricing/store/modules/onDemand/onDemandPricing.ducks';
import { regionsReducer } from '../../sharedStore/modules/regions/regions.ducks';
import { territoriesReducer } from '../../sharedStore/modules/territories/territories.ducks';
import { locationsReducer } from '../../sharedStore/modules/locations/locations.ducks';
import { marketsReducer } from '../../sharedStore/modules/markets/markets.ducks';
import { priceableItemsReducer } from '../../app/pricing/standardPricing/store/modules/priceableItems/priceableItems.ducks';
import { authReducer } from '../../sharedStore/modules/auth/auth.ducks';
import { discountItemsReducer } from '../../app/discounts/store/modules/discountItems/discountItems.ducks';
import { discountFiltersReducer } from '../../app/discounts/store/modules/filters/filters.ducks';
import { detailPanelReducer } from '../../sharedStore/modules/detailPanel/detailPanel.ducks';
import { publishPriceReducer } from '../../app/pricing/standardPricing/store/modules/publishPrice/publishPrice.ducks';
import { standardPricingItemsReducer } from '../../app/pricing/standardPricing/store/modules/standardPricingItems/standardPricingItems.ducks';
import { standardPricingFiltersReducer } from '../../app/pricing/standardPricing/store/modules/standardPricingFilters/standardPricingFilters.ducks';
import { breakevenItemsReducer } from '../../app/breakeven/store/modules/breakevenItems/breakevenItems.ducks';
import { uploadFileReducer } from '../../sharedStore/modules/uploadFile/uploadFile.ducks';
import { inventoryReconciliationReducer } from '../../app/adminPanel/store/modules/inventoryReconciliation/inventoryReconciliation.ducks';
import { inventoryReconciliationByLocationReducer } from '../../app/adminPanel/store/modules/inventoryReconciliationByLocation/inventoryReconciliationByLocation.ducks';
import { locationReconciliationReducer } from '../../app/adminPanel/store/modules/locationReconciliation/locationReconciliation.ducks';
import { resetBreakEvenReducer } from '../../app/adminPanel/store/modules/resetBreakEven/resetBreakEven.ducks';
import { snowflakeSyncInitializationReducer } from '../../app/adminPanel/store/modules/snowflakeSyncInitialization/snowflakeSyncInitialization.ducks';
import { spacemanPricesConsolidationReducer } from '../../app/adminPanel/store/modules/spacemanPricesConsolidation/spacemanPricesConsolidation.ducks';
import { geoHierarchyReconciliationReducer } from '../../app/adminPanel/store/modules/geoHierarchyReconciliation/geoHierarchyReconciliation.ducks';
import { priceRePublishingReducer } from '../../app/adminPanel/store/modules/priceSyncAttempts/priceSyncAttempts.ducks';
import { JobStatusReducer } from '../../app/adminPanel/store/modules/jobStatus/jobStatus.ducks';
import { actionItemsReducer } from '../../app/actionItems/store/modules/actionItems.ducks';
import { discountRePublishingReducer } from '../../app/adminPanel/store/modules/discountSyncAttempts/discountSyncAttempts.ducks';
import { generateLinkReducer } from '../../sharedStore/modules/generateLink/generateLink.ducks';

const createRootReducer = (history: History, segmentMetaReducer: Reducer): Reducer =>
  combineReducers({
    router: connectRouter(history),
    regions: regionsReducer,
    locations: locationsReducer,
    markets: marketsReducer,
    territories: territoriesReducer,
    priceableItems: priceableItemsReducer,
    auth: authReducer,
    discountItems: discountItemsReducer,
    discountFilters: discountFiltersReducer,
    detailPanel: detailPanelReducer,
    publishPrice: publishPriceReducer,
    standardPricingItems: standardPricingItemsReducer,
    standardPricingFilters: standardPricingFiltersReducer,
    breakevenItems: breakevenItemsReducer,
    segmentReducer: segmentMetaReducer,
    uploadFile: uploadFileReducer,
    actionItems: actionItemsReducer,
    inventoryReconciliation: inventoryReconciliationReducer,
    inventoryReconciliationByLocation: inventoryReconciliationByLocationReducer,
    locationReconciliation: locationReconciliationReducer,
    geoHierarchyReconciliation: geoHierarchyReconciliationReducer,
    resetBreakEven: resetBreakEvenReducer,
    snowflakeSyncInitialization: snowflakeSyncInitializationReducer,
    spacemanPricesConsolidation: spacemanPricesConsolidationReducer,
    priceRePublishing: priceRePublishingReducer,
    discountRePublishing: discountRePublishingReducer,
    onDemandPricingItems: onDemandPricingItemsReducer,
    jobStatus: JobStatusReducer,
    generateLink: generateLinkReducer,
  });

export default createRootReducer;
