export const breakevenRows = ['published', 'liveBudget', 'override', 'toBePublished'];
export const notInheritableProps = ['notes', 'sgAndA', 'breakEvenPrice'];

export const typeMap = {
  liveBudgetApplied: 'Live Budget',
  override: 'Override',
  noChange: 'No Change',
};

// CONST - set of fields which are editable.
export const fieldsEditable = new Set([
  'rsf',
  'deskCapacity',
  'tenancyNNN',
  'opex',
  'sgAndA',
  'rent',
  'totalBudgetRevenue',
  'desksInYearMonths',
  'steadyStateOcc',
  'notes',
]);

export const percentValues = ['steadyStateOcc'];
export const wholeNumberValues = ['deskCapacity', 'desksInYearMonths'];
export const valuesIncludedInCalculation = [
  'rsf',
  'rent',
  'tenancyNNN',
  'opex',
  'totalBudgetRevenue',
  'desksInYearMonths',
  'deskCapacity',
  'steadyStateOcc',
  'sgAndA',
];

export const rowTypeMap = {
  liveBudget: 'Live Budget',
  current: 'Published',
  toBePublished: 'To Be Published',
  override: 'Overrides',
};

export const maxIntegerValue = Number.MAX_SAFE_INTEGER;
// Java Integer.MAX_VALUE = 2^31 - 1
export const maxJavaIntegerValue = 2147483647;
