import * as React from 'react';
import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm, Checkbox } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import {
  RESET_SELECTED_NODES_ON_DEMAND,
  SET_ON_DEMAND_ITEMS_TO_PUBLISH,
  SET_ON_DEMAND_PRODUCTS_TO_PUBLISH,
} from '../../../store/modules/onDemand/onDemandPricing.ducks';
import {
  onDemandPricingAllItemsSelector,
  onDemandPricingProductsSelector,
  onDemandPricingProductsToPublishSelector,
  onDemandSelectedNodesSelector,
} from '../../../store/modules/onDemand/onDemandPricing.selector';
import { generateWorkingPricesOnDemandMutationParam } from '../../onDemandPricing.helpers';
import { trackAnalytics } from '../../../../../../utils/analytics/helpers';
import { ConfirmProps } from '../../../../../../sharedStore/entities/confirmProps';
import { routerHistory } from '../../../../../../utils/store/configureStore';
import ROUTES from '../../../../../../utils/routes/routes';
import { GeoHierarchy, Product } from '../../../../../../generated/voyager/graphql';

function OnDemandPreviewWorkingPricesConfirm(props: ConfirmProps): ReactElement {
  const dispatch = useDispatch();

  const allProducts = useSelector(onDemandPricingProductsSelector);
  const allPricingItems = useSelector(onDemandPricingAllItemsSelector);
  const productsToPublish = useSelector(onDemandPricingProductsToPublishSelector);
  const selectedNodes = useSelector(onDemandSelectedNodesSelector);

  const setOnDemandItemsToPublish = useCallback(
    (payload: GeoHierarchy[]) => dispatch({ type: SET_ON_DEMAND_ITEMS_TO_PUBLISH, payload }),
    [dispatch],
  );
  const setOnDemandProductsToPublish = useCallback(
    (payload: Product[]) => dispatch({ type: SET_ON_DEMAND_PRODUCTS_TO_PUBLISH, payload }),
    [dispatch],
  );
  const resetSelectedNodes = useCallback(
    () =>
      dispatch({
        type: RESET_SELECTED_NODES_ON_DEMAND,
      }),
    [dispatch],
  );

  const resetSelectedProductIdsToCancel = () => {
    setOnDemandProductsToPublish(allProducts);
  };

  const closePreviewConfirmationModal = () => {
    props.closeModal();
    resetSelectedNodes();
    resetSelectedProductIdsToCancel();
  };

  useEffect(resetSelectedProductIdsToCancel, [setOnDemandProductsToPublish, allProducts]);

  const trackOnDemandPreviewButton = () => {
    trackAnalytics('On Demand - Preview Button Click', {
      workflow: 'On Demand - Preview',
      object_type: 'button',
      object_name: 'Preview',
    });
  };

  const redirectToPreview = () => {
    trackOnDemandPreviewButton();

    setOnDemandItemsToPublish(
      isEmpty(selectedNodes) ? allPricingItems : selectedNodes.map(node => node.data),
    );

    routerHistory.push({
      pathname: ROUTES.PUBLISH_ON_DEMAND_SUMMARY,
    });
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={closePreviewConfirmationModal}
      onConfirm={redirectToPreview}
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
      }}
      closeIcon
      confirmButton={{
        content: 'Continue to preview',
        disabled: !generateWorkingPricesOnDemandMutationParam(
          isEmpty(selectedNodes) ? allPricingItems : selectedNodes.map(node => node.data),
          productsToPublish.map(product => product.id),
        ).length,
      }}
      header="Preview Working Prices"
      content={
        <div>
          <p>Please select products to generate previews for:</p>
          {allProducts.map(product => (
            <div key={product.id}>
              <Checkbox
                className={'checkbox-top'}
                label={product.name}
                checked={productsToPublish.includes(product)}
                onChange={(_, data) =>
                  setOnDemandProductsToPublish(
                    data.checked
                      ? [...productsToPublish, product]
                      : productsToPublish.filter(p => p.id !== product.id),
                  )
                }
              />
            </div>
          ))}
        </div>
      }
    />
  );
}

export default OnDemandPreviewWorkingPricesConfirm;
