import * as React from 'react';
import { ReactElement } from 'react';
import { Tag } from '@wework/dieter-ui';
import { isBefore, startOfToday } from 'date-fns';
import { ValueGetterParams } from 'ag-grid-community';
import { dateHelper } from './multipleLocationSearchAGGrid.helpers';

const StatusCellRenderer = (props: ValueGetterParams): ReactElement => {
  const currentDate = dateHelper(props);
  return currentDate && isBefore(currentDate, startOfToday()) ? (
    <Tag color="green">Open</Tag>
  ) : (
    <Tag color="red">Closed</Tag>
  );
};

export default StatusCellRenderer;
