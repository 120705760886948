import * as React from 'react';
import { ReactElement } from 'react';
import { withRequestedAuthz } from '@wework/we-auth-react';
import { get } from 'lodash';
import ApplicationCardComponent from './applicationCard';
import ROUTES from '../../utils/routes/routes';
import { AuthzProps } from '../../utils/constants';
import { ApplicationCardItem, ApplicationsDashboardList } from './homeDashboard.styled';
import EmployeePermissions from '../../utils/store/permissions';
import { AppSubHeading } from './applicationCard.styled';

function HomeDashboardComponent({ requestedPermissions }: AuthzProps): ReactElement {
  const discountBtn = [
    {
      label: 'Manage Discounts',
      route: ROUTES.DISCOUNT,
    },
  ];

  if (
    get(
      requestedPermissions,
      EmployeePermissions.voyager_dedicated_space_discount_curve_manage,
      false,
    )
  ) {
    discountBtn.push({
      label: 'Curves',
      route: ROUTES.DISCOUNT_CURVE_MANAGEMENT,
    });
  }

  // Checking for batch discount uploader permission.
  if (
    get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_discount_publish, false)
  ) {
    discountBtn.push({
      label: 'Upload',
      route: ROUTES.UPLOAD_DISCOUNTS_FROM_FILE,
    });
  }

  const pricingBtn = [
    {
      label: 'Manage Prices',
      route: ROUTES.PRICING,
    },
  ];

  const onDemandPricingBtn = [
    {
      label: 'Manage On Demand Prices',
      route: ROUTES.ON_DEMAND_PRICING,
    },
  ];

  if (get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_publish, false)) {
    pricingBtn.push({
      label: 'Upload',
      route: ROUTES.UPLOAD_PRICING_FROM_FILE,
    });
  }

  const applicationCardsDedicatedSpace = [
    {
      name: 'Pricing Management',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_dedicated_space_price_view, false),
        'Pricing',
        'Management',
        'Pricing and repricing for SKUs.',
        pricingBtn,
      ),
    },
    {
      name: 'Discounts Management',
      content: ApplicationCardComponent(
        'discounts',
        !get(
          requestedPermissions,
          EmployeePermissions.voyager_dedicated_space_discount_view,
          false,
        ),
        'Discounts',
        'Management',
        'Discount and curves management tools.',
        discountBtn,
      ),
    },
    {
      name: 'Breakeven Price',
      content: ApplicationCardComponent(
        'breakeven',
        !get(
          requestedPermissions,
          EmployeePermissions.voyager_dedicated_space_breakeven_view,
          false,
        ),
        'Breakeven',
        'Price',
        'Manage breakeven price to ensure profitability.',
        [
          {
            label: 'Manage Breakeven',
            route: ROUTES.BREAKEVEN,
          },
        ],
      ),
    },
  ];

  const applicationCardsOnDemand = [
    {
      name: 'On Demand Pricing Management',
      content: ApplicationCardComponent(
        'pricing',
        !get(requestedPermissions, EmployeePermissions.voyager_on_demand_price_view, false),
        'Pricing',
        'Management',
        'On Demand Pricing and repricing.',
        onDemandPricingBtn,
      ),
    },
  ];

  return (
    <>
      <AppSubHeading>Dedicated Space</AppSubHeading>
      <ApplicationsDashboardList key="dedicated-list">
        {applicationCardsDedicatedSpace.map((application: any) => (
          <ApplicationCardItem key={application.name}>{application.content}</ApplicationCardItem>
        ))}
      </ApplicationsDashboardList>
      <AppSubHeading>On Demand</AppSubHeading>
      <ApplicationsDashboardList key="on-demand-list">
        {applicationCardsOnDemand.map((application: any) => (
          <ApplicationCardItem key={application.name}>{application.content}</ApplicationCardItem>
        ))}
      </ApplicationsDashboardList>
    </>
  );
}

export default withRequestedAuthz<AuthzProps>({
  permissions: [
    EmployeePermissions.voyager_dedicated_space_breakeven_view,
    EmployeePermissions.voyager_dedicated_space_price_view,
    EmployeePermissions.voyager_dedicated_space_discount_view,
    EmployeePermissions.voyager_dedicated_space_discount_curve_manage,
    EmployeePermissions.voyager_dedicated_space_price_publish,
    EmployeePermissions.voyager_dedicated_space_discount_publish,
    EmployeePermissions.voyager_on_demand_price_view,
  ],
})(HomeDashboardComponent);
