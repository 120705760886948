import styled from 'styled-components';
import { blue, lighterGray, lightestGray, lightGray } from '../../../../styles/colors';

export const SnowflakeFunctionalZone = styled.div`
  margin: 0 auto;
  height: 70vh;
  width: 90%;
  min-width: 240px;
  border: 1px solid ${lighterGray};
  background: ${lightestGray};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    padding: 0.5rem 0.75rem;
    text-align: center;
  }
  &.hidden-dropzone {
    display: none;
  }
`;

export const SnowflakeButton = styled.button`
  position: relative;
  padding: 20px;
  border: 0;
  box-shadow: none;
  margin: 20px 20px 20px 70px;
  border-radius: 12px;
  background-color: ${lighterGray};
  padding: 10px;
  order: 1;
  color: ${blue};
  &:disabled {
    color: ${lightGray};
  }
`;
