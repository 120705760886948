import * as React from 'react';
import { ReactElement, useCallback } from 'react';
import { pluralize } from 'apollo/lib/utils';
import { Confirm } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelFutureDiscountIdsSelector,
  cancelFutureDiscountLoadingSelector,
} from '../../../store/modules/discountItems/discountItems.selector';
import {
  CancelDiscountScope,
  CancelFutureDiscountInput,
} from '../../../../../generated/voyager/graphql';
import { CANCEL_FUTURE_DISCOUNT } from '../../../store/modules/discountItems/discountItems.ducks';
import { DataWithCallback } from '../../../../../utils/sharedTypes';
import { ConfirmProps } from '../../../../../sharedStore/entities/confirmProps';

function CancelFutureDiscountsConfirm(props: ConfirmProps): ReactElement {
  const dispatch = useDispatch();
  const cancelIds = useSelector(cancelFutureDiscountIdsSelector);
  const loading = useSelector(cancelFutureDiscountLoadingSelector);

  const closeCancelConfirmationModal = () => {
    props.closeModal();
  };

  const cancelFutureDiscounts = useCallback(
    (payload: DataWithCallback<CancelFutureDiscountInput>) =>
      dispatch({ type: CANCEL_FUTURE_DISCOUNT, payload }),
    [dispatch],
  );

  const onConfirm = () => {
    cancelFutureDiscounts({
      data: {
        ids: cancelIds,
        scope: CancelDiscountScope.PRICEABLE_ITEM,
      },
      successCallback: () => closeCancelConfirmationModal(),
    });
  };

  return (
    <Confirm
      open={props.isOpen}
      onCancel={closeCancelConfirmationModal}
      onConfirm={() => onConfirm()}
      cancelButton={{
        content: 'Cancel',
        className: 'cancel-confirmation',
        disabled: loading,
      }}
      closeIcon
      confirmButton={{
        content: `Cancel Discounts for ${pluralize(cancelIds.length, 'item')}`,
        loading,
      }}
      header="Cancel Discount"
      content={
        <div>
          <p>For the selected reservables, future scheduled discounts will be canceled.</p>
          <p>Are you sure you want to cancel future discounts?</p>
        </div>
      }
    />
  );
}

export default CancelFutureDiscountsConfirm;
